import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ISystem } from '../../ts/interface/system.interface';
import { IMode } from '../../ts/interface/mode.interface';
import { changeTab, toTitleCase } from '../../utils';
import { useParams } from 'react-router-dom';
import PageContainer from '../../components/Redesing/page-container';

import { Card as MuiCard } from '@mui/material';
import { Stack } from '@mui/system';
import PageContent from '../../components/Redesing/page-content';
import MaintenanceTags from './maintenance-tags';

import MaintenanceLogs from './maintenance-logs';
import MaintenanceTicket from './maintenance-ticket';

const Maintenance = ({ mode, system }: { mode: IMode; system: ISystem }) => {
  const { currentTab = 'tickets' } = useParams<{ currentTab: string }>();
  const [currentTabV, setCurrentTabV] = useState(currentTab);

  const handleTabsChange = useCallback(
    (event: React.SyntheticEvent, value: React.SetStateAction<string>) => {
      changeTab(value, currentTab, 'maintenance');
    },
    [currentTab]
  );

  useEffect(() => {
    if (currentTab !== currentTabV) {
      setCurrentTabV(currentTab);
    }
  }, [currentTab, currentTabV]);

  const activeTab = {
    tickets: <MaintenanceTicket />,
    logs: <MaintenanceLogs />,
    tags: <MaintenanceTags />,
  };

  return (
    <PageContainer spaceName={`Maintenance ${toTitleCase(currentTab)}`}>
      <PageContent
        detailedView={false}
        tabsList={[
          { label: 'Tickets', value: 'tickets' },
          { label: 'Logs', value: 'logs' },
          { label: 'Tags', value: 'tags' },
        ]}
        tabsChildren={
          <Stack paddingTop={1}>
            <MuiCard>{activeTab[currentTabV as 'tickets' | 'logs' | 'tags']}</MuiCard>
          </Stack>
        }
        currentTabV={currentTabV}
        handleTabsChange={handleTabsChange}
      />
    </PageContainer>
  );
};

export default connect(
  (state: { system: { isLoaded: boolean; current: ISystem }; mode: IMode }) => ({
    system: state.system.current,
    mode: state.mode,
  }),
  () => ({})
)(Maintenance);
