import React, { useCallback, useMemo, useState } from 'react';

import { connect } from 'react-redux';
import { ISystem } from '../../ts/interface/system.interface';
import { IMode } from '../../ts/interface/mode.interface';

import { PaginationTable } from '../../components/Redesing/table/pagination-table';
import { ITag } from '../../ts/interface/problem.interface';
import { ticketColumns } from '../../tableColumns/maintenanceColumns';
import {
  IColumn,
  ISortConfig,
} from '../../ts/interface/components/redesing/table.interface';
import { SearchChip } from '../../ts/interface/components/table.interface';

import useUrlState from '../../hooks/use-url-state';
import useSearchDebounce from '../../hooks/search/useSearchDebounce';
import useMaintenance from '../../hooks/maintenance/query/useMaintenance';
import { useUpdateEffect } from '../../hooks/use-update-effect';
import useTags from '../../hooks/maintenance/query/useTags';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { MovaticFieldGroupSelectFilter } from '../../components/Redesing/movatic-field-group';
import AddIcon from '@mui/icons-material/Add';
import { history } from '../../utils';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants';

interface IMaintenanceTicketProps {
  systemLoaded: boolean;
  system: ISystem;
  mode: IMode;
}

interface IFilters {
  label: string;
  value: string;
}

const filterOptions = [
  { value: 'open', label: 'Open', disabled: true },
  { value: 'closed', label: 'Closed' },
  { value: 'canceled', label: 'Canceled' },
];

const MaintenanceTicket: React.FC<IMaintenanceTicketProps> = (props) => {
  const { mode, system } = props;
  const { unitsTitle } = mode.details;
  const { new_hardware } = system;
  const [exportData, setExportData] = useState<boolean>(false);
  const [value, setValue] = React.useState<any[]>([{ label: 'Open', value: 'open' }]);
  const [tagValues, setTagValues] = useState<IFilters[]>([]);
  const { data: tagsData = [], isLoading: isTagsLoading } = useTags(system.id, {
    enabled: props.systemLoaded,
  });

  const loadedTags = useMemo(
    () =>
      tagsData.map((item: ITag) => {
        return {
          label: item.name,
          value: item.name,
        };
      }),
    [tagsData]
  );

  const [filters, setFilters, isComplete] = useUrlState({
    sizePerPage: DEFAULT_ROWS_PER_PAGE,
    pageNumber: 0,
    search: '',
    status: 'open',
    tags: '',
    sortConfig: {
      key: 'created_on',
      direction: 'desc',
    } as ISortConfig,
  });

  const debouncePatternMaintenance = useSearchDebounce(filters.search);
  const [chips, setChips] = useState<SearchChip[]>([
    {
      label: 'Status',
      field: 'status',
      value: 'open',
      displayValue: 'Open',
      disabled: false,
    },
  ]);

  const {
    data,
    isLoading: loadingTickets,
    isFetched: isAllTicketsComplete,
  } = useMaintenance(
    {
      enabled: isComplete && props.systemLoaded,
    },
    {
      page: filters.pageNumber,
      limit: filters.sizePerPage,
      status: filters.status,
      tags: filters.tags,
      sort_by: filters.sortConfig.key,
      sort_order: filters.sortConfig.direction,
      pattern: debouncePatternMaintenance,
    }
  );

  useUpdateEffect(() => {
    if (isComplete) {
      const status = filters.status ? filters.status.split(',') : [];
      const tags = filters.tags ? filters.tags.split(',') : [];

      const chipsAux: SearchChip[] = status.map((item) => {
        const option = filterOptions.find((option) => option.value === item);
        return {
          label: 'Status',
          field: 'status',
          value: item,
          displayValue: option?.label || 'Open',
        };
      });

      tags.forEach((item) => {
        chipsAux.push({
          label: 'Tags',
          field: 'tags',
          value: item,
          displayValue: item,
        });
      });

      setChips(chipsAux);

      setValue(
        status.map((item) => ({
          label: filterOptions.find((option) => option.value === item)?.label || '',
          value: item,
        }))
      );

      setTagValues(
        tags.map((item) => ({
          label: item,
          value: item,
        }))
      );
    }
  }, [isComplete, filters.status, filters.tags, filterOptions]);

  const columnsDis = useMemo(() => {
    return ticketColumns(
      unitsTitle,
      new_hardware,
      filters.status ? [filters.status] : ['all']
    );
  }, [unitsTitle, new_hardware, filters.status]);

  const handleChipsChange = useCallback(
    (
      values: string[],
      list: { label: string; value: string }[],
      metadata: { label: string; value: string }
    ): void => {
      setFilters((prevState) => ({
        ...prevState,
        pageNumber: 0,
        sizePerPage: DEFAULT_ROWS_PER_PAGE,
        [metadata.value]: values.join(','),
      }));

      setChips((prevChips) => {
        const valuesFound: string[] = [];

        const newChips = prevChips.filter((chip) => {
          if (chip.field !== metadata.value) {
            return true;
          }

          const found = values.includes(chip.value as string);

          if (found) {
            valuesFound.push(chip.value as string);
          }

          return found;
        });

        if (values.length === valuesFound.length) {
          return newChips;
        }

        values.forEach((value) => {
          if (!valuesFound.includes(value)) {
            const option = list.find((option) => option.value.toString() === value);
            newChips.push({
              label: metadata.label,
              field: metadata.value,
              value,
              displayValue: option!.label,
            });
          }
        });
        return newChips;
      });
    },
    [setFilters]
  );

  const handleChipDelete = useCallback(
    (deletedGroup: SearchChip) => {
      setChips((prevChips) => {
        return prevChips.filter((group) => {
          return group.label !== deletedGroup.label;
        });
      });

      const chipToDelete = deletedGroup.label.toLowerCase() as 'status' | 'tags';

      setFilters((prevState) => ({
        ...prevState,
        pageNumber: 0,
        sizePerPage: DEFAULT_ROWS_PER_PAGE,
        [chipToDelete]: '',
      }));

      const deleteChip = {
        status: () => setValue([]),
        tags: () => setTagValues([]),
      };

      if (deleteChip[chipToDelete]) {
        deleteChip[chipToDelete]();
      }
    },
    [setFilters]
  );

  const allTicketsData = useMemo(() => {
    return data?.data || [];
  }, [data]);

  const totalTickets = useMemo(() => {
    return data?.total_count || 0;
  }, [data]);

  const totalItems = useMemo(() => {
    return totalTickets;
  }, [totalTickets]);

  return (
    <PaginationTable
      noDataSlice
      totalItems={totalItems}
      isLoading={loadingTickets || !isComplete || !isAllTicketsComplete}
      disableHasMore
      localSearch={false}
      showFilter
      padding={{ pt: 1, pl: 0, pr: 1 }}
      chips={chips}
      handleChipDelete={handleChipDelete}
      exportData={exportData}
      csvFileName={'Maintenance_tickets.csv'}
      onExport={() => setExportData(false)}
      buttons={[
        {
          label: 'Export',
          startIcon: <FileDownloadIcon />,
          onClick: () => setExportData(true),
        },
        {
          label: 'Add',
          onClick: () => history.push('/maintenance/add'),
          startIcon: <AddIcon />,
        },
      ]}
      onApplyFilter={() => {
        if (JSON.stringify(value) !== JSON.stringify(filters.status)) {
          handleChipsChange(
            [
              ...value
                .filter((option) =>
                  filterOptions.some((item) => item.label === option.label)
                )
                .map((item) => item.value.toString()),
            ],
            filterOptions,
            { label: 'Status', value: 'status' }
          );
        }

        if (JSON.stringify(tagValues) !== JSON.stringify(filters.tags)) {
          handleChipsChange(
            tagValues.map((item) => item.value.toString()),
            loadedTags,
            { label: 'Tags', value: 'tags' }
          );
        }
      }}
      spaceFilters={
        <>
          <MovaticFieldGroupSelectFilter
            label={'Status'}
            value={value}
            onChange={(event: React.SyntheticEvent, newValue: any[]) => {
              setValue([
                ...newValue.filter((option) =>
                  filterOptions.some((item) => item.label === option.label)
                ),
              ]);
            }}
            options={filterOptions}
          />
          <MovaticFieldGroupSelectFilter
            label={'Tags'}
            isLoading={isTagsLoading}
            value={tagValues}
            onChange={(event, newValue) => {
              setTagValues([
                ...newValue.filter((option) =>
                  loadedTags.some(
                    (item: { label: string }) => item.label === option.label
                  )
                ),
              ]);
            }}
            options={loadedTags}
          />
        </>
      }
      onRowCLick={(id: string) => history.push(`/maintenance/${id}`)}
      sortBy={filters.sortConfig.key}
      onSortChange={(sort) =>
        setFilters((prevState) => ({
          ...prevState,
          sortConfig: sort as ISortConfig,
        }))
      }
      columns={columnsDis as IColumn[]}
      items={allTicketsData}
      searchPlaceholder={`Search tickets`}
      page={filters.pageNumber}
      rowsPerPage={filters.sizePerPage}
      onPageChange={(page, newPage) => {
        setFilters((prevState) => ({
          ...prevState,
          pageNumber: newPage,
        }));
      }}
      onRowsPerPageChange={(pageSizeString) => {
        const pageSize = Number(pageSizeString.target.value);
        setFilters((prevState) => ({
          ...prevState,
          pageNumber: 0,
          sizePerPage: pageSize,
        }));
      }}
      handleSearch={(e: React.ChangeEvent<HTMLInputElement>) =>
        setFilters((prevState) => ({
          ...prevState,
          search: e.target.value,
        }))
      }
      searchText={filters.search}
    />
  );
};

export default connect(
  (state: { system: { isLoaded: boolean; current: ISystem }; mode: IMode }) => ({
    systemLoaded: state.system.isLoaded,
    system: state.system.current,
    mode: state.mode,
  }),
  () => ({})
)(MaintenanceTicket);
