import type { FC } from 'react';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { logOut } from '../../api/admin';
import toast from 'react-hot-toast';
import { IAdmin } from '../../ts/interface/admins.interface';
import DarkModeToggle from '../../components/Redesing/dark-mode-toggle';
import Stack from '@mui/material/Stack';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import LogoutIcon from '@mui/icons-material/Logout';
interface AccountPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
  admin: IAdmin;
  onAboutMe?: () => void;
}

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
  const { anchorEl, onClose, open, admin, onAboutMe, ...other } = props;
  const handleLogout = useCallback(() => {
    toast.promise(
      logOut().then((res) => {
        if (res.status === 200) {
          window.location.href = '/';
        }
      }),
      {
        loading: 'Logging you out...',
        success: <b>Good bye!</b>,
        error: <b>Error Login you out.</b>,
      }
    );
  }, []);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 200 } }}
      {...other}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ px: 2.5, py: 2 }}
        // spacing={2}
      >
        <IconButton
          size={'small'}
          sx={{ p: 2, position: 'absolute', top: 0, right: 0 }}
          onClick={onAboutMe}
        >
          <EditIcon />
        </IconButton>
        <Typography variant="body1">{`${admin.firstName} ${admin.lastName}`}</Typography>
        <Typography color="text.secondary" variant="body2">
          {admin.email}
        </Typography>
      </Stack>
      <Divider />
      <Stack direction={'column'} divider={<Divider />}>
        <DarkModeToggle />
      </Stack>
      <Divider sx={{ my: '0 !important' }} />
      <Box
        sx={{
          display: 'flex',
          p: 1,
          justifyContent: 'center',
        }}
      >
        <IconButtonMenu
          buttons={[
            {
              label: 'Logout',
              onClick: handleLogout,
              startIcon: <LogoutIcon />,
            },
          ]}
        />
      </Box>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
