/**
 * Helper functions to transform data using Yup's transform()
 */

/**
 * @param {*} value
 * @param {*} originalValue
 * @returns {(null|*)} null if the originalValue is an empty string, the value otherwise
 */
export const emptyStringToNull = (value, originalValue) =>
  originalValue === '' ? null : value;

/**
 * @param {number} dollars
 * @returns {number} dollars multiplied by 100 and rounded if its truthy, otherwise dollars
 */
export const dollarsToCents = (dollars) =>
  dollars ? Math.round(dollars * 100) : dollars;
