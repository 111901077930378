import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import '../css/stripe.css';
import MovaticCustomModal from './Modal/MovaticCustomModal';
import IconButtonMenu from './Redesing/icon-button-menu';
import AddIcon from '@mui/icons-material/Add';
import CustomToast from './Redesing/custom-toast';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const CheckoutForm = (props) => {
  const elements = useElements();
  const stripe = useStripe();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    const clientSecret = await props.getClientSecret();
    const cardElement = elements.getElement(CardElement);

    const payload = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardElement,
      },
    });

    setLoading(false);

    if (payload.error) {
      setErrorMessage(payload.error.message);
    } else {
      setErrorMessage(null);
      CustomToast({
        type: 'success',
        message: 'Card has been added successfully!',
      });
      props.getUserCard();
      props.close();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label className="card-label" htmlFor="card number">
        Card details
        <CardElement options={CARD_ELEMENT_OPTIONS} />
      </label>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      <div className="submit-card-container btn-group">
        <IconButtonMenu
          buttons={[
            {
              label: 'Add',
              onClick: handleSubmit,
              startIcon: <AddIcon />,
              disabled: !stripe || isLoading,
            },
          ]}
        />
      </div>
    </form>
  );
};

// TODO: Get the Stripe key from an environment variable
const testMode =
  window.location.hostname === 'localhost' ||
  window.location.hostname === 'admindev.movatic.co';
const stripe = testMode
  ? loadStripe('pk_test_GlNsOiyyzS8vajk7H5lIAlSf')
  : loadStripe('pk_live_pYdZfXZEdwXsrgBiiZq08qhe');

const AddStripeCard = (props) => (
  <Elements stripe={stripe}>
    <CheckoutForm {...props} />
  </Elements>
);

const AddStripeCardModal = (props) => {
  return (
    <>
      <MovaticCustomModal
        open
        onClose={props.close}
        title={'Add card to user'}
        content={<AddStripeCard {...props} />}
      />
    </>
  );
};

export default AddStripeCardModal;
