import React from 'react';
import DashboardChart from './dashboard-chart';
import { IChartData } from '../../../ts/interface/dashboardGraph.interface';
import Card from '@mui/material/Card';
import { Stack } from '@mui/system';
import { formatNumberWithCommas } from '../../../utils';

interface MonthGraphsProps {
  rentalAvg: string;
  rentalTotal: number;
  rentalData: IChartData;
  userAvg: string;
  userTotal: number;
  userData: IChartData;
  durationAvg: string;
  durationTotal: number;
  durationData: IChartData;
  systemTimezoneLabel: string;
}

const MonthGraphs: React.FC<MonthGraphsProps> = (props) => {
  const {
    durationAvg,
    durationData,
    durationTotal,
    rentalAvg,
    rentalData,
    rentalTotal,
    userAvg,
    userData,
    userTotal,
    systemTimezoneLabel,
  } = props;

  return (
    <>
      <Stack>
        <Stack pt={2} px={2}>
          <Card>
            <DashboardChart
              id={'rental'}
              data={rentalData}
              total={formatNumberWithCommas(rentalTotal)}
              average={formatNumberWithCommas(rentalAvg)}
              systemTimezoneLabel={systemTimezoneLabel}
              showTimezone
              title={'Rentals Per Day'}
              statTittleOne={'Average'}
              statTittleTwo={'Total'}
            />
          </Card>
        </Stack>
        <Stack pt={2} px={2}>
          <Card>
            <DashboardChart
              id={'user'}
              data={userData}
              total={formatNumberWithCommas(userTotal)}
              average={formatNumberWithCommas(userAvg)}
              systemTimezoneLabel={systemTimezoneLabel}
              title={'New Users Per Day'}
              showTimezone
              statTittleOne={'Average'}
              statTittleTwo={'Total'}
            />
          </Card>
        </Stack>
        <Stack pt={2} px={2} pb={2}>
          <Card>
            <DashboardChart
              id={'duration'}
              data={durationData}
              total={formatNumberWithCommas(durationTotal)}
              average={formatNumberWithCommas(durationAvg)}
              systemTimezoneLabel={systemTimezoneLabel}
              showTimezone
              title={'Duration of Rentals'}
              statTittleOne={'Average'}
              statTittleTwo={'Total'}
            />
          </Card>
        </Stack>
      </Stack>
    </>
  );
};

export default MonthGraphs;
