import toast from 'react-hot-toast';

interface CustomToastProps {
  promise: () => Promise<void>;
  loadingMessage: any;
  successMessage: any;
  errorMessage?: any;
  onErrorCallBack?: Function;
}

//@ts-ignore
const CustomAsyncToast = ({
  promise,
  loadingMessage,
  successMessage,
  errorMessage,
  onErrorCallBack,
}: CustomToastProps) => {
  const toastStyle = {
    borderRadius: '10px',
    background: '#333',
    color: '#fff',
  };

  let result: any = null;
  return toast
    .promise(
      promise().then((r) => {
        result = r;
      }),
      {
        loading: loadingMessage,
        success: () => {
          return successMessage(result);
        },
        error: (r) => {
          onErrorCallBack?.(r);
          return r ? r : errorMessage;
        },
      },
      {
        style: toastStyle,
        duration: 10000,
      }
    )
    .then(() => {
      return result;
    });
};

export default CustomAsyncToast;
