export const fetchDirectusData = async (
  collection: string,
  filters?: Record<string, string>,
  includeDetailedViews: boolean = false
) => {
  const filterParams = filters
    ? Object.keys(filters).map((key) => `filter[${key}][_eq]=${filters[key]}`)
    : [];
  const url = `https://directus.movatic.co/items/${collection}?${filterParams.join('&')}`;
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Error: ${response.statusText}`);
  }
  const data = await response.json();
  if (includeDetailedViews) {
    const detailedViewsPromises = data.data.map(
      async (item: { detailed_views: string[] }) => {
        const detailerViewsArray = item.detailed_views;
        const views = detailerViewsArray.map(async (viewId: string) => {
          const detailedViewsUrl = `https://directus.movatic.co/items/adminSiteSubSpace?filter[id][_eq]=${viewId}`;
          const detailedViewsResponse = await fetch(detailedViewsUrl);
          if (detailedViewsResponse.ok) {
            const detailedViewsData = await detailedViewsResponse.json();
            return { ...detailedViewsData.data[0] };
          }
        });
        return { ...item, detailed_views: await Promise.all(views) };
      }
    );
    return (await Promise.allSettled(detailedViewsPromises)).find(Boolean) || {};
  }

  return data;
};
