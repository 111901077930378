export enum LocationAccess {
  Public = 'Public',
  Private = 'Private',
}

export enum StripeRequirementType {
  future = 'future_requirements',
  now = 'requirements',
}

export enum StripeRequirementStatus {
  currentlyDue = 'currently_due',
  pastDue = 'past_due',
  eventuallyDue = 'eventually_due',
}

export enum LocationStatus {
  all = 'all',
  active = 'active',
  maintenance = 'maintenance',
  retired = 'retired',
  unassigned = 'unassigned',
}
