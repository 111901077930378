import React, { useState, useMemo } from 'react';
import useStation from '../../hooks/stations/query/useStation';
import { updateMembershipPerks } from '../../api/memberships';

import type { IStation } from '../../ts/interface/station.interface';
import type { FieldGroupDropdownOption } from '../../ts/interface/components/fieldGroup.interface';
import type { PerksModalProps } from '../../ts/interface/components/perksModal.interface';
import MovaticCustomModal from '../../components/Modal/MovaticCustomModal';
import toast from 'react-hot-toast';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { MovaticFieldGroupSelect } from '../../components/Redesing/movatic-field-group';
import MenuItem from '@mui/material/MenuItem';
import { LocationStatus } from '../../ts/enums';

const DEFAULT_LOCATIONS: FieldGroupDropdownOption[] = [{ value: 'none', label: 'None' }];

function PerksModal({
  openModal,
  systemID,
  membership,
  perkData,
  locationIDs,
  handleCloseModal,
  refetchMembershipData,
}: PerksModalProps) {
  const [currentLocation, setCurrentLocation] = useState<string | null>(
    perkData?.stationID || 'none'
  );
  const { data, isLoading } = useStation(
    {
      enabled: !!systemID,
    },
    { status: LocationStatus.active, system_id: systemID, private_only: true }
  );

  const privateLocations = useMemo(() => {
    const stations: FieldGroupDropdownOption[] = [];
    const stationsData: IStation[] = data?.data || [];

    if (stationsData) {
      // filter out stations that are already in the membership except if the user clicked on the table row
      // in this case we want to render it. So the user can change the station of the perk
      stationsData
        .filter(({ id }) => !locationIDs?.includes(id) || id === perkData?.stationID)
        .forEach(({ id, name }) => stations.push({ value: id, label: name }));
    }

    return [...DEFAULT_LOCATIONS, ...stations];
  }, [data?.data, locationIDs, perkData?.stationID]);

  function handleSubmit() {
    toast.promise(
      updateMembershipPerks(membership.id, {
        perks: [
          {
            uuid: perkData?.perkID || undefined,
            station_id: currentLocation === 'none' ? null : currentLocation,
          },
        ],
      }),
      {
        loading: 'Updating membership perks...',
        success: () => {
          refetchMembershipData();
          handleCloseModal();
          return 'Membership perks updated successfully';
        },
        error: 'Error updating membership perks',
      }
    );
  }

  return (
    <>
      <MovaticCustomModal
        open={openModal}
        onClose={handleCloseModal}
        title={`Add a Location Access to Membership: "${membership.name}"`}
        content={
          <>
            <MovaticFieldGroupSelect
              label="Private Locations"
              description="Allow memberships members to access the selected location."
              name={'private-locations'}
              optionNodes={privateLocations.map((option, index) => (
                <MenuItem key={index} value={option?.value || ''}>
                  {option?.label}
                </MenuItem>
              ))}
              value={currentLocation}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setCurrentLocation(e.target.value);
              }}
              id="private-locations"
            />
          </>
        }
        action={
          <IconButtonMenu
            buttons={[
              {
                label: 'Cancel',
                onClick: () => handleCloseModal(),
                startIcon: <CloseIcon />,
              },
              {
                label: 'Submit',
                onClick: () => handleSubmit(),
                startIcon: <SaveIcon />,
                disabled:
                  isLoading ||
                  privateLocations.length === 1 ||
                  (currentLocation === 'none' && !perkData?.perkID),
              },
            ]}
          />
        }
      />
    </>
  );
}

export default PerksModal;
