import {
  baseUrl,
  fetchOptions,
  fetchOptionsV2,
  fetchPostOptions,
  getCredentials,
} from './http';
import { displayError } from '../utils';
import { FourOhOneError } from '../actionCreators/system';
import CustomToast from '../components/Redesing/custom-toast';

export const logOut = () => {
  const fetchOps = fetchOptionsV2(null, null, 'delete');
  return fetch(`${baseUrl}/adminuser/logout`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results.status === 200) {
        localStorage.removeItem('adminDate');
        localStorage.removeItem('admin');
        return results;
      } else {
        displayError(
          'It looks like there was an error while logging you out. Please try again later.'
        );
      }
    })
    .catch((err) => {
      displayError(
        'It looks like there was an error while logging you out. Please try again later.'
      );
    });
};

export const updateAdmin = (adminObj: any) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, adminObj, 'PUT');
  // @ts-ignore
  return fetch(`${baseUrl}/adminuser/${adminObj.email}`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results.status === 200) {
        return results;
      }
      FourOhOneError(results);
      throw new Error(results.message);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const resetPassword = (email: string) => {
  const data = {
    email,
  };
  return fetch(`${baseUrl}/adminuser/resetpassword`, {
    mode: 'cors',
    body: JSON.stringify(data),
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.status !== 200) {
        FourOhOneError(data);
        throw new Error(data.message);
      }
      return data;
    })
    .catch((e) => {
      return Promise.reject(`${e}`);
    });
};

export const setPasswordToken = (email: string, password: string, token: string) => {
  const data = {
    email,
    password,
    authCode: token,
  };
  return fetch(`${baseUrl}/adminuser/password`, {
    mode: 'cors',
    body: JSON.stringify(data),
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((results) => {
      if (results.status !== 200) {
        FourOhOneError(results);
        CustomToast({
          type: 'error',
          message: results.message,
        });
        throw new Error(results.message);
      }
      return results;
    })
    .catch((e) => {
      return Promise.reject(`${e}`);
    });
};

export const addNewAdmin = (adminObj: any) => {
  const { adminEmail, adminToken, systemId } = getCredentials();

  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, adminObj, 'Post');
  //@ts-ignore
  return fetch(`${baseUrl}/adminuser`, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status !== 200) {
        FourOhOneError(results);
        throw new Error(results.message);
      }
      return results;
    })
    .catch((e) => {
      return Promise.reject(`${e}`);
    });
};

export const authCheck = () => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);
  // @ts-ignore
  return fetch(`${baseUrl}/adminuser/authcheck`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      return response.status === 200;
    });
};
