import {
  API_TICKET_SUCCESS,
  API_COMPLETED_TASK_SUCCESS,
  API_TASK_SUCCESS,
  TICKETS_LOADING,
  TICKET_DETAIL_LOADED,
  COMPLETED_TASKS_LOADING,
  COMPLETED_TASK_DETAIL_LOADED,
  TASKS_LOADING,
  SET_DETAILED_TICKET,
  SET_DETAILED_TASK,
} from '../constants';

const initialState = {
  tickets: [],
  completedTasks: [],
  tasks: [],
  ticketsLoaded: false,
  ticketDetailLoaded: false,
  completedTasksLoaded: false,
  completedTaskDetailLoaded: false,
  tasksLoaded: false,
  detailedTicket: {
    id: null,
    created_on: null,
    canceled_on: null,
    due_on: null,
    completed_on: null,
    description: '',
    source_type: null,
    assignee: null,
    assignee_name: null,
    reporter: null,
    reporter_name: null,
    priority: null,
    communication_log: [],
    maintenance_task_id: null,
    system_id: null,
    hardware_id: null,
    error_report_id: null,
    module_id: null,
    task_name: null,
    task_description: null,
    completed_task: null,
    hardware_number: null,
    parents: [],
    children: [],
  },
  detailedTask: {
    // completed task
    id: null,
    created_on: null,
    notes: '',
    admin_user_id: null,
    admin_name: null,
    maintenance_ticket_id: null,
    maintenance_task_id: null,
    system_id: null,
    hardware_id: null,
    task_name: null,
    task_description: null,
    module_id: null,
    hardware_number: null,
  },
};

export default function maintenanceReducer (state = initialState, action) {
  const newState = { ...state };

  switch (action.type) {
    case API_TICKET_SUCCESS:
      newState.tickets = action.tickets;
      newState.ticketsLoaded = true;
      break;

    case API_COMPLETED_TASK_SUCCESS:
      newState.completedTasks = action.tasks;
      newState.completedTasksLoaded = true;
      break;

    case API_TASK_SUCCESS:
      newState.tasks = action.tasks;
      newState.tasksLoaded = true;
      break;

    case TICKETS_LOADING:
      newState.ticketsLoaded = action.loaded;
      break;

    case TICKET_DETAIL_LOADED:
      newState.ticketDetailLoaded = action.loaded;
      break;

    case COMPLETED_TASKS_LOADING:
      newState.completedTasksLoaded = action.loaded;
      break;

    case COMPLETED_TASK_DETAIL_LOADED:
      newState.completedTaskDetailLoaded = action.loaded;
      break;

    case TASKS_LOADING:
      newState.tasksLoaded = action.loaded;
      break;

    case SET_DETAILED_TICKET:
      newState.detailedTicket = action.ticket;
      newState.ticketDetailLoaded = true;
      break;

    case SET_DETAILED_TASK:
      newState.detailedTask = action.task;
      newState.completedTaskDetailLoaded = true;
      break;

    default:
      return state;
  }

  return newState;
}
