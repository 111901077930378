import React, { useState } from 'react';
import { assignmentColumnsV2 } from '../../../tableColumns/hardwareColumns';
import { history } from '../../../utils';
import { useParams } from 'react-router-dom';
import { IHardware, IHardwareChild } from '../../../ts/interface/hardware.interface';
import MovaticCustomModal from '../../../components/Modal/MovaticCustomModal';
import { PaginationTable } from '../../../components/Redesing/table/pagination-table';
import { IColumn } from '../../../ts/interface/components/redesing/table.interface';
import CardView from '../../../components/Redesing/card-view';
import AddIcon from '@mui/icons-material/Add';
import { removeAssignment } from '../../../api/hardware';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import CustomAsyncToast from '../../../components/Redesing/custom-async-toast';
import CloseIcon from '@mui/icons-material/Close';

const HardwareAssignment = ({ hardware }: { hardware: IHardware }) => {
  const { type, hardwareId } = useParams<{ type: string; hardwareId: string }>();
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [parent, setParent] = React.useState('');
  const [child, setChild] = React.useState('');

  const [search, setSearch] = React.useState('');
  const [pageNumber, setPageNumber] = React.useState(0);
  const [sizePerPage, setSizePerPage] = React.useState(5);
  const [sortData, setSortData] = useState<string>('removed_on');

  const data = [...(hardware?.parents || []), ...(hardware?.children || [])].map(
    (hardware) => ({
      ...hardware,
      hardware_type_name:
        hardware?.child_hardware_type_name || hardware?.parent_hardware_type_name,
    })
  );

  const goToHardware = (row: IHardwareChild) => {
    const typeName = row.hardware_type_name;
    if (row.parent_id === hardwareId) {
      history.push(`/hardware/${typeName}/${row.child_id}`);
    } else {
      history.push(`/hardware/${typeName}/${row.parent_id}`);
    }
  };

  return (
    <>
      <CardView
        documentationLink={
          'https://documentation.movatic.co/admin-site/modes/assignments'
        }
        headerActions={
          <IconButtonMenu
            buttons={[
              {
                startIcon: <AddIcon />,
                label: 'Add',
                onClick: () =>
                  history.push(`/hardware/${type}/${hardware?.gid}/add-assignment`),
              },
            ]}
          />
        }
        content={
          <>
            <PaginationTable
              onClickReturnRow
              onRowCLick={(id: IHardwareChild) => goToHardware(id)}
              sortDirection={'desc'}
              sortBy={sortData}
              onSortChange={(sort) => setSortData(sort.key)}
              handleSearch={(search) => setSearch(search.target.value)}
              searchText={search}
              showFilter={false}
              columns={
                assignmentColumnsV2(
                  hardware?.[hardware?.primary_identifier],
                  (row: { child: boolean; gid: string }) => {
                    const parentId = row.child ? hardware?.gid : row.gid;
                    const childId = row.child ? row.gid : hardware?.gid;
                    setParent(parentId);
                    setChild(childId);
                    setOpenConfirm(!openConfirm);
                  },
                  goToHardware
                ) as IColumn[]
              }
              items={data || []}
              onPageChange={(page, pageN) => {
                setPageNumber(pageN);
              }}
              onRowsPerPageChange={(row) => {
                const pageSize = Number(row.target.value);
                setSizePerPage(pageSize);
              }}
              page={pageNumber}
              rowsPerPage={sizePerPage}
              searchPlaceholder={'Search Assignments'}
              noDataText={`No assignment history for this ${hardware?.hardware_type_name}`}
            />
            {openConfirm && (
              <MovaticCustomModal
                open={openConfirm}
                onClose={() => setOpenConfirm(!openConfirm)}
                content={<div>Are you sure you want to remove this assignment?</div>}
                title={'Remove Assignment'}
                customStyles={{
                  title: {
                    p: 2,
                  },
                  action: {
                    p: 2,
                  },
                  content: {
                    p: 2,
                  },
                }}
                action={
                  <>
                    <IconButtonMenu
                      buttons={[
                        {
                          label: 'Cancel',
                          onClick: () => setOpenConfirm(!openConfirm),
                          startIcon: <CloseIcon />,
                        },
                        {
                          label: 'Submit',
                          onClick: () => {
                            CustomAsyncToast({
                              promise: () =>
                                removeAssignment({
                                  hardware_parent_id: parent,
                                  hardware_child_id: child,
                                }),
                              successMessage: () => 'Assignment removed successfully!',
                              errorMessage:
                                "Oops! We couldn't remove the assignment. Please try again.",
                              loadingMessage: 'Removing Assigment...',
                            }).then((r) => {
                              if (r) setOpenConfirm(!openConfirm);
                            });
                          },
                          startIcon: <AddIcon />,
                        },
                      ]}
                    />
                  </>
                }
              />
            )}
          </>
        }
        title={'Assignments'}
      />
    </>
  );
};

export default HardwareAssignment;
