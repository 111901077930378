import {
  baseUrl,
  createQueryParameters,
  fetchOptions,
  getCredentials,
  fetchPostOptions,
} from './http';
import bugsnagClient from '../components/bugsnag';
import { FourOhOneError } from '../actionCreators/system';
import CustomToast from '../components/Redesing/custom-toast';

export const getMaintenanceTickets = (parameters: {
  page: number;
  limit: number;
  status: [string];
  tags: string;
  sort_by: string;
  sort_order: string;
  pattern: string;
}) => {
  const queryParametersObject = {
    page: parameters?.page || 0,
    items_per_page: parameters?.limit,
    status: parameters?.status,
    tags: parameters?.tags,
    sort_by: parameters?.sort_by,
    sort_order: parameters?.sort_order,
    pattern: parameters?.pattern,
  };
  const queryParameters = createQueryParameters(queryParametersObject);
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);
  //@ts-ignore
  return fetch(`${baseUrl}/maintenance/tickets/v2?${queryParameters}`, fetchOps)
    .then((response) => response.json())
    .then((res) => {
      if (res.status === 200) {
        return res.message;
      }
      FourOhOneError(res);
      throw Error(res?.message);
    })
    .catch((e) => {
      bugsnagClient.notify(e);
      console.error(e);
    });
};

export const getTags = async () => {
  try {
    const { adminEmail, adminToken, systemId, systemGid } = getCredentials();
    const fetchOps = fetchOptions(adminEmail, adminToken, systemId);
    const queryParametersObject = {
      system_id: systemGid,
    };
    const queryParameters = createQueryParameters(queryParametersObject);

    const response = await fetch(
      `${baseUrl}/maintenance/ticket/tags?${queryParameters}`,
      // @ts-ignore
      fetchOps
    );
    const res = await response.json();
    if (res.status === 200) {
      return res.message;
    }
    FourOhOneError(res);
    throw new Error(res?.message);
  } catch (e) {
    CustomToast({
      type: 'error',
      message: `Oops! An error has occurred while loading tags. Please try again later. ${e}`,
      duration: 5000,
    });
  }
};

export const addTag = async (tag: string) => {
  try {
    const { adminEmail, adminToken, systemId } = getCredentials();
    const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, tag, 'POST');
    // @ts-ignore
    const reponse = await fetch(`${baseUrl}/maintenance/ticket/tags`, fetchOps);
    const res = await reponse.json();
    if (res.status === 200) {
      return res.message;
    }
    FourOhOneError(res);
    throw new Error(res?.message);
  } catch (e) {
    return Promise.reject(
      `Oops! An error has occurred while creating a tag. Please try again later. ${e}`
    );
  }
};

export const archiveTag = async (tagId: string, payload: { archived: boolean }) => {
  try {
    const { adminEmail, adminToken, systemId } = getCredentials();
    const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, payload, 'PUT');
    // @ts-ignore
    const response = await fetch(`${baseUrl}/maintenance/ticket/tags/${tagId}`, fetchOps);
    const res = await response.json();
    if (res.status === 200) {
      return res.message;
    }
    FourOhOneError(res);
    throw new Error(res?.message);
  } catch (e) {
    return Promise.reject(
      `Oops! An error has occurred while archiving the tag. Please try again later. ${e}`
    );
  }
};

export const addTagsToMaintenanceTicket = async (
  ticketId: string,
  tags: { tags: string[] }
) => {
  try {
    const { adminEmail, adminToken, systemId } = getCredentials();
    const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, tags, 'PUT');
    const response = await fetch(
      `${baseUrl}/maintenance/ticket/update/tags/${ticketId}`,
      // @ts-ignore
      fetchOps
    );
    const res = await response.json();
    if (res.status === 200) {
      return res.message;
    }
    FourOhOneError(res);
    throw new Error(res?.message);
  } catch (e) {
    return Promise.reject(
      `Oops! An error has occurred while Add a tag to this ticket. Please try again later. ${e}`
    );
  }
};
