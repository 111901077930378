import React, { useMemo, useReducer } from 'react';
import { connect } from 'react-redux';
import { IAdmin } from '../../ts/interface/admins.interface';
import { ISystem } from '../../ts/interface/system.interface';
import { IDocument, IMembership, IPrice } from '../../ts/interface/membership.interface';
import {
  DAY_IN_SECONDS,
  history,
  MONTH_IN_SECONDS,
  WEEK_IN_SECONDS,
  YEAR_IN_SECONDS,
} from '../../utils';
import { addMembership, uploadImage } from '../../api/memberships';
import CheckBox from '../../components/CheckBox/CheckBox';
import { BRANDING_COLOR, DURATIONS, EMAIL_REGEX } from '../../constants';
import { ChromePicker } from 'react-color';
import MembershipPricingModal from './membershipPricing';
import useMembershipImages from '../../hooks/membership/query/useMembershipImages';
import { IImage } from '../../ts/interface/image.interface';
import PageContainer from '../../components/Redesing/page-container';
import CardView from '../../components/Redesing/card-view';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';
import { Stack } from '@mui/system';
import {
  MovaticFieldGroupCheck,
  MovaticFieldGroupDate,
  MovaticFieldGroupOutlinedInput,
  MovaticFieldGroupSelect,
  MovaticFieldGroupText,
  MovaticFieldMultiOptions,
} from '../../components/Redesing/movatic-field-group';
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';
import { tooltipClasses, TooltipProps, useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import FormItem from '../../components/Redesing/form-item';
import AddPricing from './assets/add-pricing';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';
import FileUpload from '../../components/Redesing/file-upload';
import CustomToast from '../../components/Redesing/custom-toast';
import DocumentsList from './assets/documents-list';
import AddDocumentModal from './assets/add-document-modal';
import EditDocumentModal from './assets/edit-document-modal';
import { v4 as uuidv4 } from 'uuid';
import NoFilePlaceHolder from '../../components/Redesing/no-file-place-holder';

const reducer = (state: IMembership, action: { type: string; value: any }) => {
  switch (action.type) {
    case 'SET_VALUE':
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

const AddMembership = ({
  system,
  systemLoaded,
}: {
  system: ISystem;
  systemLoaded: boolean;
}) => {
  const initialState = {
    logo: [],
    admin: false,
    email: false,
    requiredDocuments: false,
    requiredDocumentList: [],
    password: false,
    authorize: false,
    paid: false,
    nameValidationResult: null,
    nameValidationHelp: '',
    activeDate: new Date().toISOString(),
    archiveDate: null,
    invalid: false,
    passwordError: null,
    minimum_wallet_deposit: null,
    credit_card_hold: null,
    priority: 5,
    hidden: false,
    branding_color: BRANDING_COLOR,
    max_rentals: '',
    perk: {
      free_time: null,
      discount: '',
      money_off: '',
      waive_base_rate: false,
    },
    prices: [],
    chipError: null,
    member: {
      id: null,
      system_id: null,
      active_on: new Date().toISOString(),
      archived_on: null,
      name: null,
      auth_type: null,
      valid_emails: [],
      hide_valid_emails: false,
      password: null,
      auth_expiration: null,
      logo_image_id: null,
      max_rentals: null,
    },
    selectedPricingTableRow: null,
    selectedDocumentTableRow: null,
    showAddPricingModal: false,
    showRequiredDocumentsModal: false,
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const theme = useTheme();
  const isDarkMode = useMemo(() => theme.palette.mode === 'dark', [theme.palette.mode]);
  const { data: membershipImages } = useMembershipImages({
    enabled: systemLoaded,
  });
  /**
   * Checks the validation state of a given date.
   * @param {string} date - The date string to validate.
   * @returns {string|null} - The validation state: 'error' if the date is in the past, otherwise null.
   */
  const getDateValidationState = (date: string) => {
    if (date && new Date(date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
      // The date is in the past, so it is considered invalid
      return 'error';
    }
    // The date is valid (current date or future date)
    return null;
  };

  const addPricingModalSubmit = (addedPrice: []) => {
    dispatch({
      type: 'SET_VALUE',
      value: {
        prices: [...state.prices, { ...addedPrice, id: state.prices.length }],
        showAddPricingModal: false,
      },
    });
  };

  const addDocumentModalSubmit = (addedDocument: IDocument) => {
    const newArray = [...state.requiredDocumentList, { ...addedDocument, id: uuidv4() }];
    dispatch({
      type: 'SET_VALUE',
      value: {
        requiredDocumentList: newArray,
        showRequiredDocumentsModal: false,
      },
    });
  };

  const handleRemovePricing = (priceId: number) => {
    dispatch({
      type: 'SET_VALUE',
      value: {
        prices: state.prices.filter((price: { id: number }) => price.id !== priceId),
      },
    });
  };

  const handleRemoveDocument = (documentId: string) => {
    dispatch({
      type: 'SET_VALUE',
      value: {
        requiredDocumentList: state.requiredDocumentList.filter(
          (document: { id: string }) => document.id !== documentId
        ),
      },
    });
  };

  const handleFileUpload = (files: Blob[] | MediaSource[], rejected: string) => {
    if (rejected.length > 0) {
      CustomToast({
        message: 'That photo is too large',
        type: 'error',
      });
      return;
    }

    const images = files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    const image = new Image();
    image.src = images[0].preview;
    image.onload = () => {
      if (image.width !== 200 || image.height !== 200) {
        CustomToast({
          message: 'Your image is not 200px by 200px, please reformat and try again',
          type: 'error',
        });
        dispatch({
          type: 'SET_VALUE',
          value: {
            logo: [],
          },
        });
      } else {
        dispatch({
          type: 'SET_VALUE',
          value: {
            logo: images,
          },
        });
      }
    };
  };

  const handleChange = (newDomains: string[]) => {
    dispatch({
      type: 'SET_VALUE',
      value: { member: { ...state.member, valid_emails: newDomains } },
    });
  };

  const handleGenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const member = { ...state.member };
    const perk = { ...state.perk };

    // todo: clear auth expiration if paid is true
    if (event.target.name === 'paid') {
      if (event.target.checked) {
        member.auth_expiration = '';
      }
      dispatch({
        type: 'SET_VALUE',
        value: {
          paid: event.target.checked,
          member,
        },
      });
      return;
    }
    if (event.target.name === 'hide_valid_emails') {
      member.hide_valid_emails = event.target.checked;
      dispatch({
        type: 'SET_VALUE',
        value: {
          member,
        },
      });
      return;
    }
    if (event.target.name === 'hidden') {
      dispatch({
        type: 'SET_VALUE',
        value: {
          hidden: event.target.checked,
        },
      });
      return;
    }
    if (event.target.name === 'authorize') {
      if (event.target.checked) {
        dispatch({
          type: 'SET_VALUE',
          value: {
            authorize: event.target.checked,
          },
        });
      } else if (!event.target.checked) {
        member.auth_expiration = null;
        member.auth_type = null;
        member.valid_emails = [];
        member.hide_valid_emails = false;
        member.password = null;
        dispatch({
          type: 'SET_VALUE',
          value: {
            authorize: event.target.checked,
            admin: event.target.checked,
            email: event.target.checked,
            password: event.target.checked,
            member,
          },
        });
      }
      return;
    }
    if (event.target.name === 'admin') {
      member.auth_expiration = null;
      member.auth_type = 'admin';
      dispatch({
        type: 'SET_VALUE',
        value: {
          admin: event.target.checked,
          password: false,
          email: false,
          member,
        },
      });
      return;
    }
    if (event.target.name === 'email') {
      member.auth_expiration = null;
      member.auth_type = 'email';
      dispatch({
        type: 'SET_VALUE',
        value: {
          email: event.target.checked,
          admin: false,
          password: false,
          member,
        },
      });
      return;
    }
    if (event.target.name === 'passwordForm') {
      member.auth_type = 'password';
      member.auth_expiration = null;
      dispatch({
        type: 'SET_VALUE',
        value: {
          password: event.target.checked,
          admin: false,
          email: false,
          member,
        },
      });
      return;
    }
    if (event.target.name === 'discount') {
      perk.discount = event.target.value;
      dispatch({
        type: 'SET_VALUE',
        value: {
          perk,
        },
      });
      return;
    }
    if (event.target.name === 'max_rentals') {
      dispatch({
        type: 'SET_VALUE',
        value: {
          max_rentals: event.target.value,
        },
      });
      return;
    }
    if (event.target.name === 'money_off') {
      perk.money_off = event.target.value;
      dispatch({
        type: 'SET_VALUE',
        value: {
          perk,
        },
      });
      return;
    }
    if (event.target.name === 'waive_base_rate') {
      perk.waive_base_rate = event.target.checked;
      dispatch({
        type: 'SET_VALUE',
        value: {
          perk,
        },
      });
      return;
    }
    if (event.target.id === 'free_time') {
      if (event.target.value !== 'None') {
        perk.free_time = event.target.value;
        dispatch({
          type: 'SET_VALUE',
          value: {
            perk,
          },
        });
      } else {
        perk.free_time = null;
        dispatch({
          type: 'SET_VALUE',
          value: {
            perk,
          },
        });
      }
      return;
    }
    if (
      event.target.name === 'minimum_wallet_deposit' ||
      event.target.name === 'credit_card_hold'
    ) {
      dispatch({
        type: 'SET_VALUE',
        value: {
          [event.target.name]: event.target.value,
        },
      });

      return;
    }

    if (event.target.name === 'priority') {
      dispatch({
        type: 'SET_VALUE',
        value: {
          priority: event.target.value,
        },
      });
      return;
    }

    member[event.target.name ? event.target.name : event.target.id] =
      event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    dispatch({
      type: 'SET_VALUE',
      value: {
        member,
      },
    });
  };

  const canSubmit = () => {
    let canSubmit = true;
    const { name, auth_type, valid_emails, password } = state.member;
    const { money_off, discount } = state.perk;
    const {
      paid,
      authorize,
      credit_card_hold,
      minimum_wallet_deposit,
      prices,
      priority,
      max_rentals,
    } = state;

    if (state.member.name === '' || state.member.name === null) {
      dispatch({
        type: 'SET_VALUE',
        value: {
          nameValidationResult: 'error',
          nameValidationHelp: 'A name is required to create a membership',
        },
      });
      canSubmit = false;
    }
    if (authorize && !(state.admin || state.email || state.password)) {
      canSubmit = false;
      CustomToast({
        message: 'You must select an authorization type',
        type: 'error',
      });
    }
    if (authorize && auth_type === 'email' && !valid_emails) {
      canSubmit = false;
      CustomToast({
        message:
          'At least one valid email address must be entered to save this membership',
        type: 'error',
      });
    }

    // Check that the email domains are valid
    if (authorize && auth_type === 'email' && valid_emails) {
      const isValid = valid_emails.every((email: string) => EMAIL_REGEX.test(email));

      if (!isValid) {
        canSubmit = false;
        CustomToast({
          message: 'Please enter a valid email domain',
          type: 'error',
        });
      }
    }

    if (auth_type === 'password' && password == null) {
      canSubmit = false;
      CustomToast({
        message: 'Please enter a password to save this membership',
        type: 'error',
      });
    }
    if ((paid && !prices.length) || !name) {
      canSubmit = false;
      CustomToast({
        message:
          'Make sure that you have filled in the required fields (i.e. Membership Name, added at least one pricing for paid memberships) to submit the form.',
        type: 'error',
      });
    }
    if (!paid && !authorize) {
      canSubmit = false;
      CustomToast({
        message:
          'Membership must be paid or use an authorization type in order to be created',
        type: 'error',
      });
    }
    // TODO: check that perk values are sane (discount and money off)
    if (discount) {
      if (discount < 0) {
        canSubmit = false;
        CustomToast({
          message: 'Discount cannot be less than 0',
          type: 'error',
        });
      }
      if (discount > 100) {
        canSubmit = false;
        CustomToast({
          message: 'Discount cannot be greater 100',
          type: 'error',
        });
      }
    }
    if (money_off && money_off < 0) {
      canSubmit = false;
      CustomToast({
        message: 'Money off cannot be less than 0',
        type: 'error',
      });
    }

    if (minimum_wallet_deposit && Math.round(minimum_wallet_deposit * 100) < 0) {
      canSubmit = false;
      CustomToast({
        message:
          'Please use a positive number (or leave empty) for the minimum wallet balance value.',
        type: 'error',
      });
    }

    if (credit_card_hold && Math.round(credit_card_hold * 100) < 0) {
      canSubmit = false;
      CustomToast({
        message:
          'Please use a positive number (or leave empty) for the credit card hold value.',
        type: 'error',
      });
    }

    if (
      !Number.isInteger(parseInt(priority, 10)) ||
      parseInt(priority, 10) < 1 ||
      parseInt(priority, 10) > 10
    ) {
      canSubmit = false;
      CustomToast({
        message: 'Priority must be an integer value from 1 to 10.',
        type: 'error',
      });
    }

    if (
      max_rentals &&
      (!Number.isInteger(parseInt(max_rentals, 10)) || parseInt(max_rentals, 10)) < 1
    ) {
      canSubmit = false;
      CustomToast({
        message: 'Max rentals must be empty or a positive integer.',
        type: 'error',
      });
    }

    return canSubmit;
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const member = { ...state.member };
    member.name = e.target.value;
    dispatch({
      type: 'SET_VALUE',
      value: {
        member,
        nameValidationResult: null,
        nameValidationHelp: '',
      },
    });
  };

  const handleColorChange = (colorEvent: { hex: string }) => {
    dispatch({
      type: 'SET_VALUE',
      value: {
        branding_color: colorEvent.hex,
      },
    });
  };

  const saveMembership = async () => {
    if (canSubmit()) {
      let formData;
      if (state.logo.length > 0) {
        formData = new FormData();
        formData.append('membershipLogoFile', state.logo[0]);
      }

      const member = { ...state.member };
      let perk = { ...state.perk };

      if (perk.discount || perk.money_off || perk.free_time || perk.waive_base_rate) {
        perk.active_on = new Date(state.member.active_on).toISOString();
        perk.free_time = Number(perk.free_time);
        perk.discount = Number(perk.discount);
        perk.money_off = Math.round(perk.money_off * 100);
      } else {
        perk = {
          free_time: null,
          discount: null,
          money_off: null,
          waive_base_rate: false,
        };
      }

      if (state.paid) {
        member.auth_expiration = null;
      }

      if (!member.auth_expiration || member.auth_expiration === 'No Expiration') {
        member.auth_expiration = null;
      } else {
        member.auth_expiration = parseInt(member.auth_expiration, 10);
      }

      member.valid_emails = member.valid_emails.length > 0 ? member.valid_emails : [];

      const getPriceInterval = (price: IPrice) => {
        if (price.pricingType === 'interval') {
          let units;

          switch (price.intervalUnits) {
            case 'days':
              units = DAY_IN_SECONDS;
              break;
            case 'weeks':
              units = WEEK_IN_SECONDS;
              break;
            case 'months':
              units = MONTH_IN_SECONDS;
              break;
            case 'years':
              units = YEAR_IN_SECONDS;
              break;
            default:
              units = 1;
          }

          return Math.round(price.intervalValue * units);
        }

        return null;
      };

      member.prices = state.prices.map((price: IPrice) => {
        if (price.pricingType === 'interval') {
          return {
            price: price.price,
            priority: price.priority,
            auto_renews: !!price.autoRenew,
            interval: getPriceInterval(price),
          };
        }

        return {
          price: price.price,
          priority: price.priority,
          starts_on: price.starts,
          ends_on: price.ends,
        };
      });
      member.active_on = new Date(state.member.active_on).toISOString();
      member.archived_on = state.member.archived_on
        ? new Date(state.member.archived_on).toISOString()
        : null;
      member.name = member.name.trim();
      member.formatted_name = member.name.toLowerCase().replace(/\s/g, '');
      member.branding_color = state.branding_color;
      member.perks = [perk];
      member.system_id = system.gid;
      member.minimum_wallet_deposit = state.minimum_wallet_deposit
        ? Math.round(state.minimum_wallet_deposit * 100)
        : null;
      member.credit_card_hold = state.credit_card_hold
        ? Math.round(state.credit_card_hold * 100)
        : null;

      member.hidden = state.hidden;
      member.priority = parseInt(state.priority, 10);
      member.max_rentals = state.max_rentals ? parseInt(state.max_rentals, 10) : null;

      // remove keys with null values from object
      Object.entries(member).forEach(([key, value]) => {
        if (value === null) delete member[key];
      });

      let logoImageId: null | string = null;

      if (formData) {
        logoImageId = await uploadImage(formData).then((response) => response.gid);
        // If logoImageId is falsy then the upload failed
        if (!logoImageId) return;
      }
      if (state.requiredDocumentList.length > 0) {
        member.data = {
          docs: state.requiredDocumentList.map((item: IDocument) => {
            return {
              name: item.name,
              description: item.description,
              required: item.required,
            };
          }),
        };
      }

      CustomAsyncToast({
        promise: () => addMembership({ ...member, logo_image_id: logoImageId }),
        successMessage: () => 'Membership added successfully!',
        errorMessage: 'Error adding membership',
        loadingMessage: 'Adding membership...',
      }).then((response) => {
        if (response) {
          history.push('/memberships');
        }
      });
    }
  };

  const activeDateChange = (value: Date) => {
    const member = { ...state.member };
    if (!isNaN(value.getTime())) {
      member.active_on = value.toISOString();
      dispatch({
        type: 'SET_VALUE',
        value: {
          activeDate: value.toISOString(),
          member,
        },
      });
    }
  };

  const archiveDateChange = (value: Date) => {
    const now = new Date();
    const member = { ...state.member };
    if (!isNaN(value.getTime())) {
      member.archived_on = value.toISOString();
      dispatch({
        type: 'SET_VALUE',
        value: {
          archiveDate: now.toISOString(),
          member,
        },
      });
    }
  };

  const editPricingModalSubmit = (editedPrice: IPrice) => {
    dispatch({
      type: 'SET_VALUE',
      value: {
        prices: state.prices.map((price: IPrice) =>
          price.id === editedPrice.id ? editedPrice : price
        ),
        selectedPricingTableRow: null,
      },
    });
  };

  const editDocumentModalSubmit = (editedDocument: IDocument) => {
    const newDocs = state.requiredDocumentList.map((document: IDocument) => {
      if (document.id === editedDocument.id) {
        return editedDocument;
      }
      return document;
    });
    dispatch({
      type: 'SET_VALUE',
      value: {
        requiredDocumentList: newDocs,
        selectedDocumentTableRow: null,
      },
    });
  };

  const closeEditPricingModal = () => {
    dispatch({
      type: 'SET_VALUE',
      value: {
        selectedPricingTableRow: null,
      },
    });
  };

  const closeEditDocumentModal = () => {
    dispatch({
      type: 'SET_VALUE',
      value: {
        selectedDocumentTableRow: null,
      },
    });
  };

  const closeAddPricingModal = () => {
    dispatch({
      type: 'SET_VALUE',
      value: {
        showAddPricingModal: false,
      },
    });
  };

  const handlePricesTableRowClick = (id: Number) => {
    dispatch({
      type: 'SET_VALUE',
      value: {
        selectedPricingTableRow: state.prices.find(
          (price: IPrice) => price.id === id.toString()
        ),
      },
    });
  };

  const handleDocumentsTableRowClick = (id: string) => {
    dispatch({
      type: 'SET_VALUE',
      value: {
        selectedDocumentTableRow: state.requiredDocumentList.find(
          (document: IDocument) => document.id === id
        ),
      },
    });
  };

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: '12px',
      border: '1px solid #dadde9',
    },
  }));

  const formToShow = () => {
    const firstForm = () => {
      if (state.email) {
        return (
          <>
            <MovaticFieldMultiOptions
              data-id="valid_emails"
              dataId={`accepted-email-domains`}
              label="Accepted Email Domain(s)"
              description={`Enter the domains you wish to allow, starting each with the "@" symbol (e.g., "@gmail.com", "@movatic.co"). You can save each domain by pressing the "Enter" key or typing a comma (",").`}
              groupValues={state.member.valid_emails}
              onChangeInput={handleChange}
              errorMessage={state.chipError || ''}
            />

            <MovaticFieldGroupCheck
              label="Hide Accepted Email Domain(s)"
              description="Check this box if the accepted email domain(s) should not be visible to users in the app"
              id="hide_valid_emails"
              name="hide_valid_emails"
              onChange={(event) => {
                dispatch({
                  type: 'SET_VALUE',
                  value: {
                    member: {
                      ...state.member,
                      hide_valid_emails: !state.member.hide_valid_emails,
                    },
                  },
                });
              }}
              checked={state.member.hide_valid_emails}
            />
          </>
        );
      }
      if (state.password) {
        return (
          <Stack pt={2}>
            <MovaticFieldGroupText
              id="password"
              name={'password'}
              label="Password"
              description="Users will enter the following password to gain access to this membership."
              value={state.member.password}
              onChange={(event) => {
                const member = { ...state.member };
                member.auth_type = 'password';
                member.auth_expiration = null;
                member.password = event.target.value;
                dispatch({
                  type: 'SET_VALUE',
                  value: {
                    admin: false,
                    email: false,
                    member,
                  },
                });
              }}
            />
          </Stack>
        );
      }

      if (state.admin) {
        return (
          <>
            <MovaticFieldGroupCheck
              id="requiredDocuments"
              label="Required Documents"
              description="Check this box if you would like to require users to upload documents to use this membership"
              name="requiredDocuments"
              onChange={(event) => {
                dispatch({
                  type: 'SET_VALUE',
                  value: {
                    requiredDocuments: event.target.checked,
                  },
                });
              }}
              checked={state.requiredDocuments}
            />
            {state.requiredDocuments && (
              <Stack spacing={1} py={2}>
                <Typography gutterBottom variant="subtitle1">
                  Documents Required
                </Typography>
                <Typography color="text.secondary" variant="body2">
                  Add documents that users will be required to upload to use this
                  membership
                </Typography>
                <DocumentsList
                  handleDocumentsTableRowClick={handleDocumentsTableRowClick}
                  handleRemoveDocument={handleRemoveDocument}
                  documents={state.requiredDocumentList}
                  onAddDocument={() =>
                    dispatch({
                      type: 'SET_VALUE',
                      value: { showRequiredDocumentsModal: true },
                    })
                  }
                />
              </Stack>
            )}
          </>
        );
      }

      return null;
    };

    return (
      <div>
        {firstForm()}
        {state.paid ? null : (
          <MovaticFieldGroupSelect
            label="Authorization expiration"
            description="Set how long until the user has to get reauthorized for this membership (optional)"
            name={'auth_expiration'}
            optionNodes={[
              { value: '', label: 'No Expiration', key: 6 },
              { value: '86400', label: '1 Day', key: 1 },
              { value: '604800', label: '1 Week', key: 2 },
              { value: '2592000', label: '1 Month', key: 3 },
              { value: '7776000', label: '3 Months', key: 4 },
              { value: '31536000', label: '1 Year', key: 5 },
            ].map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
            value={state.member.auth_expiration || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const member = { ...state.member };
              member.auth_expiration = e.target.value;
              dispatch({
                type: 'SET_VALUE',
                value: {
                  member,
                },
              });
            }}
            id="auth_expiration"
          />
        )}
      </div>
    );
  };

  const content = () => {
    const systemUsesWallets = system.billing && system.wallet_required;

    const { active_on, archived_on, logo_image_id } = state.member;
    const replacedLogo = logo_image_id
      ? membershipImages.find((image: IImage) => image.id === logo_image_id)
      : null;
    const { free_time, discount, money_off, waive_base_rate } = state.perk;
    const durOptions: { value: number | null; name: string }[] = [];
    DURATIONS.map((duration) =>
      durOptions.push({ value: duration.seconds, name: duration.value })
    );

    return (
      <Stack px={2} pb={2}>
        {/* ACTIVE ON DATE */}
        <MovaticFieldGroupDate
          id="active_on"
          label="Active On"
          description="The date on which users will be able to see this membership"
          value={new Date(state.activeDate) || null}
          hasError={getDateValidationState(active_on) === 'error'}
          errorMessage={
            getDateValidationState(active_on) === 'error'
              ? 'Date must be in the future'
              : undefined
          }
          onChange={activeDateChange}
          minDate={new Date()}
        />

        {/* ARCHIVE ON DATE */}
        <MovaticFieldGroupDate
          id="archived_on"
          label="Archive On"
          description="The date on which this membership will stop being available"
          value={archived_on ? new Date(archived_on) : null}
          hasError={getDateValidationState(archived_on) === 'error'}
          errorMessage={
            getDateValidationState(archived_on) === 'error'
              ? 'Date must be in the future'
              : undefined
          }
          onChange={archiveDateChange}
          minDate={new Date()}
        />
        {/* MEMBERSHIP NAME */}
        <MovaticFieldGroupText
          id="name"
          name={'name'}
          label="Membership Name"
          description="Enter a name for this membership. It must be unique Movatic wide"
          hasError={state.nameValidationResult === 'error'}
          errorMessage={state.nameValidationHelp}
          value={state.member.name ? state.member.name : ''}
          onChange={handleNameChange}
        />
        {/* AUTHORIZATION REQUIRED */}
        <MovaticFieldGroupCheck
          label="Authorization Required"
          description="Check this box if authorization is required to use this membership"
          id="authorize"
          name="authorize"
          onChange={handleGenChange}
          checked={state.authorize}
        />
        {state.authorize ? (
          <FormItem
            label="Authorization Type"
            description="Select the type of authorization you'd like in order for members to use this system"
            content={
              <>
                <HtmlTooltip
                  placement={'left'}
                  title={
                    <React.Fragment>
                      <Typography align={'left'} variant={'body1'}>
                        Requires all users to be approved by a system administrator before
                        they can make rentals.
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <div style={{ maxHeight: '2rem' }}>
                    <CheckBox
                      checked={state.admin}
                      eventName={'admin'}
                      onChange={handleGenChange}
                      checkBoxLabel="Administrator Authorization"
                      checkBoxId="admin-auth"
                    />
                  </div>
                </HtmlTooltip>
                <HtmlTooltip
                  placement={'left'}
                  title={
                    <React.Fragment>
                      <Typography align={'left'} variant={'body1'}>
                        Users must have an email address ending in a specified domain. A
                        code will be sent to the users email to verify the account.
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <div style={{ maxHeight: '2rem' }}>
                    <CheckBox
                      checked={state.email}
                      eventName={'email'}
                      onChange={handleGenChange}
                      checkBoxLabel="Email Authorization"
                      checkBoxId="email-auth"
                    />
                  </div>
                </HtmlTooltip>
                <HtmlTooltip
                  placement={'left'}
                  title={
                    <React.Fragment>
                      <Typography align={'left'} variant={'body1'}>
                        Users must enter a predefined password before they can use the
                        system.
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <div style={{ maxHeight: '2rem' }}>
                    <CheckBox
                      checked={state.password}
                      eventName={'passwordForm'}
                      onChange={handleGenChange}
                      checkBoxLabel="Password Authorization"
                      checkBoxId="password-auth"
                    />
                  </div>
                </HtmlTooltip>
              </>
            }
          />
        ) : null}
        {state.authorize && (state.admin || state.password || state.email)
          ? formToShow()
          : null}
        {/* PAID MEMBERSHIPS */}
        <MovaticFieldGroupCheck
          id="paid"
          label="Paid Membership"
          description="Check this box if there is a cost for this membership"
          name="paid"
          onChange={handleGenChange}
          checked={state.paid}
        />

        {/* PRICES */}
        {state.paid ? (
          <Stack spacing={1} py={2}>
            <Typography gutterBottom variant="subtitle1">
              Pricing Options
            </Typography>
            <Typography color="text.secondary" variant="body2">
              The following prices will be available to a user to select from when
              purchasing a membership.
            </Typography>
            <AddPricing
              handlePricesTableRowClick={handlePricesTableRowClick}
              handleRemovePricing={handleRemovePricing}
              prices={state.prices}
              onAddPrice={() =>
                dispatch({ type: 'SET_VALUE', value: { showAddPricingModal: true } })
              }
            />
          </Stack>
        ) : null}
        {/* PAID MEMBERSHIPS */}

        {/* PERKS */}
        {system.billing ? (
          <div>
            {/* free_time */}
            <MovaticFieldGroupSelect
              label="Free Time"
              description="Users with this membership will receive the following amount of time free."
              name={'free_time'}
              optionNodes={durOptions.map((option, index) => (
                <MenuItem key={index} value={option.value || ''}>
                  {option.name}
                </MenuItem>
              ))}
              value={free_time || ''}
              onChange={(event: SelectChangeEvent) => {
                const perk = { ...state.perk };
                const value = Number(event.target.value);
                if (value !== 0) {
                  perk.free_time = value;
                  dispatch({
                    type: 'SET_VALUE',
                    value: {
                      perk,
                    },
                  });
                } else {
                  perk.free_time = null;
                  dispatch({
                    type: 'SET_VALUE',
                    value: {
                      perk,
                    },
                  });
                }
              }}
              id="free_time"
            />

            {/* discount */}
            <MovaticFieldGroupOutlinedInput
              type={'number'}
              label="Discount"
              description="Users with this membership will receive the following percentage off of all of their rentals."
              value={discount ?? ''}
              inputProps={{ max: 100, min: 0, step: 1 }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const perk = { ...state.perk };
                perk.discount = event.target.value;
                dispatch({
                  type: 'SET_VALUE',
                  value: {
                    perk,
                  },
                });
              }}
              id="discount"
              name={'discount'}
              addOn={'%'}
            />

            <MovaticFieldGroupOutlinedInput
              type={'number'}
              label="Money Off"
              description="Users with this membership will receive the following amount off of each of their rentals."
              value={money_off ?? ''}
              inputProps={{ max: 9999, min: 0, step: 0.25 }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const perk = { ...state.perk };
                perk.money_off = event.target.value;
                dispatch({
                  type: 'SET_VALUE',
                  value: {
                    perk,
                  },
                });
              }}
              id="money_off"
              name={'money_off'}
              addOn={'$'}
            />

            {/*Waive Base Rate*/}
            <MovaticFieldGroupCheck
              id="waive_base_rate"
              label={'Waive Base Rate'}
              description={
                'Check this box if the rental base rate (unlock fee) will not be charged for users.'
              }
              name="waive_base_rate"
              onChange={handleGenChange}
              checked={waive_base_rate}
            />
          </div>
        ) : null}

        {systemUsesWallets ? (
          <MovaticFieldGroupOutlinedInput
            type={'number'}
            label="Minimum Wallet Balance"
            description="The minimum wallet balance that users with this membership need to have in order to start a rental. If left blank, the value for the Rate will be used."
            value={state.minimum_wallet_deposit ?? ''}
            inputProps={{ min: 0, step: 0.25 }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              dispatch({
                type: 'SET_VALUE',
                value: {
                  minimum_wallet_deposit: event.target.value,
                },
              });
            }}
            id="minimum_wallet_deposit"
            name={'minimum_wallet_deposit'}
            addOn={'$'}
          />
        ) : null}

        {system.billing ? (
          <MovaticFieldGroupOutlinedInput
            type={'number'}
            label="Credit Card Hold"
            description="The credit card hold amount that users with this membership will be applied in order to start a rental. If left blank, the value for the Rate will be used."
            value={state.credit_card_hold ?? ''}
            inputProps={{ min: 0, step: 0.25 }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              dispatch({
                type: 'SET_VALUE',
                value: {
                  credit_card_hold: event.target.value,
                },
              });
            }}
            id="credit_card_hold"
            name={'credit_card_hold'}
            addOn={'$'}
          />
        ) : null}

        <MovaticFieldGroupOutlinedInput
          type={'number'}
          name="priority"
          id="priority"
          label="Priority"
          description="Determines display order in the app (highest goes first). Must be a value between 1 and 10."
          value={state.priority ?? ''}
          inputProps={{ step: 1, min: 1, max: 10 }}
          onChange={(event) =>
            dispatch({
              type: 'SET_VALUE',
              value: {
                priority: event.target.value,
              },
            })
          }
        />

        {/* MAX OPEN RENTALS */}
        <MovaticFieldGroupOutlinedInput
          type={'number'}
          name="max_rentals"
          id="max_rentals"
          label="Max Open Rentals"
          description="Maximum number of simultaneous rentals for a user when using this subscription."
          value={state.max_rentals ?? ''}
          inputProps={{ step: 1, min: 1, max: 10 }}
          onChange={(event) =>
            dispatch({
              type: 'SET_VALUE',
              value: {
                max_rentals: event.target.value,
              },
            })
          }
        />

        <MovaticFieldGroupCheck
          label="Hidden"
          description="Check this box if the membership should be hidden in the app."
          id="hidden"
          name="hidden"
          onChange={handleGenChange}
          checked={state.hidden}
        />

        {/* BRANDING COLORS */}
        <FormItem
          label="Branding Color"
          description="Branding color for the membership"
          content={
            <div className={isDarkMode ? 'dark-mode-chrome-picker' : 'none'}>
              <ChromePicker
                color={state.branding_color}
                onChangeComplete={(colorEvent) => handleColorChange(colorEvent)}
              />
            </div>
          }
        />

        {/* MEMBER BANNER */}
        <FormItem
          label="Logo"
          description="The logo image must be 200px by 200px and a .png"
          content={
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              pt={2}
            >
              <Stack>
                <span>Logo Preview</span>
                <div id="currentLogo">
                  {state.logo.length > 0 ? (
                    <img alt="" className="logoImg" src={state.logo[0].preview} />
                  ) : replacedLogo ? (
                    <img
                      alt=""
                      className="logoRadius"
                      src={`data:image/png;base64,${replacedLogo.image}`}
                    />
                  ) : (
                    <NoFilePlaceHolder />
                  )}
                  {/*
                    if member.logo_image_id (aka if a membership to replace was selected)
                      figure out what we do to display those
                      match id from membership to id of image
                      show it instead
                */}
                </div>
              </Stack>
              <Stack>
                <Typography>
                  <i
                    className="far fa-file-image  fieldHorizontalPadding paddingLeftClear"
                    aria-hidden="true"
                  />
                  Upload A Logo
                </Typography>
                {/* TODO: Put the dropzone component as a separate component */}
                <FileUpload
                  caption={
                    'The file must be a .png, 200px wide, 200px high, and less than 1MB'
                  }
                  onDrop={handleFileUpload}
                />
              </Stack>
            </Stack>
          }
        />

        {/* ADD PRICING MODAL */}
        <MembershipPricingModal
          showModal={state.showAddPricingModal}
          close={closeAddPricingModal}
          submit={addPricingModalSubmit}
        />

        <AddDocumentModal
          open={state.showRequiredDocumentsModal}
          onClose={() =>
            dispatch({ type: 'SET_VALUE', value: { showRequiredDocumentsModal: false } })
          }
          addDocumentModalSubmit={addDocumentModalSubmit}
        />

        <EditDocumentModal
          document={state.selectedDocumentTableRow}
          open={!!state.selectedDocumentTableRow}
          onClose={closeEditDocumentModal}
          addDocumentModalSubmit={editDocumentModalSubmit}
        />

        {/* EDIT PRICING MODAL */}
        <MembershipPricingModal
          showModal={!!state.selectedPricingTableRow}
          close={closeEditPricingModal}
          submit={editPricingModalSubmit}
          pricing={state.selectedPricingTableRow}
        />
      </Stack>
    );
  };

  return (
    <PageContainer isLoading={false}>
      <CardView
        headerActions={
          <IconButtonMenu
            buttons={[
              {
                label: 'Cancel',
                onClick: () => history.push('/memberships'),
                startIcon: <CloseIcon />,
              },
              {
                label: 'Save',
                onClick: saveMembership,
                startIcon: <SaveIcon />,
              },
            ]}
          />
        }
        content={content()}
        title={'Add'}
      />
    </PageContainer>
  );
};

const MembershipAddR = connect(
  (state: {
    admin: { admin: IAdmin };
    system: { isLoaded: boolean; current: ISystem };
  }) => ({
    auth: state.admin.admin,
    system: state.system.current,
    systemLoaded: state.system.isLoaded,
  }),
  () => ({})
)(AddMembership);

export default MembershipAddR;
