import React, { useMemo, useState } from 'react';
import { locationAccessColumnsV2 } from '../../../tableColumns/membershipColumns';
import { PaginationTable } from '../../../components/Redesing/table/pagination-table';
import { IMembershipPerk } from '../../../ts/interface/membership.interface';
import AddIcon from '@mui/icons-material/Add';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CardView from '../../../components/Redesing/card-view';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';

interface IMembershipPerksProps {
  locations: IMembershipPerk[];
  onAddClick: () => void;
}

const MembershipLocationAccess: React.FC<IMembershipPerksProps> = (props) => {
  const [searchRate, setSearchRate] = React.useState('');
  const [pageRate, setPageRate] = React.useState(0);
  const [rowsPerPageRate, setRowsPerPageRate] = React.useState(5);
  const [sortData, setSortData] = useState<string>('station_name');
  const [exportData, setExportData] = useState<boolean>(false);
  const columns = useMemo(() => {
    return locationAccessColumnsV2;
  }, []);
  return (
    <CardView
      content={
        <PaginationTable
          showFilter={false}
          sortBy={sortData}
          onSortChange={(sortBy) => setSortData(sortBy.key)}
          exportData={exportData}
          csvFileName={'Memberships_locations_access.csv'}
          onExport={() => setExportData(false)}
          items={props.locations || []}
          noDataText={'This membership does not grant access to any private locations.'}
          columns={columns}
          rowsPerPage={rowsPerPageRate}
          page={pageRate}
          onPageChange={(page, pageN: number) => setPageRate(pageN)}
          onRowsPerPageChange={(rowsPerPage) =>
            setRowsPerPageRate(Number(rowsPerPage.target.value))
          }
          searchText={searchRate}
          searchPlaceholder={'Search location access'}
          handleSearch={(search) => setSearchRate(search.target.value)}
        />
      }
      title={'Private Location Access'}
      headerActions={
        <IconButtonMenu
          buttons={[
            {
              label: 'Export',
              onClick: () => setExportData(true),
              startIcon: <FileDownloadIcon />,
            },
            {
              label: 'Add',
              onClick: props.onAddClick,
              startIcon: <AddIcon />,
              variant: 'contained',
            },
          ]}
        />
      }
    />
  );
};

export default MembershipLocationAccess;
