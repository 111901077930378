import store from './store';
import { getAllSystems } from './actionCreators/system';
import { dbLoaded, getCurrentAdmin, adminLoaded } from './actionCreators/admin';
import { findAccess, history } from './utils';

export const loadAdmin = (nextRouterState) => {
  const state = store.getState();
  if (state.admin.admin === null) {
    let { admin } = localStorage;
    const adminDate = new Date(localStorage.adminDate);
    const now = new Date();

    if (adminDate) {
      if (adminDate.setDate(adminDate.getDate() + 1) < now) {
        admin = null;
        localStorage.removeItem('admin');
        localStorage.removeItem('adminDate');
      } else {
        try {
          admin = JSON.parse(admin);
        } catch (e) {
          // bugsnagClient.notify(e)
          admin = null;
        }
      }
    }

    if (admin !== null && !window.location.href.includes('setpassword')) {
      const system = localStorage.systemId;
      if (typeof admin === 'string') admin = JSON.parse(admin);

      store
        .dispatch(getCurrentAdmin(admin.admin.email, admin.token, system))
        .then((response) => {
          const currentAdmin = response;

          if (currentAdmin && JSON.stringify(currentAdmin) !== JSON.stringify(admin)) {
            admin = currentAdmin;
            localStorage.setItem('admin', JSON.stringify(currentAdmin));
          }

          store.dispatch(adminLoaded(admin));
          store
            .dispatch(getAllSystems(admin))
            .then(() => {
              store.dispatch(dbLoaded(true));
            })
            .catch((e) => {
              // bugsnagClient.notify(e)
              history.push('/');
            });
        });
    }
  }
};

/**
 * Checks if the admin user has at least one of the given permissions.
 *
 * @param {Array} permissions - an array of permission strings
 */
export const checkPermissions = (permissions) => {
  const state = store.getState();
  const { systemAccess } = state.admin;
  const access = findAccess(systemAccess);

  // Return here if admin user has any of the required permissions
  if (permissions.some((permission) => access.includes(permission))) {
    return;
  }

  const showErrorAndRedirect = () => {
    // store.dispatch(
    //   setError('You do not have sufficient permissions to visit that page'),
    // );
    history.push('/');
  };

  // If systemAccess is null it probably means the store has not been fully updated.
  // We listen for store state changes until it stops being null
  if (systemAccess === null) {
    const unsubscribe = store.subscribe(() => {
      const newSystemAccess = store.getState().admin.systemAccess;

      if (newSystemAccess !== null) {
        unsubscribe();

        const newAccess = findAccess(newSystemAccess);

        if (!permissions.some((permission) => newAccess.includes(permission))) {
          // We send the user to the dashboard and display an error if the permission requirements have not been met
          showErrorAndRedirect();
        }
      }
    });

    return;
  }

  showErrorAndRedirect();
};
