import React, { Component } from 'react';
import { connect } from 'react-redux';

import { updateAdmin } from '../actionCreators/admin';
import MovaticCustomModal from './Modal/MovaticCustomModal';
import IconButtonMenu from './Redesing/icon-button-menu';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { PropertyList } from './Redesing/property-list';
import FormItem from './Redesing/form-item';
import { MovaticFieldGroupText } from './Redesing/movatic-field-group';
import { Stack } from '@mui/system';

class AboutMe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      firstName: this.props.admin.firstName,
      lastName: this.props.admin.lastName,
    };
    this.content = this.content.bind(this);
    this.submit = this.submit.bind(this);
  }

  submit() {
    if (this.state.edit) {
      const adminObject = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
      };

      if (Object.keys(adminObject).length) {
        adminObject.email = this.props.admin.email;
        this.props.updateAdmin(adminObject);
      }
      this.setState({
        edit: !this.state.edit,
      });
    } else {
      this.setState({
        edit: !this.state.edit,
      });
    }
  }

  content() {
    return this.state.edit ? (
      <Stack direction={'column'}>
        <MovaticFieldGroupText
          id="firstName"
          name="firstName"
          label="First Name"
          description={'Your first name'}
          value={this.state.firstName}
          onChange={(e) => this.setState({ firstName: e.target.value })}
        />
        <MovaticFieldGroupText
          id="lastName"
          name="lastName"
          label="Last Name"
          description={'Your last name'}
          value={this.state.lastName}
          onChange={(e) => this.setState({ lastName: e.target.value })}
        />
      </Stack>
    ) : (
      <PropertyList>
        <FormItem
          label={'Name'}
          content={`${this.props.admin.firstName} ${this.props.admin.lastName}`}
        />
        <>
          {!this.props.admin?.email?.includes('@movaticguest.co') && (
            <FormItem label={'Email'} content={this.props.admin.email} />
          )}
        </>
      </PropertyList>
    );
  }

  render() {
    const { showModal, handleClose } = this.props;

    return (
      <>
        <MovaticCustomModal
          open={showModal}
          onClose={() => {
            handleClose();
            this.setState({ edit: false });
          }}
          title="About me"
          content={this.content()}
          action={
            <IconButtonMenu
              buttons={[
                {
                  label: 'Close',
                  onClick: () => {
                    handleClose();
                    this.setState({ edit: false });
                  },
                  startIcon: <CloseIcon />,
                },
                {
                  label: this.state.edit ? 'Save' : 'Edit',
                  onClick: this.submit,
                  startIcon: this.state.edit ? <SaveIcon /> : <EditIcon />,
                },
              ]}
            />
          }
        />
      </>
    );
  }
}

export default connect(
  (state) => ({
    admin: state.admin.admin.admin,
  }),
  (dispatch) => ({
    updateAdmin(adminObject) {
      dispatch(updateAdmin(adminObject));
    },
  })
)(AboutMe);
