import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import FormHelperText from '@mui/material/FormHelperText';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { RouterLink } from '../../components/Redesing/router-link';
import { useMounted } from '../../hooks/use-mounted';
import { paths } from '../../paths';
import Box from '@mui/material/Box';
import { Logo } from '../../components/Redesing/logo';
import PageLoader from '../../components/Redesing/page-loader';
import AuthLayout from '../../layout/auth/auth-layout';

interface Values {
  email: string;
  password: string;
  submit: null;
}

const initialValues: Values = {
  email: '',
  password: '',
  submit: null,
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Must be a valid email')
    .max(255)
    .required('Email is required'),
  password: Yup.string().max(255).required('Password is required'),
});

const LoginForm = ({ login }: { login: Function }) => {
  const isMounted = useMounted();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values): Promise<void> => {
      login(values.email, values.password);
    },
  });

  if (!isMounted) return <PageLoader />;
  return (
    <AuthLayout>
      <Card elevation={16}>
        <CardHeader
          subheader={
            <Box display="flex" justifyContent="center" alignItems="center">
              <Logo />
            </Box>
          }
          sx={{ pb: 0 }}
          title=""
        />
        <CardContent>
          <form noValidate onSubmit={formik.handleSubmit}>
            <Stack spacing={3}>
              <TextField
                autoFocus
                error={!!(formik.touched.email && formik.errors.email)}
                fullWidth
                helperText={formik.touched.email && formik.errors.email}
                label="Email Address"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="email"
                value={formik.values.email}
              />
              <TextField
                error={!!(formik.touched.password && formik.errors.password)}
                fullWidth
                helperText={formik.touched.password && formik.errors.password}
                label="Password"
                name="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="password"
                value={formik.values.password}
              />
              <Link
                padding={0}
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                component={RouterLink}
                href={paths.auth.setPassword}
                underline="hover"
                variant="subtitle2"
              >
                Forgot your password?
              </Link>
            </Stack>
            {formik.errors.submit && (
              <FormHelperText error sx={{ mt: 3 }}>
                {formik.errors.submit as string}
              </FormHelperText>
            )}
            <Button
              disabled={formik.isSubmitting}
              fullWidth
              size="large"
              sx={{ mt: 2 }}
              type="submit"
              variant="contained"
            >
              Log In
            </Button>
            <Link
              target="_blank"
              rel="noreferrer"
              padding={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
              href={paths.termOfService}
              underline="hover"
              variant="subtitle1"
            >
              Terms of Services
            </Link>
          </form>
        </CardContent>
      </Card>
    </AuthLayout>
  );
};

export default LoginForm;
