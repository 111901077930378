import {
  CLEAR_MEMBERSHIP_DETAILS,
  MEMBERSHIP_IMAGES_LOADED,
  SET_DETAILED_MEMBERSHIP,
  SET_MEMBERSHIP_DETAILS,
  SET_MEMBERSHIP_IMAGES,
  SET_MEMBERSHIPS,
  SET_USER_MEMBERSHIPS,
  SET_USER_MEMBERSHIPS_LOADED,
  SET_USERS_FOR_MEMBERSHIPS,
} from '../constants';

const initialState = {
  memberships: [],
  userMemberships: [],
  userMembershipsLoaded: false,
  usersMembership: [],
  detailedMembership: {},
  isLoaded: false,
  membershipImages: [],
  membershipImagesLoaded: false,
  currentMembership: {
    id: null,
    system: null,
    monthly_cost: null,
    yearly_cost: null,
    created: null,
    active_on: null,
    archived: null,
    replacement_membership: null,
    name: null,
    auth_type: null,
    valid_emails: null,
    password: null,
    auth_expiration_period: null,
    auth_expiration_periods: null,
    banner_id: null,
  },
};

export default function membershipsReducer (state = initialState, action) {
  const newState = { ...state };
  switch (action.type) {
    case SET_MEMBERSHIPS:
      newState.memberships = action.memberships;
      newState.isLoaded = true;
      break;

    case SET_USER_MEMBERSHIPS:
      newState.userMemberships = action.userMemberships;
      break;

    case SET_USER_MEMBERSHIPS_LOADED:
      newState.userMembershipsLoaded = action.loaded;
      break;

    case SET_DETAILED_MEMBERSHIP:
      newState.detailedMembership = action.membership;
      break;

    case SET_MEMBERSHIP_DETAILS:
      newState.currentMembership = action.membership;
      newState.replace = true;
      break;

    case SET_MEMBERSHIP_IMAGES:
      newState.membershipImages = action.images;
      newState.membershipImagesLoaded = true;
      break;

    case MEMBERSHIP_IMAGES_LOADED:
      newState.membershipImagesLoaded = action.loaded;
      break;

    case CLEAR_MEMBERSHIP_DETAILS:
      newState.currentMembership = {
        id: null,
        system: null,
        monthly_cost: null,
        yearly_cost: null,
        created: null,
        active_on: null,
        archived: null,
        replacement_membership: null,
        name: null,
        auth_type: null,
        valid_emails: null,
        password: null,
        auth_expiration_period: null,
        auth_expiration_periods: null,
        banner_id: null,
      };
      newState.replace = false;
      break;

    case SET_USERS_FOR_MEMBERSHIPS:
      newState.usersMembership = [...action.users];
      break;

    default:
      return state;
  }
  return newState;
}
