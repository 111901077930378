import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import RentalResume from './assets/rentalResume';
import useRental from '../../hooks/rentals/query/useRental';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { ISystem } from '../../ts/interface/system.interface';
import { IMode } from '../../ts/interface/mode.interface';
import { changeTab, findAccess } from '../../utils';
import TransactionInformation from './assets/transactionInformation';
import { IAdmin, IAuth } from '../../ts/interface/admins.interface';
import { IRentalDetailProps } from '../../ts/interface/pages/rentals.interface';
import PageContainer from '../../components/Redesing/page-container';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import useRentalRoute from '../../hooks/rentals/query/useRentalRoute';
import { IRental, IRentalRoute } from '../../ts/interface/rental.interface';
import RentalMap from './assets/rentalMap';
import Card from '@mui/material/Card';
import RentalLogs from './assets/rental-logs';
import RentalSupport from './assets/rental-support';
import CardView from '../../components/Redesing/card-view';
import ImageLightBox from '../../components/ImageLightBox/ImageLightBox';
import PageContent from '../../components/Redesing/page-content';
import LockClockIcon from '@mui/icons-material/LockClock';
import RentalHardwareEventLogs from './assets/rental-hardware-event-logs';

const tabs = [
  { label: 'Details', value: 'details' },
  { label: 'Support', value: 'support' },
  { label: 'Unlock Logs', value: 'logs' },
  { label: 'Event Logs', value: 'hardware-logs' },
];

interface AccessPermissions {
  [key: string]: (value: boolean) => void;
}

const getStationCoords = (rental: IRental) => {
  const { end, start } = rental;
  if (!start.station?.id) return [];

  if (start.station?.id === end?.station?.id || !end?.station?.id) {
    // don't make two markers if locations are the same or if rental hasn't ended
    return [
      {
        id: start.station?.id,
        lat: start.station?.latitude,
        long: start.station?.longitude,
      },
    ];
  }

  return [
    {
      id: start.station?.id,
      lat: start.station?.latitude,
      long: start.station?.longitude,
    },
    {
      id: end.station?.id,
      lat: end.station?.latitude,
      long: end.station?.longitude,
    },
  ];
};

const RentalDetail = ({
  system,
  auth,
  modeInfo,
  systemAccess,
  admins,
  systemLoaded,
}: IRentalDetailProps) => {
  const { rentalId, currentTab: currentActiveTab = 'details' } = useParams<{
    rentalId: string;
    currentTab: string;
  }>();
  const [rentalAdminPermission, setRentalAdminPermission] = useState(false);
  const [refundAdminPermission, setRefundAdminPermission] = useState(false);
  const [movaticAccessPermission, setMovaticAccessPermission] = useState(false);
  const [transactionId, setTransactionId] = useState('');
  const [walletTransactionId, setWalletTransactionId] = useState('');
  const [holdTransactionId, setHoldTransactionId] = useState('');
  const [pathLoaded, setPathLoaded] = useState<boolean>(false);
  const [loadedRoutes, setLoadedRoutes] = useState<IRentalRoute[] | null>(null);
  const [currentTab, setCurrentTab] = useState<string>('details');
  const [lightBoxToggler, setLightBoxToggler] = useState<boolean>(false);

  useEffect(() => {
    if (currentActiveTab !== currentTab) {
      setCurrentTab(currentActiveTab);
    }
  }, [currentActiveTab, currentTab]);

  const handleTabsChange = useCallback(
    (event: ChangeEvent<any>, value: string): void => {
      changeTab(`${rentalId}/${value}`, currentTab, 'rentals');
    },
    [currentTab, rentalId]
  );

  const {
    data,
    isLoading,
    refetch: refetchRental,
  } = useRental(
    {
      queryParameters: {
        rental_id: rentalId,
      },
    },
    {
      enabled: systemLoaded,
    }
  );
  const rental = useMemo(() => data?.data?.[0] || {}, [data]);

  const { refetch } = useRentalRoute(
    rentalId,
    {
      enabled: !pathLoaded,
    },
    {
      paginationResultHandler: (res: { data: IRentalRoute[] }) => {
        setLoadedRoutes((sources) => {
          const { data } = res;

          if (!sources) {
            return data;
          }

          const newSources = [...sources];

          data.forEach((item: IRentalRoute) => {
            let index = newSources.findIndex(
              (source: IRentalRoute) => source.id === item.id
            );

            if (index >= 0) {
              newSources[index].route = newSources[index].route.concat(item.route);
            } else {
              newSources.push(item);
            }
          });

          return newSources;
        });
        setPathLoaded(true);
      },
    }
  );

  useEffect(() => {
    refetch().then(() => setPathLoaded(true));
  }, [refetch]);

  const stations = rental?.start ? getStationCoords(rental) : [];

  useEffect(() => {
    if (!rental) return;
    const transactionId = rental.billing?.transactions?.find(
      ({ type, source }: { type: string; source: string }) =>
        type === 'charge' && source === 'stripe'
    )?.id;
    const walletTransactionId = rental.billing?.transactions?.find(
      ({ type, source }: { type: string; source: string }) =>
        type === 'charge' && source === 'wallet'
    )?.old_id;
    const holdTransactionId = rental.billing?.transactions?.find(
      ({ type, source }: { type: string; source: string }) =>
        type === 'hold' && source === 'stripe'
    )?.id;

    setTransactionId(transactionId);
    setWalletTransactionId(walletTransactionId);
    setHoldTransactionId(holdTransactionId);
  }, [rental]);

  useEffect(() => {
    const accessPermissions: AccessPermissions = {
      rental: setRentalAdminPermission,
      refunds: setRefundAdminPermission,
      movaticAccess: setMovaticAccessPermission,
    };
    const adminAccess = findAccess(systemAccess);
    adminAccess.forEach((eachAccess) => {
      if (accessPermissions[eachAccess]) {
        accessPermissions[eachAccess](true);
      }
    });
  }, [systemAccess]);

  return (
    <PageContainer isLoading={isLoading}>
      <PageContent
        dataId="rental-details"
        tabsList={tabs}
        backLink={'/rentals'}
        detailIcon={<LockClockIcon />}
        contentTitle={'Rentals'}
        itemName={`Rental: ${rental?.user?.full_name}`}
        itemChipValue={rental?.id}
        chipLabel={'Rental ID'}
        currentTabV={currentTab}
        handleTabsChange={handleTabsChange}
        tabsChildren={
          <>
            {currentTab === 'details' && (
              <Grid container spacing={2}>
                <Grid xs={12} lg={4}>
                  <Stack spacing={2}>
                    <RentalResume
                      onRentalEnded={() => refetchRental()}
                      rental={rental}
                      system={system}
                      auth={auth}
                      rentalAdminPermission={rentalAdminPermission}
                      modeInfo={modeInfo}
                      systemAccess={systemAccess}
                      systemLoaded={systemLoaded}
                    />
                    {rental?.end?.image_url && (
                      <CardView
                        content={
                          <Stack data-id={'endRentalPhoto-item'} px={2} pb={2}>
                            <img
                              style={{
                                height: 'auto',
                                width: '100%',
                                maxWidth: '50em',
                                cursor: 'pointer',
                              }}
                              alt={'End Rental'}
                              src={rental?.end?.image_url || ''}
                              onClick={() => {
                                setLightBoxToggler(!lightBoxToggler);
                              }}
                            />
                            <ImageLightBox
                              imageUrl={rental?.end?.image_url || ''}
                              toggler={lightBoxToggler}
                            />
                          </Stack>
                        }
                        title={'End Rental Photo'}
                      />
                    )}
                  </Stack>
                </Grid>
                <Grid xs={12} lg={8}>
                  <Stack spacing={2}>
                    <Card>
                      <Stack padding={2}>
                        {pathLoaded && (
                          <RentalMap
                            systemId={system?.gid}
                            coordinatesLoaded={loadedRoutes}
                            hardwareHasGps={rental.hardware?.has_gps}
                            stations={stations || []}
                            systemType={modeInfo.details.hardware_type}
                            systemAccess={systemAccess}
                            rentalMarkerType={rental.type}
                            endStationId={
                              rental.end?.station.id || rental.start?.station?.id
                            }
                          />
                        )}
                      </Stack>
                    </Card>
                    {system.billing && (
                      <TransactionInformation
                        admins={admins}
                        walletTransactionId={walletTransactionId}
                        holdTransactionId={holdTransactionId}
                        transactionId={transactionId}
                        movaticAccess={movaticAccessPermission}
                        refundAdminPermission={refundAdminPermission}
                        rental={rental}
                      />
                    )}
                  </Stack>
                </Grid>
              </Grid>
            )}
            {currentTab === 'logs' && <RentalLogs unlocks={rental?.unlocks || []} />}
            {currentTab === 'support' && (
              <RentalSupport supportMessage={rental?.support_messages || []} />
            )}
            {currentTab === 'hardware-logs' && (
              <RentalHardwareEventLogs rental={rental} />
            )}
          </>
        }
      />
    </PageContainer>
  );
};

const RentalDetailsR = connect(
  (state: {
    system: { current: ISystem; isLoaded: boolean };
    mode: IMode;
    admin: { admin: IAuth; systemAccess: number; systemAdmins: IAdmin[] };
  }) => ({
    auth: state.admin.admin,
    modeInfo: state.mode,
    system: state.system.current,
    systemLoaded: state.system.isLoaded,
    systemAccess: state.admin.systemAccess,
    admins: state.admin.systemAdmins,
  }),
  () => ({})
)(RentalDetail);

export default RentalDetailsR;
