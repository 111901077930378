import React, { useEffect, useState } from 'react';
import { getLocaleDateTimeString, history } from '../../../utils';
import useStation from '../../../hooks/stations/query/useStation';
import { IStation } from '../../../ts/interface/station.interface';
import { IEndRentalProps } from '../../../ts/interface/pages/rentals.interface';
import FormItem from '../../../components/Redesing/form-item';
import {
  MovaticFieldGroupAsyncSelect,
  MovaticFieldGroupDate,
  MovaticFieldGroupOutlinedInput,
  MovaticFieldGroupSelect,
  MovaticFieldGroupText,
} from '../../../components/Redesing/movatic-field-group';
import MenuItem from '@mui/material/MenuItem';
import RadioButtons from '../../../components/RadioButtons/RadioButtons';
import { getTimeList } from '../../../util/utilsR';
import MovaticCustomModal from '../../../components/Modal/MovaticCustomModal';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import CustomAsyncToast from '../../../components/Redesing/custom-async-toast';
import { endRental } from '../../../api/rentals';
import { LocationStatus } from '../../../ts/enums';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

const END_TIME_EARLIER_ERROR =
  'The date and time combination is earlier than the start of the rental.';

interface EndRentalState {
  amount: string;
  amountError: string | null;
  adminModificationReason: string | null;
  adminModificationReasonError: string | null;
  voidReason: string | null;
  voidReasonError: string | null;
  dateError: string | null;
  endTimeType: string;
  startingTime: string;
  station: string | null;
  stationError: string | null;
  chargeType: string;
  endTime: number;
  activationDate: string;
  disabled: boolean;
  openConfirmationModal: boolean;
}

const EndRental = ({
  rental,
  showModal,
  onClose,
  round_trip,
  currentMode,
  systemLoaded,
  billing,
  stationTitle,
}: IEndRentalProps) => {
  const initialState = {
    activationDate: '',
    amount: '',
    amountError: null,
    adminModificationReason: '',
    adminModificationReasonError: null,
    voidReason: '',
    voidReasonError: null,
    dateError: null,
    disabled: false,
    endTime: 0,
    endTimeType: 'now',
    startingTime: rental?.start?.time,
    station: null,
    stationError: null,
    chargeType: 'standard',
    openConfirmationModal: false,
  };

  const [
    {
      activationDate,
      amount,
      amountError,
      adminModificationReason,
      adminModificationReasonError,
      voidReason,
      voidReasonError,
      dateError,
      endTimeType,
      startingTime,
      station,
      stationError,
      chargeType,
      endTime,
      disabled,
      openConfirmationModal,
    },
    setState,
  ] = useState<EndRentalState>(initialState as EndRentalState);
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState('');

  const { data, isLoading: isLoadingStations } = useStation(
    {
      enabled: systemLoaded,
    },
    { status: LocationStatus.active, pattern: search }
  );
  const stations = data?.data
    ? [{ value: 'null', name: `No ${stationTitle}` }, ...data?.data]
    : [{ value: '', name: `No ${stationTitle}` }];

  useEffect(() => {
    if (currentMode === 'bike_locker' || round_trip) {
      setState((prevState) => ({
        ...prevState,
        station: rental.start?.station?.id,
      }));
    }
  }, [currentMode, round_trip, rental]);

  const { hardware, start, user } = rental;

  const onTimeChange = (value: number) => {
    if (!activationDate || validateEndDate({ endTime: value, activationDate })) {
      setState((prevState) => ({
        ...prevState,
        dateError: null,
        endTime: value,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        dateError: END_TIME_EARLIER_ERROR,
        endTime: value,
      }));
    }
  };

  const dateChange = (value: string) => {
    const isValidEndDate = validateEndDate({
      endTime,
      activationDate: value,
    });

    if (isValidEndDate) {
      setState((prevState) => ({
        ...prevState,
        activationDate: value,
        dateError: null,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        activationDate: value,
        dateError: END_TIME_EARLIER_ERROR,
      }));
    }
  };

  const validateEndDate = ({
    endTime,
    activationDate,
  }: {
    endTime: number;
    activationDate: string;
  }) => {
    const startDateMil = new Date(startingTime).getTime();
    const endDateMil = new Date(activationDate).setHours(0, 0, 0, 0) + endTime * 1000;
    return endDateMil > startDateMil;
  };

  const canSubmit = () => {
    let canReturn = true;
    const stateUpdates = {} as EndRentalState;

    if (!station && rental?.hardware?.has_gps && round_trip) {
      canReturn = false;
      stateUpdates.stationError = 'End station is required';
    }

    if (
      chargeType === 'credit_or_debit' &&
      (!amount.length ||
        Number.isNaN(Number(amount)) ||
        Number(amount) < -9999 ||
        Number(amount) > 9999)
    ) {
      canReturn = false;
      stateUpdates.amountError = 'Please enter a valid amount between -9999 and 9999';
    }

    if (
      chargeType === 'set_subtotal' &&
      (!amount.length ||
        Number.isNaN(Number(amount)) ||
        Number(amount) < 0 ||
        Number(amount) > 9999)
    ) {
      canReturn = false;
      stateUpdates.amountError = 'Please enter a valid amount between 0 and 9999';
    }

    if (endTimeType === 'custom' && !activationDate) {
      canReturn = false;
      stateUpdates.dateError = 'Please enter a rental end date or select end now';
    } else if (
      endTimeType === 'custom' &&
      !validateEndDate({ endTime, activationDate })
    ) {
      canReturn = false;
      stateUpdates.dateError = END_TIME_EARLIER_ERROR;
    }

    if (
      ['set_subtotal', 'credit_or_debit'].includes(chargeType) &&
      !adminModificationReason
    ) {
      canReturn = false;
      stateUpdates.adminModificationReasonError = 'Adding a reason is required';
    }

    if (['void'].includes(chargeType) && !voidReason) {
      canReturn = false;
      stateUpdates.voidReasonError = 'Adding a reason is required';
    }

    if (Object.keys(stateUpdates).length) {
      setState((prevState) => ({ ...prevState, ...stateUpdates }));
    }

    return canReturn;
  };

  const getEndTime = () => {
    let endTimeBuffer = '';
    if (endTimeType === 'now') {
      endTimeBuffer = 'now';
    } else if (activationDate !== '') {
      const time = new Date(activationDate);
      const UTCTime = new Date(
        time.getUTCFullYear(),
        time.getUTCMonth(),
        time.getUTCDate()
      );
      endTimeBuffer = UTCTime.setSeconds(UTCTime.getSeconds() + endTime).toString();
    }
    return endTimeBuffer;
  };

  const endRentalCallback = () => {
    setState((prevState) => ({
      ...prevState,
      disabled: true,
    }));

    if (canSubmit()) {
      const endTime = getEndTime();
      const endRentalInfo = {
        admin_modification:
          chargeType === 'credit_or_debit' && amount ? Number(amount) * 100 : null,
        admin_modification_reason:
          (['set_subtotal', 'credit_or_debit'].includes(chargeType) &&
            adminModificationReason) ||
          null,
        admin_set_usage_amount:
          chargeType === 'set_subtotal' ? Number(amount) * 100 : null,
        no_charge: chargeType === 'void',
        no_charge_reason: chargeType === 'void' && voidReason ? voidReason : null,
        station_id: station && station !== 'No Location' ? station : null,
        type: 'admin',
        end_time:
          endTime === 'now'
            ? new Date().toISOString()
            : new Date(parseInt(endTime)).toISOString(),
      };

      CustomAsyncToast({
        promise: () => endRental({ rentalId: rental.id, body: endRentalInfo }),
        successMessage: () => 'The rental was ended successfully!',
        errorMessage: 'There was an error ending the rental. Please try again later.',
        loadingMessage: 'Ending rental...',
        onErrorCallBack: (r: string) => {
          setState((prevState) => ({
            ...prevState,
            disabled: false,
          }));
        },
      }).then((response) => {
        if (response) {
          onClose();
          history.push(`/rentals/${rental.id}`);
        }
      });
    } else {
      setState((prevState) => ({
        ...prevState,
        disabled: false,
      }));
    }
  };

  return (
    <MovaticCustomModal
      open={showModal}
      onClose={onClose}
      dataId="end-rental-modal"
      content={
        <div>
          <MovaticCustomModal
            dataId="end-rental-confirmation-modal"
            open={openConfirmationModal}
            onClose={() => {
              setState((prevState) => ({
                ...prevState,
                openConfirmationModal: false,
              }));
            }}
            title={'Ending rental without an end location'}
            content={`You are about to end this rental without selecting an end location, which will remove the unit’s currently assigned location and might prevent users from being able to rent it.`}
            action={
              <IconButtonMenu
                buttons={[
                  {
                    label: 'Cancel',
                    onClick: () => {
                      setState((prevState) => ({
                        ...prevState,
                        openConfirmationModal: false,
                      }));
                    },
                    startIcon: <CloseIcon />,
                  },
                  {
                    label: 'End without location',
                    onClick: () => {
                      setState((prevState) => ({
                        ...prevState,
                        openConfirmationModal: false,
                      }));
                      endRentalCallback();
                    },
                    startIcon: <CheckIcon />,
                  },
                ]}
              />
            }
          />

          {!!start?.station?.id && (
            <FormItem label="Start Station" content={start?.station?.name} />
          )}
          <FormItem
            content={!startingTime ? '' : getLocaleDateTimeString(startingTime)}
            label="Start Time"
          />
          <FormItem label="User" content={user?.full_name} />
          <MovaticFieldGroupAsyncSelect
            dataId="end-rental-station-select"
            isLoading={isLoadingStations}
            label={`End Station ${
              hardware?.has_gps && round_trip ? '(Required)' : '(Optional)'
            }`}
            description="Select the station where the rental will end."
            name={'card_type'}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            isOptionEqualToValue={(option: any, value: any) =>
              option.value === value.value
            }
            options={stations
              .filter((station: IStation) => station.configuration !== 'zone')
              .map((station: IStation) => {
                return { value: station.id, name: station.name };
              })}
            onChange={(event: any, newValue: { value: any }) => {
              setState((prevState) => ({
                ...prevState,
                station: newValue?.value || null,
                stationError: null,
                stationValidation: null,
              }));
            }}
            hasError={stationError !== null}
            errorMessage={stationError || ''}
            id="station"
            getOptionText={(option: any) => `${option.name}`}
            handleSearch={(search) => setSearch(search.target.value)}
          />

          <FormItem
            label="End Time"
            content={
              <RadioButtons
                id="endTimeType"
                value={endTimeType}
                handleChange={(event, value: string) =>
                  setState((prevState) => ({
                    ...prevState,
                    endTimeType: value,
                    dateError: null,
                    endTime: 0,
                  }))
                }
                options={[
                  {
                    id: 'now',
                    label: 'Now',
                    popoverContent: 'The rental will end at the time this is submitted.',
                  },
                  {
                    id: 'custom',
                    label: 'Custom',
                    popoverContent: 'The rental will end at a specified date/time.',
                  },
                ]}
              />
            }
          />

          {endTimeType === 'custom' ? (
            <>
              <MovaticFieldGroupSelect
                label={'Custom Time'}
                description="Select the time the rental will end. (Required)"
                name={'endTimeDate'}
                optionNodes={getTimeList().map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
                value={endTime}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onTimeChange(Number(e.target.value));
                }}
                id={'endTimeDate'}
              />

              <MovaticFieldGroupDate
                id="endTimeDate"
                label="Custom Date"
                description="Select the date the rental will end. (Required)"
                value={new Date(activationDate) || null}
                hasError={Boolean(dateError)}
                errorMessage={dateError || ''}
                onChange={(date) =>
                  dateChange(date && !isNaN(date.getTime()) && date.toISOString())
                }
              />
            </>
          ) : null}
          {
            // if this is a billing system we have to account for the price
            billing ? (
              <>
                <FormItem
                  label="Charge"
                  description={'Select the type of charge to apply to the rental.'}
                  content={
                    <RadioButtons
                      id="chargeType"
                      value={chargeType}
                      handleChange={(event, value: string) => {
                        setState((prevState) => ({
                          ...prevState,
                          chargeType: value,
                          amount: '',
                          amountError: null,
                          adminModificationReason: '',
                          voidReason: '',
                          adminModificationReasonError: null,
                          voidReasonError: null,
                        }));
                      }}
                      options={[
                        {
                          id: 'standard',
                          label: 'Standard',
                          popoverContent: 'The user will be charged normally.',
                        },
                        {
                          id: 'void',
                          label: 'Void',
                          popoverContent: 'The user will not be charged at all.',
                        },
                        {
                          id: 'set_subtotal',
                          label: 'Set subtotal',
                          popoverContent:
                            'The subtotal will be set to the amount specified.',
                        },
                        {
                          id: 'credit_or_debit',
                          label: 'Set subtotal credit/debit',
                          popoverContent:
                            'The subtotal will be adjusted by the amount specified.',
                        },
                      ]}
                    />
                  }
                />

                {['set_subtotal', 'credit_or_debit'].includes(chargeType) ? (
                  <MovaticFieldGroupOutlinedInput
                    type="number"
                    description={'Enter the amount you would like to add. (Required)'}
                    name="amount"
                    addOn={'$'}
                    inputProps={{ step: 0.25, min: 0 }}
                    label="Amount"
                    id="fund_amount"
                    value={amount}
                    hasError={Boolean(amountError)}
                    errorMessage={amountError || ''}
                    onChange={(value: React.ChangeEvent<HTMLInputElement>) =>
                      setState((prevState) => ({
                        ...prevState,
                        amount: value.target.value,
                        amountError: null,
                      }))
                    }
                  />
                ) : null}

                {['set_subtotal', 'credit_or_debit'].includes(chargeType) ? (
                  <MovaticFieldGroupText
                    id="cardholder_first_name"
                    multiline
                    minRows={2}
                    maxRows={5}
                    label="Admin Modification Reason"
                    description="Enter a reason for the admin modification. (Required)"
                    hasError={Boolean(adminModificationReasonError)}
                    errorMessage={adminModificationReasonError}
                    value={adminModificationReason}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setState((prevState) => ({
                        ...prevState,
                        adminModificationReason: e.target.value,
                        adminModificationReasonError: null,
                      }));
                    }}
                  />
                ) : null}
                {chargeType === 'void' ? (
                  <MovaticFieldGroupText
                    id="voidReason"
                    name={'voidReason'}
                    multiline
                    minRows={2}
                    maxRows={5}
                    label="Void Reason"
                    description="Enter a reason for the void. (Required)"
                    hasError={Boolean(voidReasonError)}
                    errorMessage={voidReasonError}
                    value={voidReason}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setState((prevState) => ({
                        ...prevState,
                        voidReason: e.target.value,
                        voidReasonError: null,
                      }));
                    }}
                  />
                ) : null}
              </>
            ) : null
          }
        </div>
      }
      title={`End ${rental?.user?.full_name}'s Rental`}
      action={
        <IconButtonMenu
          buttons={[
            {
              label: 'Cancel',
              onClick: () => onClose(),
            },
            {
              label: 'Submit',
              onClick: () => {
                if (
                  !station &&
                  (!rental?.hardware?.has_gps || !round_trip) &&
                  stations.length > 0
                ) {
                  setState((prevState) => ({
                    ...prevState,
                    openConfirmationModal: true,
                  }));
                } else {
                  endRentalCallback();
                }
              },
              disabled: disabled,
            },
          ]}
        />
      }
    />
  );
};

export default EndRental;
