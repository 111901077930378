import { useQuery } from '@tanstack/react-query';
import { getMemberships } from '../../../api/memberships';
import { UseMembershipFilter } from '../../../ts/interface/membership.interface';

const useMemberships = (filter?: UseMembershipFilter, options?: {}) => {
  return useQuery(
    ['memberships', filter?.pageNumber, filter?.limit, filter?.system_id, filter?.status],
    () => getMemberships(filter),
    { ...options }
  );
};

export default useMemberships;
