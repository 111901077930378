import React, { useState } from 'react';
import { getLocaleDateTimeString, history } from '../../../utils';
import useUserRfidCards from '../../../hooks/users/query/useUserRfidCards';
import { IRfidCard } from '../../../ts/interface/rfidCard.interface';
import CardView from '../../../components/Redesing/card-view';
import { PaginationTable } from '../../../components/Redesing/table/pagination-table';
import { IColumn } from '../../../ts/interface/components/redesing/table.interface';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import { Stack } from '@mui/system';

interface UserRfidCardsProps {
  userId: string;
  userPhone: string;
  documentationLink?: string;
}

const UserRfidCards = ({ userId, userPhone, documentationLink }: UserRfidCardsProps) => {
  const [search, setSearch] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(5);
  const [rfidCards, setRfidCards] = useState<IRfidCard[]>([]);
  const [sortBy, setSortBy] = useState('created_on');
  useUserRfidCards(userId, {
    onSuccess: (data: IRfidCard[]) => {
      setRfidCards(data);
    },
  });
  return (
    <>
      <CardView
        documentationLink={documentationLink}
        headerActions={
          <>
            <IconButtonMenu
              buttons={[
                {
                  label: 'Add RFID Card',
                  onClick: () => history.push(`/users/${userPhone}/add-rfid`),
                  startIcon: <AddIcon />,
                },
              ]}
            />
          </>
        }
        content={
          <PaginationTable
            onRowCLick={(id: string) => history.push(`/users/${userPhone}/rfid/${id}`)}
            handleSearch={(search) => setSearch(search.target.value)}
            searchText={search}
            sortBy={sortBy}
            onSortChange={(sort) => setSortBy(sort.key)}
            columns={
              [
                {
                  id: 'actions',
                  label: 'Actions',
                  notSortable: true,
                  format: (row: IRfidCard) => {
                    return (
                      <Stack justifyContent={'flex-start'} alignItems={'flex-start'}>
                        <IconButtonMenu
                          buttons={[
                            {
                              label: '',
                              startIcon: <EditIcon />,
                              onClick: () =>
                                history.push(`/users/${userPhone}/rfid/${row.id}/edit`),
                            },
                          ]}
                        />
                      </Stack>
                    );
                  },
                },
                {
                  id: 'card_id',
                  label: 'Card ID',
                  searchable: true,
                },
                {
                  id: 'created_on',
                  label: 'Created On',
                  format: (row: IRfidCard) => getLocaleDateTimeString(row.created_on),
                  searchable: true,
                },
                {
                  id: 'archived_on',
                  label: 'Archived On',
                  format: (row: IRfidCard) => getLocaleDateTimeString(row.archived_on),
                  searchable: true,
                },
              ] as IColumn[]
            }
            items={rfidCards || []}
            onPageChange={(page, pageN) => {
              setPageNumber(pageN);
            }}
            onRowsPerPageChange={(row) => {
              const pageSize = Number(row.target.value);
              setSizePerPage(pageSize);
            }}
            page={pageNumber}
            rowsPerPage={sizePerPage}
            searchPlaceholder={'Search User RFID Cards'}
            showFilter={false}
          />
        }
        title={'User RFID Cards'}
      />
    </>
  );
};

export default UserRfidCards;
