import React, { useState } from 'react';
import { history } from '../../utils';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { newLogin } from '../../actionCreators/admin';
import AuthLayout from '../../layout/auth/auth-layout';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Box from '@mui/material/Box';
import { Logo } from '../../components/Redesing/logo';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import { Stack } from '@mui/system';
import Button from '@mui/material/Button';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';
import { setPasswordToken } from '../../api/admin';

const SetPass = ({ newLogin }: { newLogin?: Function }) => {
  const { email, page, token } = useParams<{
    email: string;
    token: string;
    page: string;
  }>();
  const confirmPasswordPlaceholder = 'Confirm your password Here';
  const passwordPlaceholder = 'Enter Password Here (Case Sensitive)';
  const ctaText = page === 'setpassword' ? 'Set' : 'Reset';
  const noPassword = 'Password must be longer than eight characters.';
  const passwordMismatch = 'Passwords Must Match';

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordValidationResult, setPasswordValidationResult] = useState<string | null>(
    ''
  );
  const [passwordHelp, setPasswordHelp] = useState(
    'Password must be longer than eight characters.'
  );
  const [confirmPasswordValidationResult, setConfirmPasswordValidationResult] = useState<
    string | null
  >('');
  const [confirmPasswordHelp, setConfirmPasswordHelp] = useState('Passwords Must Match');
  const [isLoading, setIsLoading] = useState(false);

  const checkPasswordMatch = (password: string, confirmPassword: string) => {
    if (password !== confirmPassword) {
      setConfirmPasswordValidationResult('error');
      setConfirmPasswordHelp(passwordMismatch);
    } else {
      setConfirmPasswordValidationResult(null);
      setConfirmPasswordHelp('');
    }
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setConfirmPassword(value);
    checkPasswordMatch(password, value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPassword(value);
    if (e.target.value.length < 8) {
      setPasswordValidationResult('error');
      setPasswordHelp(noPassword);
    } else {
      setPasswordValidationResult(null);
      setPasswordHelp('');
    }
    checkPasswordMatch(value, confirmPassword);
  };

  const canBeSubmitted = () => {
    if (password !== confirmPassword) {
      return false;
    }
    return password.length >= 8;
  };

  const setPasswordHandler = () => {
    if (canBeSubmitted()) {
      setIsLoading(true);
      CustomAsyncToast({
        promise: () => setPasswordToken(email, password, token),
        successMessage: () => 'Password set successfully',
        errorMessage: 'Error setting password',
        loadingMessage: 'Setting password...',
      }).then((r) => {
        if (r) {
          newLogin?.(email, password).then(() => history.push('/'));
        }
      });
    }
  };

  return (
    <>
      <AuthLayout>
        <Card elevation={16}>
          <CardHeader
            subheader={
              <>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Logo />
                </Box>
                {/*<Box display="flex" justifyContent="center" alignItems="center">*/}
                {/*  <Logo />*/}
                {/*</Box>*/}
                {/*<div>*/}
                {/*  <Typography*/}
                {/*    display="flex"*/}
                {/*    justifyContent="center"*/}
                {/*    alignItems="center"*/}
                {/*    color="text.primary"*/}
                {/*    variant="h4"*/}
                {/*  >*/}
                {/*    <span>Movatic</span>*/}
                {/*  </Typography>*/}
                {/*</div>*/}
              </>
            }
            sx={{ pb: 0 }}
            title=""
          />
          <CardContent>
            <Stack spacing={3}>
              <TextField
                error={passwordValidationResult === 'error'}
                fullWidth
                helperText={passwordHelp}
                label="Password"
                name="password"
                placeholder={passwordPlaceholder}
                // onBlur={formik.handleBlur}
                onChange={handlePasswordChange}
                type="password"
                value={password}
              />
              <TextField
                error={confirmPasswordValidationResult === 'error'}
                fullWidth
                helperText={confirmPasswordHelp}
                label="Confirm Password"
                name="confirmPassword"
                id="confirmPassword"
                onChange={handleConfirmPasswordChange}
                placeholder={confirmPasswordPlaceholder}
                type="password"
                value={confirmPassword}
                onKeyPress={(event: React.KeyboardEvent) => {
                  if (event.key === 'Enter') {
                    setPasswordHandler();
                  }
                }}
              />
              <Button
                disabled={
                  isLoading ||
                  passwordValidationResult === 'error' ||
                  confirmPasswordValidationResult === 'error'
                }
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={setPasswordHandler}
              >
                {isLoading ? 'Loading' : `${ctaText} Your Password`}
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </AuthLayout>
    </>
  );
};

// export default SetPass;

export default connect(
  () => ({}),
  (dispatch: Function) => ({
    newLogin: (email: string, password: string) => dispatch(newLogin(email, password)),
  })
)(SetPass);
