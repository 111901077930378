import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ISystem } from '../../ts/interface/system.interface';
import { IMode } from '../../ts/interface/mode.interface';
import { useParams } from 'react-router-dom';
import useOneHardware from '../../hooks/hardware/query/useOneHardware';
import useAllTask from '../../hooks/maintenance/query/useAllTask';
import { IAdmin } from '../../ts/interface/admins.interface';
import { IHardware } from '../../ts/interface/hardware.interface';
import { history, makeStationOptionsArray, toTitleCase } from '../../utils';
import { ITask, ITicket } from '../../ts/interface/problem.interface';
import useStation from '../../hooks/stations/query/useStation';
import useOneTicket from '../../hooks/maintenance/query/useOneTicket';
import { completeTask, closeTicket as closingTicket } from '../../api/problems';
import useAllHardware from '../../hooks/hardware/query/useAllHardware';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import SaveIcon from '@mui/icons-material/Save';
import {
  MovaticFieldGroupAsyncSelect,
  MovaticFieldGroupOutlinedInput,
  MovaticFieldGroupSelect,
  MovaticFieldGroupText,
  MovaticFieldItemLink,
} from '../../components/Redesing/movatic-field-group';
import MenuItem from '@mui/material/MenuItem';
import PageContainer from '../../components/Redesing/page-container';
import CardView from '../../components/Redesing/card-view';
import { Stack } from '@mui/system';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';
import CustomToast from '../../components/Redesing/custom-toast';
import { LocationStatus } from '../../ts/enums';

interface IAddCompletedTaskState {
  time_tracking: string;
  time_trackingValidation: null | string;
  time_trackingHelp: string;
  notes: string;
  maintenance_task_id: string;
  hardware_id: string;
  type_name: string;
  module_id: string;
  closeTicket: boolean;
  hardwareOptions: { value: string; name: string }[];
  editHardware: boolean;
  hardwareNumber: string;
  station: null | string;
  ticket: ITicket | null;
  stationsOptions: { value: string; name: string; code: string }[];
  stationOpen: boolean;
  stationSearch: string;
}

const AddCompletedTask = ({
  mode,
  system,
  systemLoaded,
}: {
  mode: IMode;
  system: ISystem;
  systemLoaded: boolean;
}) => {
  const { hardwareId = '', ticketId = '' } = useParams<{
    hardwareId: string;
    ticketId: string;
  }>();
  const initialState = {
    time_tracking: '',
    time_trackingValidation: null,
    time_trackingHelp: '',
    notes: '',
    maintenance_task_id: '',
    hardware_id: '',
    type_name: '',
    module_id: '',
    closeTicket: !!ticketId,
    hardwareOptions: [],
    editHardware: true,
    hardwareNumber: '',
    station: null,
    ticket: null,
    stationsOptions: [] as { value: string; name: string; code: string }[],
    stationOpen: false,
    stationSearch: '',
  };

  const [
    {
      time_tracking,
      time_trackingValidation,
      time_trackingHelp,
      notes,
      maintenance_task_id,
      hardware_id,
      type_name,
      module_id,
      closeTicket,
      hardwareOptions,
      editHardware,
      hardwareNumber,
      station,
      ticket,
      stationsOptions,
      stationOpen,
      stationSearch,
    },
    setState,
  ] = useState<IAddCompletedTaskState>(initialState);

  const { data: hardware } = useOneHardware(hardwareId, {
    enabled: !!hardwareId,
  });

  const { data: allHardware = [] } = useAllHardware({
    enabled: !hardwareId && systemLoaded,
  });

  const { data: allTask = [] } = useAllTask({
    enabled: systemLoaded,
  });

  const { data: ticketData } = useOneTicket(ticketId, {
    enabled: !!ticketId,
  });

  const {
    data: stationsData,
    isLoading: isLoadingStations,
    isRefetching: isRefetchingStations,
  } = useStation(
    { enabled: true },
    { status: LocationStatus.active, pattern: stationSearch }
  );

  useEffect(() => {
    const stations = stationsData?.data || [];
    if (stations.length > 0) {
      const formattedStations = makeStationOptionsArray(stations, '', true);
      setState((prevState) => ({
        ...prevState,
        stationsOptions: formattedStations,
      }));
    }
  }, [stationsData]);

  useEffect(() => {
    if (allTask.length > 0) {
      setState((prevState) => ({
        ...prevState,
        maintenance_task_id: allTask.length ? allTask[0].id : '',
      }));
    }
  }, [allTask]);

  useEffect(() => {
    if (hardwareId && hardware) {
      setState((prevState) => ({
        ...prevState,
        editHardware: false,
        hardware_id: hardware.id,
        hardwareNumber: hardware.number,
        type_name: hardware.hardware_type,
      }));
    } else if (ticketId) {
      setState((prevState) => ({
        ...prevState,
        ticket: ticketData,
        hardware_id: ticketData?.hardware_id,
        editHardware: true,
      }));
    } else {
      const hardwareOptions = allHardware
        ?.map((unit: IHardware) => ({
          value: unit.id,
          name: `${toTitleCase(unit.hardware_type_name)} ${unit.number || unit.id}`,
        }))
        .sort((a: { name: string }, b: { name: string }) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
      setState((prevState) => ({
        ...prevState,
        hardwareOptions: hardwareOptions,
        hardware_id: hardwareOptions?.length ? hardwareOptions[0].value : '',
      }));
    }
  }, [hardwareId, systemLoaded, hardware, allHardware, ticketData, ticketId]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'hardware_id' && !system.new_hardware) {
      const unit: IHardware =
        allHardware?.find((hardware: IHardware) => hardware.id === e.target.value) ||
        ({} as IHardware);
      setState((prevState) => ({
        ...prevState,
        hardware_id: unit.id,
        module_id: unit?.module || '',
      }));
    } else if (e.target.name === 'time_tracking') {
      setState((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
        time_trackingValidation: null,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const save = () => {
    if (isNaN(Number(time_tracking)) || Number(time_tracking) <= 0 || !time_tracking) {
      setState((prevState) => ({
        ...prevState,
        time_trackingValidation: 'error',
        time_trackingHelp: 'Time taken must be a number greater than 0',
      }));
      return;
    }
    if (!closeTicket && maintenance_task_id === '') {
      CustomToast({
        type: 'error',
        message: 'Maintenance type cannot be empty',
      });
      return;
    }
    if (!closeTicket && hardware_id === '') {
      CustomToast({
        type: 'error',
        message: `${
          system.new_hardware ? 'Hardware' : mode.details.unitsTitle
        } cannot be empty`,
      });
      return;
    }

    const task = {
      ticketId: ticket?.id || null,
      time_tracking: Number(time_tracking) * 60,
      notes: notes.toString() || null,
      maintenance_task_id: maintenance_task_id,
      hardware_id: hardware_id,
      module_id: module_id || null,
      station_gid: station || null,
    };

    if (closeTicket) {
      CustomAsyncToast({
        promise: () => closingTicket(task),
        successMessage: () => 'Ticket closed successfully',
        loadingMessage: 'Closing ticket...',
        errorMessage: 'Unable to close ticket',
      }).then((r) => {
        if (r) {
          history.push(`/maintenance/tickets`);
        }
      });
    } else {
      CustomAsyncToast({
        promise: () => completeTask(task),
        successMessage: () => 'Log added successfully!',
        loadingMessage: 'Adding log...',
        errorMessage: 'Unable to add log',
      }).then((r) => {
        if (r) {
          history.push(`/maintenance/logs`);
        }
      });
    }
  };

  const goBack = () => {
    if (closeTicket) {
      history.push(`/maintenance/${ticketId}`);
    } else {
      history.goBack();
    }
  };

  return (
    <>
      <PageContainer isLoading={!systemLoaded}>
        <CardView
          title={closeTicket ? 'Close Ticket' : 'Log Maintenance'}
          content={
            <Stack px={2} pb={2}>
              {!closeTicket ? (
                <div>
                  {editHardware ? (
                    <MovaticFieldGroupSelect
                      label={system.new_hardware ? 'Hardware' : mode.details.unitsTitle}
                      description="Hardware that was worked on."
                      optionNodes={hardwareOptions.map(
                        (option: { value: string; name: string }, index: number) => (
                          <MenuItem key={index} value={option.value}>
                            {option.name}
                          </MenuItem>
                        )
                      )}
                      value={hardware_id}
                      onChange={handleChange}
                      name="hardware_id"
                    />
                  ) : system.new_hardware ? (
                    <>
                      <MovaticFieldItemLink
                        dataId={'hardware-id'}
                        onClick={() =>
                          history.push(`/hardware/${type_name}/${hardware_id}`)
                        }
                        label={toTitleCase(type_name)}
                        content={hardware_id}
                      />
                    </>
                  ) : (
                    <MovaticFieldItemLink
                      dataId={'hardware-number'}
                      onClick={() => history.push(`/units/${hardware_id}`)}
                      label={mode.details.unitsTitle}
                      content={hardwareNumber}
                    />
                  )}

                  <MovaticFieldGroupAsyncSelect
                    isLoading={isLoadingStations || isRefetchingStations}
                    label={mode.details.stationsTitle}
                    description="(optional)"
                    name={'station'}
                    id={'station'}
                    open={stationOpen}
                    onOpen={() =>
                      setState((prevState) => ({
                        ...prevState,
                        stationOpen: true,
                      }))
                    }
                    onClose={() =>
                      setState((prevState) => ({
                        ...prevState,
                        stationOpen: false,
                      }))
                    }
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.value === value.value
                    }
                    options={stationsOptions.map(
                      (station: { value: string; name: string }) => {
                        return { value: station.value, name: station.name };
                      }
                    )}
                    onChange={(event: any, newValue: { value: any }) =>
                      setState((prevState) => ({
                        ...prevState,
                        station: newValue?.value || null,
                      }))
                    }
                    getOptionText={(option: any) => `${option.name}`}
                    handleSearch={(search) =>
                      setState((prevState) => ({
                        ...prevState,
                        stationSearch: search.target.value,
                      }))
                    }
                  />

                  <MovaticFieldGroupSelect
                    optionNodes={allTask
                      ?.map((task: ITask) => ({
                        value: task.id,
                        name: task.name,
                      }))
                      .map((option: { value: string; name: string }, index: number) => (
                        <MenuItem key={index} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    onChange={handleChange}
                    name="maintenance_task_id"
                    label="Maintenance Type"
                    description="Maintenance type."
                    value={maintenance_task_id}
                  />
                </div>
              ) : null}
              <MovaticFieldGroupOutlinedInput
                type={'number'}
                label="Time Taken"
                description="Amount of time taken to complete task in minutes"
                value={time_tracking}
                inputProps={{ min: 1 }}
                hasError={time_trackingValidation === 'error'}
                errorMessage={time_trackingHelp}
                onChange={(event) => {
                  setState((prevState) => ({
                    ...prevState,
                    time_tracking: event.target.value,
                    time_trackingValidation: null,
                  }));
                }}
                name="time_tracking"
              />

              <MovaticFieldGroupText
                multiline
                maxRows={10}
                minRows={1}
                label="Additional Notes"
                placeholder="Notes"
                description="(Optional)"
                name="notes"
                value={notes}
                onChange={handleChange}
              />
            </Stack>
          }
          headerActions={
            <IconButtonMenu
              buttons={[
                {
                  label: 'Cancel',
                  onClick: goBack,
                },
                {
                  label: 'Save',
                  onClick: save,
                  startIcon: <SaveIcon />,
                },
              ]}
            />
          }
        />
      </PageContainer>
    </>
  );
};

export default connect(
  (state: {
    system: { current: ISystem; isLoaded: boolean };
    mode: IMode;
    admin: { admin: { admin: IAdmin } };
  }) => ({
    system: state.system.current,
    systemLoaded: state.system.isLoaded,
    mode: state.mode,
    admin: state.admin.admin.admin,
  }),
  () => ({})
)(AddCompletedTask);
