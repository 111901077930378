import React, { useState } from 'react';
import { history, isValidEmail } from '../../utils';
import useUser from '../../hooks/users/query/useUser';
import { useParams } from 'react-router-dom';
import { UserInterface } from '../../ts/interface/user.interface';
import PageContainer from '../../components/Redesing/page-container';
import {
  MovaticFieldGroupTelephone,
  MovaticFieldGroupText,
} from '../../components/Redesing/movatic-field-group';
import CardView from '../../components/Redesing/card-view';
import { Stack } from '@mui/system';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';
import { updateUser } from '../../api/users';

const EditUser = () => {
  const { userPhone = '' } = useParams<{ userPhone: string }>();
  const [email, setEmail] = useState<string>('');
  const [autoGenNumber, setAutoGenNumber] = useState<boolean>(false);
  const [id, setId] = useState<string>('');
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [emailValidation, setEmailValidation] = useState<string | null>(null);
  const [emailHelp, setEmailHelp] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [phoneValidation, setPhoneValidation] = useState<string | null>(null);
  const [phoneHelp, setPhoneHelp] = useState<string>('');

  const { isLoading } = useUser(userPhone, {
    onSuccess: (data: UserInterface) => {
      const { emailAddress: email, autoGenNumber, id } = data.userDetails;
      setEmail(email);
      setAutoGenNumber(autoGenNumber);
      setId(id);
    },
  });

  const canSubmit = () => {
    if (phoneNumber.length < 10) {
      setPhoneHelp('Phone number must be at least 10 digits');
      setPhoneValidation('error');
      return false;
    }

    if (emailAddress && !isValidEmail(emailAddress)) {
      setEmailHelp('Email address is not valid');
      setEmailValidation('error');
      return false;
    }

    return true;
  };

  const submit = () => {
    if (canSubmit()) {
      const userInfo = {
        id,
        emailAddress,
        userPhone,
        newPhone: phoneNumber !== userPhone ? phoneNumber : null,
      };
      CustomAsyncToast({
        loadingMessage: 'Updating user...',
        successMessage: () => 'User updated successfully',
        errorMessage: 'Error updating user',
        promise: () => updateUser(userInfo),
      }).then((r) => {
        if (r) {
          history.push(`/users/${phoneNumber || userPhone}/general`);
        }
      });
    }
  };

  return (
    <PageContainer isLoading={isLoading}>
      <CardView
        content={
          <Stack px={2} pb={2}>
            {autoGenNumber ? (
              <MovaticFieldGroupTelephone
                id="phoneNumber"
                label="Phone Number"
                errorMessage={phoneHelp}
                hasError={phoneValidation === 'error'}
                description="User's phone number."
                name="phoneNumber"
                onChange={(value: string) => {
                  const phone = value.replace(/\s/g, '');
                  setPhoneNumber(phone);
                  setPhoneValidation(null);
                  setPhoneHelp('');
                }}
                value={phoneNumber}
              />
            ) : null}

            {email ? null : (
              <MovaticFieldGroupText
                id="email"
                name={'email'}
                label="Email"
                description="User's email address."
                hasError={emailValidation === 'error'}
                errorMessage={emailHelp}
                value={emailAddress}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setEmailAddress(e.target.value);
                  setEmailValidation(null);
                  setEmailHelp('');
                }}
              />
            )}
          </Stack>
        }
        title="Edit User"
        headerActions={
          <IconButtonMenu
            buttons={[
              {
                label: 'Cancel',
                startIcon: <CloseIcon />,
                onClick: () => history.push(`/users/${userPhone}`),
              },
              {
                label: 'Save',
                onClick: submit,
                startIcon: <SaveIcon />,
              },
            ]}
          />
        }
      />
    </PageContainer>
  );
};

export default EditUser;
