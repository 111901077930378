import React, { useState } from 'react';
// @ts-ignore
import * as Yup from 'yup';
import { history } from '../../utils';
import { addUser, checkUser } from '../../api/users';
import PageContainer from '../../components/Redesing/page-container';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { matchIsValidTel } from 'mui-tel-input';
import CardView from '../../components/Redesing/card-view';
import FormItem from '../../components/Redesing/form-item';
import { ButtonGroup } from '@mui/material';
import {
  MovaticFieldGroupCheck,
  MovaticFieldGroupTelephone,
} from '../../components/Redesing/movatic-field-group';

interface GlobalState {
  email: string;
  firstName: string;
  lastName: string;
  checkForUser: boolean;
  disableInfo: boolean;
  onlyRFID: boolean;
}

const AddUser = () => {
  const initialState = {
    email: '',
    firstName: '',
    lastName: '',
    checkForUser: false,
    disableInfo: false,
    onlyRFID: false,
  };

  const [{ email, firstName, lastName, checkForUser, disableInfo, onlyRFID }, setState] =
    useState<GlobalState>(initialState as GlobalState);
  const formik = useFormik({
    initialValues: {
      rfidLabel: '',
      lastName: lastName || '',
      email: email || '',
      firstName: firstName || '',
      phone: '',
      rfid: '',
      submit: null,
    },
    validationSchema: Yup.object().shape({
      rfidLabel: Yup.string().when('onlyRFID', {
        is: true,
        then: Yup.string().required('RFID Label is required').max(255),
        otherwise: Yup.string().max(255),
      }),
      lastName: Yup.string().when(['checkForUser', 'onlyRFID'], {
        is: (checkForUser: boolean, onlyRFID: boolean) => !checkForUser || onlyRFID,
        then: Yup.string().required('First Name is required').max(255),
        otherwise: Yup.string().max(255),
      }),
      email: Yup.string().when('onlyRFID', {
        is: false,
        then: Yup.string().required('Email is required').max(255),
        otherwise: Yup.string().max(255),
      }),
      firstName: Yup.string().when(['checkForUser', 'onlyRFID'], {
        is: (checkForUser: boolean, onlyRFID: boolean) => !checkForUser || onlyRFID,
        then: Yup.string().required('First Name is required').max(255),
        otherwise: Yup.string().max(255),
      }),
      phone: Yup.string()
        .max(15)
        .when('onlyRFID', {
          is: false,
          then: Yup.string().required('Phone number is required'),
        }),
      rfid: Yup.string().when('onlyRFID', {
        is: true,
        then: Yup.string().required('RFID # is required').max(255),
        otherwise: Yup.string().max(255),
      }),
    }),

    onSubmit: async (values): Promise<void> => {
      await toast.promise(
        addUser(values).then((res) => {
          if (res && res?.phone) {
            history.push(`/users/${res.phone}/general`);
          }
        }),
        {
          loading: 'Saving...',
          success: <b>User saved!</b>,
          error: <b>Could not save new user.</b>,
        }
      );
    },
  });
  const [value, setValue] = React.useState('');

  const handleChangePhone = (newValue: string) => {
    const phone = newValue.replace(/\s/g, '');
    formik.setFieldValue('phone', phone);
    setValue(newValue);
  };

  const handleValidateClick = () => {
    if (value && matchIsValidTel(value)) {
      checkUser(formik.values.phone).then((resp) => {
        if (resp.status !== 200) {
          toast.error(resp.message);
        } else {
          const userObj = resp?.message;
          if (userObj) {
            formik.setFieldValue('firstName', userObj.firstName);
            formik.setFieldValue('lastName', userObj.lastName);
            formik.setFieldValue('email', userObj.emailAddress);
            setState((prevState) => ({
              ...prevState,
              disableInfo: true,
              checkForUser: true,
            }));
          } else {
            formik.setFieldValue('firstName', '');
            formik.setFieldValue('lastName', '');
            formik.setFieldValue('email', '');
            setState((prevState) => ({
              ...prevState,
              disableInfo: false,
              checkForUser: true,
            }));
          }
        }
      });
    } else {
      toast.error('Please enter a valid phone number');
    }
  };

  return (
    <PageContainer isLoading={false} spaceName={''}>
      <CardView
        headerActions={
          <Stack
            direction={{
              xs: 'column',
              sm: 'row',
            }}
            spacing={1}
          >
            {!checkForUser && !onlyRFID ? (
              <ButtonGroup>
                <Button
                  disabled={!value}
                  onClick={handleValidateClick}
                  variant="contained"
                >
                  Validate
                </Button>
                <Button
                  variant="outlined"
                  disabled={formik.isSubmitting}
                  onClick={() => history.push('/users')}
                >
                  Cancel
                </Button>
              </ButtonGroup>
            ) : (
              <ButtonGroup>
                <Button
                  onClick={() => formik.handleSubmit()}
                  disabled={formik.isSubmitting || (!checkForUser && !onlyRFID)}
                  type={'submit'}
                  variant="contained"
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  disabled={formik.isSubmitting}
                  onClick={() => history.push('/users')}
                >
                  Cancel
                </Button>
              </ButtonGroup>
            )}
          </Stack>
        }
        content={
          <form onSubmit={formik.handleSubmit}>
            <Stack paddingX={2} paddingBottom={2}>
              <MovaticFieldGroupCheck
                label="Is this user only for RFID?"
                description="Will this user only be used for RFID?"
                id="onlyRFID"
                name="onlyRFID"
                onChange={(e: React.BaseSyntheticEvent) => {
                  formik.setFieldValue('rfidLabel', '');
                  formik.setFieldValue('rfid', '');
                  formik.setFieldValue('firstName', '');
                  formik.setFieldValue('lastName', '');
                  formik.setFieldValue('email', '');
                  setState((prevState) => ({
                    ...prevState,
                    disableInfo: false,
                    checkForUser: false,
                    onlyRFID: e.target.checked,
                  }));
                }}
                checked={onlyRFID}
              />

              {!onlyRFID && (
                <MovaticFieldGroupTelephone
                  id="phoneNumber"
                  label="Phone number"
                  errorMessage={formik.errors.phone}
                  hasError={!!(formik.touched.phone && formik.errors.phone)}
                  description="User phone number"
                  name="phone"
                  onChange={handleChangePhone}
                  value={value}
                />
              )}

              <>
                {(checkForUser || onlyRFID) && (
                  <>
                    <FormItem
                      label="Email address"
                      description="User email address"
                      content={
                        <TextField
                          error={!!(formik.touched.email && formik.errors.email)}
                          fullWidth
                          helperText={formik.touched.email && formik.errors.email}
                          label="Email address"
                          name="email"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          required={!onlyRFID}
                          value={formik.values.email}
                          disabled={disableInfo}
                        />
                      }
                    />
                    <FormItem
                      label="First name"
                      description="User first name"
                      content={
                        <TextField
                          error={!!(formik.touched.firstName && formik.errors.firstName)}
                          fullWidth
                          helperText={formik.touched.firstName && formik.errors.firstName}
                          label="First name"
                          name="firstName"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          required
                          value={formik.values.firstName}
                          disabled={disableInfo}
                        />
                      }
                    />
                    <FormItem
                      label="Last name"
                      description="User last name"
                      content={
                        <TextField
                          error={!!(formik.touched.lastName && formik.errors.lastName)}
                          fullWidth
                          helperText={formik.touched.lastName && formik.errors.lastName}
                          label="Last Name"
                          name="lastName"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.lastName}
                          disabled={disableInfo}
                          required
                        />
                      }
                    />
                    <FormItem
                      label="RFID #"
                      description="User RFID #"
                      content={
                        <TextField
                          error={!!(formik.touched.rfid && formik.errors.rfid)}
                          fullWidth
                          helperText={formik.touched.rfid && formik.errors.rfid}
                          label="RFID #"
                          name="rfid"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.rfid}
                        />
                      }
                    />

                    <FormItem
                      label="RFID label"
                      description="User RFID label"
                      content={
                        <TextField
                          error={!!(formik.touched.rfidLabel && formik.errors.rfidLabel)}
                          fullWidth
                          helperText={formik.touched.rfidLabel && formik.errors.rfidLabel}
                          label="RFID label"
                          name="rfidLabel"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.rfidLabel}
                        />
                      }
                    />
                  </>
                )}
              </>
            </Stack>
          </form>
        }
        title={'Add'}
      />
    </PageContainer>
  );
};

export default AddUser;
