import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import {
  issueStateFormatter,
  getLocaleDateTimeString,
  formatPhoneNumber,
  history,
} from '../../utils';
import {
  getOneProblem,
  getAllTickets,
  updateIssue,
  sendReplyEmail,
  addNoteToReport,
  addTagsToErrorReport,
} from '../../api/problems';
import CommunicationLog from '../../components/communicationlog';
import { ticketColumnsV2 } from '../../tableColumns/hardwareColumns';
import { PropertyList } from '../../components/Redesing/property-list';
import { PropertyListItem } from '../../components/Redesing/property-list-item';
import Typography from '@mui/material/Typography';
import PropertyListItemLink from '../../components/Redesing/property-list-item-link';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import CustomToast from '../../components/Redesing/custom-toast';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';
import { PaginationTable } from '../../components/Redesing/table/pagination-table';
import CardView from '../../components/Redesing/card-view';
import PageContainer from '../../components/Redesing/page-container';
import PageContent from '../../components/Redesing/page-content';
import { Stack } from '@mui/system';
import Grid from '@mui/material/Unstable_Grid2';
import { SeverityPill } from '../../components/Redesing/severity-pill';
import MovaticCustomModal from '../../components/Modal/MovaticCustomModal';
import CloseIcon from '@mui/icons-material/Close';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants';
import { MovaticFieldGroupSelectFilter } from '../../components/Redesing/movatic-field-group';
import { getCredentials } from '../../api/http';
import useTags from '../../hooks/supportTickets/query/useTags';
import useSmallScreen from '../../hooks/useSmallScreen';
import Chip from '@mui/material/Chip';

function ProblemDetails(props) {
  const { problemId } = props.match.params;
  const { systemGid } = getCredentials();
  const [note, setNote] = useState('');
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [problemRefetchInterval, setProblemRefetchInterval] = useState(30000);
  const queryClient = useQueryClient();
  const [addTagModal, setAddTagModal] = useState(false);
  const [value, setValue] = useState([]);
  const [deleteTagModal, setDeleteTagModal] = useState(false);
  const [tagToDelete, setTagToDelete] = useState('');
  const isSmallScreen = useSmallScreen();

  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_ROWS_PER_PAGE);
  const [sortData, setSortData] = useState('due_on');
  const [search, setSearch] = React.useState('');

  const { data = [], isLoading } = useTags(systemGid, {
    enabled: props.systemLoaded,
  });

  const problemQuery = useQuery(['problem', problemId], () => getOneProblem(problemId), {
    refetchInterval: problemRefetchInterval,
    onError: () => {
      setProblemRefetchInterval(0);
      CustomToast({
        type: 'error',
        message:
          'Oops! Looks like there was an error while getting the problem details. Please try again later.',
      });
    },
    enabled: props.systemLoaded,
  });

  const ticketsQuery = useQuery(['tickets'], getAllTickets, {
    enabled: props.systemLoaded,
    onError: () => {
      CustomToast({
        type: 'error',
        message:
          'Oops! Looks like there was an error while getting the tickets. Please try again later.',
      });
    },
  });
  const problem = useMemo(() => problemQuery.data || {}, [problemQuery.data]);

  const loadedTags = useMemo(
    () =>
      data
        ?.filter((item) => !problem?.tags?.includes(item.name))
        .map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        }),
    [data, problem]
  );

  const tickets =
    ticketsQuery.data?.filter((ticket) => ticket.error_report_id === problemId) || [];

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  const sendEmail = () => {
    if (note !== '') {
      const email = {
        message: note.toString(),
      };

      CustomAsyncToast({
        promise: () => sendReplyEmail(email, problem.id),
        successMessage: () => 'Email sent successfully',
        loadingMessage: 'Sending email...',
        errorMessage:
          'Oops! Looks like there was an error while sending the email. Please try again later.',
      }).then((results) => {
        setShowEmailModal(false);
        if (results) {
          setNote('');
          queryClient.invalidateQueries(['problem', problemId]);
        }
      });
    } else {
      CustomToast({
        type: 'error',
        message: 'Message cannot be blank',
      });
    }
  };

  const tags = useMemo(() => {
    return problem?.tags?.map((item) => {
      return {
        label: item,
        onClick: (name) => {
          setTagToDelete(name);
          setDeleteTagModal(true);
        },
      };
    });
  }, [problem]);

  const addInternalNote = () => {
    if (note !== '') {
      const newNote = JSON.stringify({
        admin_id: props.admin.email,
        created_on: new Date().toISOString(),
        message: note.toString(),
        type: 'internal',
      });

      CustomAsyncToast({
        promise: () => addNoteToReport({ note: newNote, id: problem.id }),
        successMessage: () => 'Note added successfully',
        loadingMessage: 'Adding note...',
        errorMessage:
          'Oops! Looks like there was an error while adding the note. Please try again later.',
      }).then((results) => {
        if (results) {
          setNote('');
          queryClient.invalidateQueries(['problem', problemId]);
        }
      });
    } else {
      CustomToast({
        type: 'error',
        message: 'Message cannot be blank',
      });
    }
  };

  const content = () => {
    const { new_hardware } = props.system;
    return (
      <PropertyList>
        <PropertyListItemLink
          others={{ divider: true }}
          label="User"
          text={problem.userName}
          onClick={() => history.push(`/users/${problem.userId}/general`)}
          dataId="user"
        />
        <PropertyListItem
          divider
          label="Email Address"
          value={problem.email}
          dataId="email"
        />
        <PropertyListItem
          divider
          label="Phone Number"
          value={formatPhoneNumber(problem.userId)}
          dataId="phone"
        />
        <PropertyListItem
          divider
          label="Created"
          value={getLocaleDateTimeString(problem.created)}
          dataId="created"
        />
        <PropertyListItem divider label="Current Status" dataId="status">
          <SeverityPill color={problem.state === 1 ? 'success' : 'warning'}>
            {issueStateFormatter(problem.state)}
          </SeverityPill>
        </PropertyListItem>
        {issueStateFormatter(problem.state) === 'Closed' ? (
          <PropertyListItem
            divider
            label="Closed"
            value={getLocaleDateTimeString(problem.closedDate)}
          />
        ) : null}
        <PropertyListItem
          divider
          label="Owner"
          value={problem.owner || 'Unassigned'}
          dataId="owner"
        />
        {/* TODO: improve this for new hardware */}
        {problem.hardware_id && (
          <PropertyListItemLink
            others={{ divider: true }}
            label="Hardware"
            text={problem.hardware_id}
            onClick={
              new_hardware
                ? () =>
                    history.push(
                      `/hardware/${problem.hardware_type}/${problem.hardware_id}`
                    )
                : () => history.push(`/units/${problem.hardware_id}`)
            }
            dataId="hardware"
          />
        )}
        {problem.rental_gid ? (
          <PropertyListItemLink
            others={{ divider: true }}
            label="Rental"
            text={problem.rental_gid}
            onClick={() => history.push(`/rentals/${problem.rental_gid}`)}
            dataId="rental"
          />
        ) : null}
        <PropertyListItem divider label="Tags" dataId="tags">
          <Grid container spacing={1}>
            {tags && tags.length > 0 && (
              <>
                {tags.map((tag) => (
                  <Grid item key={tag.label}>
                    <Chip
                      sx={{
                        height: 'auto',
                        py: 0.5,
                        '& .MuiChip-label': {
                          display: 'block',
                          whiteSpace: 'normal',
                        },
                      }}
                      onDelete={() => tag.onClick(tag.label)}
                      label={tag.label}
                    />
                  </Grid>
                ))}
              </>
            )}
            <Grid item>
              <IconButtonMenu
                buttons={[
                  {
                    label: '',
                    startIcon: <AddIcon />,
                    onClick: () => setAddTagModal(true),
                  },
                ]}
              />
            </Grid>
          </Grid>
        </PropertyListItem>
      </PropertyList>
    );
  };

  const button = () => {
    return (
      <IconButtonMenu
        buttons={[
          {
            label: problem.state === 0 ? 'Close Ticket' : 'Reopen Ticket',
            startIcon: problem.state === 0 ? <CloseIcon /> : <LockOpenIcon />,
            onClick:
              problem.state === 0
                ? () => setShowCloseModal(true)
                : () => {
                    const problemAux = problem;
                    problemAux.state = 0;
                    CustomAsyncToast({
                      promise: () => updateIssue(problemAux),
                      successMessage: () => 'Support ticket reopened successfully',
                      loadingMessage: 'Reopening problem report...',
                      errorMessage:
                        'Oops! Looks like there was an error while reopening the problem report. Please try again later.',
                    }).then((results) => {
                      if (results) {
                        problemQuery.refetch();
                      }
                    });
                  },
          },
        ]}
      />
    );
  };

  const maintenanceContent = () => {
    return (
      <>
        <CardView
          isLoading={ticketsQuery.isLoading}
          headerActions={
            <IconButtonMenu
              buttons={[
                {
                  label: 'Add',
                  onClick: () => history.push(`/maintenance/add/report/${problem.id}`),
                  startIcon: <AddIcon />,
                },
              ]}
            />
          }
          content={
            <PaginationTable
              padding={{
                pl: -1,
                pt: 1,
                pr: 1,
              }}
              sortBy={sortData}
              showFilter={false}
              onSortChange={(sortBy) => setSortData(sortBy.key)}
              items={tickets}
              noDataText={'No Maintenance Tickets'}
              onRowCLick={(id) => history.push(`/maintenance/${id}`)}
              columns={ticketColumnsV2}
              rowsPerPage={pageSize}
              page={pageNo}
              onPageChange={(page, pageN) => {
                setPageNo(pageN);
              }}
              onRowsPerPageChange={(rowsPerPage) =>
                setPageSize(Number(rowsPerPage.target.value))
              }
              searchText={search}
              searchPlaceholder={`Search maintenance tickets`}
              handleSearch={(search) => setSearch(search.target.value)}
            />
          }
          title={'Maintenance Tickets'}
        />
      </>
    );
  };

  return (
    <PageContainer isLoading={ticketsQuery.isLoading}>
      <PageContent
        backLink={'/problems'}
        contentTitle={'Support Tickets'}
        itemName={'Support Ticket'}
        itemChipValue={problemId}
        detailIcon={<SupportAgentIcon />}
        chipLabel={'Ticket ID'}
        dataId="ticket-details"
        tabsChildren={
          <Grid container spacing={2}>
            <Grid xs={12} lg={4}>
              <Stack spacing={2}>
                <CardView
                  content={content()}
                  title="Support Ticket Details"
                  isLoading={ticketsQuery.isLoading}
                  dataId="ticket-details"
                />
              </Stack>
            </Grid>
            <Grid xs={12} lg={8}>
              <Stack spacing={2}>
                <CommunicationLog
                  ticketDescription={
                    <>
                      <Box className="messageRow">
                        <Box className="flexDisplay flexWrap">
                          <Typography variant="subtitle1" className="topRow">
                            Description
                          </Typography>
                        </Box>
                        <>
                          {problem.content
                            ? problem.content.split('\n').map((p, i) => (
                                <Typography
                                  align={'justify'}
                                  variant="body2"
                                  className="messageContent"
                                  key={i}
                                >
                                  {p.toString()}
                                </Typography>
                              ))
                            : ''}{' '}
                        </>
                      </Box>
                    </>
                  }
                  buttons={2}
                  log={problem.communication_log}
                  note={note}
                  placeholder="New message"
                  noNoteText="No communication for this support ticket"
                  handleNoteChange={handleNoteChange}
                  saveNote={() => setShowEmailModal(true)}
                  saveButtonText="Send to User"
                  secondarySaveNote={addInternalNote}
                  loaded={!problemQuery.isLoading}
                />
                {maintenanceContent()}
              </Stack>
            </Grid>
          </Grid>
        }
        itemTopButtons={button()}
      />

      <MovaticCustomModal
        open={showCloseModal}
        close={() => setShowCloseModal(false)}
        content="Are you sure you want to close this support ticket?"
        title="Close Support Ticket"
        action={
          <IconButtonMenu
            buttons={[
              { label: 'No', onClick: () => setShowCloseModal(false) },
              {
                label: 'Yes',
                onClick: () => {
                  const problemAux = problem;
                  problemAux.state = 1;
                  CustomAsyncToast({
                    promise: () => updateIssue(problemAux),
                    successMessage: () => 'Support ticket closed successfully',
                    loadingMessage: 'Closing problem report...',
                    errorMessage:
                      'Oops! Looks like there was an error while closing the support ticket. Please try again later.',
                  }).then((results) => {
                    if (results) {
                      problemQuery.refetch();
                      history.push('/problems');
                    }
                  });
                  setShowCloseModal(false);
                },
              },
            ]}
          />
        }
      />

      <MovaticCustomModal
        open={showEmailModal}
        close={() => setShowEmailModal(false)}
        content="Send this email to the customer?"
        title="Contact User"
        action={
          <IconButtonMenu
            buttons={[
              { label: 'Cancel', onClick: () => setShowEmailModal(false) },
              { label: 'Confirm', onClick: sendEmail },
            ]}
          />
        }
      />

      <MovaticCustomModal
        open={addTagModal}
        close={() => setAddTagModal(false)}
        content={
          <MovaticFieldGroupSelectFilter
            label={'Tags'}
            isLoading={isLoading}
            value={value}
            onChange={(event, newValue) => {
              setValue([
                ...newValue.filter((option) =>
                  loadedTags.some((item) => item.label === option.label)
                ),
              ]);
            }}
            options={loadedTags}
          />
        }
        title="Add Tag"
        action={
          <IconButtonMenu
            buttons={[
              { label: 'Cancel', onClick: () => setAddTagModal(false) },
              {
                label: 'Save',
                disabled: loadedTags?.length === 0,
                onClick: () => {
                  CustomAsyncToast({
                    promise: () =>
                      addTagsToErrorReport(problem.gid, {
                        tags: [
                          ...(problem?.tags || []),
                          ...value.map((item) => item.label),
                        ],
                      }),
                    successMessage: () => 'Tags added successfully',
                    loadingMessage: 'Adding tags...',
                    errorMessage:
                      'Oops! Looks like there was an error while adding the tags. Please try again later.',
                  }).then((results) => {
                    if (results) {
                      setAddTagModal(false);
                      problemQuery.refetch().then(() => {
                        setValue([]);
                      });
                    }
                  });
                },
              },
            ]}
          />
        }
      />
      <MovaticCustomModal
        customStyles={{
          content: {
            minWidth: 'none',
          },
        }}
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: isSmallScreen ? '90vw' : '30vw',
            margin: isSmallScreen ? 1 : 'auto',
            width: '100%',
          },
        }}
        open={deleteTagModal}
        onClose={() => setDeleteTagModal(false)}
        content={`Are you sure you want to remove the tag ${tagToDelete}?`}
        title={'Remove Tag'}
        action={
          <IconButtonMenu
            buttons={[
              {
                label: 'Cancel',
                onClick: () => setDeleteTagModal(false),
              },
              {
                label: 'Submit',
                onClick: () => {
                  CustomAsyncToast({
                    promise: () =>
                      addTagsToErrorReport(problem.gid, {
                        tags: problem.tags
                          ? problem.tags.filter((item) => item !== tagToDelete)
                          : [],
                      }),
                    successMessage: () => 'Tag removed successfully',
                    loadingMessage: 'Removing tag...',
                    errorMessage:
                      'Oops! Looks like there was an error while removing the tag. Please try again later.',
                  }).then((results) => {
                    if (results) {
                      setDeleteTagModal(false);
                      problemQuery.refetch().then(() => {
                        setTagToDelete('');
                      });
                    }
                  });
                },
              },
            ]}
          />
        }
      />
    </PageContainer>
  );
}

export default connect(
  (state) => ({
    admin: state.admin.admin.admin,
    system: state.system.current,
    systemLoaded: state.system.isLoaded,
  }),
  () => ({})
)(ProblemDetails);
