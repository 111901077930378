import React, { useState } from 'react';
import type { IStatementBillingDescriptorCardProps } from '../../../ts/interface/pages/settings.interface';
import { BILLING_DESCRIPTOR_MAX_LENGTH } from '../../../constants';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import SaveIcon from '@mui/icons-material/Save';
import CardView from '../../../components/Redesing/card-view';
import { Stack } from '@mui/system';
import { MovaticFieldGroupText } from '../../../components/Redesing/movatic-field-group';

export default function StatementBillingDescriptorCard({
  bankAccess,
  movaticAccess,
  saveButtonLoading,
  statementBillingDescriptor,
  handleChange,
  handleSubmit,
}: IStatementBillingDescriptorCardProps) {
  const [error, setError] = useState<{ error: string | null; help: string }>({
    error: null,
    help: '',
  });

  return (
    <CardView
      title="Billing Descriptor"
      content={
        <Stack px={2} pb={2}>
          <MovaticFieldGroupText
            id="statementBillingDescriptor"
            name={'statementBillingDescriptor'}
            label="Statement Billing Descriptor"
            description={`You can set a custom billing descriptor that will appear on the user's credit card statement when they are charged. The descriptor can be up to ${BILLING_DESCRIPTOR_MAX_LENGTH} characters long.`}
            hasError={Boolean(error.error)}
            errorMessage={error.help}
            value={statementBillingDescriptor}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { value } = e.target;
              const errorText =
                value.length > BILLING_DESCRIPTOR_MAX_LENGTH
                  ? `Statement Billing Descriptor must be ${BILLING_DESCRIPTOR_MAX_LENGTH} characters or less.`
                  : '';
              setError({ error: errorText ? 'error' : null, help: errorText });
              handleChange(e);
            }}
          />
        </Stack>
      }
      headerActions={
        <>
          <IconButtonMenu
            buttons={[
              {
                label: 'Save',
                startIcon: <SaveIcon />,
                onClick: () =>
                  handleSubmit({
                    card: 'statementBillingDescriptor',
                    statementBillingDescriptor,
                  }),
                disabled:
                  !saveButtonLoading ||
                  !(bankAccess || movaticAccess) ||
                  Boolean(error.error),
              },
            ]}
          />
        </>
      }
    />
  );
}
