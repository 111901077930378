import React from 'react';
import { IMode } from '../../ts/interface/mode.interface';
import { connect } from 'react-redux';
import { ISystem } from '../../ts/interface/system.interface';
import PageContainer from '../../components/Redesing/page-container';
import RentalsTableCard from '../../components/Redesing/table/rental/rentals-table-card';

const Rentals = ({ systemLoaded }: { systemLoaded: boolean }) => {
  return (
    <PageContainer isLoading={!systemLoaded} spaceName={'Rentals'}>
      <RentalsTableCard noRentalsText={'No rentals available'} />
    </PageContainer>
  );
};

const RentalsR = connect(
  (state: { system: ISystem; mode: IMode }) => ({
    modeInfo: state.mode,
    systemLoaded: state.system.isLoaded,
  }),
  () => ({})
)(Rentals);

export default RentalsR;
