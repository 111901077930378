export interface IUserDetail {
  amount_due: number;
  appVersion: string;
  autoGenNumber: boolean;
  banned: boolean;
  deviceCarrier: string;
  deviceModel: string;
  deviceOS: string;
  deviceOSVersion: string;
  emailAddress: string;
  fullName: string;
  has_membership: boolean;
  id: string;
  joined: string;
  phone: string;
  removed: boolean;
  status: string;
  acceptedTOSOn: string;
  hidden: boolean;
}

export enum UserDetailStatus {
  'BLACKLISTED' = 'blacklisted',
  'ACTIVE' = 'active',
  'INACTIVE' = 'inactive',
}
