import {
  getCredentials,
  fetchOptionsV2,
  baseUrlV2,
  createQueryParameters,
  fetchOptions,
  baseUrl,
} from './http';
import { displayError } from '../utils';
import { FourOhOneError } from '../actionCreators/system';

export const getStations = (parameters) => {
  const { adminEmail, adminToken, systemGid } = getCredentials();
  const fetchOps = fetchOptionsV2(adminEmail, adminToken);

  const queryParametersObject = {
    system_id: systemGid || parameters.system_id,
    location_id: parameters?.locationId,
    limit: parameters?.limit || 100,
    last_id: parameters?.lastId,
    ...(parameters?.status && { status: parameters.status }),
    ...(parameters?.pattern && { pattern: parameters.pattern }),
    private_only: parameters?.private_only,
    order: parameters?.order,
    order_by: parameters?.order_by,
    fields: parameters?.fields,
  };

  const queryParameters = createQueryParameters(queryParametersObject);

  return fetch(`${baseUrlV2}/station/?${queryParameters}`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response?.data) {
        return response;
      }
      if (response.status === 404) {
        return null;
      }
      throw new Error(response?.error_description);
    })
    .catch((error) => {
      displayError(
        'Oops! Looks like there was an error while loading the location. Please try again later.'
      );
      return [];
    });
};

export const updateStation = (body) => {
  const { adminEmail, adminToken, systemGid } = getCredentials();
  const putBody = { ...body, system_id: systemGid };
  const fetchOps = fetchOptionsV2(adminEmail, adminToken, 'PUT', putBody);
  return fetch(`${baseUrlV2}/station/${body.id}`, fetchOps)
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }
      FourOhOneError(response);
      throw new Error(response?.error_description);
    })
    .catch((error) => {
      return Promise.reject(`${error}`);
    });
};

export const retireStation = (locationId) => {
  const { adminEmail, adminToken } = getCredentials();
  const fetchOps = fetchOptionsV2(adminEmail, adminToken, 'POST', {
    location_id: locationId,
  });

  return fetch(`${baseUrlV2}/station/retire`, fetchOps)
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }
      FourOhOneError(response);
      throw new Error(response?.error_description);
    })
    .catch((error) => {
      return Promise.reject(`${error}`);
    });
};

export const createLocation = (body) => {
  const { adminEmail, adminToken, systemGid } = getCredentials();
  const postBody = { ...body, system_id: systemGid };
  const fetchOps = fetchOptionsV2(adminEmail, adminToken, 'POST', postBody);

  return fetch(`${baseUrlV2}/station`, fetchOps)
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }
      FourOhOneError(response);
      throw new Error(response?.error_description);
    })
    .catch((error) => {
      return Promise.reject(`${error}`);
    });
};

export const getStationStats = async ({ location_uuid, system_gid }) => {
  const { adminEmail, adminToken } = getCredentials();
  const fetchOps = fetchOptionsV2(adminEmail, adminToken);

  try {
    const result = await fetch(
      `${baseUrlV2}/station/${location_uuid}/stats?system_id=${system_gid}`,
      fetchOps
    );

    const { data, status, error_description } = await result.json();

    if (data) return data;
    if (status === 404) return null;

    throw new Error(error_description);
  } catch (error) {
    displayError(
      'Oops! Looks like there was an error while loading the location stats. Please try again later.'
    );
    return null;
  }
};

export const getStationImages = () => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);
  return fetch(`${baseUrl}/approve-image/images/station`, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        return results.message || [];
      }
      FourOhOneError(results);
      throw new Error(results.message);
    })
    .catch((error) => {
      displayError('There was an error getting images');
    });
};
