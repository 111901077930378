import {
  CHANGE_MODE,
  LOCKER,
  RACK,
  SCOOTER,
  KAYAK,
  BIKE,
  BEACH_CHAIR,
  KEYBOX,
  BIKE_ROOM,
  STATION_TITLE,
} from '../constants';
import { toTitleCase } from '../utils';

function makeModeConstants(modeInfo) {
  const details = {
    unitsTitle: toTitleCase(modeInfo.type_name),
    pluralUnits: toTitleCase(modeInfo.type_name_plural),
    hardware_type: modeInfo.type,
    hardware_type_name: modeInfo.type_name,
    hardware_type_gid: modeInfo.hardware_type_gid,
    stationsTitle: STATION_TITLE,
  };

  // eslint-disable-next-line default-case
  switch (modeInfo.type) {
    case LOCKER:
      return {
        currentMode: 'bike_locker',
        details,
        configuration: null,
        icon: 'fa fa-bicycle fa-fw',
      };

    case KEYBOX:
      return {
        currentMode: 'bikeshare(keybox)',
        details,
        configuration: null,
        icon: 'fa fa-bicycle fa-fw',
      };

    case RACK:
    case BIKE_ROOM:
      return {
        currentMode: 'bikeRoom',
        details,
        configuration: null,
        icon: 'fa fa-bicycle fa-fw',
      };

    case SCOOTER:
      return {
        currentMode: 'scooter',
        details,
        configuration: null,
        icon: 'fa fa-bolt fa-fw',
      };

    case KAYAK:
      return {
        currentMode: 'kayak',
        details,
        configuration: null,
        icon: 'fa fa-anchor fa-fw',
      };

    case BIKE:
      return {
        currentMode: 'bikeshare',
        details,
        configuration: null,
        icon: 'fa fa-bicycle fa-fw',
      };

    case BEACH_CHAIR:
      return {
        currentMode: 'beachchair',
        details,
        configuration: null,
        icon: 'fa fa-umbrella-beach fa-fw',
      };
  }
}

export const setMode = (mode) => ({
  type: CHANGE_MODE,
  value: makeModeConstants(mode),
});
