import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { IAdmin } from '../../ts/interface/admins.interface';
import { useParams } from 'react-router-dom';
import useUserMemberships from '../../hooks/membership/query/useUserMemberships';
import {
  formatPhoneNumber,
  getFormattedMembershipPrice,
  getLocaleDateTimeString,
  history,
  secondsToMinFormat,
  toTitleCase,
} from '../../utils';
import {
  addMinutesToMembership,
  addUnlocksToMembership,
  cancelMembership,
  expireMembership,
  discountMinutesToMembership,
  discountUnlocksToMembership,
  membershipApproval,
} from '../../api/memberships';
import { PropertyList } from '../../components/Redesing/property-list';
import PropertyListItemLink from '../../components/Redesing/property-list-item-link';
import Divider from '@mui/material/Divider';
import { PropertyListItem } from '../../components/Redesing/property-list-item';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import {
  MovaticFieldGroupText,
  MovaticFieldGroupSelect,
  MovaticFieldGroupOutlinedInput,
} from '../../components/Redesing/movatic-field-group';
import MovaticCustomModal from '../../components/Modal/MovaticCustomModal';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import PageContainer from '../../components/Redesing/page-container';
import CardView from '../../components/Redesing/card-view';
import { Stack } from '@mui/system';
import { SeverityPill } from '../../components/Redesing/severity-pill';
import PageContent from '../../components/Redesing/page-content';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { MenuItem } from '@mui/material';
import { useNavigationContext } from '../../context/navigation-context';
import UserMembershipDocumentsList from './assets/user-membership-documents-list';

interface AddSubstractOperation {
  value: string;
  name: string;
}

interface MinutesUnlocksOperation {
  value: string;
}
interface State {
  cancellationReason: string;
  cancellationReasonValidation: null | any; // replace `any` with your validation type if available
  cancellationReasonHelp: null | string;
  rejectionReason: string;
  rejectionReasonValidation: null | any; // replace `any` with your validation type if available
  rejectionReasonHelp: null | string;
  showRejectionModal: boolean;
  showCancellationModal: boolean;
  showExpirationModal: boolean;
  showMinutesModal: boolean;
  addSubstractOperation: AddSubstractOperation;
  typeOfOperation: MinutesUnlocksOperation;
  minutesUnlocksToAddOrDiscount: number;
  isLoading: boolean;
}

const UserMembershipDetails = ({ admins }: { admins: IAdmin[] }) => {
  const { membershipId } = useParams<{ membershipId: string }>();
  const initialState = {
    cancellationReason: '',
    cancellationReasonValidation: null,
    cancellationReasonHelp: null,
    rejectionReason: '',
    rejectionReasonValidation: null,
    rejectionReasonHelp: null,
    showRejectionModal: false,
    showCancellationModal: false,
    showExpirationModal: false,
    showMinutesModal: false,
    addSubstractOperation: { value: 'add', name: 'Add' },
    typeOfOperation: { value: 'minutes', name: 'Minutes' },
    minutesUnlocksToAddOrDiscount: 1,
    isLoading: false,
  };
  const [
    {
      cancellationReason,
      cancellationReasonValidation,
      cancellationReasonHelp,
      rejectionReason,
      rejectionReasonValidation,
      rejectionReasonHelp,
      showRejectionModal,
      showCancellationModal,
      showExpirationModal,
      showMinutesModal,
      typeOfOperation,
      addSubstractOperation,
      minutesUnlocksToAddOrDiscount,
      isLoading,
    },
    setState,
  ] = useState<State>(initialState);
  const { data: userMembershipsData, refetch } = useUserMemberships({
    user_membership_id: membershipId,
  });
  const userMembership = useMemo(() => userMembershipsData?.[0], [userMembershipsData]);
  const { manualUpdate } = useNavigationContext();
  const approveMembershipCallback = (payload: {}, action: string) => {
    const approving = action === 'approve';
    CustomAsyncToast({
      promise: () => membershipApproval(payload),
      successMessage: () =>
        approving
          ? 'Membership approved successfully'
          : 'Membership rejected successfully',
      errorMessage: approving
        ? 'Error approving membership'
        : 'Error rejecting membership',
      loadingMessage: approving ? 'Approving membership...' : 'Rejecting membership...',
      onErrorCallBack: (r: any) =>
        console.log(
          approving ? 'Error approving membership' : 'Error rejecting membership',
          r
        ),
    }).then((r) => {
      refetch().then(() => manualUpdate());
    });
  };

  const content = () => {
    if (!userMembership) return;
    const {
      membership,
      email_auth_code,
      created_on,
      approved_on,
      canceled_on,
      rejected_on,
      expires_on,
      last_charged_on,
      renewed_times,
      rejection_reason,
      membership_price,
      active,
      renews_on,
      canceled_by_admin,
      admin_cancellation_reason,
      cancelation_type,
      minutes_left,
      minutes_total,
      unlocks,
      email,
      unlock_count,
      additional_unlocks,
    } = userMembership;

    const formattedMembershipPrice = membership_price
      ? getFormattedMembershipPrice(membership_price)
      : '';

    const canceledByAdminIsOnSystem = !!admins.find(
      (admin: IAdmin) => admin.email === canceled_by_admin?.email
    );

    const totalUnlocks = unlocks ? unlocks + (additional_unlocks || 0) : 0;

    return (
      <Stack>
        <PropertyList>
          <PropertyListItemLink
            others={{
              divider: true,
            }}
            label="Membership"
            text={membership ? membership.name : ''}
            onClick={() => history.push(`/memberships/${membership.id}/general`)}
          />
          <PropertyListItemLink
            label="Phone Number"
            text={
              userMembership?.user ? formatPhoneNumber(userMembership?.user.phone) : ''
            }
            onClick={() => history.push(`/users/${userMembership?.user.phone}/general`)}
          />
          {email && (
            <>
              <Divider />
              <PropertyListItem label="Verified Email" value={email} />
            </>
          )}
          {userMembership?.email_address && (
            <>
              <Divider />
              <PropertyListItem label="Email" value={userMembership?.user.emailAddress} />
            </>
          )}
          {!approved_on && email_auth_code ? (
            <>
              <Divider />
              <PropertyListItem label="Email Auth Code" value={email_auth_code} />
            </>
          ) : null}
          {membership_price ? (
            <>
              <Divider />
              <PropertyListItem label="Pricing" value={formattedMembershipPrice} />
            </>
          ) : null}
          <Divider />
          <PropertyListItem label="Status" value={active ? 'Active' : 'Inactive'}>
            <SeverityPill color={active ? 'success' : 'error'}>
              {active ? 'Active' : 'Inactive'}
            </SeverityPill>
          </PropertyListItem>
          {membership_price?.interval ? (
            <>
              <PropertyListItem
                divider
                label="Free Renewals"
                value={userMembership.free_renewals}
              />
              <PropertyListItem label="Renewed Times" value={renewed_times} />
              {last_charged_on && (
                <>
                  <Divider />
                  <PropertyListItem
                    label="Last Charged On"
                    value={getLocaleDateTimeString(last_charged_on)}
                  />
                </>
              )}
            </>
          ) : null}
          {renews_on ? (
            <>
              <Divider />
              <PropertyListItem
                label="Renews On"
                value={getLocaleDateTimeString(renews_on)}
              />
            </>
          ) : null}
          {membership?.auth_type ? (
            <>
              <Divider />
              <PropertyListItem
                label="Requested On"
                value={getLocaleDateTimeString(created_on)}
              />
            </>
          ) : (
            <>
              <Divider />
              <PropertyListItem
                label="Created On"
                value={getLocaleDateTimeString(created_on)}
              />
            </>
          )}
          {approved_on && membership?.auth_type ? (
            <>
              <Divider />
              <PropertyListItem
                label="Approved On"
                value={getLocaleDateTimeString(approved_on)}
              />
            </>
          ) : null}
          {canceled_on ? (
            <>
              <Divider />
              <PropertyListItem
                label="Canceled On"
                value={getLocaleDateTimeString(canceled_on)}
              />
            </>
          ) : null}
          {canceled_on && cancelation_type ? (
            <>
              <Divider />
              <PropertyListItemLink
                label="Canceled By"
                text={
                  canceled_by_admin?.full_name
                    ? canceled_by_admin.full_name +
                      (canceledByAdminIsOnSystem ? '' : ` (${canceled_by_admin.email})`)
                    : toTitleCase(cancelation_type)
                }
                onClick={
                  canceledByAdminIsOnSystem
                    ? () => history.push(`/settings/admin/${canceled_by_admin.email}`)
                    : undefined
                }
              />
            </>
          ) : null}
          {canceled_by_admin && admin_cancellation_reason ? (
            <>
              <Divider />
              <PropertyListItem
                label="Cancellation Reason"
                value={admin_cancellation_reason}
              />
            </>
          ) : null}
          {rejected_on ? (
            <>
              <Divider />
              <PropertyListItem
                label="Rejected On"
                value={getLocaleDateTimeString(rejected_on)}
              />
            </>
          ) : null}
          {rejected_on && rejection_reason && (
            <>
              <Divider />
              <PropertyListItem
                label="Rejection Reason"
                value={rejection_reason.toString()}
              />
            </>
          )}
          {expires_on ? (
            <>
              <Divider />
              <PropertyListItem
                label={new Date(expires_on) > new Date() ? 'Expires On' : 'Expired On'}
                value={getLocaleDateTimeString(expires_on)}
              />
            </>
          ) : null}
          {/* Verify that the membership contains minutes */}
          {minutes_total ? (
            <>
              <Divider />
              <PropertyListItem
                label="Remaining minutes"
                value={`${secondsToMinFormat(minutes_left)} min`}
              />
            </>
          ) : null}
          {/* Verify that the membership contains unlocks */}
          {unlocks ? (
            <>
              <Divider />
              <PropertyListItem label="Number of unlocks" value={unlock_count} />
            </>
          ) : null}
          {unlocks ? (
            <>
              <Divider />
              <PropertyListItem label="Unlocks per plan" value={unlocks} />
            </>
          ) : null}
          {unlocks ? (
            <>
              <Divider />
              <PropertyListItem label="Additional Unlocks" value={additional_unlocks} />
            </>
          ) : null}
          {unlocks ? (
            <>
              <Divider />
              <PropertyListItem
                label="Remaining Unlocks"
                value={totalUnlocks - unlock_count}
              />
            </>
          ) : null}
        </PropertyList>
      </Stack>
    );
  };
  const button = () => {
    if (!userMembership) return;
    const {
      id,
      membership,
      approved_on,
      rejected_on,
      expired,
      canceled,
      active,
      minutes_left,
    } = userMembership;

    if (membership?.auth_type === 'admin' && !expired && !canceled) {
      if (!approved_on && !rejected_on) {
        return (
          <IconButtonMenu
            buttons={[
              {
                label: 'Reject',
                onClick: () =>
                  setState((prevState) => ({
                    ...prevState,
                    showRejectionModal: true,
                  })),
                startIcon: <CloseIcon />,
              },
              {
                label: 'Approve',
                onClick: () =>
                  approveMembershipCallback(
                    { body: { approval: 'accept' }, userId: id },
                    'approve'
                  ),
                startIcon: <CheckCircleIcon />,
              },
            ]}
          />
        );
      }
      if (rejected_on) {
        return (
          <IconButtonMenu
            buttons={[
              {
                label: 'Approve',
                onClick: () =>
                  approveMembershipCallback(
                    { body: { approval: 'accept' }, userId: id },
                    'approve'
                  ),
              },
            ]}
          />
        );
      }
    }

    if (!canceled) {
      return (
        <IconButtonMenu
          buttons={[
            {
              label: 'Cancel',
              onClick: () =>
                setState((prevState) => ({
                  ...prevState,
                  showCancellationModal: true,
                })),
              startIcon: <CloseIcon />,
            },
            ...(active && minutes_left != null
              ? [
                  {
                    label: 'Add / Discount (Minutes / Unlocks)',
                    onClick: () =>
                      setState((prevState) => ({
                        ...prevState,
                        showMinutesModal: true,
                      })),
                    startIcon: <WatchLaterIcon />,
                  },
                ]
              : []),
          ]}
        />
      );
    }

    if (canceled && active && !expired) {
      return (
        <IconButtonMenu
          buttons={[
            {
              label: 'Expire',
              onClick: () =>
                setState((prevState) => ({
                  ...prevState,
                  showExpirationModal: true,
                })),
              startIcon: <CloseIcon />,
            },
          ]}
        />
      );
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
      [`${e.target.name}Validation`]: null,
      [`${e.target.name}Help`]: null,
    }));
  };

  const rejectionModalContent = () => {
    return (
      <MovaticFieldGroupText
        label="Rejection Reason"
        description="Provide a reason for why the request was rejected"
        name="rejectionReason"
        hasError={rejectionReasonValidation === 'error'}
        errorMessage={rejectionReasonHelp}
        value={rejectionReason}
        onChange={handleChange}
      />
    );
  };

  const cancellationModalContent = () => {
    return (
      <>
        <MovaticFieldGroupText
          label="Cancellation Reason"
          description="Provide a reason for why the membership is being cancelled"
          name="cancellationReason"
          hasError={cancellationReasonValidation === 'error'}
          errorMessage={cancellationReasonHelp}
          value={cancellationReason}
          onChange={handleChange}
        />
        {userMembership?.membership_price ? (
          <div>
            <p>
              <strong>Note:</strong> Cancelling the user membership will prevent it from
              renewing but the user will still have access to the membership until the end
              of the billing cycle. If you want to remove access immediately, you can
              expire it after cancelling.
            </p>
          </div>
        ) : null}
      </>
    );
  };

  const operationModalContent = () => {
    return (
      <>
        <MovaticFieldGroupSelect
          label="Type of Operation"
          name="select-operation-type"
          optionNodes={[
            { value: 'minutes', name: 'Minutes' },
            ...(userMembership?.unlocks ? [{ value: 'unlocks', name: 'Unlocks' }] : []),
          ].map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
          value={typeOfOperation.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setState((prevState) => ({
              ...prevState,
              typeOfOperation: { value: e.target.value },
            }));
          }}
          id="type-operation-id"
        />
        <MovaticFieldGroupSelect
          label="Select Operation"
          name="select-minutes-operation"
          optionNodes={[
            { value: 'add', name: 'Add' },
            { value: 'discount', name: 'Discount' },
          ].map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
          value={addSubstractOperation.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setState((prevState) => ({
              ...prevState,
              addSubstractOperation: { value: e.target.value, name: e.target.name },
            }));
          }}
          id="minutes-operation-id"
        />
        <MovaticFieldGroupOutlinedInput
          type={'number'}
          label="Minutes / Unlocks to add or discount"
          name="minutes-operation-add-discount"
          value={minutesUnlocksToAddOrDiscount}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setState((prevState) => ({
              ...prevState,
              minutesUnlocksToAddOrDiscount: parseInt(e.target.value),
            }));
          }}
          inputProps={{ step: 1, min: 1, max: 10000 }}
        />
      </>
    );
  };

  const submitRejectionModal = () => {
    if (!rejectionReason) {
      setState((prevState) => ({
        ...prevState,
        rejectionReasonValidation: 'error',
        rejectionReasonHelp: 'A reason is required',
      }));
      return;
    }
    approveMembershipCallback(
      {
        body: { approval: 'reject', rejectionReason: rejectionReason.trim() },
        userId: userMembership.id,
      },
      'reject'
    );
    setState((prevState) => ({
      ...prevState,
      showRejectionModal: false,
    }));
  };

  const submitCancellationModal = () => {
    if (!cancellationReason) {
      setState((prevState) => ({
        ...prevState,
        cancellationReasonValidation: 'error',
        cancellationReasonHelp: 'A reason is required',
      }));
      return;
    }
    CustomAsyncToast({
      promise: () => cancelMembership(userMembership.id, cancellationReason.trim()),
      successMessage: () => 'Membership cancelled successfully',
      errorMessage: 'Error cancelling membership',
      loadingMessage: 'Cancelling membership...',
    }).then((r) => {
      if (r) {
        refetch();
        setState((prevState) => ({
          ...prevState,
          showCancellationModal: false,
        }));
      }
    });
  };

  const submitExpirationModal = () => {
    CustomAsyncToast({
      promise: () => expireMembership(userMembership.id),
      successMessage: () => 'Membership expired successfully',
      errorMessage: 'Error expiring membership',
      loadingMessage: 'Expiring membership...',
    }).then((r) => {
      if (r) {
        refetch();
        setState((prevState) => ({
          ...prevState,
          showExpirationModal: false,
        }));
      }
    });
  };

  const submitOperation = () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    let membershipFetch: Promise<any>;
    if (typeOfOperation.value === 'minutes') {
      membershipFetch =
        addSubstractOperation.value === 'add'
          ? addMinutesToMembership(membershipId, minutesUnlocksToAddOrDiscount * 60)
          : discountMinutesToMembership(membershipId, minutesUnlocksToAddOrDiscount * 60);
    } else if (typeOfOperation.value === 'unlocks') {
      membershipFetch =
        addSubstractOperation.value === 'add'
          ? addUnlocksToMembership(membershipId, minutesUnlocksToAddOrDiscount)
          : discountUnlocksToMembership(membershipId, minutesUnlocksToAddOrDiscount);
    } else {
      return;
    }

    CustomAsyncToast({
      promise: () => membershipFetch,
      successMessage: () => 'The membership was updated successfully',
      loadingMessage: 'Updating membership...',
    })
      .then((r) => {
        if (r) {
          refetch();
        }
      })
      .finally(() =>
        setState((prevState) => ({
          ...prevState,
          showMinutesModal: false,
          minutesUnlocksToAddOrDiscount: 1,
          isLoading: false,
        }))
      );
  };

  const secondContent = () => {
    if (!userMembership) return;
    const { data } = userMembership;
    const docs = data?.docs ? JSON.parse(data?.docs) : [];
    docs.map((doc: any, idx: number) => {
      return (doc.id = idx);
    });
    return <>{docs?.length > 0 && <UserMembershipDocumentsList docs={docs} />}</>;
  };

  return (
    <PageContainer isLoading={!userMembership?.id}>
      <PageContent
        backLink={`/memberships/${userMembership?.membership?.id}/general`}
        contentTitle={'Membership Details'}
        itemName={userMembership?.user?.full_name || ''}
        itemChipValue={userMembership?.id || ''}
        chipLabel={'User Membership ID'}
        itemTopButtons={button()}
        tabsChildren={
          <Stack spacing={2}>
            <CardView content={content()} title={userMembership?.user?.full_name || ''} />
            {secondContent()}
          </Stack>
        }
      />

      <MovaticCustomModal
        open={showRejectionModal}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            showRejectionModal: false,
          }))
        }
        content={rejectionModalContent()}
        title="Reject Membership Request"
        action={
          <IconButtonMenu
            buttons={[
              {
                label: 'Cancel',
                dataId: 'cancel-rejection',
                onClick: () =>
                  setState((prevState) => ({
                    ...prevState,
                    showRejectionModal: false,
                  })),
                startIcon: <CloseIcon />,
              },
              {
                label: 'Reject',
                dataId: 'submit-rejection',
                onClick: submitRejectionModal,
                startIcon: <SaveIcon />,
              },
            ]}
          />
        }
      />

      <MovaticCustomModal
        open={showCancellationModal}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            showCancellationModal: false,
          }))
        }
        content={cancellationModalContent()}
        title="Cancel Membership"
        action={
          <IconButtonMenu
            buttons={[
              {
                label: 'Cancel',
                dataId: 'cancel-cancellation',
                onClick: () =>
                  setState((prevState) => ({
                    ...prevState,
                    showCancellationModal: false,
                  })),
                startIcon: <CloseIcon />,
              },
              {
                label: 'Submit',
                dataId: 'submit-cancellation',
                onClick: submitCancellationModal,
                startIcon: <SaveIcon />,
              },
            ]}
          />
        }
      />

      <MovaticCustomModal
        open={showExpirationModal}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            showExpirationModal: false,
          }))
        }
        content="Are you sure you want to expire this user membership? This will prevent the user from using the membership for the remainder of the billing cycle."
        title="Expire Membership"
        action={
          <IconButtonMenu
            buttons={[
              {
                label: 'Cancel',
                dataId: 'cancel-expiration',
                onClick: () =>
                  setState((prevState) => ({
                    ...prevState,
                    showExpirationModal: false,
                  })),
                startIcon: <CloseIcon />,
              },
              {
                label: 'Submit',
                dataId: 'submit-expiration',
                onClick: submitExpirationModal,
                startIcon: <SaveIcon />,
              },
            ]}
          />
        }
      />

      <MovaticCustomModal
        open={showMinutesModal}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            showMinutesModal: false,
          }))
        }
        content={operationModalContent()}
        title="Membership Operation"
        action={
          <IconButtonMenu
            buttons={[
              {
                label: 'Cancel',
                dataId: 'cancel-operation',
                onClick: () =>
                  setState((prevState) => ({
                    ...prevState,
                    showMinutesModal: false,
                  })),
                startIcon: <CloseIcon />,
              },
              {
                label: 'Save',
                dataId: 'submit-operation',
                onClick: () => submitOperation(),
                startIcon: <SaveIcon />,
                disabled: isLoading,
              },
            ]}
          />
        }
      />
    </PageContainer>
  );
};

export default connect(
  (state: { system: { isLoaded: boolean }; admin: { systemAdmins: IAdmin[] } }) => ({
    systemLoaded: state.system.isLoaded,
    admins: state.admin.systemAdmins,
  }),
  () => ({})
)(UserMembershipDetails);
