// TODO: just instantiate bugsnag on production mode
// to get rid of the error msg on testing
import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

const bugsnagClientWrapper = () => {
  const ErrorBoundary = (props) => {
    console.error('BUGSNAG NOTIFICATIONS OFF (DEV MODE) ==========');
    return props.children;
  };

  const devClient = {
    notify: (e) => {
      console.error('BUGSNAG DEV NOTIFY ======');
      console.error(e);
      console.error('===');
    },
    getPlugin: () => ErrorBoundary,
  };

  const client =
    window.location.hostname === 'admin.movatic.co'
      ? Bugsnag.start({
          apiKey: process.env.REACT_APP_BUGSNAG_KEY,
          plugins: [new BugsnagPluginReact(React)],
        })
      : devClient;

  return client;
};

export default bugsnagClientWrapper();
