import React, { useCallback, useState } from 'react';
import { PaginationTable } from '../../../components/Redesing/table/pagination-table';
import { IPrice } from '../../../ts/interface/membership.interface';
import { addMembershipPricingColumnsV2 } from '../../../tableColumns/membershipPricingColumns';
import AddIcon from '@mui/icons-material/Add';
import Card from '@mui/material/Card';
import { IColumn } from '../../../ts/interface/components/redesing/table.interface';

interface IMembershipPricingProps {
  prices: IPrice[];
  onAddPrice: () => void;
  handleRemovePricing?: (id: number) => void;
  handlePricesTableRowClick?: (id: number) => void;
}

const MembershipPricing: React.FC<IMembershipPricingProps> = (props) => {
  const [searchRate, setSearchRate] = React.useState('');
  const [pageRate, setPageRate] = React.useState(0);
  const [rowsPerPageRate, setRowsPerPageRate] = React.useState(5);
  const [sortData, setSortData] = useState<string>('system_name');

  const columns = useCallback(
    () => addMembershipPricingColumnsV2(props.handleRemovePricing),
    [props.handleRemovePricing]
  );

  return (
    <Card>
      <PaginationTable
        padding={{ pt: 1, pl: 0, pr: 1 }}
        buttons={[
          {
            label: 'Add',
            onClick: props.onAddPrice,
            startIcon: <AddIcon />,
            variant: 'contained',
          },
        ]}
        onRowCLick={(id) =>
          props.handlePricesTableRowClick
            ? props.handlePricesTableRowClick(id)
            : undefined
        }
        showFilter={false}
        sortBy={sortData}
        onSortChange={(sortBy) => setSortData(sortBy.key)}
        items={props.prices || []}
        noDataText={'This membership has no prices at this time'}
        columns={columns() as IColumn[]}
        rowsPerPage={rowsPerPageRate}
        page={pageRate}
        onPageChange={(page, pageN: number) => setPageRate(pageN)}
        onRowsPerPageChange={(rowsPerPage) =>
          setRowsPerPageRate(Number(rowsPerPage.target.value))
        }
        searchText={searchRate}
        searchPlaceholder={'Search membership prices'}
        handleSearch={(search) => setSearchRate(search.target.value)}
      />
    </Card>
  );
};

export default MembershipPricing;
