import { useQuery } from '@tanstack/react-query';
import { getHardwareEventLogs } from '../../../api/hardware';
import { IUseHardwareEventLogsFilter } from '../../../ts/interface/hardware.interface';

const useHardwareEventLogs = (
  id: string,
  parameters: IUseHardwareEventLogsFilter,
  options?: {}
) => {
  return useQuery(
    [
      'HardwareEventLogs',
      id,
      parameters?.after,
      parameters?.before,
      parameters?.limit,
      parameters?.order,
      parameters?.order_by,
      parameters?.after_created_on,
      parameters?.before_created_on,
      parameters?.filter_types,
    ],
    () => getHardwareEventLogs(id, parameters),
    {
      ...options,
    }
  );
};

export default useHardwareEventLogs;
