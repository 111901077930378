import PedalBikeIcon from '@mui/icons-material/PedalBike';
import ElectricBikeIcon from '@mui/icons-material/ElectricBike';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import ElectricScooterIcon from '@mui/icons-material/ElectricScooter';
import LockIcon from '@mui/icons-material/Lock';
import StorageOutlinedIcon from '@mui/icons-material/Storage';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import KayakingIcon from '@mui/icons-material/Kayaking';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import InboxIcon from '@mui/icons-material/Inbox';
import { getInitials } from '../utils';
import ListAltOutlinedIcon from '@mui/icons-material/ListAlt';
import DockIcon from '@mui/icons-material/Dock';
import StoreIcon from '@mui/icons-material/Store';
import WarehouseOutlinedIcon from '@mui/icons-material/Warehouse';
import PentagonTwoToneIcon from '@mui/icons-material/PentagonTwoTone';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import { StationConfiguration } from '../ts/interface/station.interface';
import BikeScooterIcon from '@mui/icons-material/BikeScooter';

export const getTimeList = () => {
  const availableOptions = [];
  let currentTime = 0; // Start at 12:00 AM

  while (currentTime < 24 * 60 * 60) {
    // Convert time in seconds to HH:MM AM/PM format
    const hours = Math.floor(currentTime / 3600) % 12 || 12;
    const minutes = Math.floor((currentTime % 3600) / 60);
    const ampm = currentTime < 12 * 3600 ? 'AM' : 'PM';

    const label = `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')} ${ampm}`;
    availableOptions.push({ label, value: currentTime });

    // Increment by 30 minutes (1800 seconds)
    currentTime += 1800;
  }
  return availableOptions;
};

export const getHardwareIconByName = (name: string = '') => {
  let icon, bgColor;
  switch (name) {
    case 'bike':
      icon = PedalBikeIcon;
      bgColor = '#0A3255'; // Main color for a bike
      break;
    case 'e_bike':
      icon = ElectricBikeIcon;
      bgColor = '#1E88E5'; // Light color for an electric bike
      break;
    case 'locker':
      icon = RoomPreferencesIcon;
      bgColor = '#F79009'; // Main color for a locker
      break;
    case 'scooter':
      icon = ElectricScooterIcon;
      bgColor = '#077E84'; // Main color for a scooter
      break;
    case 'lock':
      icon = LockIcon;
      bgColor = '#111927'; // Dark color for a lock
      break;
    case 'base_station':
      icon = StorageOutlinedIcon;
      bgColor = '#2F3746'; // Dark color for a base station
      break;
    case 'battery':
      icon = BatteryFullIcon;
      bgColor = '#0B815A'; // Dark color for a battery
      break;
    case 'dock':
      icon = DockIcon;
      bgColor = '#0E7090'; // Dark color for a dock
      break;
    case 'kayak':
    case 'canoe':
      icon = KayakingIcon;
      bgColor = '#F58964'; // Main color for a kayak or canoe
      break;
    case 'rack':
      icon = ListAltOutlinedIcon;
      bgColor = '#4D5761'; // Main color for a rack
      break;
    case 'bike room':
      icon = MeetingRoomIcon;
      bgColor = '#06AED4'; // Main color for a bike room
      break;
    case 'beach_chair':
      icon = EventSeatIcon;
      bgColor = '#134E48'; // Lightest color for a beach chair
      break;
    case 'keybox':
      icon = InboxIcon;
      bgColor = '#F04438'; // Main color for a keybox
      break;
    default: // Default color
      // If the item name doesn't match any case, you can handle it accordingly.
      icon = BikeScooterIcon;
      bgColor = '#6C737F';
      break;
  }

  return { Icon: icon, bgColor };
};

export const getLocationIconByName = (name: string = '') => {
  const { ZONE, RACK, DOCK, STORE, WAREHOUSE } = StationConfiguration;

  let icon, bgColor;
  switch (name) {
    case RACK:
      icon = <ListAltOutlinedIcon />;
      bgColor = '#F58964'; // Replace with your color from the palette
      break;
    case ZONE:
      icon = <PentagonTwoToneIcon />;
      bgColor = '#F79009'; // Replace with your color from the palette
      break;
    case DOCK:
      icon = <DockIcon />;
      bgColor = '#00B7BE'; // Replace with your color from the palette
      break;
    case STORE:
      icon = <StoreIcon />;
      bgColor = '#134E48'; // Replace with your color from the palette
      break;
    case WAREHOUSE:
      icon = <WarehouseOutlinedIcon />;
      bgColor = '#06AED4'; // Replace with your color from the palette
      break;
    default: // Default color
      // If the item name doesn't match any case, you can handle it accordingly.
      icon = getInitials(name);
      bgColor = '#6C737F';
      break;
  }

  return { icon, bgColor };
};
