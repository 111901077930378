import React, { useState } from 'react';
import { RATE_DURATIONS } from '../../constants';
import { findAccess, history, timeForHumans } from '../../utils';
import { ISystem } from '../../ts/interface/system.interface';
import { IAuth } from '../../ts/interface/admins.interface';
import { addRate } from '../../api/rates';
import { connect } from 'react-redux';
import {
  MovaticFieldGroupCheck,
  MovaticFieldGroupDate,
  MovaticFieldGroupOutlinedInput,
  MovaticFieldGroupSelect,
  MovaticFieldGroupText,
} from '../../components/Redesing/movatic-field-group';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { Stack } from '@mui/system';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import SaveIcon from '@mui/icons-material/Save';
import PageContainer from '../../components/Redesing/page-container';
import CardView from '../../components/Redesing/card-view';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';

const AddRates = ({
  system,
  auth,
  movaticAccess,
  systemLoaded,
  systemAccess,
}: {
  system: ISystem;
  systemAccess: number;
  movaticAccess: number;
  auth: IAuth;
  systemLoaded: boolean;
}) => {
  const [activeDate, setActiveDate] = useState(new Date().toISOString());
  const [name, setName] = useState('');
  const [activeOn, setActiveOn] = useState(String(new Date()));
  const [membershipRequired, setMembershipRequired] = useState(false);
  const [freeTime, setFreeTime] = useState<string>('');
  const [maxPeriods, setMaxPeriods] = useState('');
  const [periodDuration, setPeriodDuration] = useState(3600);
  const [baseRate, setBaseRate] = useState(0);
  const [pricePerPeriod, setPricePerPeriod] = useState(0);
  const [reservation, setReservation] = useState(system.reservations);
  const [maxRentals, setMaxRentals] = useState('');
  const [maxRentalsWarning, setMaxRentalsWarning] = useState<string | null>(null);
  const [pricePeriodWarning, setPricePeriodWarning] = useState<string | null>(null);
  const [dateWarning, setDateWarning] = useState<string | null>(null);
  const [nameWarning, setNameWarning] = useState<string | null>(null);
  const [minimumWalletDeposit, setMinimumWalletDeposit] = useState<number | null>(null);
  const [minimumWalletDepositWarning, setMinimumWalletDepositWarning] = useState<
    string | null
  >(null);
  const [creditCardHold, setCreditCardHold] = useState<number | null>(null);
  const [creditCardHoldWarning, setCreditCardHoldWarning] = useState<string | null>(null);
  const [hideRate, setHideRate] = useState(false);

  const getRateString = () => {
    const time = RATE_DURATIONS.find(
      (duration) => duration.seconds.toString() === periodDuration.toString()
    );
    const stringDuration = time?.value;

    if (maxPeriods === '1') {
      // fixed
      // {price_per_period} for {period}
      return `$${Number(pricePerPeriod).toFixed(2) || '0.00'} for ${stringDuration}`;
    }
    // incremental
    // [{free_time} free, then] [{base_rate} +] {price_per_period} per {period}
    return `${freeTime ? `${timeForHumans(freeTime)} free, then ` : ''}${
      baseRate ? `$${Number(baseRate).toFixed(2)} + ` : ''
    }$${Number(pricePerPeriod).toFixed(2) || '0.00'} per ${stringDuration}`;
  };
  const dateChange = (value: string, formattedValue: string) => {
    const now = new Date();
    if (new Date(value).getTime() < now.getTime()) {
      setActiveDate(now.toISOString());
      setActiveOn(now.toISOString());
    } else {
      setActiveDate(value);
      setActiveOn(formattedValue);
    }
  };

  const canSubmit = () => {
    let canSubmit = true;
    if (!name || name === '') {
      canSubmit = false;
      setNameWarning('error');
    } else {
      setNameWarning(null);
    }

    if (!pricePerPeriod || pricePerPeriod === 0) {
      canSubmit = false;
      setPricePeriodWarning('error');
    } else {
      setPricePeriodWarning(null);
    }

    if (new Date(activeOn).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
      canSubmit = false;
      setDateWarning('error');
    } else {
      setDateWarning(null);
    }

    if (minimumWalletDeposit && Math.round(minimumWalletDeposit * 100) < 0) {
      canSubmit = false;
      setMinimumWalletDepositWarning('error');
    } else {
      setMinimumWalletDepositWarning(null);
    }

    if (creditCardHold && Math.round(creditCardHold * 100) < 0) {
      canSubmit = false;
      setCreditCardHoldWarning('error');
    } else {
      setCreditCardHoldWarning(null);
    }

    if (maxRentals && Number(maxRentals) < 1) {
      canSubmit = false;
      setMaxRentalsWarning('error');
    } else {
      setMaxRentalsWarning(null);
    }
    return canSubmit;
  };

  const saveRate = () => {
    if (canSubmit()) {
      const rate = {
        name: name.trim(),
        active_on: new Date(activeOn).toISOString(),
        membership_required: membershipRequired,
        free_time: maxPeriods !== '1' ? Number(freeTime) : null,
        max_periods: maxPeriods ? Number(maxPeriods) : null,
        period_duration: Number(
          periodDuration.toString() === 'None' ? 0 : periodDuration
        ),
        base_rate: maxPeriods !== '1' ? Math.round(baseRate * 100) : null,
        price_per_period: Math.round(pricePerPeriod * 100),
        reservation: reservation,
        max_rentals: Number(maxRentals) || null,
        minimum_wallet_deposit: minimumWalletDeposit
          ? Math.round(minimumWalletDeposit * 100)
          : null,
        credit_card_hold: creditCardHold ? Math.round(creditCardHold * 100) : null,
        hidden: hideRate,
      };
      CustomAsyncToast({
        promise: () => addRate(rate, null),
        successMessage: () => 'Your rate was added successfully!',
        loadingMessage: () => 'Adding rate...',
        errorMessage: () => 'There was an error adding the rate. Please try again later.',
      }).then((r) => {
        if (r) return;
        history.push('/billing/rates');
      });
    }
  };

  const content = () => {
    const systemUsesWallets = system.billing && system.wallet_required;
    return (
      <div>
        {/* NAME */}
        <MovaticFieldGroupText
          name="name"
          label="Name"
          description='An informative description for this rate (e.g. "Hourly," "Daily," etc). Users will see this name in the app.'
          hasError={nameWarning === 'error'}
          value={name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setName(e.target.value);
            setNameWarning(null);
          }}
        />

        {/* ACTIVE ON DATE */}
        <MovaticFieldGroupDate
          id="active_on"
          label="Active On"
          description="The date on which this rate will be implemented."
          value={new Date(activeDate) || null}
          hasError={dateWarning === 'error'}
          onChange={(date) => dateChange(date, date)}
        />

        {/* MAX NUMBER OF PERIODS */}
        <MovaticFieldGroupSelect
          label="Rate Type"
          description="Select incremental for rentals that can have multiple periods (e.g. an hourly rate) or fixed for rentals that will only be one period (e.g. a rental that lasts one day)"
          name={'max_periods'}
          optionNodes={[
            { value: 'None', name: 'Incremental Rate' },
            { value: 1, name: 'Fixed Rate' },
          ].map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
          value={maxPeriods || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setMaxPeriods(e.target.value);
          }}
          id="max_periods"
        />

        {/* PERIOD DURATION */}
        <MovaticFieldGroupSelect
          id="period_duration"
          label="Period Duration"
          name="period_duration"
          description="This amount of time will be used as a period. E.g. a one hour rental on a plan with a 30 minute periods would mean the user is billed 2 units at the set period price."
          optionNodes={RATE_DURATIONS.slice(1).map((option, index) => (
            <MenuItem key={index} value={option.seconds}>
              {option.value}
            </MenuItem>
          ))}
          value={periodDuration || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setPeriodDuration(Number(e.target.value));
          }}
        />
        {/* FREE TIME */}
        {maxPeriods !== '1' && (
          <MovaticFieldGroupSelect
            id="free_time"
            label="Free Time"
            description="Users with this rate will receive the following amount of time free."
            optionNodes={RATE_DURATIONS.map((option, index) => (
              <MenuItem key={index} value={option.seconds}>
                {option.value}
              </MenuItem>
            ))}
            value={freeTime || 0}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFreeTime(e.target.value);
            }}
          />
        )}
        {/* BASE RENTAL CHARGE */}

        {maxPeriods !== '1' && (
          <MovaticFieldGroupOutlinedInput
            type={'number'}
            label="Base Rental Charge"
            description="The base amount charged for every rental regardless of rental time (unless user is within the free time period)."
            value={baseRate || ''}
            inputProps={{ max: 99, min: 0, step: 0.25 }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setBaseRate(Number(e.target.value))
            }
            addOn={'$'}
          />
        )}
        {/* DURATION PRICE : PRICE PER PERIOD */}
        <MovaticFieldGroupOutlinedInput
          type={'number'}
          label={maxPeriods === '1' ? 'Duration Price' : 'Price per Period'}
          description={
            maxRentals === '1'
              ? 'This is how much the user will be charged for the rental'
              : 'This is how much the user will be charged for every unit of duration.'
          }
          value={pricePerPeriod || ''}
          hasError={pricePeriodWarning === 'error'}
          inputProps={{ max: 99, min: 0, step: 0.25 }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setPricePerPeriod(Number(e.target.value));
            setPricePeriodWarning(null);
          }}
          addOn={'$'}
        />

        {/* Simultaneous Rental Limit */}
        <MovaticFieldGroupOutlinedInput
          type={'number'}
          label="Max Open Rentals"
          description="The maximum number of units users are allowed to rent simultaneously. If left blank, the value will be set to the system max rental value."
          value={maxRentals || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setMaxRentals(e.target.value);
            setMaxRentalsWarning(null);
          }}
          hasError={maxRentalsWarning === 'error'}
        />

        {systemUsesWallets && (
          <MovaticFieldGroupOutlinedInput
            label="Minimum Wallet Balance"
            description="The minimum wallet balance that users charged with this rate need to have in order to start a rental. If left blank, the value in Settings will be used."
            type="number"
            name="minimum_wallet_deposit"
            id="minimum_wallet_deposit"
            value={minimumWalletDeposit || ''}
            inputProps={{ min: 0, step: 0.25 }}
            addOn={'$'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMinimumWalletDeposit(Number(e.target.value));
              setMinimumWalletDepositWarning(null);
            }}
            hasError={minimumWalletDepositWarning === 'error'}
          />
        )}

        {system.billing && (
          <MovaticFieldGroupOutlinedInput
            label="Credit Card Hold"
            description="The credit card hold amount that users charged with this rate will be applied in order to start a rental. If left blank, the value in Settings will be used."
            type="number"
            name="credit_card_hold"
            id="credit_card_hold"
            value={creditCardHold || ''}
            inputProps={{ min: 0, step: 0.25 }}
            addOn={'$'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCreditCardHold(Number(e.target.value));
              setCreditCardHoldWarning(null);
            }}
            hasError={creditCardHoldWarning === 'error'}
          />
        )}

        {system.new_hardware && (
          <MovaticFieldGroupCheck
            id="hide-rate"
            label="Hide Rate"
            description="If set, the rate will only be available when assigned to a location."
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setHideRate(e.target.checked)
            }
            checked={hideRate}
          />
        )}
        {/* RESERVATIONS */}
        {system.reservations && movaticAccess && !auth.admin.partner && (
          <MovaticFieldGroupCheck
            name="reservations"
            label="Reservations"
            description="Allow users to create reservations using this rate."
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setReservation(e.target.checked)
            }
            checked={reservation}
          />
        )}
        {/* MEMBERSHIP REQUIRED? */}
        {movaticAccess && !auth.admin.partner && (
          <MovaticFieldGroupCheck
            name="membership_required"
            label="Membership"
            description="This rate will not be available to users that do not have a membership to the system if selected."
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setMembershipRequired(e.target.checked)
            }
            checked={membershipRequired}
          />
        )}
        {/* EXAMPLE */}
        <Box
          sx={{
            //backgroundColor: 'primary.dark',
            border: '2px solid #000000', // Add border configuration here
            borderRadius: '20px',
            marginRight: 8.5,
            marginLeft: 8.5,
          }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={0.5}
            py={3}
          >
            <div
              style={{
                fontWeight: 'bold',
                // Add any other styles you need for the "subTitle" here
              }}
            >
              {getRateString()}
            </div>
            <div
              style={{
                fontWeight: 'bold',
                // Add any other styles you need for the "heavyFont" here
              }}
            >
              Example:
            </div>
            <div>A user rents a bike for 1 hour</div>
            <div>
              {freeTime && Number(freeTime) >= 3600 ? (
                <div>Within the free period—no charge</div>
              ) : (
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={0.5}
                >
                  <div>
                    base charge + ((rental time - free time) / durations) * (duration
                    charge) = total
                  </div>
                  <div>
                    {`${baseRate || 0} + (3600 ${
                      freeTime && freeTime !== 'None' ? `- ${freeTime}` : ''
                    } / ${periodDuration || 0}) * ${pricePerPeriod || 0} = $${
                      (baseRate ? Number(baseRate) : 0) +
                      Math.ceil(
                        (3600 -
                          (freeTime && freeTime !== 'None' ? Number(freeTime) : 0)) /
                          (periodDuration ? Number(periodDuration) : 0)
                      ) *
                        (pricePerPeriod ? Number(pricePerPeriod) : 0)
                    }`}
                  </div>
                </Stack>
              )}
            </div>
          </Stack>
        </Box>
      </div>
    );
  };

  return (
    <>
      <PageContainer isLoading={!systemLoaded}>
        <CardView
          content={
            <Stack px={2} pb={2}>
              {content()}
            </Stack>
          }
          title={'Add'}
          headerActions={
            <IconButtonMenu
              buttons={[
                {
                  label: 'Cancel',
                  onClick: () => history.push(`/billing/rates/`),
                },
                {
                  label: 'Save',
                  onClick: saveRate,
                  disabled: !findAccess(systemAccess).includes('rates') && !movaticAccess,
                  startIcon: <SaveIcon />,
                },
              ]}
            />
          }
        />
      </PageContainer>
    </>
  );
};

export default connect(
  (state: {
    admin: {
      systemAccess: number;
      admin: IAuth;
    };
    system: {
      current: ISystem;
      isLoaded: boolean;
    };
  }) => ({
    auth: state.admin.admin,
    movaticAccess: state.admin.admin.admin.movaticAccess,
    systemAccess: state.admin.systemAccess,
    system: state.system.current,
    systemLoaded: state.system.isLoaded,
  }),
  () => ({})
)(AddRates);
