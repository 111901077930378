import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import useCoupons from '../../hooks/coupons/query/useCoupons';
import { ICoupon } from '../../ts/interface/coupon.interface';
import { connect } from 'react-redux';
import { history } from '../../utils';
import PageContainer from '../../components/Redesing/page-container';
import PageContent from '../../components/Redesing/page-content';
import { Stack } from '@mui/system';
import { PropertyList } from '../../components/Redesing/property-list';
import { PropertyListItem } from '../../components/Redesing/property-list-item';
import { Card as MuiCard } from '@mui/material';
import CardView from '../../components/Redesing/card-view';
import RentalsTableCard from '../../components/Redesing/table/rental/rentals-table-card';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import EditIcon from '@mui/icons-material/Edit';
import { SeverityPill } from '../../components/Redesing/severity-pill';

const CouponDetail = ({ systemLoaded }: { systemLoaded: boolean }) => {
  const { couponId } = useParams<{ couponId: string }>();
  const [coupon, setCoupon] = useState<ICoupon>({} as ICoupon);
  const { isLoading } = useCoupons({
    enabled: systemLoaded,
    onSuccess: (data: { coupons: ICoupon[]; retired_coupons: ICoupon[] }) => {
      let coupon = data.coupons.find((coupon: ICoupon) => coupon.id === couponId);
      if (!coupon)
        coupon = data.retired_coupons.find((coupon: ICoupon) => coupon.id === couponId);
      if (coupon) {
        setCoupon(coupon);
      }
    },
  });

  return (
    <>
      <PageContainer isLoading={isLoading || !systemLoaded}>
        <PageContent
          backLink={'/billing/coupons'}
          contentTitle={'Coupons'}
          itemName={coupon.name || coupon.code || ''}
          itemChipValue={coupon.gid || coupon.id || ''}
          chipLabel={'Coupon ID'}
          tabsChildren={
            <Stack spacing={3}>
              <CardView
                title={'Coupon Details'}
                content={
                  <PropertyList>
                    <PropertyListItem divider label="Code" value={coupon?.code} />
                    <PropertyListItem
                      divider
                      label="Discount"
                      value={coupon?.discount || ''}
                    />
                    <PropertyListItem
                      divider
                      label="Valid For"
                      value={
                        coupon?.duration
                          ? coupon.duration
                          : coupon?.uses
                          ? coupon.uses
                          : ''
                      }
                    />
                    <PropertyListItem label="Active">
                      <SeverityPill color={coupon?.active ? 'success' : 'error'}>
                        {coupon?.active ? 'Yes' : 'No'}
                      </SeverityPill>
                    </PropertyListItem>
                  </PropertyList>
                }
              />
              <MuiCard>
                <RentalsTableCard
                  noRentalsText={
                    'This coupon has not been used in any rentals matching the criteria'
                  }
                  parameters={{ coupon_id: coupon?.gid }}
                />
              </MuiCard>
            </Stack>
          }
          itemTopButtons={
            <IconButtonMenu
              buttons={[
                {
                  label: 'Edit',
                  onClick: () => {
                    history.push(`/billing/coupon/edit/${couponId}`);
                  },
                  startIcon: <EditIcon />,
                },
              ]}
            />
          }
        />
      </PageContainer>
    </>
  );
};

export default connect(
  (state: { system: { isLoaded: boolean } }) => ({
    systemLoaded: state.system.isLoaded,
  }),
  () => ({})
)(CouponDetail);
