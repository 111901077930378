import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { findAccess, getLocalSeconds, getUtcSeconds } from '../../utils';
import { DEFAULT_GRACE_PERIOD, CENTS_PER_UNIT } from '../../constants';
import { IRentalInfoProps } from '../../ts/interface/pages/settings.interface';
import { isValidURL } from '../../util/isValidURL';
import { displayError } from '../../utils';
import { IStateReduxProps } from '../../ts/interface/reduxState.interface';
import FormItem from '../../components/Redesing/form-item';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { TextField } from '@mui/material';
import CardView from '../../components/Redesing/card-view';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import SaveIcon from '@mui/icons-material/Save';
import { Stack } from '@mui/system';
import { getTimeList } from '../../util/utilsR';
import {
  MovaticFieldGroupCheck,
  MovaticFieldGroupOutlinedInput,
} from '../../components/Redesing/movatic-field-group';

function getParkingCreditCalculation(parkingCredit: number | string | undefined) {
  const credit = parseInt(parkingCredit as string) > 0 ? (parkingCredit as number) : 0;
  return credit / CENTS_PER_UNIT;
}

const GRACE_PERIOD_OPTIONS = [
  { name: 'None', value: 0 },
  { name: '1 minute', value: 60 },
  { name: '2 minutes', value: 120 },
  { name: '3 minutes', value: 180 },
  { name: '4 minutes', value: 240 },
  { name: '5 minutes', value: 300 },
];

const DEFAULT_BOOLEAN_OPTIONS_FOR_CONFIG = [
  { name: 'Use partner default', value: 'null' },
  { name: 'Yes', value: 'true' },
  { name: 'No', value: 'false' },
];

interface StateProps {
  open_time: number;
  close_time: number;
  timezone_offset: number;
  timezone: string;
  maxOpenRentals: number | null;
  rentalLengthWarning: number;
  operatingHours: boolean | null;
  maxSpeed: number | null;
  outsideSystemMaxSpeed: number | null;
  supports_in_rental_unlock: boolean;
  round_trip: boolean;
  gracePeriod: number;
  forceEndPossible: boolean;
  rental: boolean;
  parking_credit_outside_designated: number;
  parking_credit_outside_system_service_zone: number;
  parking_credit_restricted_zone: number;
  parking_credit_preferred_parking_zone: number;
  parking_credit_parking_zone: number;
  parking_credit_rack_station: number;
  preventEndingRentals: string;
  allowEndingRentalsOutsideStation: string;
  enablePhotoEndRental: string;
  disableEndRentalNearestStation: string;
  enableRentalWebsite: boolean;
  endRentalWebsiteTitle: string;
  endRentalWebsiteMsg: string;
  endRentalWebsiteURL: string;
  useUserDeviceGps: string;
  disableUserEndRentals: string;
  disableUserStartRentals: string;
  enableAdminStartRentals: string;
  requireTermsAdditionalRentals: string;
  requireTosOnEveryRental: string;
  // used on submit handler ...updatedInfo mutation variables
  prevent_ending_rentals_outside_service_zone?: boolean | null;
  allow_ending_rentals_outside_station?: boolean | null;
  enable_photo_end_rental?: boolean | null;
  disable_end_rental_nearest_station?: boolean | null;
  use_user_device_gps?: boolean | null;
  disable_user_end_rentals?: boolean | null;
  disable_user_start_rentals?: boolean | null;
  enable_admin_start_rentals?: boolean | null;
  require_terms_additional_rentals?: boolean | null;
  require_tos_on_every_rental?: boolean | null;
  billing?: boolean;
  end_rental_website?: {
    title: string;
    message: string;
    url: string;
  };
}

const RentalInfo = (props: IRentalInfoProps) => {
  const [state, setState] = useState<StateProps>({} as StateProps);
  function syncStateWithProps(newProps: IRentalInfoProps) {
    let openTime = 0;
    let closeTime = 0;

    const {
      billing,
      allow_ending_rentals_outside_station,
      close_time,
      disable_end_rental_nearest_station,
      disable_user_end_rentals,
      disable_user_start_rentals,
      enable_admin_start_rentals,
      enable_photo_end_rental,
      forceEndPossible,
      gracePeriod,
      max_speed,
      maxOpenRentals,
      open_time,
      operatingHours,
      outside_system_max_speed,
      parking_credit_outside_designated,
      parking_credit_outside_system_service_zone,
      parking_credit_parking_zone,
      parking_credit_preferred_parking_zone,
      parking_credit_rack_station,
      parking_credit_restricted_zone,
      prevent_ending_rentals_outside_service_zone,
      rentalLengthWarning,
      require_terms_additional_rentals,
      require_tos_on_every_rental,
      round_trip = false,
      supports_in_rental_unlock,
      timezone_offset,
      timezone,
      use_user_device_gps,
      end_rental_website,
    } = newProps?.detailedSystem || {};

    if (timezone !== null && open_time !== null && close_time !== null) {
      const offset = moment.tz
        .zone(timezone)! // Non-null assertion
        .utcOffset(Date.now());
      openTime = getLocalSeconds(open_time, offset);
      closeTime = getLocalSeconds(close_time, offset);
    }

    setState({
      open_time: openTime,
      close_time: closeTime,
      timezone_offset,
      timezone,
      maxOpenRentals: maxOpenRentals || null,
      rentalLengthWarning,
      operatingHours,
      maxSpeed: max_speed,
      outsideSystemMaxSpeed: outside_system_max_speed,
      supports_in_rental_unlock: supports_in_rental_unlock,
      round_trip: round_trip || false,
      gracePeriod: gracePeriod ?? DEFAULT_GRACE_PERIOD,
      forceEndPossible: forceEndPossible,
      rental: false,
      parking_credit_outside_designated: getParkingCreditCalculation(
        parking_credit_outside_designated
      ),
      parking_credit_outside_system_service_zone: getParkingCreditCalculation(
        parking_credit_outside_system_service_zone
      ),
      parking_credit_restricted_zone: getParkingCreditCalculation(
        parking_credit_restricted_zone
      ),
      parking_credit_preferred_parking_zone: getParkingCreditCalculation(
        parking_credit_preferred_parking_zone
      ),
      parking_credit_parking_zone: getParkingCreditCalculation(
        parking_credit_parking_zone
      ),
      parking_credit_rack_station: getParkingCreditCalculation(
        parking_credit_rack_station
      ),
      preventEndingRentals: String(prevent_ending_rentals_outside_service_zone),
      enableRentalWebsite: !!end_rental_website?.title,
      endRentalWebsiteTitle: end_rental_website?.title || '',
      endRentalWebsiteMsg: end_rental_website?.message || '',
      endRentalWebsiteURL: end_rental_website?.url || '',
      allowEndingRentalsOutsideStation: String(allow_ending_rentals_outside_station),
      enablePhotoEndRental: String(enable_photo_end_rental),
      disableEndRentalNearestStation: String(disable_end_rental_nearest_station),
      useUserDeviceGps: String(use_user_device_gps),
      disableUserEndRentals: String(disable_user_end_rentals),
      disableUserStartRentals: String(disable_user_start_rentals),
      enableAdminStartRentals: String(enable_admin_start_rentals),
      requireTermsAdditionalRentals: String(require_terms_additional_rentals),
      requireTosOnEveryRental: String(require_tos_on_every_rental),
      billing: billing || false,
    });
  }

  function handleSubmit() {
    const {
      preventEndingRentals,
      allowEndingRentalsOutsideStation,
      enablePhotoEndRental,
      disableEndRentalNearestStation,
      useUserDeviceGps,
      disableUserEndRentals,
      disableUserStartRentals,
      enableAdminStartRentals,
      requireTermsAdditionalRentals,
      requireTosOnEveryRental,
      enableRentalWebsite,
      ...updatedInfo
    } = state;

    updatedInfo.parking_credit_outside_designated =
      state.parking_credit_outside_designated * 100;

    updatedInfo.parking_credit_outside_system_service_zone =
      state.parking_credit_outside_system_service_zone * 100;

    updatedInfo.parking_credit_restricted_zone =
      state.parking_credit_restricted_zone * 100;

    // These are multiplied by a negative number so that they are
    // used as a discount rather than a fee
    updatedInfo.parking_credit_preferred_parking_zone =
      state.parking_credit_preferred_parking_zone * -100;

    updatedInfo.parking_credit_parking_zone = state.parking_credit_parking_zone * -100;

    updatedInfo.parking_credit_rack_station = state.parking_credit_rack_station * -100;

    // validate operating hours info before submitting
    if (updatedInfo.operatingHours) {
      updatedInfo.open_time = updatedInfo.open_time ? updatedInfo.open_time : 0;
      updatedInfo.close_time = updatedInfo.close_time ? updatedInfo.close_time : 0;

      const offset = moment.tz.zone(updatedInfo.timezone)!.utcOffset(Date.now());
      updatedInfo.open_time = getUtcSeconds(updatedInfo.open_time, offset);
      updatedInfo.close_time = getUtcSeconds(updatedInfo.close_time, offset);
    }

    updatedInfo.maxOpenRentals = updatedInfo.maxOpenRentals || null;

    updatedInfo.prevent_ending_rentals_outside_service_zone =
      preventEndingRentals === 'null' ? null : preventEndingRentals === 'true';

    updatedInfo.allow_ending_rentals_outside_station =
      allowEndingRentalsOutsideStation === 'null'
        ? null
        : allowEndingRentalsOutsideStation === 'true';

    updatedInfo.enable_photo_end_rental =
      enablePhotoEndRental === 'null' ? null : enablePhotoEndRental === 'true';

    updatedInfo.disable_end_rental_nearest_station =
      disableEndRentalNearestStation === 'null'
        ? null
        : disableEndRentalNearestStation === 'true';

    updatedInfo.use_user_device_gps =
      useUserDeviceGps === 'null' ? null : useUserDeviceGps === 'true';

    updatedInfo.disable_user_end_rentals =
      disableUserEndRentals === 'null' ? null : disableUserEndRentals === 'true';

    updatedInfo.disable_user_start_rentals =
      disableUserStartRentals === 'null' ? null : disableUserStartRentals === 'true';

    updatedInfo.enable_admin_start_rentals =
      enableAdminStartRentals === 'null' ? null : enableAdminStartRentals === 'true';

    updatedInfo.require_terms_additional_rentals =
      requireTermsAdditionalRentals === 'null'
        ? null
        : requireTermsAdditionalRentals === 'true';

    updatedInfo.require_tos_on_every_rental =
      requireTosOnEveryRental === 'null' ? null : requireTosOnEveryRental === 'true';
    if (enableRentalWebsite) {
      if (
        !state.endRentalWebsiteTitle ||
        !state.endRentalWebsiteMsg ||
        !state.endRentalWebsiteURL ||
        !isValidURL(state.endRentalWebsiteURL)
      ) {
        displayError(
          'Please fill out all fields for the end rental website or disable it.'
        );
        return;
      }

      updatedInfo.end_rental_website = {
        title: state.endRentalWebsiteTitle,
        message: state.endRentalWebsiteMsg,
        url: state.endRentalWebsiteURL,
      };
    } else {
      // if not checked, clear the end rental website values to wipe them out
      updatedInfo.end_rental_website = undefined;
    }
    updatedInfo.billing = state.billing;
    updatedInfo.round_trip = state.round_trip;
    props.submit(updatedInfo);
  }

  useEffect(() => {
    syncStateWithProps(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.systemLoaded]);

  const parseNumber = (value: string): number | null => {
    const trimmedValue = value.trim();
    if (trimmedValue === '') {
      return null;
    }
    const parsedNumber = Number(trimmedValue);
    return parsedNumber > 0 ? parsedNumber : null;
  };

  return (
    <CardView
      title={'Rental Settings'}
      content={
        <Stack px={2} pb={2}>
          {/* ROUNDTRIPS */}
          {(props.admin && !props.adminPartner) || props.mode === 'kayak' ? (
            <MovaticFieldGroupCheck
              label="Round Trip"
              description="Require user to checkout and return to the same location."
              id="round_trip"
              name="round_trip"
              disabled={props.mode === 'kayak'}
              onChange={(event: React.BaseSyntheticEvent) => {
                setState((prevState) => ({
                  ...prevState,
                  round_trip: event.target.checked,
                }));
                props.isChanged(true);
              }}
              checked={state.round_trip || false}
            />
          ) : null}

          {/* GRACE PERIOD */}
          {props.detailedSystem.billing && (
            <FormItem
              label="Grace Period"
              description="Rentals that end within this time period will not be charged"
              content={
                <Select
                  fullWidth
                  id="gracePeriod"
                  value={
                    state.gracePeriod?.toString() || state.gracePeriod === 0
                      ? state.gracePeriod?.toString()
                      : '300'
                  }
                  onChange={(event: SelectChangeEvent) => {
                    setState((prevState) => ({
                      ...prevState,
                      gracePeriod: Number(event.target.value),
                    }));
                    props.isChanged(true);
                  }}
                >
                  {GRACE_PERIOD_OPTIONS.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          )}

          {/* IN RENTAL UNLOCK TODO: this should be hidden for locker and bike room systems */}
          {!(props.mode === 'bike_locker' || props.mode === 'bikeRoom') ? (
            <MovaticFieldGroupCheck
              label="Supports in Rental Unlock"
              description="Allows the user to lock and unlock the unit during a rental"
              id="rounsupports_in_rental_unlockd_trip"
              name="supports_in_rental_unlock"
              onChange={(event: React.BaseSyntheticEvent) => {
                setState((prevState) => ({
                  ...prevState,
                  supports_in_rental_unlock: event.target.checked,
                }));
                props.isChanged(true);
              }}
              checked={state.supports_in_rental_unlock || false}
            />
          ) : null}

          {/* MAX OPEN RENTALS */}
          <FormItem
            label="Max Open Rentals"
            description="Maximum number of simultaneous rentals per user. This can be overridden in rates and memberships."
            content={
              <OutlinedInput
                fullWidth
                id="maxOpenRentals"
                inputProps={{ max: 100, min: 1 }}
                type="number"
                placeholder="0"
                value={state.maxOpenRentals ?? ''}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setState((prevState) => ({
                    ...prevState,
                    maxOpenRentals: Number(event.target.value),
                  }));
                  props.isChanged(true);
                }}
              />
            }
          />

          {/* RENTAL WARNING LENGTH */}
          <FormItem
            label="Rental Warning Length"
            description="Time elapsed before notifying a user of an unusually long rental"
            content={
              <Select
                fullWidth
                id="rentalLengthWarning"
                value={state.rentalLengthWarning?.toString() || '0'}
                onChange={(event: SelectChangeEvent) => {
                  setState((prevState) => ({
                    ...prevState,
                    rentalLengthWarning: Number(event.target.value),
                  }));
                  props.isChanged(true);
                }}
              >
                <MenuItem value="0">None</MenuItem>
                <MenuItem value="1800">30 minutes</MenuItem>
                <MenuItem value="2700">45 minutes</MenuItem>
                <MenuItem value="3600">1 hour</MenuItem>
                <MenuItem value="7200">2 hours</MenuItem>
                <MenuItem value="21600">6 hours</MenuItem>
                <MenuItem value="43200">12 hours</MenuItem>
                <MenuItem value="86400">1 day</MenuItem>
                <MenuItem value="259200">3 days</MenuItem>
                <MenuItem value="604800">1 week</MenuItem>
                <MenuItem value="1209600">2 weeks</MenuItem>
                <MenuItem value="1814400">3 weeks</MenuItem>
                <MenuItem value="2419200">4 weeks</MenuItem>
              </Select>
            }
          />

          {/* FORCE END RENTAL*/}
          {props.mode === 'bikeshare' && (
            <MovaticFieldGroupCheck
              label="Force End Rentals"
              description="Allow users to start rentals on bikes if the previous rental was not ended properly, ending the original rental"
              id="forceEndPossible"
              name="forceEndPossible"
              onChange={(event: React.BaseSyntheticEvent) => {
                setState((prevState) => ({
                  ...prevState,
                  forceEndPossible: event.target.checked,
                }));
                props.isChanged(true);
              }}
              checked={state.forceEndPossible || false}
            />
          )}

          {/* TIME RESTRICTIONS */}
          <MovaticFieldGroupCheck
            label="Time Restrictions"
            description="Restrict rentals to a certain time of day"
            id="operatingHours"
            name="operatingHours"
            onChange={(event: React.BaseSyntheticEvent) => {
              setState((prevState) => ({
                ...prevState,
                operatingHours: event.target.checked,
              }));
              props.isChanged(true);
            }}
            checked={state.operatingHours || false}
          />

          {state.operatingHours && (
            <div>
              {/* OPEN TIME */}
              <FormItem
                label="Open Time"
                description="Time when system opens."
                content={
                  <Select
                    defaultValue={'0'}
                    fullWidth
                    id="open_time"
                    value={state.open_time?.toString() || '0'}
                    onChange={(event: SelectChangeEvent) => {
                      setState((prevState) => ({
                        ...prevState,
                        open_time: Number(event.target.value),
                      }));
                      props.isChanged(true);
                    }}
                  >
                    {getTimeList().map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                }
              />

              {/* CLOSE TIME */}
              <FormItem
                label="Close Time"
                description="Time when system closes. Users will be able to end rentals after closing time."
                content={
                  <Select
                    defaultValue={'0'}
                    fullWidth
                    id="close_time"
                    value={state.close_time?.toString() || '0'}
                    onChange={(event: SelectChangeEvent) => {
                      setState((prevState) => ({
                        ...prevState,
                        close_time: Number(event.target.value),
                      }));
                      props.isChanged(true);
                    }}
                  >
                    {getTimeList().map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                }
              />
            </div>
          )}

          {/* END RENTALS */}
          <FormItem
            label="Prevent Ending Rentals Outside Service Zone"
            description="Check this option if you do not want a user to be able to end a rental outside the service zone."
            content={
              <Select
                defaultValue={'null'}
                fullWidth
                id="preventEndingRentals"
                value={state.preventEndingRentals || 'null'}
                onChange={(event: SelectChangeEvent) => {
                  setState((prevState) => ({
                    ...prevState,
                    preventEndingRentals: event.target.value,
                  }));
                  props.isChanged(true);
                }}
              >
                {DEFAULT_BOOLEAN_OPTIONS_FOR_CONFIG.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            }
          />

          <FormItem
            label="Allow Users To End Rentals Outside Of a Station"
            description="Check this option if you want a user to be able to end a rental outside of a station."
            content={
              <Select
                fullWidth
                id="allowEndingRentalsOutsideStation"
                value={state.allowEndingRentalsOutsideStation || 'null'}
                onChange={(event: SelectChangeEvent) => {
                  setState((prevState) => ({
                    ...prevState,
                    allowEndingRentalsOutsideStation: event.target.value,
                  }));
                  props.isChanged(true);
                }}
              >
                {DEFAULT_BOOLEAN_OPTIONS_FOR_CONFIG.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            }
          />

          <FormItem
            label="End Rental Photo"
            description="Check this option if you want users to take a picture of the hardware at the end of each rental."
            content={
              <Select
                fullWidth
                id="enablePhotoEndRental"
                value={state.enablePhotoEndRental || 'null'}
                onChange={(event: SelectChangeEvent) => {
                  setState((prevState) => ({
                    ...prevState,
                    enablePhotoEndRental: event.target.value,
                  }));
                  props.isChanged(true);
                }}
              >
                {DEFAULT_BOOLEAN_OPTIONS_FOR_CONFIG.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            }
          />

          <FormItem
            label="Disable Out-Of-Range Nearest Station Selection"
            description="Check this option to disable selecting the nearest station for a user when ending a rental if the user is not within the radius of that station."
            content={
              <Select
                fullWidth
                id="disableEndRentalNearestStation"
                value={state.disableEndRentalNearestStation || 'null'}
                onChange={(event: SelectChangeEvent) => {
                  setState((prevState) => ({
                    ...prevState,
                    disableEndRentalNearestStation: event.target.value,
                  }));
                  props.isChanged(true);
                }}
              >
                {DEFAULT_BOOLEAN_OPTIONS_FOR_CONFIG.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            }
          />

          <FormItem
            label="Enable User Device GPS Usage"
            description="Check this if option if you want to allow using the user's device GPS for features that rely on hardware GPS, when hardware GPS is not available."
            content={
              <Select
                fullWidth
                id="useUserDeviceGps"
                value={state.useUserDeviceGps || 'null'}
                onChange={(event: SelectChangeEvent) => {
                  setState((prevState) => ({
                    ...prevState,
                    useUserDeviceGps: event.target.value,
                  }));
                  props.isChanged(true);
                }}
              >
                {DEFAULT_BOOLEAN_OPTIONS_FOR_CONFIG.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            }
          />

          <FormItem
            label="Prevent User From Ending Rentals"
            description="Check this option if you do not want users to be able to end rentals. This is useful if you want rentals to be managed only from the admin site."
            content={
              <Select
                fullWidth
                id="disableUserEndRentals"
                value={state.disableUserEndRentals || 'null'}
                onChange={(event: SelectChangeEvent) => {
                  setState((prevState) => ({
                    ...prevState,
                    disableUserEndRentals: event.target.value,
                  }));
                  props.isChanged(true);
                }}
              >
                {DEFAULT_BOOLEAN_OPTIONS_FOR_CONFIG.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            }
          />

          {/* END RENTAL WEBSITE */}
          <MovaticFieldGroupCheck
            label="Enable End Rental Website"
            description="A website link is shown after the rental in the app."
            id="enableRentalWebsite"
            name="enableRentalWebsite"
            onChange={(event: React.BaseSyntheticEvent) => {
              setState((prevState) => ({
                ...prevState,
                enableRentalWebsite: event.target.checked,
              }));
              props.isChanged(true);
            }}
            checked={state.enableRentalWebsite || false}
          />
          {state.enableRentalWebsite && (
            <>
              <FormItem
                label="End Rental Website Title"
                description="Title displayed to the user about the website they are being linked to."
                content={
                  <OutlinedInput
                    fullWidth
                    id="endRentalWebsiteTitle"
                    type="text"
                    value={state.endRentalWebsiteTitle ?? ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setState((prevState) => ({
                        ...prevState,
                        endRentalWebsiteTitle: event.target.value,
                      }));
                      props.isChanged(true);
                    }}
                  />
                }
              />

              <FormItem
                label="End Rental Website Message"
                description="Message displayed to the user about the website they are being linked to."
                content={
                  <OutlinedInput
                    fullWidth
                    id="endRentalWebsiteMsg"
                    type="text"
                    value={state.endRentalWebsiteMsg ?? ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setState((prevState) => ({
                        ...prevState,
                        endRentalWebsiteMsg: event.target.value,
                      }));
                      props.isChanged(true);
                    }}
                  />
                }
              />

              <FormItem
                label="End Rental Website URL"
                description="URL of the website that would be displayed to the user."
                content={
                  <TextField
                    id="endRentalWebsiteURL"
                    variant="outlined"
                    fullWidth
                    error={!isValidURL(state.endRentalWebsiteURL)}
                    value={state.endRentalWebsiteURL ?? ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setState((prevState) => ({
                        ...prevState,
                        endRentalWebsiteURL: event.target.value,
                      }));
                      props.isChanged(true);
                    }}
                  />
                }
              />
            </>
          )}

          {/* START RENTALS  */}
          <FormItem
            label="Prevent Users From Starting Rentals"
            description="Check this option if you do not want users to be able to start rentals. This is useful if you want rentals to be managed only from the admin site."
            content={
              <Select
                fullWidth
                id="disableUserStartRentals"
                value={state.disableUserStartRentals || 'null'}
                onChange={(event: SelectChangeEvent) => {
                  setState((prevState) => ({
                    ...prevState,
                    disableUserStartRentals: event.target.value,
                  }));
                  props.isChanged(true);
                }}
              >
                {DEFAULT_BOOLEAN_OPTIONS_FOR_CONFIG.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            }
          />

          <FormItem
            label="Allow Admins To Start Rentals For Users"
            description="Check this option if you want admins to be able to start rentals for users. This is useful if you want rentals to be managed only from the admin site."
            content={
              <Select
                fullWidth
                id="enableAdminStartRentals"
                value={state.enableAdminStartRentals || 'null'}
                onChange={(event: SelectChangeEvent) => {
                  setState((prevState) => ({
                    ...prevState,
                    enableAdminStartRentals: event.target.value,
                  }));
                  props.isChanged(true);
                }}
              >
                {DEFAULT_BOOLEAN_OPTIONS_FOR_CONFIG.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            }
          />

          <FormItem
            label="Display Terms Of Service In Every Rental"
            description="Check this option if you want users to have to read and agree to the system's terms of service at the start of all rentals."
            content={
              <Select
                fullWidth
                id="requireTosOnEveryRental"
                value={state.requireTosOnEveryRental || 'null'}
                onChange={(event: SelectChangeEvent) => {
                  setState((prevState) => ({
                    ...prevState,
                    requireTosOnEveryRental: event.target.value,
                  }));
                  props.isChanged(true);
                }}
              >
                {DEFAULT_BOOLEAN_OPTIONS_FOR_CONFIG.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            }
          />

          <FormItem
            label="Display Terms Of Service In Every Additional Rental"
            description="Check this option if you want users to have to read and agree to terms of service at the start of all additional rentals (for systems that allow starting more than once rental at a time)."
            content={
              <Select
                fullWidth
                id="requireTermsAdditionalRentals"
                value={state.requireTermsAdditionalRentals || 'null'}
                onChange={(event: SelectChangeEvent) => {
                  setState((prevState) => ({
                    ...prevState,
                    requireTermsAdditionalRentals: event.target.value,
                  }));
                  props.isChanged(true);
                }}
              >
                {DEFAULT_BOOLEAN_OPTIONS_FOR_CONFIG.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            }
          />

          {props.detailedSystem.billing && (
            <div>
              <FormItem
                label="Outside Service Zone Fee"
                description="Fee charged to the user if they end the rental outside of the service bounds."
                content={
                  <OutlinedInput
                    fullWidth
                    id="parking_credit_outside_system_service_zone"
                    inputProps={{ step: 0.25, max: 99, min: 0 }}
                    type="number"
                    placeholder="0"
                    value={state.parking_credit_outside_system_service_zone || ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setState((prevState) => ({
                        ...prevState,
                        parking_credit_outside_system_service_zone: Number(
                          event.target.value
                        ),
                      }));
                      props.isChanged(true);
                    }}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  />
                }
              />

              <FormItem
                label="Outside Designated Parking Fee"
                description="Fee charged to the user if they end the rental within service bounds but outside of a designated parking area."
                content={
                  <OutlinedInput
                    fullWidth
                    id="parking_credit_outside_designated"
                    inputProps={{ step: 0.25, max: 99, min: 0 }}
                    type="number"
                    placeholder="0"
                    value={state.parking_credit_outside_designated || ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setState((prevState) => ({
                        ...prevState,
                        parking_credit_outside_designated: Number(event.target.value),
                      }));
                      props.isChanged(true);
                    }}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  />
                }
              />

              <FormItem
                label="Restricted Parking Fee"
                description="Fee charged to the user if they end the rental inside a restricted parking zone."
                content={
                  <OutlinedInput
                    fullWidth
                    id="parking_credit_restricted_zone"
                    inputProps={{ step: 0.25, max: 99, min: 0 }}
                    type="number"
                    placeholder="0"
                    value={state.parking_credit_restricted_zone || ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setState((prevState) => ({
                        ...prevState,
                        parking_credit_restricted_zone: Number(event.target.value),
                      }));
                      props.isChanged(true);
                    }}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  />
                }
              />

              <FormItem
                label="Preferred Parking Credit"
                description="Discount applied to user if they end the rental within a preferred parking zone."
                content={
                  <OutlinedInput
                    fullWidth
                    id="parking_credit_preferred_parking_zone"
                    inputProps={{ step: 0.25, max: 99, min: 0 }}
                    type="number"
                    placeholder="0"
                    value={state.parking_credit_preferred_parking_zone || ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setState((prevState) => ({
                        ...prevState,
                        parking_credit_preferred_parking_zone: Number(event.target.value),
                      }));
                      props.isChanged(true);
                    }}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  />
                }
              />

              <FormItem
                label="Parking Zone Credit"
                description="Discount applied to user if they end the rental within a standard parking zone."
                content={
                  <OutlinedInput
                    fullWidth
                    id="parking_credit_parking_zone"
                    inputProps={{ step: 0.25, max: 99, min: 0 }}
                    type="number"
                    placeholder="0"
                    value={state.parking_credit_parking_zone || ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setState((prevState) => ({
                        ...prevState,
                        parking_credit_parking_zone: Number(event.target.value),
                      }));
                      props.isChanged(true);
                    }}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  />
                }
              />

              <FormItem
                label="Station Credit"
                description="Discount applied to user if they end the rental at a rack based station."
                content={
                  <OutlinedInput
                    fullWidth
                    id="parking_credit_rack_station"
                    inputProps={{ step: 0.25, max: 99, min: 0 }}
                    type="number"
                    placeholder="0"
                    value={state.parking_credit_rack_station || ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setState((prevState) => ({
                        ...prevState,
                        parking_credit_rack_station: Number(event.target.value),
                      }));
                      props.isChanged(true);
                    }}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  />
                }
              />

              <MovaticFieldGroupOutlinedInput
                type={'number'}
                label="System Max Speed"
                description="Hardware with speed control will not travel faster than this speed."
                value={state.maxSpeed || ''}
                inputProps={{ step: 1, max: 999 }}
                onChange={(event) => {
                  setState((prevState) => ({
                    ...prevState,
                    maxSpeed: parseNumber(event.target.value),
                  }));
                  props.isChanged(true);
                }}
                id="maxSpeed"
                name="maxSpeed"
                addOn={'Km/h'}
              />

              <MovaticFieldGroupOutlinedInput
                type={'number'}
                label="Out of Service Area Max Speed"
                description="If the system has a service area, hardware with speed control will not travel faster than this speed outside of the service area."
                value={state.outsideSystemMaxSpeed || ''}
                inputProps={{ step: 1, max: 999 }}
                onChange={(event) => {
                  setState((prevState) => ({
                    ...prevState,
                    outsideSystemMaxSpeed: parseNumber(event.target.value),
                  }));
                  props.isChanged(true);
                }}
                id="outsideSystemMaxSpeed"
                name="outsideSystemMaxSpeed"
                addOn={'Km/h'}
              />
            </div>
          )}
        </Stack>
      }
      headerActions={
        <IconButtonMenu
          buttons={[
            {
              label: 'Save',
              startIcon: <SaveIcon />,
              onClick: handleSubmit,
              disabled:
                !props.systemLoaded ||
                (!findAccess(props.systemAccess).includes('rental') && !props.admin) ||
                props.buttonDisabled,
            },
          ]}
        />
      }
    />
  );
};

export default connect(
  (state: IStateReduxProps) => ({
    admin: state.admin.admin.admin.movaticAccess,
    systemAccess: state.admin.systemAccess,
    adminPartner: state.admin.admin.admin.partner,
    detailedSystem: state.system.current,
    mode: state.mode.currentMode,
    systemLoaded: state.system.isLoaded,
  }),
  () => ({})
)(RentalInfo);
