import React, { Component } from 'react';
import { Map, TileLayer, Marker, Polyline, Polygon } from 'react-leaflet';

import LeafletLegend from './maps/LeafletLegend';

import { MOVATIC_PRIMARY_COLOR, getZoneColors, ZONE_LEGEND_LABELS } from '../constants';
import { mapHardwareMarker, mapStationMarker } from './mapMarker';
import bugsnagClient from './bugsnag';
import { findAccess, history } from '../utils';
import { useTheme } from '@mui/material';
import PageLoader from './Redesing/page-loader';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';

export default class MyMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      hasPolygon: false,
      hasPolyline: false,
      boundsOptions: [
        [0, 0],
        [0, 0],
      ],
    };
  }

  componentDidMount() {
    let { boundsOptions } = this.state;

    if (this.props.polyline) {
      this.setState({ hasPolyline: true });
    }

    // if stations were sent in use them to format the bounds options
    if (this.props.stations?.length) {
      boundsOptions = this.props.stations.map((station) => {
        if (station.polygon_coordinates) {
          this.setState({ hasPolygon: true });
          return station.polygon_coordinates;
        }
        const lat = station?.lat || 0;
        const long = station?.lng || 0;

        return [lat, long];
      });
    } else if (this.props.units) {
      boundsOptions = this.props.units.map((unit) => {
        const lat = unit.last_lat ? unit.last_lat : 0;
        const long = unit.last_lng ? unit.last_lng : 0;

        return [lat, long];
      });
    } else if (this.props.polyline) {
      this.setState({ hasPolyline: true });
      boundsOptions = this.props.polyline;
    } else if (this.props.marker) {
      boundsOptions = [this.props.marker];
    }

    this.setState({ boundsOptions });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.stations?.length &&
      nextProps.stations?.length !== this.props.stations?.length
    ) {
      const boundsOptions = nextProps.stations.map((station) => {
        if (station.polygon_coordinates) {
          this.setState({ hasPolygon: true });
          return station.polygon_coordinates;
        }
        const lat = station?.lat || 0;
        const long = station?.lng || 0;

        return [lat, long];
      });

      this.setState({ boundsOptions });
    } else if (
      nextProps.units?.length &&
      this.props.units?.length !== nextProps.units?.length
    ) {
      const boundsOptions = nextProps.units.map((unit) => {
        const lat = unit.last_lat ? unit.last_lat : 0;
        const long = unit.last_lng ? unit.last_lng : 0;

        return [lat, long];
      });

      this.setState({ boundsOptions });
    } else if (nextProps.polyline && this.props.polyline !== nextProps.polyline) {
      this.setState({
        hasPolyline: true,
        boundsOptions: nextProps.polyline,
      });
    } else if (nextProps.marker && this.props.marker !== nextProps.marker) {
      this.setState({
        boundsOptions: [nextProps.marker],
      });
    }
  }

  componentDidCatch(error) {
    // because this component likes to crash
    this.setState({
      hasError: true,
    });

    console.warn(error);
    bugsnagClient.notify(error);
  }

  render() {
    if (!this.props.loaded) {
      return <PageLoader />;
    }
    // something hasn't loaded or has gone wrong--don't bother rendering map
    if (
      this.state.hasError ||
      this.state.boundsOptions.length === 0 ||
      (this.state.boundsOptions[0][0] === 0 && this.state.boundsOptions[0][1] === 0)
    ) {
      return (
        <Card>
          <Stack p={2}>
            <p>No location data is available for display.</p>
          </Stack>
        </Card>
      );
    }

    const access = findAccess(this.props.systemAccess) ?? [];
    const shouldRenderZonesLegend =
      !!this.props.stations?.find((station) => station.polygon_coordinates) &&
      !this.props.isMobile;

    return (
      <div>
        <Map
          ref={(ref) => {
            this.map = ref;
          }}
          center={
            !this.state.hasPolygon && !this.state.hasPolyline
              ? this.state.boundsOptions[0]
              : null
          }
          zoom={this.props.zoom || 17}
          maxZoom={19}
          style={{ height: `${this.props.height}` }}
          bounds={
            this.state.hasPolygon || this.state.hasPolyline
              ? this.state.boundsOptions
              : null
          }
          boundsOptions={{ padding: [10, 10] }}
        >
          <GenericMapTileLayer />
          {this.props.stations
            ? this.props.stations.map((station) => {
                if (station?.lat && station?.lng) {
                  return (
                    <Marker
                      onClick={
                        access.includes('locations')
                          ? () => history.push(`/locations/${station.id}/general`)
                          : null
                      }
                      id={station.id}
                      key={station.id}
                      position={[station?.lat, station?.lng]}
                      icon={mapStationMarker(
                        this.props.systemType || station.type,
                        station
                      )}
                    />
                  );
                }
                if (station.polygon_coordinates) {
                  const { strokeColor, fillColor, strokeWidth, lineDashPattern } =
                    getZoneColors(station.zone_type);
                  return (
                    <Polygon
                      onClick={
                        access.includes('locations')
                          ? () => history.push(`/locations/${station.id}/general`)
                          : null
                      }
                      positions={station.polygon_coordinates}
                      color={strokeColor}
                      fillColor={fillColor}
                      weight={strokeWidth}
                      dashArray={lineDashPattern}
                      stroke
                      key={station.id}
                    />
                  );
                }
                return null;
              })
            : null}

          {/* for rental detail if rental has gps tracking */}
          {this.state.hasPolyline && (
            <Polyline positions={this.props.polyline} color={MOVATIC_PRIMARY_COLOR(1)} />
          )}

          {this.props.units &&
            this.props.units.map((unit) => (
              <Marker
                onClick={
                  access.includes('physical')
                    ? () =>
                        history.push(`/hardware/${unit.hardware_type_name}/${unit.id}`)
                    : null
                }
                id={unit.id}
                key={unit.id}
                position={[unit.last_lat, unit.last_lng]}
                icon={mapHardwareMarker(unit.hardware_type_name, unit)}
              />
            ))}

          {/* most recent location for gps locks */}
          {this.props.marker && (
            <Marker
              position={this.props.marker}
              icon={mapHardwareMarker(this.props.markerType)}
            />
          )}

          {/* Start (A) and end (B) location markers */}
          {this.props.startPoint && (
            <Marker position={this.props.startPoint} icon={mapHardwareMarker('start')} />
          )}
          {this.props.endPoint && (
            <Marker position={this.props.endPoint} icon={mapHardwareMarker('end')} />
          )}

          {shouldRenderZonesLegend ? (
            <LeafletLegend title="Zones" labels={ZONE_LEGEND_LABELS} />
          ) : null}
        </Map>
      </div>
    );
  }
}

export const GenericMapTileLayer = () => {
  const theme = useTheme();
  return (
    <TileLayer
      attribution={
        '&copy; <a target={_blank} href=https://www.openstreetmap.org/>OpenStreetMap</a> &copy; <a target={_blank} href=https://www.mapbox.com/>Mapbox</a>'
      }
      className={theme.palette.mode === 'dark' ? 'map-tiles' : 'none'}
      url={
        'https://api.mapbox.com/styles/v1/movatic/ckashz0mm1zwv1ipckr1ccjai/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibW92YXRpYyIsImEiOiJja2FyNGZid3gwZnQ3MzNucjh3b2JpazUwIn0.wE_NweMk5kSa5bYVg6wKwA'
      }
    />
  );
};
