import { styled } from '@mui/material/styles';
import { Card } from '@mui/material';

export const MyButtonCard = styled(Card)(({ theme }) => ({
  cursor: 'pointer',
  borderRadius: 0, // Set borderRadius to 0 to remove rounded corners
  boxShadow: 'none', // Remove the box-shadow (paper effect)
  border: 'none', // Set border to none
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: 'th', // Set your desired hover background color
  },
}));
