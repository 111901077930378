import React, { useState } from 'react';
import { connect } from 'react-redux';
import { ISystem } from '../../ts/interface/system.interface';
import { useParams } from 'react-router-dom';
import useRates from '../../hooks/rates/query/useRates';
import { IRate } from '../../ts/interface/rental.interface';
import { RATE_DURATIONS } from '../../constants';
import { convertCentsToMoney, findAccess, history, timeForHumans } from '../../utils';
import { archiveRate, updateRate } from '../../api/rates';
import { IAuth } from '../../ts/interface/admins.interface';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { PropertyList } from '../../components/Redesing/property-list';
import { PropertyListItem } from '../../components/Redesing/property-list-item';
import PageContainer from '../../components/Redesing/page-container';
import PageContent from '../../components/Redesing/page-content';
import MovaticCustomModal from '../../components/Modal/MovaticCustomModal';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';
import CardView from '../../components/Redesing/card-view';
import { Stack } from '@mui/system';
import { SeverityPill } from '../../components/Redesing/severity-pill';

interface IViewRatesProps {
  system: ISystem;
  systemLoaded: boolean;
  movaticAccess: number;
  systemAccess: number;
  auth: IAuth;
}

const ViewRates = ({
  system,
  systemLoaded,
  movaticAccess,
  systemAccess,
  auth,
}: IViewRatesProps) => {
  const { rateId } = useParams<{ rateId: string }>();
  const [rateLoaded, setRateLoaded] = useState<IRate>({} as IRate);
  const [archivedRate, setArchivedRate] = useState<boolean>(false);
  const [showArchived, setShowArchived] = useState<boolean>(false);
  const { isLoading: ratesLoading, refetch } = useRates({
    enabled: systemLoaded,
    onSuccess: (data: IRate[]) => {
      let rate = data.find((r: IRate) => r.id === rateId || r.gid === rateId);
      if (rate) {
        rate.active_on = new Date(rate.active_on).toDateString();
        if (rate.price_per_period && !rate.base_rate) {
          RATE_DURATIONS.forEach((time) => {
            if (time.seconds === rate?.period_duration) {
              rate.stringDuration = time.value;
            }
          });
          rate.free_time && rate.free_time !== 0 && rate.free_time !== null
            ? (rate.overview = `${timeForHumans(rate.free_time)} free, then $${(
                rate.price_per_period / 100
              ).toFixed(2)} for ${rate.stringDuration}`)
            : (rate.overview = `$${(rate.price_per_period / 100).toFixed(2)} for ${
                rate.stringDuration
              }`);
        } else if (rate.price_per_period && rate.base_rate) {
          RATE_DURATIONS.forEach((time) => {
            if (time.seconds === rate?.period_duration) {
              rate.stringDuration = time.value;
            }
          });
          rate.free_time && rate.free_time !== 0 && rate.free_time !== null
            ? (rate.overview = `${timeForHumans(rate.free_time)} free, then $${(
                rate.base_rate / 100
              ).toFixed(2)} + $${(rate.price_per_period / 100).toFixed(
                2
              )} per ${timeForHumans(rate.period_duration)}`)
            : (rate.overview = `$${(rate.base_rate / 100).toFixed(2)} + $${(
                rate.price_per_period / 100
              ).toFixed(2)} per ${timeForHumans(rate.period_duration)}`);
        }
        if (
          rate.archived &&
          new Date(rate.active_on).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)
        ) {
          setArchivedRate(true);
        }
        setRateLoaded(rate);
      }
    },
  });

  const toggleArchiveModal = () => {
    setShowArchived(!showArchived);
  };

  const activateRate = () => {
    const rate = { ...rateLoaded };
    rate.archived = null;
    CustomAsyncToast({
      promise: () => updateRate(rate),
      successMessage: () => 'Your rate was activated successfully!',
      errorMessage: () =>
        'There was an error activating the rate. Please try again later.',
      loadingMessage: () => 'Activating rate...',
    }).then((r) => {
      if (r) {
        refetch().then(() => {
          setArchivedRate(false);
        });
      }
    });
  };

  const markArchiveRate = () => {
    const rate = { ...rateLoaded };
    CustomAsyncToast({
      promise: () => archiveRate(rate),
      successMessage: () => 'Your rate was archived successfully!',
      errorMessage: () =>
        'There was an error archiving the rate. Please try again later.',
      loadingMessage: () => 'Archiving rate...',
    }).then((r) => {
      if (r) {
        refetch().then(() => {
          setArchivedRate(true);
        });
      }
    });
  };

  const content = () => {
    const systemUsesWallets = system.billing && system.wallet_required;
    return (
      <CardView
        content={
          <Stack>
            <PropertyList>
              <PropertyListItem
                divider
                label="Name"
                value={rateLoaded.name}
                dataId="name"
              />
              <PropertyListItem divider label="Rate" dataId="rate">
                <SeverityPill color={'success'}>{rateLoaded.overview}</SeverityPill>
              </PropertyListItem>
              {system.reservations && movaticAccess && !auth.admin.partner ? (
                <PropertyListItem
                  label="Reservations"
                  value={rateLoaded.reservation ? 'Yes' : 'No'}
                />
              ) : null}
              {movaticAccess && !auth.admin.partner ? (
                <PropertyListItem divider label="Membership Required" dataId="membership">
                  <SeverityPill
                    color={rateLoaded.membership_required ? 'error' : 'success'}
                  >
                    {rateLoaded.membership_required ? 'Yes' : 'No'}
                  </SeverityPill>
                </PropertyListItem>
              ) : null}
              <PropertyListItem
                divider
                label="Simultaneous Rental Limit"
                value={rateLoaded.max_rentals ?? 'Not set'}
                dataId="rental-limit"
              />
              {systemUsesWallets ? (
                <PropertyListItem
                  divider
                  label="Minimum Wallet Balance"
                  dataId="wallet-balance"
                  value={
                    Number.isInteger(rateLoaded.minimum_wallet_deposit)
                      ? convertCentsToMoney(rateLoaded.minimum_wallet_deposit)
                      : 'Not set'
                  }
                />
              ) : null}
              {system.billing ? (
                <PropertyListItem
                  divider
                  label="Credit Card Hold"
                  dataId="cc-hold"
                  value={
                    Number.isInteger(rateLoaded.credit_card_hold)
                      ? convertCentsToMoney(rateLoaded.credit_card_hold)
                      : 'Not set'
                  }
                />
              ) : null}
              <PropertyListItem label="Hidden" dataId="hidden">
                <SeverityPill color={rateLoaded.hidden ? 'error' : 'success'}>
                  {rateLoaded.hidden ? 'Yes' : 'No'}
                </SeverityPill>
              </PropertyListItem>
            </PropertyList>
          </Stack>
        }
        title={'Rate Details'}
      />
    );
  };

  const button = () => {
    const isArchived =
      rateLoaded.archived &&
      new Date(rateLoaded.active_on).setHours(0, 0, 0, 0) <=
        new Date().setHours(0, 0, 0, 0);
    const buttons = [];

    if (!isArchived) {
      buttons.push({
        label: 'Archive',
        onClick: toggleArchiveModal,
        disabled: !findAccess(systemAccess).includes('rates'),
        startIcon: <ArchiveIcon />,
      });
      buttons.push({
        label: 'Edit',
        startIcon: <EditIcon />,
        onClick: () => history.push(`/billing/rates/${rateId}/edit`),
        disabled: !findAccess(systemAccess).includes('rates'),
      });
    } else {
      buttons.push({
        label: 'Activate',
        onClick: toggleArchiveModal,
        disabled: !findAccess(systemAccess).includes('rates'),
        startIcon: <UnarchiveIcon />,
      });
    }
    return <IconButtonMenu buttons={buttons} />;
  };

  return (
    <div>
      <PageContainer isLoading={ratesLoading}>
        <PageContent
          backLink={'/billing/rates'}
          contentTitle={'Rates'}
          itemName={rateLoaded.name || 'Rate'}
          itemChipValue={rateLoaded.gid || rateId}
          chipLabel={'Rate ID'}
          tabsChildren={content()}
          itemTopButtons={button()}
        />
        <MovaticCustomModal
          open={showArchived}
          onClose={toggleArchiveModal}
          content={
            archivedRate
              ? 'Are you sure you would like to reactivate this rate?'
              : 'Are you sure you would like to archive this rate?'
          }
          title={archivedRate ? 'Reactivate Rate' : 'Archive Rate'}
          action={
            <IconButtonMenu
              buttons={[
                {
                  label: 'Cancel',
                  onClick: toggleArchiveModal,
                },
                {
                  label: archivedRate ? 'Reactivate' : 'Archive',
                  onClick: () => {
                    archivedRate ? activateRate() : markArchiveRate();
                    toggleArchiveModal();
                  },
                  startIcon: archivedRate ? <UnarchiveIcon /> : <ArchiveIcon />,
                },
              ]}
            />
          }
        />
      </PageContainer>
    </div>
  );
};

export default connect(
  (state: {
    admin: {
      systemAccess: number;
      admin: IAuth;
    };
    system: {
      current: ISystem;
      isLoaded: boolean;
    };
  }) => ({
    auth: state.admin.admin,
    movaticAccess: state.admin.admin.admin.movaticAccess,
    systemAccess: state.admin.systemAccess,
    system: state.system.current,
    systemLoaded: state.system.isLoaded,
  }),
  () => ({})
)(ViewRates);
