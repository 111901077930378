import React, { ChangeEvent, useCallback, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import useOneTicket from '../../hooks/maintenance/query/useOneTicket';
import { IMode } from '../../ts/interface/mode.interface';
import { ISystem } from '../../ts/interface/system.interface';
import { IAdmin } from '../../ts/interface/admins.interface';
import { ITicket } from '../../ts/interface/problem.interface';
import {
  getLocaleDateString,
  getLocaleDateTimeString,
  getPriorityName,
  history,
  toTitleCase,
} from '../../utils';
import { addNoteToTicket, cancelTicket } from '../../api/problems';
import CommunicationLog from '../../components/communicationlog';
import useSystemAdmins from '../../hooks/system/query/useSystemAdmins';
import { PropertyList } from '../../components/Redesing/property-list';
import { PropertyListItem } from '../../components/Redesing/property-list-item';
import PropertyListItemLink from '../../components/Redesing/property-list-item-link';
import { SeverityPill } from '../../components/Redesing/severity-pill';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';
import CustomToast from '../../components/Redesing/custom-toast';
import PageContainer from '../../components/Redesing/page-container';
import PageContent from '../../components/Redesing/page-content';
import CardView from '../../components/Redesing/card-view';
import { Stack } from '@mui/system';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Divider from '@mui/material/Divider';
import MovaticCustomModal from '../../components/Modal/MovaticCustomModal';
import Box from '@mui/material/Box';
import BuildIcon from '@mui/icons-material/Build';
import Chip from '@mui/material/Chip';
import AddIcon from '@mui/icons-material/Add';
import { addTagsToMaintenanceTicket } from '../../api/maintenance';
import useSmallScreen from '../../hooks/useSmallScreen';
import { MovaticFieldGroupSelectFilter } from '../../components/Redesing/movatic-field-group';
import useTags from '../../hooks/maintenance/query/useTags';
import { getCredentials } from '../../api/http';

const TicketDetail = ({
  mode,
  system,
  admin,
  systemLoaded,
}: {
  mode: IMode;
  system: ISystem;
  admin: IAdmin;
  systemLoaded: boolean;
}) => {
  const { systemId } = getCredentials();
  const { ticketId = '' } = useParams<{ ticketId: string }>();
  const {
    data: ticket = {},
    isLoading,
    refetch,
  } = useOneTicket(ticketId, { enabled: systemLoaded });
  const { data: admins = [] } = useSystemAdmins({
    enabled: systemLoaded,
  });
  const [pastDue, setPastDue] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [note, setNote] = useState('');

  const [addTagModal, setAddTagModal] = useState(false);
  const [deleteTagModal, setDeleteTagModal] = useState(false);
  const [tagToDelete, setTagToDelete] = useState('');
  const isSmallScreen = useSmallScreen();
  const [value, setValue] = useState([]);

  const { data: tagsData = [], isLoading: isTagLoading } = useTags(systemId, {
    enabled: systemLoaded,
  });

  const tags = useMemo(() => {
    if (!ticket?.tags) {
      return [];
    }

    return ticket?.tags?.map((item: string) => {
      return {
        label: item,
        onClick: (name: string) => {
          setTagToDelete(name);
          setDeleteTagModal(true);
        },
      };
    });
  }, [ticket]);

  const loadedTags = useMemo(
    () =>
      tagsData
        // @ts-ignore
        ?.filter((item) => !ticket?.tags?.includes(item.name))
        // @ts-ignore
        .map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        }),
    [tagsData, ticket]
  );

  useEffect(() => {
    if (ticket?.due_on) {
      setPastDue(isPastDue(ticket));
    }
  }, [ticket]);

  const isPastDue = (ticket: ITicket) => {
    return (
      new Date(ticket.due_on).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) &&
      !ticket.completed_on &&
      !ticket.canceled_on
    );
  };

  const getStatusColor = useCallback((ticket: ITicket) => {
    if (ticket.canceled_on) {
      return 'warning';
    }
    if (ticket.completed_on) {
      return 'success';
    }
    if (ticket.assignee) {
      return 'info';
    }
    return 'error';
  }, []);

  const getStatus = useCallback(
    (ticket: ITicket) => {
      const pastDueStatus = pastDue ? ' - Past Due' : '';
      if (ticket.canceled_on) {
        return 'Canceled';
      }
      if (ticket.completed_on) {
        return 'Closed';
      }
      if (ticket.assignee) {
        return `Assigned${pastDueStatus}`;
      }
      return `Open${pastDueStatus}`;
    },
    [pastDue]
  );

  const getOEMContact = () => {
    switch (system.partner) {
      case 1: // cycle safe
        return (window.location.href = 'mailto:support@cyclesafe.com');
      case 2: // on bikeshare
        return (window.location.href = 'mailto:info@onbikeshare.com');
      default:
        return (window.location.href = 'mailto:support@movatic.co');
    }
    // (more partners listed at the bottom of this file for future reference)
  };

  const closeTicket = () => {
    history.push(`/maintenance/close/${ticket?.id}`);
  };

  const toggleConfirmationModal = () => {
    setShowModal(!showModal);
  };

  const handleCancelTicket = () => {
    CustomAsyncToast({
      promise: () => cancelTicket({ id: ticket.id }),
      successMessage: () => 'Ticket canceled successfully',
      loadingMessage: 'Canceling ticket...',
      errorMessage: 'There was an error canceling the ticket. Please try again.',
    }).then((r) => {
      if (r) {
        refetch().then(() => toggleConfirmationModal());
        history.push('/maintenance');
      }
    });
  };

  const saveNote = () => {
    if (note !== '') {
      const newNote = JSON.stringify({
        admin_id: admin.email,
        created_on: new Date().toISOString(),
        message: note.toString(),
      });
      CustomAsyncToast({
        promise: () => addNoteToTicket({ note: newNote, id: ticket.id }),
        successMessage: () => 'Note saved successfully.',
        loadingMessage: 'Saving note...',
        errorMessage: 'There was an error saving the note. Please try again.',
      }).then((r) => {
        if (r) {
          refetch().then(() => {
            setNote('');
          });
        }
      });
    } else {
      CustomToast({
        type: 'error',
        message: 'You must enter a message to save your note',
      });
    }
  };

  const handleNoteChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNote(e.target.value);
  };

  const hardwareContent = (ticket: ITicket) => {
    // TODO: remove this, just put new_hardware section in content()
    if (system.new_hardware) {
      return (
        <PropertyListItemLink
          others={{ divider: true }}
          label={toTitleCase(ticket.hardware_type_name)}
          text={
            ticket.hardware_number ||
            ticket[ticket.primary_identifier] ||
            ticket.hardware_id
          }
          onClick={() =>
            history.push(`/hardware/${ticket.hardware_type}/${ticket.hardware_id}`)
          }
        />
      );
    }
    return (
      <div>
        {ticket.hardware_id ? (
          <PropertyListItemLink
            others={{ divider: true }}
            label={mode.details.stationsTitle}
            text={ticket.hardware_number}
            onClick={() => history.push(`/units/${ticket.hardware_id}`)}
          />
        ) : null}
        {ticket.module_id ? (
          <PropertyListItemLink
            others={{ divider: true }}
            label="Lock"
            text={ticket.module_id}
            onClick={() => history.push(`/units/locks/${ticket.module_id}`)}
          />
        ) : null}
      </div>
    );
  };

  const getHeader = (ticket: ITicket) => {
    let header = 'Ticket for ';
    // TODO: remove this
    if (system.new_hardware) {
      if (ticket?.hardware_number) {
        header += `${toTitleCase(ticket?.hardware_type_name)} ${ticket?.hardware_number}`;
      } else {
        header += ticket[ticket?.primary_identifier];
      }
    } else {
      header += `${mode.details.unitsTitle} ${ticket?.hardware_number}`;
    }
    return header;
  };
  return (
    <PageContainer isLoading={isLoading || !systemLoaded}>
      <PageContent
        backLink={'/maintenance'}
        contentTitle={'Maintenance'}
        itemName={getHeader(ticket || ({} as ITicket))}
        itemChipValue={ticketId}
        detailIcon={<BuildIcon />}
        chipLabel={'Ticket ID'}
        tabsChildren={
          <Grid container spacing={2}>
            <Grid xs={12} lg={5}>
              <CardView
                title={'Ticket Details'}
                content={
                  <Stack>
                    <PropertyList>
                      <PropertyListItem
                        divider
                        label="Maintenance Type"
                        value={`${ticket.task_name}: ${ticket.task_description}`}
                      />
                      <PropertyListItem divider label="Due">
                        <SeverityPill color={pastDue ? 'error' : 'success'}>
                          {getLocaleDateString(ticket.due_on)}
                        </SeverityPill>
                      </PropertyListItem>
                      <PropertyListItem
                        divider
                        label="Created"
                        value={getLocaleDateTimeString(ticket.created_on)}
                      />
                      {ticket.completed_on ? (
                        <PropertyListItem
                          divider
                          label="Completed"
                          value={getLocaleDateTimeString(ticket.completed_on)}
                        />
                      ) : null}
                      <PropertyListItem
                        divider
                        label="Priority"
                        value={`${ticket.priority} - ${getPriorityName(ticket.priority)}`}
                      />
                      <PropertyListItem divider label="State">
                        <SeverityPill color={getStatusColor(ticket)}>
                          {getStatus(ticket)}
                        </SeverityPill>
                      </PropertyListItem>
                      {hardwareContent(ticket)}
                      {ticket.error_report_id ? (
                        <PropertyListItemLink
                          others={{ divider: true }}
                          label="Original Problem Report"
                          text={ticket.error_report_id}
                          onClick={() =>
                            history.push(`/problems/${ticket.error_report_id}`)
                          }
                        />
                      ) : null}
                      {ticket.rental_gid ? (
                        <PropertyListItemLink
                          others={{ divider: true }}
                          label="Rental"
                          text={ticket.rental_gid}
                          onClick={() => history.push(`/rentals/${ticket.rental_gid}`)}
                        />
                      ) : null}
                      {ticket.completed_task ? (
                        <PropertyListItemLink
                          others={{ divider: true }}
                          label="Maintenance Log"
                          text={ticket.completed_task}
                          onClick={() =>
                            history.push(`/maintenance/log/${ticket.completed_task}`)
                          }
                        />
                      ) : null}
                      {ticket.assignee &&
                      admins.find((admin: IAdmin) => admin.email === ticket.assignee) ? (
                        <PropertyListItemLink
                          label="Assignee"
                          text={ticket.assignee_name}
                          onClick={() =>
                            history.push(`/settings/admin/${ticket.assignee}`)
                          }
                        />
                      ) : (
                        <PropertyListItem label="Assignee" value="Unassigned" />
                      )}
                      {ticket.reporter &&
                      admins.find((admin: IAdmin) => admin.email === ticket.reporter) ? (
                        <>
                          <Divider />
                          <PropertyListItemLink
                            others={{ divider: true }}
                            label="Reporter"
                            text={ticket.reporter_name}
                            onClick={() =>
                              history.push(`/settings/admin/${ticket.reporter}`)
                            }
                          />
                        </>
                      ) : null}
                      {system.partner === 1 || system.partner === 2 ? (
                        <>
                          <Divider />
                          <PropertyListItemLink
                            others={{ divider: true }}
                            label="Report Hardware Failure"
                            text="Contact Manufacturer"
                            onClick={() => getOEMContact()}
                          />
                        </>
                      ) : null}
                      <PropertyListItem divider label="Tags" dataId="tags">
                        <Grid container spacing={1}>
                          {tags.map(
                            // @ts-ignore
                            (tag) => (
                              <Grid key={tag.label}>
                                <Chip
                                  sx={{
                                    height: 'auto',
                                    py: 0.5,
                                    '& .MuiChip-label': {
                                      display: 'block',
                                      whiteSpace: 'normal',
                                    },
                                  }}
                                  onDelete={() => tag.onClick(tag.label)}
                                  label={tag.label}
                                />
                              </Grid>
                            )
                          )}
                          <Grid>
                            <IconButtonMenu
                              buttons={[
                                {
                                  label: '',
                                  startIcon: <AddIcon />,
                                  onClick: () => setAddTagModal(true),
                                },
                              ]}
                            />
                          </Grid>
                        </Grid>
                      </PropertyListItem>
                    </PropertyList>
                  </Stack>
                }
              />
            </Grid>
            <Grid xs={12} lg={7}>
              <CommunicationLog
                ticketDescription={
                  <>
                    <Box className="messageRow">
                      <Box className="flexDisplay flexWrap">
                        <Typography variant="subtitle1" className="topRow">
                          Description
                        </Typography>
                      </Box>
                      <>
                        {ticket.description
                          ? ticket.description.split('\n').map(
                              (
                                p: {
                                  toString: () =>
                                    | string
                                    | number
                                    | boolean
                                    | React.ReactElement<
                                        any,
                                        string | React.JSXElementConstructor<any>
                                      >
                                    | React.ReactFragment
                                    | React.ReactPortal
                                    | null
                                    | undefined;
                                },
                                i: any
                              ) => (
                                <Typography
                                  align={'justify'}
                                  variant="body2"
                                  className="messageContent"
                                  key={i}
                                >
                                  {p.toString()}
                                </Typography>
                              )
                            )
                          : ''}
                      </>
                    </Box>
                  </>
                }
                header="Notes"
                log={ticket?.communication_log}
                saveNote={saveNote}
                handleNoteChange={handleNoteChange}
                loaded={systemLoaded && !isLoading}
                note={note}
              />
            </Grid>
          </Grid>
        }
        itemTopButtons={
          <IconButtonMenu
            buttons={[
              {
                label: 'Cancel',
                onClick: () => toggleConfirmationModal(),
              },
              {
                label: 'Edit',
                onClick: () => history.push(`/maintenance/edit/${ticketId}`),
                startIcon: <EditIcon />,
              },
              {
                label: 'Close',
                onClick: () => closeTicket(),
                startIcon: <CloseIcon />,
              },
            ]}
          />
        }
      />
      <MovaticCustomModal
        open={showModal}
        onClose={toggleConfirmationModal}
        content="Are you sure you want to cancel this maintenance ticket?"
        title="Cancel Maintenance Ticket"
        action={
          <IconButtonMenu
            buttons={[
              { label: 'No', onClick: toggleConfirmationModal },
              { label: 'Yes', onClick: handleCancelTicket },
            ]}
          />
        }
      />
      <MovaticCustomModal
        open={addTagModal}
        onClose={() => setAddTagModal(false)}
        content={
          <MovaticFieldGroupSelectFilter
            label={'Tags'}
            isLoading={isTagLoading}
            value={value}
            onChange={(event, newValue) => {
              setValue(
                // @ts-ignore
                newValue.filter((option) =>
                  // @ts-ignore
                  loadedTags.some((item) => item.label === option.label)
                )
              );
            }}
            options={loadedTags}
          />
        }
        title="Add Tag"
        action={
          <IconButtonMenu
            buttons={[
              { label: 'Cancel', onClick: () => setAddTagModal(false) },
              {
                label: 'Save',
                disabled: loadedTags?.length === 0,
                onClick: () => {
                  CustomAsyncToast({
                    promise: () =>
                      addTagsToMaintenanceTicket(ticket.id, {
                        tags: [
                          ...(ticket?.tags || []),
                          // @ts-ignore
                          ...value.map((item) => item.label),
                        ],
                      }),
                    successMessage: () => 'Tags added successfully',
                    loadingMessage: 'Adding tags...',
                    errorMessage:
                      'Oops! Looks like there was an error while adding the tags. Please try again later.',
                  }).then((results) => {
                    if (results) {
                      setAddTagModal(false);
                      refetch().then(() => {
                        setValue([]);
                      });
                    }
                  });
                },
              },
            ]}
          />
        }
      />
      <MovaticCustomModal
        customStyles={{
          content: {
            minWidth: 'none',
          },
        }}
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: isSmallScreen ? '90vw' : '30vw',
            margin: isSmallScreen ? 1 : 'auto',
            width: '100%',
          },
        }}
        open={deleteTagModal}
        onClose={() => setDeleteTagModal(false)}
        content={`Are you sure you want to remove the tag ${tagToDelete}?`}
        title={'Remove Tag'}
        action={
          <IconButtonMenu
            buttons={[
              {
                label: 'Cancel',
                onClick: () => setDeleteTagModal(false),
              },
              {
                label: 'Submit',
                onClick: () => {
                  CustomAsyncToast({
                    promise: () =>
                      addTagsToMaintenanceTicket(ticket.id, {
                        tags: ticket.tags
                          ? ticket.tags.filter((item: string) => item !== tagToDelete)
                          : [],
                      }),
                    successMessage: () => 'Tag removed successfully',
                    loadingMessage: 'Removing tag...',
                    errorMessage:
                      'Oops! Looks like there was an error while adding the tag to the Ticket. Please try again later.',
                  }).then((results) => {
                    if (results) {
                      setDeleteTagModal(false);
                      refetch().then(() => {
                        setTagToDelete('');
                      });
                    }
                  });
                },
              },
            ]}
          />
        }
      />
    </PageContainer>
  );
};

export default connect(
  (state: {
    admin: { admin: { admin: IAdmin }; systemAdmins: IAdmin[] };
    system: { current: ISystem; isLoaded: boolean };
    mode: IMode;
  }) => ({
    admin: state.admin.admin.admin,
    admins: state.admin.systemAdmins,
    system: state.system.current,
    systemLoaded: state.system.isLoaded,
    mode: state.mode,
  }),
  () => ({})
)(TicketDetail);
