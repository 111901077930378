import { useState, useEffect } from 'react';

const useSearchDebounce = (
  searchTerm: string | null | undefined,
  delay: number = 1000
) => {
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm, delay]);

  return debouncedSearchTerm;
};

export default useSearchDebounce;
