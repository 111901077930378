import { create } from 'zustand';

export interface ISystemInfo {
  oldId: string;
  gid: string;
}

export interface ISystemStore {
  systemInfo: ISystemInfo;
  setSystemInfo: (systemInfo: ISystemInfo) => void;
}

export const useSystemStore = create<ISystemStore>((set, get) => ({
  systemInfo: {
    oldId: '',
    gid: '',
  },
  setSystemInfo: (systemInfo: ISystemInfo) => set({ systemInfo }),
}));
