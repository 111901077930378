import React, { useRef, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { getInitials } from '../../utils';
import { connect } from 'react-redux';
import { IAdmin } from '../../ts/interface/admins.interface';
import { AccountPopover } from '../../layout/account-button/account-popover';
import { usePopover } from '../../hooks/use-popover';
import StyledAvatar from './styled-avatar';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Stack from '@mui/material/Stack';
import RoundedIconAvatar from './rounded-icon-avatar';
import AboutMeModal from '../aboutMe';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

const AccountInfo: React.FC<{ admin: IAdmin; open: boolean }> = ({ admin, open }) => {
  const popover = usePopover<HTMLButtonElement>();
  const [showAboutMeModal, setShowAboutMeModal] = useState(false);
  const styledIconButtonRef = useRef<HTMLDivElement>(null);

  const name = `${admin.firstName} ${admin.lastName}`;
  return (
    <Stack data-id={'account-section'} ref={styledIconButtonRef}>
      {open ? (
        <Stack>
          <ListItemButton
            onClick={popover.handleOpen}
            sx={{ backgroundColor: 'inherit', color: 'inherit' }}
          >
            <ListItemIcon>
              <IconButton onClick={popover.handleOpen}>
                <StyledAvatar>{getInitials(name)}</StyledAvatar>
              </IconButton>
            </ListItemIcon>
            <ListItemText
              primary={
                <Stack direction="column" justifyContent="center" alignItems="flex-start">
                  <Typography variant="subtitle1">{name}</Typography>
                  <Typography color="text.secondary" variant="body2">
                    {admin.email}
                  </Typography>
                </Stack>
              }
            />
          </ListItemButton>
        </Stack>
      ) : (
        <div onClick={popover.handleOpen}>
          <RoundedIconAvatar icon={<ManageAccountsIcon />} />
        </div>
      )}
      <AccountPopover
        admin={admin}
        anchorEl={styledIconButtonRef.current}
        onClose={popover.handleClose}
        open={popover.open}
        onAboutMe={() => setShowAboutMeModal(true)}
      />
      <AboutMeModal
        showModal={showAboutMeModal}
        handleClose={() => setShowAboutMeModal(false)}
      />
    </Stack>
  );
};

export default connect(
  (state: {
    admin: {
      admin: {
        admin: IAdmin;
      };
    };
  }) => ({
    admin: state.admin.admin.admin,
  }),
  () => ({})
)(AccountInfo);
