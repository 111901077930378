// GENERAL
import React from 'react';

export const RESET = 'RESET';
export const ENGLISH_LANGUAGE = 'en';
export const SMALL_SCREEN_THRESHOLD = 768;
export const LOCAL_STORAGE_SYSTEM_KEY = 'systemAux';
export const DEFAULT_ROWS_PER_PAGE = 25;
// STATS
export const SET_NAVIGATION_STATS = 'SET_NAVIGATION_STATS';
export const NAVIGATION_STATS_LOADED = 'NAVIGATION_STATS_LOADED';
export const SET_DASHBOARD_STATS = 'SET_DASHBOARD_STATS';
export const DASHBOARD_STATS_LOADED = 'DASHBOARD_STATS_LOADED';
export const SET_GRAPH_STATS = 'SET_GRAPH_STATS';
export const GRAPH_STATS_LOADED = 'GRAPH_STATS_LOADED';
// USERS
export const API_USERS_SUCCESS = 'API_USERS_SUCCESS';
export const USERS_LOADED = 'USERS_LOADED';
export const API_USER_DETAIL_SUCCESS = 'API_USER_DETAIL_SUCCESS';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const SET_USER_CARD = 'SET_USER_CARD';
export const CLEAR_CARD_INFO = 'CLEAR_CARD_INFO';
export const SET_USER_WALLET = 'SET_USER_WALLET';
export const CLEAR_WALLET_INFO = 'CLEAR_WALLET_INFO';
export const USER_LOADED = 'USER_LOADED';
export const SET_CURRENTUSER_NONDELINQUENT = 'SET_CURRENTUSER_NONDELINQUENT';
export const USER_RFID_CARDS_LOADED = 'USER_RFID_CARDS_LOADED';
export const SET_USER_RFID_CARDS = 'SET_USER_RFID_CARDS';
// STATIONS
export const API_STATIONS_SUCCESS = 'API_STATIONS_SUCCESS';
export const SET_STATION_DETAILS = 'SET_STATION_DETAILS';
export const CLEAR_DETAILED_STATON = 'CLEAR_DETAILED_STATON';
export const STATIONS_LOADED = 'STATIONS_LOADED';
export const DETAILED_STATION_LOADED = 'DETAILED_STATION_LOADED';
export const SET_STATION_IMAGES = 'SET_STATION_IMAGES';
export const STATION_IMAGES_LOADED = 'STATION_IMAGES_LOADED';
// RENTALS
export const API_RENTALS_SUCCESS = 'API_RENTALS_SUCCESS';
export const RENTALS_LOADED = 'RENTALS_LOADED';
export const SET_RENTAL_DETAILS = 'SET_RENTAL_DETAILS';
export const RENTAL_DETAIL_LOADED = 'RENTAL_DETAIL_LOADED';
export const UNLOCK_LOGS = 'UNLOCK_LOGS';
export const UNLOCK_LOGS_LOADED = 'UNLOCK_LOGS_LOADED';
export const SET_PENDING_CHARGES = 'SET_PENDING_CHARGES';
export const PENDING_CHARGES_LOADED = 'PENDING_CHARGES_LOADED';
export const PENDING_CHARGE_UPDATED = 'PENDING_CHARGE_UPDATED';
// HARDWARE
export const API_HARDWARE_SUCCESS = 'API_HARDWARE_SUCCESS';
export const SET_DETAILED_HARDWARE = 'SET_DETAILED_HARDWARE';
export const CLEAR_DETAILED_HARDWARE = 'CLEAR_DETAILED_HARDWARE';
export const HARDWARE_TABLE_PAGE = 'HARDWARE_TABLE_PAGE';
export const HARDWARE_LOADED = 'HARDWARE_LOADED';
export const HARDWARE_DETAIL_LOADED = 'HARDWARE_DETAIL_LOADED';
export const REMOTE_ACTION_LOADED = 'REMOTE_ACTION_LOADED';
export const SET_REMOTE_ACTION_LOADED = 'SET_REMOTE_ACTION_LOADED';
export const HARDWARE_TYPE_LOADED = 'HARDWARE_TYPE_LOADED';
export const SET_HARDWARE_TYPES = 'SET_HARDWARE_TYPES';
// HARDWARE PRODUCTS
export const API_HARDWARE_PRODUCT_SUCCESS = 'API_HARDWARE_PRODUCT_SUCCESS';
export const API_PRODUCT_VERSION_SUCCESS = 'API_PRODUCT_VERSION_SUCCESS';
export const API_PRODUCT_FIELD_SUCCESS = 'API_PRODUCT_FIELD_SUCCESS';
export const PRODUCT_LOADED = 'PRODUCT_LOADED';
export const PRODUCT_VERSION_LOADED = 'PRODUCT_VERSION_LOADED';
export const PRODUCT_FIELD_LOADED = 'PRODUCT_FIELD_LOADED';
// ADMIN
export const ADMIN_LOADED = 'ADMIN_LOADED';
export const API_SYSTEMS_ADMIN_SUCCESS = 'API_SYSTEMS_ADMIN_SUCCESS';
export const LOG_OUT = 'LOG_OUT';
export const LOGGED_IN_FAILED = 'LOGGED_IN_FAILED';
export const LOGGED_IN_SUCCESS = 'LOGGED_IN_SUCCESS';
export const DB_LOADED = 'DB_LOADED';
export const UPDATE_ACCESS = 'UPDATE_ACCESS';
export const SET_ACCESS = 'SET_ACCESS';
export const SET_ADMIN_NAMES = 'SET_ADMIN_NAMES';
// MODALS
export const CONFIRMATION_MODAL_CLOSE = 'CONFIRMATION_MODAL_CLOSE';
export const CONFIRMATION_MODAL_FAILURE = 'CONFIRMATION_MODAL_FAILURE';
// SYSTEM
export const API_SYSTEMS_SUCCESS = 'API_SYSTEMS_SUCCESS';
export const SET_DETAILED_SYSTEM = 'SET_DETAILED_SYSTEM';
export const API_STRIPE_INFO = 'API_STRIPE_INFO';
export const SET_BANK_INFO = 'SET_BANK_INFO';
export const SYSTEM_LOADING = 'SYSTEM_LOADING';
export const LOADING_TRANSFER_CSV = 'LOADING_TRANSFER_CSV';
export const LOADING_PAYMENTS_CSV = 'LOADING_PAYMENTS_CSV';
export const SET_BILLING_PAGE_STATS = 'SET_BILLING_PAGE_STATS';
export const IMAGE_SUCCESS = 'IMAGE_SUCCESS';
export const ALL_IMAGE_SUCCESS = 'ALL_IMAGE_SUCCESS';
export const INSERT_IMAGE = 'INSERT_IMAGE';
export const UPDATE_SYSTEM_LOGO_ID = 'UPDATE_SYSTEM_LOGO_ID';
export const UPDATE_SYSTEM_BANNER_ID = 'UPDATE_SYSTEM_BANNER_ID';
export const STRIPE_INFO_LOADED = 'STRIPE_INFO_LOADED';
export const STRIPE_MAX_REQUIRED_INFORMATION_MISSING = 1;
export const STRIPE_REQUIRED_INFORMATION_TYPE = 'external_account';
export const DEFAULT_GRACE_PERIOD = 300;
export const CENTS_PER_UNIT = 100;
export const LOCAL_HOSTNAME = 'localhost';
export const DEV_HOSTNAME = 'admindev.movatic.co';
export const STRIPE_ROUTING_NUMBER_TEST = '110000000';
export const STRIPE_ACCOUNT_NUMBER_TEST = '000123456789';

// TOAST
export const SUCCESS = 'SUCCESS';
export const CLEAR_SUCCESS = 'CLEAR_SUCCESS';
export const ERROR = 'ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
// MODULES
export const API_MODULE_SUCCESS = 'API_MODULE_SUCCESS';
export const SET_MODULE_DETAILS = 'SET_MODULE_DETAILS';
export const CLEAR_MODULE_DETAILS = 'CLEAR_MODULE_DETAILS';
export const LOCK_DETAIL_SEARCH = 'LOCK_DETAIL_SEARCH';
export const LOCK_TABLE_PAGE = 'LOCK_TABLE_PAGE';
// PROBLEMS
export const API_PROBLEMS_SUCCESS = 'API_PROBLEMS_SUCCESS';
export const PROBLEMS_LOADING = 'PROBLEMS_LOADING';
export const SET_PROBLEM_DETAILS = 'SET_PROBLEM_DETAILS';
export const PROBLEM_DETAIL_LOADED = 'PROBLEM_DETAIL_LOADED';
export const RENTAL_PROBLEMS_LOADED = 'RENTAL_PROBLEMS_LOADED';
export const SET_RENTAL_PROBLEMS = 'SET_RENTAL_PROBLEMS';
// MAINTENANCE
export const API_TICKET_SUCCESS = 'API_TICKET_SUCCESS';
export const API_COMPLETED_TASK_SUCCESS = 'API_COMPLETED_TASK_SUCCESS';
export const API_TASK_SUCCESS = 'API_TASK_SUCCESS';
export const TICKETS_LOADING = 'TICKETS_LOADING';
export const TICKET_DETAIL_LOADED = 'TICKET_DETAIL_LOADED';
export const COMPLETED_TASKS_LOADING = 'COMPLETED_TASKS_LOADING';
export const COMPLETED_TASK_DETAIL_LOADED = 'COMPLETED_TASK_DETAIL_LOADED';
export const TASKS_LOADING = 'TASKS_LOADING';
export const SET_DETAILED_TICKET = 'SET_DETAILED_TICKET';
export const SET_DETAILED_TASK = 'SET_DETAILED_TASK';

// TRANSACTIONS
export const API_TRANSACTION_SUCCESS = 'API_TRANSACTION_SUCCESS';
export const TRANSACTIONS_LOADED = 'TRANSACTIONS_LOADED';
export const API_CHARGES_SUCCESS = 'API_CHARGES_SUCCESS';
export const API_REFUNDS_SUCCESS = 'API_REFUNDS_SUCCESS';
export const TRANSACTION_DETAILS = 'TRANSACTION_DETAILS';
export const CLEAR_TRANSACTION_DETAILS = 'CLEAR_TRANSACTION_DETAILS';
export const FORMATTED_TRANSACTION_DETAILS = 'FORMATTED_TRANSACTION_DETAILS';

export const WALLET_TRANSACTION_DETAILS = 'WALLET_TRANSACTION_DETAILS';
export const CLEAR_WALLET_TRANSACTION_DETAILS = 'CLEAR_WALLET_TRANSACTION_DETAILS';
export const FORMATTED_WALLET_TRANSACTION_DETAILS =
  'FORMATTED_WALLET_TRANSACTION_DETAILS';
export const WALLET_TRANSACTION_DETAIL_LOADED = 'WALLET_TRANSACTION_DETAIL_LOADED';

export const TRANSACTION_DETAIL_LOADED = 'TRANSACTION_DETAIL_LOADED';
export const API_DISPUTES_SUCCESS = 'API_DISPUTES_SUCCESS';
export const DISPUTES_LOADED = 'DISPUTES_LOADED';
export const SET_DISPUTE_DETAILS = 'SET_DISPUTES_DETAILS';

export const HOLD_TRANSACTION_DETAILS = 'HOLD_TRANSACTION_DETAILS';
export const HOLD_TRANSACTION_DETAILS_LOADED = 'HOLD_TRANSACTION_DETAILS_LOADED';
// NEW RATES
export const SET_NEW_RATES = 'SET_NEW_RATES';
export const SET_RATE_DETAILS = 'SET_RATE_DETAILS';
export const INSERT_RATE = 'INSERT_RATE';
// MEMBERSHIPS
export const DURATIONS = [
  { seconds: 0, value: 'None' },
  { seconds: 600, value: '10 minutes' },
  { seconds: 900, value: '15 minutes' },
  { seconds: 1200, value: '20 minutes' },
  { seconds: 1800, value: '30 minutes' },
  { seconds: 3600, value: '1 hour' },
  { seconds: 5400, value: '1.5 hours' },
  { seconds: 7200, value: '2 hours' },
  { seconds: 10800, value: '3 hours' },
  { seconds: 21600, value: '6 hours' },
  { seconds: 28800, value: '8 hours' },
  { seconds: 43200, value: '12 hours' },
  { seconds: 86400, value: '24 hours' },
  { seconds: 172800, value: '48 hours' },
  { seconds: 259200, value: '78 hours' },
];

export const TIME_OPTIONS = [
  <option value="" key={6}>
    No Expiration
  </option>,
  <option value="86400" key={1}>
    1 Day
  </option>,
  <option value="604800" key={2}>
    1 Week
  </option>,
  <option value="2592000" key={3}>
    1 Month
  </option>,
  <option value="7776000" key={4}>
    3 Months
  </option>,
  <option value="31536000" key={5}>
    1 Year
  </option>,
];
export const SET_MEMBERSHIPS = 'SET_MEMBERSHIPS';
export const SET_MEMBERSHIP_DETAILS = 'SET_MEMBERSHIP_DETAILS';
export const CLEAR_MEMBERSHIP_DETAILS = 'CLEAR_MEMBERSHIP_DETAILS';
export const SET_USER_MEMBERSHIPS = 'SET_USER_MEMBERSHIPS';
export const SET_USER_MEMBERSHIPS_LOADED = 'SET_USER_MEMBERSHIPS_LOADED';
export const SET_DETAILED_MEMBERSHIP = 'SET_USER_MEMBERSHIP';
export const SET_MEMBERSHIP_IMAGES = 'SET_MEMBERSHIP_IMAGES';
export const MEMBERSHIP_IMAGES_LOADED = 'MEMBERSHIP_IMAGES_LOADED';
export const SET_USERS_FOR_MEMBERSHIPS = 'SET_USERS_FOR_MEMBERSHIPS';
export const BRANDING_COLOR = '#838383';

// MODE
export const CHANGE_MODE = 'CHANGE_MODE';

// TABLES
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const CHANGE_SIZE = 'CHANGE_SIZE';
export const DEFAULT_PAGE_ACTIVE = 'DEFAULT_PAGE_ACTIVE';
export const DEFAULT_PAGE_COMPLETED = 'DEFAULT_PAGE_COMPLETED';

// STATE CONSTANTS
export const ALL = -1;
export const UNASSIGNED = 0;
export const ACTIVE = 1;
export const INACTIVE = 2;
export const MAINTENANCE = 3;
export const RETIRED = 4;
export const RESERVED = 5;
export const REBALANCE = 6;
export const MISSING = 7;
export const LOST = 8;
export const STORED = 9;

// RESERVATIONS
export const API_RESERVATIONS_SUCCESS = 'API_RESERVATIONS_SUCCESS';
export const SET_RESERVATION_DETAILS = 'SET_RESERVATION_DETAILS';
export const INSERT_RESERVATION = 'INSERT_RESERVATION';
export const SET_RESERVATION = 'SET_RESERVATION';
export const INSERT_HARDWARE = 'INSERT_HARDWARE';
export const INSERT_RESERVATIONS = 'INSERT_RESERVATIONS';
export const CLEAR_LOADED = 'CLEAR_LOADED';

// COUPONS
export const GET_COUPONS_SUCCESS = 'GET_COUPONS_SUCCESS';
export const ADD_COUPON_SUCCESS = 'ADD_COUPON_SUCCESS';
export const SET_COUPON_DETAILS = 'SET_COUPON_DETAILS';
export const COUPON_DETAIL_LOADING = 'COUPON_DETAIL_LOADING';

export const COUPON_DURATION = [
  { seconds: 0, value: 'Select' },
  { seconds: 600, value: '10 Minutes' },
  { seconds: 1200, value: '20 Minutes' },
  { seconds: 1800, value: '30 Minutes' },
  { seconds: 3600, value: '1 Hour' },
  { seconds: 7200, value: '2 Hours' },
  { seconds: 10800, value: '3 Hours' },
  { seconds: 14400, value: '4 Hours' },
  { seconds: 43200, value: '1/2 Day' },
  { seconds: 86400, value: '1 Day' },
  { seconds: 604800, value: '1 Week' },
];

export const COUPON_TIME_OPTION = [
  { seconds: 0, value: 'Select' },
  { seconds: 3600, value: '1 Hour' },
  { seconds: 7200, value: '2 Hours' },
  { seconds: 10800, value: '3 Hours' },
  { seconds: 86400, value: '1 Day' },
  { seconds: 604800, value: '1 Week' },
];

//
export const UPDATE_LOADING = 'UPDATE_LOADING';

// WALLET
export const API_GET_WALLET_SUCCESS = 'API_GET_WALLET_SUCCESS';

// /////////////////////////
// //   TABLE FILTERS  ////
// ///////////////////////

// TRANSACTIONS
export const TSAVE_STRING_DATES = 'TSAVE_STRING_DATES';
export const TSAVE_START_DATE = 'TSAVE_START_DATE';
export const TSAVE_END_DATE = 'TSAVE_END_DATE';
export const TSAVE_FILTERED_DATES = 'TSAVE_FILTERED_DATES';
export const TCLEAR_FILTER_DATES = 'TCLEAR_DATES';
// RENTALS
// ACTIVE RENTALS
export const RSAVE_STRING_DATES = 'RSAVE_STRING_DATES';
export const RSAVE_START_DATE = 'RSAVE_START_DATE';
export const RSAVE_END_DATE = 'RSAVE_END_DATE';
export const RSAVE_FILTERED_DATES = 'RSAVE_FILTERED_DATES';
export const RCLEAR_FILTER_DATES = 'RCLEAR_DATES';

// COMPLETED RENTALS
export const RCSAVE_START_DATE = 'RCSAVE_START_DATE';
export const RCSAVE_END_DATE = 'RCSAVE_END_DATE';
export const RCSAVE_STRING_DATES = 'RCSAVE_STRING DATES';
export const RCCLEAR_FILTER_DATES = 'RC_CLEARFILTER_DATES';
export const RCSAVE_FILTERED_DATES = 'RCSAVE_FILTERED_DATES';

// /////////////////////////
// //   TABLE SEARCH   ////
// ///////////////////////
// USERS
export const ACTIVE_USERS_SEARCH = 'ACTIVE_USERS_SEARCH';
export const INACTIVE_USERS_SEARCH = 'INACTIVE_USERS_SEARCH';
export const BANNED_USERS_SEARCH = 'BANNED_USERS_SEARCH';
export const AUTH_PENDING_SEARCH = 'AUTH_PENDING_SEARCH';
export const EMAIL_PENDING_SEARCH = 'EMAIL_PENDING_SEARCH';
export const USER_RENTAL_SEARCH = 'USER_RENTAL_SEARCH';
export const USER_MEMBERSHIP_SEARCH = 'USER_MEMBERSHIP_SEARCH';
export const USER_RFID_CARD_SEARCH = 'USER_RFID_CARD_SEARCH';
// RENTALS
export const ACTIVE_RENTAL_SEARCH = 'ACTIVE_RENTAL_SEARCH';
export const COMPLETED_RENTAL_SEARCH = 'COMPLETED_RENTAL_SEARCH';
// HARDWARE
export const HARDWARE_SEARCH = 'HARDWARE_SEARCH';
export const HARDWARE_RENTAL_SEARCH = 'HARDWARE_RENTAL_SEARCH';
export const LOCK_SEARCH = 'LOCK_SEARCH';
export const HARDWARE_TICKET_SEARCH = 'HARDWARE_TICKET_SEARCH';
export const HARDWARE_LOG_SEARCH = 'HARDWARE_LOG_SEARCH';
export const MODULE_TICKET_SEARCH = 'MODULE_TICKET_SEARCH';
export const MODULE_LOG_SEARCH = 'MODULE_LOG_SEARCH';
// STATIONS
export const STATION_SEARCH = 'STATION_SEARCH';
export const STATION_DETAIL_HARDWARE_SEARCH = 'STATION_DETAIL_HARDWARE_SEARCH';
export const STATION_DETAIL_RENTAL_VALUE = 'STATION_DETAIL_RENTAL_VALUE';
// PROBLEMS
export const PROBLEM_OPEN_SEARCH = 'PROBLEM_OPEN_SEARCH';
export const PROBLEM_CLOSED_SEARCH = 'PROBLEM_CLOSED_SEARCH';
// MAINTENANCE
export const MAINTENANCE_OPEN_SEARCH = 'MAINTENANCE_OPEN_SEARCH';
export const MAINTENANCE_SCHEDULED_SEARCH = 'MAINTENANCE_SCHEDULED_SEARCH';
export const MAINTENANCE_CLOSED_SEARCH = 'MAINTENANCE_CLOSED_SEARCH';
export const MAINTENANCE_CANCELED_SEARCH = 'MAINTENANCE_CANCELED_SEARCH';
export const MAINTENANCE_ALL_SEARCH = 'MAINTENANCE_ALL_SEARCH';
export const MAINTENANCE_TASK_SEARCH = 'MAINTENANCE_TASK_SEARCH';
// BILLING
export const BILLING_TRANSACTION_SEARCH = 'BILLING_TRANSACTION_SEARCH';
export const BILLING_DISPUTE_SEARCH = 'BILLING_DISPUTE_SEARCH';
export const BILLING_DESCRIPTOR_MAX_LENGTH = 22;
// SETTINGS
export const SETTINGS_ADMIN_SEARCH = 'SETTINGS_ADMIN_SEARCH';
// COUPONS
export const COUPON_SEARCH = 'COUPON_SEARCH';
export const RETIRED_COUPON_SEARCH = 'RETIRED_COUPON_SEARCH';
// RESERVATIONS
export const COMPLETED_RESERVATION_SEARCH = 'COMPLETED_RESERVATION_SEARCH';
export const PENDING_RESERVATION_SEARCH = 'PENDING_RESERVATION_SEARCH';
export const ASSIGNED_RESERVATION_SEARCH = 'ASSIGNED_RESERVATION_SEARCH';
export const CANCELED_RESERVATION_SEARCH = 'CANCELED_RESERVATION_SEARCH';
export const RES_SAVE_START_DATE = 'RES_SAVE_START_DATE';
export const RES_SAVE_END_DATE = 'RES_SAVE_END_DATE';
export const RES_SAVE_STRING_DATES = 'RES_SAVE_STRING_DATES';
export const RES_CLEAR_FILTER_DATES = 'RES_CLEAR_FILTER_DATES';
export const RES_SAVE_FILTERED_DATES = 'RES_SAVE_FILTERED_DATES';
// MEMBERSHIP
export const ACTIVE_MEMBERSHIP_SEARCH = 'ACTIVE_MEMBERSHIP_SEARCH';
export const PENDING_MEMBERSHIP_SEARCH = 'PENDING_MEMBERSHIP_SEARCH';
export const HISTORIC_MEMBERSHIP_SEARCH = 'HISTORIC_MEMBERSHIP_SEARCH';
export const PENDING_MEMBER_SEARCH = 'PENDING_MEMBER_SEARCH';
// HARDWARE NAMES
export const BIKE = 'bike';
export const LOCKER = 'locker';
export const LOCK = 'lock';
export const SCOOTER = 'scooter';
export const BATTERY = 'battery';
export const KAYAK = 'kayak';
export const RACK = 'rack';
export const BIKE_ROOM = 'bike room';
export const BEACH_CHAIR = 'beach chair';
export const KEYBOX = 'keybox';
export const CANOE = 'canoe';
// ADMIN PERMISSIONS
export const ADMIN_PERMISSIONS = {
  admin: 2,
  rental: 4,
  rates: 8,
  refunds: 16,
  bank: 32,
  physical: 64,
  users: 128,
  locations: 256,
  support: 512,
  maintenance: 1024,
  memberships: 2048,
  full: 4095,
};

// MOVATIC PARTNER
export const MOVATIC_PARTNER = 0;

// CLEAR
export const CLEAR_SEARCHES = 'CLEAR_SEARCHS';

// COLORS
export const MOVATIC_PRIMARY_COLOR = (opacity) =>
  // == hex value #0366b3
  `rgba(3, 102, 179, ${opacity || 1})`;

export const MOVATIC_FONT_COLOR = '#555555';

export const MOVATIC_FONTS = 'Montserrat, sans-serif';

export const ROUTES_COLOR_BY_TYPE = (type) => {
  switch (type) {
    case 'hardware':
      return colors.NO_GO;
    case 'device':
      return colors.SLOW_ZONE;
    default:
      return colors.IN_MAINTENANCE;
  }
};

// Return hex color (e.g. #324623)
export const DEGRADE_COLOR = (color, degradeAmount) => {
  return (
    '#' +
    color
      .replace(/^#/, '')
      .replace(/../g, (color) =>
        (
          '0' +
          Math.min(255, Math.max(0, parseInt(color, 16) + degradeAmount)).toString(16)
        ).slice(-2)
      )
  );
};

export const colors = {
  HOVER: '#1689e2',
  AVAILABLE: '#006193',
  IN_RENTAL: '#007831',
  IN_MAINTENANCE: '#e59300',
  LOW_BATTERY: '#a4001d',
  NO_GO: '#ff0000',
  SLOW_ZONE: '#eeee00',
  RESTRICTED_ZONE: '#ffa500',
  PREFERRED_ZONE: '#2da840',
  MOVATIC_BLUE: '#0366b3',
  MOVATIC_ALMOST_WHITE: '#f8f8f8',
};

export const ZONE_LEGEND_LABELS = [
  { label: 'No Go / System', color: colors.NO_GO },
  { label: 'Slow Zone', color: colors.SLOW_ZONE },
  { label: 'Restricted Parking', color: colors.RESTRICTED_ZONE },
  { label: 'Preferred Parking', color: colors.PREFERRED_ZONE },
  { label: 'Parking', color: colors.MOVATIC_BLUE },
];

export const HARDWARE_LEGEND_LABELS = [
  { label: 'Available', color: colors.AVAILABLE },
  { label: 'In Rental', color: colors.IN_RENTAL },
  { label: 'In Maintenance', color: colors.IN_MAINTENANCE },
  { label: 'Low Battery', color: colors.LOW_BATTERY },
];

export const monthArray = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export function getZoneColors(zoneType) {
  let strokeColor = MOVATIC_PRIMARY_COLOR(1);
  let fillColor = `${strokeColor}20`;
  let strokeWidth = 2;
  let lineDashPattern = [0, 0];
  if (zoneType === 'restricted') {
    strokeColor = colors.RESTRICTED_ZONE;
    fillColor = `${strokeColor}40`;
  } else if (zoneType === 'nogo') {
    strokeColor = colors.NO_GO;
    fillColor = `${strokeColor}40`;
  } else if (zoneType === 'slowzone') {
    strokeColor = colors.SLOW_ZONE;
    fillColor = `${strokeColor}40`;
  } else if (zoneType === 'preferred') {
    strokeColor = colors.PREFERRED_ZONE;
    fillColor = `${strokeColor}40`;
  } else if (zoneType === 'system') {
    strokeColor = colors.NO_GO;
    fillColor = `#00000000`;
    strokeWidth = 4;
    lineDashPattern = [15, 15];
  }

  return {
    strokeColor,
    fillColor,
    strokeWidth,
    lineDashPattern,
  };
}

export const RATE_DURATIONS = [
  { seconds: 0, value: 'None' },
  { seconds: 60, value: '1 minute' },
  { seconds: 300, value: '5 minutes' },
  { seconds: 600, value: '10 minutes' },
  { seconds: 900, value: '15 minutes' },
  { seconds: 1200, value: '20 minutes' },
  { seconds: 1800, value: '30 minutes' },
  { seconds: 3600, value: '1 hour' },
  { seconds: 7200, value: '2 hours' },
  { seconds: 10800, value: '3 hours' },
  { seconds: 21600, value: '6 hours' },
  { seconds: 28800, value: '8 hours' },
  { seconds: 43200, value: '12 hours' },
  { seconds: 64800, value: '18 hours' },
  { seconds: 86400, value: '1 day' },
  { seconds: 172800, value: '2 days' },
  { seconds: 259200, value: '3 days' },
  { seconds: 604800, value: '1 week' },
  { seconds: 2592000, value: '1 month' },
];

export const SYSTEM_USE_CASE = [
  { name: 'Amenity - real estate, hotel', value: 'Amenity - real estate, hotel' },
  { name: 'Mobility - municipal', value: 'Mobility - municipal' },
  { name: 'Recreational', value: 'Recreational' },
  {
    name: 'Multi-location, multi-use case, operator',
    value: 'Multi-location, multi-use case, operator',
  },
];

export const SYSTEM_BUSINESS_DETAIL = [
  { name: 'Startup, new venture', value: 'Startup, new venture' },
  { name: 'Existing service provider', value: 'Existing service provider' },
  {
    name: 'Existing business expanding services',
    value: 'Existing business expanding services',
  },
];

export const HARDWARE_NUMBER_LIMIT = 999999999;
export const RADIUS_UPPER_LIMIT = 999999;
export const API_PAGINATION_LIMIT = 100;
export const STATION_TITLE = 'Location';

export const MOVATIC_SHOP = 'https://shop.movatic.co/';
export const MOVATIC_TOS = 'https://legal.movatic.co/platform-tos';
export const MOVATIC_DEFAULT_APP_LINK = 'https://apps.movatic.co/';

export const CHARGE_TRANSACTION_ID = 'ch';
export const REFUND_TRANSACTION_ID = 're';
export const TRANSFER_TRANSACTION_ID = 'po';

export const GOOGLE_RECAPTCHA_SITE_KEY = '6Lfv32IfAAAAAJvqJBLcAtIEabayN29Rccjp9ph5';

export const STRIPE_LIVE_KEY = 'pk_live_pYdZfXZEdwXsrgBiiZq08qhe';
export const STRIPE_TEST_KEY = 'pk_test_GlNsOiyyzS8vajk7H5lIAlSf';

export const EVENT_LOGS_TYPES = [
  { name: 'Important', value: 'Important', color: 'normal' },
  { name: 'Device Discovered', value: 'Device Discovered', color: 'info' },
  { name: 'Device Connected', value: 'Device Connected', color: 'success' },
  { name: 'Unlock Success', value: 'Unlock Success', color: 'success' },
  { name: 'Lock is unlocked', value: 'Lock is unlocked', color: 'success' },
  {
    name: 'UnlockRequestConfirmation',
    value: 'UnlockRequestConfirmation',
    color: 'success',
  },
  { name: 'LockRequestConfirmation', value: 'LockRequestConfirmation', color: 'success' },
  { name: 'CmdUnlockConfirmation', value: 'CmdUnlockConfirmation', color: 'success' },
  { name: 'CmdLockConfirmation', value: 'CmdLockConfirmation', color: 'success' },
  { name: 'CmdUnlockRequest', value: 'CmdUnlockRequest', color: 'info' },
  { name: 'CmdLockRequest', value: 'CmdLockRequest', color: 'info' },
  { name: 'CmdUnlock', value: 'CmdUnlock', color: 'info' },
  { name: 'CmdLock', value: 'CmdLock', color: 'info' },
  { name: 'CmdSignIn', value: 'CmdSignIn', color: 'info' },
  { name: 'lock_open', value: 'lock_open', color: 'success' },
  { name: 'lock_closed', value: 'lock_closed', color: 'success' },
  {
    name: 'Success Fetch Unlock Code',
    value: 'Success Fetch Unlock Code',
    color: 'success',
  },
  { name: 'CmdUnlockBattery', value: 'CmdUnlockBattery', color: 'info' },
  { name: 'unlocked Confirmation', value: 'unlocked Confirmation', color: 'success' },
  {
    name: 'RFID Starting Rental Error',
    value: 'RFID Starting Rental Error',
    color: 'error',
  },
  {
    name: 'BLE Characteristic Write Failure',
    value: 'BLE Characteristic Write Failure',
    color: 'error',
  },
  { name: 'RentalEndError', value: 'RentalEndError', color: 'error' },
  { name: 'error', value: 'error', color: 'error' },
  { name: 'LocationCheckError', value: 'LocationCheckError', color: 'error' },
  {
    name: 'Failure Fetch Unlock Code',
    value: 'Failure Fetch Unlock Code',
    color: 'error',
  },
  { name: 'BLE Timeout', value: 'BLE Timeout', color: 'error' },
  { name: 'Plugin is not inserted', value: 'Plugin is not inserted', color: 'warning' },
  { name: 'Lock is locking', value: 'Lock is locking', color: 'success' },
  { name: 'Lock is locked', value: 'Lock is locked', color: 'success' },
];

export const EMAIL_REGEX = /^@(?:\w+\.?)*(?:\w+\.\w+|\w+-\w+\.\w+)$/;
