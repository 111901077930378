import React, { useState } from 'react';
import { connect } from 'react-redux';
import { displaySuccess, history } from '../../../utils';
import { addIssuingCard } from '../../../api/issuingCard';
import { IIssuingCardAdd } from '../../../ts/interface/issuingCard.interface';
import MenuItem from '@mui/material/MenuItem';
import {
  MovaticFieldGroupCheck,
  MovaticFieldGroupSelect,
  MovaticFieldGroupText,
} from '../../../components/Redesing/movatic-field-group';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import SaveIcon from '@mui/icons-material/Save';
import CardView from '../../../components/Redesing/card-view';
import PageContainer from '../../../components/Redesing/page-container';
import { Stack } from '@mui/system';

const AddIssuingCard = ({ systemLoaded }: { systemLoaded: boolean }) => {
  const [cardType, setCardType] = useState<string>('');
  const [cardholderFirstName, setCardholderFirstName] = useState<string>('');
  const [cardholderLastName, setCardholderLastName] = useState<string>('');
  const [cardholderEmail, setCardholderEmail] = useState<string>('');
  const [cardholderPhone, setCardholderPhone] = useState<string>('');
  const [billingAddressLine1, setBillingAddressLine1] = useState<string>('');
  const [billingAddressLine2, setBillingAddressLine2] = useState<string>('');
  const [billingAddressCity, setBillingAddressCity] = useState<string>('');
  const [billingAddressState, setBillingAddressState] = useState<string>('');
  const [billingAddressPostalCode, setBillingAddressPostalCode] = useState<string>('');
  const [billingAddressCountry, setBillingAddressCountry] = useState<string>('');
  const [useBillingAddressForShipping, setUseBillingAddressForShipping] =
    useState<boolean>(false);
  const [shippingAddressLine1, setShippingAddressLine1] = useState<string>('');
  const [shippingAddressLine2, setShippingAddressLine2] = useState<string>('');
  const [shippingAddressCity, setShippingAddressCity] = useState<string>('');
  const [shippingAddressState, setShippingAddressState] = useState<string>('');
  const [shippingAddressPostalCode, setShippingAddressPostalCode] = useState<string>('');
  const [shippingAddressCountry, setShippingAddressCountry] = useState<string>('');
  const [addSpendingControls, setAddSpendingControls] = useState<boolean>(false);
  const [spendingControlAmount, setSpendingControlAmount] = useState<number>(0);
  const [spendingControlInterval, setSpendingControlInterval] = useState<string>('');

  const [cardTypeValidation, setCardTypeValidation] = useState<string | null>(null);
  const [cardholderFirstNameValidation, setCardholderFirstNameValidation] = useState<
    string | null
  >(null);
  const [cardholderLastNameValidation, setCardholderLastNameValidation] = useState<
    string | null
  >(null);
  const [cardholderEmailValidation, setCardholderEmailValidation] = useState<
    string | null
  >(null);
  const [billingAddressLine1Validation, setBillingAddressLine1Validation] = useState<
    string | null
  >(null);
  const [billingAddressCityValidation, setBillingAddressCityValidation] = useState<
    string | null
  >(null);
  const [billingAddressStateValidation, setBillingAddressStateValidation] = useState<
    string | null
  >(null);
  const [billingAddressPostalCodeValidation, setBillingAddressPostalCodeValidation] =
    useState<string | null>(null);
  const [billingAddressCountryValidation, setBillingAddressCountryValidation] = useState<
    string | null
  >(null);
  const [shippingAddressLine1Validation, setShippingAddressLine1Validation] = useState<
    string | null
  >(null);
  const [shippingAddressCityValidation, setShippingAddressCityValidation] = useState<
    string | null
  >(null);
  const [shippingAddressStateValidation, setShippingAddressStateValidation] = useState<
    string | null
  >(null);
  const [shippingAddressPostalCodeValidation, setShippingAddressPostalCodeValidation] =
    useState<string | null>(null);
  const [shippingAddressCountryValidation, setShippingAddressCountryValidation] =
    useState<string | null>(null);
  const [spendingControlAmountValidation, setSpendingControlAmountValidation] = useState<
    string | null
  >(null);
  const [spendingControlIntervalValidation, setSpendingControlIntervalValidation] =
    useState<string | null>(null);

  const physicalCardType = 'physical';

  const canSubmit = () => {
    let canSubmit = true;
    if (!cardType) {
      canSubmit = false;
      setCardTypeValidation('error');
    } else {
      setCardTypeValidation(null);
    }
    if (!cardholderFirstName) {
      canSubmit = false;
      setCardholderFirstNameValidation('error');
    } else {
      setCardholderFirstNameValidation(null);
    }
    if (!cardholderLastName) {
      canSubmit = false;
      setCardholderLastNameValidation('error');
    } else {
      setCardholderLastNameValidation(null);
    }
    if (!cardholderEmail) {
      canSubmit = false;
      setCardholderEmailValidation('error');
    } else {
      setCardholderEmailValidation(null);
    }
    if (!billingAddressLine1) {
      canSubmit = false;
      setBillingAddressLine1Validation('error');
    } else {
      setBillingAddressLine1Validation(null);
    }
    if (!billingAddressCity) {
      canSubmit = false;
      setBillingAddressCityValidation('error');
    } else {
      setBillingAddressCityValidation(null);
    }
    if (!billingAddressState) {
      canSubmit = false;
      setBillingAddressStateValidation('error');
    } else {
      setBillingAddressStateValidation(null);
    }
    if (!billingAddressPostalCode) {
      canSubmit = false;
      setBillingAddressPostalCodeValidation('error');
    } else {
      setBillingAddressPostalCodeValidation(null);
    }
    if (!billingAddressCountry) {
      canSubmit = false;
      setBillingAddressCountryValidation('error');
    } else {
      setBillingAddressCountryValidation(null);
    }
    if (cardType === physicalCardType && !useBillingAddressForShipping) {
      if (!shippingAddressLine1) {
        canSubmit = false;
        setShippingAddressLine1Validation('error');
      } else {
        setShippingAddressLine1Validation(null);
      }
      if (!shippingAddressCity) {
        canSubmit = false;
        setShippingAddressCityValidation('error');
      } else {
        setShippingAddressCityValidation(null);
      }
      if (!shippingAddressState) {
        canSubmit = false;
        setShippingAddressStateValidation('error');
      } else {
        setShippingAddressStateValidation(null);
      }
      if (!shippingAddressPostalCode) {
        canSubmit = false;
        setShippingAddressPostalCodeValidation('error');
      } else {
        setShippingAddressPostalCodeValidation(null);
      }
      if (!shippingAddressCountry) {
        canSubmit = false;
        setShippingAddressCountryValidation('error');
      } else {
        setShippingAddressCountryValidation(null);
      }
    }
    if (addSpendingControls) {
      if (!spendingControlAmount) {
        canSubmit = false;
        setSpendingControlAmountValidation('error');
      } else {
        setSpendingControlAmountValidation(null);
      }
      if (!spendingControlInterval) {
        canSubmit = false;
        setSpendingControlIntervalValidation('error');
      } else {
        setSpendingControlIntervalValidation(null);
      }
    }
    return canSubmit;
  };

  const submit = () => {
    if (canSubmit()) {
      const issuingCardPayload = {
        type: cardType,
        name: cardholderFirstName + ' ' + cardholderLastName,
        first_name: cardholderFirstName,
        last_name: cardholderLastName,
        email: cardholderEmail,
        phone_number: cardholderPhone,
        billing_address: {
          line1: billingAddressLine1,
          line2: billingAddressLine2,
          city: billingAddressCity,
          state: billingAddressState,
          postal_code: billingAddressPostalCode,
          country: billingAddressCountry,
        },
        shipping_address: {},
        spending_controls: addSpendingControls ? {} : null,
      };

      if (!useBillingAddressForShipping) {
        issuingCardPayload.shipping_address = {
          name: cardholderFirstName + ' ' + cardholderLastName,
          line1: shippingAddressLine1,
          line2: shippingAddressLine2,
          city: shippingAddressCity,
          state: shippingAddressState,
          postal_code: shippingAddressPostalCode,
          country: shippingAddressCountry,
        };
      } else {
        issuingCardPayload.shipping_address = {
          name: cardholderFirstName + ' ' + cardholderLastName,
          line1: billingAddressLine1,
          line2: billingAddressLine2,
          city: billingAddressCity,
          state: billingAddressState,
          postal_code: billingAddressPostalCode,
          country: billingAddressCountry,
        };
      }
      if (addSpendingControls) {
        issuingCardPayload.spending_controls = {
          amount: spendingControlAmount,
          interval: spendingControlInterval,
        };
      }
      addIssuingCard(issuingCardPayload as IIssuingCardAdd).then(() => {
        displaySuccess('Issuing Card Added Successfully!');
        history.push('/billing/issuing');
      });
    }
  };

  return (
    <PageContainer>
      <CardView
        isLoading={!systemLoaded}
        title={'Add'}
        content={
          <Stack px={2} pb={2}>
            <MovaticFieldGroupSelect
              label={'Card Type'}
              description="The type of card to issue, either physical or virtual."
              name={'card_type'}
              optionNodes={[
                { value: 'physical', label: 'Physical' },
                { value: 'virtual', label: 'Virtual' },
              ].map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
              value={cardType}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setCardType(e.target.value);
                setCardTypeValidation(null);
              }}
              hasError={cardTypeValidation === 'error'}
              id={'card_type'}
            />
            {cardType && (
              <div>
                <MovaticFieldGroupText
                  id="cardholder_first_name"
                  name={'cardholder_first_name'}
                  label="Cardholder First Name"
                  description="The first name of the cardholder."
                  hasError={cardholderFirstNameValidation === 'error'}
                  value={cardholderFirstName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setCardholderFirstName(e.target.value);
                    setCardholderFirstNameValidation(null);
                  }}
                />
                <MovaticFieldGroupText
                  id="cardholder_last_name"
                  name={'cardholder_last_name'}
                  label="Cardholder LAst Name"
                  description="The last name of the cardholder."
                  hasError={cardholderLastNameValidation === 'error'}
                  value={cardholderLastName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setCardholderLastName(e.target.value);
                    setCardholderLastNameValidation(null);
                  }}
                />
                <MovaticFieldGroupText
                  id="cardholder_email"
                  name={'cardholder_email'}
                  label="Cardholder Email"
                  description="The email of the cardholder."
                  hasError={cardholderEmailValidation === 'error'}
                  value={cardholderEmail}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setCardholderEmail(e.target.value);
                    setCardholderEmailValidation(null);
                  }}
                />
                <MovaticFieldGroupText
                  id="cardholder_phone"
                  name={'cardholder_phone'}
                  label="Cardholder Phone"
                  description="The phone of the cardholder."
                  value={cardholderEmail}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setCardholderPhone(e.target.value);
                  }}
                />

                <MovaticFieldGroupCheck
                  id="add_spending_controls"
                  name={'add_spending_controls'}
                  label="Add Spending Controls"
                  description="Whether to add spending controls to the card."
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setAddSpendingControls(e.target.checked)
                  }
                  checked={addSpendingControls}
                />

                {addSpendingControls && (
                  <>
                    <MovaticFieldGroupText
                      id="spending_control_amount"
                      name={'spending_control_amount'}
                      label="Spending Control Amount"
                      description="The spending control amount of the card."
                      value={spendingControlAmount}
                      hasError={spendingControlAmountValidation === 'error'}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setSpendingControlAmount(parseInt(e.target.value));
                        setSpendingControlAmountValidation(null);
                      }}
                    />

                    <MovaticFieldGroupSelect
                      id="spending_control_interval"
                      name={'spending_control_interval'}
                      label="Spending Control Interval"
                      description="The spending control interval of the card."
                      optionNodes={[
                        { value: 'per_authorization', label: 'Per Authorization' },
                        { value: 'daily', label: 'Daily' },
                        { value: 'weekly', label: 'Weekly' },
                        { value: 'monthly', label: 'Monthly' },
                        { value: 'yearly', label: 'Yearly' },
                        { value: 'all_time', label: 'All Time' },
                      ].map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                      value={spendingControlInterval}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setSpendingControlInterval(e.target.value);
                        setSpendingControlIntervalValidation(null);
                      }}
                      hasError={spendingControlIntervalValidation === 'error'}
                    />
                  </>
                )}

                <MovaticFieldGroupText
                  id="billing_address_line1"
                  name={'billing_address_line1'}
                  label="Billing Address Line 1"
                  description="The billing address line 1 of the cardholder."
                  value={billingAddressLine1}
                  hasError={billingAddressLine1Validation === 'error'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setBillingAddressLine1(e.target.value);
                    setBillingAddressLine1Validation(null);
                  }}
                />

                <MovaticFieldGroupText
                  id="billing_address_line2"
                  name={'billing_address_line2'}
                  label="Billing Address Line 2"
                  description="The billing address line 2 of the cardholder."
                  value={billingAddressLine2}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setBillingAddressLine2(e.target.value);
                  }}
                />

                <MovaticFieldGroupText
                  id="billing_address_city"
                  name={'billing_address_city'}
                  label="Billing Address City"
                  description="The billing address city of the cardholder."
                  hasError={billingAddressCityValidation === 'error'}
                  value={billingAddressCity}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setBillingAddressCity(e.target.value);
                    setBillingAddressCityValidation(null);
                  }}
                />

                <MovaticFieldGroupText
                  id="billing_address_state"
                  name={'billing_address_state'}
                  label="Billing Address State"
                  description="The billing address state of the cardholder."
                  hasError={billingAddressStateValidation === 'error'}
                  value={billingAddressState}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setBillingAddressState(e.target.value);
                    setBillingAddressStateValidation(null);
                  }}
                />

                <MovaticFieldGroupText
                  id="billing_address_postal_code"
                  name={'billing_address_postal_code'}
                  label="Billing Address Postal Code"
                  description="The billing address postal code of the cardholder."
                  hasError={billingAddressPostalCodeValidation === 'error'}
                  value={billingAddressPostalCode}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setBillingAddressPostalCode(e.target.value);
                    setBillingAddressPostalCodeValidation(null);
                  }}
                />

                <MovaticFieldGroupText
                  id="billing_address_country"
                  name={'billing_address_country'}
                  label="Billing Address Country"
                  description="The billing address country of the cardholder."
                  hasError={billingAddressCountryValidation === 'error'}
                  value={billingAddressCountry}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setBillingAddressCountry(e.target.value);
                    setBillingAddressCountryValidation(null);
                  }}
                />

                {cardType === physicalCardType && (
                  <MovaticFieldGroupCheck
                    id="use_billing_address_for_shipping"
                    name={'use_billing_address_for_shipping'}
                    label="Use Billing Address For Shipping"
                    description="Whether to use the billing address for shipping."
                    checked={useBillingAddressForShipping}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setUseBillingAddressForShipping(e.target.checked)
                    }
                  />
                )}
                {!useBillingAddressForShipping && cardType === physicalCardType && (
                  <div>
                    <MovaticFieldGroupText
                      id="shipping_address_line1"
                      name={'shipping_address_line1'}
                      label="Shipping Address Line 1"
                      description="The shipping address line 1 of the cardholder."
                      hasError={shippingAddressLine1Validation === 'error'}
                      value={shippingAddressLine1}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setShippingAddressLine1(e.target.value);
                        setShippingAddressLine1Validation(null);
                      }}
                    />

                    <MovaticFieldGroupText
                      id="shipping_address_line2"
                      name={'shipping_address_line2'}
                      label="Shipping Address Line 2"
                      description="The shipping address line 2 of the cardholder."
                      value={shippingAddressLine2}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setShippingAddressLine2(e.target.value);
                      }}
                    />
                    <MovaticFieldGroupText
                      id="shipping_address_city"
                      name={'shipping_address_city'}
                      label="Shipping Address City"
                      description="The shipping address city of the cardholder."
                      hasError={shippingAddressCityValidation === 'error'}
                      value={shippingAddressCity}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setShippingAddressCity(e.target.value);
                        setShippingAddressCityValidation(null);
                      }}
                    />
                    <MovaticFieldGroupText
                      id="shipping_address_state"
                      name={'shipping_address_state'}
                      label="Shipping Address State"
                      description="The shipping address state of the cardholder."
                      hasError={shippingAddressStateValidation === 'error'}
                      value={shippingAddressState}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setShippingAddressState(e.target.value);
                        setShippingAddressStateValidation(null);
                      }}
                    />
                    <MovaticFieldGroupText
                      id="shipping_address_postal_code"
                      name={'shipping_address_postal_code'}
                      label="Shipping Address Postal Code"
                      description="The shipping address postal code of the cardholder."
                      hasError={shippingAddressPostalCodeValidation === 'error'}
                      value={shippingAddressPostalCode}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setShippingAddressPostalCode(e.target.value);
                        setShippingAddressPostalCodeValidation(null);
                      }}
                    />
                    <MovaticFieldGroupText
                      id="shipping_address_country"
                      name={'shipping_address_country'}
                      label="Shipping Address Country"
                      description="The shipping address country of the cardholder."
                      hasError={shippingAddressCountryValidation === 'error'}
                      value={shippingAddressCountry}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setShippingAddressCountry(e.target.value);
                        setShippingAddressCountryValidation(null);
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </Stack>
        }
        headerActions={
          <>
            <IconButtonMenu
              buttons={[
                {
                  label: 'Cancel',
                  dataId: 'cancel',
                  onClick: () => history.push('/billing/issuing'),
                },
                {
                  label: 'Save',
                  dataId: 'save',
                  onClick: submit,
                  disabled: !canSubmit,
                  startIcon: <SaveIcon />,
                },
              ]}
            />
          </>
        }
      />
    </PageContainer>
  );
};

export default connect(
  (state: { system: { isLoaded: boolean } }) => ({
    systemLoaded: state.system.isLoaded,
  }),
  () => ({})
)(AddIssuingCard);
