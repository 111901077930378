import React from 'react';
import DialogContent from "@mui/material/DialogContent";

const ModalContent = ({children, customStyle, useDividers}) => {
    return (
        <DialogContent dividers={useDividers} sx={customStyle}>
            {children}
        </DialogContent>
    );
};

export default ModalContent;
