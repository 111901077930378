import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ButtonInfo } from '../../ts/interface/components/redesing/table.interface';
import { neutral } from '../../theme/colors';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import useIsMobileDevice from '../../hooks/mobileDevices/useIsMobileDevice';
import Typography from '@mui/material/Typography';

interface IconButtonMenuProps {
  buttons: ButtonInfo[];
  slice?: number;
  showEllipsis?: boolean;
  buttonLimit?: number;
  dataId?: string;
}

function IconButtonMenu({
  buttons,
  slice = 2,
  showEllipsis = false,
  buttonLimit = 2,
  dataId = 'icon-button-menu',
}: IconButtonMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const isMobile = useIsMobileDevice();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box paddingRight={1}>
      {(buttons.length > buttonLimit || (showEllipsis && buttons.length > 0)) && (
        <>
          <IconButton
            data-id={`more-vertical-icons-${dataId}`}
            sx={{
              color: theme.palette.text.primary,
              borderRadius: '10px',
              bottom: 1.5,
              '&:hover': {
                borderRadius: '10px',
                backgroundColor: neutral[800],
                color: 'white',
              },
            }}
            aria-label="more"
            aria-controls="button-menu"
            aria-haspopup="true"
            onClick={(event) => {
              event.stopPropagation();
              handleClick(event);
            }}
          >
            <MoreVertIcon />
          </IconButton>
          {/* Render the menu items */}
          <Menu
            id="button-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {buttons.slice(slice).map((button, index) => (
              <MenuItem
                disabled={button.disabled}
                data-id={button.dataId}
                key={index}
                onClick={(event) => {
                  event.stopPropagation();
                  button.onClick?.('');
                  handleClose();
                }}
              >
                <IconButton
                  data-id={button.dataId}
                  size={'small'}
                  sx={{
                    color: theme.palette.text.primary,
                    borderRadius: '10px',
                    bottom: 1.5,
                    ml: -1,
                    '&:hover': {
                      borderRadius: '10px',
                      backgroundColor: 'transparent', // Set hover effect to none
                    },
                  }}
                >
                  {button.startIcon}
                </IconButton>
                <Typography variant={'body1'}>{button.label}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
      {buttons.slice(0, slice).map((button, index) => (
        <React.Fragment key={index}>
          {button.label ? (
            <Button
              data-id={button.dataId}
              disabled={button.disabled}
              sx={{
                height: '36px',
                '&:hover': {
                  backgroundColor: neutral[800], // Replace with your desired color
                },
              }}
              onClick={(event) => {
                event.stopPropagation();
                button.onClick?.(event);
              }}
              startIcon={button.startIcon}
              variant="text"
              fullWidth={isMobile}
              size="small"
            >
              <Typography variant={'body1'}>{button.label}</Typography>
            </Button>
          ) : (
            <IconButton
              data-id={button.dataId}
              disabled={button.disabled}
              onClick={(event) => {
                event.stopPropagation();
                button.onClick?.(event);
              }}
              sx={{
                color: theme.palette.text.primary,
                borderRadius: '10px',
                bottom: 1.5,
                ml: index === 1 ? -1 : 0,
                '&:hover': {
                  borderRadius: '10px',
                  backgroundColor: neutral[800], // Replace with your desired color
                  color: 'white',
                },
              }}
            >
              {button.startIcon}
            </IconButton>
          )}
        </React.Fragment>
      ))}
    </Box>
  );
}

export default IconButtonMenu;
