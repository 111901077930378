import { useMutation } from '@tanstack/react-query';
import { addUsersFromCsv } from '../../../api/users';

const useAddUserFromCsv = (options?: {}) => {
  return useMutation(addUsersFromCsv, {
    ...options,
  });
};

export default useAddUserFromCsv;
