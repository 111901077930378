import React, { useState } from 'react';
import { IChargeRentalModalProps } from '../../../ts/interface/pages/rentals.interface';
import {
  MovaticFieldGroupCheck,
  MovaticFieldGroupOutlinedInput,
  MovaticFieldGroupText,
} from '../../../components/Redesing/movatic-field-group';
import FormItem from '../../../components/Redesing/form-item';
import { SeverityPill } from '../../../components/Redesing/severity-pill';
import MovaticCustomModal from '../../../components/Modal/MovaticCustomModal';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import AddIcon from '@mui/icons-material/Add';
import CustomAsyncToast from '../../../components/Redesing/custom-async-toast';
import { chargeRental } from '../../../api/rentals';

const ChargeModal = ({
  showModal,
  onClose,
  userName,
  billing,
  rentalId,
}: IChargeRentalModalProps) => {
  const [billRegAmount, setBillRegAmount] = useState<boolean>(false);
  const [customAmount, setCustomAmount] = useState<number>(0);
  const [chargeError, setChargeError] = useState<string | null>(null);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [reason, setReason] = useState<string>('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const cents = value.split('.')[1] !== undefined ? value.split('.')[1] : '';
    if (value === '') return;
    if (cents.length > 2) return;
    if (value.charAt(value.length - 1) === '.' && value.split('.').length >= 2) return;
    setCustomAmount(Number(value));
    setChargeError(null);
  };

  const canSubmit = () => {
    let canReturn = true;
    if (!billing) {
      setChargeError('Billing is not enabled for this system');
    } else if (!billRegAmount && customAmount < 0) {
      canReturn = false;
      setChargeError('Please enter valid custom amount to charge');
    } else if (!billRegAmount && !reason) {
      setChargeError('Please enter the reason for charging a custom amount');
      canReturn = false;
    }
    return canReturn;
  };

  const chargeRentalCallback = () => {
    setDisabled(true);
    if (canSubmit()) {
      const rentalInfo: { custom_amount: number | null; reason: string } = {
        custom_amount: null,
        reason: reason,
      };
      if (!billRegAmount) {
        // if they entered a custom amount, send that amount into the back end in cents
        rentalInfo.custom_amount = Math.round(customAmount * 100);
      }
      CustomAsyncToast({
        promise: () => chargeRental({ rentalId: rentalId, rentalData: rentalInfo }),
        successMessage: () => 'Rental was charged successfully!',
        errorMessage: 'There was an error charging the rental. Please try again later.',
        loadingMessage: 'Charging rental...',
      }).then((r) => {
        setDisabled(false);
        if (r) {
          onClose();
        }
      });
    } else {
      setDisabled(false);
    }
  };

  return (
    <>
      <MovaticCustomModal
        open={showModal}
        onClose={onClose}
        content={
          <div>
            <MovaticFieldGroupCheck
              id="add_spending_controls"
              name={'add_spending_controls'}
              description="Charge regular rental amount based on the total rental time"
              label="Standard Charge"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setBillRegAmount(e.target.checked);
                setChargeError(null);
              }}
              checked={billRegAmount}
            />

            {chargeError !== null ? (
              <FormItem
                label={'Charge Error'}
                content={<SeverityPill color={'error'}>{chargeError}</SeverityPill>}
              />
            ) : null}
            {!billRegAmount ? (
              <div>
                <FormItem label={<SeverityPill color={'info'}>Or</SeverityPill>} />
                <MovaticFieldGroupOutlinedInput
                  type="number"
                  description={'Enter the amount you would like to charge.'}
                  name="customAmount"
                  addOn={'$'}
                  inputProps={{ min: 0, step: 0.25, max: 99 }}
                  label="Custom charge amount"
                  id="customAmount"
                  value={customAmount}
                  onChange={handleChange}
                />
                <MovaticFieldGroupText
                  id="reason"
                  name={'reason'}
                  label="Custom amount reason"
                  description="Enter the reason for charging a custom amount."
                  value={reason}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setReason(e.target.value);
                  }}
                />
              </div>
            ) : null}
          </div>
        }
        title={`Charge ${userName}'s Rental`}
        action={
          <IconButtonMenu
            buttons={[
              {
                label: 'Cancel',
                onClick: () => onClose(),
              },
              {
                label: 'Submit',
                onClick: () => chargeRentalCallback(),
                disabled: disabled,
                startIcon: <AddIcon />,
              },
            ]}
          />
        }
      />
    </>
  );
};

export default ChargeModal;
