import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Chip, Accordion, AccordionSummary, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface TagsCellProps {
  tags: string[];
}

const TagsCell: React.FC<TagsCellProps> = ({ tags }) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    const dummyChip = document.createElement('div');
    dummyChip.style.visibility = 'hidden';
    dummyChip.style.position = 'absolute';
    document.body.appendChild(dummyChip);

    return () => {
      document.body.removeChild(dummyChip);
    };
  }, []);

  const calculateSummaryTags = useMemo(() => {
    const summaryTags = [];
    let totalWidth = 0;
    const summaryWidth = 300;

    const dummyChip = document.createElement('div');
    dummyChip.style.visibility = 'hidden';
    dummyChip.style.position = 'absolute';
    document.body.appendChild(dummyChip);

    if (tags.length > 0) {
      summaryTags.push(tags[0]);
      dummyChip.textContent = tags[0];
      totalWidth = dummyChip.getBoundingClientRect().width;
    }

    summaryTags.push(
      ...tags.slice(1).reduce((acc: string[], tag) => {
        dummyChip.textContent = tag;
        const chipWidth = dummyChip.getBoundingClientRect().width;

        totalWidth += chipWidth;
        if (totalWidth <= summaryWidth) {
          acc.push(tag);
        }
        return acc;
      }, [])
    );

    document.body.removeChild(dummyChip);

    return summaryTags;
  }, [tags]);

  return (
    <Stack px={1}>
      <Accordion
        expanded={expanded}
        onChange={tags.length > calculateSummaryTags.length ? toggleExpansion : undefined}
      >
        <AccordionSummary
          expandIcon={
            tags.length > calculateSummaryTags.length ? <ExpandMoreIcon /> : null
          }
        >
          <div>
            {expanded ? (
              <Grid container spacing={0.5}>
                {tags.map((tag, index) => (
                  <Grid item key={index}>
                    <Chip
                      sx={{
                        height: 'auto',
                        py: 0.5,
                        '& .MuiChip-label': {
                          display: 'block',
                          whiteSpace: 'normal',
                        },
                      }}
                      label={tag}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <>
                <Grid container spacing={0.5}>
                  {calculateSummaryTags.map((tag, index) => (
                    <Grid item key={index}>
                      <Chip
                        sx={{
                          height: 'auto',
                          py: 0.5,
                          '& .MuiChip-label': {
                            display: 'block',
                            whiteSpace: 'normal',
                          },
                        }}
                        label={tag}
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </div>
        </AccordionSummary>
      </Accordion>
    </Stack>
  );
};

export default TagsCell;
