import React from 'react';
import { Badge, Tabs, Row, Col, Nav, Tab } from 'react-bootstrap';

export default function TabsComponent(props) {
  return (
    <span id="card-navigation" data-id={props.dataId}>
      {/* THIS IS WHEN WE ARE IN A SMALL SCREEN */}
      <Tab.Container activeKey={props.activeKey} id={props.id} onSelect={props.onSelect}>
        <Row className={`clearfix tabs ${props.loaded ? `` : `loadingBlur`}`}>
          <Col xs={12} className="tab-fix">
            <Nav
              variant="pills"
              id="smallPillNavs"
              activeKey={props.activeKey}
              onSelect={props.onSelect}
            >
              {props.tabs.filter(item => item?.shouldRender).map((eachTab) => {
                return (
                  <Nav.Link
                    key={eachTab.eventKey}
                    disabled={eachTab.disabled}
                    eventKey={eachTab.eventKey}
                  >
                    <span>
                      {eachTab.badge && (
                        <Badge className="navBadgeStyle">{eachTab.badge}</Badge>
                      )}{' '}
                      {eachTab.title}{' '}
                    </span>
                  </Nav.Link>
                );
              })}
            </Nav>
          </Col>
        </Row>
      </Tab.Container>
      {/* THIS IS WHEN WE ARE VIEWING ON A LARGE SCREEN */}
      <Tab.Container id={props.id} onSelect={props.onSelect}>
        <Row className={`clearfix tabs ${props.loaded ? '' : 'loadingBlur'}`}>
          <Col className="tab-fix">
            <Tabs
              variant="tabs"
              activeKey={props.activeKey}
              id={props.id}
              onSelect={props.onSelect}
            >
              {props.tabs.map((eachTab) => {
                if (eachTab != null) {
                  if (eachTab.shouldRender) {
                    return (
                      <Nav.Link
                        key={eachTab.eventKey}
                        eventKey={eachTab.eventKey}
                        title={
                          <span>
                            {eachTab.badge && <Badge>{eachTab.badge}</Badge>}{' '}
                            {eachTab.title}{' '}
                          </span>
                        }
                      />
                    );
                  }
                }
                return null;
              })}
            </Tabs>
          </Col>
        </Row>
      </Tab.Container>
    </span>
  );
}
