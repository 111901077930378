import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { Theme } from '@mui/material/styles/createTheme';
import { styled } from '@mui/material/styles';

import type { NavColor } from '../../ts/types/settings';

import type { Section } from '../config';
import { MobileNav } from '../mobile-nav';
import { TopNav } from './top-nav';
import { useMobileNav } from './use-mobile-nav';
import { MiniDrawer } from './side-nav';
import { Box } from '@mui/material';
import ErrorBoundary from '../../components/Redesing/error-boundary';
const VerticalLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  padding: 3,
}));

const VerticalLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%',
  pl: 1,
  pr: 1,
  pb: 1,
});

interface VerticalLayoutProps {
  children?: ReactNode;
  navColor?: NavColor;
  sections?: Section[];
}

export const VerticalLayout: FC<VerticalLayoutProps> = (props) => {
  const { children, sections, navColor } = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const mobileNav = useMobileNav();

  const desktopView = (
    <Box sx={{ display: 'flex' }}>
      <MiniDrawer color={navColor} sections={sections} />
      <VerticalLayoutRoot data-id="test">
        <VerticalLayoutContainer data-id="test5">
          <ErrorBoundary>{children}</ErrorBoundary>
        </VerticalLayoutContainer>
      </VerticalLayoutRoot>
    </Box>
  );

  const mobileView = (
    <>
      <TopNav onMobileNavOpen={mobileNav.handleOpen} />
      <MobileNav
        color={navColor}
        onClose={mobileNav.handleClose}
        open={mobileNav.open}
        sections={sections}
      />
      <VerticalLayoutRoot data-id="test">
        <VerticalLayoutContainer data-id="test5">{children}</VerticalLayoutContainer>
      </VerticalLayoutRoot>
    </>
  );

  return <>{lgUp ? desktopView : mobileView}</>;
};
VerticalLayout.propTypes = {
  children: PropTypes.node,
  navColor: PropTypes.oneOf<NavColor>(['blend-in', 'discrete', 'evident']),
  sections: PropTypes.array,
};
