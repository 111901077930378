import React, { useState } from 'react';
import useHardwareProduct from '../../hooks/hardwareProduct/query/useHardwareProduct';
import useHardwareProductVersion from '../../hooks/hardwareProduct/query/useHardwareProductVersion';
import { history, STATE_OPTIONS, stateFormatter } from '../../utils';
import useStation from '../../hooks/stations/query/useStation';
import { HARDWARE_NUMBER_LIMIT } from '../../constants';
import { connect } from 'react-redux';
import { IMode } from '../../ts/interface/mode.interface';
import CSVReader from 'react-csv-reader';
import { IHardware } from '../../ts/interface/hardware.interface';
import { IHardwareVersion } from '../../ts/interface/hardwareVersion.interface';
import { MovaticFieldGroupSelect } from '../../components/Redesing/movatic-field-group';
import MenuItem from '@mui/material/MenuItem';
import { IStation } from '../../ts/interface/station.interface';
import CardView from '../../components/Redesing/card-view';
import { Stack } from '@mui/system';
import PageContainer from '../../components/Redesing/page-container';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';
import { createHardwareFromCsv } from '../../api/hardware';
import '../../css/newOrganizedCss/csv-reader.scss';
import { LocationStatus } from '../../ts/enums';

const NewHardwareAddFromCsv = ({ mode }: { mode: IMode }) => {
  const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
  const [selectedProductVersion, setSelectedProductVersion] = useState<string | null>(
    null
  );
  const [state, setState] = useState<string | null>(null);
  const [station, setStation] = useState<null | string>(null);
  const [validationError, setValidationError] = useState<string | null>(null);
  const parserOptions = {
    header: true,
    skipEmptyLines: true,
    transformHeader: (header: string) => header.toLowerCase().replace(/\W/g, '_'),
  };
  const supportedColumns =
    'number, state, station, vendor_serial, vendor_id, mac, imei, label, vendor_qr_code';
  const stationTitle = mode.details.stationsTitle;
  const hardwareProductQuery = useHardwareProduct();
  const hardwareProducts = hardwareProductQuery.data?.data || [];
  const isHardwareProductSelected = !!selectedProduct && selectedProduct !== 'Select One';
  const isProductVersionSelected =
    !!selectedProductVersion && selectedProductVersion !== 'Select One';
  const hardwareProductVersionsQuery = useHardwareProductVersion(
    {
      hardware_product_id: hardwareProducts.find(
        (item: { data: { old_id: string } }) => item.data.old_id === selectedProduct
      )?.data?.id,
    },
    {
      enabled: isHardwareProductSelected,
    }
  );

  const { data } = useStation(
    {
      enabled: isProductVersionSelected,
    },
    { status: LocationStatus.active }
  );
  const stations = data?.data
    ? [
        { value: 'null', name: `No ${stationTitle}` },
        ...data?.data.map((item: IStation) => ({ name: item.name, value: item.id })),
      ]
    : [{ value: '', name: `No ${stationTitle}` }];

  const hardwareProductVersions = hardwareProductVersionsQuery.data?.data || [];

  const isDataInValid = (data: IHardware[], hardwareProduct: { has_imei: boolean }) => {
    const imeiLengthRegex = /^(?:\d{15}|\d{17})$/;
    return data.some((hardware, index) => {
      const { number = null, imei = null } = hardware;
      if (!number || number > HARDWARE_NUMBER_LIMIT) {
        setValidationError(
          `Error in row ${index + 1}: The number you entered is invalid`
        );

        return true;
      }

      if (hardwareProduct.has_imei && !imeiLengthRegex.test(imei || '')) {
        setValidationError(`Error in row ${index + 1}: The imei you entered is invalid`);
        return true;
      }
      return false;
    });
  };

  const handleForce = (data: IHardware[]) => {
    const formattedData = data.map((hardware: IHardware) => {
      //@ts-ignore
      const stateToAdd = state || stateFormatter(hardware.state).value || 0;
      const stationToAdd = station || hardware.station || null;

      return { ...hardware, stateToAdd, stationToAdd };
    });

    const hardwarePro = hardwareProducts.find(
      (item: { data: { old_id: string } }) => item.data.old_id === selectedProduct
    )?.data;

    const hardwareProductInfo = {
      hardwareProduct: hardwarePro,
      hardwareProductVersion: selectedProductVersion,
    };

    return (
      !isDataInValid(formattedData, hardwarePro) &&
      CustomAsyncToast({
        promise: () => createHardwareFromCsv(formattedData, hardwareProductInfo),
        loadingMessage: 'Creating Hardware...',
        successMessage: () => 'Hardware Created Successfully.',
        errorMessage: 'Error Creating Hardware.',
      }).then((r) => {
        if (r) {
          history.push(
            `/hardware/${hardwareProductInfo.hardwareProduct.hardware_type_name}`
          );
        }
      })
    );
  };
  const content = () => {
    return (
      <div>
        <MovaticFieldGroupSelect
          label="Hardware Product"
          description="The specific type of hardware you're adding"
          name={'product'}
          optionNodes={[
            { name: 'Select One', value: '' },
            ...hardwareProducts
              .filter(
                (item: { data: { old_id: string; name: string } }) =>
                  item.data.old_id !== '1' && item.data.name
              )
              .map((product: { data: { name: string; old_id: string } }) => ({
                name: product?.data?.name,
                value: product?.data?.old_id,
              })),
          ].map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
          value={selectedProduct}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSelectedProduct(e.target.value);
          }}
          id={'product'}
        />

        {isHardwareProductSelected && (
          <>
            <MovaticFieldGroupSelect
              label="Product Version"
              description="The version of the hardware product you're adding"
              name={'productVersion'}
              optionNodes={[
                ...hardwareProductVersions?.map((item: IHardwareVersion) => ({
                  name: item.name,
                  value: item.name,
                })),
              ].map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
              value={selectedProductVersion}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSelectedProductVersion(e.target.value);
              }}
              id={'productVersion'}
            />

            {isProductVersionSelected && (
              <div className="csv-input-container">
                <MovaticFieldGroupSelect
                  label="Override State"
                  description="If an option is selected, overrides the state of all hardware in the file"
                  name={'state'}
                  optionNodes={STATE_OPTIONS.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                  value={state}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setState(e.target.value);
                  }}
                  id="state"
                />

                <MovaticFieldGroupSelect
                  label={`Override ${stationTitle}`}
                  description={`If an option is selected, overrides the ${stationTitle} of all hardware in the file`}
                  name={'station'}
                  optionNodes={stations.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                  value={station}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setStation(e.target.value);
                  }}
                  id="station"
                />
                <Stack pt={2}>
                  <CSVReader
                    cssClass="react-csv-input"
                    label={`The file must be a CSV formatted file. The following columns are supported in the CSV: ${supportedColumns}.`}
                    onFileLoaded={handleForce}
                    parserOptions={parserOptions}
                    accept=".csv, text/csv"
                  />
                </Stack>
              </div>
            )}
          </>
        )}
        {!!validationError && <div className="csv-row-error">{validationError}</div>}
      </div>
    );
  };
  return (
    <PageContainer isLoading={false}>
      <CardView
        content={
          <Stack px={2} pb={2}>
            {content()}
          </Stack>
        }
        title={'Add Hardware from CSV'}
      />
    </PageContainer>
  );
};

export default connect(
  (state: { mode: IMode }) => ({
    mode: state.mode,
  }),
  () => ({})
)(NewHardwareAddFromCsv);
