import { useQuery } from '@tanstack/react-query';
import { getRentals } from '../../../api/rentals';
import { IUseRental } from '../../../ts/interface/rental.interface';

const useRental = (parameters?: IUseRental, options?: {}) => {
  const { systemId, queryParameters } = parameters || {};
  return useQuery(
    ['rentals', systemId, queryParameters],
    () => getRentals(queryParameters),
    {
      ...options,
    }
  );
};

export default useRental;
