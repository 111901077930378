import React, { useMemo } from 'react';
import CardView from '../../components/Redesing/card-view';
import { ChromePicker } from 'react-color';
import { Stack } from '@mui/system';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';

interface IBrandingColorProps {
  colorPrimary: string;
  colorSecondary: string;
  handleColorChange: (colorEvent: any, color: string) => void;
  actions: React.ReactNode;
  isChanged: boolean;
}

const BrandingColor: React.FC<IBrandingColorProps> = ({
  colorPrimary,
  colorSecondary,
  handleColorChange,
  actions,
}) => {
  const {
    palette: { mode: themeMode },
  } = useTheme();
  const isDarkMode = useMemo(() => themeMode === 'dark', [themeMode]);

  return (
    <CardView
      headerActions={actions}
      content={
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          pb={2}
          px={2}
        >
          <Stack direction={'column'} spacing={1}>
            <Typography variant={'subtitle2'}>Primary Color</Typography>
            <div className={isDarkMode ? 'dark-mode-chrome-picker' : 'none'}>
              <ChromePicker
                color={colorPrimary}
                onChangeComplete={(colorEvent) =>
                  handleColorChange(colorEvent, 'colorPrimary')
                }
              />
            </div>
          </Stack>
          <Stack direction={'column'}>
            <Typography variant={'subtitle2'}>Secondary Color</Typography>
            <div className={isDarkMode ? 'dark-mode-chrome-picker' : 'none'}>
              <ChromePicker
                color={colorSecondary}
                onChangeComplete={(colorEvent) =>
                  handleColorChange(colorEvent, 'colorSecondary')
                }
              />
            </div>
          </Stack>
        </Stack>
      }
      title={'Branding Color'}
    />
  );
};

export default BrandingColor;
