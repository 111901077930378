import React, { useMemo, useState } from 'react';
import { history } from '../../utils';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { createSystemPublic } from '../../api/system';
import {
  BEACH_CHAIR,
  BIKE,
  KAYAK,
  LOCKER,
  RACK,
  SCOOTER,
  SYSTEM_BUSINESS_DETAIL,
  SYSTEM_USE_CASE,
} from '../../constants';
import { VALID_EMAIL_REGEX } from '../../util/regExp';
import ReCaptchaWrapper from '../../components/recaptcha/ReCaptcha';
import useAnalyticsEventTracker from '../../hooks/analytics/useAnalyticsEventTracker';
import userLogin from '../../hooks/users/login/userLogin';
import useHardwareTypes from '../../hooks/hardware/query/useHardwareTypes';
import CardView from '../../components/Redesing/card-view';
import { Stack } from '@mui/system';
import {
  MovaticFieldGroupSelect,
  MovaticFieldGroupTelephone,
  MovaticFieldGroupText,
} from '../../components/Redesing/movatic-field-group';
import MenuItem from '@mui/material/MenuItem';
import CustomToast from '../../components/Redesing/custom-toast';
import BigButton from '../../components/Redesing/big-button';

const countries = require('../../../data/countries.json');

const AddPublicContent = () => {
  const reportEvent = useAnalyticsEventTracker();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const loginUser = userLogin();
  const initialState = {
    name: '',
    country: null,
    captcha: null,
    phoneNumber: null,
    selectedMobilityType: '',
    adminUserEmail: '',
    adminUserFirstName: '',
    adminUserLastName: '',
    mobilityTypes: [],
    source: 'Movatic Sales Site',
    countryHelp: '',
    selectedMobilityTypeHelp: '',
    adminUserFirstNameHelp: '',
    adminUserLastNameHelp: '',
    adminUserEmailHelp: '',
    phoneNumberHelp: '',
    captchaHelp: '',
    systemUseCase: '',
    systemUseCaseHelp: '',
    systemBusinessDetail: '',
    systemBusinessDetailHelp: '',
    nameHelp: '',
  };

  const [
    {
      name,
      country,
      phoneNumber,
      selectedMobilityType,
      adminUserEmail,
      adminUserFirstName,
      adminUserLastName,
      source,
      countryHelp,
      selectedMobilityTypeHelp,
      adminUserFirstNameHelp,
      adminUserLastNameHelp,
      adminUserEmailHelp,
      phoneNumberHelp,
      systemUseCase,
      systemUseCaseHelp,
      systemBusinessDetail,
      systemBusinessDetailHelp,
      nameHelp,
    },
    setState,
  ] = useState(initialState);

  const handleChange = (event) => {
    const { value, name } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
      [`${name}Help`]: '',
    }));
  };

  const phoneNumberChange = (phoneNumber) => {
    setState((prevState) => ({
      ...prevState,
      phoneNumber,
      phoneNumberHelp: '',
    }));
  };

  const { data: dataHardwareTypes, isLoading: isLoadingTypes } = useHardwareTypes();

  const mapMobilityTypeOptions = useMemo(() => {
    if (!dataHardwareTypes) return [];

    return [
      {
        value: dataHardwareTypes[BIKE].gid,
        name: 'Bike Share',
        old_id: dataHardwareTypes[BIKE].old_id,
      },
      {
        value: dataHardwareTypes[RACK].gid,
        name: 'Bike Room',
        old_id: dataHardwareTypes[RACK].old_id,
      },
      {
        value: dataHardwareTypes[LOCKER].gid,
        name: 'Bike Locker',
        old_id: dataHardwareTypes[LOCKER].old_id,
      },
      {
        value: dataHardwareTypes[SCOOTER].gid,
        name: 'Scooter',
        old_id: dataHardwareTypes[SCOOTER].old_id,
      },
      {
        value: dataHardwareTypes[KAYAK].gid,
        name: 'Kayak',
        old_id: dataHardwareTypes[KAYAK].old_id,
      },
      {
        value: dataHardwareTypes[BEACH_CHAIR].gid,
        name: 'Beach Chairs',
        old_id: dataHardwareTypes[BEACH_CHAIR].old_id,
      },
    ];
  }, [dataHardwareTypes]);

  const canSubmit = () => {
    let canSub = true;
    if (!name) {
      setState((prevState) => ({
        ...prevState,
        nameHelp: 'System name is required.',
      }));
      canSub = false;
    }
    if (!systemUseCase) {
      setState((prevState) => ({
        ...prevState,
        systemUseCaseHelp: 'Use case is required.',
      }));
      canSub = false;
    }

    if (!systemBusinessDetail) {
      setState((prevState) => ({
        ...prevState,
        systemBusinessDetailHelp: 'Business detail is required.',
      }));
      canSub = false;
    }

    if (!country) {
      setState((prevState) => ({
        ...prevState,
        countryHelp: 'Country is required.',
      }));
      canSub = false;
    }

    if (!selectedMobilityType) {
      setState((prevState) => ({
        ...prevState,
        selectedMobilityTypeHelp: 'Mobility type is required.',
      }));
      canSub = false;
    }

    if (!adminUserFirstName) {
      setState((prevState) => ({
        ...prevState,
        adminUserFirstNameHelp: 'First name is required.',
      }));
      canSub = false;
    }

    if (!adminUserLastName) {
      setState((prevState) => ({
        ...prevState,
        adminUserLastNameHelp: 'Last name is required.',
      }));
      canSub = false;
    }

    if (!phoneNumber) {
      setState((prevState) => ({
        ...prevState,
        phoneNumberHelp: 'Phone number is required.',
      }));
      canSub = false;
    }

    if (!adminUserEmail || !VALID_EMAIL_REGEX.test(adminUserEmail)) {
      setState((prevState) => ({
        ...prevState,
        adminUserEmailHelp: 'Please enter a valid email address.',
      }));
      canSub = false;
    }

    return canSub;
  };

  const handleSubmit = () => {
    if (canSubmit()) {
      executeRecaptcha('createSystem').then((token) => {
        const systemObj = {
          name: name.trim(),
          useCase: systemUseCase,
          businessDetail: systemBusinessDetail,
          recaptchaToken: token,
          country,
          rentable_hardware_type_id: selectedMobilityType,
          phone: phoneNumber,
          source,
          adminUser: {
            email: adminUserEmail.toLowerCase().trim(),
            firstName: adminUserFirstName,
            lastName: adminUserLastName,
          },
        };
        createSystemPublic(systemObj).then((r) => {
          if (r) {
            reportEvent('system_created', {
              system_name: name.trim(),
              system_country: country,
              admin_phone: phoneNumber,
              source: source,
              admin_email: adminUserEmail.toLowerCase().trim(),
              admin_first_name: adminUserFirstName,
              admin_last_name: adminUserLastName,
            });
            if (r === 'System created') {
              CustomToast({
                message:
                  'System successfully created!. Please check your provided email to set your password.',
                type: 'success',
              });
            } else {
              loginUser(r);
              CustomToast({
                message:
                  'System successfully created!. Please check your provided email to set your password.',
                type: 'success',
              });
            }
            history.push('/dashboard');
          }
        });
      });
    }
  };

  const content = () => {
    return (
      <Stack px={2} pb={2} sx={{ minWidth: '50vw' }}>
        {/* SYSTEM NAME */}
        <MovaticFieldGroupText
          id="name"
          name="name"
          label="System Name"
          description="Required"
          hasError={!!nameHelp}
          errorMessage={nameHelp}
          value={name}
          onChange={handleChange}
        />

        <MovaticFieldGroupSelect
          label="Use Case"
          description="Required."
          name={'systemUseCase'}
          placeholder={'Select'}
          optionNodes={[...SYSTEM_USE_CASE].map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
          value={systemUseCase || ''}
          onChange={(e) => {
            setState((prevState) => ({
              ...prevState,
              systemUseCaseHelp: '',
              systemUseCase: e.target.value,
            }));
          }}
          hasError={!!systemUseCaseHelp}
          errorMessage={systemUseCaseHelp}
          id={'systemUseCase'}
        />

        <MovaticFieldGroupSelect
          label="Business Details"
          description="Required."
          name={'systemBusinessDetail'}
          placeholder={'Select'}
          optionNodes={[...SYSTEM_BUSINESS_DETAIL].map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
          value={systemBusinessDetail || ''}
          onChange={(e) => {
            setState((prevState) => ({
              ...prevState,
              systemBusinessDetail: e.target.value,
              systemBusinessDetailHelp: '',
            }));
          }}
          hasError={!!systemBusinessDetailHelp}
          errorMessage={systemBusinessDetailHelp}
          id={'systemBusinessDetail'}
        />

        {/* COUNTRY */}
        <MovaticFieldGroupSelect
          label="Country"
          description={"Required. Don't see your country? Contact support@movatic.co"}
          name={'country'}
          optionNodes={countries.map((country) => (
            <MenuItem key={country['alpha-2']} value={country['alpha-2']}>
              {country.label}
            </MenuItem>
          ))}
          value={country || ''}
          onChange={(e) => {
            setState((prevState) => ({
              ...prevState,
              country: e.target.value,
              countryHelp: '',
            }));
          }}
          hasError={!!countryHelp}
          errorMessage={countryHelp}
          id={'country'}
        />

        <MovaticFieldGroupSelect
          label="Mobility Type"
          description="Required"
          name={'selectedMobilityType'}
          optionNodes={mapMobilityTypeOptions.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
          value={selectedMobilityType || ''}
          onChange={(e) => {
            setState((prevState) => ({
              ...prevState,
              selectedMobilityTypeHelp: '',
              selectedMobilityType: e.target.value,
            }));
          }}
          hasError={!!selectedMobilityTypeHelp}
          errorMessage={selectedMobilityTypeHelp}
          id={'selectedMobilityType'}
        />

        {/* ADMIN FIRST NAME */}
        <MovaticFieldGroupText
          id="adminFirstName"
          name="adminFirstName"
          label="Admin User First Name"
          description="First Name of the first user of the system."
          hasError={!!adminUserFirstNameHelp}
          errorMessage={adminUserFirstNameHelp}
          value={adminUserFirstName}
          onChange={(event) => {
            setState((prevState) => ({
              ...prevState,
              adminUserFirstNameHelp: '',
              adminUserFirstName: event.target.value,
            }));
          }}
        />

        {/* ADMIN LAST NAME */}
        <MovaticFieldGroupText
          id="adminUserLastName"
          name="adminUserLastName"
          label="Admin User Last Name"
          description="Last Name of the first user of the system."
          hasError={!!adminUserLastNameHelp}
          errorMessage={adminUserLastNameHelp}
          value={adminUserLastName}
          onChange={(event) => {
            setState((prevState) => ({
              ...prevState,
              adminUserLastNameHelp: '',
              adminUserLastName: event.target.value,
            }));
          }}
        />

        {/* Phone Number */}
        <MovaticFieldGroupTelephone
          id="phoneNumber"
          label="Phone Number"
          errorMessage={phoneNumberHelp}
          hasError={!!phoneNumberHelp}
          description="Required."
          international
          withCountryCallingCode
          name="phoneNumber"
          onChange={phoneNumberChange}
          value={phoneNumber}
          helpDataId="phone-help"
        />

        {/* ADMIN EMAIL */}
        <MovaticFieldGroupText
          id="adminUserEmail"
          name="adminUserEmail"
          label="Admin User Email"
          description="Email of the first user of the system."
          hasError={!!adminUserEmailHelp}
          errorMessage={adminUserEmailHelp}
          value={adminUserEmail}
          onChange={(event) => {
            setState((prevState) => ({
              ...prevState,
              adminUserEmailHelp: '',
              adminUserEmail: event.target.value,
            }));
          }}
        />
        <BigButton description="Create Your System" onClick={handleSubmit} />
      </Stack>
    );
  };

  return (
    <div className="add_system_card">
      <CardView
        isLoading={isLoadingTypes}
        content={content()}
        title={'Create your system'}
      />
    </div>
  );
};

const addPublic = () => {
  return (
    <ReCaptchaWrapper>
      <AddPublicContent />
    </ReCaptchaWrapper>
  );
};

export default addPublic;
