import React, { useMemo } from 'react';
import { ISystem, IUseStripeAccountInfo } from '../../ts/interface/system.interface';
import { connect } from 'react-redux';
import { findAccess, getLocaleDateString, history } from '../../utils';
import { userPermissions } from '../../util/userPermissions';
import MovaticCustomModal from '../Modal/MovaticCustomModal';
import { getStripeOnboardingLink } from '../../api/system';
import IconButtonMenu from './icon-button-menu';
import EditIcon from '@mui/icons-material/Edit';
import CheckBox from '../CheckBox/CheckBox';
import Stack from '@mui/material/Stack';
import { useCookies } from 'react-cookie';
import { StripeRequirementType } from '../../ts/enums';

interface IStripeBannerProps {
  stripeAccountInfo?: IUseStripeAccountInfo;
  system: ISystem;
  systemAccess: number;
  keySend: string;
}

const StripeBanner: React.FC<IStripeBannerProps> = ({
  system,
  systemAccess,
  keySend,
  stripeAccountInfo,
}) => {
  const [open, setOpen] = React.useState(true);
  const [checked, setChecked] = React.useState(false);
  const [cookies, setCookie] = useCookies([]);
  const [cookieTimestamp, setCookieTimestamp] = React.useState<string | null>(null);

  const hasBillingAccess = useMemo(() => {
    return findAccess(systemAccess).includes(userPermissions.BANK);
  }, [systemAccess]);

  const arrayContains = (array: string[] | undefined, value: string): boolean => {
    return array?.includes(value) ?? false;
  };

  const externalAccount = useMemo(() => {
    // @ts-ignore
    const stripeRequirements = stripeAccountInfo?.[keySend];

    if (!stripeRequirements) {
      return false;
    }

    const { currently_due, eventually_due, past_due } = stripeRequirements;

    return (
      arrayContains(currently_due, 'external_account') ||
      arrayContains(eventually_due, 'external_account') ||
      arrayContains(past_due, 'external_account')
    );
  }, [stripeAccountInfo, keySend]);

  const modalMessage = useMemo(() => {
    if (!stripeAccountInfo) return null;
    // @ts-ignore
    const stripeRequirements = stripeAccountInfo?.[keySend];
    if (!stripeRequirements) return null;
    const { currently_due, eventually_due, past_due, current_deadline } =
      stripeRequirements;

    const link = (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        style={{
          color: '#007bff',
          textDecoration: '',
          cursor: 'pointer',
        }}
        onClick={() => history.push('/settings/billing')}
      >
        Settings {'>'} Billing
      </a>
    );

    if (externalAccount) {
      return (
        <>
          {hasBillingAccess ? (
            <p>
              Bank account information is missing for this system, which is required in
              order to be able to complete your Stripe setup and receive payouts. Please
              provide this information by going to {link}.
            </p>
          ) : (
            <p>
              Bank account information is missing, which is required in order to be able
              to complete your Stripe setup and receive payouts. Please contact an admin
              with Billing permissions in this system to update the Stripe information.
            </p>
          )}
        </>
      );
    }

    if (
      (currently_due?.length > 0 || eventually_due?.length > 0 || past_due?.length > 0) &&
      !cookies[
        `stripe_${keySend}_${current_deadline + system.id}` as keyof typeof cookies
      ]
    ) {
      const currentDueDate = current_deadline
        ? getLocaleDateString(new Date(current_deadline * 1000).toISOString())
        : null;
      setCookieTimestamp(current_deadline);
      return (
        <>
          {hasBillingAccess ? (
            <>
              {currentDueDate ? (
                <p>
                  Stripe has additional information that will be required by{' '}
                  <b>{currentDueDate}</b> and may impact your ability to process and
                  receive payments. Please update your Stripe connected account
                  information by clicking the button below or by going to {link}.
                </p>
              ) : (
                <p>
                  Stripe requires additional information that may impact your ability to
                  process and receive payments. Please update your Stripe connected
                  account information by clicking the button below or by going to {link}.
                </p>
              )}
            </>
          ) : (
            <>
              {currentDueDate ? (
                <p>
                  Stripe has additional information that will be required by{' '}
                  <b>{currentDueDate}</b> and may impact your ability to process and
                  receive payments. Please contact an admin with Billing permissions in
                  this system to update the Stripe information.
                </p>
              ) : (
                <p>
                  Stripe has additional information that may impact your ability to
                  process and receive payments. Please contact an admin with Billing
                  permissions in this system to update the Stripe information.
                </p>
              )}
            </>
          )}
        </>
      );
    } else {
      setOpen(false);
    }
  }, [hasBillingAccess, stripeAccountInfo, cookies, system.id, keySend, externalAccount]);

  const updateRequiredText = useMemo(() => {
    if (externalAccount) {
      return 'Bank Account Information Needed';
    }
    return keySend === StripeRequirementType.now
      ? 'Stripe Account Update Required'
      : keySend === StripeRequirementType.future
      ? 'Stripe Account Update Required Soon'
      : 'Stripe Account Update Required';
  }, [keySend, externalAccount]);

  return (
    <MovaticCustomModal
      open={open}
      customStyles={{
        content: {
          minWidth: 'none',
        },
      }}
      onClose={() => {
        if (checked) {
          setCookie(
            `stripe_${keySend}_${cookieTimestamp + system.id}` as keyof typeof cookies,
            cookieTimestamp,
            { path: '/' }
          );
        }
        setOpen(!open);
      }}
      content={modalMessage}
      title={updateRequiredText}
      action={
        <Stack
          alignItems={'center'}
          direction={'row'}
          alignContent={'center'}
          justifyContent={'center'}
          pb={-2}
        >
          {!externalAccount && (
            <IconButtonMenu
              buttons={[
                {
                  label: 'Update Stripe Account',
                  onClick: () => {
                    getStripeOnboardingLink('update').then((res) => {
                      if (res) {
                        window.open(res.url, '_blank');
                      }
                    });
                  },
                  startIcon: <EditIcon />,
                },
              ]}
            />
          )}
          <CheckBox
            checkBoxId={'hide-stripe-banner'}
            checked={checked}
            checkBoxLabel="Don't show this again"
            onChange={() => {
              setChecked(!checked);
            }}
          />
        </Stack>
      }
    />
  );
};

const StripeBannerLayout = connect(
  (state: { admin: { systemAccess: number } }) => ({
    systemAccess: state.admin.systemAccess,
  }),
  () => ({})
)(StripeBanner);

export default StripeBannerLayout;
