import React, { useEffect, useState } from 'react';
import MovaticCustomModal from '../../../components/Modal/MovaticCustomModal';
import { IEvent } from '../../../ts/interface/event.interface';
import useMemberships from '../../../hooks/membership/query/useMemberships';
import { getFormattedMembershipPrice } from '../../../utils';
import { UserInterface } from '../../../ts/interface/user.interface';
import { IMembership } from '../../../ts/interface/membership.interface';
import {
  MovaticFieldGroupOutlinedInput,
  MovaticFieldGroupSelect,
  MovaticFieldGroupText,
} from '../../../components/Redesing/movatic-field-group';
import MenuItem from '@mui/material/MenuItem';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import AddIcon from '@mui/icons-material/Add';
import CustomAsyncToast from '../../../components/Redesing/custom-async-toast';
import { addUserMembership } from '../../../api/memberships';
import { useNavigationContext } from '../../../context/navigation-context';

const AddUserMembership = ({
  showModal,
  close,
  user,
}: {
  showModal: boolean;
  close: Function;
  user: UserInterface;
}) => {
  const [selectedMembership, setSelectedMembership] = useState<IMembership | null>(null);
  const [activeMemberships, setActiveMemberships] = useState<IMembership[]>([]);
  const [membershipOptions, setMembershipOptions] = useState<
    { name: string; value: string }[]
  >([]);
  const [selectedPriceId, setSelectedPriceId] = useState('');
  const [freeRenewals, setFreeRenewals] = useState(0);
  const [membershipValidationResult, setMembershipValidationResult] = useState<
    string | null
  >(null);
  const [membershipValidationHelp, setMembershipValidationHelp] = useState('');
  const [emailValidationHelp, setEmailValidationHelp] = useState('');
  const [emailValidationResult, setEmailValidationResult] = useState<string | null>(null);
  const [freeRenewalsValidationResult, setFreeRenewalsValidationResult] = useState<
    string | null
  >(null);
  const [freeRenewalsValidationHelp, setFreeRenewalsValidationHelp] = useState('');
  const [userValidEmail, setUserValidEmail] = useState('');
  const [membershipPeriods, setMembershipPeriods] = useState<any>([]);
  const [selectedPrice, setSelectedPrice] = useState<any>(null);
  const [authType, setAuthType] = useState('');
  const { manualUpdate } = useNavigationContext();

  useEffect(() => {
    if (selectedMembership?.prices?.length) {
      const membershipPeriodsArray = selectedMembership.prices.map(
        (pricing: { id: string }) => {
          const formattedMembershipPrice = getFormattedMembershipPrice(pricing);

          return {
            value: pricing.id,
            name: formattedMembershipPrice,
          };
        }
      );
      setMembershipPeriods(membershipPeriodsArray);
    }

    const authType = selectedMembership?.auth_type || '';
    setAuthType(authType);

    const selectedPriceObj = selectedPriceId
      ? selectedMembership?.prices.find(
          (price: { id: string }) => price.id === selectedPriceId
        )
      : null;
    setSelectedPrice(selectedPriceObj);
  }, [selectedMembership, selectedPriceId]);

  useMemberships(
    { status: 'active' },
    {
      onSuccess: (r: any) => {
        setActiveMemberships(r?.data);
      },
    }
  );

  useEffect(() => {
    const membershipOptionsArray = activeMemberships.map((membership: IMembership) => {
      return {
        name: membership.name,
        value: membership.id,
      };
    });
    membershipOptionsArray.unshift({ name: 'Select a membership', value: '' });
    setMembershipOptions(membershipOptionsArray);
  }, [activeMemberships]);

  const onMembershipChange = (event: IEvent) => {
    const { value } = event.target;
    const selectedMembershipAux = activeMemberships.find(
      (membership: any) => membership.id === value
    );
    const selectedPriceId = selectedMembershipAux?.prices?.length
      ? selectedMembershipAux.prices[0].id
      : '';

    if (
      selectedMembershipAux?.auth_type === 'email' &&
      selectedMembershipAux.valid_emails?.length
    ) {
      const validEmails = selectedMembershipAux.valid_emails;
      const userEmail = user?.userDetails?.emailAddress || '';
      const userEmailIsValid = validEmails.some((domain: string) =>
        userEmail.includes(domain)
      );
      setEmailValidationResult('error');
      setEmailValidationHelp(
        `Please enter an email with one of these domains: ${validEmails.join(', ')}`
      );
      setUserValidEmail(userEmailIsValid ? userEmail : '');
    }

    setSelectedMembership(selectedMembershipAux || null);
    setSelectedPriceId(selectedPriceId);
    setMembershipValidationResult(null);
    setMembershipValidationHelp('');
    setFreeRenewalsValidationResult(null);
    setFreeRenewalsValidationHelp('');
  };

  const validateAllFields = () => {
    if (!selectedMembership) {
      setMembershipValidationResult('error');
      setMembershipValidationHelp('Please select a membership');
      return false;
    }

    const validEmails = selectedMembership.valid_emails || [];
    const userEmailIsValid = validEmails.some((domain: string) =>
      userValidEmail.includes(domain)
    );

    if (
      selectedMembership.auth_type === 'email' &&
      (!userValidEmail || !userEmailIsValid)
    ) {
      setEmailValidationResult('error');
      setEmailValidationHelp(
        `Please enter an email with one of these domains: ${validEmails.join(', ')}`
      );
      return false;
    }

    if (
      user?.memberships?.some(
        (membership: IMembership) =>
          membership.membership_id === selectedMembership.id && !membership.canceled_on
      )
    ) {
      setMembershipValidationResult('error');
      setMembershipValidationHelp('User already has this membership');
      return false;
    }

    if (freeRenewals < 0) {
      setFreeRenewalsValidationResult('error');
      setFreeRenewalsValidationHelp('Free renewals must be greater than 0');
    }
    return true;
  };

  const submit = () => {
    if (validateAllFields()) {
      const membershipInfo = {
        membership_id: selectedMembership?.id,
        membership_price_id: selectedPriceId || null,
        email: userValidEmail || null,
        user_id: user?.userDetails?.id,
        full_name: user?.userDetails?.fullName,
        free_renewals: freeRenewals ? freeRenewals : 0,
        password: selectedMembership?.password || null,
      };
      CustomAsyncToast({
        promise: () => addUserMembership(membershipInfo),
        successMessage: () => 'Membership added successfully',
        errorMessage: 'Error adding membership',
        loadingMessage: 'Adding membership...',
      }).then((r) => {
        if (selectedMembership?.auth_type === 'admin') {
          manualUpdate();
        }
        if (r) {
          close();
        }
      });
    }
  };

  return (
    <MovaticCustomModal
      open={showModal}
      onClose={close}
      title={'Add User Membership'}
      content={
        <div>
          <MovaticFieldGroupSelect
            label="Membership"
            description="Select a membership that will be added to the user"
            name="membershipName"
            optionNodes={membershipOptions.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
            value={selectedMembership?.id || ''}
            onChange={onMembershipChange}
            hasError={membershipValidationResult === 'error'}
            errorMessage={membershipValidationHelp}
            id={'membershipName'}
          />

          {membershipPeriods.length ? (
            <MovaticFieldGroupSelect
              label="Membership Period"
              description="Select the membership period"
              optionNodes={membershipPeriods.map(
                (option: { value: string; name: string }, index: number) => (
                  <MenuItem key={index} value={option.value}>
                    {option.name}
                  </MenuItem>
                )
              )}
              value={selectedPriceId}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = event.target;
                setSelectedPriceId(value);
                setFreeRenewals(0);
                setFreeRenewalsValidationResult(null);
                setFreeRenewalsValidationHelp('');
              }}
              hasError={membershipValidationResult === 'error'}
              errorMessage={membershipValidationHelp}
              id="membershipPeriod"
              name="membershipPeriod"
            />
          ) : null}

          {selectedPrice?.interval ? (
            <MovaticFieldGroupOutlinedInput
              type={'number'}
              label="Free Renewals"
              description="Amount of times the membership will be renewed for free (first charge counts as 1 renewal)."
              value={freeRenewals}
              inputProps={{ step: 1, min: 0 }}
              hasError={freeRenewalsValidationResult === 'error'}
              errorMessage={freeRenewalsValidationHelp}
              onChange={(event) => {
                const { value } = event.target;
                setFreeRenewals(parseInt(value));
                setFreeRenewalsValidationResult(null);
                setFreeRenewalsValidationHelp('');
              }}
              id="freeRenewals"
              name="freeRenewals"
              addOn={'#'}
            />
          ) : null}

          {authType === 'email' ? (
            <MovaticFieldGroupText
              id="userEmail"
              label="User Email"
              description="Enter the email of the user"
              name="userEmail"
              hasError={emailValidationResult === 'error'}
              errorMessage={emailValidationHelp}
              value={userValidEmail}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setUserValidEmail(e.target.value);
                setEmailValidationResult(null);
              }}
            />
          ) : null}
        </div>
      }
      action={
        <IconButtonMenu
          buttons={[
            {
              label: 'Cancel',
              onClick: () => close(),
            },
            {
              label: 'Submit',
              onClick: () => submit(),
              startIcon: <AddIcon />,
            },
          ]}
        />
      }
    />
  );
};
export default AddUserMembership;
