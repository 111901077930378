import { Editor } from '@tinymce/tinymce-react';
import { useMutation, useQuery } from '@tanstack/react-query';

import Button from '../../components/Button';
import { acceptSystemTos } from '../../api/users';
import { getSystemTosPublic } from '../../api/system';

const TOSAcceptance = ({ systemId, userId }: { systemId: string; userId: string }) => {
  const tosQuery = useQuery({
    queryKey: ['tos', systemId],
    queryFn: () => getSystemTosPublic(systemId),
  });

  const acceptTosMutation = useMutation({
    mutationFn: acceptSystemTos,
  });

  if (tosQuery.isLoading) {
    return (
      <div>
        <div
          style={{ paddingBottom: '20px' }}
          className="headerStyle heavyFont fullWidth graphRowTitle "
        >
          Terms of Service
        </div>
        <p style={{ backgroundColor: 'white', padding: '20px', width: '100%' }}>
          Loading...
        </p>
      </div>
    );
  }

  if (acceptTosMutation.isSuccess) {
    return (
      <div>
        <div
          style={{ paddingBottom: '20px' }}
          className="headerStyle heavyFont fullWidth graphRowTitle "
        >
          Terms of Service Accepted!
        </div>
        <p style={{ backgroundColor: 'white', padding: '20px', width: '100%' }}>
          You have accepted the Terms of Service for this system. You can now close this
          window.
        </p>
      </div>
    );
  }

  if (acceptTosMutation.isError || tosQuery.isError) {
    return (
      <div>
        <div
          style={{ paddingBottom: '20px' }}
          className="headerStyle heavyFont fullWidth graphRowTitle "
        >
          Terms of Service Error
        </div>
        <p style={{ backgroundColor: 'white', padding: '20px', width: '100%' }}>
          There was an error accepting the Terms of Service for this system. Please try
          again or contact support@movatic.co if the problem persists.
        </p>
      </div>
    );
  }

  return (
    <>
      <div
        style={{ paddingBottom: '40px' }}
        className="headerStyle heavyFont fullWidth graphRowTitle "
      >
        Terms of Service
      </div>
      <div>
        <Editor
          tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`}
          initialValue={tosQuery.data}
          init={{
            height: '80vh',
            menubar: false,
            plugins: [],
            toolbar: false,
            content_style:
              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          }}
        />
      </div>
      <div
        style={{
          width: '100%',
          padding: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          buttons={1}
          primaryText={'I Agree'}
          primaryName={'sendTosEmail'}
          primaryOnClick={() => acceptTosMutation.mutate({ systemId, userId })}
          primaryLoaded={!acceptTosMutation.isLoading}
          primaryDisabled={acceptTosMutation.isLoading}
        />
      </div>
    </>
  );
};

export default TOSAcceptance;
