import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { getDateAtLastSecond, getDateWithoutTime } from '../../../../helpers/dateHelpers';

import useRental from '../../../../hooks/rentals/query/useRental';
import useSearchDebounce from '../../../../hooks/search/useSearchDebounce';
import useExportRentals, {
  useExportSystemRentalsRoutes,
} from '../../../../hooks/rentals/query/useExportRentals';
import { IMode } from '../../../../ts/interface/mode.interface';
import { ISystem } from '../../../../ts/interface/system.interface';
import {
  IRental,
  IUseRentalQueryParameters,
} from '../../../../ts/interface/rental.interface';
import ShareIcon from '@mui/icons-material/Share';
import { history } from '../../../../utils';
import { PaginationTable } from '../pagination-table';
import {
  IColumn,
  ISortConfig,
} from '../../../../ts/interface/components/redesing/table.interface';
import Card from '@mui/material/Card';
import { SearchChip } from '../../../../ts/interface/components/table.interface';
import { useUpdateEffect } from '../../../../hooks/use-update-effect';
import AddIcon from '@mui/icons-material/Add';
import {
  MovaticFieldGroupDate,
  MovaticFieldGroupMultiSelect,
} from '../../movatic-field-group';
import CustomAsyncToast from '../../custom-async-toast';
import useQueryString from '../../../../hooks/queryParams/useQueryString';
import { DEFAULT_ROWS_PER_PAGE } from '../../../../constants';
import useUrlState from '../../../../hooks/use-url-state';
import { makeColumns } from '../../../../tableColumns/rental-columns';

const filterOptions = [
  { value: 'active', label: 'Active' },
  { value: 'completed', label: 'Completed' },
];

const RentalTable = ({
  disableFetch,
  parameters,
  modeInfo,
  onAddRental,
}: {
  systemLoaded: boolean;
  tabs?: JSX.Element;
  csvExportFileName?: string;
  noRentalsText: string;
  disableFetch?: boolean;
  onAddRental?: Function;
  parameters?: IUseRentalQueryParameters;
  header?: string;
  modeInfo: IMode;
  system: ISystem;
}) => {
  const { queryParams, deleteQueryParam } = useQueryString();
  const [chips, setChips] = useState<SearchChip[]>([
    ...(queryParams.get('status')
      ? [
          {
            label: 'Status',
            field: 'status',
            value: 'active',
            displayValue: 'Active',
            disabled: false,
          },
        ]
      : []),
  ]);
  const [selectedFilters, setSelectedFilters] = React.useState<string[]>([
    ...(queryParams.get('status') ? ['active'] : []),
  ]);
  const [componentsSelectedFilters, setComponentsSelectedFilters] = React.useState<any[]>(
    [...(queryParams.get('status') ? [{ label: 'Active', value: 'active' }] : [])]
  );
  const [preSelectedStartDate, setPreSelectedStartDate] = useState('');
  const [preSelectedEndDate, setPreSelectedEndDate] = useState('');
  const [rentals, setRentals] = useState<IRental[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [pageState, setPageState, isComplete, isRestoring] = useUrlState({
    sizePerPage: DEFAULT_ROWS_PER_PAGE,
    status: queryParams.get('status') ? [queryParams.get('status') as string] : [],
    pageNumber: 0,
    after: null as string | null,
    search: '',
    startDate: '',
    endDate: '',
    sortConfig: {
      key: 'start.time',
      direction: 'desc',
    } as ISortConfig,
  });
  const debouncedSearchTerm = useSearchDebounce(pageState.search);

  useUpdateEffect(() => {
    if (isRestoring) {
      if (pageState.status.length > 0) {
        setChips([
          {
            label: 'Status',
            field: 'status',
            value: pageState.status,
            displayValue: pageState.status,
            disabled: false,
          },
        ]);
        setComponentsSelectedFilters([
          {
            label: pageState.status[0],
            value: pageState.status[0],
          },
        ]);
        setSelectedFilters(pageState.status);
      }
      if (pageState.startDate) {
        setChips((prevChips) => [
          ...prevChips,
          {
            label: 'Start Date',
            field: 'startDate',
            value: new Date(pageState.startDate),
            displayValue: new Date(pageState.startDate).toLocaleDateString(),
          },
        ]);
      }
      if (pageState.endDate) {
        setChips((prevChips) => [
          ...prevChips,
          {
            label: 'End Date',
            field: 'endDate',
            value: new Date(pageState.endDate),
            displayValue: new Date(pageState.endDate).toLocaleDateString(),
          },
        ]);
      }
      setPageState((prev) => ({
        ...prev,
        pageNumber: 0,
        sizePerPage: DEFAULT_ROWS_PER_PAGE,
        after: null,
      }));
    }
  }, [isRestoring]);

  const { refetch, isRefetching, isLoading } = useRental({
    queryParameters: {
      order: pageState.sortConfig.direction,
      limit:
        pageState.after && isComplete && isRestoring
          ? DEFAULT_ROWS_PER_PAGE
          : pageState.sizePerPage,
      after: pageState.after && isComplete && isRestoring ? null : pageState.after,
      status: pageState.status.join(','),
      pattern: debouncedSearchTerm,
      after_start_time: pageState.startDate
        ? getDateWithoutTime(new Date(pageState.startDate)).toISOString()
        : null,
      before_start_time: pageState.endDate
        ? getDateAtLastSecond(new Date(pageState.endDate)).toISOString()
        : null,
      ...parameters,
    },
  });

  const handleChipDelete = useCallback(
    (deletedGroup: SearchChip) => {
      if (deletedGroup.label === 'Status') {
        if (queryParams.get('status')) {
          deleteQueryParam('status');
        }
        setPageState((prev) => ({
          ...prev,
          status: [],
        }));
        setComponentsSelectedFilters([]);
        setSelectedFilters([]);
      }
      if (deletedGroup.label === 'Start Date') {
        setPageState((prev) => ({
          ...prev,
          startDate: '',
        }));
        setPreSelectedStartDate('');
      }
      if (deletedGroup.label === 'End Date') {
        setPageState((prev) => ({
          ...prev,
          endDate: '',
        }));
        setPreSelectedEndDate('');
      }
      setChips((prevChips) => {
        return prevChips.filter((group) => {
          return group.label !== deletedGroup.label;
        });
      });
    },
    [deleteQueryParam, queryParams, setPageState]
  );

  const handleStatusChange = useCallback((values: string[]): void => {
    setChips((prevChips) => {
      const valuesFound: string[] = [];

      const newChips = prevChips.filter((chip) => {
        if (chip.field !== 'status') {
          return true;
        }

        const found = values.includes(chip.value as string);

        if (found) {
          valuesFound.push(chip.value as string);
        }

        return found;
      });

      if (values.length === valuesFound.length) {
        return newChips;
      }

      values.forEach((value) => {
        if (!valuesFound.includes(value)) {
          const option = filterOptions.find((option) => option.value === value);
          newChips.push({
            label: 'Status',
            field: 'status',
            value,
            displayValue: option!.label,
          });
        }
      });
      return newChips;
    });
  }, []);

  const handleDateChange = useCallback(
    (field: 'startDate' | 'endDate', date: Date | null): void => {
      if (date) {
        setChips((prevChips) => {
          const chip: SearchChip = {
            label: field === 'startDate' ? 'Start Date' : 'End Date',
            field: field,
            value: date,
            displayValue: date.toLocaleDateString(),
          };
          return [...prevChips.filter((chip) => chip.field !== field), chip];
        });
      }
    },
    []
  );

  const { refetch: refetchExportRentals } = useExportRentals(
    {
      queryParameters: {
        order: pageState.sortConfig.direction,
        status: pageState.status.join(','),
        pattern: debouncedSearchTerm,
        after_start_time: pageState.startDate
          ? getDateWithoutTime(new Date(pageState.startDate)).toISOString()
          : null,
        before_start_time: pageState.endDate
          ? getDateAtLastSecond(new Date(pageState.endDate)).toISOString()
          : null,
      },
    },
    {
      enabled: false,
    }
  );

  const { refetch: refetchExportRentalsRoutes } = useExportSystemRentalsRoutes(
    {
      queryParameters: {
        after_start_time: pageState.startDate
          ? getDateWithoutTime(new Date(pageState.startDate)).toISOString()
          : null,
        before_start_time: pageState.endDate
          ? getDateAtLastSecond(new Date(pageState.endDate)).toISOString()
          : null,
        ...parameters,
      },
    },
    {
      enabled: false,
    }
  );

  const handleRentalRouteExport = useCallback(() => {
    CustomAsyncToast({
      // @ts-ignore
      promise: () => refetchExportRentalsRoutes(),
      successMessage: () => 'Rentals exported successfully',
      loadingMessage: 'Exporting rentals...',
      errorMessage: 'Error exporting rentals',
    });
  }, [refetchExportRentalsRoutes]);

  const hasMoreMemo = useMemo(() => {
    const { sizePerPage, pageNumber } = pageState;
    return !Boolean(hasMore) && rentals?.length >= sizePerPage * (pageNumber + 1)
      ? true
      : hasMore;
  }, [hasMore, rentals, pageState]);

  useUpdateEffect(() => {
    if (!disableFetch && isComplete) {
      refetch().then((res) => {
        if (res.status === 'success') {
          const { data, has_more } = res.data;
          setHasMore(has_more);
          if (pageState.after) {
            if (data) {
              setRentals((rentals) => [...rentals, ...data]);
            }
          } else {
            setRentals(data);
          }
        }
      });
    }
  }, [
    pageState.after,
    pageState.sizePerPage,
    pageState.endDate,
    pageState.startDate,
    pageState.status,
    debouncedSearchTerm,
    refetch,
    disableFetch,
    parameters,
    pageState.sortConfig.direction,
    isComplete,
  ]);

  const handleSearchChange = (search: string) => {
    setPageState((prev) => ({
      ...prev,
      pageNumber: 0,
      after: null,
      search,
    }));
  };

  const buttonsMemo = useMemo(() => {
    if (parameters?.user_id) {
      return [
        {
          startIcon: <ShareIcon />,
          label: 'Export Rentals',
          onClick: () => {
            refetchExportRentals();
          },
        },
        {
          startIcon: <AddIcon />,
          label: 'Add',
          onClick: () => onAddRental && onAddRental(),
        },
        {
          startIcon: <ShareIcon />,
          label: 'Export Routes',
          onClick: handleRentalRouteExport,
        },
      ];
    } else {
      return [
        {
          startIcon: <ShareIcon />,
          label: 'Export Routes',
          onClick: handleRentalRouteExport,
        },
        {
          startIcon: <ShareIcon />,
          label: 'Export Rentals',
          onClick: () => {
            CustomAsyncToast({
              // @ts-ignore
              promise: () => refetchExportRentals(),
              successMessage: () => 'Rentals exported successfully',
              loadingMessage: 'Exporting rentals...',
              errorMessage: 'Error exporting rentals',
            });
          },
        },
      ];
    }
  }, [parameters?.user_id, handleRentalRouteExport, onAddRental, refetchExportRentals]);

  return (
    <Card>
      <PaginationTable
        hasMore={hasMoreMemo}
        isLoading={isRefetching || isLoading}
        onRowCLick={(id: string) => history.push(`/rentals/${id}`)}
        onSortChange={(sort) =>
          setPageState((prev) => ({
            ...prev,
            sortConfig: {
              key: sort.key,
              direction: sort.direction as 'asc' | 'desc',
            },
          }))
        }
        sortBy={pageState.sortConfig.key}
        padding={{ pt: 1, pl: 0, pr: 1 }}
        chips={chips}
        showFilter
        totalItems={rentals.length}
        handleChipDelete={handleChipDelete}
        onApplyFilter={() => {
          let applyedFilter = false;
          if (selectedFilters.length > 0 || pageState.status.length > 0) {
            // @ts-ignore
            setPageState((prev) => ({
              ...prev,
              status: selectedFilters,
              after: null,
            }));
            handleStatusChange(selectedFilters);
            applyedFilter = true;
          }
          if (preSelectedStartDate) {
            handleDateChange('startDate', new Date(preSelectedStartDate));
            setPageState((prev) => ({
              ...prev,
              startDate: preSelectedStartDate,
              after: null,
            }));
            applyedFilter = true;
          }
          if (preSelectedEndDate) {
            handleDateChange('endDate', new Date(preSelectedEndDate));
            setPageState((prev) => ({
              ...prev,
              after: null,
              endDate: preSelectedEndDate,
            }));
            applyedFilter = true;
          }
          if (!applyedFilter) {
            setChips([]);
          }
        }}
        spaceFilters={
          <>
            <MovaticFieldGroupMultiSelect
              label="Status"
              value={componentsSelectedFilters}
              onChange={(event: React.SyntheticEvent, newValue: any[]) => {
                setSelectedFilters(newValue.map((item) => item.value));
                setComponentsSelectedFilters(newValue);
              }}
              options={filterOptions}
            />
            <MovaticFieldGroupDate
              label="Start Date"
              value={new Date(preSelectedStartDate) || null}
              onChange={(date) => setPreSelectedStartDate(date)}
            />
            <MovaticFieldGroupDate
              label="End Date"
              value={new Date(preSelectedEndDate) || null}
              onChange={(date) => setPreSelectedEndDate(date)}
            />
          </>
        }
        localSearch={false}
        columns={
          makeColumns(
            modeInfo.details.unitsTitle,
            modeInfo.details.stationsTitle,
            parameters?.user_id
          ) as IColumn[]
        }
        buttons={buttonsMemo}
        csvFileName={'Rentals.csv'}
        items={rentals || []}
        searchText={pageState.search}
        handleSearch={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleSearchChange(e.target.value)
        }
        searchPlaceholder={'Search rentals'}
        page={pageState.pageNumber}
        rowsPerPage={pageState.sizePerPage}
        onPageChange={(page, newPage) => {
          const pageNumber = newPage + 1;
          if (rentals.length / pageState.sizePerPage < pageNumber) {
            setPageState((prev) => ({
              ...prev,
              after: rentals[rentals.length - 1].id,
              pageNumber: newPage,
            }));
            return;
          }
          setPageState((prev) => ({
            ...prev,
            pageNumber: newPage,
          }));
        }}
        onRowsPerPageChange={(pageSizeString) =>
          setPageState((prev) => ({
            ...prev,
            after: null,
            sizePerPage: Number(pageSizeString.target.value),
          }))
        }
      />
    </Card>
  );
};

const RentalR = connect(
  (state: { system: { current: ISystem; isLoaded: boolean }; mode: IMode }) => ({
    modeInfo: state.mode,
    systemLoaded: state.system.isLoaded,
    system: state.system.current,
  }),
  () => ({})
)(RentalTable);

export default RentalR;
