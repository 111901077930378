import React, { useState } from 'react';
import { ticketColumnsV2 } from '../../../tableColumns/hardwareColumns';
import { history } from '../../../utils';
import { IHardware } from '../../../ts/interface/hardware.interface';
import { PaginationTable } from '../../../components/Redesing/table/pagination-table';
import { IColumn } from '../../../ts/interface/components/redesing/table.interface';
import CardView from '../../../components/Redesing/card-view';
import { ITicket } from '../../../ts/interface/problem.interface';
import AddIcon from '@mui/icons-material/Add';
import { useParams } from 'react-router-dom';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants';

const HardwareAssignment = ({
  hardware,
  isLoading,
}: {
  hardware: IHardware;
  isLoading: boolean;
}) => {
  const [search, setSearch] = React.useState('');
  const [pageNumber, setPageNumber] = React.useState(0);
  const [sizePerPage, setSizePerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);
  const [sortData, setSortData] = useState<string>('start_time');
  const { type, hardwareId } = useParams<{ type: string; hardwareId: string }>();
  const [exportData, setExportData] = useState(false);

  const getStatus = (tickets: ITicket[]) => {
    return tickets
      ? tickets.map((ticket) => {
          const pastDue =
            new Date(ticket.due_on).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
              ? ' - Past Due'
              : '';
          let status = '';
          if (ticket.canceled_on) {
            status = 'Canceled';
          } else if (ticket.completed_on) {
            status = 'Closed';
          } else if (ticket.assignee) {
            status = `Assigned${pastDue}`;
          } else {
            status = `Open${pastDue}`;
          }

          ticket.status = status;

          return ticket;
        })
      : [];
  };

  return (
    <>
      <CardView
        headerActions={
          <IconButtonMenu
            buttons={[
              {
                label: 'Export',
                onClick: () => setExportData(true),
                startIcon: <FileDownloadIcon />,
              },
              {
                startIcon: <AddIcon />,
                variant: 'contained',
                label: 'Add',
                onClick: () => history.push(`/maintenance/add/unit/${hardwareId}`),
              },
            ]}
          />
        }
        isLoading={isLoading}
        content={
          <>
            <PaginationTable
              customKey={'id'}
              onRowCLick={(id: string) => history.push(`/maintenance/${id}`)}
              exportData={exportData}
              csvFileName={'Hardware_Tickets.csv'}
              onExport={() => setExportData(false)}
              sortDirection={'desc'}
              sortBy={sortData}
              onSortChange={(sort) => setSortData(sort.key)}
              handleSearch={(search) => setSearch(search.target.value)}
              searchText={search}
              columns={ticketColumnsV2 as IColumn[]}
              items={getStatus(hardware?.tickets)}
              onPageChange={(page, pageN) => {
                setPageNumber(pageN);
              }}
              onRowsPerPageChange={(row) => {
                const pageSize = Number(row.target.value);
                setSizePerPage(pageSize);
              }}
              page={pageNumber}
              rowsPerPage={sizePerPage}
              searchPlaceholder={'Search Maintenance Tickets'}
              noDataText={`No maintenance tickets for this ${type}`}
            />
          </>
        }
        title={'Maintenance Tickets'}
      />
    </>
  );
};

export default HardwareAssignment;
