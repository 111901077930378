import React, { Component } from 'react';
import { getLocaleDateTimeString } from '../utils.jsx';
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';

import IconButtonMenu from './Redesing/icon-button-menu';
import CardView from './Redesing/card-view';
import Typography from '@mui/material/Typography';
import { TextField } from '@mui/material';
import { Stack } from '@mui/system';
import Box from '@mui/material/Box';

function MessageRow(props) {
  return (
    <Box className="messageRow">
      <Box className="flexDisplay flexWrap">
        <Typography variant="subtitle1" className="topRow">
          {props.message?.user_id || props.message?.admin_id}
        </Typography>
        <Typography variant="body2" className="lightText topRow">
          {getLocaleDateTimeString(props.message?.created_on)}
        </Typography>
        <Typography variant="body2" className="lightText">
          {props.message?.type === 'internal' ? '(Internal)' : ''}
        </Typography>
      </Box>
      <Typography variant="body2" className="messageContent" align={'justify'}>
        {props.message?.message?.toString()}
      </Typography>
    </Box>
  );
}

export default class CommunicationLog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      start: 0,
      end: 10,
    };

    this.content = this.content.bind(this);
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
  }

  next() {
    this.setState((state) => ({
      start: state.start + 10,
      end: state.end + 10,
    }));
  }

  prev() {
    this.setState((state) => ({
      start: state.start - 10,
      end: state.end - 10,
    }));
  }

  getButtons = () => {
    const cant = this.props.buttons || 1;
    const buttons = [];
    if (cant === 1) {
      buttons.push({
        label: this.props.saveButtonText || 'Save Note',
        onClick: this.props.saveNote,
        disabled: !this.props.note,
        startIcon: <SaveIcon />,
      });
    } else {
      buttons.push({
        label: this.props.saveButtonText || 'Save Note',
        onClick: this.props.saveNote,
        disabled: !this.props.note,
        startIcon:
          this.props.saveButtonText === 'Send to User' ? <SendIcon /> : <SaveIcon />,
      });
      buttons.push({
        label: this.props.secondarySaveButtonText || 'Save Internal Note',
        onClick: this.props.secondarySaveNote,
        disabled: !this.props.note,
        startIcon: <SaveIcon />,
      });
    }
    return buttons;
  };

  content() {
    const { start, end } = this.state;
    let { log } = this.props;

    if (typeof log === 'string') {
      log = JSON.parse(log);
    }

    return (
      <div>
        {this.props.ticketDescription}
        {!log?.length ? (
          <Typography variant={'subtitle2'} paddingLeft={2} paddingTop={2}>
            {this.props.noNoteText || 'No notes added for this ticket'}
          </Typography>
        ) : (
          log
            .slice(start, end)
            .map((message, index) => (
              <MessageRow message={message} key={`message-${index}`} />
            ))
        )}

        <Stack px={2} py={2} spacing={2}>
          <TextField
            maxRows={10}
            minRows={2}
            multiline
            variant="outlined"
            fullWidth
            value={this.props.note}
            onChange={this.props.handleNoteChange}
            placeholder={this.props.placeholder || 'Add note'}
          />

          <div className="flexDisplay justifyBetween">
            <IconButtonMenu buttons={this.getButtons()} />
            <IconButtonMenu
              buttons={[
                {
                  label: 'Prev',
                  onClick: this.prev,
                  disabled: !log || start === 0 || log.length <= 10,
                },
                {
                  label: 'Next',
                  onClick: this.next,
                  disabled: !log || end >= log.length || log.length <= 10,
                },
              ]}
            />
          </div>
        </Stack>
      </div>
    );
  }

  render() {
    return (
      <CardView
        isLoading={!this.props.loaded}
        content={this.content()}
        title={this.props.header || 'Communication Log'}
      />
    );
  }
}
