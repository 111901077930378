import { useQuery } from '@tanstack/react-query';
import { getAllDisputes } from '../../../api/transactions';

const useDisputes = (options?: {}) => {
  return useQuery(['disputes'], getAllDisputes, {
    ...options,
  });
};

export default useDisputes;
