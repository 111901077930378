import {
  API_USERS_SUCCESS,
  CLEAR_CARD_INFO,
  CLEAR_WALLET_INFO,
  SET_CURRENTUSER_NONDELINQUENT,
  SET_USER_CARD,
  SET_USER_DETAILS,
  SET_USER_WALLET,
  USER_LOADED,
  USERS_LOADED,
  USER_RFID_CARDS_LOADED,
  SET_USER_RFID_CARDS,
} from '../constants';

const initialState = {
  users: [],
  detailedUser: {
    userDetails: {},
    rentals: [],
    memberships: [],
    problems: [],
  },
  isLoaded: false,
  userLoaded: false,
  currentUserCardInfo: {
    loaded: false,
    card: {
      address_city: '',
      address_country: '',
      address_line1: '',
      address_line1_check: '',
      address_line2: '',
      address_state: '',
      address_zip: 0,
      address_zip_check: '',
      brand: '',
      country: '',
      customer: '',
      cvc_check: '',
      dynamic_last4: null,
      exp_month: 0,
      exp_year: 0,
      fingerprint: '',
      funding: '',
      id: '',
      last4: '',
      metadata: null,
      name: '',
      object: '',
      tokenization_method: null,
      delinquent: false,
    },
  },
  currentUserWalletInfo: {
    wallet: {
      balance: null,
    },
    loaded: false,
  },
  currentUserRfidCards: {
    rfidCards: [],
    loaded: false,
  },
};

export default function userReducer (state = initialState, action) {
  const newState = { ...state };
  switch (action.type) {
    case API_USERS_SUCCESS:
      newState.users = action.users;
      newState.isLoaded = true;
      break;

    case USERS_LOADED:
      newState.isLoaded = action.loaded;
      break;

    case SET_USER_DETAILS:
      newState.detailedUser = action.detailedUser;
      newState.userLoaded = true;
      break;

    case USER_LOADED:
      newState.userLoaded = action.loaded;
      break;

    case SET_USER_CARD:
      newState.currentUserCardInfo = {
        card: action.cardInfo,
        loaded: true,
      };
      break;

    case SET_USER_WALLET:
      newState.currentUserWalletInfo = {
        wallet: action.walletInfo,
        loaded: true,
      };
      break;

    case CLEAR_CARD_INFO:
      newState.currentUserCardInfo = {
        card: {
          address_city: '',
          address_country: '',
          address_line1: '',
          address_line1_check: '',
          address_line2: '',
          address_state: '',
          address_zip: 0,
          address_zip_check: '',
          brand: '',
          country: '',
          customer: '',
          cvc_check: '',
          dynamic_last4: null,
          exp_month: 0,
          exp_year: 0,
          fingerprint: '',
          funding: '',
          id: '',
          last4: '',
          metadata: null,
          name: '',
          object: '',
          tokenization_method: null,
        },
        loaded: false,
      };
      break;

    case CLEAR_WALLET_INFO:
      newState.currentUserWalletInfo = {
        wallet: {
          balance: null,
        },
        loaded: false,
      };
      break;

    case SET_CURRENTUSER_NONDELINQUENT:
      newState.currentUserCardInfo.card = {
        ...newState.currentUserCardInfo.card,
        delinquent: false,
      };
      break;

    case SET_USER_RFID_CARDS:
      newState.currentUserRfidCards = {
        ...newState.currentUserRfidCards,
        rfidCards: action.rfidCards,
        loaded: true,
      };
      break;

    case USER_RFID_CARDS_LOADED:
      newState.currentUserRfidCards = {
        ...newState.currentUserRfidCards,
        loaded: action.loaded,
      };
      break;

    default:
      return state;
  }

  return newState;
}
