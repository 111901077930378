import React, { useState } from 'react';
import CardView from '../../components/Redesing/card-view';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import SaveIcon from '@mui/icons-material/Save';
import SyncIcon from '@mui/icons-material/Sync';
import { Stack } from '@mui/system';
import FormItem from '../../components/Redesing/form-item';
import { ISystem } from '../../ts/interface/system.interface';
import { TextField } from '@mui/material';
import { resetSystemAPIKey, updateSystemProvider } from '../../api/system';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';

interface ISettingsOpenDataProps {
  detailedSystem: ISystem;
  isChanged: (flag: boolean) => void;
  buttonDisabled: boolean;
  reloadSystem: () => void;
}

const SettingsOpenData: React.FC<ISettingsOpenDataProps> = ({
  detailedSystem,
  isChanged,
  buttonDisabled,
  reloadSystem,
}) => {
  const [providerId, setProviderId] = useState<string>(detailedSystem.provider_id || '');
  const [providerName, setProviderName] = useState<string>(
    detailedSystem.provider_name || ''
  );
  const [providerIdHelperText, setProviderIdHelperText] = useState<string>('');
  const [providerIdError, setProviderIdError] = useState<boolean>(false);
  const [updateApiKey, setUpdateApiKey] = useState<string | undefined>(
    detailedSystem.api_key
  );

  return (
    <Stack spacing={3}>
      <CardView
        headerActions={
          <IconButtonMenu
            buttons={[
              {
                label: `${updateApiKey ? 'Regenerate' : 'Generate'} API Key`,
                startIcon: <SyncIcon />,
                onClick: () => {
                  CustomAsyncToast({
                    promise: () => resetSystemAPIKey(),
                    successMessage: () => 'API Key Successfully updated!',
                    loadingMessage: 'Updating API Key...',
                    errorMessage:
                      'It looks like there was an error on our end while trying to reset the system API key. Please try again!',
                  }).then((r) => {
                    reloadSystem();
                    setUpdateApiKey(r);
                  });
                },
              },
              {
                label: 'Save',
                startIcon: <SaveIcon />,
                disabled: buttonDisabled,
                onClick: () => {
                  const UUIDRegex =
                    /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
                  if (providerId.match(UUIDRegex)) {
                    CustomAsyncToast({
                      promise: () =>
                        updateSystemProvider({
                          id: detailedSystem.id,
                          provider_id: providerId,
                          provider_name: providerName,
                        }),
                      successMessage: () => 'Provider Successfully updated!',
                      loadingMessage: 'Updating Provider...',
                      errorMessage:
                        'It looks like there was an error on our end while trying to update the provider. Please try again!',
                    }).then(() => {
                      reloadSystem();
                      setProviderIdError(false);
                      setProviderIdHelperText('');
                    });
                  } else {
                    setProviderIdError(true);
                    setProviderIdHelperText('');
                  }
                },
              },
            ]}
          />
        }
        content={
          <Stack px={1.5} pb={2}>
            <FormItem label={'URL'} content={'mds.movatic.co/'} />
            <FormItem
              label={'Basic Auth'}
              content={
                updateApiKey
                  ? `${detailedSystem.id}:${updateApiKey}`
                  : `(You're missing the API key. Please press the "Generate API Key" button.)`
              }
            />

            <FormItem
              label="Provider Name"
              content={
                <TextField
                  id="providerName"
                  variant="outlined"
                  fullWidth
                  value={providerName}
                  onChange={(e) => {
                    setProviderName(e.target.value);
                    isChanged(true);
                  }}
                />
              }
            />

            <FormItem
              label={"Provider's UUID"}
              content={
                <TextField
                  id="providerUUID"
                  variant="outlined"
                  fullWidth
                  value={providerId}
                  helperText={providerIdHelperText}
                  error={providerIdError}
                  onChange={(e) => {
                    setProviderId(e.target.value);
                    isChanged(true);
                  }}
                />
              }
            />
          </Stack>
        }
        title={'MDS Data'}
      />
      <CardView
        content={
          <Stack px={1.5} pb={2}>
            <FormItem
              label={'URL'}
              content={`https://gbsf.movatic.co/en/1.1/${detailedSystem.id}`}
            />
          </Stack>
        }
        title={'GBSF'}
      />
    </Stack>
  );
};

export default SettingsOpenData;
