import { history } from '../utils';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import React from 'react';
import { SeverityPill } from '../components/Redesing/severity-pill';

export function tableColumns(columnType) {
  const uniqueColumns = new Set();
  const columns = [];
  const addColumn = (column) => {
    const columnId = column.id;
    if (!uniqueColumns.has(columnId)) {
      columns.push(column);
      uniqueColumns.add(columnId);
    }
  };

  addColumn({
    id: 'actions',
    label: 'Actions',
    shouldNotExport: true,
    format: (row) => {
      return (
        <div style={{ display: 'flex', justifyContent: 'flex-start', maxWidth: '10px' }}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              history.push(`/billing/rates/${row.id}/edit`);
            }}
          >
            <EditIcon />
          </IconButton>
        </div>
      );
    },
    notSortable: true,
  });
  addColumn({
    id: 'name',
    label: 'Name',
    format: (row) => {
      return row.name ? row.name : <SeverityPill color={'info'}>N/A</SeverityPill>;
    },
  });
  addColumn({
    id: 'overview',
    label: 'Rate',
    format: (row) => {
      return row.overview ? (
        row.overview
      ) : (
        <SeverityPill color={'info'}>N/A</SeverityPill>
      );
    },
  });

  if (columnType.includes('activeRates') || columnType.includes('pendingRates')) {
    addColumn({
      id: 'active_on',
      label: 'Active On',
      format: (row) => {
        return row.active_on ? (
          row.active_on
        ) : (
          <SeverityPill color={'info'}>N/A</SeverityPill>
        );
      },
    });
  } else if (columnType.includes('archivedRates')) {
    addColumn({
      id: 'archived',
      label: 'Archived On',
      format: (row) => {
        return row.archived ? (
          row.archived
        ) : (
          <SeverityPill color={'info'}>N/A</SeverityPill>
        );
      },
    });
  } else {
    addColumn({
      id: 'active_on',
      label: 'Active On',
      format: (row) => {
        return row.active_on ? (
          row.active_on
        ) : (
          <SeverityPill color={'info'}>N/A</SeverityPill>
        );
      },
    });
    addColumn({
      id: 'archived',
      label: 'Archived On',
      format: (row) => {
        return row.archived ? (
          row.archived
        ) : (
          <SeverityPill color={'info'}>N/A</SeverityPill>
        );
      },
    });
  }
  return columns;
}
