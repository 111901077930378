export interface ICoupon {
  active?: boolean;
  uses?: string | number;
  duration?: number | string;
  discountActual?: string | number;
  code?: string;
  discount?: string | number;
  type?: string;
  id?: string;
  name?: string;
  old_id?: string;
  gid?: string;
  discount_type?: string;
}

export interface ICouponAdd {
  name: string;
  code: string;
  active: boolean;
  discount_type: string;
  discount: number;
  duration: string;
  uses: number;
}

export interface ICouponEdit {
  id: string;
  name: string;
  active: boolean;
}

export enum ECoupon {
  PERCENT = 'percent',
  TYPE = 'time',
  ONE_USE = ' Use',
  MULTIPLE_USE = ' Uses',
}
