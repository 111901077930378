import { useQuery } from '@tanstack/react-query';
import { getAllHardwareProductVersions } from '../../../api/hardware';

const useHardwareProductVersion = (parameters?: {}, options?: {}) => {
  return useQuery(
    ['hardwareProductVersion', parameters],
    () => getAllHardwareProductVersions(parameters),
    { ...options }
  );
};

export default useHardwareProductVersion;
