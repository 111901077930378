import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CancelIcon from '@mui/icons-material/Cancel';
import { Grid, Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

interface ButtonInfo {
  id: string;
  label: string;
  icon: ReactNode;
  onActive?: (id: string) => void;
  onInactive?: (id: string) => void;
  defaultActive?: boolean; // New property
}

interface MultiActionChipProps {
  items: ButtonInfo[];
  resetChips?: boolean;
  onReset?: () => void;
  disableChips?: boolean;
}

const MultiActionChip: React.FC<MultiActionChipProps> = ({
  items,
  disableChips,
  resetChips = false,
  onReset,
}) => {
  const [activeStates, setActiveStates] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    const newActiveStates = items.reduce((acc, item) => {
      if (item.defaultActive !== undefined) {
        // @ts-ignore
        acc[item.id] = item.defaultActive;
      } else {
        // @ts-ignore
        acc[item.id] = true;
      }
      return acc;
    }, {});
    setActiveStates(newActiveStates);
  }, [items]);

  const isMobileDevice = useMediaQuery(useTheme().breakpoints.down('sm'));

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const sortedItems = items.sort((a, b) => a.label.localeCompare(b.label));
  const activeItems = useMemo(
    () => sortedItems.filter((item) => activeStates[item.id]),
    [activeStates, sortedItems]
  );

  useEffect(() => {
    // Set initial state only if it's not already set
    if (Object.keys(activeStates).length === 0) {
      const initialStates = items.reduce((acc, item) => {
        // @ts-ignore
        acc[item.id] = true;
        return acc;
      }, {});
      setActiveStates(initialStates);
    }
  }, [items, activeStates]);

  useEffect(() => {
    if (resetChips) {
      setActiveStates(
        items.reduce((acc, item) => {
          // @ts-ignore
          acc[item.id] = true;
          return acc;
        }, {})
      );
      onReset?.();
    }
  }, [resetChips, items, onReset]);

  const handleToggle = (itemId: string) => {
    setActiveStates((prevStates) => ({
      ...prevStates,
      [itemId]: !prevStates[itemId],
    }));
  };

  const renderChip = (item: ButtonInfo) => (
    <Chip
      disabled={disableChips}
      sx={{
        opacity: activeStates[item.id] ? 0.5 : 1,
        pointerEvents: 'auto',
      }}
      variant="outlined"
      label={item.label}
      onDelete={() => {
        const active = activeStates[item.id];
        if (active) {
          item.onActive?.(item.id);
        } else {
          item.onInactive?.(item.id);
        }
        handleToggle(item.id);
      }}
      deleteIcon={
        activeStates[item.id] ? (
          <IconButton sx={{ mb: 0.5 }} size="small">
            <RadioButtonUncheckedIcon />
          </IconButton>
        ) : (
          <CancelIcon />
        )
      }
      icon={
        <IconButton disabled sx={{ mb: 0.5 }} size="small">
          {item.icon}
        </IconButton>
      }
    />
  );

  return (
    <>
      {isMobileDevice ? (
        <>
          {activeItems.map((item) => (
            <Grid key={item.id} item>
              {renderChip(item)}
            </Grid>
          ))}
          <IconButton onClick={handleClick} size="small">
            <MoreVertIcon />
          </IconButton>

          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
            {sortedItems.map((item) => (
              <MenuItem key={item.id} onClick={handleClose}>
                <Grid item>{renderChip(item)}</Grid>
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : (
        <>
          {sortedItems.map((item) => (
            <Grid key={item.id} item>
              {renderChip(item)}
            </Grid>
          ))}
        </>
      )}
    </>
  );
};

export default MultiActionChip;
