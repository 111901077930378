import React from 'react';
import { Link, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { PropertyListItem } from './property-list-item';

interface RenderLinkProps {
  to?: string;
  text: string | number;
  align?: 'horizontal' | 'vertical';
  label: string;
  others?: any;
  onClick?: () => void;
  dataId?: string;
}

const PropertyListItemLink: React.FC<RenderLinkProps> = ({
  to,
  text,
  label,
  align,
  others,
  onClick,
  dataId,
}) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(to || '');
  };

  return (
    <PropertyListItem dataId={dataId} align={align || 'horizontal'} label={label} {...others}>
      <Link onClick={onClick ? onClick : handleClick}>
        <Typography color="text.secondary" variant="subtitle1">
          {text}
          <i id="table_item_carot" className="fa fa-caret-right" />
        </Typography>
      </Link>
    </PropertyListItem>
  );
};

export default PropertyListItemLink;
