import { useQuery } from '@tanstack/react-query';
import { getDashboardStats } from '../../../api/dashboard';

const useDashboardStats = (systemId: string, options?: {}) => {
  return useQuery(['dashboardStats', systemId], () => getDashboardStats(systemId), {
    ...options,
  });
};

export default useDashboardStats;
