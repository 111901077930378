import { getLocaleDateString, convertCentsToMoney } from '../../../utils';
import { SeverityPill } from '../../../components/Redesing/severity-pill';

const feeTableColumns = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'amount',
    label: 'Amount',
    format: (row) => convertCentsToMoney(row.amount),
  },
  {
    id: 'created_on',
    label: 'Created On',
    format: (row) => getLocaleDateString(row.created_on),
  },
  {
    id: 'archived_on',
    label: 'Status',
    format: (row) =>
      row && row.archived_on && new Date(row.archived_on) <= new Date() ? (
        <SeverityPill color={'error'}>Archived</SeverityPill>
      ) : row.active_on && new Date(row.active_on) <= new Date() ? (
        <SeverityPill color={'success'}>Active</SeverityPill>
      ) : (
        <SeverityPill color={'warning'}>Inactive</SeverityPill>
      ),
  },
];

export default feeTableColumns;
