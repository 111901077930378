import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Map as MyMap, Marker, Polygon, Polyline, Circle } from 'react-leaflet';
import { GenericMapTileLayer } from '../../../components/mapComponent';
import { mapHardwareMarker, mapStationMarker } from '../../../components/mapMarker';
import { findAccess, history } from '../../../utils';
import { DEGRADE_COLOR, ROUTES_COLOR_BY_TYPE } from '../../../constants';
import Tooltip from '@mui/material/Tooltip';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { SeverityPill } from '../../../components/Redesing/severity-pill';
import { showRadious, getLocationColors } from '../../../util/stations';
import useStation from '../../../hooks/stations/query/useStation';
import PageLoader from '../../../components/Redesing/page-loader';
import { LocationStatus } from '../../../ts/enums';

const RentalMap = ({
  coordinatesLoaded,
  stations,
  systemType,
  systemAccess,
  rentalMarkerType,
  hardwareHasGps,
  systemId,
  endStationId,
}) => {
  const mapRef = useRef();
  const [legends, setLegends] = useState([]);
  const [bounds, setBounds] = useState(null);
  const [colors, setColors] = useState([]);
  const [markerType, setMarkerType] = useState(null);
  const [loadedRoutes, setLoadedRoutes] = useState([]);
  const [lastSixHours, setLastSixHours] = useState(false);
  const [showMarker, setShowMarker] = useState(false);
  const access = findAccess(systemAccess) ?? [];
  const degradationPercentAmount = 50;
  const maximumLocationsToDisplay = 5000;

  const { data: endStation } = useStation(
    {
      enabled: !!endStationId,
    },
    {
      locationId: endStationId,
    }
  );

  const { data: locationsAround, isLoading: isLoadingAroundLocations } = useStation(
    { enabled: true },
    { system_id: systemId, status: LocationStatus.all }
  );

  const locationsNear = useMemo(() => {
    return locationsAround?.data || [];
  }, [locationsAround]);

  useEffect(() => {
    if (
      coordinatesLoaded &&
      coordinatesLoaded.length > 0 &&
      coordinatesLoaded[0]?.route?.length > 0
    ) {
      const colors = [];
      const bounds = [];
      bounds.push([
        coordinatesLoaded[0].route[0].latitude,
        coordinatesLoaded[0].route[0].longitude,
      ]);
      if (coordinatesLoaded[0].route.length > 1) {
        bounds.push([
          coordinatesLoaded[0].route[coordinatesLoaded[0].route.length - 1].latitude,
          coordinatesLoaded[0].route[coordinatesLoaded[0].route.length - 1].longitude,
        ]);
      } else {
        setMarkerType(rentalMarkerType);
      }

      const routesLegendToDisplay = [
        ...coordinatesLoaded
          .reduce((acc, item) => acc.set(item.id, item), new Map())
          .values(),
      ];
      const routesLegendsArray = routesLegendToDisplay.map((item, index) => {
        const degradationAmount = index * degradationPercentAmount;
        const color = DEGRADE_COLOR(ROUTES_COLOR_BY_TYPE(item.type), degradationAmount);
        colors.push(color);
        return {
          label: item.display_name.concat(` (${item.id.toString().slice(0, 8)}...)`),
          color: color,
          type: item.type,
        };
      });
      if (coordinatesLoaded[0].route.length >= maximumLocationsToDisplay) {
        setLastSixHours(true);
      }
      setBounds(bounds);
      setLegends(routesLegendsArray);
      setColors(colors);
      setLoadedRoutes(coordinatesLoaded);
    } else {
      setShowMarker(true);
      setMarkerType(rentalMarkerType);
      const bounds = stations.map((station) => {
        const lat = station.lat || 0;
        const long = station.long || 0;
        return [lat, long];
      });
      setBounds(bounds);
    }
  }, [coordinatesLoaded, rentalMarkerType, stations]);

  const locationsToShow = useMemo(() => {
    if (endStation && endStation?.data && endStation?.data[0]) {
      return [endStation.data[0], ...locationsNear];
    }
    return locationsNear || [];
  }, [endStation, locationsNear]);

  if (isLoadingAroundLocations) {
    return <PageLoader minHeight={'1vh'} maxSize={'10%'} />;
  }

  return (
    <>
      {(hardwareHasGps && !loadedRoutes?.length) || bounds?.length === 0 ? (
        <Typography variant="subtitle1">
          <SeverityPill color={'info'}>
            No location tracking data for this rental
          </SeverityPill>
        </Typography>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MyMap
              ref={mapRef}
              zoom={19}
              maxZoom={20}
              style={{ height: `30em` }}
              bounds={bounds}
              boundsOptions={{ padding: [10, 10] }}
            >
              <GenericMapTileLayer />
              {locationsToShow.length > 0
                ? locationsToShow.map((location) => {
                    const { strokeColor, fillColor, strokeWidth, lineDashPattern } =
                      getLocationColors(location);

                    return (
                      <>
                        {location?.configuration !== 'zone' && (
                          <Marker
                            id={location?.id}
                            key={location?.id}
                            position={[location?.lat, location?.lng]}
                            icon={mapStationMarker(systemType, location)}
                            onClick={
                              access.includes('locations')
                                ? () => history.push(`/locations/${location.id}/general`)
                                : null
                            }
                          />
                        )}
                        {showRadious(location) ? (
                          <Circle
                            id={location?.radius}
                            center={[location?.lat, location?.lng]}
                            radius={location.radius}
                            color={strokeColor}
                            fillColor={fillColor}
                            weight={strokeWidth}
                            stroke
                          />
                        ) : null}
                        {location?.configuration === 'zone' && (
                          <Polygon
                            positions={location.polygon_coordinates}
                            color={strokeColor}
                            fillColor={fillColor}
                            weight={strokeWidth}
                            dashArray={lineDashPattern}
                            stroke
                            key={location.id}
                          />
                        )}
                      </>
                    );
                  })
                : null}
              <>
                {loadedRoutes.map((item, index) => {
                  const positions = item.route.map((coordinates) => ({
                    lat: coordinates.latitude,
                    lng: coordinates.longitude,
                  }));
                  let endStart = null;
                  if (item.route.length > 1) {
                    endStart = [
                      {
                        lat: positions[0].lat,
                        lng: positions[0].lng,
                      },
                      {
                        lat: positions[positions.length - 1].lat,
                        lng: positions[positions.length - 1].lng,
                      },
                    ];
                  }
                  return (
                    <>
                      <Polyline positions={positions} color={colors[index]} />
                      {item.route.length > 1 ? (
                        <>
                          <Marker
                            position={endStart[1]}
                            icon={mapHardwareMarker('start')}
                          />
                          <Marker
                            position={endStart[0]}
                            icon={mapHardwareMarker('end')}
                          />
                        </>
                      ) : (
                        <Marker
                          position={positions[0]}
                          icon={mapHardwareMarker(markerType)}
                        />
                      )}
                    </>
                  );
                })}
              </>
            </MyMap>
          </Grid>
          {isLoadingAroundLocations && (
            <Grid item xs={5}>
              <Typography variant="subtitle1">
                <SeverityPill color={'info'}>Loading more locations...</SeverityPill>
              </Typography>
            </Grid>
          )}
          {lastSixHours && (
            <Grid item xs={12}>
              <p style={{ paddingTop: '10px' }}>
                <i>Showing last 6 hours of location data</i>
              </p>
            </Grid>
          )}
          {!showMarker && (
            <Grid item xs={isLoadingAroundLocations ? 7 : 12}>
              <Box display="flex" justifyContent="flex-end">
                <Paper sx={{ borderRadius: 1 }} variant="outlined">
                  <div
                    style={{ borderRadius: '8px', padding: '8px' }}
                    data-id={`routes-legend`}
                  >
                    <Typography
                      fontSize={15}
                      fontFamily={'"Montserrat", sans-serif'}
                      variant={'h6'}
                    >
                      Routes Legend
                    </Typography>
                    {legends.map((item) => {
                      return (
                        <React.Fragment key={item.id}>
                          <div
                            key={`leyend-item-${item.label}`}
                            style={{
                              background: `${item.color}`,
                              width: '18px',
                              height: '18px',
                              float: 'left',
                              marginRight: '8px',
                              opacity: '0.7',
                              marginBottom: '1',
                            }}
                          />
                          <Tooltip
                            title={item.type === 'device' ? "User's device" : item.type}
                          >
                            <span>{item.label}</span>
                          </Tooltip>
                          <br />
                        </React.Fragment>
                      );
                    })}
                  </div>
                </Paper>
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default RentalMap;
