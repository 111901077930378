import React, { useCallback, useState } from 'react';
import { PaginationTable } from '../../../components/Redesing/table/pagination-table';
import { IDocument } from '../../../ts/interface/membership.interface';
import { membershipsDocuments } from '../../../tableColumns/membershipPricingColumns';
import AddIcon from '@mui/icons-material/Add';
import Card from '@mui/material/Card';

interface IMembershipPricingProps {
  documents: IDocument[];
  onAddDocument?: () => void;
  handleRemoveDocument?: (id: string) => void;
  handleDocumentsTableRowClick?: (id: string) => void;
}

const DocumentsList: React.FC<IMembershipPricingProps> = (props) => {
  const [searchRate, setSearchRate] = React.useState('');
  const [pageRate, setPageRate] = React.useState(0);
  const [rowsPerPageRate, setRowsPerPageRate] = React.useState(5);
  const [sortData, setSortData] = useState<string>('name');
  const columns = useCallback(() => {
    return membershipsDocuments(
      props.handleRemoveDocument,
      props.handleDocumentsTableRowClick
    );
  }, [props?.handleRemoveDocument, props?.handleDocumentsTableRowClick]);
  return (
    <Card>
      <PaginationTable
        padding={{ pt: 1, pl: 0, pr: 1 }}
        buttons={[
          props?.onAddDocument
            ? {
                label: 'Add',
                onClick: props.onAddDocument,
                startIcon: <AddIcon />,
                variant: 'contained',
              }
            : {},
        ]}
        onRowCLick={(id) =>
          props.handleDocumentsTableRowClick
            ? props.handleDocumentsTableRowClick(id)
            : undefined
        }
        showFilter={false}
        sortBy={sortData}
        onSortChange={(sortBy) => setSortData(sortBy.key)}
        items={props.documents || []}
        noDataText={'This membership has no documents at this time.'}
        columns={columns()}
        rowsPerPage={rowsPerPageRate}
        page={pageRate}
        onPageChange={(page, pageN: number) => setPageRate(pageN)}
        onRowsPerPageChange={(rowsPerPage) =>
          setRowsPerPageRate(Number(rowsPerPage.target.value))
        }
        searchText={searchRate}
        searchPlaceholder={'Search membership documents'}
        handleSearch={(search) => setSearchRate(search.target.value)}
      />
    </Card>
  );
};

export default DocumentsList;
