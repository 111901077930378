import React, { ReactNode } from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact, { BugsnagPluginReactResult } from '@bugsnag/plugin-react';
import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';

Bugsnag.start({
  // @ts-ignore
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact(React)],
});

const ErrorBoundary = (
  Bugsnag.getPlugin('react') as BugsnagPluginReactResult
).createErrorBoundary(React);

const ErrorView = () => {
  return (
    <Paper
      elevation={3}
      style={{ padding: '20px', textAlign: 'center', borderRadius: 'unset' }}
    >
      <Typography variant="h4" color="error">
        Something went wrong!
      </Typography>
      <Typography variant="body1" color="textSecondary">
        Please refresh the page and try again, or contact support if the problem persists.
      </Typography>
    </Paper>
  );
};

const ErrorBoundaryComponent = ({ children }: { children: ReactNode }) => {
  return <ErrorBoundary FallbackComponent={ErrorView}>{children}</ErrorBoundary>;
};

export default ErrorBoundaryComponent;
