import { baseUrl, getCredentials, fetchOptions, fetchPostOptions } from './http';
import { FourOhOneError } from '../actionCreators/system';

const performFetchRequest = async (
  url: string,
  fetchOps: RequestInit,
  errorMessage: string
) => {
  try {
    const response = await fetch(url, fetchOps);
    const data = await response.json();

    if (response.status === 200) {
      return data.message;
    }
    FourOhOneError(data);
    throw new Error(data?.message);
  } catch (error) {
    return Promise.reject(`${error}`);
  }
};

export const getRates = () => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);
  //@ts-ignore
  return performFetchRequest(`${baseUrl}/rates/new`, fetchOps, 'while fetching rates');
};

export const addRate = (rate: any, image: any) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  if (image) {
    rate.banner_id = image.id;
  }
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, rate, 'POST');
  //@ts-ignore
  return performFetchRequest(`${baseUrl}/rates/add`, fetchOps, 'while adding rate');
};

export const updateRate = (rate: any, image?: any) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  if (image) {
    rate.banner_id = image.id;
  }
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, rate, 'PUT');
  return performFetchRequest(
    `${baseUrl}/rates/update/${rate.id}`,
    //@ts-ignore
    fetchOps,
    'while updating rate'
  );
};

export const archiveRate = (rate: any) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, rate, 'PUT');
  return performFetchRequest(
    `${baseUrl}/rates/archive/${rate.id}`,
    //@ts-ignore
    fetchOps,
    'while archiving rate'
  );
};
