import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useMemberships from '../../hooks/membership/query/useMemberships';
import { IMembership, IUserMembership } from '../../ts/interface/membership.interface';
import useSearchDebounce from '../../hooks/search/useSearchDebounce';
import useUserMemberships from '../../hooks/membership/query/useUserMemberships';
import { ISystem } from '../../ts/interface/system.interface';
import { connect } from 'react-redux';
import MembershipList from './assets/membership-list';
import PageContainer from '../../components/Redesing/page-container';
import PageContent from '../../components/Redesing/page-content';
import { changeTab } from '../../utils';
import { useParams } from 'react-router-dom';
import PendingMemberships from './pending-memberships';

import useUrlState from '../../hooks/use-url-state';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants';
import { ISortConfig } from '../../ts/interface/components/redesing/table.interface';
import { useUpdateEffect } from '../../hooks/use-update-effect';

interface IIndexState {
  memberships: IMembership[];
  refetchData: boolean;
  queryPattern: string | null;
  hasMore: boolean;
}

const Index = ({ system, systemLoaded }: { system: ISystem; systemLoaded: boolean }) => {
  const initialState = {
    pendingUserMemberships: [] as IUserMembership[],
    memberships: [] as IMembership[],
    refetchData: false,
    queryPattern: null,
    hasMore: false,
  };
  const { currentTab = 'memberships' } = useParams<{ currentTab: string }>();
  const [currentTabV, setCurrentTabV] = useState(currentTab);
  const handleTabsChange = useCallback(
    (event: React.SyntheticEvent, value: React.SetStateAction<string>) => {
      changeTab(value, currentTab, 'memberships');
    },
    [currentTab]
  );

  const [filters, setFilters, isComplete, isRestoring] = useUrlState({
    sizePerPage: DEFAULT_ROWS_PER_PAGE,
    pageNumber: 0,
    search: '',
    status: 'active',
    sortConfig: {
      key: 'created_on',
      direction: 'asc',
    } as ISortConfig,
    lastId: null as string | null,
  });

  useEffect(() => {
    if (currentTab !== currentTabV) {
      setCurrentTabV(currentTab);
    }
  }, [currentTab, currentTabV]);

  const [{ memberships = [], hasMore }, setState] = useState<IIndexState>(initialState);
  const debouncedSearchTerm = useSearchDebounce(filters.search);

  const pageNumberMemo = useMemo(() => filters.pageNumber, [filters.pageNumber]);
  const { refetch, isRefetching, isLoading } = useMemberships(
    {
      pageNumber: pageNumberMemo,
      system_id: system.id,
      limit: filters.sizePerPage,
      pattern: debouncedSearchTerm,
      status: filters.status.toLowerCase(),
      order_by: filters?.sortConfig?.key || 'created_on',
      order: filters?.sortConfig?.direction || 'asc',
      lastId: filters.lastId && isComplete && isRestoring ? null : filters.lastId,
    },
    { enabled: systemLoaded }
  );

  const { data: userMemberships, isLoading: isLoadingUserMemb } = useUserMemberships(
    {},
    { enabled: systemLoaded }
  );

  const pendingUserMemberships = useMemo(() => {
    return userMemberships?.filter(
      (userMembership: IUserMembership) =>
        userMembership.membership.auth_type === 'admin' &&
        userMembership.approved === null &&
        !userMembership.canceled &&
        !userMembership.expired
    );
  }, [userMemberships]);

  useUpdateEffect(() => {
    if (isComplete && systemLoaded) {
      refetch().then((res) => {
        if (res.status === 'success') {
          const { data, has_more } = res.data;
          let membershipsNewData = data;
          if (filters.lastId && data) {
            membershipsNewData = [...memberships, ...data];
          }
          setState((prevState) => ({
            ...prevState,
            refetchData: false,
            memberships: membershipsNewData,
            hasMore: has_more,
          }));
        }
      });
    }
  }, [
    isComplete,
    debouncedSearchTerm,
    filters.sizePerPage,
    filters.lastId,
    filters.sortConfig,
    filters.status,
    systemLoaded,
  ]);

  const warning = useCallback(() => {
    const noActiveMemberships = memberships?.length === 0;
    const isPrivateSystem = system.privateSystem;
    const noStatusFilter = !filters.status;
    const noSearchTerm = !debouncedSearchTerm;
    const hasPendingMemberships = Boolean(pendingUserMemberships?.length);

    if (
      noActiveMemberships &&
      isPrivateSystem &&
      !isLoading &&
      noStatusFilter &&
      noSearchTerm
    ) {
      return 'There are no active memberships and the system is private. Please add a membership for users to join the system.';
    }

    if (hasPendingMemberships) {
      const membershipWord =
        pendingUserMemberships.length === 1 ? 'membership' : 'memberships';
      return `You have ${pendingUserMemberships.length} pending user ${membershipWord} waiting for approval`;
    }
    return undefined;
  }, [
    memberships,
    system,
    isLoading,
    pendingUserMemberships,
    filters,
    debouncedSearchTerm,
  ]);

  return (
    <PageContainer
      isLoading={!systemLoaded}
      warningMessage={warning()}
      spaceName={'Memberships'}
    >
      <PageContent
        detailedView={false}
        tabsList={[
          { label: 'Memberships', value: 'memberships' },
          {
            label: 'Pending Memberships',
            value: 'pending',
            warnings: pendingUserMemberships?.length,
          },
        ]}
        tabsChildren={
          <>
            {currentTabV === 'memberships' && (
              <MembershipList
                isRestoring={isRestoring}
                memberships={memberships}
                isLoading={isLoading || isRefetching || !systemLoaded}
                hasMore={hasMore}
                filters={filters}
                setFilters={setFilters}
                isFilterComplete={isComplete}
                onSizePerPageChange={(sizePerPage) => {
                  setFilters((prevState) => ({
                    ...prevState,
                    sizePerPage,
                    pageNumber: 0,
                    lastId: null,
                  }));
                }}
                onPageChange={(pageNumber) => {
                  if (memberships?.length / filters.sizePerPage < pageNumber + 1) {
                    setFilters((prevState) => ({
                      ...prevState,
                      pageNumber: pageNumber,
                      lastId: memberships[memberships.length - 1].id,
                    }));
                    return;
                  }
                  setFilters((prevState) => ({
                    ...prevState,
                    pageNumber,
                  }));
                }}
              />
            )}
            {currentTabV === 'pending' && (
              <PendingMemberships
                pendingUserMemberships={pendingUserMemberships}
                isLoading={isLoadingUserMemb}
              />
            )}
          </>
        }
        currentTabV={currentTabV}
        handleTabsChange={handleTabsChange}
      />
    </PageContainer>
  );
};

export default connect(
  (state: { system: { current: ISystem; isLoaded: boolean } }) => ({
    system: state.system.current,
    systemLoaded: state.system.isLoaded,
  }),
  () => ({})
)(Index);
