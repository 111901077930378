import React, { useMemo, useState } from 'react';
import { PaginationTable } from '../../../components/Redesing/table/pagination-table';
import { IPrice } from '../../../ts/interface/membership.interface';
import { viewMembershipPricingColumnsV2 } from '../../../tableColumns/membershipPricingColumns';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CardView from '../../../components/Redesing/card-view';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import { IColumn } from '../../../ts/interface/components/redesing/table.interface';

interface IMembershipPricingProps {
  prices: IPrice[];
  handleRemovePricing?: (id: string) => void;
}

const MembershipPricing: React.FC<IMembershipPricingProps> = (props) => {
  const [searchRate, setSearchRate] = React.useState('');
  const [pageRate, setPageRate] = React.useState(0);
  const [rowsPerPageRate, setRowsPerPageRate] = React.useState(5);
  const [sortData, setSortData] = useState<string>('system_name');
  const [exportData, setExportData] = useState<boolean>(false);

  const columns = useMemo(() => viewMembershipPricingColumnsV2(), []);

  return (
    <CardView
      content={
        <PaginationTable
          showFilter={false}
          sortBy={sortData}
          onSortChange={(sortBy) => setSortData(sortBy.key)}
          exportData={exportData}
          csvFileName={'Pricing.csv'}
          onExport={() => setExportData(false)}
          items={props.prices || []}
          noDataText={'This membership has no prices at this time'}
          columns={columns as IColumn[]}
          rowsPerPage={rowsPerPageRate}
          page={pageRate}
          onPageChange={(page, pageN: number) => setPageRate(pageN)}
          onRowsPerPageChange={(rowsPerPage) =>
            setRowsPerPageRate(Number(rowsPerPage.target.value))
          }
          searchText={searchRate}
          searchPlaceholder={'Search membership prices'}
          handleSearch={(search) => setSearchRate(search.target.value)}
        />
      }
      title={'Pricing Options'}
      headerActions={
        <IconButtonMenu
          buttons={[
            {
              label: 'Export',
              onClick: () => setExportData(true),
              startIcon: <FileDownloadIcon />,
            },
          ]}
        />
      }
    />
  );
};

export default MembershipPricing;
