import React from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { Toaster } from 'react-hot-toast';
import { createTheme } from '../../../theme';

const withTheme = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  const theme = createTheme({
    colorPreset: 'blue',
    contrast: 'normal',
    direction: 'ltr',
    paletteMode: 'light',
    responsiveFontSizes: true,
  });
  const WithThemeProvider: React.FC<P> = (props) => (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toaster />
      <WrappedComponent {...(props as P)} />
    </ThemeProvider>
  );
  return WithThemeProvider;
};

export default withTheme;
