import React from 'react';
import {
  getLocaleDateTimeString,
  getPriorityName,
  history,
  toTitleCase,
} from '../utils.jsx';
import { SeverityPill } from '../components/Redesing/severity-pill';
import EditIcon from '@mui/icons-material/Edit';
import { Stack } from '@mui/system';
import IconButtonMenu from '../components/Redesing/icon-button-menu';
import ColumnAvatar from '../components/Redesing/column-avatar';
import PersonIcon from '@mui/icons-material/Person';
import { Typography } from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';
import TagsCell from '../pages/problems/assets/tags-cell';

export const ticketColumns = (unitTitle, new_hardware, selectedStates) => {
  const hardwareIdentifier = new_hardware
    ? {
        id: 'hardware_number',
        label: 'Unit',
        searchable: true,
        showToolTip: true,
        format: (row) =>
          `${toTitleCase(row.hardware_type_name)} ${
            row.hardware_number
              ? row.hardware_number
              : row[`hardware_${row.primary_identifier}`] ||
                row.label ||
                row.mac ||
                row.id ||
                ''
          }`,
      }
    : {
        id: 'hardware_number',
        label: `${unitTitle} #`,
      };

  const uniqueColumns = new Set(); // Store unique column identifiers
  const columns = [];

  const addColumn = (column) => {
    const columnId = column.id;
    if (!uniqueColumns.has(columnId)) {
      columns.push(column);
      uniqueColumns.add(columnId);
    }
  };

  addColumn({
    id: 'assignee',
    label: 'Assignee',
    showToolTip: true,
    searchable: true,
    format: (row) => {
      const assignee_name = row?.assignee_name;
      return (
        <ColumnAvatar
          avatar={<BuildIcon />}
          firstRow={
            <>
              {!assignee_name ? (
                <Typography color="text.secondary" variant="body2">
                  <SeverityPill color={'info'}>Unassigned</SeverityPill>
                </Typography>
              ) : (
                <Typography color="text.secondary" variant="body2">
                  {row?.assignee_name}
                </Typography>
              )}
            </>
          }
        />
      );
    },
  });

  addColumn({
    id: 'created_on',
    label: 'Created',
    showToolTip: true,
    searchable: true,
    format: (row) => getLocaleDateTimeString(row.created_on),
  });

  addColumn({
    id: 'actions',
    label: 'Actions',
    shouldNotExport: true,
    format: (row) => {
      return (
        <Stack justifyContent={'flex-start'} alignItems={'flex-start'}>
          <IconButtonMenu
            buttons={[
              {
                label: '',
                startIcon: <EditIcon />,
                onClick: (e) => {
                  e.stopPropagation();
                  history.push(`/maintenance/edit/${row.id}`);
                },
              },
            ]}
          />
        </Stack>
      );
    },
    notSortable: true,
  });

  addColumn({
    id: 'vendor_serial',
    label: 'Vendor Serial',
    hidden: true,
    searchable: true,
    notSortable: true,
    shouldNotExport: true,
  });
  addColumn({
    id: 'mac',
    label: 'Mac',
    hidden: true,
    searchable: true,
    notSortable: true,
    shouldNotExport: true,
  });
  addColumn({
    id: 'imei',
    label: 'IMEI',
    hidden: true,
    searchable: true,
    notSortable: true,
    shouldNotExport: true,
  });
  addColumn({
    id: 'hardware_id',
    label: 'Hardware Id',
    hidden: true,
    searchable: true,
    notSortable: true,
    shouldNotExport: true,
  });
  addColumn({
    id: 'rental_id',
    label: 'Rental Id',
    hidden: true,
    searchable: true,
    notSortable: true,
    shouldNotExport: true,
  });
  addColumn({
    id: 'rental_gid',
    label: 'Rental Gid',
    hidden: true,
    searchable: true,
    notSortable: true,
    shouldNotExport: true,
  });

  if (selectedStates.includes('open')) {
    addColumn({
      id: 'due_on',
      label: 'Due',
      format: (row) => {
        if (!row?.due_on) {
          return <SeverityPill color={'info'}>N/A</SeverityPill>;
        }

        const due = new Date(row.due_on);

        // Create a new Date object for the current date without timezone information
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        // Compare dates without timezone information
        if (due.getTime() < currentDate.getTime()) {
          return <SeverityPill color={'error'}>{due.toLocaleDateString()}</SeverityPill>;
        }

        return <SeverityPill color={'success'}>{due.toLocaleDateString()}</SeverityPill>;
      },
    });
    addColumn({
      id: 'priority',
      label: 'Priority',
      format: (row) => getPriorityName(row.priority),
    });
    addColumn({
      id: 'assignee',
      label: 'Assignee',
      searchable: true,
      format: (row) => {
        const assignee_name = row?.assignee_name;
        const email = row?.assignee;
        return (
          <>
            {!assignee_name ? (
              <ColumnAvatar
                avatar={<PersonIcon />}
                firstRow={null}
                secondRow={
                  <Typography color="text.secondary" variant="body2">
                    <SeverityPill color={'info'}>Unassigned</SeverityPill>
                  </Typography>
                }
              />
            ) : (
              <ColumnAvatar
                avatar={<PersonIcon />}
                firstRow={
                  <Typography color="text.pramary" variant="body1">
                    {assignee_name}
                  </Typography>
                }
                secondRow={
                  <Typography color="text.secondary" variant="body2">
                    <SeverityPill color={'info'}>{email}</SeverityPill>
                  </Typography>
                }
              />
            )}
          </>
        );
      },
    });
    addColumn(hardwareIdentifier);
    addColumn({
      id: 'description',
      searchable: true,
      showToolTip: true,
      label: 'Description',
    });
  }

  if (selectedStates.includes('closed')) {
    addColumn({
      id: 'completed_on',
      label: 'Closed',
      format: (row) =>
        row?.completed_on ? (
          new Date(row?.completed_on).toLocaleDateString()
        ) : (
          <SeverityPill color={'info'}>N/A</SeverityPill>
        ),
    });
    addColumn({
      id: 'assignee',
      label: 'Assignee',
      searchable: true,
      format: (row) => {
        const assignee_name = row?.assignee_name;
        if (!assignee_name) {
          return 'Unassigned';
        }
        return assignee_name;
      },
    });
    addColumn(hardwareIdentifier);
    addColumn({
      id: 'description',
      label: 'Description',
      notSortable: true,
      showToolTip: true,
      searchable: true,
    });
  }

  if (selectedStates.includes('canceled')) {
    addColumn({
      id: 'canceled_on',
      label: 'Canceled',
      format: (row) =>
        row?.canceled_on ? (
          new Date(row?.canceled_on).toLocaleDateString()
        ) : (
          <SeverityPill color={'info'}>N/A</SeverityPill>
        ),
    });
    addColumn(hardwareIdentifier);
    addColumn({
      id: 'description',
      label: 'Description',
      showToolTip: true,
      searchable: true,
    });
  }

  if (selectedStates.includes('all')) {
    addColumn({
      id: 'due_on',
      label: 'Due',
      format: (row) => {
        if (!row?.due_on) return <SeverityPill color={'info'}>N/A</SeverityPill>;
        const due = new Date(row?.due_on);
        if (due.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
          return <SeverityPill color={'error'}>{due.toLocaleDateString()}</SeverityPill>;
        }
        return <SeverityPill color={'success'}>{due.toLocaleDateString()}</SeverityPill>;
      },
    });
    addColumn({
      id: 'status',
      label: 'Status',
      searchable: true,
      format: (row) => {
        if (row?.completed_on)
          return <SeverityPill color={'success'}>Closed</SeverityPill>;
        if (row?.canceled_on)
          return <SeverityPill color={'error'}>Canceled</SeverityPill>;
        return <SeverityPill color={'info'}>Open</SeverityPill>;
      },
    });
    addColumn(hardwareIdentifier);
    addColumn({
      id: 'description',
      label: 'Description',
      showToolTip: true,
      searchable: true,
    });
  }
  addColumn({
    id: 'tags',
    label: `Tags`,
    exportFormat: (row) => `"${row?.tags?.join(', ')}"`,
    disableCLick: true,
    format: (row) => <TagsCell tags={row?.tags} />,
    searchable: true,
  });

  return columns;
};

export const taskColumnsV2 = (unitTitle, new_hardware, stationsTitle) => {
  let hardwareIdentifier = {
    id: 'hardware_number',
    label: `${unitTitle} #`,
  };

  if (new_hardware) {
    hardwareIdentifier = {
      id: 'hardware_id',
      label: 'Unit',
      format: (row) =>
        `${toTitleCase(row.hardware_type_name)} ${
          row.hardware_number ? row.hardware_number : row[row.primary_identifier] || ''
        }`,
    };
  }

  return [
    {
      id: 'created_on',
      label: 'Closed',
      format: (row) => new Date(row.created_on).toLocaleDateString(),
    },
    {
      id: 'admin_name',
      label: 'Completed By',
      format: (row) => {
        if (!row.admin_name) {
          return 'Unassigned';
        }
        return row.admin_name;
      },
    },
    hardwareIdentifier,
    {
      id: 'station_id',
      label: stationsTitle,
      format: (row) => row.station_name || 'None',
    },
    {
      id: 'task_name',
      label: 'Maintenance Type',
    },
  ];
};
