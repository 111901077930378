import { useQuery } from '@tanstack/react-query';
import { getStations } from '../../../api/stations';
import { IUseStationParameters } from '../../../ts/interface/station.interface';

const useStation = (options?: {}, parameters?: IUseStationParameters) => {
  return useQuery(
    [
      'stations',
      parameters?.pageNumber,
      parameters?.limit,
      parameters?.system_id,
      parameters?.status,
    ],
    () => getStations(parameters),
    {
      ...options,
    }
  );
};

export default useStation;
