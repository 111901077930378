import { getZoneColors } from '../constants';
import { IStation } from '../ts/interface/station.interface';

export const stationConfigurations = {
  ZONE: 'zone',
  RACK: 'rack',
  DOCK: 'dock',
  STORE: 'store',
  WAREHOUSE: 'warehouse',
};

export const showRadious = (location: IStation) => {
  return (
    [stationConfigurations.RACK, stationConfigurations.WAREHOUSE].includes(
      location.configuration
    ) &&
    location.radius &&
    location.radius > 0
  );
};

export const getLocationColors = (location: IStation) => {
  return getZoneColors(location.zone_type);
};
