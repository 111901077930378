export const adminColumns = [
  {
    id: 'firstName',
    label: 'First Name',
    searchable: true,
  },
  {
    id: 'lastName',
    label: 'Last Name',
    searchable: true,
  },
  {
    id: 'email',
    label: 'Email',
    searchable: true,
  },
];
