import { useQuery } from '@tanstack/react-query';
import { getSystemsAdmins } from '../../../api/system';

const useSystemAdmins = (options?: {}) => {
  return useQuery(['allSystemAdmins'], getSystemsAdmins, {
    ...options,
  });
};

export default useSystemAdmins;
