import DeleteIcon from '@mui/icons-material/Delete';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {
  authoirzeFormater,
  getLocaleDateString,
  getLocaleDateTimeString,
  history,
  STATE_OPTIONS,
  stateFormatter,
  toTitleCase,
} from '../utils';
import MovaticBtnsGroup from '../components/ButtonGroup';
import { Grid, IconButton, LinearProgress } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import EditIcon from '@mui/icons-material/Edit';
import React from 'react';
import Typography from '@mui/material/Typography';
import { SeverityPill } from '../components/Redesing/severity-pill';
import IconButtonMenu from '../components/Redesing/icon-button-menu';
import { Stack } from '@mui/system';
import ColumnAvatar from '../components/Redesing/column-avatar';
import { getHardwareIconByName } from '../util/utilsR';
import CustomAsyncToast from '../components/Redesing/custom-async-toast';
import { hardwareRemoteAction } from '../api/hardware';
import StatePills from '../components/Redesing/state-pills';
import OnlineOfflineIndicator from '../components/Redesing/online-offline-indicator';

const sendActions = (
  action,
  successMessage,
  errorMessage,
  loadingMessage,
  hardwareId
) => {
  return CustomAsyncToast({
    promise: () => hardwareRemoteAction(hardwareId, action),
    successMessage: () => successMessage,
    errorMessage: errorMessage,
    loadingMessage: loadingMessage,
  });
};

export function hardwareColumnsV2(
  type,
  systemType,
  remoteUnlockId,
  remoteLockId,
  remoteRingId,
  remoteActionLoaded,
  handleChangeState,
  isMobileScreen,
  isMediumScreen,
  hardwareAccess
) {
  const identifierRowFormat = (row) => {
    const identifier =
      row.hardware_type_name === systemType
        ? row.number
        : row[row.primary_identifier]
        ? row[row.primary_identifier]
        : row?.label
        ? row.label
        : row.gid;
    const identifierName =
      row.hardware_type_name === systemType
        ? 'Number'
        : row.primary_identifier && row[row.primary_identifier]
        ? toTitleCase(row.primary_identifier.replace(/_/g, ' '))
        : 'ID';
    const { Icon, bgColor } = getHardwareIconByName(row.hardware_type_name);
    return (
      <ColumnAvatar
        avatar={<Icon />}
        avatarColor={bgColor}
        firstRow={
          <Typography color="text.pramary" variant="body1">
            {identifier}
          </Typography>
        }
        secondRow={
          <Typography color="text.secondary" variant="body2">
            <SeverityPill color={'info'}>{identifierName}</SeverityPill>
          </Typography>
        }
      />
    );
  };
  const columns = [
    {
      id: 'hardware_type_name',
      label: 'Type',
      exportFormat: (row) => toTitleCase(row.hardware_type_name),
      format: identifierRowFormat,
    },
    {
      id: 'id',
      alignment: 'left',
      label: `Identifier`,
      hidden: true,
      searchable: true,
    },
    {
      id: 'state',
      label: `Status`,
      exportFormat: (row) => stateFormatter(row.state).name,
      format: (row) => {
        return (
          <Grid container>
            {row.remote_connection_async !== null && (
              <Grid
                item
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: 1,
                }}
              >
                <OnlineOfflineIndicator isOnline={row.remote_connection_async} />
              </Grid>
            )}
            <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
              <StatePills
                mapState={(state) => stateFormatter(state).name}
                stateOptions={STATE_OPTIONS.filter((item) => item.hardware)}
                row={row}
                handleChangeState={handleChangeState}
                dataId={`hardware-status`}
              />
            </Grid>
          </Grid>
        );
      },
    },
    {
      id: 'battery_percent',
      label: 'Battery',
      format: (row) => {
        const cant = row?.battery_percent || row?.battery_voltage;
        const show = !!row?.battery_percent;
        return (
          <>
            {show && (
              <LinearProgress
                value={cant || 0}
                variant="determinate"
                color={cant >= 20 ? (cant > 50 ? 'success' : 'warning') : 'error'}
                sx={{
                  height: 8,
                  width: 36,
                }}
              />
            )}

            <>
              {' '}
              {row?.battery_percent
                ? `${row.battery_percent}%`
                : row.battery_voltage
                ? `${row.battery_voltage} mV`
                : 'Unknown'}
            </>
          </>
        );
      },
    },
    {
      id: 'station_name',
      alignment: 'left',
      label: 'Location',
      searchable: true,
      width: '30%',
    },
    {
      id: 'number',
      alignment: 'left',
      label: `Number`,
      hidden: true,
      searchable: true,
    },
    {
      id: 'imei',
      alignment: 'left',
      label: `IMEI`,
      hidden: true,
      searchable: true,
    },
    {
      id: 'mac',
      alignment: 'left',
      label: `Mac Address`,
      hidden: true,
      searchable: true,
    },
    {
      id: 'qr_id',
      alignment: 'left',
      label: `QR Code`,
      hidden: true,
      searchable: true,
    },

    {
      id: 'vendor_serial',
      alignment: 'left',
      label: `Vendor Serial`,
      hidden: true,
      searchable: true,
    },
    {
      id: 'vendor_qr_code',
      alignment: 'left',
      label: `Vendor QR Code`,
      hidden: true,
      searchable: true,
    },
  ];
  if (hardwareAccess) {
    columns.splice(isMediumScreen || isMobileScreen ? 5 : 1, 0, {
      id: 'actions',
      label: 'Actions',
      width: '25%',
      notSortable: true,
      shouldNotExport: true,
      disableCLick: true,
      format: (row) => {
        const buttons = [
          {
            label: '',
            startIcon: <LockOpenIcon />,
            onClick: (e) => {
              sendActions(
                'unlock',
                'Unlock request sent successfully',
                'There was an issue while sending the unlock request, please try again!',
                'Sending unlock request...',
                row.id
              );
            },
            disabled:
              remoteUnlockId === row.id ||
              !row.remote_connection_async ||
              !remoteActionLoaded,
            shouldRender: row.supports_remote_unlock,
          },
          {
            label: isMediumScreen || isMobileScreen ? 'Edit' : null,
            startIcon: <EditIcon />,
            onClick: (e) => {
              history.push(`/hardware/${type}/${row.id}/edit`);
            },
            disabled: false,
            shouldRender: true, // Set your condition here
          },

          {
            label: 'Remote Lock',
            startIcon: <LockIcon />,
            onClick: (e) => {
              sendActions(
                'lock',
                'Lock request sent successfully',
                'There was an issue while sending the lock request, please try again!',
                'Sending lock request...',
                row.id
              );
            },
            disabled:
              remoteLockId === row.id ||
              !row.remote_connection_async ||
              !remoteActionLoaded,
            shouldRender: row.supports_remote_lock,
          },
          {
            label: 'Remote Ring',
            startIcon: <NotificationsActiveIcon />,
            onClick: (e) => {
              sendActions(
                'ring',
                'Ring request sent successfully',
                'There was an issue while sending the ring request, please try again!',
                'Sending ring request...',
                row.id
              );
            },
            disabled:
              remoteRingId === row.id ||
              !row.remote_connection_async ||
              !remoteActionLoaded,
            shouldRender: row.supports_remote_ring,
          },
        ];
        return (
          <Stack justifyContent={'flex-start'} alignItems={'flex-start'}>
            <IconButtonMenu
              showEllipsis={isMediumScreen || isMobileScreen}
              slice={isMobileScreen ? 0 : isMediumScreen ? 1 : 2}
              buttons={buttons.filter((item) => item.shouldRender)}
            />
          </Stack>
        );
      },
    });
  }
  return columns;
}

export const ticketColumns = [
  {
    id: 'actions',
    alignment: 'left',
    title: 'Actions',
    width: '25%',
    shouldNotExport: true,
    noSort: true,
    activeFormatter: (cell, row) => {
      const arrayBtns = [
        {
          text: (
            <div>
              <i className="fa fa-eye" style={{ color: 'white' }} />
            </div>
          ),
          onClick: (e) => {
            e.stopPropagation();
            history.push(`/maintenance/${row.id}`);
          },
          shouldRender: true,
          shouldDisable: false,
        },
      ];

      return (
        <MovaticBtnsGroup arrayBtns={arrayBtns} btnGroupClass="btn-group-sm alignLeft" />
      );
    },
  },
  {
    id: 'due_on',
    title: 'Due',
    width: '20%',
    activeFormatter: (due_on) => {
      let due = new Date(due_on);
      if (due === 'Invalid Date') {
        // probably safari
        due = new Date(due_on.replace(/ /g, 'T'));
      }
      return due.toLocaleDateString();
    },
    className: (due_on, row) => {
      const due = new Date(due_on);
      if (
        due.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) &&
        !row.completed_on &&
        !row.canceled_on
      ) {
        return { className: 'bad' };
      }
    },
  },
  {
    id: 'status',
    title: 'Status',
    width: '20%',
    activeFormatter: (cell) => {
      return cell ? cell : ' ';
    },
  },
  {
    id: 'description',
    title: 'Description',
    width: '60%',
    activeFormatter: (cell) => {
      return cell ? cell : ' ';
    },
  },
];

export const ticketColumnsV2 = [
  {
    id: 'due_on',
    label: 'Due',
    format: (row) => {
      let due = new Date(row.due_on);
      if (due === 'Invalid Date') {
        // probably safari
        due = new Date(row.due_on.replace(/ /g, 'T'));
      }
      return (
        <SeverityPill
          color={
            due.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) &&
            !row.completed_on &&
            !row.canceled_on
              ? 'error'
              : 'success'
          }
        >
          {getLocaleDateString(row.due_on)}
        </SeverityPill>
      );
    },
  },
  {
    id: 'status',
    label: 'Status',
    format: (row) => {
      return row?.status ? row?.status : ' ';
    },
  },
  {
    id: 'description',
    label: 'Description',
    activeFormatter: (row) => {
      return row?.description ? row.description : ' ';
    },
  },
];

export const taskColumns = [
  {
    id: 'actions',
    alignment: 'left',
    title: 'Actions',
    width: '25%',
    shouldExport: false,
    shouldNotExport: true,
    noSort: true,
    activeFormatter: (cell, row) => {
      const arrayBtns = [
        {
          text: (
            <div>
              <i className="fa fa-eye" style={{ color: 'white' }} />
            </div>
          ),
          onClick: (e) => {
            e.stopPropagation();
            history.push(`/maintenance/${row.id}`);
          },
          shouldRender: true,
          shouldDisable: false,
        },
      ];

      return (
        <MovaticBtnsGroup arrayBtns={arrayBtns} btnGroupClass="btn-group-sm alignLeft" />
      );
    },
  },
  {
    id: 'created_on',
    title: 'Closed',
    width: '20%',
    activeFormatter: (date) => getLocaleDateString(date),
  },
  {
    id: 'admin_name',
    title: 'Completed By',
    width: '20%',
    activeFormatter: (assignee_name) => {
      if (!assignee_name) {
        return 'Unassigned';
      }
      return assignee_name;
    },
  },
  {
    id: 'task_name',
    title: 'Maintenance Type',
    width: '60%',
    activeFormatter: (cell) => {
      return cell ? cell : ' ';
    },
  },
];

export const taskColumnsV2 = [
  {
    id: 'created_on',
    label: 'Closed',
    format: (row) => getLocaleDateString(row.created_on),
  },
  {
    id: 'admin_name',
    label: 'Completed By',
    format: (row) => {
      if (!row.admin_name) {
        return 'Unassigned';
      }
      return row.admin_name;
    },
  },
  {
    id: 'task_name',
    label: 'Maintenance Type',
    format: (row) => {
      return row?.task_name ? row.task_name : ' ';
    },
  },
];

export const eventLogsColumns = [
  {
    id: 'created_on',
    label: 'Created',
    format: (row) => getLocaleDateTimeString(row.created_on),
  },
  {
    id: 'recorded_at',
    label: 'Recorded',
    format: (row) => getLocaleDateTimeString(row.recorded_at),
  },
  {
    id: 'type',
    label: 'Type',
    format: (row) => {
      if (!row.type) {
        return 'N/A';
      }
      if (row.color_type) {
        return <SeverityPill color={row.color_type}>{row.type}</SeverityPill>;
      }
      return row.type;
    },
  },
];

export const hardwareRentalColumnsV2 = [
  {
    id: 'start_station',
    label: 'Start Location',
    format: (row) => {
      return row?.start_station ? row.start_station : ' ';
    },
  },
  {
    id: 'end_station',
    label: 'End Location',
    format: (row) => {
      return row?.end_station ? row.end_station : ' ';
    },
  },
  {
    id: 'start_time',
    label: 'Start Time',
    format: (row) => (row?.start_time ? getLocaleDateTimeString(row.start_time) : ' '),
  },
  {
    id: 'end_time',
    label: 'End Time',
    format: (row) => (row?.end_time ? getLocaleDateTimeString(row.end_time) : ' '),
  },
];

export function assignmentColumnsV2(hardware, removeAssigment, goToHardware) {
  return [
    {
      id: 'actions',
      label: 'Actions',
      width: '25%',
      shouldNotExport: true,
      notSortable: true,
      format: (row) => {
        const btns = [
          {
            button: (
              <IconButton
                sx={{
                  borderRadius: '10px',
                  '&:hover': {
                    borderRadius: '10px',
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  removeAssigment(row);
                }}
                disabled={!!row?.removed_on}
              >
                <DeleteIcon />
              </IconButton>
            ),
            shouldRender: true,
          },
        ];
        return (
          <Grid container spacing={1}>
            {btns
              .filter((item) => item.shouldRender)
              .map((btn) => (
                <Grid item>{btn.button}</Grid>
              ))}
          </Grid>
        );
      },
    },
    {
      id: 'hardware_type_name',
      alignment: 'left',
      label: 'Type',
      width: '20%',
      searchable: true,
      format: (row) => {
        return toTitleCase(
          row?.child_hardware_type_name || row?.parent_hardware_type_name
        );
      },
    },
    {
      id: 'child_id',
      label: 'Assignee',
      searchable: true,
      format: (row) => row?.child && row[row.primary_identifier],
    },
    {
      id: 'created_on',
      label: 'Assigned On',
      searchable: true,
      format: (row) => {
        return getLocaleDateTimeString(row?.created_on) || 'N/A';
      },
    },
    {
      id: 'removed_on',
      label: 'Unassigned On',
      searchable: true,
      format: (row) => {
        return getLocaleDateTimeString(row?.removed_on) || 'N/A';
      },
    },
  ];
}

// OLD HARDWARE
// TODO: remove this
export function columnsForLocks(title, vendor) {
  const columns = [
    {
      id: 'mac',
      alignment: 'left',
      title: 'Lock #',
      width: '20%',
      activeFormatter: (cell) => {
        return cell ? cell : ' ';
      },
    },
    title === 'Room'
      ? {
          id: 'stationName',
          alignment: 'left',
          title: `${title} Name`,
          width: '20%',
          activeFormatter: (cell) => {
            return cell ? cell : ' ';
          },
        }
      : {
          id: 'hardwareNumber',
          alignment: 'left',
          title: `${title} #`,
          width: '20%',
          activeFormatter: (hardwareNumber) => {
            if (hardwareNumber) return hardwareNumber;
            return `No ${title}`;
          },
        },
    {
      id: 'state',
      alignment: 'left',
      title: 'Current Status',
      width: '20%',
      activeFormatter: authoirzeFormater,
    },
    {
      id: 'battery_status',
      alignment: 'left',
      title: 'Power Level',
      width: '20%',
      activeFormatter: (battery_status) => {
        if (battery_status) return 'Good';
        if (!battery_status) return `Low`;
        return 'Unknown';
      },
    },
  ];

  if (vendor === 'noke') {
    columns.splice(1, 0, {
      id: 'nokeSerialNumber',
      alignment: 'left',
      title: 'Serial Number',
      activeFormatter: (cell) => {
        return cell ? cell : ' ';
      },
    });
  }

  return columns;
}

// OLD HARDWARE
// TODO: remove this
export function makeColumnsForUnits(title, path) {
  const array = [
    {
      id: 'actions',
      alignment: 'left',
      title: 'Actions',
      width: '25%',
      shouldNotExport: true,
      noSort: true,
      activeFormatter: (cell, row) => {
        const arrayBtns = [
          {
            text: (
              <div>
                <i className="fa fa-eye" style={{ color: 'white' }} />
              </div>
            ),
            onClick: (e) => {
              e.stopPropagation();
              history.push(`${path}/${row.id}`);
            },
            shouldRender: true,
            shouldDisable: false,
          },
        ];

        return (
          <MovaticBtnsGroup
            arrayBtns={arrayBtns}
            btnGroupClass="btn-group-sm alignCenter"
          />
        );
      },
    },
    {
      id: 'id',
      hidden: true,
      title: 'ID',
      shouldExport: true,
    },
    {
      id: 'gid',
      hidden: true,
      title: 'GID',
      shouldExport: true,
    },
    {
      id: 'number',
      title: `${title} #`,
      width: '20%',
    },
    {
      id: 'state',
      alignment: 'left',
      title: 'Status',
      width: '20%',
      activeFormatter: (state) => stateFormatter(state).name,
    },
    {
      id: 'tableString',
      title: 'Location',
    },
    {
      hidden: true,
      id: 'iccid',
      shouldExport: true,
      title: 'ICCID',
    },
    {
      hidden: true,
      id: 'imei',
      shouldExport: true,
      title: 'IMEI',
    },
    {
      hidden: true,
      id: 'label',
      shouldExport: true,
      title: 'Label',
    },
    {
      hidden: true,
      id: 'firmware_version',
      shouldExport: true,
      title: 'Firmware Version',
    },
    {
      hidden: true,
      id: 'vendor_qr_code',
      shouldExport: true,
      title: 'Vendor QR Code',
    },
    {
      hidden: true,
      id: 'qr_id',
      shouldExport: true,
      title: 'Movatic QR Code',
    },
    {
      hidden: true,
      id: 'vendor_id',
      shouldExport: true,
      title: 'Vendor ID',
    },
    {
      hidden: true,
      id: 'last_seen',
      shouldExport: true,
      title: 'Last Seen',
      activeFormatter: (date) => getLocaleDateString(date),
    },
  ];

  if (title === 'Locker') {
    array.push({
      id: 'battery_status_string',
      title: 'Power Level',
      activeFormatter: (cell) => {
        return cell ? cell : ' ';
      },
    });
  }

  if (title === 'Keybox') {
    array.push(
      {
        id: 'nusetSerialNumber',
        title: 'Serial Number',
        activeFormatter: (cell) => {
          return cell ? cell : ' ';
        },
      },
      {
        id: 'pincodes',
        title: 'Pincodes Left',
        activeFormatter: (cell) => {
          return cell ? cell : ' ';
        },
      }
    );
  }

  return array;
}

// TODO: remove this
export function makeHardwareRentalColumns(unitTitle, stationTitle) {
  unitTitle = `${unitTitle} #`;
  const startStation = `${stationTitle}`;
  const endStation = `End ${stationTitle}`;
  if (unitTitle === 'Locker #' || unitTitle === 'Rack #') {
    return [
      {
        id: 'actions',
        alignment: 'left',
        title: 'Actions',
        width: '25%',
        shouldExport: false,
        noSort: true,
        activeFormatter: (cell, row) => {
          const arrayBtns = [
            {
              text: (
                <div>
                  <i className="fa fa-eye" style={{ color: 'white' }} />
                </div>
              ),
              onClick: (e) => {
                e.stopPropagation();
                history.push(`/rentals/${row.id}`);
              },
              shouldRender: true,
              shouldDisable: false,
            },
          ];

          return (
            <MovaticBtnsGroup
              arrayBtns={arrayBtns}
              btnGroupClass="btn-group-sm alignCenter"
            />
          );
        },
      },
      {
        id: 'startStationName',
        alignment: 'left',
        title: startStation,
        width: '20%',
        noSort: true,
        activeFormatter: (cell, row) => row.start?.station?.name || ' ',
      },
      {
        id: 'startTime',
        alignment: 'left',
        title: 'Start Time',
        width: '20%',
        activeFormatter: (cell, row) =>
          row.start && row.start.time ? getLocaleDateTimeString(row.start.time) : ' ',
      },
      {
        id: 'endTime',
        alignment: 'left',
        title: 'End Time',
        width: '20%',
        noSort: true,
        activeFormatter: (cell, row) =>
          row.end && row.end.time ? getLocaleDateTimeString(row.end.time) : ' ',
      },
    ];
  }
  return [
    {
      id: 'actions',
      alignment: 'left',
      title: 'Actions',
      width: '25%',
      shouldExport: false,
      noSort: true,
      activeFormatter: (cell, row) => {
        const arrayBtns = [
          {
            text: (
              <div>
                <i className="fa fa-eye" style={{ color: 'white' }} />
              </div>
            ),
            onClick: (e) => {
              e.stopPropagation();
              history.push(`/rentals/${row.id}`);
            },
            shouldRender: true,
            shouldDisable: false,
          },
        ];

        return (
          <MovaticBtnsGroup
            arrayBtns={arrayBtns}
            btnGroupClass="btn-group-sm alignCenter"
          />
        );
      },
    },
    {
      id: 'startStationName',
      alignment: 'left',
      title: startStation,
      width: '20%',
      noSort: true,
      activeFormatter: (cell, row) => row.start?.station?.name || ' ',
    },
    {
      id: 'endStationName',
      alignment: 'left',
      title: endStation,
      width: '20%',
      noSort: true,
      activeFormatter: (cell, row) => row.end?.station?.name || ' ',
    },
    {
      id: 'startTime',
      alignment: 'left',
      title: 'Start Time',
      width: '20%',
      activeFormatter: (cell, row) =>
        row.start && row.start.time ? getLocaleDateTimeString(row.start.time) : ' ',
    },
    {
      id: 'endTime',
      alignment: 'left',
      title: 'End Time',
      width: '20%',
      noSort: true,
      activeFormatter: (cell, row) =>
        row.end && row.end.time ? getLocaleDateTimeString(row.end.time) : ' ',
    },
  ];
}
