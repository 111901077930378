import React, { useEffect, useState, useCallback } from 'react';
import { changeTab, history } from '../../../utils';
import type { ChangeEvent } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import MovaticCustomModal from '../../../components/Modal/MovaticCustomModal';
import GeneralUserInfoBanner from './assets/GeneralUserInfoBanner';
import { ISystem } from '../../../ts/interface/system.interface';
import UserMemberships from './userMemberships';
import { UserInterface } from '../../../ts/interface/user.interface';
import UserProblems from './userProblems';
import { IProblem } from '../../../ts/interface/problem.interface';
import UserRfidCards from './userRfidCards';
import UserTransactions from '../userTransactions';
import {
  IUserDetail,
  UserDetailStatus,
} from '../../../ts/interface/userDetail.interface';
import RentalsTableCard from '../../../components/Redesing/table/rental/rentals-table-card';
import { useParams } from 'react-router-dom';
import PageContent from '../../../components/Redesing/page-content';
import CustomAsyncToast from '../../../components/Redesing/custom-async-toast';
import { authorizeUser, banUser } from '../../../api/users';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import BlockIcon from '@mui/icons-material/Block';
import EditIcon from '@mui/icons-material/Edit';
import useDirectusData from '../../../hooks/directus/query/useDirectusData';
import { DirectusData } from '../../../ts/interface/directus';
import PersonIcon from '@mui/icons-material/Person';

interface GeneralUserInfoProps {
  isLoading: boolean;
  onSuccess: Function;
  privateSystem: boolean;
  system: ISystem;
  tabs: JSX.Element;
  user: UserInterface;
  userDetails: IUserDetail;
  usesWallet: boolean;
  problems: IProblem[];
  unitsTitle: string;
}

const tabs = [
  { label: 'Details', value: 'general' },
  { label: 'Rentals', value: 'rentals' },
  { label: 'Transactions', value: 'transactions' },
];
enum UserStatusText {
  BAN = 'Ban',
  AUTHORIZE = 'Authorize',
}

const GeneralUserInfo = ({
  privateSystem,
  onSuccess,
  user,
  userDetails,
  system,
  problems,
}: GeneralUserInfoProps) => {
  const { userPhone, currentTab: currentActiveTab = 'general' } = useParams<{
    userPhone: string;
    currentTab: string;
  }>();
  const [userStatusText, setUserStatusText] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [currentTab, setCurrentTab] = useState<string>('general');
  const [directusData, setDirectusData] = useState<DirectusData>();

  useDirectusData('adminSiteSpace', { code: 'user-details' }, true, {
    onSuccess: (dataXd: { value: DirectusData }) => {
      setDirectusData(dataXd.value);
    },
  });

  useEffect(() => {
    if (currentActiveTab !== currentTab) {
      setCurrentTab(currentActiveTab);
    }
  }, [currentActiveTab, currentTab]);

  useEffect(() => {
    if (userDetails) {
      setUserStatusText(
        userDetails.banned || userDetails.status === UserDetailStatus.BLACKLISTED
          ? UserStatusText.AUTHORIZE
          : UserStatusText.BAN
      );
    }
  }, [userDetails]);

  const handleTabsChange = useCallback(
    (event: ChangeEvent<any>, value: string): void => {
      changeTab(`${userPhone}/${value}`, currentTab, 'users');
    },
    [currentTab, userPhone]
  );

  return (
    <>
      <PageContent
        tabsList={tabs}
        backLink={'/users'}
        detailIcon={<PersonIcon />}
        contentTitle={'Users'}
        itemName={userDetails.fullName}
        itemChipValue={userDetails.id}
        chipLabel={'User ID'}
        currentTabV={currentTab}
        handleTabsChange={handleTabsChange}
        tabsChildren={
          <>
            {currentTab === 'general' && (
              <div>
                <Grid container spacing={2}>
                  <Grid xs={12} md={5} lg={5} xl={5}>
                    <Stack spacing={2}>
                      <GeneralUserInfoBanner
                        paymentInfoDocumentationLink={
                          directusData?.detailed_views?.find(
                            (item) => item.identifier === 'payment-info'
                          )?.documentation_link
                        }
                        privateSystem={privateSystem}
                        userDetails={userDetails}
                        system={system}
                        onSuccess={onSuccess}
                      />
                    </Stack>
                  </Grid>
                  <Grid xs={12} md={7} lg={7} xl={7}>
                    <Stack spacing={2}>
                      <UserMemberships
                        documentationLink={
                          directusData?.detailed_views?.find(
                            (item) => item.identifier === 'user-memberships'
                          )?.documentation_link
                        }
                        user={user}
                      />
                      <UserProblems
                        documentationLink={
                          directusData?.detailed_views?.find(
                            (item) => item.identifier === 'reported-problems'
                          )?.documentation_link
                        }
                        problems={problems}
                      />
                      <UserRfidCards
                        documentationLink={
                          directusData?.detailed_views?.find(
                            (item) => item.identifier === 'user-rfid-cards'
                          )?.documentation_link
                        }
                        userId={userDetails.id}
                        userPhone={userDetails.phone}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </div>
            )}
            {currentTab === 'rentals' && (
              <div>
                <RentalsTableCard
                  noRentalsText={
                    'This user does not have any rentals matching the criteria'
                  }
                  parameters={{ user_id: userDetails.id }}
                  disableFetch={!userDetails?.id}
                  onAddRental={() =>
                    history.push(`/users/${userDetails.phone}/rentals/add`)
                  }
                />
              </div>
            )}
            {currentTab === 'transactions' && (
              <div>
                <UserTransactions />
              </div>
            )}
            {showConfirmationModal && (
              <MovaticCustomModal
                useContentDividers
                open={showConfirmationModal}
                onClose={() => setShowConfirmationModal(false)}
                title={`${userStatusText} User!`}
                content={
                  <div>
                    {`Are you sure you would like to ${userStatusText} ${userDetails?.fullName}?`}
                  </div>
                }
                action={
                  <IconButtonMenu
                    buttons={[
                      {
                        label: 'Cancel',
                        onClick: () => setShowConfirmationModal(false),
                        startIcon: <CloseIcon />,
                      },
                      {
                        label: 'Submit',
                        onClick: () => {
                          if (userStatusText === UserStatusText.BAN) {
                            CustomAsyncToast({
                              promise: () => banUser(userDetails.phone),
                              successMessage: () => 'User banned successfully',
                              loadingMessage: 'Banning user...',
                              errorMessage: 'Error banning user',
                            }).then((r) => {
                              if (r) {
                                setShowConfirmationModal(false);
                                onSuccess();
                              }
                            });
                          } else {
                            CustomAsyncToast({
                              promise: () => authorizeUser(userDetails.phone),
                              successMessage: () => 'User authorized successfully',
                              loadingMessage: 'Authorizing user...',
                              errorMessage: 'Error authorizing user',
                            }).then((r) => {
                              if (r) {
                                setShowConfirmationModal(false);
                                onSuccess();
                              }
                            });
                          }
                        },
                        startIcon: <CheckCircleIcon />,
                      },
                    ]}
                  />
                }
              />
            )}
          </>
        }
        itemTopButtons={
          <>
            <IconButtonMenu
              buttons={[
                {
                  dataId: 'user-status',
                  label: userStatusText,
                  onClick: () => setShowConfirmationModal(true),
                  startIcon:
                    userStatusText === 'Ban' ? <BlockIcon /> : <CheckCircleIcon />,
                },
                {
                  label: 'Edit',
                  disabled: !!userDetails.emailAddress || !userDetails.autoGenNumber,
                  onClick: () => history.push(`/users/${userDetails.phone}/edit`),
                  startIcon: <EditIcon />,
                },
              ]}
            />
          </>
        }
      />
    </>
  );
};

export default GeneralUserInfo;
