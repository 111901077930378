import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const RowItem = ({
  label,
  children,
  ref,
}: {
  label: string;
  children: React.ReactNode;
  ref?: any;
}) => {
  return (
    <Stack
      ref={ref || null}
      spacing={5}
      direction="row"
      alignItems={'center'}
      alignContent={'flex-start'}
      justifyContent={'space-between'}
      py={2}
    >
      <Stack>
        <Typography variant="body2">{label}</Typography>
      </Stack>
      {children}
    </Stack>
  );
};

export default RowItem;
