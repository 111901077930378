import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  addHardwareProductVersion,
  getAllHardwareProducts,
} from '../../actionCreators/hardwareproduct';
import Card from '../../components/Card.jsx';
import Button from '../../components/Button.tsx';
import { FieldGroupAdd, FieldGroupSelect } from '../../components/fieldGroup.jsx';
import { history } from '../../utils';

class AddProductVersion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      product: '',
      hardwareProduct: {},
      name: '',
      description: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
    this.content = this.content.bind(this);
    this.button = this.button.bind(this);
  }

  componentDidMount() {
    if (this.props.movaticAccess && !this.props.partner) {
      this.props.getAllHardwareProducts();
    } else if (this.props.systemLoaded) {
      history.push('/');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.movaticAccess !== nextProps.movaticAccess ||
      this.props.partner !== nextProps.partner
    ) {
      if (nextProps.movaticAccess && !nextProps.partner) {
        this.props.getAllHardwareProducts();
      } else {
        history.push('/');
      }
    }

    if (this.props.hardwareProducts !== nextProps.hardwareProducts) {
      const productList = nextProps.hardwareProducts.filter(
        (product) => product.id !== '1'
      );

      this.setState({
        products: productList,
      });
    }
  }

  handleChange(e) {
    if (e.target.id === 'product') {
      const newProduct = this.state.products.find(
        (product) => product.name === e.target.value
      );

      if (newProduct) {
        this.setState({
          [e.target.name]: e.target.value,
          hardwareProduct: newProduct,
        });
      } else {
        this.setState({
          [e.target.name]: '',
          hardwareProduct: {},
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  save() {
    const version = {
      name: this.state.name,
      description: this.state.description,
      hardware_product_id: this.state.hardwareProduct.id,
    };

    this.props.addHardwareProductVersion(version).then((response) => {
      if (response.status === 200) {
        this.setState({
          name: '',
          description: '',
          product: '',
          hardwareProduct: {},
        });
      }
    });
  }

  button() {
    return (
      <Button
        buttons={1}
        primaryText={
          <span>
            <i className="fas fa-plus" /> Add
          </span>
        }
        primaryName="add"
        primaryOnClick={this.save}
      />
    );
  }

  content() {
    const productOptions = [
      { name: 'Select One', value: '' },
      ...this.state.products.map((product) => ({
        name: product.name,
        value: product.name,
      })),
    ];

    return (
      <div>
        <Link className="page_link" to="/addproduct">
          Go to add product
          <i className="fa fa-caret-right" />
        </Link>
        <FieldGroupSelect
          name="product"
          options={productOptions}
          label="Hardware Product"
          value={this.state.product}
          handleChange={this.handleChange}
        />
        <FieldGroupAdd
          name="name"
          label="Name"
          description="Version number of the product (eg v1, 1.2, etc)"
          value={this.state.name}
          handleChange={this.handleChange}
        />
        <FieldGroupAdd
          name="description"
          label="Description"
          description="Description of this version of the product"
          value={this.state.description}
          handleChange={this.handleChange}
        />
      </div>
    );
  }

  render() {
    return (
      <Card
        header="Add Hardware Product Version"
        loaded={this.props.productLoaded}
        detailedView
        button={this.button()}
        content={this.content()}
      />
    );
  }
}

export default connect(
  (state) => ({
    hardwareProducts: state.hardwareProduct.hardwareProducts,
    productLoaded: state.hardwareProduct.productLoaded,
    systemLoaded: state.system.isLoaded,
    movaticAccess: state.admin.admin.admin.movaticAccess,
    partner: state.admin.admin.admin.partner,
  }),
  (dispatch) => ({
    getAllHardwareProducts() {
      dispatch(getAllHardwareProducts());
    },
    addHardwareProductVersion(product) {
      return dispatch(addHardwareProductVersion(product));
    },
  })
)(AddProductVersion);
