import React, { useEffect, useState } from 'react';
import { eventLogsColumns } from '../../../tableColumns/hardwareColumns';
import { IHardwareEvent } from '../../../ts/interface/hardware.interface';
import { PaginationTable } from '../../../components/Redesing/table/pagination-table';
import {
  IColumn,
  ISortConfig,
} from '../../../ts/interface/components/redesing/table.interface';
import CardView from '../../../components/Redesing/card-view';
import { useParams } from 'react-router-dom';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import useHardwareEventLogs from '../../../hooks/hardware/query/useHardwareEventLogs';
import { DEFAULT_ROWS_PER_PAGE, EVENT_LOGS_TYPES } from '../../../constants';
import { SeverityPillColor } from '../../../ts/types/settings';

const EventLogs = ({
  hardware_id,
  isLoadingHardware,
}: {
  hardware_id: string;
  isLoadingHardware: boolean;
}) => {
  const [search, setSearch] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const { type } = useParams<{ type: string; hardwareId: string }>();
  const [exportData, setExportData] = useState<boolean>(false);
  const [events, setEvents] = useState<IHardwareEvent[]>([]);
  const [sortConfig, setSortConfig] = useState<ISortConfig>({
    key: 'created_on',
    direction: 'desc',
  } as ISortConfig);
  const [lastId, setLastId] = useState<string | null>(null);

  const { refetch, isRefetching, isLoading } = useHardwareEventLogs(
    hardware_id,
    {
      limit: lastId ? sizePerPage : sizePerPage + 1,
      after: lastId,
      order_by: sortConfig.key,
      order: sortConfig.direction,
    },
    {
      enabled: false,
    }
  );

  useEffect(() => {
    refetch().then((res) => {
      if (res.status === 'success') {
        const { data } = res.data;
        const eventsData = data.map((event: IHardwareEvent) => {
          const { type } = event;
          const colorType = EVENT_LOGS_TYPES.find(({ value }) => value === type);
          event.color_type = (colorType?.color as SeverityPillColor) ?? null;
          return event;
        });
        if (lastId && eventsData) {
          setEvents((event) => [...event, ...eventsData]);
        } else {
          setEvents(eventsData);
        }
      }
    });
  }, [lastId, sizePerPage, refetch, sortConfig]);

  return (
    <>
      <CardView
        headerActions={
          <IconButtonMenu
            buttons={[
              {
                label: 'Export',
                onClick: () => setExportData(true),
                startIcon: <FileDownloadIcon />,
              },
            ]}
          />
        }
        content={
          <>
            <PaginationTable
              showFilter={false}
              customKey={'id'}
              sortDirection={'desc'}
              exportData={exportData}
              csvFileName={'Hardware_Events_Logs.csv'}
              onExport={() => setExportData(false)}
              sortBy={sortConfig.key}
              onSortChange={(sort) => setSortConfig(sort as ISortConfig)}
              handleSearch={(search) => setSearch(search.target.value)}
              searchText={search}
              isLoading={isLoading || isRefetching || !hardware_id || isLoadingHardware}
              columns={eventLogsColumns as IColumn[]}
              items={events || []}
              onPageChange={(page, newPage) => {
                const pageNumberAux = newPage + 1;
                if (events?.length / sizePerPage < pageNumberAux) {
                  setLastId(events[events.length - 1].id);
                }
                setPageNumber(newPage);
              }}
              onRowsPerPageChange={(pageSizeString) => {
                const pageSize = Number(pageSizeString.target.value);
                setSizePerPage(pageSize);
              }}
              page={pageNumber}
              rowsPerPage={sizePerPage}
              searchPlaceholder={'Search Event Logs'}
              noDataText={`No event logs for this ${type}`}
            />
          </>
        }
        title={'Event Logs'}
      />
    </>
  );
};

export default EventLogs;
