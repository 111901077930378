import { useQuery } from '@tanstack/react-query';
import { getOneCompletedTask } from '../../../api/problems';

const useOneCompletedTask = (taskId: String, options?: {}) => {
  return useQuery(['oneCompletedTask', taskId], () => getOneCompletedTask(taskId), {
    ...options,
  });
};

export default useOneCompletedTask;
