import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { getNavigationStats } from '../api/stats';

interface NavigationContextProps {
  children: ReactNode;
  initialSystemId: string; // Replace 'any' with the actual type of systemId
}

interface NavigationContextValue {
  navigationData: any; // Replace 'any' with the actual type of navigation data
  updateSystemId: (newSystemId: any) => void; // Replace 'any' with the actual type of systemId
  manualUpdate: () => void;
}

const NavigationContext = createContext<NavigationContextValue | undefined>(undefined);

export const useNavigationContext = () => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error(
      'useNavigationContext must be used within a NavigationContextProvider'
    );
  }
  return context;
};

export const NavigationContextProvider: React.FC<NavigationContextProps> = ({
  children,
  initialSystemId,
}) => {
  const [navigationData, setNavigationData] = useState<any | null>(null); // Replace 'any' with the actual type of navigation data
  const [systemId, setSystemId] = useState<string | null>(initialSystemId); // Replace 'any' with the actual type of systemId

  useEffect(() => {
    setSystemId(initialSystemId);
  }, [initialSystemId]);

  const fetchData = async () => {
    try {
      const res = await getNavigationStats(); // Pass the current systemId to the API function
      setNavigationData(res);
    } catch (error) {
      console.error('Error fetching navigation data:', error);
    }
  };

  useEffect(() => {
    if (!!systemId) {
      fetchData();
    }
  }, [systemId]);

  const updateSystemId = (newSystemId: any) => {
    setSystemId(newSystemId);
  };

  const manualUpdate = () => {
    fetchData();
  };

  const contextValue: NavigationContextValue = {
    navigationData,
    updateSystemId,
    manualUpdate,
  };

  return (
    <NavigationContext.Provider value={contextValue}>
      {children}
    </NavigationContext.Provider>
  );
};
