import React, { useRef, useState } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';

interface SplitButtonItem {
  type?: string;
  desc: string;
  icon?: JSX.Element;
  action?: () => void;
  disabled?: boolean;
}

interface SplitButtonProps {
  items: SplitButtonItem[];
  defaultSelectedIndex?: number;
  variant?: 'text' | 'outlined' | 'contained';
}

const SplitButton: React.FC<SplitButtonProps> = ({
  items = [],
  defaultSelectedIndex = 0,
  variant = 'contained',
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex] = useState(defaultSelectedIndex);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    // setSelectedIndex(index);
    event.stopPropagation();
    setOpen(false);
    // @ts-ignore
    items[index].action();
  };

  const handleToggle = (event: any) => {
    event.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const displayItems = items?.filter((item, index) => index !== 0);

  return (
    <>
      <ButtonGroup
        variant={variant}
        ref={anchorRef}
        aria-label="split button"
        sx={{ width: 'fit-content' }}
      >
        <Button endIcon={<ArrowDropDownIcon />} onClick={handleToggle}>
          {items[selectedIndex]?.desc}
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 100000,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {displayItems.map((item, index) => (
                    <MenuItem
                      key={item.type}
                      disabled={item.disabled || false}
                      onClick={(event) => handleMenuItemClick(event, index + 1)}
                    >
                      <Stack direction={'row'}>
                        <Stack pr={0.5}>{item.icon}</Stack>
                        <Stack>{item.desc}</Stack>
                      </Stack>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default SplitButton;
