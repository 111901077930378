import React from 'react';
import './online-offline-indicator.css';
interface Props {
  isOnline: boolean;
}
const OnlineOfflineIndicator: React.FC<Props> = ({ isOnline }) => {
  const statusClass = isOnline ? 'online' : 'offline';
  return <span className={statusClass} aria-hidden="true"></span>;
};
export default OnlineOfflineIndicator;
