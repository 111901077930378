import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
const ModalTittle = ({ children, onClose, customStyle }) => {
  const theme = useTheme();
  return (
    <DialogTitle sx={customStyle}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.text.primary,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default ModalTittle;
