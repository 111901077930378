import React, { useEffect, useRef, useState } from 'react';
import {
  ICoordinates,
  INewStation,
  IStation,
  StationConfiguration,
} from '../../ts/interface/station.interface';
import { IRate, IRental } from '../../ts/interface/rental.interface';
import useRates from '../../hooks/rates/query/useRates';
import {
  findAccess,
  getPricingString,
  history,
  makeLockOptionsArray,
  truncateName,
} from '../../utils';
import useStation from '../../hooks/stations/query/useStation';
import { useParams } from 'react-router-dom';
import {
  ACTIVE,
  getZoneColors,
  MAINTENANCE,
  MOVATIC_PRIMARY_COLOR,
  RADIUS_UPPER_LIMIT,
  RETIRED,
} from '../../constants';
import useGetStationImages from '../../hooks/stations/query/useGetStationImages';
import useRental from '../../hooks/rentals/query/useRental';
import { stationConfigurations } from '../../util/stations';
import { updateStation } from '../../api/stations';

//@ts-ignore
import { Circle, FeatureGroup, Map, Marker, Polygon } from 'react-leaflet/es';
import { mapStationMarker } from '../../components/mapMarker';
//@ts-ignore
import { EditControl } from 'react-leaflet-draw/dist/esm';
import { GenericMapTileLayer } from '../../components/mapComponent';
import { getAllStations } from '../../actionCreators/stations';
import { IHardware } from '../../ts/interface/hardware.interface';
import useAllHardware from '../../hooks/hardware/query/useAllHardware';
import { connect } from 'react-redux';
import { ISystem } from '../../ts/interface/system.interface';
import { IMode } from '../../ts/interface/mode.interface';
import { IStationEditProps } from '../../ts/interface/pages/stations.interfaces';
import { uploadImage } from '../../api/memberships';
import { LocationAccess } from '../../ts/enums';
import {
  MovaticFieldGroupCheck,
  MovaticFieldGroupMultiSelect,
  MovaticFieldGroupOutlinedInput,
  MovaticFieldGroupRadio,
  MovaticFieldGroupSelect,
  MovaticFieldGroupText,
} from '../../components/Redesing/movatic-field-group';
import FormItem from '../../components/Redesing/form-item';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import SaveIcon from '@mui/icons-material/Save';
import MenuItem from '@mui/material/MenuItem';
import FileUpload from '../../components/Redesing/file-upload';
import { Stack } from '@mui/system';
import PageContainer from '../../components/Redesing/page-container';
import CardView from '../../components/Redesing/card-view';
import MovaticCustomModal from '../../components/Modal/MovaticCustomModal';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';
import CustomToast from '../../components/Redesing/custom-toast';
import Typography from '@mui/material/Typography';
import NoFilePlaceHolder from '../../components/Redesing/no-file-place-holder';

const SLOW_ZONE_ERROR = 'Please enter a valid speed limit';
const RADIUS_ERROR = `Please enter a valid radius value between 1 and ${RADIUS_UPPER_LIMIT}`;
interface IStationEditState {
  detailedStation: IStation;
  id: string;
  stationName: string;
  bannerFiles: [] | { preview: string }[];
  lat?: string;
  lng?: string;
  state: number;
  capacity: number | null;
  zoneCoordinates: { lat: number; lng: number }[] | number[][];
  reservations: boolean;
  maxReservations: number;
  module: string | null;
  stationRentals: boolean;
  nameValidationResult: string | null;
  nameValidationHelp: string;
  latValidationResult: string | null;
  latValidationHelp: string;
  longValidationResult: string | null;
  longValidationHelp: string;
  validationResult: string | null;
  showModal: boolean;
  slowZoneSpeed: number | null;
  slowZoneSpeedHelp: string;
  unsavedChanges: boolean;
  radius: number | null;
  radiusValidationResult: string | null;
  radiusValidationHelp: string;
  systemRates: { label: string; value: string }[] | string[];
  ratesLoaded: boolean;
  selectedRates: string[];
  componentRates: { label: string; value: string }[];
  preventEndingRentals: boolean;
  preventStartingRentals: boolean;
  circleBound: [];
  access: LocationAccess;
}

const StationEdit = ({
  systemLoaded,
  systemAccess,
  movaticAccess,
  modules,
  system,
  stations,
  modeInfo,
  partner,
  getAllStations,
}: IStationEditProps) => {
  const { stationId } = useParams<{ stationId: string }>();
  const [state, setState] = useState<IStationEditState>({
    detailedStation: {} as IStation,
    id: '',
    stationName: '',
    bannerFiles: [],
    lat: '',
    lng: '',
    state: ACTIVE,
    capacity: null,
    zoneCoordinates: [],
    reservations: false,
    maxReservations: 0,
    module: '',
    stationRentals: false,
    nameValidationResult: null,
    nameValidationHelp: '',
    latValidationResult: null,
    latValidationHelp: '',
    longValidationResult: null,
    longValidationHelp: '',
    validationResult: null,
    showModal: false,
    slowZoneSpeed: null,
    slowZoneSpeedHelp: '',
    unsavedChanges: false,
    radius: null,
    radiusValidationResult: null,
    radiusValidationHelp: '',
    systemRates: [],
    ratesLoaded: false,
    selectedRates: [],
    componentRates: [],
    preventEndingRentals: false,
    preventStartingRentals: false,
    circleBound: [],
    access: LocationAccess.Public,
  });
  const mapRef = useRef();
  useRates({
    enabled: systemLoaded,
    onSuccess: (data: IRate[]) => {
      setState((prevState) => ({
        ...prevState,
        systemRates: data
          .filter((rate) => !rate.archived)
          .map((rate) => {
            const priceString = getPricingString({ ...rate, base: rate.base_rate });
            return {
              label: `${truncateName(rate.name)}` + (priceString && ` (${priceString})`),
              value: rate.gid,
            };
          }),
        ratesLoaded: true,
      }));
    },
  });

  const { data: allHardware = [] } = useAllHardware({
    enabled: systemLoaded && !!stationId,
  });

  const { isLoading: isLoadingStations } = useStation(
    {
      enabled: !!stationId && systemLoaded,
      onSuccess: (data: { data: IStation[] }) => {
        setStationAttributes(data.data[0]);
      },
    },
    {
      locationId: stationId,
      fields: 'rates',
    }
  );

  const { data: stationImages } = useGetStationImages({
    enabled: !!stationId && systemLoaded,
  });

  useRental(
    {},
    {
      enabled: !!stationId && systemLoaded,
      onSuccess: (data: any) => {
        setState((prevState) => ({
          ...prevState,
          stationRentals: !!data?.data.find(
            (rental: IRental) =>
              rental.start.station.id === stationId || rental.end.station.id === stationId
          ),
        }));
      },
    }
  );

  useEffect(() => {
    getAllStations();
  }, [getAllStations]);

  const outOfBounds = (coords: number[][]): boolean => {
    // !! converts to boolean
    // find returns undefined if no match and casts to false (i.e., no out of bounds points)
    // or the pair of coordinates that match, which will be cast to true
    return !!coords.find(
      (latLng) => latLng[0] > 90 || latLng[0] < -90 || latLng[1] > 180 || latLng[1] < -180
    );
  };

  const setStationAttributes = (station: IStation) => {
    setState((prevState) => ({
      ...prevState,
      detailedStation: station,
      stationName: station?.name,
      lat: station?.lat,
      lng: station?.lng,
      state: station?.state,
      capacity: station?.capacity,
      id: station?.id,
      module: station?.module,
      reservations: station?.reservations,
      maxReservations: station?.maxReservations,
      zoneCoordinates: station?.polygon_coordinates,
      slowZoneSpeed: station?.slow_zone_speed,
      radius: station?.radius,
      preventEndingRentals: station?.prevent_ending_rentals,
      preventStartingRentals: station?.prevent_starting_rentals,
      selectedRates: station?.rates?.map((rate) => rate.gid) ?? [],
      componentRates:
        station?.rates?.map((rate) => {
          const priceString = getPricingString({ ...rate, base: rate.base_rate });
          return {
            label: `${truncateName(rate.name)}` + (priceString && ` (${priceString})`),
            value: rate.gid,
          };
        }) ?? [],
      access: station?.private ? LocationAccess.Private : LocationAccess.Public,
    }));
  };

  const getAccess = (section: string) => {
    return !findAccess(systemAccess).includes(section) && !movaticAccess;
  };

  const handleMapClick = (e: { latlng: { lat: number; lng: number } }) => {
    const lat = parseFloat(e.latlng.lat.toFixed(10));
    const lng = parseFloat(e.latlng.lng.toFixed(10));
    let latValidationResult: string | null = null;
    let latValidationHelp = '';
    let longValidationResult: string | null = null;
    let longValidationHelp = '';

    if (lat > 90) {
      latValidationResult = 'error';
      latValidationHelp = 'Latitude cannot be greater than 90';
    } else if (lat < -90) {
      latValidationResult = 'error';
      latValidationHelp = 'Latitude cannot be less than -90';
    }

    if (lng > 180) {
      longValidationResult = 'error';
      longValidationHelp = 'Longitude cannot be greater than 180';
    } else if (lng < -180) {
      longValidationResult = 'error';
      longValidationHelp = 'Longitude cannot be less than -180';
    }
    setState((prevState) => ({
      ...prevState,
      lat: lat.toString(10),
      lng: lng.toString(10),
      latValidationResult,
      latValidationHelp,
      longValidationResult,
      longValidationHelp,
      circleBounds: null,
    }));
  };

  const onCreatePolygon = (e: {
    layer: { editing: { latlngs: [ICoordinates][][] } };
  }) => {
    // save points to state always, but show error if coords are out of bounds
    const mapCoords = e.layer.editing.latlngs[0][0];
    const coords = mapCoords.map((latlng: ICoordinates) => [
      Number(latlng.lat.toString(10)),
      Number(latlng.lng.toString(10)),
    ]);
    setState((prevState) => ({
      ...prevState,
      zoneCoordinates: coords,
    }));

    if (outOfBounds(coords)) {
      CustomToast({
        message: 'One or more of the points in your zone are invalid',
        type: 'error',
      });
    }
  };

  const onEditPolygon = (e: { layers: { toGeoJSON: Function } }) => {
    if (e.layers.toGeoJSON().features.length === 0) {
      // user clicked edit but made no changes
      return;
    }

    // save points to state always, but show error if coords are out of bounds
    const mapCoords = e.layers.toGeoJSON().features[0].geometry.coordinates[0];
    // geoJSON is backwards (lnglat instead of latlng)
    const coords = mapCoords.map((latlng: number[]) => [
      Number(latlng[1].toString(10)),
      Number(latlng[0].toString(10)),
    ]);

    // the last point tends to be a duplicate of the first one for some reason
    if (
      coords[0][0] === coords[coords.length - 1][0] &&
      coords[0][1] === coords[coords.length - 1][1]
    ) {
      coords.pop();
    }
    setState((prevState) => ({
      ...prevState,
      zoneCoordinates: coords,
    }));

    if (outOfBounds(coords)) {
      CustomToast({
        message: 'One or more of the points in your zone are invalid',
        type: 'error',
      });
    }
  };

  const onDeletePolygon = () => {
    setState((prevState) => ({
      ...prevState,
      zoneCoordinates: [],
    }));
  };
  const handleModuleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newModuleId: string | null = null;
    modules.forEach((module: { mac: string }) => {
      if (module.mac === e.target.value) {
        newModuleId = module.mac;
      }
    });
    let customState = state.state;
    if (parseInt(system.mobilityType) === 17 && customState === 0) {
      customState = 1;
    }
    setState((prevState) => ({
      ...prevState,
      module: newModuleId,
      state: customState,
    }));
  };

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: value,
    }));
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      stationName: e.target.value,
      nameValidationResult: null,
      nameValidationHelp: '',
    }));
  };

  const handleLatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (value > 90) {
      setState((prevState) => ({
        ...prevState,
        latValidationResult: 'error',
        latValidationHelp: 'Latitude cannot be greater than 90',
      }));
    } else if (value < -90) {
      setState((prevState) => ({
        ...prevState,
        latValidationResult: 'error',
        latValidationHelp: 'Latitude cannot be less than -90',
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        latValidationResult: null,
        latValidationHelp: '',
      }));
    }
    setState((prevState) => ({
      ...prevState,
      lat: value.toString(10),
    }));
  };

  const handleLongChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (value > 180) {
      setState((prevState) => ({
        ...prevState,
        longValidationResult: 'error',
        longValidationHelp: 'Longitude cannot be greater than 180',
      }));
    } else if (value < -180) {
      setState((prevState) => ({
        ...prevState,
        longValidationResult: 'error',
        longValidationHelp: 'Longitude cannot be less than -180',
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        longValidationResult: null,
        longValidationHelp: '',
      }));
    }
    setState((prevState) => ({
      ...prevState,
      lng: value.toString(10),
    }));
  };

  const handleStateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      state: Number(e.target.value),
    }));
  };

  const handleSlowZoneSpeedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      slowZoneSpeed: e.target.value ? Number(e.target.value) : null,
      slowZoneSpeedHelp: '',
    }));
  };

  const handleRadiusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      radius: e.target.value ? Number(e.target.value) : null,
      radiusValidationResult: null,
      radiusValidationHelp: '',
    }));
  };

  const handleFileUpload = (files: Blob[] | MediaSource[], rejected: string) => {
    if (rejected.length > 0) {
      CustomToast({
        message: 'That photo is too large',
        type: 'error',
      });
      return;
    }

    const images = files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    const image = new Image();
    image.src = images[0].preview;
    image.onload = () => {
      if (image.width !== 900 || image.height !== 300) {
        CustomToast({
          message: 'Your image is not 900px by 300px, please reformat and try again',
          type: 'error',
        });
        setState((prevState) => ({
          ...prevState,
          bannerFiles: [],
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          bannerFiles: images,
        }));
      }
    };
  };

  const handleCapacityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      state.capacity &&
      state?.capacity.toString().length === 1 &&
      e.target.value === ''
    ) {
      setState((prevState) => ({
        ...prevState,
        capacity: null,
      }));
      return;
    }
    setState((prevState) => ({
      ...prevState,
      capacity: Math.floor(Number(e.target.value)),
    }));
  };

  const toggleModal = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: !state.showModal,
    }));
  };

  const updateStationCallback = (newStationInfo: {}) => {
    CustomAsyncToast({
      promise: () => updateStation(newStationInfo),
      successMessage: () => 'Your station was updated successfully!',
      errorMessage: () => 'There was an error updating your station.',
      loadingMessage: () => 'Updating station...',
    }).then((r) => {
      if (r) {
        history.push(`/locations/${stationId}/general`);
      }
    });
  };

  const click = () => {
    const { ZONE } = StationConfiguration;
    const { configuration, state: currentStationState } = state.detailedStation;
    const { preventEndingRentals, selectedRates, access, preventStartingRentals } = state;

    if (currentStationState === RETIRED) {
      if (uniqueName()) {
        const newStationInfo = {
          id: state.id,
          state: state.state,
          name: state.stationName.trim(),
        };
        updateStationCallback(newStationInfo);
      } else {
        CustomToast({
          message: 'There is already an active station with this name',
          type: 'error',
        });
      }
    } else if (canBeSubmitted() && !!state.id) {
      const newStationInfo: INewStation = {
        id: state.id,
        name: state.stationName.trim(),
        lat: configuration !== ZONE ? parseFloat(state.lat ? state.lat : '') : null,
        lng: configuration !== ZONE ? parseFloat(state.lng ? state.lng : '') : null,
        state: state.state,
        capacity: state?.capacity ? state.capacity : null,
        reservations: state?.reservations,
        maxReservations: state?.maxReservations,
        slow_zone_speed:
          state?.slowZoneSpeed || Number.isNaN(state?.slowZoneSpeed)
            ? null
            : state.slowZoneSpeed,
        radius: Number.isNaN(state.radius) ? null : state.radius,
        polygon_coordinates:
          configuration === ZONE ? (state.zoneCoordinates as number[][]) : null,
        prevent_ending_rentals: preventEndingRentals,
        prevent_starting_rentals: preventStartingRentals,
        rates_id: selectedRates,
        private: access === LocationAccess.Private,
      };

      if (parseInt(system.mobilityType) === 17 && !system.new_hardware) {
        state.module === ''
          ? (newStationInfo.module = null)
          : (newStationInfo.module = state.module);
      }

      if (
        (newStationInfo.module &&
          parseInt(system.mobilityType) === 17 &&
          !system.new_hardware) ||
        parseInt(system.mobilityType) !== 17
      ) {
        newStationInfo.state = Number(state.state);
      } else {
        newStationInfo.state = 3;
      }

      if (newStationInfo.state === 2) {
        newStationInfo.state = 3;
      }

      if (state.bannerFiles.length > 0) {
        const formData = new FormData();
        // @ts-ignore
        formData.append('stationBannerFile', state.bannerFiles[0]);
        CustomAsyncToast({
          promise: () => uploadImage(formData),
          successMessage: () => 'Image Uploaded Successfully',
          errorMessage: 'Image Upload Failed',
          loadingMessage: 'Uploading Image...',
        }).then((response) => {
          if (response) {
            newStationInfo.banner_id = response.id;
            CustomAsyncToast({
              // @ts-ignore
              promise: () => updateStationCallback(newStationInfo),
              successMessage: () => 'Station Updated Successfully',
              errorMessage: 'Station Updated Failed',
              loadingMessage: 'Updating Station...',
            });
          }
        });
      } else {
        updateStationCallback(newStationInfo);
      }
    }
  };

  const checkForUnitsAtStation = () => {
    const { ZONE } = StationConfiguration;

    if (state.state === 3 && state.detailedStation.configuration !== ZONE) {
      const bikesAtStation = allHardware.filter(
        (bike: IHardware) =>
          bike.state === 1 && bike.current_station === stationId && !bike.has_gps
      );
      if (bikesAtStation.length > 0) {
        toggleModal();
      } else {
        click();
      }
    } else {
      click();
    }
  };

  const uniqueName = () => {
    const { stationName } = state;
    const match = stations.find(
      (station: IStation) =>
        stationName.toLowerCase().replace(/\s/g, '') ===
          station.name.toLowerCase().replace(/\s/g, '') &&
        station.gid !== stationId &&
        station.state === ACTIVE
    );
    // name is unique if there's no match
    return !match;
  };

  const canBeSubmitted = () => {
    let canBeSubmitted = true;
    const { ZONE, RACK, WAREHOUSE } = stationConfigurations;

    if (!state.stationName) {
      setState((prevState) => ({
        ...prevState,
        nameValidationResult: 'error',
        nameValidationHelp: 'A station name is required to create a station',
      }));

      canBeSubmitted = false;
    } else if (!uniqueName()) {
      setState((prevState) => ({
        ...prevState,
        nameValidationResult: 'error',
        nameValidationHelp: 'There is already an active station with this name',
      }));
      canBeSubmitted = false;
    } else if (state.stationName.length > 30) {
      setState((prevState) => ({
        ...prevState,
        nameValidationResult: 'error',
        nameValidationHelp: 'Station name must be less then 30 characters',
      }));
      canBeSubmitted = false;
    }

    if (state.detailedStation.configuration === ZONE) {
      // TODO: more specific
      if (outOfBounds(state.zoneCoordinates as number[][])) {
        CustomToast({
          message: 'The coordinates for your zone are invalid',
          type: 'error',
        });
        canBeSubmitted = false;
      }

      if (state.zoneCoordinates.length <= 2) {
        CustomToast({
          message: 'A zone must be made of at least three points',
          type: 'error',
        });
        canBeSubmitted = false;
      }

      if (state.unsavedChanges) {
        CustomToast({
          message: 'Please save or cancel the station boundary changes before submitting',
          type: 'error',
        });
        canBeSubmitted = false;
      }

      const slowZoneSpeed = state.slowZoneSpeed || null;

      if ((slowZoneSpeed && slowZoneSpeed < 0) || Number.isNaN(slowZoneSpeed)) {
        CustomToast({
          message: SLOW_ZONE_ERROR,
          type: 'error',
        });
        setState((prevState) => ({
          ...prevState,
          slowZoneSpeedHelp: SLOW_ZONE_ERROR,
        }));
        canBeSubmitted = false;
      }
    } else {
      if (state.lat === '') {
        setState((prevState) => ({
          ...prevState,
          latValidationResult: 'error',
          latValidationHelp: 'Latitude is required',
        }));
        canBeSubmitted = false;
      } else if (Number(state.lat) > 90) {
        setState((prevState) => ({
          ...prevState,
          latValidationResult: 'error',
          latValidationHelp: 'Latitude cannot be greater than 90',
        }));
        canBeSubmitted = false;
      } else if (Number(state.lat) < -90) {
        setState((prevState) => ({
          ...prevState,
          latValidationResult: 'error',
          latValidationHelp: 'Latitude cannot be less than -90',
        }));
        canBeSubmitted = false;
      }
      if (state.lng === '') {
        setState((prevState) => ({
          ...prevState,
          longValidationResult: 'error',
          longValidationHelp: 'Longitude is required',
        }));
        canBeSubmitted = false;
      } else if (Number(state.lng) > 180) {
        setState((prevState) => ({
          ...prevState,
          longValidationResult: 'error',
          longValidationHelp: 'Longitude cannot be greater than 180',
        }));
        canBeSubmitted = false;
      } else if (Number(state.lng) < -180) {
        setState((prevState) => ({
          ...prevState,
          longValidationResult: 'error',
          longValidationHelp: 'Longitude cannot be less than -180',
        }));
        canBeSubmitted = false;
      }

      const radius = state.radius ? state.radius : null;

      if (
        [RACK, WAREHOUSE].includes(state.detailedStation.configuration) &&
        (Number.isNaN(radius) ||
          (radius && radius < 1) ||
          (radius && radius > RADIUS_UPPER_LIMIT))
      ) {
        setState((prevState) => ({
          ...prevState,
          radiusValidationResult: 'error',
          radiusValidationHelp: RADIUS_ERROR,
        }));
        canBeSubmitted = false;
      }
    }

    return canBeSubmitted;
  };

  const location = () => {
    const { polygon_coordinates, configuration, zone_type, id } = state.detailedStation;
    const { strokeColor, fillColor, strokeWidth, lineDashPattern } =
      getZoneColors(zone_type);
    const { ZONE, RACK, WAREHOUSE } = stationConfigurations;

    const { stationsTitle, hardware_type } = modeInfo.details;
    const radius = state.radius ? state.radius : 0;
    const showRadius = [RACK, WAREHOUSE].includes(configuration) && radius;
    const circleBounds = showRadius
      ? state.circleBound && state.circleBound.length > 0
        ? state.circleBound
        : null
      : null;
    if (state.stationRentals) {
      return (
        <div className="darkText fieldTopPadding">
          {`The location of this ${stationsTitle.toLowerCase()} cannot be changed since rentals have already occurred at it. Please archive this ${stationsTitle.toLowerCase()} and create a new ${stationsTitle.toLowerCase()} at the desired location.`}
        </div>
      );
    }

    return (
      <div className="fieldTopPadding">
        {configuration !== ZONE ? (
          <div>
            <MovaticFieldGroupOutlinedInput
              type={'number'}
              description={`The latitude of the ${stationsTitle.toLowerCase()}. (Enter the value or click the location on the map)`}
              label={`${stationsTitle} Latitude`}
              value={state.lat}
              allowNegativeNumbers
              hasError={state.latValidationResult === 'error'}
              errorMessage={state.latValidationHelp}
              onChange={handleLatChange}
              id="lat"
            />

            <MovaticFieldGroupOutlinedInput
              type={'number'}
              description={`The longitude of the ${stationsTitle.toLowerCase()}. (Enter the value or click the location on the map)`}
              label={`${stationsTitle} Longitude`}
              value={state.lng}
              allowNegativeNumbers
              hasError={state.longValidationResult === 'error'}
              errorMessage={state.longValidationHelp}
              onChange={handleLongChange}
              id="lng"
            />
          </div>
        ) : (
          <FormItem
            label="Edit Zone"
            content="Click the edit icon to begin editing the shape. Click the delete icon if you want to delete it, and click the pentagon icon to begin drawing a new area for the zone."
          />
        )}
        <Stack pt={2}>
          <Map
            center={configuration === ZONE ? null : [state.lat, state.lng]}
            zoom={17}
            bounds={configuration === ZONE ? polygon_coordinates : circleBounds}
            boundsOptions={{ padding: [15, 15] }}
            ref={mapRef}
            onClick={configuration !== ZONE ? handleMapClick : null}
          >
            {state.lat && state.lng && (
              <Marker
                position={[state.lat, state.lng]}
                icon={mapStationMarker(hardware_type)}
              />
            )}
            {showRadius ? (
              <Circle
                center={[state.lat, state.lng]}
                radius={radius}
                color={strokeColor}
                fillColor={fillColor}
                weight={strokeWidth}
                stroke
                onadd={(e: { target: { getBounds: [] } }) =>
                  setState((prevState) => ({
                    ...prevState,
                    circleBound: e.target.getBounds,
                  }))
                }
              />
            ) : null}
            {configuration === ZONE && (
              <FeatureGroup>
                <EditControl
                  onCreated={onCreatePolygon}
                  onDeleted={onDeletePolygon}
                  onEdited={onEditPolygon}
                  onEditStart={handleEditPolygonStart}
                  onEditStop={handleEditPolygonStop}
                  draw={{
                    polygon:
                      state.zoneCoordinates.length === 0
                        ? {
                            allowIntersection: false,
                            shapeOptions: {
                              color: MOVATIC_PRIMARY_COLOR(1),
                            },
                          }
                        : false,
                    rectangle: false,
                    circle: false,
                    polyline: false,
                    marker: false,
                    circlemarker: false,
                  }}
                />
                <Polygon
                  positions={polygon_coordinates}
                  color={strokeColor}
                  fillColor={fillColor}
                  weight={strokeWidth}
                  dashArray={lineDashPattern}
                  stroke
                  key={id}
                />
              </FeatureGroup>
            )}

            <GenericMapTileLayer />
          </Map>
        </Stack>
      </div>
    );
  };

  const button = () => {
    return (
      <>
        <IconButtonMenu
          buttons={[
            {
              label: 'Cancel',
              onClick: () => history.push(`/locations/${state.id}/general`),
            },
            {
              label: 'Save',
              onClick: checkForUnitsAtStation,
              disabled: getAccess('physical'),
              startIcon: <SaveIcon />,
            },
          ]}
        />
      </>
    );
  };

  const content = () => {
    const { state: stationState, banner_id, configuration } = state.detailedStation;
    const { stationsTitle } = modeInfo.details;
    const banner =
      stationImages?.filter((image: { id: string }) => image.id === banner_id) || [];

    const { ZONE, RACK, DOCK, WAREHOUSE } = stationConfigurations;
    const { systemRates } = state;

    return (
      <div>
        {stationState === RETIRED ? (
          <MovaticFieldGroupCheck
            label="Unretire?"
            description={`This ${stationsTitle.toLowerCase()} is currently retired. To edit anything about it you need to first bring it out of retirement.`}
            id="state"
            name="state"
            onChange={() =>
              setState((prevState) => ({
                ...prevState,
                state: state.state === RETIRED ? MAINTENANCE : RETIRED,
              }))
            }
            checked={state.state !== RETIRED}
          />
        ) : (
          <div>
            <MovaticFieldGroupText
              id="name"
              name="name"
              label={`${stationsTitle} Name`}
              description="Visible to users to identify where they are renting from."
              value={state.stationName}
              hasError={state.nameValidationResult === 'error'}
              errorMessage={state.nameValidationHelp}
              onChange={handleNameChange}
            />

            {parseInt(system.mobilityType) === 22 &&
            [RACK, DOCK, WAREHOUSE].includes(state.detailedStation.configuration) ? (
              <MovaticFieldGroupOutlinedInput
                label="Station Capacity"
                description="The number of spots available for return. Leave blank for unlimited capacity."
                type="number"
                name="capacity"
                id="capacity"
                value={state.capacity}
                inputProps={{ min: 0, step: 1 }}
                onChange={handleCapacityChange}
              />
            ) : null}

            {parseInt(system.mobilityType) === 17 && !system.new_hardware ? (
              <MovaticFieldGroupSelect
                label="Module"
                description={`What module is assigned to the room, the current module is: ${
                  state.detailedStation.module ? state.detailedStation.module : 'None'
                }`}
                name={'module'}
                optionNodes={
                  state.detailedStation.module == null ||
                  state.detailedStation.module === ''
                    ? makeLockOptionsArray(modules)
                    : makeLockOptionsArray(modules)
                        .concat([
                          {
                            value: state.detailedStation.module,
                            name: 'Current Lock',
                          },
                        ])
                        .map((option: { value: string; name: string }, index: number) => (
                          <MenuItem key={index} value={option.value}>
                            {option.name}
                          </MenuItem>
                        ))
                }
                value={state.module ? state.module : 'None'}
                onChange={handleModuleChange}
                id="module"
              />
            ) : null}
            {!state.module &&
            parseInt(system.mobilityType) === 17 &&
            !system.new_hardware ? null : (
              <MovaticFieldGroupSelect
                label={`${stationsTitle} Status`}
                description={`A ${stationsTitle.toLowerCase()} must be active for rentals to occur at it.`}
                name={'module'}
                optionNodes={[
                  { value: 1, name: 'Active' },
                  { value: 3, name: 'Maintenance' },
                ].map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
                value={state.state === 2 ? '3' : state.state}
                onChange={handleStateChange}
                id="state"
              />
            )}

            {configuration === ZONE && (
              <MovaticFieldGroupOutlinedInput
                label={`${stationsTitle} Speed Limit`}
                description="Speed limit for this zone."
                type="number"
                name="slowZoneSpeed"
                id="slowZoneSpeed"
                value={state.slowZoneSpeed ?? null}
                inputProps={{ min: 0, step: 1 }}
                onChange={handleSlowZoneSpeedChange}
                hasError={state.slowZoneSpeedHelp === 'error'}
                errorMessage={state.slowZoneSpeedHelp}
              />
            )}
            {![ZONE].includes(configuration) && (
              <MovaticFieldGroupOutlinedInput
                label={`${stationsTitle} Radius`}
                description="Radius for this zone."
                type="number"
                name="radius"
                id="radius"
                value={state.radius ?? ''}
                inputProps={{ min: 0, step: 1, max: 999999 }}
                onChange={handleRadiusChange}
                hasError={state.radiusValidationHelp === 'error'}
                errorMessage={state.radiusValidationHelp}
              />
            )}
            {system.reservations && movaticAccess && !partner ? (
              <div>
                <MovaticFieldGroupCheck
                  label="Reservations"
                  description="Allow users to make reservations ahead of time"
                  id="reservations"
                  name="reservations"
                  onChange={onChangeValue}
                  checked={state.reservations}
                />

                {state.reservations && movaticAccess && !partner ? (
                  <MovaticFieldGroupSelect
                    label="Max Reservations"
                    description="Limit the amount of reservations"
                    name={'maxReservations'}
                    optionNodes={[
                      { value: '0', label: 'none' },
                      { value: '1', label: '1' },
                      { value: '2', label: '2' },
                      { value: '3', label: '3' },
                      { value: '4', label: '4' },
                      { value: '5', label: '5' },
                      { value: '10', label: '10' },
                      { value: '20', label: '20' },
                    ].map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                    value={state.maxReservations}
                    onChange={onChangeValue}
                    id="maxReservations"
                  />
                ) : null}
              </div>
            ) : null}
            <MovaticFieldGroupRadio
              label="Access"
              row
              description="Choose if this will be a public or private location. Public locations are visible to all users, private locations are only visible to users who have a special perk to use it."
              value={state.access}
              onChange={(access) =>
                setState((prevState) => ({
                  ...prevState,
                  access:
                    access.target.value === LocationAccess.Private
                      ? LocationAccess.Private
                      : LocationAccess.Public,
                }))
              }
              options={[
                { id: LocationAccess.Public, label: LocationAccess.Public },
                { id: LocationAccess.Private, label: LocationAccess.Private },
              ]}
            />
            <Stack pb={2}>
              <FormItem
                label="Banner Image"
                description={`Add a banner so users can identify this ${stationsTitle.toLowerCase()} more easily. The banner image must be 900px X 300px and a .png`}
                content={
                  <Stack mt={'6em'}>
                    <div>
                      Current Banner
                      <div id="currentLogo">
                        {banner.length > 0 ? (
                          <img
                            alt=""
                            src={`data:image/png;base64,${banner[0].image}`}
                            className="station_bannerImage"
                          />
                        ) : (
                          <NoFilePlaceHolder />
                        )}
                      </div>
                    </div>
                    {state.bannerFiles.length > 0 ? (
                      <div>
                        <div>New Banner</div>
                        <img
                          alt=""
                          className="station_bannerImage"
                          //@ts-ignore
                          name="bannerfile"
                          src={state.bannerFiles[0].preview}
                        />
                      </div>
                    ) : null}
                    <div style={{ paddingTop: '16px' }}>
                      <i
                        className="far fa-file-image  fieldHorizontalPadding paddingLeftClear"
                        aria-hidden="true"
                      />
                      <Typography>Upload A New Banner</Typography>
                      <div>
                        <FileUpload
                          caption={
                            'The file must be a .png, 900px wide, 300px high, and less than 1MB'
                          }
                          onDrop={handleFileUpload}
                        />
                      </div>
                    </div>
                  </Stack>
                }
              />
            </Stack>

            {[
              {
                id: 'preventEndingRentals',
                label: 'Prevent ending Rentals',
                description:
                  'Check this option if you do not want a user to be able to end a rental in this location.',
                checked: state.preventEndingRentals,
              },
              {
                id: 'preventStartingRentals',
                label: 'Prevent starting Rentals',
                description:
                  'Check this option if you do not want a user to be able to start a rental in this location.',
                checked: state.preventStartingRentals,
              },
            ].map(({ checked, description, id, label }) => (
              <MovaticFieldGroupCheck
                key={id}
                label={label}
                description={description}
                id={id}
                name={id}
                onChange={onChangeValue}
                checked={checked}
              />
            ))}

            {systemRates.length > 0 && system.new_hardware && (
              <MovaticFieldGroupMultiSelect
                label="Assigned Rates"
                description="Select optional rates for this location."
                value={state.componentRates}
                onChange={(event: React.SyntheticEvent, newValue: any[]) => {
                  setState((state) => ({
                    ...state,
                    selectedRates: newValue.map((rate) => rate.value),
                    componentRates: newValue,
                  }));
                }}
                options={systemRates}
              />
            )}

            {location()}
          </div>
        )}
      </div>
    );
  };

  // the user start editing the polygon
  const handleEditPolygonStart = () => {
    setState((state) => ({ ...state, unsavedChanges: true }));
  };

  // the user either save or cancel the current changes
  const handleEditPolygonStop = () => {
    setState((state) => ({ ...state, unsavedChanges: false }));
  };

  return (
    <PageContainer
      isLoading={!systemLoaded || isLoadingStations || !state?.detailedStation?.id}
    >
      <CardView
        content={
          <Stack px={2} pb={2}>
            {content()}
          </Stack>
        }
        title={`Edit ${modeInfo?.details?.stationsTitle}`}
        headerActions={button()}
      />
      <MovaticCustomModal
        open={state.showModal}
        onClose={toggleModal}
        content={`Warning: Maintenance mode will make active ${modeInfo.details.unitsTitle.toLowerCase()} in this station non-accessible by users`}
        title={`${modeInfo.details.unitsTitle} at Location`}
        action={
          <IconButtonMenu
            buttons={[
              {
                label: 'Cancel',
                onClick: toggleModal,
              },
              {
                label: 'Save',
                onClick: () => {
                  click();
                  toggleModal();
                },
                startIcon: <SaveIcon />,
              },
            ]}
          />
        }
      />
    </PageContainer>
  );
};

export default connect(
  (state: {
    admin: {
      systemAccess: number;
      admin: {
        admin: {
          movaticAccess: number;
          partner: number;
        };
      };
    };
    system: {
      current: ISystem;
      isLoaded: boolean;
    };
    mode: IMode;
    station: {
      stations: IStation[];
    };
    module: {
      modules: { mac: string; state: number }[];
    };
  }) => ({
    stations: state.station.stations,
    systemAccess: state.admin.systemAccess,
    system: state.system.current,
    modeInfo: state.mode,
    systemLoaded: state.system.isLoaded,
    partner: state.admin.admin.admin.partner,
    movaticAccess: state.admin.admin.admin.movaticAccess,
  }),
  (dispatch: Function) => ({
    getAllStations: () => dispatch(getAllStations()),
  })
)(StationEdit);
