import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useStation from '../../hooks/stations/query/useStation';
import { IStation } from '../../ts/interface/station.interface';
import { DEFAULT_ROWS_PER_PAGE, LOCKER } from '../../constants';
import { changeTab, findAccess, history } from '../../utils';
import { connect } from 'react-redux';
import { stationConfigurations } from '../../util/stations';
import { makeColumnsV2 } from '../../tableColumns/stationColumns';
import useSearchDebounce from '../../hooks/search/useSearchDebounce';
import { StationProps } from '../../ts/interface/pages/stations.interfaces';
import PageContainer from '../../components/Redesing/page-container';
import AddIcon from '@mui/icons-material/Add';
import { PaginationTable } from '../../components/Redesing/table/pagination-table';
import {
  IColumn,
  ISortConfig,
} from '../../ts/interface/components/redesing/table.interface';
import Card from '@mui/material/Card';
import { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { SearchChip } from '../../ts/interface/components/table.interface';
import { useUpdateEffect } from '../../hooks/use-update-effect';
import { MovaticFieldGroupSingleSelectFilter } from '../../components/Redesing/movatic-field-group';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useParams } from 'react-router-dom';
import PageContent from '../../components/Redesing/page-content';
import StationLocationsMap from './stationLocationsMap';
import useUrlState from '../../hooks/use-url-state';
import { LocationStatus } from '../../ts/enums';

const STATION_STATUS = [
  { value: LocationStatus.all, label: 'All' },
  { value: LocationStatus.active, label: 'Active' },
  { value: LocationStatus.maintenance, label: 'Maintenance' },
  { value: LocationStatus.retired, label: 'Retired' },
  { value: LocationStatus.unassigned, label: 'Unassigned' },
];

const Station = ({
  system,
  movaticAccess,
  modeInfo,
  systemAccess,
  systemLoaded,
}: StationProps) => {
  const [tableStations, setTableStations] = useState<IStation[]>([]);
  const [stationLoaded, setStationLoaded] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectFilter, setSelectFilter] = useState<string>(STATION_STATUS[0].value);
  const [exportData, setExportData] = useState<boolean>(false);
  const { currentTab = 'locations' } = useParams<{ currentTab: string }>();
  const [currentTabV, setCurrentTabV] = useState(currentTab);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [refectData, setRefectData] = useState(false);
  const [pageState, setPageState, isComplete, isRestoring] = useUrlState({
    sizePerPage: DEFAULT_ROWS_PER_PAGE,
    status: STATION_STATUS[0].value as LocationStatus | string,
    pageNumber: 0,
    lastId: null as string | null,
    search: null as string | null,
    sortConfig: {
      key: 'name',
      direction: 'asc',
    } as ISortConfig,
  });
  const debouncedSearchTerm = useSearchDebounce(pageState.search);

  const handleTabsChange = useCallback(
    (event: React.SyntheticEvent, value: React.SetStateAction<string>) => {
      changeTab(value, currentTab, 'locations');
    },
    [currentTab]
  );

  useEffect(() => {
    if (currentTab !== currentTabV) {
      setCurrentTabV(currentTab);
    }
  }, [currentTab, currentTabV]);

  const [chips, setChips] = useState<SearchChip[]>([
    {
      label: 'Status',
      field: 'status',
      value: STATION_STATUS[0].value,
      displayValue: STATION_STATUS[0].label,
    },
  ]);

  useUpdateEffect(() => {
    if (isRestoring) {
      setRefectData(!refectData);
      setPageState((prev) => ({
        ...prev,
        pageNumber: 0,
        sizePerPage: DEFAULT_ROWS_PER_PAGE,
        status: STATION_STATUS[0].value,
      }));
    }
  }, [isRestoring]);

  const pageNumberMemo = useMemo(() => pageState.pageNumber, [pageState.pageNumber]);

  const { refetch } = useStation(
    {
      enabled: false,
    },
    {
      limit:
        pageState.lastId && isComplete && isRestoring
          ? DEFAULT_ROWS_PER_PAGE
          : pageState.sizePerPage,
      lastId: pageState.lastId && isComplete && isRestoring ? null : pageState.lastId,
      status: pageState.status,
      pattern: debouncedSearchTerm,
      system_id: system.id,
      order: pageState.sortConfig.direction,
      pageNumber: pageNumberMemo,
    }
  );

  const hasMoreMemo = useMemo(() => {
    const { sizePerPage, pageNumber } = pageState;
    return !Boolean(hasMore) && tableStations?.length >= sizePerPage * (pageNumber + 1)
      ? true
      : hasMore;
  }, [hasMore, tableStations, pageState]);

  useUpdateEffect(() => {
    if (isComplete && systemLoaded) {
      setStationLoaded(false);
      setLoading(true);
      refetch().then((res) => {
        if (res.status === 'success') {
          const { data, has_more } = res.data;
          setHasMore(has_more);
          if (pageState.lastId) {
            if (data) setTableStations((stations) => [...stations, ...data]);
          } else {
            setTableStations(data);
          }
          setStationLoaded(true);
        }
      });
      setLoading(false);
    }
  }, [
    pageState.lastId,
    pageState.sizePerPage,
    pageState.sortConfig.direction,
    pageState.status,
    debouncedSearchTerm,
    systemLoaded,
    isComplete,
    refetch,
  ]);

  const formatStations = (stations: IStation[]) => {
    return stations.map((station) => {
      const available = station.available_hardware || 0;
      const capacity =
        modeInfo.details.hardware_type !== LOCKER ||
        station.configuration === stationConfigurations.ZONE
          ? station.capacity
          : station.totalHardware || 0;
      return { ...station, available, capacity };
    });
  };

  const handleChipDelete = useCallback(
    (deletedGroup: SearchChip) => {
      setChips([]);
      setSelectFilter(STATION_STATUS[0].value);
      setPageState((prev) => ({
        ...prev,
        pageNumber: 0,
        sizePerPage: DEFAULT_ROWS_PER_PAGE,
        status: '',
        lastId: null,
      }));
    },
    [setChips, setSelectFilter, setPageState]
  );

  const handleTypeChange = useCallback(
    (selectedOption: { value: string; label: string }): void => {
      setPageState((prev) => ({
        ...prev,
        pageNumber: 0,
        sizePerPage: DEFAULT_ROWS_PER_PAGE,
        status: selectedOption.value as LocationStatus,
        lastId: null,
      }));
      setChips([
        {
          label: 'Status',
          field: 'status',
          value: selectedOption.value,
          displayValue: selectedOption!.label,
        },
      ]);
    },
    [setPageState, setChips]
  );

  return (
    <>
      <PageContainer isLoading={!systemLoaded} spaceName={'Locations'}>
        <PageContent
          dataId="locations"
          detailedView={false}
          tabsList={[
            { label: 'Locations', value: 'locations' },
            {
              label: 'Map',
              value: 'map',
            },
          ]}
          tabsChildren={
            <>
              {currentTabV === 'locations' && (
                <Card>
                  <PaginationTable
                    dataId="locations-list"
                    isLoading={!stationLoaded || !systemLoaded || loading}
                    buttons={[
                      {
                        label: 'Export',
                        onClick: () => setExportData(true),
                        startIcon: <FileDownloadIcon />,
                      },
                      {
                        startIcon: <AddIcon />,
                        label: 'Add',
                        onClick: () => history.push('/locations/add'),
                        disabled:
                          !findAccess(systemAccess).includes('physical') ||
                          !!movaticAccess,
                      },
                    ]}
                    exportData={exportData}
                    localSearch={false}
                    csvFileName={'Stations.csv'}
                    onExport={() => setExportData(false)}
                    chips={chips}
                    handleChipDelete={handleChipDelete}
                    spaceFilters={
                      <>
                        <MovaticFieldGroupSingleSelectFilter
                          dataId={`location-status-filter`}
                          label={'Status'}
                          value={selectFilter.toString()}
                          onChange={(e: SelectChangeEvent) => {
                            const id = e.target.value as string;
                            setSelectFilter(id);
                          }}
                          options={STATION_STATUS.map((option, index) => (
                            <MenuItem
                              id={`location-status-dropdown-${index}`}
                              key={index}
                              value={option.value}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        />
                      </>
                    }
                    onApplyFilter={() => {
                      const obj = STATION_STATUS.find(
                        (item) => item.value === selectFilter
                      ) || {
                        value: 'all',
                        label: 'All',
                      };
                      handleTypeChange(obj);
                    }}
                    showFilter
                    onRowCLick={(id: string) => history.push(`/locations/${id}/general`)}
                    sortBy={pageState.sortConfig.key}
                    sortDirection={'asc'}
                    onSortChange={(sort) => {
                      setPageState((prev) => ({
                        ...prev,
                        pageNumber: 0,
                        sizePerPage: DEFAULT_ROWS_PER_PAGE,
                        sortConfig: {
                          key: sort.key,
                          direction: sort.direction as 'asc' | 'desc',
                        },
                        lastId: null,
                      }));
                      setTableStations([]);
                    }}
                    padding={{ pt: 1, pr: 1 }}
                    columns={makeColumnsV2(system) as IColumn[]}
                    items={formatStations(tableStations || [])}
                    searchPlaceholder={`Search Locations`}
                    page={pageState.pageNumber}
                    rowsPerPage={pageState.sizePerPage}
                    hasMore={hasMoreMemo}
                    totalItems={tableStations.length}
                    onPageChange={(page, newPage) => {
                      const pageNumberAux = newPage + 1;
                      if (tableStations?.length / pageState.sizePerPage < pageNumberAux) {
                        setPageState((prev) => ({
                          ...prev,
                          pageNumber: newPage,
                          lastId: tableStations[tableStations.length - 1].id,
                        }));
                        return;
                      }
                      setPageState((prev) => ({
                        ...prev,
                        pageNumber: newPage,
                      }));
                    }}
                    onRowsPerPageChange={(pageSizeString) =>
                      setPageState((prev) => ({
                        ...prev,
                        sizePerPage: Number(pageSizeString.target.value),
                      }))
                    }
                    handleSearch={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setPageState((prev) => ({
                        ...prev,
                        search: e.target.value,
                      }))
                    }
                    searchText={pageState.search || ''}
                  />
                </Card>
              )}
              {currentTabV === 'map' && <StationLocationsMap />}
            </>
          }
          currentTabV={currentTabV}
          handleTabsChange={handleTabsChange}
        />
      </PageContainer>
    </>
  );
};

const StationR = connect(
  (state: any) => ({
    system: state.system.current,
    auth: state.admin.admin,
    systemAccess: state.admin.systemAccess,
    modeInfo: state.mode,
    systemLoaded: state.system.isLoaded,
  }),
  () => ({})
)(Station);

export default StationR;
