import { Component } from 'react';
import { connect } from 'react-redux';

import AccountVerification from './billing/cardAccountVerification';
import BankAccountCard from './billing/cardBankAccount';
import BillingCard from './billing/cardBilling';
import MaxThresholdCard from './billing/cardMaxThreshold';
import MinimumWalletBalanceCard from './billing/cardMinimumWalletBalance';
import CreditCardHoldCard from './billing/cardCreditCardHold';
import StatementBillingDescriptor from './billing/cardStatementBillingDescriptor';

import {
  clearBankInfo,
  createStripeAccount,
  getSetBankInfo,
  update,
  updateSystemSettings,
} from '../../actionCreators/system.js';
import { gotError } from '../../actionCreators/toast';
import { findAccess } from '../../utils.jsx';
import Qvo from '../../components/qvoComponent.jsx';
import { Stack } from '@mui/system';

class BillingInfo extends Component {
  constructor(props) {
    super(props);

    const { custom_statement_billing_descriptor } = this.props.system;
    const { billing, currencyCode, country, tax_rate } = this.props.detailedSystem;
    const { bankName, accountLast4, routingNumber } = this.props.bankInfo;
    const { stripeAccountInfo } = this.props;

    this.state = {
      accountLast4,
      accountNumber: null,
      bank: false,
      bankName,
      billing,
      country,
      currencyCode,
      edit: false,
      editBankAccount: false,
      maxChargeThreshold: null,
      creditCardHold: null,
      minimumWalletDeposit: null,
      routingNumber,
      stripeAccountInfo,
      tax_rate,
      statementBillingDescriptor:
        custom_statement_billing_descriptor ||
        stripeAccountInfo?.settings?.payments?.statement_descriptor,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeById = this.handleChangeById.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
    this.submit = this.submit.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
  }

  componentDidMount() {
    if (this.props.systemLoaded) {
      this.props.getAndSetBankInfo();

      this.setState({
        maxChargeThreshold: this.props.system.max_charge_threshold / 100 || '',
        creditCardHold: Number.isInteger(this.props.system.credit_card_hold)
          ? this.props.system.credit_card_hold / 100
          : null,
        minimumWalletDeposit: Number.isInteger(this.props.system.minimum_wallet_deposit)
          ? this.props.system.minimum_wallet_deposit / 100
          : null,
      });
    }

    const access = this.props.systemAccess;
    const adminAccess = findAccess(access);

    adminAccess.forEach((eachAccess) => {
      if (eachAccess === 'bank') {
        this.setState({
          bank: true,
        });
      }
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.systemLoaded && nextProps.systemLoaded) {
      this.props.getAndSetBankInfo();
    }

    const { bankName, accountLast4, routingNumber, currencyCode, tax_rate } = this.state;

    if (bankName !== nextProps.bankInfo.bankName && nextProps.bankInfo.bankName) {
      this.setState({ bankName: nextProps.bankInfo.bankName });
    }

    if (
      accountLast4 !== nextProps.bankInfo.accountLast4 &&
      nextProps.bankInfo.accountLast4
    ) {
      this.setState({ accountLast4: nextProps.bankInfo.accountLast4 });
    }

    if (
      routingNumber !== nextProps.bankInfo.routingNumber &&
      nextProps.bankInfo.routingNumber
    ) {
      this.setState({ routingNumber: nextProps.bankInfo.routingNumber });
    }

    if (
      nextProps.detailedSystem.currencyCode !== currencyCode ||
      nextProps.detailedSystem.tax_rate !== tax_rate
    ) {
      this.setState({
        currencyCode: nextProps.detailedSystem.currencyCode,
        tax_rate: nextProps.detailedSystem.tax_rate,
      });
    }

    if (nextProps.stripeAccountInfo) {
      this.setState({
        stripeAccountInfo: nextProps.stripeAccountInfo,
      });
    }

    if (this.props.systemAccess !== nextProps.systemAccess) {
      const access = nextProps.systemAccess;
      const adminAccess = findAccess(access);
      adminAccess.forEach((eachAccess) => {
        if (eachAccess === 'bank') {
          this.setState({
            bank: true,
          });
        }
      });
    }

    if (
      this.props.system.max_charge_threshold !== nextProps.system.max_charge_threshold
    ) {
      this.setState({
        maxChargeThreshold: nextProps.system.max_charge_threshold / 100 || '',
      });
    }

    if (this.props.system.credit_card_hold !== nextProps.system.credit_card_hold) {
      this.setState({
        creditCardHold: Number.isInteger(nextProps.system.credit_card_hold)
          ? nextProps.system.credit_card_hold / 100
          : null,
      });
    }

    if (
      this.props.system.minimum_wallet_deposit !== nextProps.system.minimum_wallet_deposit
    ) {
      this.setState({
        minimumWalletDeposit: Number.isInteger(nextProps.system.minimum_wallet_deposit)
          ? nextProps.system.minimum_wallet_deposit / 100
          : null,
      });
    }

    if (
      this.props.system.custom_statement_billing_descriptor !==
      nextProps.system.custom_statement_billing_descriptor
    ) {
      this.setState({
        statementBillingDescriptor: nextProps.system.custom_statement_billing_descriptor,
      });
    }
  }

  toggleEdit(name) {
    this.setState({
      [name]: !this.state[name],
    });
  }

  handleCurrencyChange(event) {
    this.setState(
      {
        currencyCode: event.target.value,
      },
      this.props.isChanged(true)
    );
  }

  handleChangeById(event) {
    let value =
      event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    if (event.target.name === 'edit') value = !this.state.edit;

    if (value === true || value === false) {
      this.setState(
        {
          [event.target.name]: value,
        },
        this.props.isChanged(true)
      );
    }
    this.setState(
      {
        [event.target.id]: value,
      },
      this.props.isChanged(true)
    );
  }

  handleChange(event) {
    let value =
      event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    if (event.target.name === 'edit') value = !this.state.edit;

    this.setState(
      {
        [event.target.name]: value,
      },
      this.props.isChanged(true)
    );
  }

  submit(info) {
    if (info.card === 'billingSettings') {
      const updatedSystem = { ...this.props.detailedSystem, ...info };

      this.props.isChanged(false);
      this.props.updateSystem(updatedSystem);
    }

    if (info.card === 'maxChargeThreshold') {
      if (info.maxChargeThreshold === null && !isNaN(Number(info.maxChargeThreshold))) {
        this.props.sendError('Max Charge Threshold must have a value to submit');

        return;
      }

      const updatedSystem = {
        ...this.props.detailedSystem,
        max_charge_threshold: Number(info.maxChargeThreshold) * 100,
      };

      this.props.isChanged(false);
      this.props.updateSystem(updatedSystem);
      this.setState({
        maxChargeThreshold: info.maxChargeThreshold,
      });
    }

    if (info.card === 'creditCardHold') {
      if (info.creditCardHold && Math.round(info.creditCardHold * 100) < 0) {
        this.props.sendError(
          'Please use a positive number (or leave empty) for the credit card hold value.'
        );

        return;
      }

      const credit_card_hold = info.creditCardHold
        ? Math.round(info.creditCardHold * 100, 10)
        : null;
      const updatedSystem = {
        ...this.props.detailedSystem,
        credit_card_hold,
      };

      this.props.isChanged(false);
      this.props.updateSystem(updatedSystem);
      this.setState({
        creditCardHold: Number.isInteger(credit_card_hold)
          ? credit_card_hold / 100
          : null,
      });
    }

    if (info.card === 'minimumWalletDeposit') {
      if (info.minimumWalletDeposit && Math.round(info.minimumWalletDeposit * 100) < 0) {
        this.props.sendError(
          'Please use a positive number (or leave empty) for the minimum wallet balance value.'
        );

        return;
      }

      const minimum_wallet_deposit = info.minimumWalletDeposit
        ? Math.round(info.minimumWalletDeposit * 100, 10)
        : null;
      const updatedSystem = {
        ...this.props.detailedSystem,
        minimum_wallet_deposit,
      };

      this.props.isChanged(false);
      this.props.updateSystem(updatedSystem);
      this.setState({
        minimumWalletDeposit: Number.isInteger(minimum_wallet_deposit)
          ? minimum_wallet_deposit / 100
          : null,
      });
    }

    if (info.card === 'statementBillingDescriptor') {
      const customStatement = info.statementBillingDescriptor.trim() || null;
      const updatedSystem = {
        ...this.props.detailedSystem,
        custom_statement_billing_descriptor: customStatement,
      };
      this.props.isChanged(false);
      this.props.updateSystem(updatedSystem);
      this.setState({
        statementBillingDescriptor:
          customStatement ||
          this.props.stripeAccountInfo?.settings?.payments?.statement_descriptor ||
          '',
      });
    }
  }

  componentWillUnmount() {
    this.props.removeBankInfo();
  }

  render() {
    const { system } = this.props;
    const systemUsesWallets = system.billing && system.wallet_required;

    return this.props.detailedSystem.payment_provider === 'stripe' ||
      this.props.detailedSystem.payment_provider === null ? (
      <Stack spacing={3}>
        {/*<div>*/}
        {/* BILLING SETTINGS */}
        <BillingCard
          handleCurrencyChange={this.handleCurrencyChange}
          saveButtonLoading={this.props.saveButtonLoading}
          info={this.state}
          handleChange={this.handleChangeById}
          submit={this.submit}
          bankAccess={this.state.bank}
          movaticAccess={this.props.auth.admin.admin.movaticAccess}
        />

        {/* BANK ACCOUNT */}
        {this.props.system.payment_provider === 'stripe' ||
        this.props.system.payment_provider === null ? (
          <BankAccountCard
            systemLoaded={this.props.saveButtonLoading}
            bankAccess={this.state.bank}
            movaticAccess={this.props.auth.admin.admin.movaticAccess}
            detailedSystem={this.props.detailedSystem}
          />
        ) : null}

        {/* MAX CHARGE THRESHOLD */}
        <MaxThresholdCard
          info={this.state}
          handleChange={this.handleChangeById}
          saveButtonLoading={this.props.saveButtonLoading}
          submit={this.submit}
          maxChargeThreshold={this.state.maxChargeThreshold}
          toggleEdit={this.toggleEdit}
        />

        {systemUsesWallets ? (
          <MinimumWalletBalanceCard
            handleChange={this.handleChangeById}
            saveButtonLoading={this.props.saveButtonLoading}
            submit={this.submit}
            minimumWalletDeposit={this.state.minimumWalletDeposit}
            isLoaded={this.props.systemLoaded}
          />
        ) : null}

        {system.billing ? (
          <CreditCardHoldCard
            handleChange={this.handleChangeById}
            saveButtonLoading={this.props.saveButtonLoading}
            submit={this.submit}
            creditCardHold={this.state.creditCardHold}
            isLoaded={this.props.systemLoaded}
          />
        ) : null}

        {/* ACCOUNT VERIFICATION */}
        {this.props.detailedSystem.payment_provider === 'stripe' ||
        this.props.detailedSystem.payment_provider === null ? (
          <AccountVerification
            saveButtonLoading={this.props.saveButtonLoading}
            country={this.props.detailedSystem.country}
            createStripeAccount={this.props.createStripeAccount}
            bankAccess={this.state.bank}
            movaticAccess={this.props.auth.admin.admin.movaticAccess}
          />
        ) : null}

        {/* CUSTOM STATEMENT BILLING DESCRIPTOR */}
        {this.props.detailedSystem.payment_provider === 'stripe' ||
        this.props.detailedSystem.payment_provider === null ? (
          <StatementBillingDescriptor
            movaticAccess={this.props.auth.admin.admin.movaticAccess}
            saveButtonLoading={this.props.saveButtonLoading}
            bankAccess={this.state.bank}
            handleChange={this.handleChangeById}
            handleSubmit={this.submit}
            statementBillingDescriptor={this.state.statementBillingDescriptor}
          />
        ) : null}
        {/*</div>*/}
      </Stack>
    ) : (
      <Qvo
        tabName="Billing"
        provider={this.props.detailedSystem.payment_provider}
        needsCard
      />
    );
  }
}

export default connect(
  (state) => ({
    saveButtonLoading: state.system.isLoaded,
    admin: state.admin.admin.admin.movaticAccess,
    systemAccess: state.admin.systemAccess,
    detailedSystem: state.system.current,
    auth: state.admin,
    stripeAccountInfo: state.system.stripeAccountInfo,
    bankInfo: state.system.bankInfo,
    systemId: state.system.current.id,
    system: state.system.current,
    systemLoaded: state.system.isLoaded,
    stripeInfoLoaded: state.system.stripeInfoLoaded,
  }),
  (dispatch) => ({
    getAndSetBankInfo() {
      dispatch(getSetBankInfo());
    },
    createStripeAccount(businessType) {
      return dispatch(createStripeAccount(businessType));
    },
    updateSystem(info) {
      dispatch(updateSystemSettings(info));
    },
    sendError(error) {
      dispatch(gotError(error));
    },
    removeBankInfo() {
      dispatch(clearBankInfo());
    },
    update(loading) {
      dispatch(update(loading));
    },
  })
)(BillingInfo);
