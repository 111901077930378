import type { FC } from 'react';

export const Logo: FC = () => {
  return (
    <img
      style={{ height: '100%', maxWidth: '150px' }}
      src={'/assets/movatic_LOGO-06.png'}
      alt={'movatic-logo'}
    />
  );
};
