import React from 'react';

const NoFilePlaceHolder = () => {
  return (
    <img
      alt=""
      className="logoImg"
      src={`${process.env.PUBLIC_URL}/img/no-file-palce-holder.png`}
    />
  );
};

export default NoFilePlaceHolder;
