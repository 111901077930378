import React, { Component } from 'react';
import Card from './Card.jsx';

export default class Qvo extends Component {
  constructor(props) {
    super(props);

    this.content = this.content.bind(this);
  }

  content() {
    let provider = 'this payment provider';

    if (this.props.provider === 'qvo') {
      provider = 'QVO';
    } else if (this.props.provider === 'peach') {
      provider = 'Peach Payments';
    }

    return (
      <div className="cardBody darkText">
        {this.props.tabName} is not yet implemented for
        {provider}.
      </div>
    );
  }

  render() {
    if (this.props.needsCard) {
      return <Card content={this.content()} loaded detailedView={false} />;
    }

    return this.content();
  }
}
