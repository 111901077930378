import React, { useMemo, useState } from 'react';
import usePendingCharges from '../../hooks/rentals/query/usePendingCharges';
import { makePendingChargesColumnsV2 } from '../../tableColumns/pendingChargesColumns';
import { history } from '../../utils';
import PendingChargesModal from './pendingChargesModal';
import { connect } from 'react-redux';
import { PaginationTable } from '../../components/Redesing/table/pagination-table';
import Card from '@mui/material/Card';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants';

const PendingCharges = ({ systemLoaded }: { systemLoaded: boolean }) => {
  const { data = [] } = usePendingCharges({
    enabled: systemLoaded,
  });
  const [openModal, setOpenModal] = useState(false);
  const [selectedRentalId, setSelectedRentalId] = useState<string>('');
  const [selectedRentalAmount, setSelectedRentalAmount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_ROWS_PER_PAGE);
  const [sortData, setSortData] = useState<string>('created_on');
  const [search, setSearch] = React.useState('');
  const [exportData, setExportData] = useState<boolean>(false);

  const handleModal = (rentalId: string, rentalAmount: number) => {
    setOpenModal(!openModal);
    setSelectedRentalId(rentalId);
    setSelectedRentalAmount(rentalAmount);
  };

  const pendingCharges = useMemo(() => {
    return data;
  }, [data]);

  return (
    <Card>
      <div>
        <PaginationTable
          padding={{ pt: 1, pl: 0, pr: 1 }}
          exportData={exportData}
          csvFileName={'Pending_Charges.csv'}
          onExport={() => setExportData(false)}
          buttons={[
            {
              label: 'Export',
              startIcon: <FileDownloadIcon />,
              onClick: () => setExportData(true),
            },
          ]}
          customKey={'gid'}
          sortBy={sortData}
          onSortChange={(sortBy) => setSortData(sortBy.key)}
          items={pendingCharges}
          noDataText={`Good news! No pending charges yet`}
          onRowCLick={(id: string) => history.push(`/rentals/${id}`)}
          columns={makePendingChargesColumnsV2(handleModal)}
          rowsPerPage={pageSize}
          page={pageNo}
          onPageChange={(page, pageN: number) => {
            setPageNo(pageN);
          }}
          onRowsPerPageChange={(rowsPerPage) =>
            setPageSize(Number(rowsPerPage.target.value))
          }
          searchText={search}
          searchPlaceholder={`Search pending charges`}
          handleSearch={(search) => setSearch(search.target.value)}
        />
        <PendingChargesModal
          rentalId={selectedRentalId}
          rentalAmount={selectedRentalAmount}
          show={openModal}
          handleModal={handleModal}
        />
      </div>
    </Card>
    // <Card
    //   customClass="unsetBorders"
    //   loaded={!isLoading && systemLoaded}
    //   content={
    //     <div>
    //             <PaginationTable
    //                 padding={{
    //                     pl: -1,
    //                     pt: 2,
    //                 }}
    //                 customKey={'gid'}
    //                 sortBy={sortData}
    //                 onSortChange={(sortBy) => setSortData(sortBy)}
    //                 items={data}
    //                 noDataText={`Good news! No pending charges yet`}
    //                 onRowCLick={(id: string) => history.push(`/rentals/${id}`)}
    //                 columns={makePendingChargesColumnsV2(handleModal)}
    //                 rowsPerPage={pageSize}
    //                 page={pageNo}
    //                 onPageChange={(page, pageN: number) => {
    //                     setPageNo(pageN);
    //                 }}
    //                 onRowsPerPageChange={(rowsPerPage) =>
    //                     setPageSize(Number(rowsPerPage.target.value))
    //                 }
    //                 searchText={search}
    //                 searchPlaceholder={`Search pending charges`}
    //                 handleSearch={(search) => setSearch(search.target.value)}
    //             />
    //       {/*  <TableComponent*/}
    //       {/*  addButtonState*/}
    //       {/*  tableInfo={{*/}
    //       {/*    data: data,*/}
    //       {/*    columns: makePendingChargesColumns(handleModal),*/}
    //       {/*    csv: 'pendingCharges.csv',*/}
    //       {/*    noDataText: 'Good news! No pending charges yet',*/}
    //       {/*    defaultSearch: '',*/}
    //       {/*    page: pageNo,*/}
    //       {/*    pageSize: pageSize,*/}
    //       {/*    pageChange: (pageNumber: number) => setPageNo(pageNumber),*/}
    //       {/*    pageSizeChange: (pageSize: number) => setPageSize(pageSize),*/}
    //       {/*  }}*/}
    //       {/*  rowClick={(row: IRental) => history.push(`/rentals/${row.gid}`)}*/}
    //       {/*/>*/}
    //       <PendingChargesModal
    //         rentalId={selectedRentalId}
    //         rentalAmount={selectedRentalAmount}
    //         show={openModal}
    //         handleModal={handleModal}
    //       />
    //     </div>
    //   }
    // />
  );
};

export default connect(
  (state: { system: { isLoaded: boolean } }) => ({
    systemLoaded: state.system.isLoaded,
  }),
  () => ({})
)(PendingCharges);
