import {
  // ACTIVE RENTAL FILTERS
  RSAVE_END_DATE,
  RSAVE_START_DATE,
  RSAVE_STRING_DATES,
  RSAVE_FILTERED_DATES,
  RCLEAR_FILTER_DATES,
  // COMPLETED RENTAL FILTERS
  RCSAVE_START_DATE,
  RCSAVE_END_DATE,
  RCCLEAR_FILTER_DATES,
  RCSAVE_FILTERED_DATES,
  // TRANSACTION FILTERS
  TSAVE_START_DATE,
  TSAVE_END_DATE,
  TSAVE_STRING_DATES,
  TCLEAR_FILTER_DATES,
  TSAVE_FILTERED_DATES,
  // RESERVATION FILTERS
  RES_SAVE_START_DATE,
  RES_SAVE_END_DATE,
  RES_SAVE_STRING_DATES,
  RES_CLEAR_FILTER_DATES,
  RES_SAVE_FILTERED_DATES,
} from '../constants';

const initialState = {
  // clear
  clear: true,
  completedClear: true,
  // ACTIVE USER FILTER STATE
  sDate: '',
  eDate: '',
  sValue: '',
  eValue: '',
  stringActiveDates: [],
  filteredDates: [],
  // COMPLETED USER FILTER STATE
  sCDate: '',
  eCDate: '',
  sCValue: '',
  eCValue: '',
  stringCompletedDates: [],
  filteredCDates: [],
  // TRANSACTIONS FILTER STATE
  sTDate: '',
  eTDate: '',
  sTValue: '',
  eTValue: '',
  tStringDates: [],
  tFilteredDates: [],
  // RESERVATION FILTER STATE
  sRDate: '',
  eRDate: '',
  sRValue: '',
  eRValue: '',
  stringRDates: [],
  filteredRDates: [],
};

export default function filtersReducer (state = initialState, action) {
  const newState = { ...state };
  switch (action.type) {
    case RSAVE_STRING_DATES:
      newState.stringActiveDates = action.stringActiveDates;
      newState.stringCompletedDates = action.stringCompletedDates;
      newState.stringReservationDates = action.stringReservationDates;
      break;

    // FOR ACTIVE RENTALS
    case RSAVE_START_DATE:
      newState.sDate = action.sDate;
      newState.sValue = action.sValue;
      break;

    case RSAVE_END_DATE:
      newState.eDate = action.eDate;
      newState.eValue = action.eValue;
      break;

    case RSAVE_FILTERED_DATES:
      newState.filteredDates = action.filteredDates;
      newState.clear = false;
      break;

    case RCLEAR_FILTER_DATES:
      newState.sDate = '';
      newState.eDate = '';
      newState.sValue = null;
      newState.eValue = null;
      newState.filteredDates = [];
      newState.clear = true;
      break;

    // FOR COMPLETED RENTALS
    case RCSAVE_START_DATE:
      newState.sCDate = action.sCDate;
      newState.sCValue = action.sCValue;
      break;

    case RCSAVE_END_DATE:
      newState.eCDate = action.eCDate;
      newState.eCValue = action.eCValue;
      break;

    case RCSAVE_FILTERED_DATES:
      newState.filteredCDates = action.filteredCDates;
      newState.completedClear = false;
      break;

    case RCCLEAR_FILTER_DATES:
      newState.sCDate = '';
      newState.eCDate = '';
      newState.sCValue = null;
      newState.eCValue = null;
      newState.filteredCDates = [];
      newState.completedClear = true;
      break;

    // FOR TRANSACTION FILTERS
    case TSAVE_START_DATE:
      newState.sTDate = action.sTDate;
      newState.sTValue = action.sTValue;
      break;

    case TSAVE_END_DATE:
      newState.eTDate = action.eTDate;
      newState.eTValue = action.eTValue;
      break;

    case TSAVE_STRING_DATES:
      newState.tStringDates = action.tStringDates;
      break;

    case TSAVE_FILTERED_DATES:
      newState.tFilteredDates = action.tFilteredDates;
      newState.clear = false;
      break;

    case TCLEAR_FILTER_DATES:
      newState.sTDate = '';
      newState.eTDate = '';
      newState.sTValue = null;
      newState.eTValue = null;
      newState.tFilteredDates = [];
      newState.clear = true;
      break;

    // FOR RESERVATION FILTERS
    case RES_SAVE_START_DATE:
      newState.sRDate = action.sRDate;
      newState.sRValue = action.sRValue;
      break;

    case RES_SAVE_END_DATE:
      newState.eRDate = action.eRDate;
      newState.eRValue = action.eRValue;
      break;

    case RES_SAVE_STRING_DATES:
      newState.stringRDates = action.stringRDates;
      break;

    case RES_SAVE_FILTERED_DATES:
      newState.filteredRDates = action.filteredRDates;
      newState.clear = false;
      break;

    case RES_CLEAR_FILTER_DATES:
      newState.sRDate = '';
      newState.eRDate = '';
      newState.sRValue = null;
      newState.eRValue = null;
      newState.filteredRDates = [];
      newState.clear = true;
      break;

    default:
      return state;
  }

  return newState;
}
