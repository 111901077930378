import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { SeverityPill } from './severity-pill';
import { Menu, Paper } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  IStatePills,
  OpenMenuState,
} from '../../ts/interface/components/redesing/state-pills.interface';
import { SeverityPillColor } from '../../ts/types/settings';

const StatePills: React.FC<IStatePills> = (props) => {
  const { row, handleChangeState, stateOptions, mapState } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const [openMenu, setOpenMenu] = useState<OpenMenuState>({});

  const statePillsId = props?.dataId
    ? `state-pills-${props.dataId}-${row.number}`
    : `state-pills-${row.id}`;

  const handleClick = (
    event: React.SyntheticEvent<Element, Event>,
    clickedRow: { id: string }
  ) => {
    event.stopPropagation();
    const currentTarget = event.currentTarget as HTMLElement;
    if (currentTarget) {
      setAnchorEl(currentTarget);
      setOpenMenu((prevState) => ({
        ...prevState,
        [clickedRow.id]: true,
      }));
    }
  };

  const handleClose = (
    event: React.SyntheticEvent<Element, Event>,
    closedRow: { id: string }
  ) => {
    event.stopPropagation();
    setAnchorEl(undefined);
    setOpenMenu((prevState) => ({
      ...prevState,
      [closedRow.id]: false,
    }));
  };

  return (
    <SeverityPill
      id={statePillsId}
      onClick={(event: React.SyntheticEvent<Element, Event>) => {
        handleClick(event, row);
      }}
      color={
        (stateOptions.filter(
          (item) => item.value === row.state || row.state === item.stringValue
        )[0]?.color as SeverityPillColor) || 'success'
      }
    >
      <>
        {mapState ? mapState(row.state) : row.state}
        <ArrowDropDownIcon
          sx={{
            ml: 0.3,
            mb: 0.5,
            mt: 0.5,
            mr: -0.5,
            '&:hover': {
              backgroundColor: 'white',
              borderRadius: '50%',
              cursor: 'pointer',
            },
          }}
        />
        <Paper>
          <Menu
            key={row.id}
            id="dropdown-menu"
            anchorEl={anchorEl}
            onClose={(event: React.SyntheticEvent<Element, Event>) => {
              handleClose(event, row);
            }}
            open={openMenu[row.id]}
            sx={{
              zIndex: 100000,
              backgroundColor: 'transparent',
              '& .css-k7yds5-MuiPaper-root-MuiMenu-paper-MuiPopover-paper': {
                boxShadow: '0px 6px 5px rgba(0, 0, 0, 0.08)',
              },
            }}
          >
            {stateOptions
              .sort((a, b) => {
                const nameA = a.color.toLowerCase();
                const nameB = b.color.toLowerCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
              })
              .map((item, index) => (
                <MenuItem
                  id={`${row.number}-${index}-state-pill`}
                  selected={row.state === item.stringValue || row.state === item.value}
                  key={`${row.id}-${index}-state`}
                  onClick={(event) => {
                    handleClose(event, row);
                    handleChangeState(row, item);
                  }}
                >
                  <SeverityPill
                    sx={{
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                    color={(item?.color as SeverityPillColor) || 'success'}
                  >
                    {item.name}
                  </SeverityPill>
                </MenuItem>
              ))}
          </Menu>
        </Paper>
      </>
    </SeverityPill>
  );
};

export default StatePills;
