import {
  SET_NAVIGATION_STATS,
  NAVIGATION_STATS_LOADED,
  SET_DASHBOARD_STATS,
  DASHBOARD_STATS_LOADED,
  SET_GRAPH_STATS,
  GRAPH_STATS_LOADED,
} from '../constants';
import { fetchOptions, fetchPostOptions, baseUrl } from '../api/http.jsx';
import { FourOhOneError } from './system.js';
import { displayError } from '../utils';

const setNavigationStats = (stats) => ({
  type: SET_NAVIGATION_STATS,
  stats,
});

const navigationStatsLoaded = (loaded) => ({
  type: NAVIGATION_STATS_LOADED,
  loaded,
});

const setDashboardStats = (stats) => ({
  type: SET_DASHBOARD_STATS,
  stats,
});

const dashboardStatsLoaded = (loaded) => ({
  type: DASHBOARD_STATS_LOADED,
  loaded,
});

const setGraphStats = (stats) => ({
  type: SET_GRAPH_STATS,
  stats,
});

const graphStatsLoaded = (loaded) => ({
  type: GRAPH_STATS_LOADED,
  loaded,
});

export const getNavigationStats = () => (dispatch, getState) => {
  dispatch(navigationStatsLoaded(false));
  const state = getState();

  const fetchOps = fetchOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id
  );
  return fetch(`${baseUrl}/stats/navigation`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        return dispatch(setNavigationStats(response.message));
      }
      dispatch(navigationStatsLoaded(true));
      dispatch(FourOhOneError(response));
    })
    .catch((e) => {
      dispatch(navigationStatsLoaded(true));
      displayError(
        'Oops! Looks like there was an error while loading the navigation stats. Please try again later.'
      );
    });
};

export const getDashboardStats = () => (dispatch, getState) => {
  dispatch(dashboardStatsLoaded(false));
  const state = getState();

  const fetchOps = fetchOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id
  );
  return fetch(`${baseUrl}/stats/dashboard`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        return dispatch(setDashboardStats(response.message));
      }
      dispatch(dashboardStatsLoaded(true));
      dispatch(FourOhOneError(response));
    })
    .catch((e) => {
      dispatch(dashboardStatsLoaded(true));
      displayError(
        'Oops! Looks like there was an error while loading the dashboard stats. Please try again later.'
      );
    });
};

export const getGraphStats = (options) => (dispatch, getState) => {
  dispatch(graphStatsLoaded(false));
  const state = getState();

  options.offset = 0;

  const fetchOps = fetchPostOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id,
    options,
    'POST'
  );
  return fetch(`${baseUrl}/stats/graph`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        return dispatch(setGraphStats(response.message));
      }
      dispatch(graphStatsLoaded(true));
      dispatch(FourOhOneError(response));
      displayError(
        'Oops! Looks like there was an error while loading the stats. Please try again later.'
      );
    })
    .catch((e) => {
      dispatch(graphStatsLoaded(true));
    });
};
