import React, { useCallback, useMemo, useState } from 'react';
import { PaginationTable } from '../../components/Redesing/table/pagination-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { history, toTitleCase } from '../../utils';
import AddIcon from '@mui/icons-material/Add';
import { MovaticFieldGroupSelectFilter } from '../../components/Redesing/movatic-field-group';
import { taskColumnsV2 } from '../../tableColumns/maintenanceColumns';
import {
  IColumn,
  ISortConfig,
} from '../../ts/interface/components/redesing/table.interface';
import useAllCompletedTask from '../../hooks/maintenance/query/useAllCompletedTask';
import { connect } from 'react-redux';
import { ISystem } from '../../ts/interface/system.interface';
import { IMode } from '../../ts/interface/mode.interface';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants';
import { SearchChip } from '../../ts/interface/components/table.interface';
import useStation from '../../hooks/stations/query/useStation';
import { ITask } from '../../ts/interface/problem.interface';
import { IStation } from '../../ts/interface/station.interface';
import useUrlState from '../../hooks/use-url-state';
import { useUpdateEffect } from '../../hooks/use-update-effect';

interface IMaintenanceLogsProps {
  systemLoaded: boolean;
  system: ISystem;
  mode: IMode;
}

const MaintenanceLogs: React.FC<IMaintenanceLogsProps> = (props) => {
  const { systemLoaded, mode, system } = props;
  const { unitsTitle } = mode.details;
  const { new_hardware } = system;
  const [open, setOpen] = React.useState(false);
  const [locationValues, setLocationValues] = React.useState<any[]>([]);

  const [pageState, setPageState, isComplete, isRestored] = useUrlState({
    pageNumber: 0,
    sizePerPage: DEFAULT_ROWS_PER_PAGE,
    search: '',
    sortingConfig: {
      key: 'id',
      direction: 'asc',
    } as ISortConfig,
    locations: [] as string[],
  });

  const [exportData, setExportData] = useState<boolean>(false);

  const [chips, setChips] = useState<SearchChip[]>([]);
  const { data: allCompletedTasksData = [], isLoading: loadingCompletedTasks } =
    useAllCompletedTask({
      enabled: systemLoaded,
    });
  const { data: stationsData, isLoading: isLoadingLocations } = useStation({
    enabled: systemLoaded && open,
  });

  const stations = useMemo(() => stationsData?.data || [], [stationsData]);

  useUpdateEffect(() => {
    if (isComplete && isRestored) {
      const locationValues: IStation[] = stations.filter((station: IStation) =>
        pageState.locations.includes(station.id.toString())
      );
      const chips = locationValues.map((location) => ({
        label: 'Locations',
        field: 'locations',
        value: location.id.toString(),
        displayValue: location.name,
      }));
      setLocationValues(locationValues);
      setChips(chips);
    }
  }, [isRestored]);

  const handleLocationChange = useCallback(
    (values: string[], options: { label: string; value: string }[]): void => {
      setPageState((prevState) => ({
        ...prevState,
        pageNumber: 0,
        sizePerPage: DEFAULT_ROWS_PER_PAGE,
      }));
      setChips((prevChips) => {
        const valuesFound: string[] = [];

        const newChips = prevChips.filter((chip) => {
          if (chip.field !== 'locations') {
            return true;
          }

          const found = values.includes(chip.value as string);

          if (found) {
            valuesFound.push(chip.value as string);
          }

          return found;
        });

        if (values.length === valuesFound.length) {
          return newChips;
        }
        values.forEach((value) => {
          if (!valuesFound.includes(value)) {
            const option = options.find((option) => option.value.toString() === value);
            newChips.push({
              label: 'Locations',
              field: 'locations',
              value,
              displayValue: option!.label,
            });
          }
        });
        return newChips;
      });
    },
    [setPageState]
  );

  const stationOptions = useMemo(() => {
    return stations.map((item: IStation) => {
      return {
        value: item.id,
        label: toTitleCase(item.name),
      };
    });
  }, [stations]);

  const filteredCompletedTasks = useMemo(() => {
    return isComplete && pageState?.locations?.length > 0
      ? allCompletedTasksData.filter((task: ITask) =>
          pageState.locations.includes(task?.station_gid)
        )
      : allCompletedTasksData;
  }, [allCompletedTasksData, pageState.locations, isComplete]);

  const handleChipDelete = useCallback((deletedGroup: SearchChip) => {
    setChips((prevChips) => {
      return prevChips.filter((group) => {
        return group.label !== deletedGroup.label;
      });
    });
    setLocationValues([]);
  }, []);

  return (
    <PaginationTable
      showFilter
      isLoading={loadingCompletedTasks || !isComplete}
      padding={{ pt: 1, pl: 0, pr: 1 }}
      exportData={exportData}
      csvFileName={'Maintenance_logs.csv'}
      onExport={() => setExportData(false)}
      buttons={[
        {
          label: 'Export',
          startIcon: <FileDownloadIcon />,
          onClick: () => setExportData(true),
        },
        {
          label: 'Add',
          onClick: () => history.push('/maintenance/log/add'),
          startIcon: <AddIcon />,
        },
      ]}
      onApplyFilter={() => {
        setPageState((prevState) => ({
          ...prevState,
          locations: locationValues.map((item) => item.value.toString()),
        }));
        handleLocationChange(
          [
            ...locationValues
              .filter((option) =>
                stationOptions.some(
                  (item: { label: string }) => item.label === option.label
                )
              )
              .map((item) => item.value.toString()),
          ],
          stationOptions
        );
      }}
      chips={chips}
      handleChipDelete={handleChipDelete}
      spaceFilters={
        <MovaticFieldGroupSelectFilter
          label={'Location'}
          isLoading={isLoadingLocations}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          value={locationValues}
          onChange={(event: React.SyntheticEvent, newValue: any[]) => {
            setLocationValues([
              ...newValue.filter((option) =>
                stationOptions.some(
                  (item: { label: string }) => item.label === option.label
                )
              ),
            ]);
          }}
          options={stationOptions}
        />
      }
      onRowCLick={(id: string) => history.push(`/maintenance/log/${id}`)}
      sortBy={pageState?.sortingConfig?.key}
      onSortChange={(sort) =>
        setPageState((prevState) => ({
          ...prevState,
          sortingConfig: sort as ISortConfig,
        }))
      }
      columns={
        taskColumnsV2(unitsTitle, new_hardware, mode.details.stationsTitle) as IColumn[]
      }
      items={filteredCompletedTasks || []}
      searchPlaceholder={`Search logs`}
      page={pageState.pageNumber}
      rowsPerPage={pageState.sizePerPage}
      onPageChange={(page, newPage) =>
        setPageState((prevState) => ({
          ...prevState,
          pageNumber: newPage,
        }))
      }
      onRowsPerPageChange={(pageSizeString) =>
        setPageState((prevState) => ({
          ...prevState,
          sizePerPage: Number(pageSizeString.target.value),
        }))
      }
      handleSearch={(e: React.ChangeEvent<HTMLInputElement>) =>
        setPageState((prevState) => ({
          ...prevState,
          search: e.target.value,
        }))
      }
      searchText={pageState.search}
    />
  );
};

export default connect(
  (state: { system: { isLoaded: boolean; current: ISystem }; mode: IMode }) => ({
    systemLoaded: state.system.isLoaded,
    system: state.system.current,
    mode: state.mode,
  }),
  () => ({})
)(MaintenanceLogs);
