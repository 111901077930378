import React, { useState } from 'react';
import {
  getLocaleDateTimeString,
  history,
  timeForHumans,
  toTitleCase,
} from '../../../utils';
import StarRatingComponent from 'react-star-rating-component';
import EndRental from './endRental';
import ChargeModal from './chargeModal';
import { IRentalResumeProps } from '../../../ts/interface/pages/rentals.interface';
import { PropertyList } from '../../../components/Redesing/property-list';
import { PropertyListItem } from '../../../components/Redesing/property-list-item';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import CardView from '../../../components/Redesing/card-view';
import { SeverityPill } from '../../../components/Redesing/severity-pill';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import PaymentIcon from '@mui/icons-material/Payment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
const RentalResume = ({
  system,
  rental,
  auth,
  modeInfo,
  rentalAdminPermission,
  systemLoaded,
  onRentalEnded,
}: IRentalResumeProps) => {
  const [chargeRentalModal, setChargeRentalModal] = useState<boolean>(false);
  const [endRentalModal, setEndRentalModal] = useState<boolean>(false);

  const canBillForceEndedRental =
    system.billing &&
    !rental?.ended_within_grace_period &&
    !rental?.billing?.total &&
    rental?.force_end?.type?.toLowerCase() === 'user';

  const { duration, distance, end, hardware, rating, start, force_end, user } = rental;
  const { stationsTitle } = modeInfo.details;

  const transactionId = rental.billing?.transactions?.find(
    ({ type, source }) => type === 'charge' && source === 'stripe'
  );

  const getModal = () => {
    if (endRentalModal) {
      return (
        <EndRental
          rental={rental}
          showModal={endRentalModal}
          onClose={() => {
            setEndRentalModal(!endRentalModal);
            onRentalEnded?.();
          }}
          round_trip={system.round_trip}
          currentMode={modeInfo.currentMode}
          systemLoaded={systemLoaded}
          stationTitle={modeInfo.details.stationsTitle || 'Location'}
          billing={system.billing}
        />
      );
    }
    if (chargeRentalModal) {
      return (
        <ChargeModal
          showModal={chargeRentalModal}
          onClose={() => setChargeRentalModal(!chargeRentalModal)}
          userName={rental?.user?.full_name}
          billing={system.billing}
          rentalId={rental.old_id}
        />
      );
    }
    return null;
  };

  const renderLink = (to: string, text: string | number, dataId?: string) => (
    <Link onClick={() => history.push(to)} data-id={`${dataId}-link`}>
      <Typography color="text.secondary" variant="subtitle1">
        {text}
        <i id="table_item_carot" className="fa fa-caret-right" />
      </Typography>
    </Link>
  );

  const renderPropertyListItem = (
    divider: boolean,
    label: string,
    value: JSX.Element
  ) => (
    <PropertyListItem divider={divider} label={label}>
      {value}
    </PropertyListItem>
  );

  return (
    // <Card>
    <CardView
      headerActions={
        <>
          {canBillForceEndedRental ? (
            <>
              <IconButtonMenu
                buttons={[
                  {
                    label: 'Charge Rental',
                    onClick: () => setChargeRentalModal(!chargeRentalModal),
                    startIcon: <PaymentIcon />,
                  },
                ]}
              />
            </>
          ) : !rental.end?.time ? (
            <>
              <IconButtonMenu
                buttons={[
                  {
                    label: 'End Rental',
                    onClick: () => setEndRentalModal(!endRentalModal),
                    startIcon: <CheckCircleIcon />,
                  },
                ]}
              />
            </>
          ) : null}
        </>
      }
      content={
        <>
          <PropertyList>
            {transactionId?.dispute_id && (
              <PropertyListItem divider label={'Disputed'}>
                {renderLink(
                  `/billing/disputes/${transactionId.dispute_id}`,
                  'Click To View'
                )}
              </PropertyListItem>
            )}
            <PropertyListItem divider label={'User'}>
              {renderLink(`/users/${user?.phone_number}/general`, user?.full_name || '')}
            </PropertyListItem>
            {start?.triggered_by_type &&
              renderPropertyListItem(
                true,
                'Started by',
                toTitleCase(start.triggered_by_type)
              )}
            {end?.triggered_by_type &&
              renderPropertyListItem(
                true,
                'Ended by',
                toTitleCase(end.triggered_by_type)
              )}
            {system?.new_hardware ? (
              <PropertyListItem
                divider
                label={`${toTitleCase(hardware?.type_name || '')} Number`}
              >
                {renderLink(
                  `/hardware/${hardware?.type_name}/${hardware?.old_id || ''}`,
                  hardware?.number || ''
                )}
              </PropertyListItem>
            ) : (
              <PropertyListItem
                divider
                label={`${toTitleCase(hardware?.type_name || '')} Number`}
              >
                {renderLink(
                  `/units/${hardware?.type_name}/${hardware?.old_id || ''}`,
                  hardware?.number || ''
                )}
              </PropertyListItem>
            )}
            {start?.station?.id && start.station.id === end?.station?.id && (
              <PropertyListItem divider label={stationsTitle || ''}>
                {renderLink(
                  `/locations/${start.station.id}/general`,
                  start.station.name || ''
                )}
              </PropertyListItem>
            )}
            {start?.station?.id !== end?.station?.id && (
              <>
                <PropertyListItem
                  divider
                  label={`Start ${stationsTitle || ''}`}
                  dataId={`startStation`}
                >
                  {start?.station?.id ? (
                    renderLink(
                      `/locations/${start.station.id}/general`,
                      start?.station?.name,
                      `startStation`
                    )
                  ) : (
                    <SeverityPill color={'info'}>None</SeverityPill>
                  )}
                </PropertyListItem>
                {!!end?.time && (
                  <PropertyListItem
                    divider
                    label={`End ${stationsTitle || ''}`}
                    dataId={`endStation`}
                  >
                    {end?.station?.id ? (
                      renderLink(
                        `/locations/${end.station.id}/general`,
                        `${end.station.name} `,
                        `endStation`
                      )
                    ) : (
                      <SeverityPill color={'info'}>None</SeverityPill>
                    )}
                  </PropertyListItem>
                )}
              </>
            )}
            <PropertyListItem
              label={'Start Time'}
              value={getLocaleDateTimeString(start?.time)}
            />
            {end?.time && (
              <>
                <Divider />
                <PropertyListItem
                  label={'End Time'}
                  value={getLocaleDateTimeString(end?.time)}
                />
              </>
            )}
            {duration ? (
              <>
                <Divider />
                <PropertyListItem
                  dataId={'rental-duration'}
                  label={'Duration'}
                  value={timeForHumans(duration).toString()}
                />
              </>
            ) : (
              <>
                <Divider />
                <PropertyListItem
                  dataId={'rental-duration'}
                  label={'Duration'}
                  value={'Rental is ongoing'}
                />
              </>
            )}
            {distance &&
            distance.kilometers !== null &&
            distance.kilometers !== undefined &&
            system?.country?.toLowerCase() === 'us' ? (
              <>
                <Divider />
                <PropertyListItem
                  label={'Distance'}
                  value={`${distance.kilometers.toFixed(3)} km`}
                />
              </>
            ) : (
              <>
                {distance && distance.miles !== null && distance.miles !== undefined && (
                  <>
                    <Divider />
                    <PropertyListItem
                      label={'Distance'}
                      value={`${distance.miles.toFixed(3)} mi`}
                    />
                  </>
                )}
              </>
            )}
            {force_end?.time && (
              <>
                <Divider />
                <PropertyListItem
                  label={'Force ended'}
                  value={
                    force_end.type?.toLowerCase() === 'user'
                      ? 'Rental was ended by another user checking out the bike.'
                      : `Rental was ended by an admin on ${getLocaleDateTimeString(
                          force_end.time
                        )}`
                  }
                />
              </>
            )}
            {end?.time || rating ? (
              <>
                <Divider />
                <PropertyListItem label={'Rating'}>
                  {typeof rating === 'number' ? (
                    <StarRatingComponent
                      name="rating"
                      value={rating}
                      starCount={5}
                      editing={false}
                    />
                  ) : (
                    <SeverityPill color="info">No Rating</SeverityPill>
                  )}
                </PropertyListItem>
              </>
            ) : null}
          </PropertyList>
          {getModal()}
        </>
      }
      title={
        <div style={{ minHeight: '36px', display: 'flex', alignItems: 'center' }}>
          Rental Details
        </div>
      }
    />
  );
};

export default RentalResume;
