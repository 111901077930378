import { SET_NEW_RATES, SET_RATE_DETAILS, INSERT_RATE } from '../constants';

const initialState = {
  rates: [],
  isLoaded: false,
  currentRate: {
    id: null,
    system: null,
    created: null,
    active_on: null,
    archived: null,
    membership_required: null,
    free_time: null,
    max_periods: null,
    period_duration: null,
    base_rate: null,
    price_per_period: null,
    reservation: null,
    max_rentals: null,
    banner: null,
    overview: null,
    stringDuration: null,
  },
};

export default function ratesV2Reducer (state = initialState, action) {
  const newState = { ...state };
  switch (action.type) {
    case SET_NEW_RATES:
      newState.rates = action.rates;
      newState.isLoaded = true;
      break;

    case SET_RATE_DETAILS:
      newState.currentRate = action.rate;
      break;

    case INSERT_RATE:
      newState.rates.push(action.rate);
      break;

    default:
      return state;
  }

  return newState;
}
