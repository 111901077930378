import React from 'react';
import styled from '@mui/system/styled';
import Avatar from '@mui/material/Avatar';

const StyledAvatar = ({ children }: { children: React.ReactNode }) => {
  const StyledAvatar = styled(Avatar)(({ theme }) => ({
    borderRadius: 20,
    width: 35,
    height: 35,
  }));
  return <StyledAvatar>{children}</StyledAvatar>;
};

export default StyledAvatar;
