import { baseUrlV2, fetchOptions, fetchPostOptions, getCredentials } from './http';
import {
  IIssuingCardAdd,
  IIssuingCardholderTosAcceptance,
  IIsuingBalanceFund,
  IIssuingCardEdit,
} from '../ts/interface/issuingCard.interface';
import { displayError } from '../utils';

function manageIssuingCardResource<T>(
  data: T,
  URI: string,
  shouldGetCredentials?: boolean,
  errorMessage?: string,
  method?: string,
  shouldUseSystemGid?: boolean
) {
  const { adminEmail, adminToken, systemGid, systemId } = shouldGetCredentials
    ? getCredentials()
    : { adminEmail: null, adminToken: null, systemGid: null, systemId: null };
  const system = shouldUseSystemGid ? systemGid : systemId;
  // @ts-ignore
  return fetch(URI, fetchPostOptions(adminEmail, adminToken, system, data, method))
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        return response.message;
      }

      throw new Error(response.message);
    })
    .catch((error) => displayError(`${errorMessage} ${error}`));
}

export function getIssuingCardResource(
  URI: string,
  shouldGetCredentials: boolean,
  errorMessage: string,
  shouldUseSystemGid?: boolean
) {
  const { adminEmail, adminToken, systemGid, systemId } = shouldGetCredentials
    ? getCredentials()
    : { adminEmail: null, adminToken: null, systemGid: null, systemId: null };
  const system = shouldUseSystemGid ? systemGid : systemId;
  // @ts-ignore
  return fetch(URI, fetchOptions(adminEmail, adminToken, system))
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        return response.message;
      }

      throw new Error(response.message);
    })
    .catch((error) => displayError(`${errorMessage} ${error}`));
}

export const addIssuingCard = (issuingCard: IIssuingCardAdd) => {
  return manageIssuingCardResource(
    issuingCard,
    `${baseUrlV2}/billing/billing/issuingcard/request`,
    true,
    'Oops! Looks like there was an error while requesting the issuing card. Please try again later.',
    'POST',
    true
  );
};

export const postCardholderTosApproval = (
  cardholderTosInformation: IIssuingCardholderTosAcceptance
) => {
  return manageIssuingCardResource(
    cardholderTosInformation,
    `${baseUrlV2}/billing/billing/issuing/tosapproval`,
    false,
    'Oops! Looks like there was an error while approving the issuing card. Please try again later.',
    'POST'
  );
};

export const updateIssuingCardStatus = (issuingCard: IIssuingCardEdit, id: string) => {
  return manageIssuingCardResource(
    issuingCard,
    `${baseUrlV2}/billing/billing/issuingcard/${id}`,
    true,
    'Oops! Looks like there was an error while updating the issuing card. Please try again later.',
    'PUT'
  );
};

export const fundIssuingBalance = (issuingBalance: IIsuingBalanceFund) => {
  return manageIssuingCardResource(
    issuingBalance,
    `${baseUrlV2}/billing/billing/issuingcard/fund`,
    true,
    'Oops! Looks like there was an error while funding the issuing card balance. Please try again later.',
    'POST'
  );
};
