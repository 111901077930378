import {
  DB_LOADED,
  API_SYSTEMS_ADMIN_SUCCESS,
  SET_ACCESS,
  RESET,
  SET_ADMIN_NAMES,
} from '../constants.js';

const initialState = {
  loading: false,
  dbLoaded: false,
  failure: null,
  admin: null,
  resetPassword: false,
  setPassword: false,
  apiDataLoaded: false,
  systemAdmins: [],
  adminsLoaded: false,
  maxedOut: false,
  adminLoaded: false,
  systemAccess: null,
};

export default function adminReducer(state = initialState, action) {
  switch (action.type) {
    case RESET:
      return {
        ...state,
        systemAdmins: [],
        adminsLoaded: false,
      };
    case DB_LOADED:
      return { ...state, dbLoaded: action.isLoaded };
    case API_SYSTEMS_ADMIN_SUCCESS:
      return {
        ...state,
        systemAdmins: action.admins,
        adminsLoaded: true,
      };
    case SET_ACCESS:
      return { ...state, systemAccess: action.access };
    case 'TO_MANY_LOGINS_RESET_PASSWORD':
      return {
        ...state,
        loading: false,
        failure: null,
        admin: null,
        resetPassword: true,
        setPassword: false,
        maxedOut: true,
      };
    case 'RESET_PASSWORD':
      return {
        ...state,
        loading: false,
        failure: null,
        admin: null,
        resetPassword: true,
        setPassword: false,
      };
    case 'CANCEL_RESET_PASSWORD':
      return {
        ...state,
        loading: false,
        failure: null,
        resetPassword: false,
        setPassword: false,
      };
    case 'RESET_PASSWORD_SUCCESS':
      return {
        ...state,
        loading: false,
        failure: null,
        resetPassword: false,
        setPassword: true,
      };
    case 'API_LOGGIN':
      return {
        ...state,
        loading: true,
        failure: null,
        admin: null,
        resetPassword: false,
      };
    case 'ADMIN_LOADED':
    case 'LOGGED_IN_SUCCESS':
      localStorage.setItem('adminDate', new Date());
      localStorage.setItem('admin', JSON.stringify(action.data));
      return {
        ...state,
        loading: false,
        failure: null,
        admin: action.data,
        resetPassword: false,
        setPassword: false,
      };
    case 'LOGGED_IN_FAILED':
      return {
        ...state,
        loading: false,
        failure: action.data,
        admin: null,
        resetPassword: false,
        setPassword: false,
      };
    case 'LOG_OUT':
      localStorage.removeItem('adminDate');
      localStorage.removeItem('admin');
      // localStorage.removeItem('systemId');
      // localStorage.removeItem('systemGid');
      return {
        ...state,
        loading: false,
        failure: null,
        admin: null,
        resetPassword: false,
      };
    case SET_ADMIN_NAMES:
      return {
        ...state,
        admin: {
          ...state.admin,
          admin: {
            ...state.admin.admin,
            firstName: action.admin.firstName,
            lastName: action.admin.lastName,
          },
        },
      };
    default:
      return state;
  }
}
