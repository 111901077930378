import React, { useState } from 'react';
import { connect } from 'react-redux';
import { history, findAccess } from '../../../utils';
import { adminColumns } from '../../../tableColumns/settingColumns';
import useSystemAdmins from '../../../hooks/system/query/useSystemAdmins';
import { PaginationTable } from '../../../components/Redesing/table/pagination-table';
import AddIcon from '@mui/icons-material/Add';
import { IAdmin } from '../../../ts/interface/admins.interface';
import Card from '@mui/material/Card';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants';

interface AdminProps {
  admin: IAdmin;
  systemAccess: any;
  systemLoaded: boolean;
}

const Admin: React.FC<AdminProps> = (props) => {
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_ROWS_PER_PAGE);
  const [sortData, setSortData] = useState<string>('created');
  const [search, setSearch] = React.useState('');
  const [exportData, setExportData] = useState<boolean>(false);

  const { data = [], isLoading } = useSystemAdmins({
    enabled: props.systemLoaded,
  });

  return (
    <Card>
      <PaginationTable
        padding={{
          //pl: -1,
          pt: 1,
          pr: 1,
        }}
        showFilter={false}
        sortBy={sortData}
        isLoading={isLoading}
        onSortChange={(sortBy) => setSortData(sortBy.key)}
        items={data}
        customKey={'email'}
        noDataText={`No admins found`}
        onRowCLick={(email: string) => history.push(`/settings/admin/${email}`)}
        columns={adminColumns}
        rowsPerPage={pageSize}
        page={pageNo}
        onPageChange={(page, pageN: number) => {
          setPageNo(pageN);
        }}
        onRowsPerPageChange={(rowsPerPage) =>
          setPageSize(Number(rowsPerPage.target.value))
        }
        searchText={search}
        searchPlaceholder={`Search admins`}
        handleSearch={(search) => setSearch(search.target.value)}
        exportData={exportData}
        csvFileName={'System_Admins.csv'}
        onExport={() => setExportData(false)}
        buttons={[
          {
            label: 'Export',
            startIcon: <FileDownloadIcon />,
            onClick: () => setExportData(true),
          },
          {
            label: 'Add',
            startIcon: <AddIcon />,
            onClick: () => history.push('/settings/admin/add'),
            disabled:
              !findAccess(props.systemAccess).includes('admin') ||
              !!props.admin.movaticAccess,
          },
        ]}
      />
    </Card>
  );
};

export default connect(
  (state: {
    admin: {
      admin: IAdmin;
      systemAccess: string;
    };
    system: {
      isLoaded: boolean;
    };
  }) => ({
    admin: state.admin.admin,
    systemAccess: state.admin.systemAccess,
    systemLoaded: state.system.isLoaded,
  }),
  () => ({})
)(Admin);
