import React, { useMemo, useState } from 'react';
import { membershipUsersColumnsV2 } from '../../../tableColumns/membershipColumns';
import { history } from '../../../utils';
import { IUserMembership } from '../../../ts/interface/membership.interface';
import { PaginationTable } from '../../../components/Redesing/table/pagination-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CardView from '../../../components/Redesing/card-view';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';

interface IMembershipListProps {
  memberships: IUserMembership[];
  authType: string;
}

const ActiveMembershipList: React.FC<IMembershipListProps> = ({
  authType,
  memberships,
}) => {
  const [searchRate, setSearchRate] = React.useState('');
  const [pageRate, setPageRate] = React.useState(0);
  const [rowsPerPageRate, setRowsPerPageRate] = React.useState(5);
  const [sortData, setSortData] = useState<string>('name');
  const [exportData, setExportData] = useState<boolean>(false);
  const columns = useMemo(() => {
    return membershipUsersColumnsV2(authType);
  }, [authType]);

  return (
    <CardView
      content={
        <PaginationTable
          showFilter={false}
          sortBy={sortData}
          onSortChange={(sortBy) => setSortData(sortBy.key)}
          exportData={exportData}
          csvFileName={'User_memberships.csv'}
          onExport={() => setExportData(false)}
          items={memberships || []}
          noDataText={'There are no users on this membership at this time'}
          onRowCLick={(id: string) => history.push(`/memberships/user/${id}`)}
          columns={columns}
          rowsPerPage={rowsPerPageRate}
          page={pageRate}
          onPageChange={(page, pageN: number) => setPageRate(pageN)}
          onRowsPerPageChange={(rowsPerPage) =>
            setRowsPerPageRate(Number(rowsPerPage.target.value))
          }
          searchText={searchRate}
          searchPlaceholder={'Search users on this membership'}
          handleSearch={(search) => setSearchRate(search.target.value)}
        />
      }
      title={'Members'}
      headerActions={
        <IconButtonMenu
          buttons={[
            {
              label: 'Export',
              onClick: () => setExportData(true),
              startIcon: <FileDownloadIcon />,
            },
          ]}
        />
      }
    />
  );
};

export default ActiveMembershipList;
