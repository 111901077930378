import { baseUrl, getCredentials, fetchPostOptions } from './http';

export const createCharge = (body) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const postBody = { ...body, adminEmail: adminEmail };
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, postBody, 'POST');
  return fetch(`${baseUrl}/billing/manual_charge/create`, fetchOps)
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }
      throw new Error(response?.error_description);
    })
    .catch((error) => {
      return Promise.reject(`${error}`);
    });
};
