import fileDownloader from 'js-file-download';

import bugsnagClient from '../components/bugsnag';
import {
  API_HARDWARE_SUCCESS,
  SET_DETAILED_HARDWARE,
  CLEAR_DETAILED_HARDWARE,
  HARDWARE_DETAIL_LOADED,
  HARDWARE_LOADED,
  REMOTE_ACTION_LOADED,
  SET_REMOTE_ACTION_LOADED,
  HARDWARE_TYPE_LOADED,
  SET_HARDWARE_TYPES,
  KEYBOX,
} from '../constants';
import { fetchOptions, baseUrl, fetchPostOptions } from '../api/http.jsx';
import { gotError, gotSuccess } from './toast';
import { getAllModules, apiModuleSuccess } from './module';
import { FourOhOneError, update } from './system.js';
import { displayError, history } from '../utils';

export const apiHardwareSuccess = (hardware, new_hardware) => ({
  type: API_HARDWARE_SUCCESS,
  hardware,
  new_hardware,
});

export const setHardwareDetail = (hardwareDetails, new_hardware_detailed) => ({
  type: SET_DETAILED_HARDWARE,
  hardwareDetails,
  new_hardware_detailed,
});

export const hardwareLoaded = (loaded) => ({
  type: HARDWARE_LOADED,
  loaded,
});

export const hardwareDetailLoaded = (loaded) => ({
  type: HARDWARE_DETAIL_LOADED,
  loaded,
});

export const clearDetailedHardware = () => ({
  type: CLEAR_DETAILED_HARDWARE,
});

const remoteActionLoaded = (hardwareId, actionType) => ({
  type: REMOTE_ACTION_LOADED,
  hardwareId,
  actionType,
});

const setRemoteActionLoaded = (loaded) => ({
  type: SET_REMOTE_ACTION_LOADED,
  loaded,
});

export const hardwareTypeLoaded = (loaded) => ({
  type: HARDWARE_TYPE_LOADED,
  loaded,
});

const setHardwareTypes = (types) => ({
  type: SET_HARDWARE_TYPES,
  types,
});

export const setAndFilterDetailedHardware = (hardwareId, allHardware) => (dispatch) => {
  const detailedHardware = allHardware.find((hardware) => hardware.id === hardwareId);

  if (detailedHardware) {
    dispatch(setHardwareDetail(detailedHardware, {}));
  }
};

export const getAllHardware = () => (dispatch, getState) => {
  dispatch(hardwareLoaded(false));
  const state = getState();
  const fetchOps = fetchOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id
  );

  return fetch(`${baseUrl}/hardware/all`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        const hardware = response.message;
        if (state.system.current.new_hardware) {
          // TODO: remove this/skip this step
          const bike = hardware.bike || [];
          const e_bike = hardware.e_bike || [];
          const locker = hardware.locker || [];
          const scooter = hardware.scooter || [];
          const kayak = hardware.kayak || [];
          const beachchair = hardware.beachchair || [];

          const old_format = [
            ...bike,
            ...e_bike,
            ...locker,
            ...scooter,
            ...kayak,
            ...beachchair,
          ];

          dispatch(apiHardwareSuccess(old_format, hardware));
          dispatch(apiModuleSuccess(hardware.lock));
          dispatch(hardwareLoaded(true));
        } else {
          return dispatch(apiHardwareSuccess(hardware, {}));
        }
      } else {
        dispatch(FourOhOneError(response));
      }
    })
    .catch((error) => {
      return error;
    });
};

export const retireHardware = (hardwareId) => (dispatch, getState) => {
  const state = getState();
  dispatch(update(false));
  const fetchOps = fetchPostOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id,
    {},
    'Put'
  );
  fetch(`${baseUrl}/hardware/retire/${hardwareId}`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      dispatch(update(true));
      if (results.status === 200) {
        dispatch(getOneHardware(hardwareId));
        dispatch(gotSuccess('Successfully retired'));
      } else {
        dispatch(FourOhOneError(results));
        dispatch(gotError('There was an error while reitring'));
      }
    });
};

export const updateHardware = (hardwareInfo) => (dispatch, getState) => {
  dispatch(update(false));
  const state = getState();
  const returnToAllHardwareTable =
    state.system.current.id !== hardwareInfo.system &&
    (state.system.current.new_hardware ? '/hardware' : '/units');
  const fetchOps = fetchPostOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id,
    hardwareInfo,
    'Put'
  );

  if (hardwareInfo.type === KEYBOX && hardwareInfo.masterUnlockCode !== undefined) {
    fetch(`${baseUrl}/hardware/update`, fetchOps)
      .then((response) => response.json())
      .then((results) => {
        dispatch(update(true));
        if (!results.status) {
          const fileName = hardwareInfo.nusetSerialNumber;
          fileDownloader(JSON.stringify(results), `${fileName}.pin`);
          dispatch(getAllHardware());
          dispatch(getAllModules());
          dispatch(gotSuccess('Update successful! Your pincodes have been downloaded!'));
          history.push(`/units/${hardwareInfo.id}`);
        } else if (results.status === 200) {
          dispatch(getAllHardware());
          dispatch(getAllModules());
          dispatch(gotSuccess('Update successful!'));
          history.push(returnToAllHardwareTable || `/units/${hardwareInfo.id}`);
        } else if (results.status === 400) {
          history.push(`/units/${hardwareInfo.id}`);
          dispatch(
            gotError(`Error while updating, please try again. ${results.message}`)
          );
        } else {
          dispatch(FourOhOneError(results));
          throw new Error();
        }
      })
      .catch((error) => {
        dispatch(update(true));
        console.log('I got an error', error);
        history.push(`/units/${hardwareInfo.id}`);
        dispatch(gotError(`Error while updating, please try again. ${error}`));
      });
  } else {
    const newHardwareType = state.hardware.new_hardware_detailed.hardware_type;

    fetch(`${baseUrl}/hardware/update`, fetchOps)
      .then((response) => response.json())
      .then((hardwareResponse) => {
        dispatch(update(true));
        console.log(history.location.pathname);
        if (hardwareResponse.status === 200) {
          if (!state.system.current.new_hardware) {
            dispatch(getAllHardware());
            dispatch(getAllModules());
            dispatch(setHardwareDetail(hardwareResponse.message, {}));
            dispatch(gotSuccess('Update successful!'));
            history.push(returnToAllHardwareTable || `/units/${hardwareInfo.id}`);
          } else if (history.location.pathname === `/hardware/${newHardwareType}`) {
            dispatch(getAllHardware());
            dispatch(gotSuccess('Update successful!'));
          } else {
            dispatch(getAllHardware());
            dispatch(gotSuccess('Update successful!'));
            history.push(
              returnToAllHardwareTable ||
                `/hardware/${newHardwareType}/${hardwareInfo.id}`
            );
          }
        } else if (hardwareResponse.status === 400) {
          dispatch(gotError(hardwareResponse.message));
        } else {
          dispatch(FourOhOneError(hardwareResponse));
          throw new Error();
        }
      })
      .catch((error) => {
        dispatch(update(true));
        console.log('I got an error', error);
        history.push(
          !state.system.current.new_hardware
            ? `/hardware/${newHardwareType}/${hardwareInfo.id}`
            : `/units/${hardwareInfo.id}`
        );
        dispatch(gotError(`Error while updating, please try again. ${error}`));
      });
  }
};

export const createHardware = (hardwareInfo) => (dispatch, getState) => {
  dispatch(update(false));
  const state = getState();
  const fetchOps = fetchPostOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id,
    hardwareInfo,
    'Post'
  );

  const url = hardwareInfo.typeName === KEYBOX ? '/hardware/nuset' : '/hardware';

  fetch(baseUrl + url, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      dispatch(update(true));
      if (results.status === 200) {
        dispatch(getAllHardware());
        if (!state.system.current.new_hardware) {
          dispatch(getAllModules());
        }
        dispatch(gotSuccess('Add successful!'));
        state.system.current.new_hardware
          ? history.push(`/hardware/${hardwareInfo.typeName}`)
          : history.push(`/units`);
      } else if (results.status === 400) {
        if (results.event === 15 || results.event === 42) {
          dispatch(gotError(results.message));
        } else if (results.message) {
          if (results.message.includes('hardware_imei_key')) {
            displayError('There is already a unit using that IMEI');
          } else if (results.message.includes('hardware_product_mac_unique')) {
            displayError('There is already a unit using that mac address');
          } else if (results.message.includes('duplicate key value (vendor_id)')) {
            displayError('There is already a piece of hardware with that Vendor ID.');
          } else {
            displayError(
              `There was an error while adding new hardware. Please try again! ${results.message}`
            );
          }
        } else {
          displayError(
            `There was an error while adding new hardware. Please try again! ${results.message}`
          );
        }
      } else {
        dispatch(FourOhOneError(results));
        throw new Error();
      }
    })
    .catch((error) => {
      displayError('There was an error while adding new hardware. Please try again!');
    });
};

export const downloadPinCode = (auth, systemId, hardwareObject) => (dispatch) => {
  // dispatch(update(false))
  const fetchOps = fetchOptions(auth.admin.email, auth.token, systemId);
  const url = `${baseUrl}/hardware/pincode/${hardwareObject.smartBoxId}/${hardwareObject.nusetSerialNumber}`;
  return fetch(url, fetchOps)
    .then((response) => {
      // dispatch(update(true))
      console.log('RESPONSE DOWNLOAD PIN');
      return response.json();
    })
    .then((results) => {
      const fileName = hardwareObject.nusetSerialNumber;
      fileDownloader(JSON.stringify(results), `${fileName}.pin`);
      dispatch(gotSuccess('Pincodes downloaded successfully!'));
    })
    .catch((error) => {
      history.push(`/units`);
      displayError('There was an error while adding. Please try again!');
    });
};

export const getOneHardware = (hardwareId) => (dispatch, getState) => {
  dispatch(hardwareDetailLoaded(false));

  const state = getState();
  const fetchOps = fetchOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id
  );
  fetch(`${baseUrl}/hardware/detail/${hardwareId}`, fetchOps)
    .then((results) => results.json())
    .then((hardwareDetail) => {
      dispatch(setHardwareDetail({}, hardwareDetail.message));
    })
    .catch((error) => {
      dispatch(hardwareDetailLoaded(true));
      console.log('error', error);
      bugsnagClient.notify(error);
    });
};

export const remoteUnlock = (hardware_id) => (dispatch, getState) => {
  console.log('remote unlock');
  dispatch(setRemoteActionLoaded(false));
  dispatch(remoteActionLoaded(hardware_id, 'remoteUnlockId'));

  const state = getState();
  const fetchOps = fetchPostOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id,
    {
      hardware_id,
    },
    'Post'
  );
  const url = '/hardware/unlock';
  fetch(baseUrl + url, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        if (results.message.hardware_id) {
          setTimeout(() => {
            dispatch(setRemoteActionLoaded(true));
            dispatch(remoteActionLoaded('', 'remoteUnlockId'));
            dispatch(gotSuccess('Unlock request sent successfully'));
          }, 5000);
          // dispatch(checkHardwareStatus(results.message.hardware_id, 'remoteUnlock'));
        } else {
          // couldn't find a lock to unlock
          dispatch(setRemoteActionLoaded(true));
          dispatch(remoteActionLoaded('', 'remoteUnlockId'));
          dispatch(gotError('Not connected'));
        }
      } else if (results.status === 400) {
        dispatch(setRemoteActionLoaded(true));
        dispatch(remoteActionLoaded('', 'remoteUnlockId'));
        dispatch(gotError(`Unlock failed`));
      } else {
        dispatch(setRemoteActionLoaded(true));
        dispatch(remoteActionLoaded('', 'remoteUnlockId'));
        dispatch(FourOhOneError(results));
      }
    })
    .catch((error) => {
      dispatch(setRemoteActionLoaded(true));
      dispatch(remoteActionLoaded('', 'remoteUnlockId'));
      dispatch(gotError(`remote unlock error: ${error}`));
    });
};

export const remoteBatteryUnlock = (hardware_id) => (dispatch, getState) => {
  dispatch(setRemoteActionLoaded(false));
  dispatch(remoteActionLoaded(hardware_id, 'remoteBatteryUnlockId'));

  const state = getState();
  const fetchOps = fetchPostOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id,
    {
      hardware_id,
    },
    'Post'
  );
  const url = '/hardware/unlockbattery';
  fetch(baseUrl + url, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        if (results.message.hardware_id) {
          setTimeout(() => {
            dispatch(setRemoteActionLoaded(true));
            dispatch(remoteActionLoaded('', 'remoteBatteryUnlockId'));
            dispatch(gotSuccess('Battery unlock request sent successfully'));
          }, 5000);
        } else {
          // couldn't find a lock to unlock
          dispatch(setRemoteActionLoaded(true));
          dispatch(remoteActionLoaded('', 'remoteBatteryUnlockId'));
          dispatch(gotError('Not connected'));
        }
      } else if (results.status === 400) {
        dispatch(setRemoteActionLoaded(true));
        dispatch(remoteActionLoaded('', 'remoteBatteryUnlockId'));
        dispatch(gotError(`Battery unlock failed`));
      } else {
        dispatch(setRemoteActionLoaded(true));
        dispatch(remoteActionLoaded('', 'remoteBatteryUnlockId'));
        dispatch(FourOhOneError(results));
      }
    })
    .catch((error) => {
      dispatch(setRemoteActionLoaded(true));
      dispatch(remoteActionLoaded('', 'remoteUnlockId'));
      dispatch(gotError(`Battery remote unlock error: ${error}`));
    });
};

export const remoteLock = (hardware_id) => (dispatch, getState) => {
  dispatch(setRemoteActionLoaded(false));
  dispatch(remoteActionLoaded(hardware_id, 'remoteLockId'));

  const state = getState();
  const fetchOps = fetchPostOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id,
    {
      hardware_id,
    },
    'Post'
  );
  const url = '/hardware/lock';
  fetch(baseUrl + url, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        if (results.message.hardware_id) {
          setTimeout(() => {
            dispatch(setRemoteActionLoaded(true));
            dispatch(remoteActionLoaded('', 'remoteLockId'));
            dispatch(gotSuccess('Lock request sent successfully'));
          }, 5000);
          // dispatch(checkHardwareStatus(results.message.hardware_id, 'remoteLock'));
        } else {
          dispatch(setRemoteActionLoaded(true));
          // couldn't find a lock to lock
          dispatch(remoteActionLoaded('', 'remoteLockId'));
          dispatch(gotError('Not connected'));
        }
      } else if (results.status === 400) {
        dispatch(setRemoteActionLoaded(true));
        dispatch(remoteActionLoaded('', 'remoteLockId'));
        dispatch(gotError(`Lock failed`));
      } else {
        dispatch(setRemoteActionLoaded(true));
        dispatch(remoteActionLoaded('', 'remoteLockId'));
        dispatch(FourOhOneError(results));
      }
    })
    .catch((error) => {
      dispatch(setRemoteActionLoaded(true));
      dispatch(remoteActionLoaded('', 'remoteLockId'));
      dispatch(gotError(`remote lock error: ${error}`));
    });
};

export const remoteRing = (hardware_id) => (dispatch, getState) => {
  console.log('remote ring');
  dispatch(setRemoteActionLoaded(false));
  dispatch(remoteActionLoaded(hardware_id, 'remoteRingId'));

  const state = getState();
  const fetchOps = fetchPostOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id,
    {
      hardware_id,
    },
    'Post'
  );
  const url = '/hardware/ring';
  fetch(baseUrl + url, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        if (results.message.hardware_id) {
          // dispatch(checkHardwareStatus(results.message.hardware_id, 'remoteRing'));
          setTimeout(() => {
            dispatch(setRemoteActionLoaded(true));
            dispatch(remoteActionLoaded('', 'remoteRingId'));
            dispatch(gotSuccess('Ring request sent successfully'));
          }, 5000);
        } else {
          dispatch(setRemoteActionLoaded(true));
          // couldn't find a unit to ring
          dispatch(remoteActionLoaded('', 'remoteRingId'));
          dispatch(gotError('Not connected'));
        }
      } else if (results.status === 400) {
        dispatch(setRemoteActionLoaded(true));
        dispatch(remoteActionLoaded('', 'remoteRingId'));
        dispatch(gotError(`Ring failed`));
      } else {
        dispatch(setRemoteActionLoaded(true));
        dispatch(remoteActionLoaded('', 'remoteRingId'));
        dispatch(FourOhOneError(results));
      }
    })
    .catch((error) => {
      dispatch(setRemoteActionLoaded(true));
      dispatch(remoteActionLoaded('', 'remoteRingId'));
      dispatch(gotError(`remote ring error: ${error}`));
    });
};

export const locationCheck = (hardware_id) => (dispatch, getState) => {
  console.log('location check');
  dispatch(setRemoteActionLoaded(false));
  dispatch(remoteActionLoaded(hardware_id, 'locationCheckId'));

  const state = getState();
  const fetchOps = fetchPostOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id,
    {
      hardware_id,
    },
    'Post'
  );
  const url = '/hardware/locationcheck';
  fetch(baseUrl + url, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        if (results.message.hardware_id) {
          setTimeout(() => {
            dispatch(setRemoteActionLoaded(true));
            dispatch(remoteActionLoaded('', 'locationCheckId'));
            dispatch(gotSuccess('Location request sent successfully'));
          }, 5000);
          // dispatch(checkHardwareStatus(results.message.hardware_id, 'locationCheck'));
        } else {
          // couldn't find a unit to locate
          dispatch(setRemoteActionLoaded(true));
          dispatch(remoteActionLoaded('', 'locationCheckId'));
          dispatch(gotError('Not connected'));
        }
      } else if (results.status === 400) {
        dispatch(setRemoteActionLoaded(true));
        dispatch(remoteActionLoaded('', 'locationCheckId'));
        dispatch(gotError(`Location check failed`));
      } else {
        dispatch(setRemoteActionLoaded(true));
        dispatch(remoteActionLoaded('', 'locationCheckId'));
        dispatch(FourOhOneError(results));
      }
    })
    .catch((error) => {
      dispatch(setRemoteActionLoaded(true));
      dispatch(remoteActionLoaded('', 'locationCheckId'));
      dispatch(gotError(`Location check error: ${error}`));
    });
};

export const getAllHardwareTypes = () => (dispatch, getState) => {
  dispatch(hardwareTypeLoaded(false));
  const state = getState();
  const fetchOps = fetchOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id
  );
  return fetch(`${baseUrl}/hardware/types`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        return dispatch(setHardwareTypes(response.message));
      }
      dispatch(FourOhOneError(response));
    })
    .catch((error) => {
      console.error(error);
      bugsnagClient.notify(error);
      return error;
    });
};
