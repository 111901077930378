import {
  API_TRANSACTION_SUCCESS,
  CLEAR_TRANSACTION_DETAILS,
  API_CHARGES_SUCCESS,
  TRANSACTION_DETAILS,
  API_REFUNDS_SUCCESS,
  FORMATTED_TRANSACTION_DETAILS,
  TRANSACTION_DETAIL_LOADED,
  WALLET_TRANSACTION_DETAILS,
  WALLET_TRANSACTION_DETAIL_LOADED,
  FORMATTED_WALLET_TRANSACTION_DETAILS,
  CLEAR_WALLET_TRANSACTION_DETAILS,
  HOLD_TRANSACTION_DETAILS,
  HOLD_TRANSACTION_DETAILS_LOADED,
  TRANSACTIONS_LOADED,
} from '../constants';

const initialState = {
  transactions: [],
  charges: [],
  refunds: [],
  isLoaded: false,
  chargesAndRefundsLoaded: false,
  detailedLoaded: false,
  walletTransactionDetailedLoaded: false,
  holdDetailsLoaded: false,
  detailed: {
    amount: 0,
    amount_refunded: 0,
    application: '',
    application_fee: '',
    balance_transaction: '',
    captured: false,
    created: 0,
    currency: '',
    customer: null,
    description: '',
    destination: null,
    dispute: null,
    failure_code: null,
    failure_message: null,
    fraud_details: null,
    id: '',
    invoice: null,
    livemode: false,
    metadata: {
      rentalId: '',
      system: '',
      user: '',
    },
    object: '',
    on_behalf_of: null,
    order: null,
    outcome: {
      network_status: '',
      reason: null,
      risk_level: '',
      seller_message: '',
      type: '',
    },
    paid: false,
    receipt_email: null,
    receipt_number: null,
    refunded: false,
    refunds: {
      data: [],
      has_more: false,
      object: '',
      total_count: 0,
      url: '',
    },
    review: null,
    shipping: null,
    source: {
      address_city: null,
      address_country: null,
      address_line1: null,
      address_line1_check: null,
      address_line2: null,
      address_state: null,
      address_zip: '',
      address_zip_check: '',
      brand: '',
      country: '',
      customer: null,
      cvc_check: null,
      dynamic_last4: null,
      exp_month: 0,
      exp_year: 0,
      fingerprint: '',
      funding: '',
      id: '',
      last4: '',
    },
    source_transfer: null,
    statement_descriptor: null,
    status: '',
    transfer_group: null,
    rentalData: {
      chargePendingDecisionDate: null,
      chargePendingNote: null,
      isChargePendingApproved: null,
    },
  },
  formattedDetailed: {
    amount: '',
    amount_due: 0,
    amount_refunded: 0,
    authStatus: 0,
    coupon_id: null,
    created: '',
    delinquencyDesc: null,
    delinquent: false,
    description: '',
    emailSent: '',
    firstName: '',
    id: '',
    language: '',
    lastName: '',
    peach_token: null,
    phone: null,
    qvo_customer: null,
    refunded: false,
    sendEmails: false,
    status: '',
    stripeCustomer: '',
    stripeCustomerLive: '',
    system: null,
    token: '',
    type: '',
    user: null,
    userName: '',
  },
  holdDetails: null,
};

export default function transactionReducer (state = initialState, action) {
  const newState = { ...state };
  switch (action.type) {
    case API_TRANSACTION_SUCCESS:
      newState.transactions = action.transactions;
      newState.isLoaded = true;
      break;

    case TRANSACTIONS_LOADED:
      newState.isLoaded = action.loaded;
      break;

    case API_CHARGES_SUCCESS:
      newState.charges = action.charges;
      break;

    case API_REFUNDS_SUCCESS:
      newState.refunds = action.refunds;
      newState.chargesAndRefundsLoaded = true;
      break;

    case TRANSACTION_DETAILS:
      newState.detailed = action.transaction;
      newState.detailedLoaded = true;
      break;

    case FORMATTED_TRANSACTION_DETAILS:
      newState.formattedDetailed = action.transaction;
      break;

    case TRANSACTION_DETAIL_LOADED:
      newState.detailedLoaded = action.loaded;
      break;

    case CLEAR_TRANSACTION_DETAILS:
      newState.detailed = {
        amount: 0,
        amount_refunded: 0,
        application: '',
        application_fee: '',
        balance_transaction: '',
        captured: false,
        created: 0,
        currency: '',
        customer: null,
        description: '',
        destination: null,
        dispute: null,
        failure_code: null,
        failure_message: null,
        fraud_details: null,
        id: '',
        invoice: null,
        livemode: false,
        metadata: {
          rentalId: '',
          system: '',
          user: '',
        },
        object: '',
        on_behalf_of: null,
        order: null,
        outcome: {
          network_status: '',
          reason: null,
          risk_level: '',
          seller_message: '',
          type: '',
        },
        paid: false,
        receipt_email: null,
        receipt_number: null,
        refunded: false,
        refunds: {
          data: [],
          has_more: false,
          object: '',
          total_count: 0,
          url: '',
        },
        review: null,
        shipping: null,
        source: {
          address_city: null,
          address_country: null,
          address_line1: null,
          address_line1_check: null,
          address_line2: null,
          address_state: null,
          address_zip: '',
          address_zip_check: '',
          brand: '',
          country: '',
          customer: null,
          cvc_check: null,
          dynamic_last4: null,
          exp_month: 0,
          exp_year: 0,
          fingerprint: '',
          funding: '',
          id: '',
          last4: '',
        },
        source_transfer: null,
        statement_descriptor: null,
        status: '',
        transfer_group: null,
      };
      break;

    case WALLET_TRANSACTION_DETAILS:
      newState.detailedWalletTransaction = action.walletTransaction;
      newState.detailedWalletTransactionLoaded = true;
      break;

    case FORMATTED_WALLET_TRANSACTION_DETAILS:
      newState.formattedDetailedWalletTransaction = action.walletTransaction;
      break;

    case WALLET_TRANSACTION_DETAIL_LOADED:
      newState.walletTransactionDetailedLoaded = action.loaded;
      break;

    case CLEAR_WALLET_TRANSACTION_DETAILS:
      newState.detailedWalletTransaction = {
        amount: 0,
      };
      break;

    case HOLD_TRANSACTION_DETAILS:
      newState.holdDetails = action.transaction;
      break;

    case HOLD_TRANSACTION_DETAILS_LOADED:
      newState.holdDetailsLoaded = action.loaded;
      break;

    default:
      return state;
  }

  return newState;
}
