import React, { useEffect, useState } from 'react';
import { findAccess, formatNumberWithCommas, history } from '../../utils';
import useDashboardStats from '../../hooks/dashaboard/query/useDashboardStats';
import { IDashboardResumeProps } from '../../ts/interface/pages/dashboard.interface';
import { IDashboardStat } from '../../ts/interface/dashboardStats.interface';
import LineChartUp04Icon from '../../icons/untitled-ui/duocolor/line-chart-up-04';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import BikeScooterIcon from '@mui/icons-material/BikeScooter';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import QuickStats from '../../components/Redesing/quick-stats';
const DashboardResume = ({
  systemAccess,
  systemLoaded,
  mode,
  new_hardware,
  systemId,
}: IDashboardResumeProps) => {
  const [dashboardStats, setDashboardStats] = useState<IDashboardStat>(
    {} as IDashboardStat
  );
  const access = findAccess(systemAccess) || [];

  const { refetch } = useDashboardStats(systemId, {
    onSuccess: (data: IDashboardStat) => {
      setDashboardStats(data);
    },
    enabled: false,
  });

  useEffect(() => {
    if (systemLoaded && systemId) {
      refetch().then((res) => setDashboardStats(res.data));
    }
  }, [systemLoaded, refetch, systemId]);

  return (
    <div>
      <QuickStats
        sections={[
          {
            title: 'Active Rentals',
            id: 'active-rentals',
            value: formatNumberWithCommas(dashboardStats?.active_rentals || 0),
            icon: <DirectionsBikeIcon />,
            onClick: () => {
              if (access.includes('rental')) {
                history.push('/rentals?status=active');
              }
            },
          },
          {
            title: 'Total Rentals',
            id: 'total-rentals',
            value: formatNumberWithCommas(dashboardStats?.total_rentals || 0),
            icon: <LineChartUp04Icon />,
            onClick: () => {
              if (access.includes('rental')) {
                history.push('/rentals');
              }
            },
          },
          {
            title: 'Total Users',
            id: 'total-users',
            value: formatNumberWithCommas(dashboardStats?.total_users || 0),
            icon: <PeopleOutlineIcon />,
            onClick: () => {
              if (access.includes('users')) {
                history.push('/users');
              }
            },
          },
          {
            title: `Active ${mode?.details?.pluralUnits || ''}`,
            id: 'active-hardware',
            value: formatNumberWithCommas(dashboardStats?.active_hardware || 0),
            icon: <BikeScooterIcon />,
            onClick: () => {
              if (access.includes('physical')) {
                history.push(
                  new_hardware
                    ? `/hardware?type=${mode.details.hardware_type}&status=active`
                    : '/units/generalUnits'
                );
              }
            },
          },
        ]}
      />
    </div>
  );
};

export default DashboardResume;
