import { SUCCESS, CLEAR_SUCCESS, ERROR, CLEAR_ERROR } from '../constants.js';

const initialState = {
  error: null,
  success: null,
};

export default function toastReducer (state = initialState, action) {
  const newState = { ...state };
  switch (action.type) {
    case SUCCESS:
      newState.success = action.success;
      break;

    case ERROR:
      newState.error = action.error;
      break;

    case CLEAR_SUCCESS:
      newState.success = null;
      break;

    case CLEAR_ERROR:
      newState.error = null;
      break;

    default:
      return state;
  }

  return newState;
}
