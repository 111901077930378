import React, { ReactNode } from 'react';
import PageLoader from './page-loader';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useMounted } from '../../hooks/use-mounted';
import { ButtonInfo } from '../../ts/interface/components/redesing/table.interface';
import { Badge, Grid, SvgIcon, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { CSVLink } from 'react-csv';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Warning from './warnings';
import IconButtonMenu from './icon-button-menu';

interface PageContainerProps {
  isLoading?: boolean;
  children: ReactNode;
  spaceName?: string;
  buttons?: ButtonInfo[];
  showExport?: boolean;
  showImport?: boolean;
  importLabel?: string;
  onImportClick?: () => void;
  onExportClick?: () => void;
  warningMessage?: string | null;
  documentationLink?: string;
  csvReport?: {
    data: any[];
    headers: string[];
    filename: string;
  };
}

const PageContainer: React.FC<PageContainerProps> = ({
  isLoading,
  children,
  spaceName,
  buttons = [],
  showExport = false,
  showImport = false,
  importLabel = 'Import',
  onImportClick,
  onExportClick,
  warningMessage = '',
  documentationLink = '',
  csvReport = {
    data: [],
    headers: [],
    filename: 'export.csv',
  },
}) => {
  const isMounted = useMounted();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isLoading || !isMounted) return <PageLoader />;
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
      }}
    >
      <Stack
        padding={isMobile ? 0 : 2}
        spacing={showExport || showImport ? undefined : 2}
      >
        {warningMessage && (
          <Stack>
            <Warning message={warningMessage} />
          </Stack>
        )}
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Stack spacing={1}>
            <Stack direction="row">
              <Badge
                badgeContent={
                  documentationLink && (
                    <a
                      style={{ marginLeft: '0.5rem', marginTop: '0.938rem' }}
                      rel={'noreferrer'}
                      href={documentationLink}
                      target={'_blank'}
                    >
                      <i
                        className="fa fa-book"
                        aria-hidden="true"
                        style={{ color: theme.palette.text.primary }}
                      />
                    </a>
                  )
                }
              >
                <Typography variant="h4">{spaceName}</Typography>
              </Badge>
            </Stack>
            {(showExport || showImport) && (
              <Stack alignItems="center" direction="row" spacing={1} paddingBottom={1}>
                {showImport && (
                  <Stack alignItems="center" direction="row" spacing={1}>
                    <Button
                      onClick={onImportClick}
                      // color="inherit"
                      variant={'outlined'}
                      size="small"
                      startIcon={
                        <SvgIcon>
                          <FileUploadIcon />
                        </SvgIcon>
                      }
                    >
                      {importLabel}
                    </Button>
                  </Stack>
                )}
                {showExport && !onExportClick && (
                  <CSVLink {...csvReport} target="_blank">
                    <Button
                      sx={{
                        mr: 1,
                        py: 1,
                        px: 2.5,
                        textDecoration: 'none',
                      }}
                      size={'small'}
                      fullWidth={isMobile}
                      startIcon={<FileDownloadIcon />}
                      variant={'outlined'}
                    >
                      Export
                    </Button>
                  </CSVLink>
                )}
                {showExport && !!onExportClick && (
                  // <CSVLink {...csvReport} target="_blank">
                  <Button
                    onClick={onExportClick}
                    sx={{
                      mr: 1,
                      py: 1,
                      px: 2.5,
                      textDecoration: 'none',
                    }}
                    size={'small'}
                    fullWidth={isMobile}
                    startIcon={<FileDownloadIcon />}
                    variant={'outlined'}
                  >
                    Export
                  </Button>
                  // </CSVLink>
                )}
              </Stack>
            )}
          </Stack>
          <Stack>
            <Grid
              container
              spacing={1}
              justifyContent={'flex-end'}
              alignContent={'flex-end'}
            >
              <IconButtonMenu buttons={buttons} />
            </Grid>
          </Stack>
        </Stack>
        {children}
      </Stack>
    </Box>
  );
};

export default PageContainer;
