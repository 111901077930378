import { baseUrl, fetchOptions, fetchPostOptions, getCredentials } from './http';
import { convertCentsToMoney, displayError, timeForHumans } from '../utils';
import { FourOhOneError } from '../actionCreators/system';
import {
  ECoupon,
  ICoupon,
  ICouponAdd,
  ICouponEdit,
} from '../ts/interface/coupon.interface';

export const getCoupons = () => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);

  // @ts-ignore
  return fetch(`${baseUrl}/billing/coupons`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        const coupons = response.message.coupons.map((coupon: ICoupon) => {
          coupon.discountActual = coupon.discount;
          if (coupon.discount_type === ECoupon.PERCENT) {
            coupon.discount += '%';
          } else if (coupon.discount_type === ECoupon.TYPE) {
            coupon.discount = timeForHumans(coupon.discount) as string;
          } else {
            coupon.discount = convertCentsToMoney(coupon.discount);
          }
          if (coupon.duration == null) {
            coupon.uses === 1
              ? // @ts-ignore
                (coupon.uses += ECoupon.ONE_USE)
              : (coupon.uses += ECoupon.MULTIPLE_USE);
          } else {
            coupon.duration = timeForHumans(coupon.duration) as number;
          }
          return coupon;
        });

        const retired_coupons = response.message.retired_coupons.map(
          (coupon: ICoupon) => {
            if (coupon.discount_type === ECoupon.PERCENT) {
              coupon.discount += '%';
            } else if (coupon.discount_type === ECoupon.TYPE) {
              coupon.discount = timeForHumans(coupon.discount) as string;
            } else {
              coupon.discount = convertCentsToMoney(coupon.discount);
            }
            if (coupon.duration == null) {
              coupon.uses === 1
                ? // @ts-ignore
                  (coupon.uses += ECoupon.ONE_USE)
                : (coupon.uses += ECoupon.MULTIPLE_USE);
            } else {
              coupon.duration = timeForHumans(coupon.duration) as number;
            }
            return coupon;
          }
        );
        return { coupons, retired_coupons };
      }
      throw new Error(response?.message);
    })
    .catch(() => {
      displayError(
        'Oops! Looks like there was an error while loading the coupons. Please try again later.'
      );
    });
};

export const addCoupon = (coupon: ICouponAdd) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, coupon, 'POST');
  // @ts-ignore
  return fetch(`${baseUrl}/billing/coupons/add`, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        return results;
      }
      FourOhOneError(results);
      throw new Error(results?.message);
    })
    .catch((error) => {
      displayError(
        `Oops! Looks like there was an error while adding the coupon. Please try again later. ${error}`
      );
    });
};

export const updateCoupon = (coupon: ICouponEdit) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, coupon, 'PUT');
  // @ts-ignore
  return fetch(`${baseUrl}/billing/coupon/update`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        console.log(response.message);
        return response.message;
      }
      FourOhOneError(response);
      throw new Error(response?.message);
    })
    .catch((error) => {
      return Promise.reject(`${error}`);
    });
};
