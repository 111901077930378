import {
  // USER TABLE SEARCH
  ACTIVE_USERS_SEARCH,
  BANNED_USERS_SEARCH,
  AUTH_PENDING_SEARCH,
  EMAIL_PENDING_SEARCH,
  USER_RENTAL_SEARCH,
  CLEAR_SEARCHES,
  USER_MEMBERSHIP_SEARCH,
  USER_RFID_CARD_SEARCH,
  // RENTAL TABLE SEARCH
  COMPLETED_RENTAL_SEARCH,
  ACTIVE_RENTAL_SEARCH,
  // HARDWARE TABLE SEARCH
  HARDWARE_SEARCH,
  HARDWARE_RENTAL_SEARCH,
  LOCK_SEARCH,
  HARDWARE_TICKET_SEARCH,
  HARDWARE_LOG_SEARCH,
  MODULE_TICKET_SEARCH,
  MODULE_LOG_SEARCH,
  // STATION TABLE SEARCH
  STATION_SEARCH,
  // PROBLEM TABLE SEARCH
  PROBLEM_OPEN_SEARCH,
  PROBLEM_CLOSED_SEARCH,
  // MAINTENANCE TABLE SEARCH
  MAINTENANCE_OPEN_SEARCH,
  MAINTENANCE_SCHEDULED_SEARCH,
  MAINTENANCE_CLOSED_SEARCH,
  MAINTENANCE_CANCELED_SEARCH,
  MAINTENANCE_ALL_SEARCH,
  MAINTENANCE_TASK_SEARCH,
  // BILLING TABLE SEARCH
  BILLING_TRANSACTION_SEARCH,
  BILLING_DISPUTE_SEARCH,
  // SETTING
  SETTINGS_ADMIN_SEARCH,
  // COUPONS
  COUPON_SEARCH,
  RETIRED_COUPON_SEARCH,
  // RESERVATIONS
  COMPLETED_RESERVATION_SEARCH,
  PENDING_RESERVATION_SEARCH,
  ASSIGNED_RESERVATION_SEARCH,
  CANCELED_RESERVATION_SEARCH,
  // MEMBERSHIPS
  PENDING_MEMBERSHIP_SEARCH,
  ACTIVE_MEMBERSHIP_SEARCH,
  HISTORIC_MEMBERSHIP_SEARCH,
  PENDING_MEMBER_SEARCH,
} from '../constants';

const initialState = {
  // USERS
  activeSearch: '',
  bannedSearch: '',
  authSearch: '',
  emailSearch: '',
  userRentalSearch: '',
  userMembershipSearch: '',
  userRfidCardSearch: '',
  // RENTALS
  activeRentalSearch: '',
  completedRentalSearch: '',
  pendingChargesSearch: '',
  // HARDWARE
  hardwareSearch: '',
  hardwareRentalSearch: '',
  lockSearch: '',
  hardwareTicketSearch: '',
  hardwareLogSearch: '',
  moduleTicketSearch: '',
  moduleLogSearch: '',
  // STATIONS
  stationSearch: '',
  // PROBLEMS
  problemOpenSearch: '',
  problemClosedSearch: '',
  // MAINTENANCE
  maintenanceOpenSearch: '',
  maintenanceScheduledSearch: '',
  maintenanceClosedSearch: '',
  maintenanceCanceledSearch: '',
  maintenanceTaskSearch: '',
  // BILLING
  billingTransactionSearch: '',
  billingDisputeSearch: '',
  // SETTINGS
  settingAdminSearch: '',
  // COUPON
  couponSearch: '',
  retiredCouponSearch: '',
  // RESERVATIONS
  pendingReservationSearch: '',
  canceledReservationSearch: '',
  completedReservationSearch: '',
  assignedReservationSearch: '',
  // MEMBERSHIPS
  pendingMembershipSearch: '',
  historicMembershipSearch: '',
  activeMembershipSearch: '',
  pendingMemberSearch: '',
};

export default function searchReducer (state = initialState, action) {
  const newState = { ...state };
  switch (action.type) {
    case CLEAR_SEARCHES:
      newState.activeSearch = '';
      newState.bannedSearch = '';
      newState.authSearch = '';
      newState.emailSearch = '';
      newState.userRentalSearch = '';
      newState.userMembershipSearch = '';
      newState.userRfidCardSearch = '';
      newState.activeRentalSearch = '';
      newState.completedRentalSearch = '';
      newState.hardwareSearch = '';
      newState.hardwareRentalSearch = '';
      newState.lockSearch = '';
      newState.stationSearch = '';
      newState.problemOpenSearch = '';
      newState.problemClosedSearch = '';
      newState.maintenanceOpenSearch = '';
      newState.maintenanceScheduledSearch = '';
      newState.maintenanceClosedSearch = '';
      newState.maintenanceCanceledSearch = '';
      newState.maintenanceAllSearch = '';
      newState.maintenanceTaskSearch = '';
      newState.billingTransactionSearch = '';
      newState.billingDisputeSearch = '';
      newState.settingAdminSearch = '';
      newState.couponSearch = '';
      newState.retiredCouponSearch = '';
      newState.pendingReservationSearch = '';
      newState.canceledReservationSearch = '';
      newState.completedReservationSearch = '';
      newState.assignedReservationSearch = '';
      newState.pendingMembershipSearch = '';
      newState.activeMembershipSearch = '';
      newState.historicMembershipSearch = '';
      newState.pendingMemberSearch = '';
      newState.hardwareLogSearch = '';
      newState.hardwareTicketSearch = '';
      newState.moduleLogSearch = '';
      newState.moduleTicketSearch = '';
      newState.pendingChargesSearch = '';
      break;

    case ACTIVE_USERS_SEARCH:
      newState.activeSearch = action.searchText;
      break;

    case BANNED_USERS_SEARCH:
      newState.bannedSearch = action.searchText;
      break;

    case AUTH_PENDING_SEARCH:
      newState.authSearch = action.searchText;
      break;

    case EMAIL_PENDING_SEARCH:
      newState.emailSearch = action.searchText;
      break;

    case USER_RENTAL_SEARCH:
      newState.userRentalSearch = action.searchText;
      break;

    case USER_MEMBERSHIP_SEARCH:
      newState.userMembershipSearch = action.searchText;
      break;

    case USER_RFID_CARD_SEARCH:
      newState.userRfidCardSearch = action.searchText;
      break;

    case ACTIVE_RENTAL_SEARCH:
      newState.activeRentalSearch = action.searchText;
      break;

    case COMPLETED_RENTAL_SEARCH:
      newState.completedRentalSearch = action.searchText;
      break;

    case HARDWARE_SEARCH:
      newState.hardwareSearch = action.searchText;
      break;

    case HARDWARE_RENTAL_SEARCH:
      newState.hardwareRentalSearch = action.searchText;
      break;

    case LOCK_SEARCH:
      newState.lockSearch = action.searchText;
      break;

    case HARDWARE_TICKET_SEARCH:
      newState.hardwareTicketSearch = action.searchText;
      break;

    case HARDWARE_LOG_SEARCH:
      newState.hardwareLogSearch = action.searchText;
      break;

    case MODULE_TICKET_SEARCH:
      newState.moduleTicketSearch = action.searchText;
      break;

    case MODULE_LOG_SEARCH:
      newState.moduleLogSearch = action.searchText;
      break;

    case STATION_SEARCH:
      newState.stationSearch = action.searchText;
      break;

    case PROBLEM_OPEN_SEARCH:
      newState.problemOpenSearch = action.searchText;
      break;

    case PROBLEM_CLOSED_SEARCH:
      newState.problemClosedSearch = action.searchText;
      break;

    case MAINTENANCE_OPEN_SEARCH:
      newState.maintenanceOpenSearch = action.searchText;
      break;

    case MAINTENANCE_SCHEDULED_SEARCH:
      newState.maintenanceScheduledSearch = action.searchText;
      break;

    case MAINTENANCE_CLOSED_SEARCH:
      newState.maintenanceClosedSearch = action.searchText;
      break;

    case MAINTENANCE_CANCELED_SEARCH:
      newState.maintenanceCanceledSearch = action.searchText;
      break;

    case MAINTENANCE_ALL_SEARCH:
      newState.maintenanceAllSearch = action.searchText;
      break;

    case MAINTENANCE_TASK_SEARCH:
      newState.maintenanceTaskSearch = action.searchText;
      break;

    case BILLING_TRANSACTION_SEARCH:
      newState.billingTransactionSearch = action.searchText;
      break;

    case BILLING_DISPUTE_SEARCH:
      newState.billingDisputeSearch = action.searchText;
      break;

    case SETTINGS_ADMIN_SEARCH:
      newState.settingAdminSearch = action.searchText;
      break;

    case COUPON_SEARCH:
      newState.couponSearch = action.searchText;
      break;

    case RETIRED_COUPON_SEARCH:
      newState.retiredCouponSearch = action.searchText;
      break;

    case COMPLETED_RESERVATION_SEARCH:
      newState.completedReservationSearch = action.searchText;
      break;
    case ASSIGNED_RESERVATION_SEARCH:
      newState.assignedReservationSearch = action.searchText;
      break;
    case PENDING_RESERVATION_SEARCH:
      newState.pendingReservationSearch = action.searchText;
      break;
    case CANCELED_RESERVATION_SEARCH:
      newState.canceledReservationSearch = action.searchText;
      break;

    case HISTORIC_MEMBERSHIP_SEARCH:
      newState.historicMembershipSearch = action.searchText;
      break;

    case ACTIVE_MEMBERSHIP_SEARCH:
      newState.activeMembershipSearch = action.searchText;
      break;

    case PENDING_MEMBERSHIP_SEARCH:
      newState.pendingMembershipSearch = action.searchText;
      break;

    case PENDING_MEMBER_SEARCH:
      newState.pendingMemberSearch = action.searchText;
      break;
    default:
      return state;
  }

  return newState;
}
