import type { FC } from 'react';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { SeverityPill } from './severity-pill';
import { SeverityPillColor } from '../../ts/types/settings';
import CardLoader from './card-loader';

interface ISection {
  title: string;
  id?: string;
  value: string | number;
  behaviorValue?: string | number;
  behaviorColor?: SeverityPillColor;
  icon?: React.ReactNode;
  onClick?: () => void; // Add onClick function
}

interface IStatsProps {
  sections: ISection[];
  isLoading?: boolean;
}

const QuickStats: FC<IStatsProps> = ({ sections, isLoading }) => (
  <Stack>
    <Grid container spacing={2}>
      {sections.map((section, index) => (
        <Grid
          xs={12}
          md={6}
          lg={3}
          key={section.title}
          id={`dashboard-grid-${section?.id}`}
        >
          <Card
            sx={{
              ...(section.onClick && {
                transition: 'transform 0.2s',
                cursor: 'pointer',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }),
            }}
            onClick={section.onClick}
          >
            {isLoading ? (
              <CardLoader useCard />
            ) : (
              <Stack alignItems="center" direction="row" spacing={2} sx={{ p: 2 }}>
                <Stack spacing={1} sx={{ flexGrow: 1 }}>
                  <Typography color="text.secondary" variant="overline">
                    {section.title}
                  </Typography>
                  <Stack alignItems="center" direction="row" spacing={1}>
                    <Typography variant="h5">{section.value}</Typography>
                    {section.behaviorValue && (
                      <SeverityPill color={section.behaviorColor || 'success'}>
                        {section.behaviorValue}
                      </SeverityPill>
                    )}
                  </Stack>
                </Stack>
                {section.icon && (
                  <Avatar
                    sx={{
                      backgroundColor: 'primary.main',
                      color: 'primary.contrastText',
                      height: 48,
                      width: 48,
                    }}
                  >
                    {section.icon}
                  </Avatar>
                )}
              </Stack>
            )}
          </Card>
        </Grid>
      ))}
    </Grid>
  </Stack>
);

export default QuickStats;
