import React from 'react';
import { connect } from 'react-redux';
import NewHardware from './newHardware';
import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import PageContainer from '../../components/Redesing/page-container';
import { Stack } from '@mui/system';
import Box from '@mui/material/Box';

// TODO: when old hardware is retired, delete the two imported files and replace this with what was in newHardware.jsx

export function HardwareIndex(props) {
  if (props.system.new_hardware) {
    return (
      <NewHardware
        params={props.match.params}
        routes={props.routes}
        route={props.route}
        location={props.location}
      />
    );
  }

  return (
    <PageContainer
      isLoading={!props.systemLoaded}
      documentationLink={'https://documentation.movatic.co/admin-site/modes'}
      spaceName={'Hardware'}
    >
      <Paper elevation={3} style={{ padding: '16px' }}>
        <Stack alignItems={'center'}>
          <div style={{ maxWidth: '100%', overflow: 'hidden' }}>
            <img
              src={'/assets/hardware_banner.png'}
              alt={''}
              style={{ width: '100%', height: 'auto', display: 'block' }}
            />
          </div>
          <Typography variant="body1">
            Your system is using a legacy hardware configuration that is no longer
            supported. Please contact{' '}
            <Box fontWeight="bold" component="span">
              support@movatic.co
            </Box>{' '}
            to create a migration plan.
          </Typography>
        </Stack>
      </Paper>
    </PageContainer>
  );
}

export default connect((state) => ({
  system: state.system.current,
  systemLoaded: state.system.isLoaded,
}))(HardwareIndex);
