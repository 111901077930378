import React, { useEffect, useMemo, useState } from 'react';
import { MOVATIC_PRIMARY_COLOR } from '../../../constants';
import MovaticCustomModal from '../../../components/Modal/MovaticCustomModal';
import {
  getAllSystemsV2,
  getStripeAccountInfo,
  getStripeOnboardingLink,
  updateSystem,
} from '../../../api/system';
import { getCredentials } from '../../../api/http';
import Typography from '@mui/material/Typography';
import FormItem from '../../../components/Redesing/form-item';
import CardView from '../../../components/Redesing/card-view';
import { Stack } from '@mui/system';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import VerifiedIcon from '@mui/icons-material/Verified';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import customToast from '../../../components/Redesing/custom-async-toast';
import { MovaticFieldGroupAsyncSelect } from '../../../components/Redesing/movatic-field-group';
import useSearchDebounce from '../../../hooks/search/useSearchDebounce';
import useSystem from '../../../hooks/system/query/useSystem';

let stripeAccountInitialObj = {
  business_type: '',
  requirements: {
    currently_due: [],
  },
  tos_acceptance: {
    date: null,
  },
};

const AccountVerification = (props) => {
  const { systemGid, systemId } = getCredentials();
  const [stripeAccountInfo, setStripeAccountInfo] = useState(stripeAccountInitialObj);
  const [showModal, setShowModal] = useState(false);
  const [availableSystems, setAvailableSystems] = useState([]);
  const [selectedSystem, setSelectedSystem] = useState(null);
  const [stripeAccountToClone, setStripeAccountToClone] = useState(null);
  const [open, setOpen] = useState(false);
  const [notAccount, setNotAccount] = useState(false);
  const [notAccountMessage, setNotAccountMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = React.useState('');
  const debouncedSearchTerm = useSearchDebounce(search);

  const { data: currentSystemLoaded } = useSystem(systemId, {
    enabled: !!systemId,
  });

  useEffect(() => {
    getStripeAccountInfo(systemId).then((r) => {
      if (r) {
        setStripeAccountInfo(r);
      }
    });
  }, [systemId]);

  useEffect(() => {
    if (open) {
      setLoading(true);
      getAllSystemsV2({
        search: debouncedSearchTerm,
      }).then((response) => {
        const { systems } = response;
        setAvailableSystems(
          systems.filter((item) => item.gid !== systemGid && item.liveStripeAccount)
        );
        setLoading(false);
      });
    }
  }, [open, systemGid, debouncedSearchTerm]);

  useEffect(() => {
    if (selectedSystem) {
      getStripeAccountInfo(selectedSystem.id).then((r) => {
        if (!r) {
          setNotAccount(true);
          setNotAccountMessage('Stripe account not found');
        } else {
          cleanErrorMessage();
        }
        setStripeAccountToClone(r);
      });
    }
  }, [selectedSystem]);

  const cleanErrorMessage = () => {
    setNotAccount(false);
    setNotAccountMessage(null);
  };

  const closeModal = () => {
    setStripeAccountToClone(null);
    setAvailableSystems([]);
    setSelectedSystem(null);
    setOpen(false);
    setShowModal(false);
    setSearch('');
  };

  const onboardLinkV2 = (type) => {
    getStripeOnboardingLink(type).then((res) => {
      if (res) {
        window.open(res.url, '_blank');
      }
    });
  };

  const createStripeAccount = (business_type) => {
    const body = {
      business_type,
      country: props.country,
    };

    props.createStripeAccount(body).then((res) => {
      if (res) {
        onboardLinkV2('verification');
      }
    });
  };

  const submit = () => {
    const payloadSystem = {
      ...currentSystemLoaded,
      liveStripeAccount: stripeAccountToClone.id,
    };
    customToast({
      promise: () => updateSystem(payloadSystem),
      loadingMessage: 'Importing...',
      successMessage: () => {
        return 'Stripe account was successfully copied!';
      },
      errorMessage: (e) => {
        return `Opps! Looks like there was an error while updating this system. Please try again later. ${e}`;
      },
    }).then(() => {
      setStripeAccountInfo(stripeAccountToClone);
      closeModal();
    });
  };

  const button = () => {
    const { id, tos_acceptance } = stripeAccountInfo;
    // no stripe account yet
    if (!id) return null;

    // per Stripe's docs: must have accepted TOS to get an update link
    const link_type = tos_acceptance?.date ? 'update' : 'verification';
    const buttons = [];
    if (link_type === 'verification') {
      buttons.push({
        label: 'Import Stripe Account',
        onClick: () => setShowModal(true),
        disabled: !props.saveButtonLoading || (!props.bankAccess && !props.movaticAccess),
        startIcon: <ContentCopyIcon />,
      });
    }

    buttons.push({
      label: tos_acceptance?.date ? 'Update' : 'Verify',
      onClick: () => onboardLinkV2(link_type),
      disabled: !props.saveButtonLoading || (!props.bankAccess && !props.movaticAccess),
      startIcon: tos_acceptance?.date ? <EditIcon /> : <VerifiedIcon />,
    });

    return <IconButtonMenu buttons={buttons} />;
  };

  const stripeAccountDetail = (stripeAccount) => {
    const { country, business_type } = stripeAccount;
    const details = stripeAccount[business_type];
    return (
      <>
        {!details ? (
          <div />
        ) : (
          <div>
            {business_type === 'company' ? (
              <div>
                <Typography data-id="business-type" sx={{ pt: 1 }} variant="h6">
                  Business
                </Typography>
                <FormItem
                  data-id="business-name-item"
                  label={'Name:'}
                  content={`${details.name || 'Please add'}`}
                />
                <FormItem
                  data-id="business-tax-id-item"
                  label="Tax ID Provided:"
                  content={`${details.tax_id_provided ? 'Yes' : 'Please add'}`}
                />
                <FormItem
                  data-id="business-address-item"
                  label="Address:"
                  content={
                    !details.address.line1
                      ? 'Please add'
                      : `${details.address.line1 || ''} 
                                                ${
                                                  details.address.line2
                                                    ? details.address.line2
                                                    : ''
                                                }
                                                ${`${details.address.city},` || ''} 
                                                ${details.address.state || ''} 
                                                ${details.address.postal_code || ''}`
                  }
                />
              </div>
            ) : (
              <div>
                <Typography data-id="individual-type" sx={{ pt: 1 }} variant="h6">
                  Representative
                </Typography>

                <FormItem
                  label={'Name:'}
                  content={`${details.first_name || 'Please add'} ${
                    details.last_name || ''
                  }`}
                />
                <FormItem
                  label="Date of Birth (mm/dd/yyyy):"
                  content={
                    details.dob.month
                      ? `${details.dob.month}/${details.dob.day}/${details.dob.year}`
                      : 'Please add'
                  }
                />

                {details.id_number_provided && country !== 'US' ? (
                  // might not be a requirement for all countries, irrelevant for US
                  <FormItem label="Personal ID Provided:" content={'Yes'} />
                ) : null}
                {country === 'US' ? (
                  <FormItem
                    label="SSN provided:"
                    content={`${details.ssn_last_4_provided ? 'Yes' : 'Please add'}`}
                  />
                ) : null}
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  const content = () => {
    const { id, requirements } = stripeAccountInfo;
    if (!id) {
      // Stripe account hasn't been created
      return (
        <div>
          <Typography color="text.secondary" sx={{ mt: 1 }} variant="body2">
            Information is needed to verify your account and enable billing. Click one of
            the buttons below to be redirected to Stripe's secure account verification.
            For most systems, you will verify as a company rather than an individual. If
            you are not sure which is appropriate for your system, please contact
            support@movatic.co
          </Typography>

          <Typography color="text.secondary" sx={{ mt: 1 }} variant="body2">
            Please wait to be redirected after clicking the button. This may take several
            seconds.
          </Typography>

          <div className="flexDisplay justifyCenter" style={{ paddingTop: '8px' }}>
            <IconButtonMenu
              buttonLimit={3}
              slice={3}
              buttons={[
                {
                  label: 'Import Stripe Account',
                  onClick: () => setShowModal(true),
                  disabled:
                    !props.saveButtonLoading ||
                    (!props.bankAccess && !props.movaticAccess),
                  startIcon: <ContentCopyIcon />,
                },
                {
                  label: 'Verify as Individual',
                  onClick: () => createStripeAccount('individual'),
                  disabled:
                    !props.saveButtonLoading ||
                    (!props.bankAccess && !props.movaticAccess),
                  startIcon: <VerifiedIcon />,
                },
                {
                  label: 'Verify as Company',
                  onClick: () => createStripeAccount('company'),
                  disabled:
                    !props.saveButtonLoading ||
                    (!props.bankAccess && !props.movaticAccess),
                  startIcon: <VerifiedIcon />,
                },
              ]}
            />
          </div>
        </div>
      );
    }

    return (
      <div>
        {requirements.currently_due.length > 1 ||
        (requirements.currently_due.length === 1 &&
          requirements.currently_due[0] !== 'external_account') ? (
          <Typography color="text.secondary" sx={{ mt: 1 }} variant="body2">
            Additonal information is needed to complete account verification. Please click
            the button above to be redirected to Stripe's secure account verfication and
            submit the remaining information.
          </Typography>
        ) : null}

        <>{stripeAccountDetail(stripeAccountInfo)}</>
      </div>
    );
  };

  const modalContent = () => {
    return (
      <>
        {showModal && (
          <MovaticCustomModal
            customStyles={{
              title: {
                color: `${MOVATIC_PRIMARY_COLOR(1)}`,
                fontWeight: '600',
                paddingRight: '5rem',
              },
              action: {
                pb: 1,
                pt: 1,
              },
            }}
            open={showModal}
            onClose={closeModal}
            title="Import Stripe Account"
            content={
              <Stack pt={2}>
                <MovaticFieldGroupAsyncSelect
                  id={'system-name'}
                  isLoading={loading}
                  label="System"
                  description={'Select a system to copy Stripe account from.'}
                  name={'system-name'}
                  open={open}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  getOptionText={(option) => option.name}
                  options={availableSystems}
                  hasError={notAccount !== null}
                  errorMessage={notAccountMessage}
                  handleSearch={(search) => setSearch(search.target.value)}
                  onChange={(value, newValue) => {
                    setSelectedSystem(newValue);
                    if (!newValue) {
                      setStripeAccountToClone(null);
                      cleanErrorMessage();
                    }
                  }}
                />
                <div>
                  {stripeAccountToClone && (
                    <>{stripeAccountDetail(stripeAccountToClone)}</>
                  )}
                </div>
              </Stack>
            }
            action={
              <>
                <IconButtonMenu
                  buttons={[
                    {
                      label: 'Cancel',
                      onClick: closeModal,
                      startIcon: <CloseIcon />,
                    },
                    {
                      label: 'Submit',
                      onClick: submit,
                      disabled: !selectedSystem && !stripeAccountToClone,
                      startIcon: <SaveIcon />,
                      dataId: 'submit-button',
                    },
                  ]}
                />
              </>
            }
          />
        )}
      </>
    );
  };

  const needsStripeAccount = useMemo(() => {
    if (!currentSystemLoaded) {
      return false;
    }

    return (
      (Boolean(stripeAccountInfo?.requirements?.currently_due?.length) &&
        Boolean(currentSystemLoaded.billingLive)) ||
      (Boolean(currentSystemLoaded.billingLive) &&
        currentSystemLoaded.payment_provider === 'stripe' &&
        !Boolean(stripeAccountInfo))
    );
  }, [currentSystemLoaded, stripeAccountInfo]);

  return (
    <>
      <CardView
        dataId="stripe-account-info"
        content={
          <Stack px={2} pb={2}>
            <>
              {content()}
              {modalContent()}
            </>
          </Stack>
        }
        title={`Stripe Account Information`}
        headerActions={button()}
        warningMessage={
          needsStripeAccount && currentSystemLoaded.payment_provider === 'stripe'
            ? 'Missing Stripe information'
            : ''
        }
      />
    </>
  );
};

export default AccountVerification;
