import React from 'react';
import MyMap from '../../components/mapComponent';
import { ZONE_LEGEND_LABELS } from '../../constants';
import { Grid } from '@mui/material';
import useIsMobileDevice from '../../hooks/mobileDevices/useIsMobileDevice';
import DashboardMapLegend from './assets/dashboard-map-legend';
import { IDashboardMapProps } from '../../ts/interface/pages/dashboard.interface';

const DashboardMap = ({
  stations,
  units,
  hardware_type,
  systemAccess,
  isLoading,
}: IDashboardMapProps) => {
  const isMobile = useIsMobileDevice();

  const shouldRenderZonesLegend = !!stations?.find(
    (station) => station.polygon_coordinates
  );

  return (
    <>
      <MyMap
        loaded={!isLoading}
        stations={stations || []}
        units={units || []}
        height="50vh"
        systemType={hardware_type}
        systemAccess={systemAccess}
        isMobile={isMobile}
      />
      {isMobile && (
        <Grid
          marginTop={0.1}
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
          spacing={1}
        >
          {shouldRenderZonesLegend && (
            <Grid item>
              <DashboardMapLegend title={'Zones'} legendItems={ZONE_LEGEND_LABELS} />
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default DashboardMap;
