import {
  GET_COUPONS_SUCCESS,
  SET_COUPON_DETAILS,
  COUPON_DETAIL_LOADING,
} from '../constants.js';

const initalState = {
  addLoading: false,
  isLoaded: false,
  couponDetailLoaded: false,
  coupons: [],
  retiredCoupons: [],
  coupon: null,
};

export default function couponReducer (state = initalState, action) {
  const newState = { ...state };

  switch (action.type) {
    case GET_COUPONS_SUCCESS:
      newState.coupons = action.coupons.coupons;
      newState.retiredCoupons = action.coupons.retired_coupons;
      newState.isLoaded = true;
      break;

    case SET_COUPON_DETAILS:
      newState.coupon = action.coupon;
      newState.couponDetailLoaded = true;
      break;

    case COUPON_DETAIL_LOADING:
      newState.couponDetailLoaded = false;
      break;

    default:
      return state;
  }

  return newState;
}
