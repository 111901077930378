import { useState, useEffect } from 'react';
import bugsnagClient from '../../components/bugsnag';

const useIpifyData = () => {
  const [ipAddress, setIpAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const ipifyUrl = 'https://api.ipify.org?format=json';

  useEffect(() => {
    const fetchData = async () => {
      // Retry 3 times to get the IP address
      for (let retryCount = 0; retryCount < 3 && !ipAddress; retryCount++) {
        try {
          const response = await fetch(ipifyUrl as string);
          const data = await response.json();
          if (data.ip) {
            setIpAddress(data.ip);
          }
        } catch (error) {
          bugsnagClient.notify(`Error getting ip address: ${error}`);
        }
      }

      setIsLoading(false);
    };

    fetchData();
  }, [ipAddress]);

  return { ipAddress, isLoading };
};

export default useIpifyData;
