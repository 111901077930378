import {
  API_HARDWARE_PRODUCT_SUCCESS,
  API_PRODUCT_VERSION_SUCCESS,
  API_PRODUCT_FIELD_SUCCESS,
  PRODUCT_LOADED,
  PRODUCT_VERSION_LOADED,
  PRODUCT_FIELD_LOADED,
} from '../constants';

const initialState = {
  hardwareProducts: [],
  productVersions: [],
  productFields: [],
  productLoaded: false,
  versionLoaded: false,
  fieldsLoaded: false,
};

export default function hardwareProductReducer (state = initialState, action) {
  const newState = { ...state };

  switch (action.type) {
    case PRODUCT_LOADED:
      newState.productLoaded = action.loaded;
      break;

    case PRODUCT_VERSION_LOADED:
      newState.versionLoaded = action.loaded;
      break;

    case PRODUCT_FIELD_LOADED:
      newState.fieldsLoaded = action.loaded;
      break;

    case API_HARDWARE_PRODUCT_SUCCESS:
      newState.hardwareProducts = action.hardwareProducts;
      newState.productLoaded = true;
      break;

    case API_PRODUCT_VERSION_SUCCESS:
      newState.productVersions = action.productVersions;
      newState.versionLoaded = true;
      break;

    case API_PRODUCT_FIELD_SUCCESS:
      newState.productFields = action.productFields;
      newState.fieldsLoaded = true;
      break;

    default:
      return state;
  }

  return newState;
}
