import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAllImages } from '../../actionCreators/system';
import PageContainer from '../../components/Redesing/page-container';
import { PaginationTable } from '../../components/Redesing/table/pagination-table';
import CardView from '../../components/Redesing/card-view';
import { Stack } from '@mui/system';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { imageApproval } from '../../api/system';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants';

interface Image {
  id: number;
  type: string;
  image: string;
  created: string;
  approved_on: string | null;
  rejected: boolean | null;
}

interface ImagesProps {
  images: Image[];
  getAllImages: () => void;
}

const Images: React.FC<ImagesProps> = ({ images, getAllImages }) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('created');

  const imageColumnsV2 = () => [
    {
      id: 'image',
      label: 'Image',
      format: (row: Image) => (
        <img
          alt="logo"
          className={`${
            row.type === 'SystemLogo' || row.type === 'MembershipLogo'
              ? 'logoRadius'
              : 'bannerImg'
          }`}
          src={`data:image/png;base64,${row.image}`}
        />
      ),
    },
    {
      id: 'created',
      label: 'Created',
      searchable: true,
      sortable: true,
    },
    {
      id: 'type',
      label: 'Type',
      searchable: true,
      sortable: true,
    },
    {
      id: 'id',
      label: 'Action',
      width: '20%',
      format: (row: Image) => (
        <Stack justifyContent={'flex-start'} alignItems={'flex-start'}>
          <IconButtonMenu
            slice={2}
            buttons={[
              {
                label: 'Reject',
                onClick: () => handleClick(row, 'Reject'),
                startIcon: <CloseIcon />,
              },
              {
                label: 'Accept',
                onClick: () => handleClick(row, 'Accept'),
                startIcon: <CheckCircleIcon />,
              },
            ]}
          />
        </Stack>
      ),
    },
  ];

  const handleClick = (row: Image, approval: string) => {
    CustomAsyncToast({
      promise: () => imageApproval(approval, row.id),
      successMessage: () =>
        `Image ${approval === 'Reject' ? 'rejected' : 'approved'} successfully!`,
      errorMessage: `There was an error while ${
        approval === 'Reject' ? 'rejecting' : 'approving'
      } this image.`,
      loadingMessage: `${approval === 'Reject' ? 'Tejecting' : 'Approving'} image...`,
    }).then(() => {
      getAllImages();
    });
  };

  useEffect(() => {
    getAllImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filteredImages = images.filter(
      (image) => image.approved_on == null && image.rejected == null
    );
    filteredImages.forEach((image) => {
      image.created = new Date(image.created).toDateString();
      return image;
    });
  }, [images]);

  return (
    <PageContainer>
      <CardView
        content={
          <PaginationTable
            handleSearch={(e) => setSearch(e.target.value)}
            searchText={search}
            sortBy={sortBy}
            onSortChange={(sort) => setSortBy(sort.key)}
            columns={imageColumnsV2()}
            items={images || []}
            onPageChange={(page, pageN) => setPageNumber(pageN)}
            onRowsPerPageChange={(row) => {
              const pageSize = Number(row.target.value);
              setSizePerPage(pageSize);
            }}
            page={pageNumber}
            rowsPerPage={sizePerPage}
            showFilter={false}
            noDataText={'No images to approve at this time.'}
          />
        }
        title={'Images Approval'}
      />
    </PageContainer>
  );
};

export default connect(
  (state: any) => ({
    images: state.system.allImages,
    isLoaded: state.system.allImagesLoaded,
    currentSystemId: state.system.current.id,
    auth: state.admin.admin,
    imagePage: state.table.imagePage,
    imageSize: state.table.imageSize,
  }),
  (dispatch: Function) => ({
    getAllImages() {
      dispatch(getAllImages());
    },
  })
)(Images);
