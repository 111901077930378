import { IHardware } from './hardware.interface';
import { IRate } from './rental.interface';

export interface IStation {
  hardware: IHardware[];
  polygon_coordinates: { lat: number; lng: number }[] | number[][];
  lat?: string;
  lng?: string;
  id: string;
  type?: string;
  gid: string;
  state: number;
  name: string;
  configuration: string;
  old_id: string;
  available_hardware: number;
  capacity: number;
  totalHardware: number;
  hardware_type: string;
  module: string;
  zone_type: string;
  parking_credit: number;
  slow_zone_speed: number | null;
  radius: number | null;
  prevent_ending_rentals: boolean;
  prevent_starting_rentals: boolean;
  reservations: boolean;
  maxReservations: number;
  banner_id: string;
  searchHardwareValue: string;
  rates?: IRate[];
  private: boolean;
}

export enum StationConfiguration {
  ZONE = 'zone',
  RACK = 'rack',
  DOCK = 'dock',
  STORE = 'store',
  WAREHOUSE = 'warehouse',
}

export interface IStationImage {
  id: string;
  system: string;
}

export interface IUseStationParameters {
  system_id?: string | null;
  locationId?: string | null;
  limit?: number | null;
  lastId?: string | null;
  status?: string | null;
  pattern?: string | null;
  trigger?: boolean;
  private_only?: boolean;
  order?: string;
  order_by?: string;
  pageNumber?: number;
  fields?: string;
}

export enum StationState {
  ALL = -1,
  UNASSIGNED = 0,
  ACTIVE = 1,
  INACTIVE = 2,
  MAINTENANCE = 3,
  RETIRED = 4,
  ESERVED = 5,
  REBALANCE = 6,
  MISSING = 7,
  LOST = 8,
  STORED = 9,
  private = 10,
}

export interface UseGetStationStats {
  location_uuid: string;
  system_gid: string;
}

export interface LocationStats {
  avg_rental_ended_per_user: number; // decimal 0.00
  avg_rental_started_per_user: number; // decimal 0.00
  total_rentals_ended: number;
  total_rentals_started: number;
}

export interface INewStation {
  id?: string;
  name: string;
  lat: number | null;
  lng: number | null;
  state: number;
  type?: number;
  reservations: boolean;
  maxReservations: number;
  configuration?: StationConfiguration | null;
  polygon_coordinates: number[][] | null;
  zone_type?: string | null;
  slow_zone_speed: number | null;
  radius: number | null;
  prevent_ending_rentals: boolean;
  prevent_starting_rentals: boolean;
  rates_id: string[] | null;
  capacity?: number | null;
  module?: string | null;
  private?: boolean;
  banner_id?: number | null;
}

export interface ICoordinates {
  lat: number;
  lng: number;
}
