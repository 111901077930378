import { useQuery } from '@tanstack/react-query';
import { getTransactionDetails } from '../../../api/transactions';

const useTransaction = (id?: string, options?: {}) => {
  return useQuery(['transactionDetails', id], () => getTransactionDetails(id), {
    ...options,
  });
};

export default useTransaction;
