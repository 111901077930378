/**
 * Helper functions to manipuate Date objects
 */

/**
 * @param {Date} date
 * @returns {Date} The input date with time set to 00:00:00
 */
export const getDateWithoutTime = (date) =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate());

/**
 * @param {Date} date
 * @returns {Date} The input date with time set to 23:59:59
 */
export const getDateAtLastSecond = (date) =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);

/**
 * @param {Date} date
 * @param {number} days
 * @returns {Date} The input date with the added amount of days
 */
export const addDaysToDate = (date, days) => new Date(date.getTime() + days * 86400000);
