import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';

let store = null;

if (process.env.NODE_ENV === 'production') {
  store = createStore(rootReducer, applyMiddleware(thunkMiddleware));
} else {
  store = createStore(
    rootReducer,
    applyMiddleware(thunkMiddleware, createLogger({ collapsed: true }))
  );
}

export default store;
