import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
  getStripeAccountInfo,
  repullSystems,
  resetSystemAPIKey,
  setDetailedSystem,
  updateSystemProvider,
} from '../../actionCreators/system';
import { getNavigationStats } from '../../actionCreators/stats';
import { changeTab } from '../../utils';
import { useParams } from 'react-router-dom';
import PageContainer from '../../components/Redesing/page-container';
import PageContent from '../../components/Redesing/page-content';
import { ISystem } from '../../ts/interface/system.interface';
import {
  updateTOS,
  updateSystemSettings,
  getSystemTOS,
  getOneSystemNew,
} from '../../api/system';
import toast from 'react-hot-toast';
import GeneralInfo from './settingsGeneral';
import Terms from './settingsTerms';
import SettingsOpenData from './settings-open-data';
import Admin from './admin';
import BrandingInfo from './settingsBranding';
import BillingInfo from './settingsBilling';
import RentalInfo from './settingsRental';
import MovaticCustomModal from '../../components/Modal/MovaticCustomModal';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import useStripeAccountInfo from '../../hooks/system/query/useStripeAccountInfo';

interface ISettingsProps {
  isLoaded: boolean;
  detailedSystem: ISystem;
  setDetail: (system: ISystem) => void;
  images: any[];
}

const SettingsV2: React.FC<ISettingsProps> = ({
  isLoaded,
  detailedSystem,
  setDetail,
  images,
}) => {
  const { currentTab = 'general' } = useParams<{ currentTab: string }>();
  const [currentTabV, setCurrentTabV] = useState(currentTab);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [tabToChangeTo, setTabToChangeTo] = useState('');

  const { data: stripeInfo } = useStripeAccountInfo(detailedSystem.id, {
    enabled:
      Boolean(detailedSystem.id) &&
      Boolean(detailedSystem.billingLive) &&
      detailedSystem.payment_provider === 'stripe',
  });

  const needsStripeAccount = useMemo(
    () =>
      (Boolean(stripeInfo?.requirements?.currently_due?.length) &&
        Boolean(detailedSystem.billingLive)) ||
      (Boolean(detailedSystem.billingLive) &&
        detailedSystem.payment_provider === 'stripe' &&
        !Boolean(stripeInfo)),
    [detailedSystem, stripeInfo]
  );

  useEffect(() => {
    if (currentTab !== currentTabV) {
      setCurrentTabV(currentTab);
    }
  }, [currentTab, currentTabV]);

  const tabChange = useCallback(
    (value: React.SetStateAction<string>, changed: boolean) => {
      if (changed && value !== currentTab) {
        setShowConfirm(true);
        setTabToChangeTo(value);
        return;
      }
      setShowConfirm(false);
      setIsChanged(false);
      setButtonDisabled(true);
      changeTab(value, currentTab, 'settings');
    },
    [currentTab]
  );

  const reloadSystem = () => {
    getOneSystemNew().then((res) => {
      setDetail(res);
    });
  };

  const submit = (updatedInfo: any) => {
    setButtonDisabled(true);
    setIsChanged(false);

    if (updatedInfo.text !== undefined && updatedInfo.original !== updatedInfo.text) {
      submitUpdateTOS(updatedInfo.text);
    }
    if (updatedInfo.text === undefined) {
      const maxMembershipRenewalAttempts =
        updatedInfo.maxMembershipRenewalAttempts === 'default'
          ? null
          : updatedInfo.maxMembershipRenewalAttempts;

      const updatedSettings = {
        ...detailedSystem,
        ...updatedInfo,
        maxMembershipRenewalAttempts,
      };
      toast
        .promise(updateSystemSettings(updatedSettings), {
          loading: 'Updating System Settings',
          success: () => {
            reloadSystem();
            return 'System Settings updated';
          },
          error: 'Failed to update System Settings',
        })
        .then(() => {
          setButtonDisabled(false);
        });
    }
  };

  const submitUpdateTOS = (tosText: string) => {
    setIsChanged(false);
    setButtonDisabled(true);
    toast.promise(updateTOS(tosText), {
      loading: 'Updating Terms of Service',
      success: () => {
        getOneSystemNew().then((res) => {
          setDetail(res);
        });
        return 'Terms of Service updated';
      },
      error: 'Failed to update Terms of Service',
    });
  };

  const isChangedFunction = (changeBool: boolean) => {
    setIsChanged(changeBool);
    setButtonDisabled(!changeBool);
  };

  const warning = () => {
    const banner = images.filter(
      (image) =>
        image.system === detailedSystem.id && detailedSystem.banner_id === image.id
    );
    const logo = images.filter(
      (image) => image.id === detailedSystem.logo_id && image.type === 'SystemLogo'
    );
    const { colorPrimary, colorSecondary, tos, billingLive, payment_provider } =
      detailedSystem;
    let message = '';
    let rejected = null;

    if (!colorPrimary || colorPrimary === '') {
      message += '    \u2022 Primary Color ';
    }

    if (!colorSecondary || colorSecondary === '') {
      message += '    \u2022 Secondary Color ';
    }

    if (logo.length === 0) {
      message = message += '    \u2022 Logo ';
    }

    if (logo.length > 0 && logo[0].rejected) {
      rejected = 'Your Logo was rejected, please try again ';
    }

    if (
      banner.length > 0 &&
      banner[0].rejected &&
      banner.length > 0 &&
      banner[0].rejected
    ) {
      if (rejected) {
        rejected += 'Your Logo & Banner was rejected, please try again ';
      } else {
        rejected = 'Your Logo & Banner was rejected, please try again ';
      }
    }

    if (banner.length > 0 && banner[0].rejected) {
      if (rejected) {
        rejected += 'Your Banner was rejected, please try again ';
      } else {
        rejected = 'Your Banner was rejected, please try again ';
      }
    }

    if (!tos || tos === '<p><br></p>') {
      message = message += '    \u2022 Terms of Service ';
    }

    if (!billingLive) {
      message = message += '    \u2022 Billing is Not Active ';
    }

    if (needsStripeAccount && payment_provider === 'stripe') {
      message = message += '    \u2022 Stripe information is missing to enable billing. ';
    }

    if (message) {
      message = `You're missing:${message}`;
    } else {
      message = '';
    }

    if (rejected != null) {
      message += rejected;
    } else if (rejected != null && !message) {
      message = rejected;
    }

    if (detailedSystem.status === 'closed') {
      return "The system status is set to 'Temporarily Closed'. Read the documentation to see how this impacts users.";
    } else if (detailedSystem.status === 'archived') {
      return "The system status is set to 'Archived'. Read the documentation to see how this impacts users.";
    } else if (detailedSystem.demoSystem) {
      return 'This system is a demo system and is not visible without a membership. Please contact your setup partner to activate this system.';
    } else {
      return message; // No specific message
    }
  };

  const showWarning = () => {
    if (
      typeof detailedSystem.logo_id !== 'undefined' ||
      typeof detailedSystem?.colorPrimary !== 'undefined'
    ) {
      return (
        detailedSystem.logo_id === null ||
        detailedSystem.logo_id === 'None' ||
        detailedSystem?.colorPrimary?.length === 0 ||
        !detailedSystem.banner_id
      );
    }
  };

  return (
    <PageContainer
      spaceName={'Settings'}
      isLoading={!isLoaded}
      warningMessage={warning()}
    >
      <PageContent
        detailedView={false}
        currentTabV={currentTabV}
        handleTabsChange={(event, tab) => {
          tabChange(tab, isChanged);
        }}
        tabsList={[
          { label: 'General', value: 'general' },
          { label: 'Rentals', value: 'rental' },
          { label: 'Open Data', value: 'opendata' },
          {
            label: 'Billing',
            value: 'billing',
            warnings: needsStripeAccount || !Boolean(detailedSystem.billingLive) ? 1 : 0,
          },
          { label: ' Branding', value: 'branding', warnings: showWarning() ? 1 : 0 },
          {
            label: 'Terms of Service',
            value: 'terms',
            warnings: detailedSystem.tos === '<p><br></p>' ? 1 : 0,
          },
          { label: 'System Admins', value: 'admin' },
        ]}
        tabsChildren={
          <>
            {currentTabV === 'general' && (
              <GeneralInfo
                system={detailedSystem}
                submit={submit}
                isChanged={isChangedFunction}
                buttonDisabled={buttonDisabled}
              />
            )}
            {currentTabV === 'rental' && (
              <RentalInfo
                submit={submit}
                isChanged={isChangedFunction}
                buttonDisabled={buttonDisabled}
                saveButtonLoading={false}
              />
            )}
            {currentTabV === 'opendata' && (
              <SettingsOpenData
                reloadSystem={reloadSystem}
                isChanged={isChangedFunction}
                buttonDisabled={buttonDisabled}
                detailedSystem={detailedSystem}
              />
            )}
            {currentTabV === 'billing' && <BillingInfo isChanged={isChangedFunction} />}
            {currentTabV === 'branding' && (
              <BrandingInfo
                submit={submit}
                system={detailedSystem}
                isChanged={isChangedFunction}
                buttonDisabled={buttonDisabled}
              />
            )}
            {currentTabV === 'terms' && (
              <Terms
                isChanged={isChangedFunction}
                buttonDisabled={buttonDisabled}
                submit={submit}
                getSystemTOS={getSystemTOS}
                isLoaded={isLoaded}
              />
            )}
            {currentTabV === 'admin' && <Admin />}
          </>
        }
      />
      <MovaticCustomModal
        open={showConfirm}
        onClose={() => setShowConfirm(false)}
        title="Change Pages?"
        content={
          <div>
            <div>
              You have unsaved changes, are you sure you would like to navigate away?
            </div>
            <div>Any changes will be disregarded!</div>
          </div>
        }
        action={
          <IconButtonMenu
            buttons={[
              {
                label: 'Cancel',
                onClick: () => setShowConfirm(false),
                startIcon: <CloseIcon />,
              },
              {
                label: 'Change Page',
                onClick: () => {
                  tabChange(tabToChangeTo, false);
                },
                startIcon: <SaveIcon />,
              },
            ]}
          />
        }
      />
    </PageContainer>
  );
};

const SettingsDetailR = connect(
  (state: any) => ({
    images: state.system.images,
    page: state.page,
    systems: state.system.systems,
    systemAccess: state.admin.systemAccess,
    auth: state.admin,
    detailedSystem: state.system.current,
    isLoaded: state.system.isLoaded,
    transactions: state.transaction.transactions,
    toast: state.toast,
    adminsLoaded: state.admin.adminsLoaded,
  }),
  (dispatch: Function) => ({
    updateGeneralSettings(info: any) {
      dispatch(updateSystemSettings(info));
    },
    getCurrentStripeAccount() {
      dispatch(getStripeAccountInfo());
    },
    getSystemTOS() {
      return dispatch(getSystemTOS());
    },
    refreshSystems(auth: any) {
      return dispatch(repullSystems(auth));
    },
    setDetail(system: ISystem) {
      dispatch(setDetailedSystem(system));
    },
    getNavigationStats() {
      dispatch(getNavigationStats());
    },
    resetSystemAPIKey() {
      return dispatch(resetSystemAPIKey());
    },
    updateSystemProvider(systemInfo: any) {
      return dispatch(updateSystemProvider(systemInfo));
    },
  })
)(SettingsV2);

export default SettingsDetailR;
