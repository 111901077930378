import {
  SET_NAVIGATION_STATS,
  NAVIGATION_STATS_LOADED,
  SET_DASHBOARD_STATS,
  DASHBOARD_STATS_LOADED,
  SET_GRAPH_STATS,
  GRAPH_STATS_LOADED,
} from '../constants';

const initialState = {
  navigation: {
    dispute_open: 0,
    maintenance_open: 0,
    support_open: 0,
    user_membership_pending: 0,
    billing_warning: null,
    membership_warning: false,
    settings_warning: false,
    pending_charges: 0,
  },
  dashboard: {
    active_rentals: 0,
    total_rentals: 0,
    total_users: 0,
    active_hardware: 0,
  },
  graph: {
    rentals: [],
    users: [],
    durations: {},
  },
  navigationStatsLoaded: false,
  dashboardStatsLoaded: false,
  graphStatsLoaded: false,
};

export default function StatsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_NAVIGATION_STATS:
      return {
        ...state,
        navigation: action.stats,
        navigationStatsLoaded: true,
      };

    case NAVIGATION_STATS_LOADED:
      return { ...state, navigationStatsLoaded: action.loaded };

    case SET_DASHBOARD_STATS:
      return {
        ...state,
        dashboard: action.stats,
        dashboardStatsLoaded: true,
      };

    case DASHBOARD_STATS_LOADED:
      return { ...state, dashboardStatsLoaded: action.loaded };

    case SET_GRAPH_STATS:
      return {
        ...state,
        graph: action.stats,
        graphStatsLoaded: true,
      };

    case GRAPH_STATS_LOADED:
      return { ...state, graphStatsLoaded: action.loaded };

    default:
      return state;
  }
}
