import { combineReducers } from 'redux';
import userReducer from './userReducer';
import stationReducer from './stationReducer';
import systemReducer from './systemReducer';
import rentalReducer from './rentalReducer';
import hardwareReducer from './hardwareReducer';
import hardwareProductReducer from './hardwareProductReducer';
import modeReducer from './modeReducer';
import adminReducer from './adminReducer';
import toastReducer from './toastReducer';
import moduleReducer from './moduleReducer';
import problemReducer from './problemReducer';
import transactionReducer from './transactionReducer';
import disputesReducer from './disputesReducer';
import membershipsReducer from './membershipsReducer';
import searchReducer from './searchReducer';
import tableReducer from './tablePages';
import filtersReducer from './filtersReducer';
import reservationsReducer from './reservationsReducer';
import {
  CONFIRMATION_MODAL_CLOSE,
  CONFIRMATION_MODAL_FAILURE,
  RESET,
} from '../constants.js';
import ratesV2Reducer from './ratesV2Reducer';
import couponReducer from './couponReducer';
import maintenanceReducer from './maintenanceReducer';
import statsReducer from './statsReducer';

const page = (
  state = {
    page: 'users',
    pageSection: 1,
    subPage: null,
    subPageAction: null,
    subPageSection: 1,
    modal: null,
    subPageData: null,
    confirmationModal: 'hide',
    confirmationModalData: { txt: '', type: '', id: '' },
    actionData: null,
  },
  action
) => {
  switch (action.type) {
    case 'CONFIRMATION_MODAL': {
      return {
        ...state,
        confirmationModal: 'show',
        confirmationModalData: action.data,
      };
    }
    case CONFIRMATION_MODAL_CLOSE: {
      return {
        ...state,
        confirmationModal: 'hide',
        confirmationModalData: { txt: '' },
      };
    }
    case CONFIRMATION_MODAL_FAILURE: {
      return { ...state, confirmationModal: 'failed' };
    }
    case 'MODAL_API_REJECT_USER':
    case 'MODAL_API_BAN_USER':
    case 'MODAL_API_ACCOUNT_BALANCE':
    case 'MODAL_API_UNDELINQUENT_USER':
    case 'MODAL_API_AUTHORIZE_USER': {
      return { ...state, confirmationModal: 'loading' };
    }

    default:
      return state;
  }
};

const allReducers = combineReducers({
  page,
  mode: modeReducer,
  admin: adminReducer,
  user: userReducer,
  station: stationReducer,
  rental: rentalReducer,
  hardware: hardwareReducer,
  hardwareProduct: hardwareProductReducer,
  system: systemReducer,
  toast: toastReducer,
  module: moduleReducer,
  problem: problemReducer,
  transaction: transactionReducer,
  disputes: disputesReducer,
  rateV2: ratesV2Reducer,
  memberships: membershipsReducer,
  search: searchReducer,
  table: tableReducer,
  filters: filtersReducer,
  reservations: reservationsReducer,
  coupons: couponReducer,
  maintenance: maintenanceReducer,
  stats: statsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === RESET) {
    const { admin, system } = state;
    state = { admin, system };
  }

  return allReducers(state, action);
};

export default rootReducer;
