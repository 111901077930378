import { useState, useMemo } from 'react';
import useTags from '../../hooks/maintenance/query/useTags';
import { getCredentials } from '../../api/http';
import Card from '@mui/material/Card';
import { PaginationTable } from '../../components/Redesing/table/pagination-table';
import {
  IColumn,
  ISortConfig,
} from '../../ts/interface/components/redesing/table.interface';
import { ITag } from '../../ts/interface/problem.interface';
import { getLocaleDateTimeString } from '../../utils';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddIcon from '@mui/icons-material/Add';
import MovaticCustomModal from '../../components/Modal/MovaticCustomModal';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import { MovaticFieldGroupText } from '../../components/Redesing/movatic-field-group';
import CustomToast from '../../components/Redesing/custom-toast';
import useSmallScreen from '../../hooks/useSmallScreen';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';
import { addTag, archiveTag } from '../../api/maintenance';
import { Stack } from '@mui/system';
import ArchiveIcon from '@mui/icons-material/Archive';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants';

const MaintenanceTags = () => {
  const { systemGid } = getCredentials();
  const [tagName, setTagName] = useState('');
  const isSmallScreen = useSmallScreen();
  const [exportData, setExportData] = useState(false);
  const [addTagModal, setAddTagModal] = useState(false);
  const [confirmArchiveModal, setConfirmArchiveModal] = useState(false);
  const [tagToArchive, setTagToArchive] = useState<ITag | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState('');
  const [sortingConfig, setSortingConfig] = useState<ISortConfig>({
    key: 'created_on',
    direction: 'desc',
  });

  const { data, refetch, isLoading, isRefetching } = useTags(systemGid);
  const loadedTags = useMemo(() => data || ([] as ITag[]), [data]);

  const columns: IColumn[] = useMemo(
    () => [
      {
        id: 'name',
        label: 'Name',
        searchable: true,
      },
      {
        id: 'created_on',
        label: 'Created On',
        exportFormat: (row) =>
          row?.created_on ? `"${getLocaleDateTimeString(row.created_on)}"` : ' ',
        format: (row: ITag) => getLocaleDateTimeString(row.created_on),
        searchable: true,
      },
      {
        id: 'actions',
        label: 'Actions',
        notSortable: true,
        shouldNotExport: true,
        disableCLick: true,
        format: (row: ITag) => (
          <Stack justifyContent={'flex-start'} alignItems={'flex-start'}>
            <IconButtonMenu
              buttons={[
                {
                  label: 'Archive',
                  startIcon: <ArchiveIcon />,
                  onClick: () => {
                    setConfirmArchiveModal(true);
                    setTagToArchive(row);
                  },
                },
              ]}
            />
          </Stack>
        ),
      },
    ],
    []
  );

  return (
    <Card>
      <PaginationTable
        buttons={[
          {
            label: 'Export',
            onClick: () => setExportData(true),
            startIcon: <FileDownloadIcon />,
          },
          {
            startIcon: <AddIcon />,
            label: 'Add',
            onClick: () => setAddTagModal(true),
          },
        ]}
        showFilter={false}
        isLoading={isLoading || isRefetching}
        padding={{ pt: 1, pr: 1 }}
        exportData={exportData}
        csvFileName={'Maintenance_Tags.csv'}
        onExport={() => setExportData(false)}
        columns={columns}
        totalItems={loadedTags?.length || 0}
        items={loadedTags}
        sortBy={sortingConfig.key}
        sortDirection={sortingConfig.direction}
        onSortChange={(sort) => setSortingConfig(sort as ISortConfig)}
        rowsPerPage={rowsPerPage}
        page={pageNumber}
        onPageChange={(page, newPage) => setPageNumber(newPage)}
        onRowsPerPageChange={(pageSizeString) =>
          setRowsPerPage(parseInt(pageSizeString.target.value))
        }
        searchText={search}
        handleSearch={(search) => setSearch(search.target.value)}
      />
      <MovaticCustomModal
        customStyles={{
          content: {
            minWidth: 'none',
          },
        }}
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: isSmallScreen ? '90vw' : '30vw',
            margin: isSmallScreen ? 1 : 'auto',
            width: '100%',
          },
        }}
        open={addTagModal}
        onClose={() => setAddTagModal(false)}
        content={
          <MovaticFieldGroupText
            label="Tag Name"
            placeholder="Name"
            description="(Required)"
            name="name"
            value={tagName}
            onChange={(e) => setTagName(e.target.value)}
          />
        }
        title={'Add New Tag'}
        action={
          <IconButtonMenu
            buttons={[
              {
                label: 'Cancel',
                onClick: () => setAddTagModal(false),
              },
              {
                label: 'Save',
                onClick: () => {
                  if (!tagName) {
                    CustomToast({
                      type: 'error',
                      message: 'Tag name is required',
                    });
                    return;
                  }
                  CustomAsyncToast({
                    // @ts-ignore
                    promise: () => addTag({ name: tagName }),
                    successMessage: () => 'Tag added successfully',
                    errorMessage: 'Error adding tag',
                    loadingMessage: 'Adding tag...',
                  }).then((r) => {
                    if (r) {
                      refetch().then(() => {
                        setTagName('');
                        setAddTagModal(false);
                      });
                    }
                  });
                },
              },
            ]}
          />
        }
      />
      <MovaticCustomModal
        customStyles={{
          content: {
            minWidth: 'none',
          },
        }}
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: isSmallScreen ? '90vw' : '30vw',
            margin: isSmallScreen ? 1 : 'auto',
            width: '100%',
          },
        }}
        open={confirmArchiveModal}
        onClose={() => setConfirmArchiveModal(false)}
        content={`Are you sure you want to archive the tag ${tagToArchive?.name}?`}
        title={'Archive Tag'}
        action={
          <IconButtonMenu
            buttons={[
              {
                label: 'Cancel',
                onClick: () => setConfirmArchiveModal(false),
              },
              {
                label: 'Submit',
                onClick: () => {
                  if (tagToArchive?.id) {
                    CustomAsyncToast({
                      promise: () => archiveTag(tagToArchive.id, { archived: true }),
                      successMessage: () => 'Tag archived successfully',
                      errorMessage: 'Error archiving tag',
                      loadingMessage: 'Archiving tag...',
                    }).then((r) => {
                      if (r) {
                        refetch().then(() => {
                          setConfirmArchiveModal(false);
                          setTagToArchive(null);
                        });
                      }
                    });
                  }
                },
              },
            ]}
          />
        }
      />
    </Card>
  );
};

export default MaintenanceTags;
