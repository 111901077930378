import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  setRemoteBatteryLock,
  retireHardware,
  downloadPinCode,
  hardwareRemoteAction,
} from '../../api/hardware';
import {
  changeTab,
  getLocaleDateTimeString,
  history,
  rentable,
  toTitleCase,
  unavailableReason,
  openGoogleMapsCoordinates,
  displayError,
  findAccess,
} from '../../utils';
import { MAINTENANCE, RETIRED, REBALANCE, MISSING, LOST, STORED } from '../../constants';
import QRCode from '../../components/QRCode';
import HardwareAssignment from './newHardwareAssets/hardware-assignment';
import PageContainer from '../../components/Redesing/page-container';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { PropertyList } from '../../components/Redesing/property-list';
import { PropertyListItem } from '../../components/Redesing/property-list-item';
import CardView from '../../components/Redesing/card-view';
import Divider from '@mui/material/Divider';
import { LinearProgress } from '@mui/material';
import HardwareRentals from './newHardwareAssets/hardware-rentals';
import MaintenanceTickets from './newHardwareAssets/hardware-tickets';
import MaintenanceLogs from './newHardwareAssets/hardware-logs';
import EventLogs from './newHardwareAssets/hardware-event-logs';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import PowerIcon from '@mui/icons-material/Power';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { SeverityPill } from '../../components/Redesing/severity-pill';
import SplitButton from '../../components/Redesing/split-button';
import PageContent from '../../components/Redesing/page-content';
import MovaticCustomModal from '../../components/Modal/MovaticCustomModal';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';
import useOneHardware from '../../hooks/hardware/query/useOneHardware';
import PropertyListItemLink from '../../components/Redesing/property-list-item-link';
import { getHardwareIconByName } from '../../util/utilsR';
import CustomMapComponentForDetailLocation from '../../components/Redesing/custom-map-component-for-detail-location';

const tabsList = [
  { label: 'Details', value: 'general' },
  { label: 'Rentals', value: 'rentals' },
  { label: 'Maintenance Tickets', value: 'maintenance-tickets' },
  { label: 'Maintenance Logs', value: 'maintenance-logs' },
  { label: 'Event Logs', value: 'event-logs' },
];

function HardwareDetail(props) {
  const [showRetireModal, setShowRetireModal] = useState(false);
  const [showPincodeModal, setShowPincodeModal] = useState(false);
  const [refetchInterval, setRefetchInterval] = useState(5000);
  const { hardwareId, currentTab = 'general', type } = props.match.params;
  const [currentTabV, setCurrentTabV] = useState(currentTab);
  const [generalTabTitle, setGeneralTabTitle] = useState('');
  const [hardwareLocation, setHardwareLocation] = useState({
    locationId: null,
    lat: null,
    lng: null,
    marker: {
      current_rental: null,
      state: 0,
      battery_percent: null,
      markerType: null,
      systemAccess: props.systemAccess,
    },
  });

  useEffect(() => {
    if (currentTab !== currentTabV) {
      setCurrentTabV(currentTab);
    }
  }, [currentTab, currentTabV]);

  const handleTabsChange = useCallback(
    (event, value) => {
      changeTab(`${type}/${hardwareId}/${value}`, currentTab, 'hardware');
    },
    [hardwareId, type, currentTab]
  );

  const query = useOneHardware(hardwareId, {
    refetchInterval: refetchInterval,
    onError: () => {
      setRefetchInterval(0);
      displayError(
        'Oops! Looks like there was an error while getting the hardware details. Please try again later.'
      );
    },
  });

  const hardware = query.data;
  const hardwareStatus = hardware ? unavailableReason(hardware) : [];
  const access = findAccess(props.systemAccess) ?? [];
  const hardwareAccess = access.includes('physical') || props.movaticAccess;

  useEffect(() => {
    if (hardware) {
      setHardwareLocation({
        locationId: hardware?.current_station_id,
        lat: hardware?.last_lat,
        lng: hardware?.last_lng,
        marker: {
          current_rental: hardware?.current_rental,
          state: hardware?.state,
          battery_percent: get(hardware, 'batteryStatus[0].battery_percent'),
          markerType: hardware?.hardware_type_name,
          systemAccess: hardwareLocation.marker.systemAccess,
        },
      });

      let title = hardware
        ? hardware[hardware?.primary_identifier] ||
          toTitleCase(hardware?.hardware_type_name)
        : '';
      if (hardware?.number) {
        title = `${toTitleCase(hardware?.hardware_type_name)} ${hardware?.number}`;
      }
      setGeneralTabTitle(title);
    }
  }, [hardware, hardwareLocation.marker.systemAccess]);

  useEffect(() => {
    setCurrentTabV(currentTab);
  }, [currentTab]);

  const sendActions = (action, successMessage, errorMessage, loadingMessage) => {
    return CustomAsyncToast({
      promise: () => hardwareRemoteAction(hardwareId, action),
      successMessage: () => successMessage,
      errorMessage: errorMessage,
      loadingMessage: loadingMessage,
    });
  };

  const getHardwareActions = () => {
    const actions = [];
    const hardwareConnected = hardware?.remote_connection_async;
    if (hardware && hardwareAccess) {
      if (
        hardware?.supports_remote_unlock &&
        hardware?.hardware_type_name !== 'base_station'
      ) {
        actions.push({
          type: 'remoteUnlock',
          desc: 'Unlock',
          icon: <LockOpenIcon />,
          disabled: !hardwareConnected,
          action: () =>
            sendActions(
              'unlock',
              'Unlock request sent successfully',
              'There was an issue while sending the unlock request, please try again!',
              'Sending unlock request...'
            ),
        });
      }

      if (hardware?.supports_remote_lock) {
        actions.push({
          type: 'remoteLock',
          desc: 'Lock',
          icon: <LockIcon />,
          disabled: !hardwareConnected,
          action: () =>
            sendActions(
              'lock',
              'Lock request sent successfully',
              'There was an issue while sending the lock request, please try again!',
              'Sending lock request...'
            ),
        });
      }

      if (hardware?.supports_remote_battery_unlock) {
        actions.push({
          type: 'remoteBatteryUnlock',
          desc: 'Disconnect',
          icon: <PowerOffIcon />,
          disabled: !hardwareConnected,
          action: () =>
            sendActions(
              'unlockbattery',
              'Battery unlock request sent successfully',
              'There was an issue while sending the battery unlock request, please try again!',
              'Sending battery unlock request...'
            ),
        });
      }

      if (hardware?.supports_remote_battery_lock) {
        actions.push({
          type: 'remoteBatteryLock',
          desc: 'Connect',
          icon: <PowerIcon />,
          disabled: !hardwareConnected,
          action: () => {
            CustomAsyncToast({
              promise: () => setRemoteBatteryLock(hardwareId),
              successMessage: () => 'Battery lock request sent successfully',
              errorMessage:
                'There was an issue while sending the battery lock request, please try again!',
              loadingMessage: 'Sending battery lock request...',
            });
          },
        });
      }

      if (hardware?.supports_remote_ring) {
        actions.push({
          type: 'remoteRing',
          desc: 'Ring',
          icon: <NotificationsActiveIcon />,
          disabled: !hardwareConnected,
          action: () =>
            sendActions(
              'ring',
              'Ring request sent successfully',
              'There was an issue while sending the ring request, please try again!',
              'Sending ring request...'
            ),
        });
      }

      if (hardware?.supports_remote_location_check) {
        actions.push({
          type: 'locationCheck',
          desc: 'Locate',
          icon: <LocationOnIcon />,
          disabled: !hardwareConnected,
          action: () =>
            sendActions(
              'locationcheck',
              'Location request sent successfully',
              'There was an issue while sending the location request, please try again!',
              'Sending location request...'
            ),
        });
      }
    }
    if (actions.length > 0) {
      actions.unshift({
        type: 'initial',
        desc: 'Actions',
        action: null,
      });
    }
    return actions;
  };

  const goToHardwareStr = (id, type) => {
    return `/hardware/${type}/${id}`;
  };

  const genPinCode = () => {
    CustomAsyncToast({
      promise: () => downloadPinCode(hardware),
      successMessage: () => 'Pincodes generated successfully',
      errorMessage: 'There was an issue while making new pincodes, please try again!',
      loadingMessage: 'Generating pincodes...',
    });
  };

  const warning = () => {
    if (
      hardware?.state !== 1 ||
      hardware?.current_rental ||
      !rentable(hardware?.hardware_type_id, props.system.hardware_type_gid)
    ) {
      return null;
    }
    if (hardwareStatus.length > 0) {
      return `This ${
        hardware?.hardware_type_name
      } is not available for rentals. Please assign a ${hardwareStatus
        .join(', ')
        .replace(/, ([^,]*)$/, ' and a $1')} to make it available`;
    }
    return null;
  };

  const button = () => {
    const keybox = false; // TODO
    const bts = [
      {
        startIcon: <EditIcon />,
        label: 'Edit',
        onClick: () =>
          history.push(`/hardware/${hardware?.hardware_type}/${hardware?.id}/edit`),
        disabled: !!hardware?.current_rental || !hardwareAccess,
      },
      {
        startIcon: <DeleteIcon />,
        label: 'Retire',
        onClick: () => setShowRetireModal(true),
        disabled:
          hardware?.state === RETIRED || !!hardware?.current_rental || !hardwareAccess,
      },
    ];
    if (keybox) {
      bts.push({
        startIcon: <AddIcon />,
        label: 'Generate Pincodes',
        onClick: () => setShowPincodeModal(true),
        disabled:
          hardware?.state === RETIRED || !!hardware?.current_rental || !hardwareAccess,
      });
    }
    return bts;
  };

  const getHardwareStatus = () => {
    if (hardware?.state === STORED) {
      return <SeverityPill color="info">Stored</SeverityPill>;
    }
    if (hardware?.state === MAINTENANCE) {
      return <SeverityPill color="warning">Maintenance</SeverityPill>;
    }
    if (hardware?.state === RETIRED) {
      return <SeverityPill color="error">Retired</SeverityPill>;
    }
    if (hardware?.state === REBALANCE) {
      return <SeverityPill color="info">Rebalance</SeverityPill>;
    }
    if (hardware?.state === MISSING) {
      return <SeverityPill color="error">Missing</SeverityPill>;
    }
    if (hardware?.state === LOST) {
      return <SeverityPill color="error">Lost</SeverityPill>;
    }
    if (hardwareStatus.length > 0) {
      return <SeverityPill color="warning">Unavailable</SeverityPill>;
    }
    return <SeverityPill color="success">Active</SeverityPill>;
  };

  const getLockStateString = () => {
    if (hardware?.unlocked_cached === true) {
      return (
        <SeverityPill color="info">
          {hardware?.unlocked_state_async !== false ? 'Unlocked' : 'Locking'}
        </SeverityPill>
      );
    }

    if (hardware?.unlocked_cached === false) {
      return (
        <SeverityPill color="info">
          {hardware?.unlocked_state_async !== true ? 'Locked' : 'Unlocking'}
        </SeverityPill>
      );
    }

    return <SeverityPill color="warning">Unknown</SeverityPill>;
  };

  const renderLink = (to, text) => (
    <Link onClick={() => history.push(to)}>
      <Typography color="text.secondary" variant="subtitle1">
        {text}
        <i id="table_item_carot" className="fa fa-caret-right" />
      </Typography>
    </Link>
  );

  const onClickLastLocation = useCallback(() => {
    openGoogleMapsCoordinates(hardware?.last_lat, hardware?.last_lng);
  }, [hardware?.last_lat, hardware?.last_lng]);

  const hasLastCoordinates =
    Boolean(hardwareLocation?.lat) && Boolean(hardwareLocation?.lng);

  const generalContent = () => {
    const batteryLevel = get(hardware, 'batteryStatus[0].battery_percent');
    const batteryVoltage = get(hardware, 'batteryStatus[0].battery_voltage');
    const batteryCharging = get(hardware, 'batteryStatus[0].battery_charging');

    return (
      <PropertyList>
        {hardware?.number !== null && hardware?.number !== undefined && (
          <>
            <PropertyListItem
              align={'horizontal'}
              label={`${toTitleCase(hardware?.hardware_type_name)} Number`}
              value={hardware?.number}
              dataId="hardware-number"
            />
            <Divider />
          </>
        )}
        {hardware?.label && (
          <>
            {' '}
            <PropertyListItem
              align={'horizontal'}
              label="Label"
              value={hardware?.label}
              dataId="label"
            />
            <Divider />
          </>
        )}
        {hardware?.vendor_id && (
          <>
            {' '}
            <PropertyListItem
              align={'horizontal'}
              label="Vendor ID"
              value={hardware?.vendor_id}
              dataId="vendor-id"
            />
            <Divider />
          </>
        )}
        {hardware?.mac && (
          <>
            {' '}
            <PropertyListItem
              align={'horizontal'}
              label="Mac Address"
              value={hardware?.mac}
              dataId="mac-address"
            />
            <Divider />
          </>
        )}
        {hardware?.imei && (
          <>
            {' '}
            <PropertyListItem
              align={'horizontal'}
              label="IMEI"
              value={hardware?.imei}
              dataId="imei"
            />
            <Divider />
          </>
        )}
        {hardware?.product_name && (
          <>
            <PropertyListItem
              align={'horizontal'}
              label="Product Name"
              value={hardware?.product_name}
              dataId="product-name"
            />
            <Divider />
          </>
        )}
        {hardware?.version_name && (
          <>
            <PropertyListItem
              align={'horizontal'}
              label="Version"
              value={hardware?.version_name}
              dataId="version-name"
            />
            <Divider />
          </>
        )}
        {hardware?.iccid && (
          <>
            <PropertyListItem
              align={'horizontal'}
              label="ICCID"
              value={hardware?.iccid}
              dataId="iccid"
            />
            <Divider />
          </>
        )}
        {hardware?.firmware_version && (
          <>
            <PropertyListItem
              align={'horizontal'}
              label="Firmware Version"
              value={hardware?.firmware_version}
              dataId="firmware"
            />
            <Divider />
          </>
        )}
        {rentable(hardware?.hardware_type_id, props.system.hardware_type_gid) && (
          <>
            <PropertyListItem
              align={'horizontal'}
              label="Current Status"
              dataId="status"
              value={<div>{getHardwareStatus()}</div>}
            />
            <Divider />
          </>
        )}

        {/* battery level */}
        {hardware?.has_battery_status && (
          <>
            {(!!batteryLevel || batteryLevel === 0) && (
              <>
                <PropertyListItem
                  align={'horizontal'}
                  label="Battery Level"
                  value={
                    <>
                      <LinearProgress
                        value={batteryLevel}
                        variant="determinate"
                        color={
                          batteryLevel >= 20
                            ? batteryLevel > 50
                              ? 'success'
                              : 'warning'
                            : 'error'
                        }
                        sx={{
                          height: 8,
                          width: 36,
                        }}
                      />
                      {batteryLevel}%
                    </>
                  }
                  dataId="battery-level"
                />
                <Divider />
              </>
            )}
            {(!!batteryVoltage || batteryVoltage === 0) && (
              <>
                <PropertyListItem
                  align={'horizontal'}
                  label="Battery Voltage"
                  value={`${batteryVoltage} mV`}
                  dataId="battery-voltage"
                />
                <Divider />
              </>
            )}
            <>
              <PropertyListItem
                align={'horizontal'}
                label="Charging"
                value={
                  <SeverityPill color={'info'}>
                    {batteryCharging ? 'Yes' : 'No'}
                  </SeverityPill>
                }
                dataId="battery-charging"
              />
              <Divider />
            </>
          </>
        )}

        {/* lock state */}
        <PropertyListItem
          align={'horizontal'}
          label="Lock State"
          value={getLockStateString()}
          dataId="lock-state"
          divider
        />

        {/* tcp state */}
        {hardware?.remote_connection_async !== null && (
          <>
            <PropertyListItem
              align={'horizontal'}
              label="Connection Status"
              value={
                hardware?.remote_connection_async ? (
                  <SeverityPill color={'success'}>Connected</SeverityPill>
                ) : (
                  <SeverityPill color={'error'}>Disconnected</SeverityPill>
                )
              }
              dataId="tcp-state"
            />
            <Divider />
          </>
        )}

        {hardware?.current_station_id && !hardware?.current_rental && (
          <>
            <PropertyListItem align={'horizontal'} label={'Assigned Location'}>
              {renderLink(
                `/locations/${hardware?.current_station_id}/general`,
                hardware?.station_name
              )}
            </PropertyListItem>
            <Divider />
          </>
        )}
        {hardware?.current_rental && (
          <>
            <PropertyListItem align={'horizontal'} label={'Current Rental'}>
              {renderLink(
                `/rentals/${hardware?.current_rental_gid}`,
                hardware?.current_rental_gid
              )}
            </PropertyListItem>
            <Divider />
          </>
        )}
        {hardware?.vendor_serial && (
          <>
            <PropertyListItem
              align={'horizontal'}
              label="Serial Number"
              value={hardware?.vendor_serial}
              dataId="serial-number"
            />
            <Divider />
          </>
        )}

        {/* parents */}
        {hardware?.parents &&
          hardware?.parents
            .filter((parent) => !parent.removed_on)
            .map((parent) => (
              <>
                <PropertyListItem
                  align={'horizontal'}
                  label={`Current ${toTitleCase(parent.parent_hardware_type_name)}`}
                  key={parent.parent_id}
                  dataId={`parent-${parent.parent_hardware_type_name}`}
                >
                  {renderLink(
                    goToHardwareStr(parent.parent_id, parent.parent_hardware_type),
                    parent.number || parent[parent.primary_identifier] || parent.parent_id
                  )}
                </PropertyListItem>
                <Divider />
              </>
            ))}

        <div>
          <PropertyListItem
            align={'horizontal'}
            label="Last Update"
            value={
              hardware?.cached_last_seen
                ? getLocaleDateTimeString(hardware?.cached_last_seen)
                : 'Unknown'
            }
            dataId="last-update"
          />
        </div>

        {/* location */}
        {(hardware?.has_gps || hasLastCoordinates) && (
          <div>
            <Divider />
            <PropertyListItemLink
              text={
                hasLastCoordinates
                  ? `${hardware?.last_lat}, ${hardware?.last_lng}`
                  : 'Unknown'
              }
              label={'Last GPS Location'}
              onClick={onClickLastLocation}
            />
          </div>
        )}

        {hardware?.qr_id &&
          rentable(hardware?.hardware_type_id, props.system.hardware_type_gid) && (
            <>
              <Divider />
              <PropertyListItem
                align={'horizontal'}
                label="Movatic QR Code"
                value={hardware?.qr_id}
                dataId="qr-code"
              />
            </>
          )}

        {rentable(hardware?.hardware_type_id, props.system.hardware_type_gid) &&
          hardware?.qr_id && (
            <>
              <Divider />
              <PropertyListItem
                align={'horizontal'}
                label="Movatic QR Code"
                dataId="qr-code"
                sx={{ maxWidth: '100%' }}
              >
                <>
                  <Typography
                    sx={{
                      maxWidth: '50%', // Set maximum width to prevent overflow
                      overflow: 'hidden', // Hide any overflow
                      wordWrap: 'break-word', // Allow words to break into multiple lines
                      paddingRight: 2,
                    }}
                    color="text.secondary"
                    variant="subtitle1"
                  >
                    {hardware?.qr_id}
                  </Typography>
                  {!!hardware?.qrCode && (
                    <QRCode
                      value={`qr.movatic.co/?${hardware?.qr_id}`}
                      size="150"
                      level="H"
                      fileName={`qr-hardware-${hardware?.gid}.png`}
                    />
                  )}
                </>
              </PropertyListItem>
            </>
          )}
        {rentable(hardware?.hardware_type_id, props.system.hardware_type_gid) &&
          hardware?.vendor_qr_code && (
            <>
              <Divider />
              <PropertyListItem
                align={'horizontal'}
                label="Vendor QR Code"
                dataId="vendorQrCodeImg"
              >
                <div>
                  <Typography
                    sx={{
                      maxWidth: '80%', // Set maximum width to prevent overflow
                      overflow: 'hidden', // Hide any overflow
                      wordWrap: 'break-word', // Allow words to break into multiple lines
                      paddingRight: 2,
                    }}
                    color="text.secondary"
                    variant="subtitle1"
                  >
                    {hardware?.vendor_qr_code}
                  </Typography>
                  {!!hardware?.vendor_qr_code && (
                    <QRCode
                      value={hardware?.vendor_qr_code}
                      size="150"
                      level="H"
                      fileName={`vendor-qr-hardware-${hardware?.gid}.png`}
                    />
                  )}
                </div>
              </PropertyListItem>
            </>
          )}
      </PropertyList>
    );
  };

  const { Icon } = getHardwareIconByName(hardware?.hardware_type_name);

  return (
    <div>
      <PageContainer warningMessage={warning()} isLoading={query.isLoading}>
        <>
          <PageContent
            itemTopButtons={<IconButtonMenu buttons={button()} />}
            backLink={'/hardware'}
            contentTitle={'Hardware'}
            itemName={generalTabTitle}
            detailIcon={<Icon />}
            itemChipValue={hardware?.gid}
            chipLabel={'Hardware ID'}
            itemActions={
              <>
                {getHardwareActions().length > 0 && (
                  <SplitButton items={getHardwareActions()} />
                )}
              </>
            }
            tabsChildren={
              <>
                {currentTab === 'general' && (
                  <Grid container spacing={2}>
                    {/* Hardware Details */}
                    <Grid item xs={12} lg={5} md={5}>
                      <Stack spacing={2}>
                        <CardView content={generalContent()} title={'Hardware Details'} />
                      </Stack>
                    </Grid>

                    {/* Map and Hardware Assignment */}
                    <Grid item xs={12} lg={7} md={7}>
                      <Stack spacing={2}>
                        {hardware?.has_gps && hasLastCoordinates && (
                          <CustomMapComponentForDetailLocation
                            hardwareInfo={hardwareLocation}
                            system={props.system}
                            modeInfo={props.modeInfo}
                          />
                        )}
                        <HardwareAssignment hardware={hardware} />
                      </Stack>
                    </Grid>
                  </Grid>
                )}
                {currentTab === 'rentals' && (
                  <HardwareRentals hardware={hardware} isLoading={query.isLoading} />
                )}
                {currentTab === 'maintenance-tickets' && (
                  <MaintenanceTickets hardware={hardware} isLoading={query.isLoading} />
                )}
                {currentTab === 'maintenance-logs' && (
                  <MaintenanceLogs hardware={hardware} isLoading={query.isLoading} />
                )}
                {currentTab === 'event-logs' && (
                  <EventLogs isLoading={query.isLoading} hardware_id={hardware?.gid} />
                )}
              </>
            }
            handleTabsChange={handleTabsChange}
            currentTabV={currentTabV}
            tabsList={tabsList}
          />
          <div>
            <MovaticCustomModal
              open={showPincodeModal}
              onClose={() => setShowPincodeModal(false)}
              title={'Generate New Pincodes'}
              content="Are you sure you would like to generate pincodes? This will require you to reprogram your keybox."
              action={
                <IconButtonMenu
                  buttons={[
                    {
                      label: 'Cancel',
                      onClick: () => setShowPincodeModal(false),
                    },
                    {
                      label: 'Generate',
                      onClick: () => {
                        genPinCode();
                        setShowPincodeModal(false);
                      },
                    },
                  ]}
                />
              }
            />

            <MovaticCustomModal
              open={showRetireModal}
              onClose={() => setShowRetireModal(false)}
              title={`Retire ${hardware?.hardware_type_name}`}
              content={`Are you sure you would like to retire this ${hardware?.hardware_type_name}?`}
              action={
                <IconButtonMenu
                  buttons={[
                    {
                      label: 'Cancel',
                      onClick: () => setShowRetireModal(false),
                    },
                    {
                      label: 'Retire',
                      onClick: () => {
                        CustomAsyncToast({
                          promise: () => retireHardware(hardware?.id),
                          successMessage: () => 'Hardware retired successfully',
                          errorMessage:
                            'There was an issue while retiring the hardware, please try again!',
                          loadingMessage: 'Retiring Hardware...',
                        }).then((r) => {
                          if (r) {
                            history.push('/hardware');
                          }
                        });
                      },
                    },
                  ]}
                />
              }
            />
          </div>
        </>
      </PageContainer>
    </div>
  );
}

export default connect(
  (state) => ({
    auth: state.admin.admin,
    systemAccess: state.admin.systemAccess,
    movaticAccess: state.admin.admin.admin.movaticAccess,
    hardware: state.hardware.new_hardware,
    hardwareTypes: state.hardware.hardwareTypes,
    system: state.system.current,
    baseStationUnlockId: state.hardware.baseStationUnlockId,
    remoteUnlockId: state.hardware.remoteUnlockId,
    remoteLockId: state.hardware.remoteLockId,
    remoteRingId: state.hardware.remoteRingId,
    locationCheckId: state.hardware.locationCheckId,
    remoteActionLoaded: state.hardware.remoteActionLoaded,
    systemLoaded: state.system.isLoaded,
    modeInfo: state.mode,
  }),
  () => ({})
)(HardwareDetail);
