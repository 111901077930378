import React, { useState } from 'react';
import useRfidCard from '../../../hooks/users/query/useRfidCard';
import { useParams } from 'react-router-dom';
import useUser from '../../../hooks/users/query/useUser';
import { formatPhoneNumber, getLocaleDateTimeString, history } from '../../../utils';
import { IRfidCard } from '../../../ts/interface/rfidCard.interface';
import { UserInterface } from '../../../ts/interface/user.interface';
import PageContainer from '../../../components/Redesing/page-container';
import CardView from '../../../components/Redesing/card-view';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import CustomAsyncToast from '../../../components/Redesing/custom-async-toast';
import { archiveUserRfidCard } from '../../../api/users';
import { PropertyList } from '../../../components/Redesing/property-list';
import { Stack } from '@mui/system';
import { PropertyListItem } from '../../../components/Redesing/property-list-item';
import PropertyListItemLink from '../../../components/Redesing/property-list-item-link';
import Divider from '@mui/material/Divider';
import MovaticCustomModal from '../../../components/Modal/MovaticCustomModal';
import CloseIcon from '@mui/icons-material/Close';

const UserRfidCardDetail = () => {
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
  const { userPhone = '', cardId = '' } = useParams<{
    userPhone: string;
    cardId: string;
  }>();
  const [rfidCard, setRfidCard] = useState<IRfidCard>({});
  const [fullName, setFullName] = useState<string | undefined>('');

  const { isLoading: userIsLoading } = useUser(userPhone, {
    onSuccess: (r: UserInterface) => setFullName(r?.userDetails?.fullName),
  });

  const { isLoading: isLoadingRfid, refetch: refetchRfidCard } = useRfidCard(cardId, {
    onSuccess: (r: IRfidCard) => setRfidCard(r),
  });

  return (
    <PageContainer>
      <CardView
        isLoading={userIsLoading || isLoadingRfid}
        headerActions={
          <>
            {rfidCard.id && !rfidCard.archived_on && (
              <IconButtonMenu
                buttons={[
                  {
                    label: 'Archive',
                    startIcon: <ArchiveIcon />,
                    onClick: () => setConfirmationOpen(true),
                  },
                  {
                    label: 'Edit',
                    startIcon: <EditIcon />,
                    onClick: () =>
                      history.push(`/users/${userPhone}/rfid/${rfidCard.id}/edit`),
                  },
                ]}
              />
            )}
          </>
        }
        title={fullName}
        content={
          <Stack px={2} pb={2}>
            <PropertyList>
              <PropertyListItem
                divider
                label="Card ID"
                value={rfidCard.card_id || 'N/A'}
              />
              <PropertyListItem
                divider
                label="Card label"
                value={rfidCard.label || '(none)'}
              />
              <PropertyListItemLink
                label={'User'}
                text={formatPhoneNumber(userPhone)}
                onClick={() => history.push(`/users/${userPhone}/general`)}
              />
              {!!rfidCard.archived_on && (
                <>
                  <Divider />
                  <PropertyListItem
                    label="Archived On"
                    value={getLocaleDateTimeString(rfidCard.archived_on)}
                  />
                </>
              )}
            </PropertyList>
            <MovaticCustomModal
              open={confirmationOpen}
              onClose={() => setConfirmationOpen(false)}
              content={'Are you sure you want to archive this card?'}
              title={'Archive Card'}
              action={
                <IconButtonMenu
                  buttons={[
                    {
                      label: 'Calcel',
                      startIcon: <CloseIcon />,
                      onClick: () => setConfirmationOpen(false),
                    },
                    {
                      label: 'Archive',
                      startIcon: <ArchiveIcon />,
                      onClick: () => {
                        CustomAsyncToast({
                          promise: () => archiveUserRfidCard(rfidCard.id),
                          successMessage: () => 'Card archived successfully!',
                          errorMessage: 'Card archive failed!',
                          loadingMessage: 'Archiving card...',
                        }).then((r) => {
                          if (r?.status === 200) {
                            refetchRfidCard().then(() => setConfirmationOpen(false));
                          }
                        });
                      },
                    },
                  ]}
                />
              }
            />
          </Stack>
        }
      />
    </PageContainer>
  );
};

export default UserRfidCardDetail;
