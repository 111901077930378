import { useQuery } from '@tanstack/react-query';
import { getUserCardInformation } from '../../../api/users';

const useUserCard = (userPhone: string, options?: {}) => {
  return useQuery(
    ['userCreditCardInformation', userPhone],
    () => getUserCardInformation(userPhone),
    { ...options }
  );
};

export default useUserCard;
