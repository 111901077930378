import { baseUrlV2, createQueryParameters, fetchOptionsV2, getCredentials } from './http';
import { FourOhOneError } from '../actionCreators/system';
import CustomToast from '../components/Redesing/custom-toast';
import { IUseHardwareFilter } from '../ts/interface/hardware.interface';
import { API_PAGINATION_LIMIT } from '../constants';

export const getHardwareV2 = (parameters?: IUseHardwareFilter) => {
  const { adminEmail, adminToken, systemGid } = getCredentials();
  const queryParametersObject = {
    limit: API_PAGINATION_LIMIT,
    system_id: systemGid,
    ...parameters,
  };
  const queryParameters = createQueryParameters(queryParametersObject);
  const fetchOps = fetchOptionsV2(adminEmail, adminToken);
  //@ts-ignore
  return fetch(`${baseUrlV2}/hardware/?${queryParameters}`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.data) {
        return response.data;
      }
      FourOhOneError(response);
      throw new Error(response.message);
    })
    .catch((e) => {
      CustomToast({
        type: 'error',
        message: 'Error fetching hardware data. Please try again.',
      });
      return null;
    });
};
