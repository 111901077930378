import React, { useState } from 'react';
import CardView from '../../../components/Redesing/card-view';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { IDocument } from '../../../ts/interface/membership.interface';
import { PaginationTable } from '../../../components/Redesing/table/pagination-table';
import { Stack } from '@mui/system';
import ImageLightBox from '../../../components/ImageLightBox/ImageLightBox';

interface ICardsViewDocumentsListProps {
  docs: IDocument[];
}

const UserMembershipDocumentsList: React.FC<ICardsViewDocumentsListProps> = (props) => {
  const { docs } = props;
  const [searchRate, setSearchRate] = React.useState('');
  const [pageRate, setPageRate] = React.useState(0);
  const [rowsPerPageRate, setRowsPerPageRate] = React.useState(5);
  const [sortData, setSortData] = useState<string>('system_name');
  const [exportData, setExportData] = useState<boolean>(false);
  const [lightBoxTogglers, setLightBoxTogglers] = useState(
    Array(docs.length).fill(false)
  );

  return (
    <CardView
      content={
        <PaginationTable
          padding={{ pt: 1, pl: 0, pr: 1 }}
          showFilter={false}
          exportData={exportData}
          sortBy={sortData}
          onSortChange={(sortBy) => setSortData(sortBy.key)}
          items={docs || []}
          csvFileName={'Documents.csv'}
          noDataText={'This user-membership has no documents at this time.'}
          columns={[
            {
              id: 'name',
              label: `Name`,
              searchable: true,
              format: (row) => row?.name || `Document ${row.id}`,
            },
            {
              id: 'url',
              label: 'Document',
              showToolTip: true,
              searchable: true,
              format: (row) => {
                const url = row?.url || '';
                return (
                  <Stack px={2} pb={2}>
                    <img
                      style={{
                        height: '7%',
                        width: '7%',
                        maxWidth: '25em',
                        cursor: 'pointer',
                      }}
                      alt={'End Rental'}
                      src={url}
                      onClick={(event) => {
                        event.stopPropagation();
                        const updatedTogglers = [...lightBoxTogglers];
                        updatedTogglers[row.id] = !updatedTogglers[row.id];
                        setLightBoxTogglers(updatedTogglers);
                      }}
                    />
                    <ImageLightBox imageUrl={url} toggler={lightBoxTogglers[row.id]} />
                  </Stack>
                );
              },
            },
          ]}
          rowsPerPage={rowsPerPageRate}
          page={pageRate}
          onPageChange={(page, pageN: number) => setPageRate(pageN)}
          onRowsPerPageChange={(rowsPerPage) =>
            setRowsPerPageRate(Number(rowsPerPage.target.value))
          }
          searchText={searchRate}
          searchPlaceholder={'Search membership documents'}
          handleSearch={(search) => setSearchRate(search.target.value)}
        />
      }
      title={'Uploaded Documents by User'}
      headerActions={
        <IconButtonMenu
          buttons={[
            {
              label: 'Export',
              onClick: () => setExportData(true),
              startIcon: <FileDownloadIcon />,
            },
          ]}
        />
      }
    />
  );
};

export default UserMembershipDocumentsList;
