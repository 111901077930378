import {
  API_RESERVATIONS_SUCCESS,
  SET_RESERVATION_DETAILS,
  INSERT_RESERVATION,
  SET_RESERVATION,
  CLEAR_LOADED,
} from '../constants.js';

const initalState = {
  reservations: {},
  detailedReservation: {},
  isLoaded: false,
};

export default function reservationsReducer (state = initalState, action) {
  const newState = { ...state };

  switch (action.type) {
    case API_RESERVATIONS_SUCCESS:
      newState.reservations = action.reservations;
      newState.isLoaded = true;
      break;

    case SET_RESERVATION_DETAILS:
      newState.detailedReservation = action.reservationDetails;
      break;

    case SET_RESERVATION:
      newState.reservation = action.reservation;
      break;
    case CLEAR_LOADED:
      newState.isLoaded = false;
      break;

    case INSERT_RESERVATION:
      let index = null;
      for (
        let i = 0;
        i <
        newState.reservations[action.reservation.created][action.reservation.user].length;
        i++
      ) {
        if (
          newState.reservations[action.reservation.created][action.reservation.user][i]
            .id === action.reservation.id
        ) {
          index = i;
        }
      }
      index != null
        ? (newState.reservations[action.reservation.created][action.reservation.user][
            index
          ] = action.reservation)
        : newState.reservations[action.reservation.created][action.reservation.user].push(
            action.reservation
          );
      break;
    default:
      return state;
  }

  return newState;
}
