import React, { useReducer } from 'react';
import {
  findAccess,
  history,
  rentable,
  toTitleCase,
  validateImei,
  validateMacAddress,
  validateVendorSerial,
} from '../../utils';

import { IHardwareType } from '../../ts/interface/hardware.interface';
import useHardwareProduct from '../../hooks/hardwareProduct/query/useHardwareProduct';
import useHardwareProductVersion from '../../hooks/hardwareProduct/query/useHardwareProductVersion';
import RadioButtons from '../../components/RadioButtons/RadioButtons';
import {
  ACTIVE,
  BEACH_CHAIR,
  HARDWARE_NUMBER_LIMIT,
  MAINTENANCE,
  BIKE,
  BIKE_ROOM,
  CANOE,
  KAYAK,
  KEYBOX,
  LOCK,
  LOCKER,
  RACK,
  SCOOTER,
  BATTERY,
} from '../../constants';
import { IMode } from '../../ts/interface/mode.interface';
import { connect } from 'react-redux';
import { IAuth } from '../../ts/interface/admins.interface';
import { ISystem } from '../../ts/interface/system.interface';
import useStation from '../../hooks/stations/query/useStation';
import { IStation } from '../../ts/interface/station.interface';
import { IHardwareProduct } from '../../ts/interface/hardwareProduct.interface';
import { IHardwareVersion } from '../../ts/interface/hardwareVersion.interface';
import useHardwareTypes from '../../hooks/hardware/query/useHardwareTypes';
import CardView from '../../components/Redesing/card-view';
import PageContainer from '../../components/Redesing/page-container';
import Stack from '@mui/material/Stack';
import { Autocomplete, TextField } from '@mui/material';
import FormItem from '../../components/Redesing/form-item';
import { createHardwareV2 } from '../../api/hardware';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import SaveIcon from '@mui/icons-material/Save';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';
import { LocationStatus } from '../../ts/enums';

interface IHardwareAddState {
  hardware_type: IHardwareType[];
  productSelected: string | null;
  version: string | null;
  versionValidation: string | null;
  versionHelp: string;
  state: string | null;
  numberHelp: string;
  numberValidation: string | null;
  number: string | null;
  label: string | null;
  labelHelp: string;
  labelValidation: string | null;
  mac: string | null;
  macHelp: string;
  macValidation: string | null;
  imei: string | null;
  imeiHelp: string;
  imeiValidation: string | null;
  vendorSerial: string | null;
  vendorSerialHelp: string;
  vendorSerialValidation: string | null;
  vendorId: string | null;
  vendorIdHelp: string;
  vendorIdValidation: string | null;
  vendorQrCode: string | null;
  vendorQrCodeHelp: string;
  vendorQrCodeValidation: string | null;
  movaticQrCode: string | null;
  movaticQrCodeHelp: string;
  movaticQrCodeValidation: string | null;
}

const reducer = (state: IHardwareAddState, action: { type: string; value: any }) => {
  switch (action.type) {
    case 'SET_VALUE':
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

const HardwareAddNew = ({
  mode,
  auth,
  system,
  systemLoaded,
  systemAccess,
}: {
  mode: IMode;
  auth: IAuth;
  system: ISystem;
  systemLoaded: boolean;
  systemAccess: number;
}) => {
  const initialState = {
    hardware_type: null,
    productSelected: null,
    version: null,
    versionValidation: null,
    versionHelp: '',
    state: ACTIVE,
    numberHelp: '',
    numberValidation: null,
    number: null,
    label: null,
    labelHelp: '',
    labelValidation: null,
    mac: null,
    macHelp: '',
    macValidation: null,
    imei: null,
    imeiHelp: '',
    imeiValidation: null,
    vendorSerial: null,
    vendorSerialHelp: '',
    vendorSerialValidation: null,
    vendorId: null,
    vendorIdHelp: '',
    vendorIdValidation: null,
    vendorQrCode: null,
    vendorQrCodeHelp: '',
    vendorQrCodeValidation: null,
    movaticQrCode: null,
    movaticQrCodeHelp: '',
    movaticQrCodeValidation: null,
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const { data, isLoading } = useHardwareTypes();
  const hardwareTypes = data
    ? [
        {
          value: data[BATTERY].gid,
          name: 'Battery',
          old_id: data[BATTERY].old_id,
        },
        {
          value: data[BEACH_CHAIR].gid,
          name: 'Beach Chairs',
          old_id: data[BEACH_CHAIR].old_id,
        },
        {
          value: data[BIKE].gid,
          name: 'Bike',
          old_id: data[BIKE].old_id,
        },
        {
          value: data[BIKE_ROOM].gid,
          name: 'Bike Room',
          old_id: data[BIKE_ROOM].old_id,
        },
        {
          value: data[CANOE].gid,
          name: 'Canoe',
          old_id: data[CANOE].old_id,
        },
        {
          value: data[KAYAK].gid,
          name: 'Kayak',
          old_id: data[KAYAK].old_id,
        },
        {
          value: data[KEYBOX].gid,
          name: 'Keybox',
          old_id: data[KEYBOX].old_id,
        },
        {
          value: data[LOCK].gid,
          name: 'Lock',
          old_id: data[LOCK].old_id,
        },
        {
          value: data[LOCKER].gid,
          name: 'Locker',
          old_id: data[LOCKER].old_id,
        },
        {
          value: data[RACK].gid,
          name: 'Rack',
          old_id: data[RACK].old_id,
        },
        {
          value: data[SCOOTER].gid,
          name: 'Scooter',
          old_id: data[SCOOTER].old_id,
        },
      ]
    : [];
  const hardwareProductQuery = useHardwareProduct(
    {
      hardware_type_id: state.hardware_type?.value,
    },
    {
      enabled: !!state.hardware_type?.name,
    }
  );
  const hardwareProductVersionQuery = useHardwareProductVersion(
    {
      hardware_product_id: state.productSelected?.value,
    },
    {
      enabled: !!state.productSelected?.name,
    }
  );

  const stationQuery = useStation(
    {
      enabled: !!system?.gid,
    },
    { status: LocationStatus.active, system_id: system.gid }
  );

  const stationsList = stationQuery?.data?.data || [];
  const versionOptions = hardwareProductVersionQuery?.data || [];
  const productOptions = hardwareProductQuery?.data?.data || [];

  const isRentable = rentable(
    state?.hardware_type?.value,
    mode.details.hardware_type_gid
  );
  const hardwareTypeName = state?.hardware_type?.name;
  const {
    require_label,
    old_id: hardwareProductOldId,
    name: productName,
    has_mac,
    has_imei,
    has_vendor_serial,
    vendor_serial_description,
    has_vendor_id,
    has_station,
    vendor_serial_validation_regex,
  } = state?.productSelected || {};
  const partner = auth.admin.movaticAccess;

  const canSubmit = () => {
    let canSubmit = true;
    const { number, version, mac, imei, vendorId, vendorSerial, label } = state;

    if (isRentable) {
      if (!number) {
        canSubmit = false;
        dispatch({
          type: 'SET_VALUE',
          value: {
            numberValidation: 'error',
            numberHelp: 'A number is required',
          },
        });
      }

      if (number < 1 || number > HARDWARE_NUMBER_LIMIT) {
        canSubmit = false;
        dispatch({
          type: 'SET_VALUE',
          value: {
            numberValidation: 'error',
            numberHelp: 'The number you entered is invalid',
          },
        });
      }
    }

    if (has_mac) {
      if (!validateMacAddress(mac)) {
        canSubmit = false;
        dispatch({
          type: 'SET_VALUE',
          value: {
            macValidation: 'error',
            macHelp: 'Please format your mac address properly',
          },
        });
      }
    }

    if (has_imei) {
      if (!validateImei(imei)) {
        canSubmit = false;
        dispatch({
          type: 'SET_VALUE',
          value: {
            imeiValidation: 'error',
            imeiHelp: 'Please format your IMEI properly',
          },
        });
      }
    }

    if (has_vendor_id) {
      if (!vendorId) {
        canSubmit = false;
        dispatch({
          type: 'SET_VALUE',
          value: {
            vendorIdValidation: 'error',
            vendorIdHelp: 'Vendor Identifier is required',
          },
        });
      }
    }

    if (has_vendor_serial) {
      if (!validateVendorSerial(vendorSerial, vendor_serial_validation_regex)) {
        canSubmit = false;
        dispatch({
          type: 'SET_VALUE',
          value: {
            vendorSerialValidation: 'error',
            vendorSerialHelp: 'Please format your serial number properly',
          },
        });
      }
    }

    if (require_label) {
      if (!label) {
        canSubmit = false;
        dispatch({
          type: 'SET_VALUE',
          value: {
            labelValidation: 'error',
            labelHelp: 'Label is required',
          },
        });
      }
    }
    if (!version) {
      canSubmit = false;
      dispatch({
        type: 'SET_VALUE',
        value: {
          versionValidation: 'error',
          versionHelp: 'You must select a version',
        },
      });
    }

    return canSubmit;
  };

  const submit = () => {
    const serialType = `${
      state?.productSelected?.vendor === 'axa' ? 'axa' : 'vendor'
    }_serial`;
    const {
      number,
      station,
      version,
      vendorId,
      vendorSerial,
      mac,
      imei,
      label,
      movaticQrCode,
      vendorQrCode,
    } = state;
    if (canSubmit()) {
      const obj = {
        number: isRentable ? number || null : null,
        state: state.state,
        currentStation: has_station ? station?.value || null : null,
        hardware_product_id: hardwareProductOldId,
        hardware_product_version_id: version?.value || null,
        vendor_id: has_vendor_id ? vendorId : null,
        [serialType]: has_vendor_serial ? vendorSerial : null,
        mac: has_mac ? mac : null,
        imei: has_imei ? imei : null,
        label: label || null,
        movaticQrCode: movaticQrCode || null,
        vendorQrCode: vendorQrCode || null,
        typeName: hardwareTypeName.toLowerCase(),
      };
      CustomAsyncToast({
        promise: () => createHardwareV2(obj),
        successMessage: () => 'Hardware created successfully',
        loadingMessage: 'Creating hardware...',
        errorMessage: 'Error creating hardware',
      });
    }
  };

  const content = () => {
    return (
      <Stack paddingX={2} paddingBottom={2}>
        <FormItem
          label="Hardware Type"
          description="Select the type of hardware that you're adding"
          content={
            <Autocomplete
              id="hardware_type"
              options={hardwareTypes}
              getOptionLabel={(option) => toTitleCase(option.name)}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              value={state.hardware_type}
              onChange={(_, selected) => {
                dispatch({
                  type: 'SET_VALUE',
                  value: { hardware_type: selected, productSelected: null },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // label="Hardware Type"
                  placeholder={'Select Hardware Type'}
                  name="hardware_type"
                  variant="outlined"
                  fullWidth
                  // helperText="Select the type of hardware that you're adding"
                />
              )}
            />
          }
        />
        {state?.hardware_type?.name && (
          <>
            <FormItem
              label="Hardware Product"
              description={`Select the brand/model of the ${toTitleCase(
                state.hardware_type.name
              )} that you're adding`}
              content={
                <Autocomplete
                  id="product"
                  options={productOptions
                    .filter(
                      (item: { data: { old_id: string } }) => item.data.old_id !== '1'
                    )
                    .map((product: IHardwareProduct) => ({
                      old_id: product.data.old_id,
                      vendor: product.data.vendor,
                      name: product.data.name,
                      value: product.data.id,
                      require_label: product.data.require_label,
                      has_mac: product.data.has_mac,
                      has_imei: product.data.has_imei,
                      has_vendor_serial: product.data.has_vendor_serial,
                      has_vendor_id: product.data.has_vendor_id,
                      has_station: product.data.has_station,
                      vendor_serial_description: product.data.vendor_serial_description,
                      vendor_serial_validation_regex:
                        product.data.vendor_serial_validation_regex,
                    }))}
                  getOptionLabel={(option) => toTitleCase(option.name)}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  value={state.productSelected}
                  onChange={(_, selected) =>
                    dispatch({ type: 'SET_VALUE', value: { productSelected: selected } })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="productSelected"
                      variant="outlined"
                      placeholder={'Select Hardware Product'}
                      fullWidth
                    />
                  )}
                />
              }
            />

            {state?.productSelected?.name && (
              <>
                {versionOptions?.data?.length > 0 && (
                  <>
                    <FormItem
                      label="Product Version"
                      description={`Select the version of the ${productName} you're adding`}
                      content={
                        <Autocomplete
                          id="version"
                          options={versionOptions?.data?.map(
                            (version: IHardwareVersion) => ({
                              name: version.name,
                              value: version.old_id,
                            })
                          )}
                          getOptionLabel={(option) => toTitleCase(option.name)}
                          isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                          }
                          value={state.version}
                          onChange={(_, selected) =>
                            dispatch({
                              type: 'SET_VALUE',
                              value: {
                                version: selected,
                                versionValidation: null,
                                versionHelp: '',
                              },
                            })
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="versionSelected"
                              variant="outlined"
                              placeholder={'Select Hardware Version'}
                              error={!!state.versionValidation}
                              fullWidth
                            />
                          )}
                        />
                      }
                    />
                  </>
                )}
                <FormItem
                  label={'Hardware State'}
                  content={
                    <RadioButtons
                      value={state.state}
                      handleChange={(event, value: string) =>
                        dispatch({ type: 'SET_VALUE', value: { state: value } })
                      }
                      options={[
                        { id: ACTIVE, label: 'Active' },
                        { id: MAINTENANCE, label: 'Maintenance' },
                      ]}
                    />
                  }
                />

                {isRentable && (
                  <FormItem
                    label="Number"
                    description={`This must be a number from 1 to ${HARDWARE_NUMBER_LIMIT}`}
                    content={
                      <TextField
                        type={'number'}
                        id="number"
                        variant="outlined"
                        fullWidth
                        value={state.number}
                        error={!!state.numberValidation}
                        helperText={state.numberHelp}
                        onChange={(e) => {
                          const value = Number(e.target.value);
                          if (value < 1 || value > HARDWARE_NUMBER_LIMIT) {
                            dispatch({
                              type: 'SET_VALUE',
                              value: {
                                number: e.target.value,
                                numberValidation: 'error',
                                numberHelp: 'The number you entered is invalid',
                              },
                            });
                            return;
                          }
                          dispatch({
                            type: 'SET_VALUE',
                            value: {
                              number: e.target.value,
                              numberValidation: null,
                              numberHelp: '',
                            },
                          });
                        }}
                      />
                    }
                  />
                )}
                <FormItem
                  label={`Label${require_label ? ' (required)' : ' (optional)'}`}
                  description={`Descriptive label to help admins identify the ${hardwareTypeName}`}
                  content={
                    <TextField
                      id="label"
                      variant="outlined"
                      fullWidth
                      value={state.label}
                      error={!!state.labelValidation}
                      onChange={(e) =>
                        dispatch({
                          type: 'SET_VALUE',
                          value: {
                            label: e.target.value,
                            labelValidation: null,
                            labelHelp: '',
                          },
                        })
                      }
                    />
                  }
                />

                {has_mac && (
                  <FormItem
                    label="Mac Address"
                    description={`Please enter the mac address for this ${productName}. This must be six groups of two hexadecimal digits (0-9, a-f) separated by colons (e.g. 01:23:45:67:89:ab)`}
                    content={
                      <TextField
                        id="mac"
                        variant="outlined"
                        fullWidth
                        value={state.mac}
                        error={!!state.macValidation}
                        helperText={state.macHelp}
                        onChange={(e) =>
                          dispatch({
                            type: 'SET_VALUE',
                            value: {
                              mac: e.target.value,
                              macValidation: null,
                              macHelp: '',
                            },
                          })
                        }
                      />
                    }
                  />
                )}
                {has_imei && (
                  <FormItem
                    label="IMEI"
                    description="Must be 15 or 17 digits"
                    content={
                      <TextField
                        id="imei"
                        variant="outlined"
                        fullWidth
                        value={state.imei}
                        helperText={state.imeiHelp}
                        error={!!state.imeiValidation}
                        onChange={(e) =>
                          dispatch({
                            type: 'SET_VALUE',
                            value: {
                              imei: e.target.value,
                              imeiValidation: null,
                              imeiHelp: '',
                            },
                          })
                        }
                      />
                    }
                  />
                )}
                {has_vendor_serial && (
                  <FormItem
                    label="Serial Number"
                    description={vendor_serial_description || ''}
                    content={
                      <TextField
                        id="vendorSerial"
                        error={!!state.vendorSerialValidation}
                        variant="outlined"
                        fullWidth
                        value={state.vendorSerial}
                        helperText={state.vendorSerialHelp}
                        onChange={(e) =>
                          dispatch({
                            type: 'SET_VALUE',
                            value: {
                              vendorSerial: e.target.value,
                              vendorSerialValidation: null,
                              vendorSerialHelp: '',
                            },
                          })
                        }
                      />
                    }
                  />
                )}
                {has_vendor_id && (
                  <FormItem
                    label="Vendor ID"
                    description="Unique vendor identifier for this hardware"
                    content={
                      <TextField
                        id="vendorId"
                        variant="outlined"
                        fullWidth
                        value={state.vendorId}
                        error={!!state.vendorIdValidation}
                        helperText={state.vendorIdHelp}
                        onChange={(e) =>
                          dispatch({
                            type: 'SET_VALUE',
                            value: {
                              vendorId: e.target.value,
                              vendorIdValidation: null,
                              vendorIdHelp: '',
                            },
                          })
                        }
                      />
                    }
                  />
                )}
                {isRentable && (
                  <div>
                    {partner && (
                      <FormItem
                        label="Movatic QR Code"
                        description={`ID of the QR code you would like to assign to this ${hardwareTypeName} (optional)`}
                        content={
                          <TextField
                            id="movaticQrCode"
                            variant="outlined"
                            fullWidth
                            type={'number'}
                            value={state.movaticQrCode}
                            helperText={state.movaticQrCodeHelp}
                            error={!!state.movaticQrCodeValidation}
                            onChange={(e) => {
                              const value = Number(e.target.value);
                              if (value && value < 1) {
                                dispatch({
                                  type: 'SET_VALUE',
                                  value: {
                                    movaticQrCode: e.target.value,
                                    movaticQrCodeValidation: 'error',
                                    movaticQrCodeHelp:
                                      'The number you entered is invalid',
                                  },
                                });
                                return;
                              }
                              dispatch({
                                type: 'SET_VALUE',
                                value: {
                                  movaticQrCode: e.target.value,
                                  movaticQrCodeValidation: null,
                                  movaticQrCodeHelp: '',
                                },
                              });
                            }}
                          />
                        }
                      />
                    )}
                    <FormItem
                      label="Vendor QR Code"
                      description={`ID of the QR code for this ${hardwareTypeName} provided by the vendor. For use in demo systems only (optional)`}
                      content={
                        <TextField
                          id="vendorQrCode"
                          variant="outlined"
                          fullWidth
                          value={state.vendorQrCode}
                          error={!!state.vendorQrCodeValidation}
                          helperText={state.vendorQrCodeHelp}
                          onChange={(e) =>
                            dispatch({
                              type: 'SET_VALUE',
                              value: {
                                vendorQrCode: e.target.value,
                                vendorQrCodeValidation: null,
                                vendorQrCodeHelp: '',
                              },
                            })
                          }
                        />
                      }
                    />
                  </div>
                )}
                {has_station && (
                  <FormItem
                    label="Assigned Location"
                    description={`The current location of the ${productName}`}
                    content={
                      <Autocomplete
                        loading={stationQuery.isLoading}
                        id="station"
                        options={stationsList
                          .filter((station: IStation) => station.configuration !== 'zone')
                          .map((station: IStation) => ({
                            name: station.name,
                            value: station.old_id,
                          }))}
                        getOptionLabel={(option) => toTitleCase(option.name)}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        value={state.station}
                        onChange={(_, selected) =>
                          dispatch({ type: 'SET_VALUE', value: { station: selected } })
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="stationSelected"
                            variant="outlined"
                            placeholder={'Select Station'}
                            fullWidth
                          />
                        )}
                      />
                    }
                  />
                )}
              </>
            )}
          </>
        )}
      </Stack>
    );
  };
  return (
    <PageContainer isLoading={isLoading && !systemLoaded}>
      <CardView
        headerActions={
          <IconButtonMenu
            buttons={[
              {
                label: 'Cancel',
                onClick: () => history.push('/hardware'),
              },
              {
                label: 'Save',
                onClick: submit,
                disabled:
                  !state.productSelected ||
                  (!findAccess(systemAccess).includes('physical') &&
                    !auth.admin.movaticAccess),
                startIcon: <SaveIcon />,
              },
            ]}
          />
        }
        content={content()}
        title={'Add'}
      />
    </PageContainer>
    // <Card
    //   detailedView
    //   header="Add Hardware"
    //   content={content()}
    //   button={
    //     <Button
    //       buttons={2}
    //       primaryText="Submit"
    //       primaryName="submit"
    //       primaryOnClick={submit}
    //       primaryDisabled={
    //         !state?.productSelected?.length ||
    //         (!findAccess(systemAccess).includes('physical') && !auth.admin.movaticAccess)
    //       }
    //       secondaryText="Cancel"
    //       secondaryName="cancel"
    //       secondaryOnClick={() => history.push('/hardware')}
    //     />
    //   }
    //   loaded={!isLoading && systemLoaded}
    // />
  );
};

export default connect(
  (state: {
    admin: { admin: IAuth; systemAccess: number };
    system: { current: ISystem; systems: ISystem[]; isLoaded: boolean };
    mode: IMode;
  }) => ({
    auth: state.admin.admin,
    system: state.system.current,
    mode: state.mode,
    systems: state.system.systems,
    systemLoaded: state.system.isLoaded,
    systemAccess: state.admin.systemAccess,
  }),
  () => ({})
)(HardwareAddNew);
