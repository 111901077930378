import type { FC } from 'react';
import PropTypes from 'prop-types';
// import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01';
import MenuIcon from '@mui/icons-material/Menu';
import { alpha } from '@mui/system/colorManipulator';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { Theme } from '@mui/material/styles/createTheme';
const TOP_NAV_HEIGHT = 64;
const SIDE_NAV_WIDTH = 50;

interface TopNavProps {
  onMobileNavOpen?: () => void;
}

export const TopNav: FC<TopNavProps> = (props) => {
  const { onMobileNavOpen, ...other } = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  return (
    <Box
      component="header"
      sx={{
        backdropFilter: 'blur(6px)',
        backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
        position: 'sticky',
        left: {
          lg: `${SIDE_NAV_WIDTH}px`,
        },
        top: 0,
        width: {
          lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
        },
        zIndex: (theme) => theme.zIndex.appBar,
      }}
      {...other}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={1}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: 1,
        }}
      >
        <Stack alignItems="center" direction="row" spacing={2} id="mobile-menu">
          {!lgUp && (
            <IconButton onClick={onMobileNavOpen}>
              <SvgIcon>{<MenuIcon />}</SvgIcon>
            </IconButton>
          )}
          {/*<SearchButton />*/}
        </Stack>
        {/*<Stack alignItems="center" direction="row" spacing={0} sx={{ pr: 1 }}>*/}
        {/*  /!*<LanguageSwitch />*!/*/}
        {/*  /!*<NotificationsButton />*!/*/}
        {/*  /!*<ContactsButton />*!/*/}
        {/*  <AccountButton />*/}
        {/*</Stack>*/}
      </Stack>
    </Box>
  );
};

TopNav.propTypes = {
  onMobileNavOpen: PropTypes.func,
};
