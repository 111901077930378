import { SUCCESS, ERROR } from '../constants';
import bugsnagClient from '../components/bugsnag';

export const setError = (error) => ({
  type: ERROR,
  error,
});

export const setSuccess = (success) => ({
  type: SUCCESS,
  success,
});

export const clear = (errorOrSuccess) => ({
  type: errorOrSuccess,
});

export const gotError = (error) => (dispatch) => {
  if (error && error !== 'Your session has expired, please log back in') {
    bugsnagClient.notify(new Error(error.toString()));
  }
  dispatch(setError(error));
};

export const gotSuccess = (success) => (dispatch) => {
  dispatch(setSuccess(success));
};
