import { ILocalAdminUser } from '../../../ts/interface/admins.interface';

const userLogin = () => {
  return (admin: ILocalAdminUser) => {
    localStorage.setItem('adminDate', new Date().toString());
    localStorage.setItem('admin', JSON.stringify(admin));
  };
};

export default userLogin;
