import React, { useState } from 'react';
import 'react-phone-number-input/style.css';
import { history } from '../../utils';
import { useParams } from 'react-router-dom';
import useRfidCard from '../../hooks/users/query/useRfidCard';
import { IRfidCard } from '../../ts/interface/rfidCard.interface';
import PageContainer from '../../components/Redesing/page-container';
import CardView from '../../components/Redesing/card-view';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { Stack } from '@mui/system';
import { MovaticFieldGroupText } from '../../components/Redesing/movatic-field-group';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';
import { updateUserRfidCard } from '../../api/users';

const EditUserRfidCard = () => {
  const { userPhone = '', cardId = '' } = useParams<{
    userPhone: string;
    cardId: string;
  }>();
  const [rfidCardId, setRfidCardId] = useState<string | null>(null);
  const [rfidCardLabel, setRfidCardLabel] = useState<string | null>(null);
  const [rfidCardIdValidation, setRfidCardIdValidation] = useState<string | null>(null);

  const { isLoading } = useRfidCard(cardId, {
    onSuccess: (data: IRfidCard) => {
      const { card_id, label } = data;
      setRfidCardId(card_id || '');
      setRfidCardLabel(label || '');
    },
  });

  const handleSubmit = () => {
    let canSubmit = true;
    if (!rfidCardId) {
      setRfidCardIdValidation('error');
      canSubmit = false;
    }
    setRfidCardIdValidation(null);

    if (canSubmit) {
      let cardPayload = {
        card_id: rfidCardId,
        label: rfidCardLabel,
      };
      CustomAsyncToast({
        promise: () =>
          updateUserRfidCard({
            cardId: cardId,
            cardPayload: cardPayload,
            userPhone: userPhone,
          }),
        successMessage: () => 'User RFID card update was successful!',
        errorMessage: 'User RFID card update failed!',
        loadingMessage: 'Updating user RFID card...',
      }).then((r) => {
        if (r?.status === 200) {
          history.push(`/users/${userPhone}/rfid/${cardId}`);
        }
      });
    }
  };

  return (
    <PageContainer>
      <CardView
        isLoading={isLoading}
        content={
          <Stack px={2} pb={2}>
            <MovaticFieldGroupText
              id="cardId"
              name={'cardId'}
              label="Card ID"
              description="Required"
              hasError={rfidCardIdValidation === 'error'}
              errorMessage={'Card ID is required'}
              value={rfidCardId}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setRfidCardId(e.target.value);
              }}
            />
            <MovaticFieldGroupText
              id="cardLabel"
              name={'cardLabel'}
              label="Card label"
              description="optional"
              value={rfidCardLabel}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setRfidCardLabel(e.target.value);
              }}
            />
          </Stack>
        }
        title="Update user RFID card"
        headerActions={
          <IconButtonMenu
            buttons={[
              {
                label: 'Cancel',
                onClick: () => history.goBack(),
                startIcon: <CloseIcon />,
              },
              {
                label: 'Save',
                startIcon: <SaveIcon />,
                onClick: handleSubmit,
              },
            ]}
          />
        }
      />
    </PageContainer>
  );
};

export default EditUserRfidCard;
