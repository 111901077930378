import { useCallback, useMemo, useState } from 'react';

import useUrlState from '../../../hooks/use-url-state';
import { DEFAULT_ROWS_PER_PAGE, EVENT_LOGS_TYPES } from '../../../constants';
import {
  IColumn,
  ISortConfig,
  FilterOption,
} from '../../../ts/interface/components/redesing/table.interface';
import { IRental } from '../../../ts/interface/rental.interface';
import useHardwareEventLogs from '../../../hooks/hardware/query/useHardwareEventLogs';
import { IHardwareEvent } from '../../../ts/interface/hardware.interface';
import { useUpdateEffect } from '../../../hooks/use-update-effect';
import { SearchChip } from '../../../ts/interface/components/table.interface';
import { eventLogsColumns } from '../../../tableColumns/hardwareColumns';
import { PaginationTable } from '../../../components/Redesing/table/pagination-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  MovaticFieldGroupSelectFilter,
  MovaticFieldGroupSingleSelectFilter,
} from '../../../components/Redesing/movatic-field-group';
import { MenuItem } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { toTitleCase } from '../../../utils';
import Card from '@mui/material/Card';
import { SeverityPillColor } from '../../../ts/types/settings';

function RentalHardwareEventLogs({ rental }: { rental: IRental }) {
  const [filters, setFilters, isComplete, isRestoring] = useUrlState({
    sizePerPage: DEFAULT_ROWS_PER_PAGE,
    pageNumber: 0,
    search: '',
    hardware_id: rental?.hardware?.id,
    hardware_name: `${toTitleCase(rental?.hardware?.type_name)} ${toTitleCase(
      rental?.hardware?.hardware_product_name ?? ''
    )} ${rental?.hardware?.id}`,
    selectedHardwareId: rental?.hardware?.id,
    eventTypes: [] as FilterOption[],
    after_created_on: rental?.start?.time,
    before_created_on: rental?.end?.time,
    lastId: null as string | null,
    types: 'Important',
    sortConfig: {
      key: 'created_on',
      direction: 'desc',
    } as ISortConfig,
  });
  const [events, setEvents] = useState<IHardwareEvent[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [exportData, setExportData] = useState(false);
  const initialHardwareName = `${toTitleCase(rental?.hardware?.type_name)} ${toTitleCase(
    rental?.hardware?.hardware_product_name ?? ''
  )} ${rental?.hardware?.id}`;

  const [chips, setChips] = useState<SearchChip[]>([
    {
      label: 'Hardware',
      field: 'hardware_id',
      value: rental?.hardware?.id,
      displayValue: filters.hardware_name,
    },
  ]);

  const hardwareOptions: FilterOption[] = [
    ...rental?.hardware?.children?.map((hardware) => ({
      value: hardware.id,
      label: `${toTitleCase(hardware?.type_name)} ${toTitleCase(
        hardware?.display_name
      )} ${hardware.id}`,
    })),
    {
      value: rental?.hardware?.id,
      label: initialHardwareName,
    },
  ];

  const filterTypes = filters.types?.includes('Important')
    ? EVENT_LOGS_TYPES.filter((item) => item.value !== 'Important')
        .map((item) => item.value)
        .join(',')
    : filters.types ?? '';

  const { refetch, isRefetching, isLoading } = useHardwareEventLogs(
    filters.hardware_id,
    {
      limit:
        filters.lastId && isComplete && isRestoring
          ? DEFAULT_ROWS_PER_PAGE
          : filters.sizePerPage,
      after: filters.lastId && isComplete && isRestoring ? null : filters.lastId,
      order_by: filters.sortConfig.key,
      order: filters.sortConfig.direction,
      after_created_on: filters.after_created_on,
      before_created_on: filters.before_created_on,
      filter_types: filterTypes !== '' ? filterTypes : null,
    },
    {
      enabled: false,
    }
  );

  const hasMoreMemo: boolean = useMemo(() => {
    const { sizePerPage, pageNumber } = filters;
    return !Boolean(hasMore) && events?.length >= sizePerPage * (pageNumber + 1)
      ? true
      : hasMore;
  }, [events, filters, hasMore]);

  useUpdateEffect(() => {
    if (isComplete) {
      refetch().then((res) => {
        if (res.status === 'success') {
          const { data, has_more } = res.data;
          setHasMore(has_more);
          const eventsData = data.map((event: IHardwareEvent) => {
            const { type } = event;
            const colorType = EVENT_LOGS_TYPES.find(({ value }) => value === type);
            event.color_type = (colorType?.color as SeverityPillColor) ?? null;
            return event;
          });

          if (filters.lastId && eventsData) {
            setEvents((events) => [...events, ...eventsData]);
          } else {
            setEvents(eventsData);
          }
        }
      });

      const event_types = filters?.types ? filters.types.split(',') : [];

      if (event_types.length > 0) {
        const chips = event_types.map((type) => {
          return {
            label: 'Types',
            field: 'types',
            value: type,
            displayValue: EVENT_LOGS_TYPES[type as keyof typeof EVENT_LOGS_TYPES],
            disabled: false,
          };
        });
        setChips([
          {
            label: 'Hardware',
            field: 'hardware_id',
            value: rental?.hardware?.id,
            displayValue: filters.hardware_name,
          },
          ...chips,
        ]);

        setFilters((prevState) => ({
          ...prevState,
          eventTypes: event_types.map((type) => {
            return {
              label: type,
              value: type,
            };
          }),
        }));
      }
    }
  }, [
    filters.lastId,
    filters.sizePerPage,
    refetch,
    filters.sortConfig,
    filters.hardware_id,
    filters.types,
    isComplete,
  ]);

  const handleChipsChange = useCallback(
    (
      values: string[],
      list: { label: string; value: string | number }[],
      metadata: { label: string; value: string }
    ): void => {
      // if metadata.value is types, and had Important but also more values remove Important
      if (metadata.value === 'types' && values.length > 1) {
        values = values.filter((value) => value !== 'Important');
      }

      setFilters((prevState) => ({
        ...prevState,
        pageNumber: 0,
        sizePerPage: DEFAULT_ROWS_PER_PAGE,
        [metadata.value]: values.join(','),
      }));

      setChips((prevChips) => {
        const valuesFound: string[] = [];

        const newChips = prevChips.filter((chip) => {
          if (chip.field !== metadata.value) {
            return true;
          }

          const found = values.includes(chip.value as string);

          if (found) {
            valuesFound.push(chip.value as string);
          }

          return found;
        });

        if (values.length === valuesFound.length) {
          return newChips;
        }

        values.forEach((value) => {
          if (!valuesFound.includes(value)) {
            const option = list.find((option) => option.value.toString() === value);
            newChips.push({
              label: metadata.label,
              field: metadata.value,
              value,
              displayValue: option!.label,
            });
          }
        });

        return newChips;
      });
    },
    [setFilters]
  );

  const handleChipDelete = useCallback(
    (deletedGroup: SearchChip) => {
      setChips((prevChips) => {
        return prevChips.filter((group) => {
          return group.label !== deletedGroup.label;
        });
      });

      const chipToDelete = deletedGroup.label.toLowerCase() as 'hardware_id' | 'types';

      setFilters((prevState) => ({
        ...prevState,
        pageNumber: 0,
        sizePerPage: DEFAULT_ROWS_PER_PAGE,
        [chipToDelete]: chipToDelete === 'hardware_id' ? rental?.hardware?.id : null,
      }));

      const deleteChip = {
        hardware_id: () =>
          setFilters((prevState) => ({
            ...prevState,
            hardware_id: rental?.hardware?.id,
          })),
        types: () => setFilters((prevState) => ({ ...prevState, eventTypes: [] })),
      };

      if (deleteChip[chipToDelete]) {
        deleteChip[chipToDelete]();
      }
    },
    [rental?.hardware?.id, setFilters, setChips]
  );

  const eventTypesOptions = useMemo(
    () =>
      EVENT_LOGS_TYPES.map((item) => ({
        label: item.name,
        value: item.value,
      })),
    []
  );

  return (
    <Card>
      <PaginationTable
        dataId="rental-hardware-event-logs"
        isLoading={isLoading || isRefetching || !filters.hardware_id}
        disableHasMore
        showFilter
        padding={{ pt: 1, pl: 0, pr: 1 }}
        hasMore={hasMoreMemo}
        chips={chips}
        handleChipDelete={handleChipDelete}
        exportData={exportData}
        csvFileName={'rental_event_logs.csv'}
        onExport={() => setExportData(false)}
        buttons={[
          {
            label: 'Export',
            startIcon: <FileDownloadIcon />,
            onClick: () => setExportData(true),
          },
        ]}
        onApplyFilter={() => {
          if (filters.selectedHardwareId !== filters.hardware_id) {
            handleChipsChange(
              [filters.selectedHardwareId],
              hardwareOptions.map(({ label, value }) => ({ label, value })),
              { label: 'Hardware', value: 'hardware_id' }
            );
          }

          const joinedTypes = filters.eventTypes
            .map(({ value }) => value.toString())
            .join(',');

          if (joinedTypes !== filters.types) {
            handleChipsChange(
              filters.eventTypes.map(({ value }) => value.toString()),
              eventTypesOptions,
              { label: 'Types', value: 'types' }
            );
          }
        }}
        spaceFilters={
          <>
            <MovaticFieldGroupSingleSelectFilter
              label={'Hardware'}
              value={filters.selectedHardwareId}
              onChange={(e: SelectChangeEvent) => {
                const findSelectedHardware = hardwareOptions.find(
                  (option) => option.value === e.target.value
                );

                setFilters((prev) => ({
                  ...prev,
                  selectedHardwareId: e.target.value,
                  hardware_name: findSelectedHardware!.label || '',
                }));
              }}
              options={hardwareOptions.map((option: FilterOption, index: number) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            />
            <MovaticFieldGroupSelectFilter
              dataId="event-types-filter"
              label={'Types'}
              value={filters.eventTypes}
              onChange={(event, newValue) => {
                setFilters((prev) => ({
                  ...prev,
                  eventTypes: newValue as FilterOption[],
                }));
              }}
              options={eventTypesOptions}
            />
          </>
        }
        sortBy={filters.sortConfig.key}
        onSortChange={(sort) =>
          setFilters((prevState) => ({
            ...prevState,
            sortConfig: sort as ISortConfig,
            pageNumber: 0,
            sizePerPage: DEFAULT_ROWS_PER_PAGE,
            lastId: null,
          }))
        }
        columns={eventLogsColumns as IColumn[]}
        items={events || []}
        searchPlaceholder={`Search logs`}
        page={filters.pageNumber}
        rowsPerPage={filters.sizePerPage}
        totalItems={events?.length}
        onPageChange={(page, newPage) => {
          const pageNumberAux = newPage + 1;
          if (events?.length / filters.sizePerPage < pageNumberAux) {
            setFilters((prev) => ({
              ...prev,
              pageNumber: newPage,
              lastId: events[events.length - 1].id,
            }));
            return;
          }
          setFilters((prevState) => ({
            ...prevState,
            pageNumber: newPage,
          }));
        }}
        onRowsPerPageChange={(pageSizeString) => {
          const pageSize = Number(pageSizeString.target.value);
          setFilters((prevState) => ({
            ...prevState,
            lastId: null,
            sizePerPage: pageSize,
          }));
        }}
        handleSearch={(e: React.ChangeEvent<HTMLInputElement>) =>
          setFilters((prev) => ({
            ...prev,
            search: e.target.value,
          }))
        }
        searchText={filters.search}
      />
    </Card>
  );
}

export default RentalHardwareEventLogs;
