import {
  API_TRANSACTION_SUCCESS,
  TRANSACTION_DETAILS,
  CLEAR_TRANSACTION_DETAILS,
  WALLET_TRANSACTION_DETAILS,
  WALLET_TRANSACTION_DETAIL_LOADED,
  TRANSACTION_DETAIL_LOADED,
  TRANSACTIONS_LOADED,
} from '../constants';
import { fetchOptions, baseUrl, createQueryParameters } from '../api/http';
import { convertCentsToMoney } from '../utils';
import { FourOhOneError } from './system';
import bugsnagClient from '../components/bugsnag';

const transactionsLoaded = (loaded) => ({
  type: TRANSACTIONS_LOADED,
  loaded,
});

export const apiTransactionSuccess = (transactions) => ({
  type: API_TRANSACTION_SUCCESS,
  transactions,
});

// transaction details directly from stripe
export const setTransactionDetail = (transaction) => ({
  type: TRANSACTION_DETAILS,
  transaction,
});

export const transactionDetailLoaded = (loaded) => ({
  type: TRANSACTION_DETAIL_LOADED,
  loaded,
});

export const clearTransactionDetails = () => ({
  type: CLEAR_TRANSACTION_DETAILS,
});

export const setWalletTransactionDetail = (walletTransaction) => ({
  type: WALLET_TRANSACTION_DETAILS,
  walletTransaction,
});

export const walletTransactionDetailLoaded = (loaded) => ({
  type: WALLET_TRANSACTION_DETAIL_LOADED,
  loaded,
});

export const getTransactions = (parameters) => (dispatch, getState) => {
  dispatch(transactionsLoaded(false));
  const state = getState();
  const fetchOps = fetchOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id
  );
  const queryParametersObject = {
    ...parameters,
  };
  const queryParameters = parameters
    ? `?${createQueryParameters(queryParametersObject)}`
    : '';

  return fetch(`${baseUrl}/transactions${queryParameters}`, fetchOps)
    .then((res) => res.json())
    .then((transactions) => {
      if (transactions.status === 200) {
        const maptransactions = transactions.message.map((transaction) => {
          // TODO don't map this at all
          const trans = { ...transaction };

          switch (trans.type?.toLowerCase()) {
            case 'wallet debit':
              trans.cents = trans.charge_total * -1;
              break;
            case 'transfer':
            case 'refund':
              trans.cents = trans.amount;
              break;
            default:
              trans.cents = trans.charge_total ?? trans.amount;
          }

          trans.cents = trans.cents || 0;
          trans.amount = convertCentsToMoney(trans.cents);

          if (trans.description != null) {
            trans.type = trans.description.includes('plan') ? 'Plan Charge' : trans.type;
          }

          return trans;
        });

        return Promise.all([dispatch(apiTransactionSuccess(maptransactions))]);
      }
      dispatch(FourOhOneError(transactions));
    })
    .catch((e) => {
      bugsnagClient.notify(e);
      console.log('inside get transactions catch with error,', e);
    });
};

// get info from stripe
export const getTransactionDetails = (chargeId) => (dispatch, getState) => {
  dispatch(transactionDetailLoaded(false));

  if (!chargeId) {
    dispatch(clearTransactionDetails());
    dispatch(transactionDetailLoaded(true));
    return;
  }

  const state = getState();
  const fetchOps = fetchOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id
  );

  fetch(`${baseUrl}/transactions/charge/${chargeId}`, fetchOps)
    .then((res) => res.json())
    .then((chargeInfo) => {
      if (chargeInfo.status === 200) {
        dispatch(setTransactionDetail(chargeInfo.message));
      } else {
        dispatch(clearTransactionDetails());
        dispatch(FourOhOneError(chargeInfo));
      }
    })
    .catch((error) => {
      dispatch(clearTransactionDetails());
      bugsnagClient.notify(error);
      console.log(error);
    })
    .finally(() => dispatch(transactionDetailLoaded(true)));
};

export const getWalletTransactionDetails =
  (walletTransactionId) => (dispatch, getState) => {
    dispatch(walletTransactionDetailLoaded(false));

    if (!walletTransactionId) {
      dispatch(setWalletTransactionDetail({}));
      dispatch(walletTransactionDetailLoaded(true));
      return;
    }

    const state = getState();
    const fetchOps = fetchOptions(
      state.admin.admin.admin.email,
      state.admin.admin.token,
      state.system.current.id
    );

    fetch(`${baseUrl}/transactions/wallettransaction/${walletTransactionId}`, fetchOps)
      .then((res) => res.json())
      .then((walletTransactionInfo) => {
        if (walletTransactionInfo.status === 200) {
          dispatch(setWalletTransactionDetail(walletTransactionInfo.message));
        } else {
          dispatch(setWalletTransactionDetail({}));
          dispatch(FourOhOneError(walletTransactionInfo));
        }
      })
      .catch((error) => {
        dispatch(setWalletTransactionDetail({}));
        bugsnagClient.notify(error);
        console.log(error);
      })
      .finally(() => dispatch(walletTransactionDetailLoaded(true)));
  };
