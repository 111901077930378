import React, { useCallback, useMemo, useState } from 'react';
import useRates from '../../hooks/rates/query/useRates';
import { IRate } from '../../ts/interface/rental.interface';
import { connect } from 'react-redux';
import { DEFAULT_ROWS_PER_PAGE, RATE_DURATIONS } from '../../constants';
import { findAccess, history, timeForHumans } from '../../utils';
import { tableColumns } from '../../tableColumns/ratesV2Columns';
import { PaginationTable } from '../../components/Redesing/table/pagination-table';
import RowItem from '../../components/Redesing/row-item';
import { Autocomplete, TextField } from '@mui/material';
import Chip from '@mui/material/Chip';
import { IColumn } from '../../ts/interface/components/redesing/table.interface';
import { SearchChip } from '../../ts/interface/components/table.interface';
import AddIcon from '@mui/icons-material/Add';
import Card from '@mui/material/Card';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const filterOptions = [
  { value: 'activeRates', label: 'Active' },
  { value: 'pendingRates', label: 'Pending' },
  { value: 'archivedRates', label: 'Archived' },
];

const RatesV2 = ({
  systemLoaded,
  movaticAccess,
  systemAccess,
}: {
  systemLoaded: boolean;
  movaticAccess: number;
  systemAccess: number;
}) => {
  const [rates, setRates] = useState<IRate[]>([]);
  const [pageNumberV2, setPageNumberV2] = useState<number>(0);
  const [sizePerPageV2, setSizePerPageV2] = useState<number>(DEFAULT_ROWS_PER_PAGE);
  const [sortData, setSortData] = useState<string>('name');
  const [search, setSearch] = useState<string>('');
  const [chips, setChips] = useState<SearchChip[]>([]);
  const [value, setValue] = React.useState<any[]>([]);
  const [exportData, setExportData] = useState<boolean>(false);

  useRates({
    enabled: systemLoaded,
    onSuccess: (data: IRate[]) => {
      data.forEach((rate) => {
        rate.active_on = new Date(rate.active_on).toDateString();
        if (rate.price_per_period && !rate.base_rate) {
          RATE_DURATIONS.forEach((time) => {
            if (time.seconds === rate.period_duration) {
              rate.stringDuration = time.value;
            }
          });
          rate.free_time && rate.free_time !== 0 && rate.free_time !== null
            ? (rate.overview = `${timeForHumans(rate.free_time)} free, then $${(
                rate.price_per_period / 100
              ).toFixed(2)} for ${rate.stringDuration}`)
            : (rate.overview = `$${(rate.price_per_period / 100).toFixed(2)} for ${
                rate.stringDuration
              }`);
        } else if (rate.price_per_period && rate.base_rate) {
          RATE_DURATIONS.forEach((time) => {
            if (time.seconds === rate.period_duration) {
              rate.stringDuration = time.value;
            }
          });
          rate.free_time && rate.free_time !== 0 && rate.free_time !== null
            ? (rate.overview = `${timeForHumans(rate.free_time)} free, then $${(
                rate.base_rate / 100
              ).toFixed(2)} + $${(rate.price_per_period / 100).toFixed(
                2
              )} per ${timeForHumans(rate.period_duration)}`)
            : (rate.overview = `$${(rate.base_rate / 100).toFixed(2)} + $${(
                rate.price_per_period / 100
              ).toFixed(2)} per ${timeForHumans(rate.period_duration)}`);
        }

        if (
          rate.archived &&
          new Date(rate.active_on).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)
        ) {
          rate.archived = new Date(rate.archived).toDateString();
        }
      });

      setRates(data);
    },
  });

  const getAccess = () => {
    return findAccess(systemAccess).includes('rates') || !!movaticAccess;
  };

  const handleStatusChange = useCallback((values: string[]): void => {
    setPageNumberV2(0);
    setSizePerPageV2(DEFAULT_ROWS_PER_PAGE);
    setChips((prevChips) => {
      const valuesFound: string[] = [];

      const newChips = prevChips.filter((chip) => {
        if (chip.field !== 'status') {
          return true;
        }

        const found = values.includes(chip.value as string);

        if (found) {
          valuesFound.push(chip.value as string);
        }

        return found;
      });

      if (values.length === valuesFound.length) {
        return newChips;
      }

      values.forEach((value) => {
        if (!valuesFound.includes(value)) {
          const option = filterOptions.find(
            (option) => option.value.toString() === value
          );
          newChips.push({
            label: 'Status',
            field: 'status',
            value,
            displayValue: option!.label,
          });
        }
      });
      return newChips;
    });
  }, []);

  const handleChipDelete = useCallback((deletedGroup: SearchChip) => {
    setChips((prevChips) => {
      return prevChips.filter((group) => {
        return group.label !== deletedGroup.label;
      });
    });
    setValue([]);
  }, []);

  const columnsDis = useMemo(() => {
    const filterers = value.length > 0 ? value.map((item) => item.value) : ['all'];
    return tableColumns(filterers);
  }, [value]);

  const allRates = useMemo(() => {
    let allRates = rates || [];
    if (value.length > 0) {
      allRates = allRates.filter((rate: IRate) => {
        let rateStatus = 'activeRates';
        if (
          rate.archived &&
          new Date(rate.active_on).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)
        ) {
          rateStatus = 'archivedRates';
        } else if (
          new Date(rate.active_on).setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0)
        ) {
          rateStatus = 'pendingRates';
        } else if (
          new Date(rate.active_on).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)
        ) {
          rateStatus = 'activeRates';
        }
        return value.map((item) => item.value).includes(rateStatus);
      });
    }
    return allRates;
  }, [rates, value]);

  return (
    <Card>
      <PaginationTable
        showFilter
        padding={{ pt: 1, pl: 0, pr: 1 }}
        chips={chips}
        handleChipDelete={handleChipDelete}
        exportData={exportData}
        csvFileName={'Rates.csv'}
        onExport={() => setExportData(false)}
        buttons={[
          {
            label: 'Export',
            startIcon: <FileDownloadIcon />,
            onClick: () => setExportData(true),
          },
          {
            label: 'Add',
            startIcon: <AddIcon />,
            disabled: !getAccess(),
            onClick: () => history.push('/billing/rates/add'),
          },
        ]}
        spaceFilters={
          <>
            <RowItem label={'Status'}>
              <Autocomplete
                multiple
                isOptionEqualToValue={(option, value) => {
                  return option.value === value.value;
                }}
                value={value}
                onChange={(event, newValue) => {
                  setValue([
                    ...newValue.filter((option) =>
                      filterOptions.some((item) => item.label === option.label)
                    ),
                  ]);
                  handleStatusChange([
                    ...newValue
                      .filter((option) =>
                        filterOptions.some((item) => item.label === option.label)
                      )
                      .map((item) => item.value.toString()),
                  ]);
                }}
                options={filterOptions}
                getOptionLabel={(option) => option.label}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      label={option.label}
                      {...getTagProps({ index })}
                      disabled={false}
                    />
                  ))
                }
                style={{ width: 340 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      '& .MuiInputBase-root': {
                        padding: 0,
                        paddingLeft: '1.5rem',
                        paddingRight: '1.5rem',
                      },
                      '& .MuiInputLabel-root': {
                        display: 'none',
                      },
                    }}
                  />
                )}
              />
            </RowItem>
          </>
        }
        onRowCLick={(id: string) => history.push(`/billing/rates/${id}`)}
        sortBy={sortData}
        onSortChange={(sort) => setSortData(sort.key)}
        columns={columnsDis as IColumn[]}
        items={allRates || []}
        searchPlaceholder={`Search rates`}
        page={pageNumberV2}
        rowsPerPage={sizePerPageV2}
        onPageChange={(page, newPage) => {
          setPageNumberV2(newPage);
        }}
        onRowsPerPageChange={(pageSizeString) => {
          const pageSize = Number(pageSizeString.target.value);
          setSizePerPageV2(pageSize);
        }}
        handleSearch={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearch(e.target.value)
        }
        searchText={search}
      />
    </Card>
  );
};

export default connect(
  (state: {
    admin: {
      systemAccess: number;
      admin: {
        admin: {
          movaticAccess: number;
        };
      };
    };
    system: {
      isLoaded: boolean;
    };
  }) => ({
    movaticAccess: state.admin.admin.admin.movaticAccess,
    systemAccess: state.admin.systemAccess,
    systemLoaded: state.system.isLoaded,
  }),
  () => ({})
)(RatesV2);
