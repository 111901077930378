import { useQuery } from '@tanstack/react-query';
import { getMaintenanceTickets } from '../../../api/maintenance';
import { getCredentials } from '../../../api/http';

const useMaintenance = (options?: {}, parameters?: any) => {
  const { systemId } = getCredentials();
  return useQuery(
    ['useMaintenance', parameters, systemId],
    () => getMaintenanceTickets(parameters),
    {
      ...options,
    }
  );
};

export default useMaintenance;
