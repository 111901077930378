import React, { useEffect } from 'react';
import { monthArray } from '../../../constants';
import Stack from '@mui/material/Stack';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface IControlProps {
  timePeriod: string;
  currentMonth: number;
  yearArray: number[];
  fromBilling?: boolean;
  updateTimePeriod: (
    key: string,
    value: string | number,
    updateState: boolean,
    obj: {}
  ) => void;
}

const Control: React.FC<IControlProps> = (props) => {
  const { timePeriod, yearArray, currentMonth, fromBilling = false } = props;
  const [actual, setActual] = React.useState(timePeriod);
  const [actualYear, setActualYear] = React.useState(new Date().getFullYear());
  const [yearArrayLimit, setYearArrayLimit] = React.useState<number[]>([
    new Date().getFullYear(),
  ]);

  useEffect(() => {
    setYearArrayLimit(yearArray?.length === 0 ? [new Date().getFullYear()] : yearArray);
  }, [yearArray]);

  useEffect(() => {
    const { updateTimePeriod } = props;
    const idxYear = yearArrayLimit.indexOf(actualYear);
    updateTimePeriod('currentYear', `${idxYear}`, false, {
      currentYear: `${idxYear}`,
      currentMonth: currentMonth,
      key: fromBilling ? 'timePeriod' : 'currentYear',
      timePeriod: fromBilling ? 'month' : actual,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    props.updateTimePeriod('timePeriod', value, true, {
      currentYear: yearArrayLimit.indexOf(actualYear),
      currentMonth: currentMonth,
      key: 'timePeriod',
      timePeriod: value,
    });
    setActual(value);
  };
  return (
    <>
      <Stack direction={'row'} spacing={0.5}>
        <Stack>
          <Select
            sx={{
              maxHeight: '53.13px',
            }}
            size={'medium'}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={actual}
            onChange={handleChange}
          >
            <MenuItem value={'month'}>Monthly</MenuItem>
            <MenuItem value={'year'}>Yearly</MenuItem>
          </Select>
        </Stack>
        {actual === 'month' ? (
          <Stack>
            <Stack direction={'row'} spacing={0.5}>
              <DatePicker
                views={['year', 'month']}
                value={new Date(actualYear, currentMonth, 1)}
                onChange={(newValue) => {
                  const month = newValue?.getMonth();
                  const year = newValue?.getFullYear();
                  if (month !== undefined && year !== undefined) {
                    const idx = monthArray.indexOf(monthArray[month]);
                    const idxYear = yearArrayLimit.indexOf(year);
                    props.updateTimePeriod('currentMonth', idx, true, {
                      currentYear: idxYear,
                      currentMonth: idx,
                      key: 'currentMonth',
                      timePeriod: actual,
                    });
                    //props.updateTimePeriod('currentYear', `${idxYear}`, true);
                    setActualYear(year);
                  }
                }}
                minDate={new Date(yearArrayLimit[yearArrayLimit.length - 1], 0, 1)}
                maxDate={new Date(yearArrayLimit[0], 11, 31)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    variant: 'outlined',
                  },
                }}
              />
            </Stack>
          </Stack>
        ) : (
          <Stack>
            <DatePicker
              views={['year']}
              value={new Date(actualYear, 0, 1)}
              onChange={(newValue) => {
                const year = newValue?.getFullYear();
                const idx = yearArrayLimit.indexOf(year as number);
                if (year !== undefined) {
                  props.updateTimePeriod('currentYear', `${idx}`, true, {
                    currentYear: idx,
                    currentMonth: currentMonth,
                    key: 'currentYear',
                    timePeriod: actual,
                  });
                  setActualYear(year);
                }
              }}
              minDate={new Date(yearArrayLimit[yearArrayLimit.length - 1], 0, 1)}
              maxDate={new Date(yearArrayLimit[0], 11, 31)}
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: 'outlined',
                },
              }}
            />
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default Control;
