import { object, date, number, string, boolean } from 'yup';

import { emptyStringToNull, dollarsToCents } from '../../../helpers/yupTransforms';
import { addDaysToDate } from '../../../helpers/dateHelpers';

const feeSchema = object()
  .noUnknown(false)
  .shape({
    active_on: date()
      .required('Cannot be empty.')
      .when('$active_on', (active_on, schema) => {
        if (typeof active_on === 'undefined') return schema;

        const yesterday = addDaysToDate(new Date(), -1);
        const activeOn = active_on ? new Date(active_on) : yesterday;
        const earliestDate = activeOn < yesterday ? activeOn : yesterday;

        return schema.min(
          earliestDate,
          `Cannot be in the past and earlier than the current active date.`
        );
      })
      .nullable()
      .transform(emptyStringToNull)
      .default(() => new Date().toISOString()),
    archived_on: date()
      .when('active_on', (active_on, schema) =>
        schema.min(new Date(active_on), 'Must be after the active date.')
      )
      .nullable()
      .transform(emptyStringToNull)
      .default(null),
    amount: number()
      .moreThan(0, 'Must be greater than $0.')
      .lessThan(100000, 'Must be less than $1,000.')
      .nullable()
      .required('Cannot be empty.')
      .transform(emptyStringToNull)
      .transform(dollarsToCents)
      .default(null),
    applied_above_sub_total_threshold: number()
      .moreThan(0, 'Must be greater than 0.')
      .lessThan(100000, 'Must be less than $1,000.')
      .nullable()
      .transform(emptyStringToNull)
      .transform(dollarsToCents)
      .default(null),
    applied_under_sub_total_threshold: number()
      .moreThan(0, 'Must be greater than 0.')
      .lessThan(100000, 'Must be less than $1,000.')
      .nullable()
      .transform(emptyStringToNull)
      .transform(dollarsToCents)
      .default(null),
    applied_post_tax: boolean().default(false),
    applied_to_members: boolean().default(false),
    description: string().nullable().transform(emptyStringToNull).default(''),
    name: string().nullable().required('Cannot be empty.').default(''),
  });

export default feeSchema;
