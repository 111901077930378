import React, { useEffect, useState } from 'react';
import useStripeAccountInfo from '../../../hooks/system/query/useStripeAccountInfo';
import useBankInfo from '../../../hooks/system/query/useBankInfo';
import {
  DEV_HOSTNAME,
  LOCAL_HOSTNAME,
  STRIPE_LIVE_KEY,
  STRIPE_MAX_REQUIRED_INFORMATION_MISSING,
  STRIPE_REQUIRED_INFORMATION_TYPE,
  STRIPE_TEST_KEY,
} from '../../../constants';
import { loadStripe } from '@stripe/stripe-js';
import { updateStripeBankInfo } from '../../../api/system';
import { ICardBankAccount } from '../../../ts/interface/pages/settings.interface';
import { IStripeRequirements } from '../../../ts/interface/system.interface';
import Typography from '@mui/material/Typography';
import FormItem from '../../../components/Redesing/form-item';
import { TextField } from '@mui/material';
import CardView from '../../../components/Redesing/card-view';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { Stack } from '@mui/system';
import CustomToast from '../../../components/Redesing/custom-toast';

const CardBankAccount = ({
  bankAccess,
  movaticAccess,
  systemLoaded,
  detailedSystem,
}: ICardBankAccount) => {
  const [edit, setEdit] = useState(false);
  const [accountNumber, setAccountNumber] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [bankName, setBankName] = useState('');

  const { data: stripeAccountInfo, isLoading: isStripeAccountInfoLoading } =
    useStripeAccountInfo(null, {
      enabled: systemLoaded,
    });
  const {
    data: bankInfo,
    isLoading: isBankInfoLoading,
    isFetched: isBankInfoFetched,
    refetch: refetchBankInfo,
  } = useBankInfo({
    enabled: bankAccess && systemLoaded,
  });

  useEffect(() => {
    if (isBankInfoFetched) {
      setRoutingNumber(bankInfo?.routingNumber || '');
      setBankName(bankInfo?.bankName || '');
    }
  }, [bankInfo, isBankInfoFetched]);

  const handleEdit = () => setEdit(!edit);

  const button = () => {
    if (stripeAccountInfo) {
      const { id, requirements } = stripeAccountInfo;
      if (!id || (systemLoaded && missingStripeInfo(requirements))) {
        return null;
      }

      return edit ? (
        <>
          <IconButtonMenu
            buttons={[
              {
                label: 'Cancel',
                onClick: handleEdit,
                disabled: !systemLoaded || (!bankAccess && !movaticAccess),
              },
              {
                label: 'Save',
                startIcon: <SaveIcon />,
                onClick: submit,
                disabled: !systemLoaded || (!bankAccess && !movaticAccess),
              },
            ]}
          />
        </>
      ) : (
        <>
          <IconButtonMenu
            buttons={[
              {
                label: 'Edit',
                onClick: handleEdit,
                disabled: !systemLoaded || (!bankAccess && !movaticAccess),
                startIcon: <EditIcon />,
              },
            ]}
          />
        </>
      );
    } else {
      return null;
    }
  };

  const makeToken = async () => {
    const testMode =
      window.location.hostname === LOCAL_HOSTNAME ||
      window.location.hostname === DEV_HOSTNAME;
    const stripeKey =
      !detailedSystem.billingLive || testMode ? STRIPE_TEST_KEY : STRIPE_LIVE_KEY;
    const stripe = await loadStripe(stripeKey);

    // @ts-ignore
    return stripe.createToken('bank_account', {
      country: detailedSystem.country,
      currency: detailedSystem.currencyCode,
      // comment these next two in for the real deal
      routing_number: routingNumber,
      account_number: accountNumber,
      // these next two are for testing they are stripe's test values
      // routing_number: STRIPE_ROUTING_NUMBER_TEST,
      // account_number: STRIPE_ACCOUNT_NUMBER_TEST
    });
  };

  const submit = () => {
    if (!accountNumber) {
      CustomToast({
        type: 'error',
        message: 'Account number must be filled in to submit.',
      });
      return;
    }
    makeToken()
      .then(async (results) => {
        if (results.error) {
          CustomToast({
            type: 'error',
            message: results.error.message,
          });
        } else {
          const data = { bankId: results.token.id };
          updateStripeBankInfo(data).then(() => {
            refetchBankInfo();
          });
        }
      })
      .catch((error) => {
        const { message } = error;
        CustomToast({
          type: 'error',
          message: message,
        });
      });
  };

  const missingStripeInfo = (requirements: IStripeRequirements) => {
    return (
      requirements.currently_due.length > STRIPE_MAX_REQUIRED_INFORMATION_MISSING ||
      (requirements.currently_due.length === STRIPE_MAX_REQUIRED_INFORMATION_MISSING &&
        requirements.currently_due[0] !== STRIPE_REQUIRED_INFORMATION_TYPE)
    );
  };

  const content = () => {
    if (stripeAccountInfo) {
      const { id, requirements } = stripeAccountInfo;
      // verification info required
      if (!id || (systemLoaded && missingStripeInfo(requirements))) {
        return (
          <Typography variant="body2" sx={{ mt: 1 }}>
            Please verify all your account information first. Only then will you be able
            to enter your banking information.
          </Typography>
        );
      }
      if (edit) {
        return (
          <div>
            {/* BANK NAME */}
            <FormItem
              label="Bank Name"
              description={"Your bank's name"}
              content={
                <TextField
                  id="bankName"
                  name="bankName"
                  variant="outlined"
                  fullWidth
                  value={bankName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setBankName(event.target.value)
                  }
                />
              }
            />

            <FormItem
              label="Account Number"
              description="The account where you would like deposits to be made"
              content={
                <TextField
                  id="accountNumber"
                  name="accountNumber"
                  variant="outlined"
                  fullWidth
                  value={accountNumber || ''}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setAccountNumber(event.target.value)
                  }
                />
              }
            />

            <FormItem
              label="Routing Number"
              description={"Your bank's routing number"}
              content={
                <TextField
                  id="routingNumber"
                  name="routingNumber"
                  variant="outlined"
                  fullWidth
                  value={routingNumber || ''}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setRoutingNumber(event.target.value)
                  }
                />
              }
            />
          </div>
        );
      }

      // display bank info
      return (
        <div>
          <FormItem
            label={'Bank Name:'}
            content={`${bankInfo?.bankName || 'Please Add'}`}
          />
          <FormItem
            label="Last 4 from account:"
            content={`${bankInfo?.accountLast4 || 'Please Add'}`}
          />
          <FormItem
            label="Routing number:"
            content={`${bankInfo?.routingNumber || 'Please Add'}`}
          />
        </div>
      );
    }
    return (
      <Typography variant="body2" sx={{ mt: 1 }}>
        Please verify all your account information first. Only then will you be able to
        enter your banking information.
      </Typography>
    );
  };
  return (
    <CardView
      isLoading={isStripeAccountInfoLoading || isBankInfoLoading}
      title={edit ? 'Edit Bank Account' : 'Bank Account'}
      content={
        <Stack px={2} pb={2}>
          {content()}
        </Stack>
      }
      headerActions={button()}
    />
  );
};

export default CardBankAccount;
