import { object, string } from 'yup';

import { emptyStringToNull } from '../../../helpers/yupTransforms';

const rentalSchema = object()
  .noUnknown(false)
  .shape({
    user_id: string().nullable().required('Cannot be empty.').default(null),
    hardware_id: string()
      .nullable()
      .required('Must select a valid hardware.')
      .default(null),
    rate_id: string().nullable().transform(emptyStringToNull).default(null),
    coupon_id: string().nullable().transform(emptyStringToNull).default(null),
    perk_id: string().nullable().transform(emptyStringToNull).default(null),
  });

export default rentalSchema;
