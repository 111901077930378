import React, { useState } from 'react';
import useDisputes from '../../hooks/transaction/query/useDisputes';
import { history } from '../../utils';
import { disputeColumns } from '../../tableColumns/disputeColumns';
import { connect } from 'react-redux';
import { PaginationTable } from '../../components/Redesing/table/pagination-table';
import Card from '@mui/material/Card';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants';

const DisputeTable = ({ systemLoaded }: { systemLoaded: boolean }) => {
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_ROWS_PER_PAGE);
  const [sortData, setSortData] = useState<string>('created');
  const [search, setSearch] = React.useState('');
  const [exportData, setExportData] = useState<boolean>(false);

  const { data } = useDisputes({
    enabled: systemLoaded,
  });
  return (
    <Card>
      <PaginationTable
        dataId={'billing-disputes'}
        padding={{ pt: 1, pl: 0, pr: 1 }}
        exportData={exportData}
        csvFileName={'Disputes.csv'}
        onExport={() => setExportData(false)}
        buttons={[
          {
            label: 'Export',
            startIcon: <FileDownloadIcon />,
            onClick: () => setExportData(true),
          },
        ]}
        sortBy={sortData}
        onSortChange={(sortBy) => setSortData(sortBy.key)}
        items={data}
        noDataText={`Great Job! No Disputes`}
        onRowCLick={(id: string) => history.push(`/billing/disputes/${id}`)}
        columns={disputeColumns}
        rowsPerPage={pageSize}
        page={pageNo}
        onPageChange={(page, pageN: number) => {
          setPageNo(pageN);
        }}
        onRowsPerPageChange={(rowsPerPage) =>
          setPageSize(Number(rowsPerPage.target.value))
        }
        searchText={search}
        searchPlaceholder={`Search cards`}
        handleSearch={(search) => setSearch(search.target.value)}
      />
    </Card>
  );
};

export default connect(
  (state: { system: { isLoaded: boolean } }) => ({
    systemLoaded: state.system.isLoaded,
  }),
  () => ({})
)(DisputeTable);
