import React, { useState, ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { getSystemsAdmins } from '../../../actionCreators/admin';
import { findAccess, history } from '../../../utils';
import { VALID_EMAIL_REGEX } from '../../../util/regExp';
import { ADMIN_PERMISSIONS } from '../../../constants';
import {
  MovaticFieldGroupCheck,
  MovaticFieldGroupText,
} from '../../../components/Redesing/movatic-field-group';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import PageContainer from '../../../components/Redesing/page-container';
import CardView from '../../../components/Redesing/card-view';
import { Stack } from '@mui/system';
import MovaticCustomModal from '../../../components/Modal/MovaticCustomModal';
import Typography from '@mui/material/Typography';
import CustomToast from '../../../components/Redesing/custom-toast';
import CustomAsyncToast from '../../../components/Redesing/custom-async-toast';
import { addNewAdmin } from '../../../api/admin';

interface IInitialState {
  firstName: string;
  firstNameError: string | null;
  firstNameHelp: string | null;
  lastName: string;
  lastNameError: string | null;
  lastNameHelp: string | null;
  email: string;
  emailHelp: string | null;
  emailError: string | null;
  access: number;
  error: string | null;
  admin: boolean;
  rental: boolean;
  rates: boolean;
  refunds: boolean;
  physical: boolean;
  bank: boolean;
  users: boolean;
  locations: boolean;
  support: boolean;
  maintenance: boolean;
  memberships: boolean;
  buttonDisabled: boolean;
  pending_user_emails: boolean;
  error_report_emails: boolean;
  adminExists: boolean;
}

const AddAdmin: React.FC<any> = (props) => {
  const [state, setState] = useState<IInitialState>({
    firstName: '',
    firstNameError: null,
    firstNameHelp: null,
    lastName: '',
    lastNameError: null,
    lastNameHelp: null,
    email: '',
    emailHelp: null,
    emailError: null,
    access: 1,
    error: null,
    admin: true,
    rental: true,
    rates: true,
    refunds: true,
    physical: true,
    bank: true,
    users: true,
    locations: true,
    support: true,
    maintenance: true,
    memberships: true,
    buttonDisabled: false,
    pending_user_emails: true,
    error_report_emails: true,
    adminExists: false,
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    if (e.target.name !== 'admin' || !value) {
      setState({
        ...state,
        admin: false,
        [e.target.name]: value,
        [`${e.target.name}Error`]: null,
      });
    } else if (e.target.name === 'admin' && value) {
      setState({
        ...state,
        admin: true,
        rental: true,
        rates: true,
        bank: true,
        refunds: true,
        physical: true,
        users: true,
        locations: true,
        support: true,
        maintenance: true,
        memberships: true,
        [`${e.target.name}Error`]: null,
      });
    }
  };

  const canSubmit = () => {
    if (state.firstName.length <= 0) {
      setState({
        ...state,
        firstNameHelp: 'First name is required',
        firstNameError: 'error',
      });
      return false;
    }
    setState({ ...state, firstNameHelp: null, firstNameError: null });

    if (state.lastName.length <= 0) {
      setState({
        ...state,
        lastNameHelp: 'Last name is required',
        lastNameError: 'error',
      });
      return false;
    }
    setState({ ...state, lastNameHelp: null, lastNameError: null });

    if (state.email.length <= 0) {
      setState({ ...state, emailHelp: 'Email is required', emailError: 'error' });
      return false;
    }
    setState({ ...state, emailHelp: null, emailError: null });

    // checking if email address is valid
    if (!VALID_EMAIL_REGEX.test(state.email)) {
      setState({
        ...state,
        emailHelp: 'Enter a valid email address',
        emailError: 'error',
      });
      return false;
    }
    setState({ ...state, emailHelp: null, emailError: null });

    return true;
  };

  const save = () => {
    if (canSubmit()) {
      setState({
        ...state,
        buttonDisabled: true,
      });
      let access = 1;
      if (state.admin) access += ADMIN_PERMISSIONS['admin'];
      if (state.physical) access += ADMIN_PERMISSIONS['physical'];
      if (state.rates) access += ADMIN_PERMISSIONS['rates'];
      if (state.refunds) access += ADMIN_PERMISSIONS['refunds'];
      if (state.bank) access += ADMIN_PERMISSIONS['bank'];
      if (state.rental) access += ADMIN_PERMISSIONS['rental'];
      if (state.users) access += ADMIN_PERMISSIONS['users'];
      if (state.locations) access += ADMIN_PERMISSIONS['locations'];
      if (state.support) access += ADMIN_PERMISSIONS['support'];
      if (state.maintenance) access += ADMIN_PERMISSIONS['maintenance'];
      if (state.memberships) access += ADMIN_PERMISSIONS['memberships'];

      const adminObj = {
        firstName: state.firstName,
        lastName: state.lastName,
        email: state.email.toLowerCase().trim(),
        access,
        pending_user_emails: state.pending_user_emails,
        error_report_emails: state.error_report_emails,
      };

      CustomAsyncToast({
        promise: () => addNewAdmin(adminObj),
        successMessage: () => 'Admin was added',
        loadingMessage: 'Adding admin...',
        errorMessage: 'There was an issue while adding this admin, please try again',
      }).then((r) => {
        if (r) {
          props.repullAdmins(props.auth, props.systemId);
          history.push(`/settings/admin/${state.email.toLowerCase().trim()}`);
        }
      });
    } else {
      CustomToast({
        type: 'error',
        message: 'There was an error, please check your form and try again',
      });
    }
  };

  const content = () => {
    const {
      firstName,
      lastName,
      email,
      admin,
      rental,
      rates,
      refunds,
      bank,
      physical,
      users,
      locations,
      support,
      maintenance,
      memberships,
      pending_user_emails,
      error_report_emails,
      firstNameHelp,
      lastNameHelp,
      emailHelp,
      firstNameError,
      lastNameError,
      emailError,
    } = state;
    return (
      <div>
        <Typography variant="h6">Admin Information</Typography>
        <MovaticFieldGroupText
          id="firstName"
          name="firstName"
          label="First Name"
          description="Admin's first name"
          hasError={firstNameError === 'error'}
          errorMessage={firstNameHelp}
          value={firstName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setState({ ...state, firstName: e.target.value });
          }}
        />

        <MovaticFieldGroupText
          id="lastName"
          name="lastName"
          label="Last Name"
          description="Admin's last name"
          hasError={lastNameError === 'error'}
          errorMessage={lastNameHelp}
          value={lastName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setState({ ...state, lastName: e.target.value });
          }}
        />

        <MovaticFieldGroupText
          id="email"
          name="email"
          label="Email"
          description="Admin's email address"
          hasError={emailError === 'error'}
          errorMessage={emailHelp}
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setState({ ...state, email: e.target.value });
          }}
        />
        <Typography variant="h6">Admin Permissions</Typography>

        <MovaticFieldGroupCheck
          label="Admin"
          description="Create other admin users (if this option is selected then admin has all permissions)"
          id="admin"
          name="admin"
          onChange={handleChange}
          checked={admin}
        />

        <MovaticFieldGroupCheck
          label="Users"
          description="Permission to manage users"
          id="users"
          name="users"
          onChange={handleChange}
          checked={users}
        />

        <MovaticFieldGroupCheck
          label="Rentals"
          description="Permission to end rentals and modify rental settings"
          id="rental"
          name="rental"
          onChange={handleChange}
          checked={rental}
        />

        <MovaticFieldGroupCheck
          label="Hardware"
          description="Permission to create or edit hardware"
          id="physical"
          name="physical"
          onChange={handleChange}
          checked={physical}
        />

        <MovaticFieldGroupCheck
          label="Locations"
          description="Permission to create or edit locations"
          id="locations"
          name="locations"
          onChange={handleChange}
          checked={locations}
        />

        <MovaticFieldGroupCheck
          label="Membership"
          description="Permission to manage user memberships"
          id="memberships"
          name="memberships"
          onChange={handleChange}
          checked={memberships}
        />

        <MovaticFieldGroupCheck
          label="Support Tickets"
          description="Permission to manage support tickets"
          id="support"
          name="support"
          onChange={handleChange}
          checked={support}
        />

        <MovaticFieldGroupCheck
          label="Maintenance"
          description="Permission to manage hardware tickets and logs"
          id="maintenance"
          name="maintenance"
          onChange={handleChange}
          checked={maintenance}
        />

        <MovaticFieldGroupCheck
          label="Rates"
          description="Permission to create, archive, or edit rates"
          id="rates"
          name="rates"
          onChange={handleChange}
          checked={rates}
        />

        <MovaticFieldGroupCheck
          label="Refunds"
          description="Permission to issue refunds to users"
          id="refunds"
          name="refunds"
          onChange={handleChange}
          checked={refunds}
        />

        <MovaticFieldGroupCheck
          label="Settings"
          description="Permission to edit and view settings"
          id="bank"
          name="bank"
          onChange={handleChange}
          checked={bank}
        />

        <Typography variant="h6">Subscribe to Email Notifications</Typography>

        <MovaticFieldGroupCheck
          label="Pending Members"
          description="Recieve notifications for new pending members"
          id="bank"
          name="bank"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setState({ ...state, pending_user_emails: e.target.checked })
          }
          checked={pending_user_emails}
        />
        <MovaticFieldGroupCheck
          label="Support Tickets Reports"
          description="Receive notifications for new support tickets reports submitted by users"
          id="bank"
          name="bank"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setState({ ...state, error_report_emails: e.target.checked })
          }
          checked={error_report_emails}
        />
      </div>
    );
  };

  const button = () => {
    return (
      <IconButtonMenu
        buttons={[
          {
            label: 'Cancel',
            onClick: () => history.push('/settings/admin'),
            startIcon: <CloseIcon />,
          },
          {
            label: 'Save',
            onClick: save,
            startIcon: <SaveIcon />,
            disabled:
              !findAccess(props.systemAccess).includes('admin') && !props.movaticAccess,
          },
        ]}
      />
    );
  };

  return (
    <PageContainer isLoading={!props.systemLoaded}>
      <CardView
        content={
          <Stack paddingX={2} paddingBottom={2}>
            {content()}
          </Stack>
        }
        title="Create Admin"
        headerActions={button()}
      />
      <MovaticCustomModal
        open={state.adminExists}
        onClose={() => setState({ ...state, adminExists: false })}
        content="An admin with this email address already exists. They have been added to this system with their existing first and last name."
        title="Admin Exists"
        action={
          <IconButtonMenu
            buttons={[
              {
                label: 'Continue',
                onClick: () => setState({ ...state, adminExists: false }),
                startIcon: <SaveIcon />,
              },
            ]}
          />
        }
      />
    </PageContainer>
  );
};

export default connect(
  (state: any) => ({
    privateSystem: state.system.current.privateSystem,
    auth: state.admin.admin,
    systemId: state.system.current.id,
    movaticAccess: state.admin.admin.admin.movaticAccess,
    systemAccess: state.admin.systemAccess,
    systemLoaded: state.system.isLoaded,
  }),
  (dispatch: Function) => ({
    repullAdmins: (authInfo: {}, systemId: string) => {
      dispatch(getSystemsAdmins());
    },
  })
)(AddAdmin);
