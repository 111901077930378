import React from 'react';
import { connect } from 'react-redux';
import useDisputes from '../../hooks/transaction/query/useDisputes';
import { formatPhoneNumber, getLocaleDateTimeString, history } from '../../utils';
import { useParams } from 'react-router-dom';
import { IDispute } from '../../ts/interface/transaction.interface';
import PageContainer from '../../components/Redesing/page-container';
import CardView from '../../components/Redesing/card-view';
import { Stack } from '@mui/system';
import { PropertyList } from '../../components/Redesing/property-list';
import PropertyListItemLink from '../../components/Redesing/property-list-item-link';
import { PropertyListItem } from '../../components/Redesing/property-list-item';

interface IDisputeIndo {
  message: string;
  item: string;
  dataId: string;
  clickFunc?: () => void;
}

const DisputeDetails = ({ systemLoaded }: { systemLoaded: boolean }) => {
  const { disputeId } = useParams<{ disputeId: string }>();
  const { data = [], isLoading } = useDisputes({
    enabled: systemLoaded,
  });
  const makeInfo = () => {
    let dispute = {} as IDispute;
    dispute = data.find(
      (eachArrayElement: IDispute) =>
        eachArrayElement.id === disputeId || eachArrayElement.gid === disputeId
    );
    return [
      {
        // Dispute id
        message: 'Dispute ID',
        item: dispute.id,
        dataId: 'dispute-id',
      },
      {
        // charge id
        message: 'Charge ID',
        item: dispute.charge_id,
        dataId: 'charge-id',
      },
      {
        // Fee
        message: 'Dispute Fee',
        item: dispute.fee,
        dataId: 'dispute-fee',
      },
      {
        // Original Amount
        message: 'Original Charge Amount',
        item: dispute.amount,
        dataId: 'dispute-amount',
      },
      {
        // Net
        message: 'Net',
        item: dispute.net,
        dataId: 'dispute-net',
      },
      {
        // Users name
        message: 'User',
        clickFunc: () => history.push(`/users/${dispute.phone}/general`),
        item: dispute.userName,
        dataId: 'dispute-username',
      },
      {
        // user phone number
        message: 'Phone number',
        item: formatPhoneNumber(dispute.phone),
        dataId: 'dispute-phone',
      },
      {
        // email
        message: 'Email address',
        item: dispute.email,
        dataId: 'dispute-email',
      },
      {
        // dispute date
        message: 'Dispute date',
        item: getLocaleDateTimeString(dispute.created),
        dataId: 'dispute-date',
      },
      {
        // dispute status
        message: 'Current status',
        item: dispute.status,
        dataId: 'dispute-status',
      },
      ...(dispute.evidence_duedate
        ? [
            {
              // Respond By
              message: 'Respond By',
              item: getLocaleDateTimeString(dispute.evidence_duedate),
              dataId: 'dispute-evidence',
            },
          ]
        : []),
      {
        // dispute reason
        message: 'Reason',
        item: dispute.reason,
        dataId: 'dispute-reason',
      },
    ];
  };

  const warning = (detailedInformation: IDisputeIndo[]) => {
    return detailedInformation[9].item === 'Needs Response' ||
      detailedInformation[9].item === 'Under Review' ||
      detailedInformation[9].item === 'Warning Under Review' ||
      detailedInformation[9].item === 'Warning Needs Response'
      ? 'Please contact user right away to find out why they have disputed this charge, then contact Movatic at support@movatic.co'
      : null;
  };

  const content = (detailedInformation: IDisputeIndo[]) => {
    const children = detailedInformation.map((element, idx) => {
      return (
        <>
          {element.clickFunc ? (
            <PropertyListItemLink
              label={element.message}
              onClick={element.clickFunc}
              key={idx}
              dataId={element.dataId}
              text={element.item}
            />
          ) : (
            <PropertyListItem
              label={element.message}
              key={idx}
              dataId={element.dataId}
              value={element.item}
            />
          )}
        </>
      );
    });
    return (
      <Stack>
        <PropertyList>{children}</PropertyList>
      </Stack>
    );
  };

  const renderContent = () => {
    if (!data.length) {
      return null;
    }

    const detailedInformation = makeInfo();

    return detailedInformation[1].item === '' ? (
      history.push('/problems/disputes')
    ) : (
      <>
        <PageContainer
          isLoading={!systemLoaded && isLoading}
          warningMessage={warning(detailedInformation)}
        >
          <CardView
            isLoading={!systemLoaded && isLoading}
            headerActions={null}
            content={content(detailedInformation)}
            title={'Dispute Details'}
            dataId={'dispute-details'}
          />
        </PageContainer>
      </>
    );
  };

  return <>{renderContent()}</>;
};

export default connect(
  (state: { system: { isLoaded: boolean } }) => ({
    systemLoaded: state.system.isLoaded,
  }),
  () => ({})
)(DisputeDetails);
