import React from 'react';
import Card from '@mui/material/Card';
import PageLoader from './page-loader';

interface ICardLoader {
  useCard?: boolean;
  dataId?: string;
}

const CardLoader: React.FC<ICardLoader> = (props) => {
  const { useCard = false, dataId = 'card-loader' } = props;
  return (
    <>
      {useCard ? (
        <Card>
          <PageLoader minHeight={'10vh'} dataId={dataId} />
        </Card>
      ) : (
        <PageLoader minHeight={'10vh'} />
      )}
    </>
  );
};

export default CardLoader;
