import toast from 'react-hot-toast';

interface CustomToastProps {
  message?: any;
  type: 'success' | 'error' | 'loading';
  duration?: number;
}

//@ts-ignore
const CustomToast = ({ type, message, duration = 2000 }: CustomToastProps) => {
  const toastStyle = {
    borderRadius: '10px',
    background: '#333',
    color: '#fff',
  };
  const options = { style: toastStyle, duration: duration };
  switch (type) {
    case 'success':
      return toast.success(message);
    case 'error':
      return toast.error(message, options);
    case 'loading':
      return toast.loading(message, options);
    default:
      return toast.success(message, options);
  }
};

export default CustomToast;
