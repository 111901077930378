import {
  SET_DISPUTE_DETAILS,
  API_DISPUTES_SUCCESS,
  DISPUTES_LOADED,
} from '../constants.js';

const initalState = {
  disputeIsLoaded: false,
  detailedDisputeLoaded: false,
  disputeDetailed: {
    amount: null,
    charge_id: '',
    created: '',
    evidence_duedate: '',
    id: '',
    reason: '',
    status: '',
    userPhone: null,
    fee: null,
    net: null,
  },
  disputes: [],
};

export default function disputesReducer (state = initalState, action) {
  const newState = { ...state };

  switch (action.type) {
    case API_DISPUTES_SUCCESS:
      newState.disputes = action.disputes;
      newState.disputeIsLoaded = true;
      break;

    case SET_DISPUTE_DETAILS:
      newState.disputeDetailed = action.disputeDetails;
      newState.detailedDisputeLoaded = true;
      break;

    case DISPUTES_LOADED:
      newState.disputeIsLoaded = action.loaded;
      break;

    default:
      return state;
  }

  return newState;
}
