import { history, STATE_OPTIONS, stateFormatter, toTitleCase } from '../utils.jsx';
import { LinearProgress } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import React from 'react';
import { SeverityPill } from '../components/Redesing/severity-pill';
import { Stack } from '@mui/system';
import IconButtonMenu from '../components/Redesing/icon-button-menu';
import ColumnAvatar from '../components/Redesing/column-avatar';
import Typography from '@mui/material/Typography';
import { getHardwareIconByName, getLocationIconByName } from '../util/utilsR';
import StatePills from '../components/Redesing/state-pills';

export function makeColumnsV2(system) {
  return [
    {
      id: 'name',
      label: 'Location Name',
      format: (cell) => {
        const locationConfig = getLocationIconByName(
          cell?.configuration ? cell.configuration : cell.name ? cell.name : 'N/A'
        );
        return (
          <ColumnAvatar
            avatar={locationConfig.icon}
            avatarColor={locationConfig.bgColor}
            firstRow={
              <Typography
                color="text.primary"
                variant="body1"
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {cell.name ? cell.name : 'N/A'}
              </Typography>
            }
            secondRow={
              <Typography color="text.secondary" variant="body2">
                <SeverityPill color={'info'}>{cell?.configuration || 'N/A'}</SeverityPill>
              </Typography>
            }
          />
        );
      },
      searchable: true,
      shouldExport: false,
      notSortable: true,
      shouldNotExport: false,
    },
    {
      id: 'configuration',
      label: 'Type',
      align: 'left',
      notSortable: true,
      format: (row) => (
        <SeverityPill color={'info'}>{toTitleCase(row.configuration)}</SeverityPill>
      ),
      searchable: false,
      hidden: true,
      shouldExport: true,
      shouldNotExport: false,
    },
    {
      id: 'actions',
      label: 'Actions',
      disableCLick: true,
      notSortable: true,
      format: (row) => {
        return (
          <Stack justifyContent={'flex-start'} alignItems={'flex-start'}>
            <IconButtonMenu
              buttons={[
                {
                  label: '',
                  startIcon: <EditIcon />,
                  onClick: (e) => {
                    e.stopPropagation();
                    history.push(`/locations/${row.id}/edit`);
                  },
                },
              ]}
            />
          </Stack>
        );
      },
      searchable: false,
      hidden: false,
      shouldExport: false,
      shouldNotExport: false,
    },
    {
      id: 'zone_type',
      label: 'Zone Type',
      align: 'left',
      notSortable: true,
      format: (row) => (
        <SeverityPill color={'info'}>
          {row.zone_type ? toTitleCase(row.zone_type) : 'N/A'}
        </SeverityPill>
      ),
      searchable: false,
      shouldExport: true,
    },
    {
      id: 'id',
      label: 'ID',
      align: 'left',
      searchable: false,
      hidden: true,
      shouldExport: true,
      shouldNotExport: false,
    },
    {
      id: 'old_id',
      label: 'ID',
      searchable: false,
      hidden: true,
      shouldExport: false,
      shouldNotExport: false,
    },
    {
      id: 'available',
      label: 'Availability',
      align: 'left',
      format: (row) =>
        row.configuration === 'store' ? (
          <SeverityPill color={'info'}>N/A</SeverityPill>
        ) : row.available ? (
          <SeverityPill color={row.available > 0 ? 'success' : 'warning'}>
            {row.available}
          </SeverityPill>
        ) : (
          <SeverityPill color={'warning'}>0</SeverityPill>
        ),
      searchable: false,
      hidden: false,
      notSortable: true,
      shouldExport: false,
      shouldNotExport: false,
    },
    {
      id: 'state',
      label: 'State',
      format: (row) => (
        <SeverityPill color={row.state === 1 ? 'success' : 'error'}>
          {stateFormatter(row.state).name}
        </SeverityPill>
      ),
      searchable: false,
      hidden: false,
      shouldExport: false,
      shouldNotExport: false,
      notSortable: true,
    },
    {
      id: 'lat',
      label: 'Latitude',
      align: 'left',
      format: (cell) =>
        cell ? cell.lat : <SeverityPill color={'info'}>N/A</SeverityPill>,
      searchable: false,
      hidden: true,
      shouldExport: true,
      notSortable: true,
      shouldNotExport: false,
    },
    {
      id: 'lng',
      label: 'Longitude',
      align: 'left',
      format: (cell) =>
        cell ? cell.lng : <SeverityPill color={'info'}>N/A</SeverityPill>,
      searchable: false,
      hidden: true,
      shouldExport: true,
      shouldNotExport: false,
      notSortable: true,
    },
    {
      id: 'capacity',
      label: `# of ${system.mobilityType === 14 ? 'Lockers' : 'Racks'}`,
      align: 'left',
      format: (row) =>
        row.configuration === 'zone' || row.configuration === 'store' ? (
          <SeverityPill color={'info'}>N/A</SeverityPill>
        ) : row.capacity ? (
          <SeverityPill color={row.capacity > 0 ? 'success' : 'warning'}>
            {row.capacity}
          </SeverityPill>
        ) : (
          <SeverityPill color={'success'}>Unlimited</SeverityPill>
        ),
      searchable: false,
      hidden: false,
      shouldExport: false,
      notSortable: true,
      shouldNotExport: false,
    },
  ];
}

export function columnsForHardwareV2(isSmallScreen, handleStateChange) {
  const identifierRowFormat = (row) => {
    const identifierMappings = [
      { property: 'number', name: 'Number' },
      { property: 'label', name: 'Label' },
      { property: 'mac', name: 'Mac' },
      { property: 'vendor_id', name: 'Vendor ID' },
    ];
    const identifierEntry = identifierMappings.find((entry) => row?.[entry.property]);
    const identifier = identifierEntry ? row[identifierEntry.property] : row.id;
    const identifierName = identifierEntry ? identifierEntry.name : 'ID';

    const { Icon, bgColor } = getHardwareIconByName(row.hardware_type_name);
    return (
      <ColumnAvatar
        avatar={<Icon />}
        avatarColor={bgColor}
        firstRow={
          <Typography color="text.pramary" variant="body1">
            {identifier}
          </Typography>
        }
        secondRow={
          <Typography color="text.secondary" variant="body2">
            <SeverityPill color={'info'}>{identifierName}</SeverityPill>
          </Typography>
        }
      />
    );
  };

  let columns = [
    {
      id: 'hardware_type_name',
      label: 'Identifier',
      format: identifierRowFormat,
    },
    {
      id: 'state',
      label: `Status`,
      notSortable: true,
      format: (row) => {
        return (
          <StatePills
            stateOptions={STATE_OPTIONS.filter((item) => item.hardware)}
            row={row}
            handleChangeState={handleStateChange}
          />
        );
      },
    },
    {
      id: 'battery_percent',
      label: 'Battery',
      notSortable: true,
      format: (row) => {
        const cant = row?.battery_percent || row?.battery_voltage;
        const show = !!row?.battery_percent;
        return (
          <>
            {show && (
              <LinearProgress
                value={cant || 0}
                variant="determinate"
                color={cant >= 20 ? (cant > 50 ? 'success' : 'warning') : 'error'}
                sx={{
                  height: 8,
                  width: 36,
                }}
              />
            )}

            <>
              {' '}
              {row?.battery_percent
                ? `${row.battery_percent}%`
                : row.battery_voltage
                ? `${row.battery_voltage} mV`
                : 'Unknown'}
            </>
          </>
        );
      },
    },
    {
      id: 'number',
      hidden: true,
      searchable: true,
    },
    {
      id: 'id',
      hidden: true,
      searchable: true,
    },
    {
      id: 'imei',
      hidden: true,
      searchable: true,
    },
    {
      id: 'vendor_serial',
      hidden: true,
      searchable: true,
    },
    {
      id: 'mac',
      hidden: true,
      searchable: true,
    },
    {
      id: 'label',
      hidden: true,
      searchable: true,
    },
    {
      id: 'vendor_id',
      hidden: true,
      searchable: true,
    },
  ];

  columns.splice(isSmallScreen ? 3 : 1, 0, {
    id: 'actions',
    label: 'Actions',
    width: '25%',
    notSortable: true,
    shouldNotExport: true,
    disableCLick: true,
    format: (row) => {
      const buttons = [
        {
          label: isSmallScreen ? 'Edit' : null,
          startIcon: <EditIcon />,
          onClick: (e) => {
            history.push(`/hardware/${row.hardware_type.name}/${row.old_id}/edit`);
          },
          disabled: false,
          shouldRender: true,
        },
      ];
      return (
        <Stack justifyContent={'flex-start'} alignItems={'flex-start'}>
          <IconButtonMenu
            showEllipsis={isSmallScreen}
            slice={isSmallScreen ? 0 : 2}
            buttons={buttons.filter((item) => item.shouldRender)}
          />
        </Stack>
      );
    },
  });

  return columns;
}
