import { useQuery } from '@tanstack/react-query';
import { baseUrlV2 } from '../../../api/http';
import { getIssuingCardResource } from '../../../api/issuingCard';

const useIssuingCardholder = (cardholderId: string, systemId: string, options?: {}) => {
  return useQuery(
    ['issuingcardholder'],
    () =>
      getIssuingCardResource(
        `${baseUrlV2}/billing/billing/issuing/cardholder/${cardholderId}/${systemId}`,
        false,
        'Oops! Looks like there was an error while getting the issuing cardholder. Please try again later.'
      ),
    { ...options }
  );
};

export default useIssuingCardholder;
