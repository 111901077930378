import { baseUrl, fetchOptions, fetchPostOptions, getCredentials } from './http';
import { FourOhOneError } from '../actionCreators/system';
import { displayError } from '../utils';

export const getDashboardStats = (systemId) => {
  const { adminEmail, adminToken, systemId: actualSystem } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId || actualSystem);

  return fetch(`${baseUrl}/stats/dashboard`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        return response.message;
      }
      FourOhOneError();
    })
    .catch((e) => {
      displayError(
        'Oops! Looks like there was an error while loading the dashboard stats. Please try again later.'
      );
    });
};

export const getGraphStats = (options) => {
  options.offset = 0;
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, options, 'POST');
  return fetch(`${baseUrl}/stats/graph`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        return response.message;
      }
      FourOhOneError();
      return [];
    })
    .catch((e) => {
      displayError(
        'Oops! Looks like there was an error while loading the stats. Please try again later.'
      );
    });
};
