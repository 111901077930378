import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FormHelperText, Typography } from '@mui/material';

interface CheckBoxProps {
  checked: boolean;
  onChange: any;
  checkBoxLabel?: string;
  eventName?: string;
  errorLabel?: string;
  checkBoxId?: string;
  disabled?: boolean;
  sx?: any;
}

const CheckBox = ({
  checked,
  onChange,
  checkBoxLabel,
  eventName,
  errorLabel,
  checkBoxId,
  disabled,
  sx,
}: CheckBoxProps) => {
  return (
    <>
      <FormControlLabel
        sx={sx}
        control={
          <Checkbox
            data-id={checkBoxId}
            disabled={disabled}
            id={checkBoxId}
            sx={{
              color: '#ced4da',
              mt: 1,
            }}
            name={eventName}
            checked={checked}
            onChange={onChange}
          />
        }
        label={
          <Typography sx={{ mt: 1, fontFamily: 'Montserrat, sans-serif' }}>
            {checkBoxLabel}
          </Typography>
        }
      />
      <FormHelperText>{errorLabel}</FormHelperText>
    </>
  );
};

export default CheckBox;
