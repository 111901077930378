import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import useMemberships from '../../hooks/membership/query/useMemberships';
import useMembershipImages from '../../hooks/membership/query/useMembershipImages';
import useUserMemberships from '../../hooks/membership/query/useUserMemberships';
import { changeTab, convertCentsToMoney, history, timeForHumans } from '../../utils';
import { archiveMembership } from '../../api/memberships';
import PerksModal from './PerksModal';

import type { ISystem } from '../../ts/interface/system.interface';
import type { IImage } from '../../ts/interface/image.interface';
import type {
  IMembership,
  IUserMembership,
} from '../../ts/interface/membership.interface';
import { PropertyList } from '../../components/Redesing/property-list';
import { PropertyListItem } from '../../components/Redesing/property-list-item';
import PageContainer from '../../components/Redesing/page-container';
import PageContent from '../../components/Redesing/page-content';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import CardView from '../../components/Redesing/card-view';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, ButtonGroup } from '@mui/material';
import MembershipPerks from './assets/membership-perks';
import MembershipLocationAccess from './assets/membership-location-access';
import MembershipPricing from './assets/membership-pricing';
import UserMembership from './assets/user-membership';
import Divider from '@mui/material/Divider';
import MovaticCustomModal from '../../components/Modal/MovaticCustomModal';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import MembershipDocumentsList from './assets/membership-documents-list';
import NoFilePlaceHolder from '../../components/Redesing/no-file-place-holder';
import CardMembershipIcon from '@mui/icons-material/CardMembership';

interface IMembershipDetailProps {
  currentSystem: ISystem;
  systemLoaded: boolean;
}

const MembershipDetail = ({ currentSystem, systemLoaded }: IMembershipDetailProps) => {
  const { membershipId, currentTab: currentActiveTab } = useParams<{
    membershipId: string;
    currentTab: string;
  }>();
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [filteredUserMemberships, setFilteredUserMemberships] = useState([]);
  const [showPerksModal, setShowPerksModal] = useState(false);
  const [selectedPerkData, setSelectedPerkData] = useState({
    perkID: '',
    stationID: '',
  });
  const [currentTabV, setCurrentTabV] = useState('general');
  useEffect(() => {
    if (currentActiveTab !== currentTabV) {
      setCurrentTabV(currentActiveTab);
    }
  }, [currentActiveTab, currentTabV]);
  const handleTabsChange = (event: SyntheticEvent<Element, Event>, value: string) => {
    changeTab(
      `${membershipId}/${value}`,
      `${membershipId}/${currentTabV}`,
      'memberships'
    );
  };

  const {
    data: membershipData,
    isLoading: isLoadingMembership,
    refetch: refetchMembershipData,
  } = useMemberships(
    {
      membership_id: membershipId,
    },
    {
      enabled: systemLoaded,
    }
  );

  const membership = membershipData?.data?.[0] as IMembership;

  const { data: membershipImages, isLoading: isLoadingMembershipImages } =
    useMembershipImages({ enabled: systemLoaded });

  const { data: userMemberships, isFetched } = useUserMemberships({
    membership_id: membershipId,
  });

  function handlePerksCloseModal() {
    setShowPerksModal(false);
    setSelectedPerkData({
      perkID: '',
      stationID: '',
    });
  }

  useEffect(() => {
    if (isFetched && userMemberships) {
      const filteredUserMembership = userMemberships?.filter(
        (userMembership: IUserMembership) =>
          userMembership.active && userMembership.membership.id === membershipId
      );
      setFilteredUserMemberships(filteredUserMembership);
    }
  }, [userMemberships, isFetched, membershipId]);

  const isLoaded = useCallback(() => {
    return !isLoadingMembership && !isLoadingMembershipImages && systemLoaded;
  }, [isLoadingMembership, isLoadingMembershipImages, systemLoaded]);

  const button = () => {
    if (membership?.archived_on && new Date(membership.archived_on) <= new Date()) {
      return null;
    }
    return [
      {
        startIcon: <EditIcon />,
        label: 'Edit',
        onClick: () => history.push(`/memberships/edit/${membership?.id}`),
      },
      {
        startIcon: <DeleteIcon />,
        label: 'Archive',
        onClick: () => setShowArchiveModal(!showArchiveModal),
      },
    ];
  };

  const content = () => {
    const systemUsesWallets = currentSystem.billing && currentSystem.wallet_required;

    const logo = membershipImages?.find(
      (image: IImage) =>
        image.id === membership?.logo_image_id &&
        (image.type === 'MembershipLogo' || image.type === 'SystemLogo')
    );

    const free = !membership?.prices?.length;
    const minimum_wallet_deposit = Number.isInteger(membership?.minimum_wallet_deposit)
      ? convertCentsToMoney(membership.minimum_wallet_deposit)
      : null;
    const credit_card_hold = Number.isInteger(membership?.credit_card_hold)
      ? convertCentsToMoney(membership.credit_card_hold)
      : null;

    return (
      <PropertyList>
        {free ? (
          <>
            <Divider />
            <PropertyListItem
              label="Cost"
              value="This is a free membership"
              dataId="cost"
            />
          </>
        ) : null}
        <Divider />
        <PropertyListItem
          label="Active On"
          value={new Date(membership?.active_on as string).toDateString()}
          dataId="active-on"
        />
        {membership?.archived_on ? (
          <>
            <Divider />
            <PropertyListItem
              label="Archived On"
              value={new Date(membership.archived_on).toDateString()}
            />
          </>
        ) : null}
        {membership?.membership_replaced ? (
          <>
            <Divider />
            <PropertyListItem
              label="Membership Replaced"
              value={membership.membership_replaced}
            />
          </>
        ) : null}
        {membership?.auth_type ? (
          <>
            <Divider />
            <PropertyListItem
              label="Authorization Type"
              value={membership.auth_type}
              dataId="auth-type"
            />
          </>
        ) : null}
        {membership?.auth_type === 'email' ? (
          <>
            <Divider />
            <PropertyListItem
              divider
              label="Valid Emails"
              value={
                membership.valid_emails.length
                  ? membership.valid_emails.join(', ')
                  : 'All Domains Accepted'
              }
              dataId="valid-emails"
            />
            <PropertyListItem
              label="Hide Valid Emails"
              value={membership.hide_valid_emails ? 'Yes' : 'No'}
              dataId="hide-emails"
            />
          </>
        ) : null}
        {membership?.auth_type === 'password' ? (
          <>
            <Divider />
            <PropertyListItem label="Password" value={membership.password || ''} />
          </>
        ) : null}
        {
          // checking if expiration type can be cast to a number is a temporary fix--should only be null or seconds
          membership?.auth_expiration ? (
            <>
              <Divider />
              <PropertyListItem
                label="Authorization Expiration"
                value={
                  Number(membership.auth_expiration)
                    ? timeForHumans(membership.auth_expiration)
                    : membership.auth_expiration
                }
              />
            </>
          ) : null
        }
        {systemUsesWallets ? (
          <>
            <Divider />
            <PropertyListItem
              label="Minimum Wallet Balance"
              value={minimum_wallet_deposit ?? 'Not set'}
              dataId="min-wallet-ballance"
            />
          </>
        ) : null}
        {currentSystem.billing ? (
          <>
            <Divider />
            <PropertyListItem
              label="Credit Card Hold"
              value={credit_card_hold ?? 'Not set'}
              dataId="cc-hold"
            />
          </>
        ) : null}
        <Divider />
        <PropertyListItem
          divider
          label="Priority"
          value={membership?.priority}
          dataId="priority"
        />
        <PropertyListItem
          divider
          label="Max Simultaneous Rentals"
          value={membership?.max_rentals ?? 'Not set'}
          dataId="max-rentals"
        />
        <PropertyListItem
          divider
          label="Hidden"
          value={membership?.hidden ? 'Yes' : 'No'}
          dataId="membership-hidden"
        />
        <PropertyListItem divider label="Branding Color" dataId="branding-color">
          <div className="flexDisplay">
            <div
              className="membershipBrandingColor"
              style={{ backgroundColor: membership?.color_primary }}
            />
            <div>{membership?.color_primary}</div>
          </div>
        </PropertyListItem>

        <PropertyListItem dataId="logo" label="Logo Image">
          <div id="currentLogo">
            {logo ? (
              <div className="logoContainer">
                {!logo.approved_on && !logo.rejected ? (
                  <i className="fa fa-hourglass-half fa-fw navIconStyle navIcon pull-right  logoIcon" />
                ) : logo.rejected ? (
                  <i className="fa fa-ban fa-fw navIconStyle navIcon pull-right imageReject logoIcon" />
                ) : logo.approved_on ? (
                  <i className="fa fa-check-circle fa-fw navIconStyle navIcon pull-right  imageApprove logoIcon">
                    {' '}
                  </i>
                ) : null}
                <img
                  alt=""
                  className="logoRadius"
                  src={`data:image/png;base64,${logo.image}`}
                />
                <div className="logoOverlay">
                  <div className="imageText">
                    {!logo.approved_on && !logo.rejected
                      ? 'Image is being reviewed by Movatic.'
                      : logo.rejected
                      ? 'Image has been rejected, due to poor quality or due to cropping issues.'
                      : logo.approved_on
                      ? 'Image has been approved.'
                      : null}
                  </div>
                </div>
              </div>
            ) : (
              <NoFilePlaceHolder />
            )}
          </div>
        </PropertyListItem>
      </PropertyList>
    );
  };

  const currentLogo = useMemo(() => {
    return membershipImages?.find(
      (image: IImage) =>
        image.id === membership?.logo_image_id &&
        (image.type === 'MembershipLogo' || image.type === 'SystemLogo')
    );
  }, [membershipImages, membership]);

  const getMembershipIcon = useMemo(() => {
    const logo = currentLogo;
    const imageStyle = {
      height: '45px',
      maxWidth: '45px',
    };
    return logo?.image ? (
      <img
        alt=""
        src={`data:image/png;base64,${logo.image}`}
        style={{ ...imageStyle, borderRadius: '50%', objectFit: 'cover' }}
      />
    ) : (
      <CardMembershipIcon />
    );
  }, [currentLogo]);

  return (
    <PageContainer isLoading={!isLoaded()}>
      <PageContent
        backLink={'/memberships'}
        avatarColor={currentLogo?.image && 'none'}
        contentTitle={'Memberships'}
        itemName={membership?.name}
        itemChipValue={membership?.id}
        chipLabel={'Membership ID'}
        detailIcon={getMembershipIcon}
        currentTabV={currentTabV}
        itemTopButtons={<IconButtonMenu buttons={button() || []} />}
        handleTabsChange={handleTabsChange}
        tabsList={[
          { label: 'General', value: 'general' },
          { label: 'Members', value: 'members' },
        ]}
        tabsChildren={
          <>
            {currentTabV === 'general' && (
              <Grid container spacing={2}>
                <Grid xs={12} lg={4}>
                  <Stack spacing={2}>
                    <CardView
                      isLoading={!isLoaded()}
                      content={content()}
                      title={'Membership Details'}
                    />
                  </Stack>
                </Grid>
                <Grid xs={12} lg={8}>
                  <Stack spacing={2}>
                    <>
                      <MembershipPerks
                        perks={membership?.perks.filter((perk) => perk.system_id)}
                      />
                      <MembershipLocationAccess
                        onAddClick={() => setShowPerksModal(!showPerksModal)}
                        locations={membership?.perks.filter(
                          (perk) => !perk.system_id && perk.archived_on === null
                        )}
                      />
                      <MembershipPricing prices={membership?.prices || []} />
                      {membership?.auth_type === 'admin' && (
                        <MembershipDocumentsList docs={membership?.data?.docs || []} />
                      )}
                    </>
                  </Stack>
                </Grid>
              </Grid>
            )}
            {currentTabV === 'members' && (
              <UserMembership
                authType={membership?.auth_type || ''}
                memberships={filteredUserMemberships}
              />
            )}
          </>
        }
      />
      <MovaticCustomModal
        open={showArchiveModal}
        onClose={() => setShowArchiveModal(!showArchiveModal)}
        title="Archive Membership"
        content="Are you sure you would like to archive this membership? This cannot be undone."
        action={
          <ButtonGroup aria-label="outlined primary button group">
            <Button
              variant="contained"
              onClick={() => {
                archiveMembership(membership?.id).then(() => {
                  history.push('/memberships');
                });
                setShowArchiveModal(!showArchiveModal);
              }}
            >
              Submit
            </Button>
            <Button
              color={'secondary'}
              variant="contained"
              onClick={() => setShowArchiveModal(!showArchiveModal)}
            >
              Cancel
            </Button>
          </ButtonGroup>
        }
        customStyles={undefined}
        useContentDividers={undefined}
      />

      {showPerksModal && (
        <PerksModal
          openModal={showPerksModal}
          systemID={currentSystem.gid}
          locationIDs={membership.perks.map((perk) => perk.station_id)}
          membership={membership}
          perkData={selectedPerkData}
          refetchMembershipData={refetchMembershipData}
          handleCloseModal={handlePerksCloseModal}
        />
      )}
    </PageContainer>
  );
};

export default connect(
  (state: { system: { current: ISystem; isLoaded: boolean } }) => ({
    currentSystem: state.system.current,
    systemLoaded: state.system.isLoaded,
  }),
  () => ({})
)(MembershipDetail);
