import {
  API_STATIONS_SUCCESS,
  SET_STATION_DETAILS,
  CLEAR_DETAILED_STATON,
  STATION_DETAIL_HARDWARE_SEARCH,
  STATION_DETAIL_RENTAL_VALUE,
  STATIONS_LOADED,
  DETAILED_STATION_LOADED,
  SET_STATION_IMAGES,
  STATION_IMAGES_LOADED,
} from '../constants.js';

const initialState = {
  stations: [],
  isLoaded: false,
  membershipImages: [],
  membershipImagesLoaded: false,
  detailedStationLoaded: false,
  detailed: {
    banner_id: null,
    capacity: 0,
    dataType: null,
    id: '',
    lat: 0,
    long: 0,
    module: '',
    name: '',
    numHardware: null,
    reserved: null,
    state: null,
    system: null,
    type: null,
    typeName: null,
    searchHardwareValue: null,
    searchRentalValue: null,
    reservations: null,
    maxReservations: null,
    operating_hours: null,
    timezone: null,
    configuration: null,
    polygon_coordinates: null,
    zone_type: null,
    slowZoneSpeed: null,
  },
};

export default function stationReducer (state = initialState, action) {
  const newState = { ...state };

  switch (action.type) {
    case API_STATIONS_SUCCESS:
      newState.stations = action.stations;
      newState.isLoaded = true;
      break;

    case STATIONS_LOADED:
      newState.isLoaded = action.loaded;
      break;

    case DETAILED_STATION_LOADED:
      newState.detailedStationLoaded = action.loaded;
      break;

    case SET_STATION_DETAILS:
      newState.detailed = action.station;
      newState.detailedStationLoaded = true;
      break;

    case SET_STATION_IMAGES:
      newState.stationImages = action.images;
      newState.stationImagesLoaded = true;
      break;

    case STATION_IMAGES_LOADED:
      newState.stationImagesLoaded = action.loaded;
      break;

    case CLEAR_DETAILED_STATON:
      newState.detailed = {};
      break;

    case STATION_DETAIL_HARDWARE_SEARCH:
      newState.detailed.searchHardwareValue = action.searchText;
      break;

    case STATION_DETAIL_RENTAL_VALUE:
      newState.detailed.searchRentalValue = action.searchText;
      break;

    default:
      return state;
  }

  return newState;
}
