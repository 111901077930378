import { baseUrl, fetchOptions, getCredentials } from './http';
import { FourOhOneError } from '../actionCreators/system';
import CustomToast from '../components/Redesing/custom-toast';

export const getNavigationStats = () => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);
  //@ts-ignore
  return fetch(`${baseUrl}/stats/navigation`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        return response.message;
      }
      FourOhOneError(response);
      throw new Error(response.message);
    })
    .catch((e) => {
      CustomToast({
        type: 'error',
        message:
          'There was an error getting the navigation stats. Please try again later.',
      });
      return null;
    });
};
