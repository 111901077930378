import type { ReactNode } from 'react';
import { useMemo } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { paths } from '../paths';
import PeopleIcon from '@mui/icons-material/People';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BikeScooterIcon from '@mui/icons-material/BikeScooter';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SettingsIcon from '@mui/icons-material/Settings';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import { Badge } from '@mui/material';
import { userPermissions } from '../util/userPermissions';
import { findAccess } from '../utils';
import { ISystem } from '../ts/interface/system.interface';
import { useNavigationContext } from '../context/navigation-context';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LockClockIcon from '@mui/icons-material/LockClock';
import BuildIcon from '@mui/icons-material/Build';
export interface Item {
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
}

export interface Section {
  items: Item[];
  subheader?: string;
}

const {
  USERS,
  RENTAL,
  PHYSICAL,
  LOCATIONS,
  SUPPORT,
  MAINTENANCE,
  MEMBERSHIPS,
  RATES,
  REFUNDS,
  BANK,
} = userPermissions;

export const useSections = ({
  system,
  systemAccess,
}: {
  system: ISystem;
  systemAccess: number;
}) => {
  const { navigationData } = useNavigationContext();

  const totalBillingIssues = useMemo(() => {
    return (
      (navigationData?.totalOfbillingIssues ?? 0) +
      (navigationData?.pending_charges ?? 0) +
      (Boolean(navigationData?.billing_warning) ? 1 : 0)
    );
  }, [navigationData]);

  const access = useMemo(() => {
    return findAccess(systemAccess);
  }, [systemAccess]);
  return useMemo(() => {
    return [
      {
        items: [
          {
            title: 'Dashboard',
            path: paths.dashboard.index,
            icon: (
              <SvgIcon fontSize="small">
                <DashboardIcon />
              </SvgIcon>
            ),
          },
          access.includes(USERS) && {
            title: 'Users',
            path: '/users',
            icon: (
              <SvgIcon fontSize="small">
                <PeopleIcon />
              </SvgIcon>
            ),
          },
          access.includes(RENTAL) && {
            title: 'Rentals',
            path: '/rentals',
            icon: (
              <SvgIcon fontSize="small">
                <LockClockIcon />
              </SvgIcon>
            ),
          },
          access.includes(PHYSICAL) && {
            title: 'Hardware',
            path: '/hardware',
            icon: (
              <SvgIcon fontSize="small">
                <BikeScooterIcon />
              </SvgIcon>
            ),
          },
          access.includes(LOCATIONS) && {
            title: 'Locations',
            path: '/locations',
            icon: (
              <SvgIcon fontSize="small">
                <LocationOnIcon />
              </SvgIcon>
            ),
          },
          access.includes(MEMBERSHIPS) && {
            title: 'Memberships',
            path: '/memberships',
            icon: (
              <Badge
                badgeContent={
                  navigationData?.user_membership_pending
                    ? navigationData?.user_membership_pending
                    : navigationData?.membership_warning
                    ? 1
                    : 0
                }
                color="error"
              >
                <SvgIcon fontSize="small">
                  <CardMembershipIcon />
                </SvgIcon>
              </Badge>
            ),
          },
          access.includes(SUPPORT) && {
            title: 'Support Tickets',
            path: '/problems',
            icon: (
              <Badge badgeContent={navigationData?.support_open} color="error">
                <SvgIcon fontSize="small">
                  <SupportAgentIcon />
                </SvgIcon>
              </Badge>
            ),
          },
          access.includes(MAINTENANCE) && {
            title: 'Maintenance',
            path: '/maintenance',
            icon: (
              <Badge badgeContent={navigationData?.maintenance_open} color="error">
                <SvgIcon fontSize="small">
                  <BuildIcon />
                </SvgIcon>
              </Badge>
            ),
          },
          system.billing &&
            access.some((perm) => [RATES, REFUNDS].includes(perm)) && {
              title: 'Billing',
              path: '/billing',
              icon: (
                <Badge badgeContent={totalBillingIssues} color="error">
                  <SvgIcon fontSize="small">
                    <AttachMoneyIcon />
                  </SvgIcon>
                </Badge>
              ),
            },
          access.includes(BANK) && {
            title: 'Settings',
            path: '/settings',
            icon: (
              <Badge
                badgeContent={navigationData?.settings_warning ? 1 : 0}
                color="error"
              >
                <SvgIcon fontSize="small">{<SettingsIcon />}</SvgIcon>
              </Badge>
            ),
          },
        ].filter(Boolean),
      },
    ];
  }, [navigationData, access, system.billing, totalBillingIssues]);
};
