import bugsnagClient from '../components/bugsnag';
import {
  API_HARDWARE_PRODUCT_SUCCESS,
  API_PRODUCT_FIELD_SUCCESS,
  PRODUCT_LOADED,
  PRODUCT_FIELD_LOADED,
} from '../constants';
import { fetchOptions, fetchPostOptions, baseUrl } from '../api/http.jsx';
import { FourOhOneError, update } from './system.js';
import { gotSuccess } from './toast';

export const apiHardwareProductSuccess = (hardwareProducts) => ({
  type: API_HARDWARE_PRODUCT_SUCCESS,
  hardwareProducts,
});

export const apiProductFieldsSuccess = (productFields) => ({
  type: API_PRODUCT_FIELD_SUCCESS,
  productFields,
});

const hardwareProductLoaded = (loaded) => ({
  type: PRODUCT_LOADED,
  loaded,
});

const productFieldsLoaded = (loaded) => ({
  type: PRODUCT_FIELD_LOADED,
  loaded,
});

export const getAllHardwareProducts = () => (dispatch, getState) => {
  dispatch(hardwareProductLoaded(false));
  const state = getState();
  const fetchOps = fetchOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id
  );
  return fetch(`${baseUrl}/hardware/product`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        if (response.message !== undefined) {
          return dispatch(apiHardwareProductSuccess(response.message));
        }
      } else {
        dispatch(FourOhOneError(response));
      }
    })
    .catch((error) => {
      console.error(error);
      bugsnagClient.notify(error);
      return error;
    });
};

// for adding new products, movatic only
export const getAllProductFields = () => (dispatch, getState) => {
  dispatch(productFieldsLoaded(false));
  const state = getState();
  const fetchOps = fetchOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id
  );
  return fetch(`${baseUrl}/hardware/product/info`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        return dispatch(apiProductFieldsSuccess(response.message));
      }
      dispatch(FourOhOneError(response));
    })
    .catch((error) => {
      console.error(error);
      bugsnagClient.notify(error);
      return error;
    });
};

export const addHardwareProduct = (product) => (dispatch, getState) => {
  dispatch(update(false));
  const state = getState();
  const fetchOps = fetchPostOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id,
    product,
    'Post'
  );

  return fetch(`${baseUrl}/hardware/product/add`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      dispatch(update(true));

      if (response.status === 200) {
        dispatch(gotSuccess(`${response.message} Remember to add a product version!`));
      } else {
        dispatch(FourOhOneError(response));
      }
    })
    .catch((error) => {
      console.error(error);
      bugsnagClient.notify(error);
      return error;
    });
};

export const addHardwareProductVersion = (version) => (dispatch, getState) => {
  dispatch(update(false));
  const state = getState();
  const fetchOps = fetchPostOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id,
    version,
    'Post'
  );

  return fetch(`${baseUrl}/hardware/product/version`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      dispatch(update(true));

      if (response.status === 200) {
        dispatch(gotSuccess(response.message));
      } else {
        dispatch(FourOhOneError(response));
      }
      return response;
    })
    .catch((error) => {
      console.error(error);
      bugsnagClient.notify(error);
      return error;
    });
};
