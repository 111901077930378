import {
  API_STATIONS_SUCCESS,
  SET_STATION_DETAILS,
  CLEAR_DETAILED_STATON,
  STATION_DETAIL_HARDWARE_SEARCH,
  STATION_DETAIL_RENTAL_VALUE,
  STATIONS_LOADED,
  DETAILED_STATION_LOADED,
  SET_STATION_IMAGES,
  STATION_IMAGES_LOADED,
} from '../constants';
import { fetchOptions, baseUrl } from '../api/http.jsx';
import { FourOhOneError } from './system.js';
import { updateStation, createLocation } from '../api/stations';
import { displayError } from '../utils';

export const apiStationsSuccess = (stations) => ({
  type: API_STATIONS_SUCCESS,
  stations,
});

export const stationsLoaded = (loaded) => ({
  type: STATIONS_LOADED,
  loaded,
});

export const setStationDetails = (station) => ({
  type: SET_STATION_DETAILS,
  station,
});

export const detailedStationLoaded = (loaded) => ({
  type: DETAILED_STATION_LOADED,
  loaded,
});

export const clearDetailStation = () => ({
  type: CLEAR_DETAILED_STATON,
});

export const stationDetailHardwareSearch = (searchText) => ({
  type: STATION_DETAIL_HARDWARE_SEARCH,
  searchText,
});

export const stationDetailRentalValue = (searchText) => ({
  type: STATION_DETAIL_RENTAL_VALUE,
  searchText,
});

const setStationImages = (images) => ({
  type: SET_STATION_IMAGES,
  images,
});

const stationImagesLoaded = (loaded) => ({
  type: STATION_IMAGES_LOADED,
  loaded,
});

export const getAllStations = () => (dispatch, getState) => {
  dispatch(stationsLoaded(false));

  const state = getState();
  const fetchOps = fetchOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id
  );
  return fetch(`${baseUrl}/station`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        return dispatch(apiStationsSuccess(response.message));
      }
      dispatch(stationsLoaded(true));
      dispatch(FourOhOneError(response));
    })
    .catch((error) => {
      dispatch(stationsLoaded(true));
    });
};

export const uploadImage =
  (formData, newStationInfo, newStation) => (dispatch, getState) => {
    const state = getState();
    return fetch(`${baseUrl}/system/settings/image`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Basic ${btoa(
          `${state.admin.admin.admin.email}:${state.admin.admin.token}`
        )}`,
        system: state.system.current.id,
      },
    })
      .then((res) => res.json())
      .then((results) => {
        if (results.status === 200) {
          if (newStation) {
            newStationInfo.banner_id = results.message.id;
            return createLocation(newStationInfo);
          }
          newStationInfo.banner_id = results.message.id;
          return updateStation(newStationInfo);
        }
        dispatch(FourOhOneError(results));
        throw new Error(results.message);
      })
      .catch((error) => {
        displayError(
          'Oops! Looks like there was an error while uploading the image. Please try again later.'
        );
      });
  };

export const getStationImages = () => (dispatch, getState) => {
  dispatch(stationImagesLoaded(false));

  const state = getState();
  const fetchOps = fetchOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id
  );
  return fetch(`${baseUrl}/approve-image/images/station`, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        if (results.message !== null) {
          const images = results.message.map((img) => {
            state.system.systems.forEach((system) => {
              if (img.id === system.banner_id || img.id === system.logo_id) {
                img.system = system.id;
              }
            });
            return img;
          });
          return dispatch(setStationImages(images));
        }
        return dispatch(setStationImages(results.message));
      }
      dispatch(stationImagesLoaded(true));
      dispatch(FourOhOneError(results));
    })
    .catch((error) => {
      dispatch(stationImagesLoaded(true));
      displayError('There was an error getting images');
    });
};
