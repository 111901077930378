import React, { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';

interface RadioOption {
  id: any;
  label: string;
  popoverContent?: React.ReactNode;
}

interface RadioButtonsProps {
  value: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  options: RadioOption[];
  row?: boolean;
  padding?: number;
  name?: string;
  id?: string;
}

const RadioButtons = ({
  value,
  handleChange,
  options,
  row,
  padding = 3,
  name,
  id,
}: RadioButtonsProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [hoveredItem, setHoveredItem] = useState<React.ReactNode | null>(null);
  const theme = useTheme();
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>, itemId: any) => {
    setAnchorEl(event.currentTarget);
    const message = options.find((item) => item.id === itemId);
    setHoveredItem(message?.popoverContent || null);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    // <ThemeProvider theme={theme}>
    <FormControl>
      <RadioGroup
        id={id}
        row={row}
        aria-labelledby="radio-buttons-group"
        name={name}
        value={value}
        onChange={(event, value) => handleChange(event, value)}
      >
        {options.map((item) => (
          <div key={item.id}>
            <FormControlLabel
              name={`${name}-${item.id}`}
              value={item.id}
              control={
                <Radio
                  color={theme.palette.mode === 'dark' ? 'secondary' : 'primary'}
                  key={item.id}
                  name={item.label}
                  size="small"
                />
              }
              label={
                <Typography
                  onMouseLeave={() => handlePopoverClose()}
                  onMouseEnter={(event) => handlePopoverOpen(event, item.id)}
                >
                  {item.label}
                </Typography>
              }
            />
            {item.popoverContent && (
              <Popover
                id={`popover-${item.id}`} // Unique ID for each popover
                sx={{
                  pointerEvents: 'none',
                }}
                open={open && value === item.id}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Typography sx={{ p: 1 }}>{hoveredItem}</Typography>
              </Popover>
            )}
          </div>
        ))}
      </RadioGroup>
    </FormControl>
    // </ThemeProvider>
  );
};

export default RadioButtons;
