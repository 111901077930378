// TODO: remove this

import {
  API_MODULE_SUCCESS,
  SET_MODULE_DETAILS,
  CLEAR_MODULE_DETAILS,
} from '../constants.js';

const initialState = {
  modules: [],
  isLoaded: false,
  detailedModule: {
    current_rental: null,
    hardwareId: null,
    hardwareNumber: null,
    hardwareType: null,
    mac: null,
    mostRecentTimestamp: null,
    oldBikeshare: null,
    state: null,
    system: null,
    systemName: null,
    vendor: null,
    voltage: null,
    battery_status: null,
    has_gps: false,
    last_seen: null,
    last_lat: null,
    last_lng: null,
  },
};

export default function moduleReducer (state = initialState, action) {
  const newState = { ...state };

  switch (action.type) {
    case API_MODULE_SUCCESS:
      newState.modules = action.modules;
      newState.isLoaded = true;
      break;

    case SET_MODULE_DETAILS:
      newState.detailedModule = action.moduleDetails;
      break;

    case CLEAR_MODULE_DETAILS:
      newState.detailedModule = {
        current_rental: null,
        hardwareId: null,
        hardwareNumber: null,
        hardwareType: null,
        mac: null,
        mostRecentTimestamp: null,
        oldBikeshare: null,
        state: null,
        system: null,
        systemName: null,
        vendor: null,
        voltage: null,
      };
      break;

    default:
      return state;
  }

  return newState;
}
