import React from 'react';
import Dropzone from 'react-dropzone';
import { Box, Typography, Stack, Avatar, SvgIcon } from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

interface FileUploadProps {
  onDrop: Function;
  caption?: string;
  accept?: string;
  maxSize?: number;
}

const FileUpload: React.FC<FileUploadProps> = ({
  onDrop,
  caption,
  accept = 'image/png',
  maxSize = 1000000,
}) => {
  return (
    //@ts-ignore
    <Dropzone onDrop={onDrop} accept={accept} maxSize={maxSize}>
      {({ getRootProps, getInputProps }) => (
        <Box
          sx={{
            alignItems: 'center',
            border: 1,
            borderRadius: 1,
            borderStyle: 'dashed',
            borderColor: 'divider',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            outline: 'none',
            p: 6,
            '&:hover': {
              backgroundColor: 'action.hover',
              cursor: 'pointer',
              opacity: 0.5,
            },
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <Stack alignItems="center" direction="row" spacing={2}>
            <Avatar
              sx={{
                height: 64,
                width: 64,
              }}
            >
              <SvgIcon>
                <FileUploadOutlinedIcon />
              </SvgIcon>
            </Avatar>
            <Stack spacing={1}>
              <Typography
                sx={{
                  '& span': {
                    textDecoration: 'underline',
                  },
                }}
                variant="h6"
              >
                <span>Click to upload</span> or drag and drop
              </Typography>
              {caption && (
                <Typography color="text.secondary" variant="body2">
                  {caption}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Box>
      )}
    </Dropzone>
  );
};

export default FileUpload;
