import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

interface IBigButtonProps {
  description: string;
  onClick: () => void;
}

const BigButton: React.FC<IBigButtonProps> = (props) => {
  const { description, onClick } = props;
  return (
    <Button onClick={onClick} sx={{ marginTop: 2 }} fullWidth variant="contained">
      <Typography variant={'h6'}>{description}</Typography>
    </Button>
  );
};

export default BigButton;
