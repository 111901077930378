import { useQuery } from '@tanstack/react-query';
import { IUseHardwareFilter } from '../../../ts/interface/hardware.interface';
import { getRentalRoute } from '../../../api/rentals';

interface Parameters {
  queryParameters?: IUseHardwareFilter;
  paginationResultHandler?: Function;
  systemId?: string;
}
const useRentalRoute = (rentalId: string, options?: {}, parameters?: Parameters) => {
  return useQuery(
    ['rentalRoute', parameters?.queryParameters, rentalId],
    () =>
      getRentalRoute(
        rentalId,
        parameters?.queryParameters,
        parameters?.paginationResultHandler
      ),
    { ...options }
  );
};

export default useRentalRoute;
