import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { useMounted } from '../../hooks/use-mounted';
import Box from '@mui/material/Box';
import { Logo } from '../../components/Redesing/logo';
import PageLoader from '../../components/Redesing/page-loader';
import AuthLayout from '../../layout/auth/auth-layout';
import { Alert, ButtonGroup, Grid } from '@mui/material';
import { resetPassword } from '../../api/admin';
import Typography from '@mui/material/Typography';

interface Values {
  email: string;
  password: string;
  submit: null;
}

const initialValues: Values = {
  email: '',
  password: '',
  submit: null,
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Must be a valid email')
    .max(255)
    .required('Email is required'),
});

interface IResetPasswordProps {
  maxedOut: boolean;
  cancel: () => void;
  onSubmit: (email: string) => void;
}

const Page: React.FC<IResetPasswordProps> = ({ maxedOut, cancel, onSubmit }) => {
  const isMounted = useMounted();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values): Promise<void> => {
      resetPassword(values.email).then((r) => {
        if (r) {
          onSubmit(values.email);
        }
      });
    },
  });
  if (!isMounted) return <PageLoader />;
  return (
    <AuthLayout>
      <>
        {maxedOut && (
          <Box pb={1}>
            <Alert severity="error">
              You have reached the maximum amount of logins. Please reset your password to
              continue.
            </Alert>
          </Box>
        )}
      </>
      <Card elevation={16}>
        <CardHeader
          subheader={
            <>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <>
                      {maxedOut && (
                        <Alert sx={{ borderRadius: '20px' }} severity="error">
                          <Typography variant="subtitle1">
                            You have reached the maximum amount of logins. Please reset
                            your password to continue.
                          </Typography>
                        </Alert>
                      )}
                    </>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <Logo />
                  </Grid>
                </Grid>
              </Box>
            </>
          }
          sx={{ pb: 0 }}
          title=""
        />
        <CardContent>
          <form noValidate onSubmit={formik.handleSubmit}>
            <Stack spacing={3}>
              <TextField
                autoFocus
                error={!!(formik.touched.email && formik.errors.email)}
                fullWidth
                helperText={formik.touched.email && formik.errors.email}
                label="Email Address"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="email"
                value={formik.values.email}
              />
            </Stack>
            {formik.errors.submit && (
              <FormHelperText error sx={{ mt: 3 }}>
                {formik.errors.submit as string}
              </FormHelperText>
            )}
            <ButtonGroup
              variant="contained"
              aria-label="outlined primary button group"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 2,
              }}
            >
              <Button
                disabled={formik.isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Reset your Password
              </Button>
              <Button onClick={cancel} fullWidth size="large" variant="outlined">
                Cancel
              </Button>
            </ButtonGroup>
          </form>
        </CardContent>
      </Card>
    </AuthLayout>
  );
};

export default Page;
