import { useQuery } from '@tanstack/react-query';
import { getGraphStats } from '../../../api/dashboard';
import { IUseGraphOptions } from '../../../ts/interface/dashboardGraph.interface';

const useGraphStats = (queryOptions?: {}, graphOptions?: IUseGraphOptions) => {
  return useQuery(
    ['dashboardStats', graphOptions],
    () => getGraphStats(graphOptions?.parameters),
    {
      ...queryOptions,
    }
  );
};

export default useGraphStats;
