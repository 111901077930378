import {
  API_PROBLEMS_SUCCESS,
  SET_PROBLEM_DETAILS,
  PROBLEM_DETAIL_LOADED,
  PROBLEMS_LOADING,
  RENTAL_PROBLEMS_LOADED,
  SET_RENTAL_PROBLEMS,
} from '../constants.js';

const initalState = {
  problems: [],
  isLoaded: false,
  detailed: {
    closedDate: '',
    content: '',
    created: '',
    email: '',
    id: null,
    notes: null,
    owner: '',
    rentalId: null,
    state: null,
    system: '',
    type: null,
    userId: null,
  },
  problemDetailLoaded: false,
  rentalProblems: [],
  rentalProblemsLoaded: false,
};

export default function problemReducer (state = initalState, action) {
  const newState = { ...state };

  switch (action.type) {
    case API_PROBLEMS_SUCCESS:
      newState.problems = action.problems;
      newState.isLoaded = true;
      break;

    case SET_PROBLEM_DETAILS:
      newState.detailed = action.problemDetails;
      newState.problemDetailLoaded = true;
      break;

    case PROBLEM_DETAIL_LOADED:
      newState.problemDetailLoaded = action.loaded;
      break;

    case PROBLEMS_LOADING:
      newState.isLoaded = action.loaded;
      break;

    case RENTAL_PROBLEMS_LOADED:
      newState.rentalProblemsLoaded = action.loaded;
      break;

    case SET_RENTAL_PROBLEMS:
      newState.rentalProblems = action.problems;
      newState.rentalProblemsLoaded = true;
      break;

    default:
      return state;
  }

  return newState;
}
