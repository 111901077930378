import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import useOneCompletedTask from '../../hooks/maintenance/query/useOneCompletedTask';
import {
  getLocaleDateTimeString,
  history,
  timeForHumans,
  toTitleCase,
} from '../../utils';
import { IMode } from '../../ts/interface/mode.interface';
import { IAdmin } from '../../ts/interface/admins.interface';
import useSystemAdmins from '../../hooks/system/query/useSystemAdmins';
import { PropertyList } from '../../components/Redesing/property-list';
import { PropertyListItem } from '../../components/Redesing/property-list-item';
import PropertyListItemLink from '../../components/Redesing/property-list-item-link';
import Divider from '@mui/material/Divider';
import PageContainer from '../../components/Redesing/page-container';
import PageContent from '../../components/Redesing/page-content';
import CardView from '../../components/Redesing/card-view';
import { Stack } from '@mui/system';

const TaskDetail = ({
  mode,
  systemLoaded,
  new_hardware,
}: {
  mode: IMode;
  systemLoaded: boolean;
  new_hardware: boolean;
}) => {
  const { unitsTitle } = mode.details;
  const { taskId = '' } = useParams<{ taskId: string }>();
  const { data: task = {}, isLoading } = useOneCompletedTask(taskId, {
    enabled: systemLoaded,
  });
  const { data: admins = [] } = useSystemAdmins({
    enabled: systemLoaded,
  });
  const hardwareContent = () => {
    // TODO: remove this, just put new_hardware section in content()
    if (new_hardware) {
      return (
        <div>
          <PropertyListItemLink
            others={{ divider: true }}
            label={toTitleCase(task?.hardware_type_name)}
            text={
              task?.hardware_number || task[task?.primary_identifier] || task?.hardware_id
            }
            onClick={() =>
              history.push(`/hardware/${task?.hardware_type}/${task?.hardware_id}`)
            }
          />
        </div>
      );
    }
    return (
      <div>
        {task?.hardware_id ? (
          <PropertyListItemLink
            others={{ divider: true }}
            label={unitsTitle}
            text={task?.hardware_number}
            onClick={() => history.push(`/units/${task?.hardware_id}`)}
          />
        ) : null}
        {task?.module_id ? (
          <PropertyListItemLink
            others={{ divider: true }}
            label="Lock"
            text={task?.module_id}
            onClick={() => history.push(`/units/locks/${task?.module_id}`)}
          />
        ) : null}
      </div>
    );
  };
  let header = 'Maintenance Log for ';
  if (new_hardware) {
    if (task?.hardware_number) {
      header += `${toTitleCase(task?.hardware_type_name)} ${task?.hardware_number}`;
    } else {
      header += task[task?.primary_identifier];
    }
  } else {
    header += `${unitsTitle} ${task?.hardware_number}`;
  }
  return (
    <>
      <PageContainer isLoading={isLoading}>
        <PageContent
          backLink={'/maintenance/logs'}
          contentTitle={'Maintenance Logs'}
          itemName={header}
          itemChipValue={taskId}
          chipLabel={'Task ID'}
          tabsChildren={
            <CardView
              content={
                <Stack>
                  <PropertyList>
                    <PropertyListItem
                      divider
                      label="Maintenance Type"
                      value={`${task?.task_name}: ${task?.task_description}`}
                    />
                    <PropertyListItem
                      divider
                      label="Completed"
                      value={getLocaleDateTimeString(task?.created_on)}
                    />
                    <PropertyListItem
                      divider
                      label="Time Taken"
                      value={timeForHumans(task?.time_tracking)}
                    />
                    {admins.find(
                      (admin: IAdmin) => admin.email === task?.admin_user_id
                    ) ? (
                      <PropertyListItemLink
                        others={{ divider: true }}
                        label="Completed By"
                        text={task?.admin_name}
                        onClick={() =>
                          history.push(`/settings/admin/${task?.admin_user_id}`)
                        }
                      />
                    ) : (
                      <PropertyListItem
                        divider
                        label="Completed By"
                        value={task?.admin_name}
                      />
                    )}
                    {hardwareContent()}
                    {task?.station_gid ? (
                      <PropertyListItemLink
                        others={{ divider: true }}
                        label={mode.details.stationsTitle}
                        text={task?.station_name}
                        onClick={() =>
                          history.push(`/locations/${task?.station_gid}/general`)
                        }
                      />
                    ) : (
                      <PropertyListItem
                        label={mode.details.stationsTitle}
                        value={'None'}
                      />
                    )}
                    {task?.maintenance_ticket_id ? (
                      <>
                        <Divider />
                        <PropertyListItemLink
                          label="Maintenance Ticket"
                          text={task?.maintenance_ticket_id}
                          onClick={() =>
                            history.push(`/maintenance/${task?.maintenance_ticket_id}`)
                          }
                        />
                      </>
                    ) : null}
                    {task?.notes ? (
                      <>
                        <Divider />{' '}
                        <PropertyListItem label="Notes" value={task?.notes.toString()} />
                      </>
                    ) : null}
                  </PropertyList>
                </Stack>
              }
              title={'Task Details'}
            />
          }
        />
      </PageContainer>
    </>
  );
};

export default connect(
  (state: {
    admin: { systemAdmins: IAdmin[] };
    mode: IMode;
    system: { current: { new_hardware: boolean }; isLoaded: boolean };
  }) => ({
    admins: state.admin.systemAdmins,
    systemLoaded: state.system.isLoaded,
    mode: state.mode,
    new_hardware: state.system.current.new_hardware,
  }),
  () => ({})
)(TaskDetail);
