import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import useStation from '../../hooks/stations/query/useStation';
import { stationConfigurations } from '../../util/stations';
import { ACTIVE, DEFAULT_ROWS_PER_PAGE, LOST, MISSING, RETIRED } from '../../constants';
import {
  changeTab,
  convertCentsToMoney,
  findAccess,
  getPricingString,
  history,
  openGoogleMapsCoordinates,
  stateFormatter,
  toTitleCase,
  zoneTypeFormatter,
} from '../../utils';
import { IStation, StationConfiguration } from '../../ts/interface/station.interface';
import useAllHardware from '../../hooks/hardware/query/useAllHardware';
import { IHardware } from '../../ts/interface/hardware.interface';
import { ISystem } from '../../ts/interface/system.interface';
import { IMode } from '../../ts/interface/mode.interface';

import LocationOffIcon from '@mui/icons-material/LocationOff';
//@ts-ignore

import useGetStationImages from '../../hooks/stations/query/useGetStationImages';
import { columnsForHardwareV2 } from '../../tableColumns/stationColumns';
import { retireStation } from '../../api/stations';
import { IRate } from '../../ts/interface/rental.interface';
import useGetStationStats from '../../hooks/stations/query/useGetStationStats';
import { connect } from 'react-redux';
import { IStationDetailsProps } from '../../ts/interface/pages/stations.interfaces';
import { LocationAccess } from '../../ts/enums';
import PageContainer from '../../components/Redesing/page-container';
import PageContent from '../../components/Redesing/page-content';
import { PropertyList } from '../../components/Redesing/property-list';
import { PropertyListItem } from '../../components/Redesing/property-list-item';
import PropertyListItemLink from '../../components/Redesing/property-list-item-link';
import Stack from '@mui/material/Stack';
import CardView from '../../components/Redesing/card-view';
import Divider from '@mui/material/Divider';
import { PaginationTable } from '../../components/Redesing/table/pagination-table';
import EditIcon from '@mui/icons-material/Edit';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Unstable_Grid2';
import MovaticCustomModal from '../../components/Modal/MovaticCustomModal';
import { SeverityPill } from '../../components/Redesing/severity-pill';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';
import SaveIcon from '@mui/icons-material/Save';
import RentalsTableCard from '../../components/Redesing/table/rental/rentals-table-card';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import NoFilePlaceHolder from '../../components/Redesing/no-file-place-holder';
import { getLocationIconByName } from '../../util/utilsR';
import useHardwareV2 from '../../hooks/hardware/query/V2/useHardwareV2';
import {
  IColumn,
  ISortConfig,
} from '../../ts/interface/components/redesing/table.interface';
import customAsyncToast from '../../components/Redesing/custom-async-toast';
import { updateHardware } from '../../api/hardware';
import { IStatePillsOption } from '../../ts/interface/components/redesing/state-pills.interface';
import useSmallScreen from '../../hooks/useSmallScreen';
import CustomMapComponent from '../../components/Redesing/custom-map-component-for-detail-location';

const StationDetails = ({
  systemLoaded,
  system,
  modeInfo,
  movaticAccess,
  systemAccess,
  partner,
}: IStationDetailsProps) => {
  const stationId = useParams<{ stationId: string }>().stationId;
  const currentTab = useParams<{ currentTab: string }>().currentTab || 'general';
  const [retireModal, setRetireModal] = useState(false);
  const [activeHardware, setActiveHardware] = useState(false);
  const [detailedStation, setDetailedStation] = useState<IStation>({} as IStation);
  const [searchRate, setSearchRate] = React.useState('');
  const [pageRate, setPageRate] = React.useState(0);
  const [rowsPerPageRate, setRowsPerPageRate] = React.useState(DEFAULT_ROWS_PER_PAGE);
  const [searchHardware, setSearchHardware] = React.useState('');
  const [pageHardware, setPageHardware] = React.useState(0);
  const [rowsPerPageHardware, setRowsPerPageHardware] =
    React.useState(DEFAULT_ROWS_PER_PAGE);
  const [hardwareLastId, setHardwareLastId] = React.useState<string | null>(null);
  const [hardwareAtLocation, setHardwareAtLocation] = React.useState<IHardware[]>([]);
  const [hardwareSortConfig, setHardwareSortConfig] = React.useState<ISortConfig>({
    key: 'created_on',
    direction: 'asc',
  });
  const [currentTabV, setCurrentTabV] = useState('general');
  const [exportRateData, setExportRateData] = useState<boolean>(false);
  const [exportHardwareData, setExportHardwareData] = useState<boolean>(false);
  const isSmallScreen = useSmallScreen();
  const handleTabsChange = useCallback(
    (event: any, value: React.SetStateAction<string>) => {
      changeTab(`${stationId}/${value}`, currentTab, 'locations');
    },
    [stationId, currentTab]
  );

  useEffect(() => {
    if (currentTab !== currentTabV) {
      setCurrentTabV(currentTab);
    }
  }, [currentTab, currentTabV]);

  const {
    data,
    isLoading: isLoadingStations,
    isFetched,
  } = useStation(
    {
      enabled: !!stationId && systemLoaded,
    },
    {
      locationId: stationId,
      fields: 'rates',
    }
  );

  const { data: allHardware = [] } = useAllHardware({
    enabled: systemLoaded && !!stationId,
  });

  const {
    refetch: refetchHardware,
    isLoading: isLoadingHardware,
    isRefetching: isRefetchingHardware,
  } = useHardwareV2(
    {
      location_id: stationId,
      after: hardwareLastId,
      limit: hardwareLastId ? rowsPerPageHardware : rowsPerPageHardware + 1,
    },
    {
      enabled: systemLoaded,
    }
  );

  const hardwareRefetch = useCallback(() => {
    return systemLoaded
      ? refetchHardware().then((res) => {
          if (res.status === 'success') {
            const { data } = res;
            if (hardwareLastId) {
              if (data) setHardwareAtLocation((hardware) => [...hardware, ...data]);
            } else {
              setHardwareAtLocation(data);
            }
          }
        })
      : [];
  }, [hardwareLastId, refetchHardware, systemLoaded]);

  useEffect(() => {
    hardwareRefetch();
  }, [
    hardwareRefetch,
    hardwareSortConfig,
    pageHardware,
    rowsPerPageHardware,
    stationId,
    systemLoaded,
  ]);

  const { data: stationImages } = useGetStationImages({
    enabled: !!stationId && systemLoaded,
  });

  useEffect(() => {
    if (data && isFetched) {
      setDetailedStation(
        data && data.data && data.data.length > 0 ? data?.data[0] : ({} as IStation)
      );
    }
  }, [data, isFetched]);

  const checkForActiveHardware = () => {
    const { ZONE } = StationConfiguration;

    const bikesAtStation = allHardware.filter((item: IHardware) => {
      return (
        item.state === ACTIVE &&
        item.current_station === stationId &&
        !item.has_gps &&
        (system.new_hardware ? item.hardware_type_name !== 'base_station' : true)
      );
    });

    if (detailedStation.configuration !== ZONE && bikesAtStation.length > 0) {
      setActiveHardware(true);
    } else {
      setActiveHardware(false);
    }
    setRetireModal(true);
  };

  const rowClick = (type: string, row: IHardware) => {
    if (type === 'rental') {
      history.push(`/rentals/${row.id}`);
    } else if (type === 'hardware') {
      if (system.new_hardware) {
        history.push(`/hardware/${row.hardware_type.name}/${row.old_id}`);
      } else {
        history.push(`/units/${row.id}`);
      }
    }
  };

  const warningCheck = () => {
    const { stationsTitle } = modeInfo.details;
    if (!detailedStation.module && modeInfo.currentMode === 'bikeroom') {
      return `This ${stationsTitle} is not available to make rentals. Assign a lock to make this ${stationsTitle} available to make rentals`;
    }
  };

  const getAccess = (section: string) => {
    return !findAccess(systemAccess).includes(section) && !movaticAccess;
  };

  const button = () => {
    const { state, id } = detailedStation;
    const bts = [
      {
        startIcon: state === RETIRED ? <LocationOnIcon /> : <EditIcon />,
        label: state === RETIRED ? 'Unretire' : 'Edit',
        onClick: () => history.push(`/locations/${id}/edit`),
        disabled: getAccess('physical'),
      },
    ];
    if (state !== RETIRED) {
      bts.push({
        startIcon: <LocationOffIcon />,
        label: 'Retire',
        onClick: () => checkForActiveHardware(),
        disabled: getAccess('physical'),
      });
    }
    return bts;
  };

  const columnsHardware = useMemo(() => {
    return columnsForHardwareV2(
      isSmallScreen,
      (row: IHardware, item: IStatePillsOption) => {
        customAsyncToast({
          promise: () =>
            updateHardware(
              {
                id: row.old_id,
                state: item.value,
                station:
                  row.state !== LOST &&
                  row.state !== MISSING &&
                  (item.value === LOST || item.value === MISSING)
                    ? null
                    : detailedStation.old_id || null,
              },
              {
                hardware_type: system.hardware_type,
              }
            ),
          loadingMessage: 'Updating...',
          successMessage: () => {
            return 'Hardware updated successfully!';
          },
          errorMessage: 'Failed to update hardware',
        }).then(() => {
          hardwareRefetch();
        });
      }
    ) as IColumn[];
  }, [system, detailedStation.old_id, hardwareRefetch, isSmallScreen]);

  const tableContent = () => {
    return (
      <>
        <PaginationTable
          exportData={exportHardwareData}
          onExport={() => setExportHardwareData(false)}
          buttons={[
            {
              label: 'Export',
              onClick: () => setExportHardwareData(true),
              startIcon: <FileDownloadIcon />,
            },
          ]}
          isLoading={isLoadingHardware || isRefetchingHardware}
          sortDirection={hardwareSortConfig.direction}
          sortBy={hardwareSortConfig.key}
          onSortChange={(item) => {
            setHardwareSortConfig(item as ISortConfig);
            setHardwareLastId(null);
            setPageHardware(0);
            setRowsPerPageHardware(10);
            setHardwareAtLocation([]);
          }}
          items={hardwareAtLocation || []}
          noDataText={'No hardware at this location'}
          onClickReturnRow
          showFilter={false}
          onRowCLick={(row: IHardware) => rowClick('hardware', row)}
          columns={columnsHardware}
          rowsPerPage={rowsPerPageHardware}
          page={pageHardware}
          onPageChange={(page, pageN: number) => {
            const pageNumberAux = pageN + 1;
            if (hardwareAtLocation?.length / rowsPerPageHardware < pageNumberAux) {
              setHardwareLastId(hardwareAtLocation[hardwareAtLocation.length - 1].id);
            }
            setPageHardware(pageN);
          }}
          onRowsPerPageChange={(rowsPerPage) =>
            setRowsPerPageHardware(Number(rowsPerPage.target.value))
          }
          searchText={searchHardware}
          searchPlaceholder={'Search Hardware'}
          handleSearch={(search) => setSearchHardware(search.target.value)}
        />
      </>
    );
  };

  const tableRateContent = () => {
    const { rates } = detailedStation;

    return (
      <>
        <PaginationTable
          exportData={exportRateData}
          onExport={() => setExportRateData(false)}
          csvFileName={'Location_Rates.csv'}
          buttons={[
            {
              label: 'Export',
              onClick: () => setExportRateData(true),
              startIcon: <FileDownloadIcon />,
            },
          ]}
          items={rates || []}
          noDataText={'No rates for this location'}
          customKey={'gid'}
          onRowCLick={(gid: string) => history.push(`/billing/rates/${gid}`)}
          columns={[
            {
              id: 'name',
              label: 'Name',
              shouldExport: true,
            },
            {
              id: 'rate',
              label: 'Rate',
              shouldExport: true,
              format: (row: IRate) => getPricingString({ ...row, base: row.base_rate }),
            },
          ]}
          rowsPerPage={rowsPerPageRate}
          page={pageRate}
          onPageChange={(page, pageN: number) => setPageRate(pageN)}
          onRowsPerPageChange={(rowsPerPage) =>
            setRowsPerPageRate(Number(rowsPerPage.target.value))
          }
          searchText={searchRate}
          searchPlaceholder={'Search Rates'}
          handleSearch={(search) => setSearchRate(search.target.value)}
        />
      </>
    );
  };

  const getSecondContent = () => {
    return (
      <>
        <CustomMapComponent system={system} modeInfo={modeInfo} />
        {currentTab !== 'rentals' && (
          <CardView
            isLoading={isLoadingStations}
            content={tableContent()}
            title={`${modeInfo.details.pluralUnits || 'Units'} at this ${
              modeInfo.details.stationsTitle || 'Location'
            }`}
          />
        )}
        {currentTab === 'general' && system.new_hardware && (
          <CardView
            isLoading={isLoadingStations}
            content={tableRateContent()}
            title={`Rates at this ${modeInfo.details.stationsTitle || 'Location'}`}
          />
        )}
      </>
    );
  };

  const modal = () => {
    const { stationsTitle, unitsTitle } = modeInfo.details;
    return (
      <>
        <MovaticCustomModal
          open={retireModal}
          onClose={() => setRetireModal(!retireModal)}
          title={`Retire ${stationsTitle}?`}
          content={`Are you sure you would like to retire this ${stationsTitle.toLowerCase()}?
          ${
            activeHardware
              ? ` Any ${unitsTitle.toLowerCase()} at this ${stationsTitle.toLowerCase()} will need to be assigned to a new ${stationsTitle.toLowerCase()} in order to be accessible to users again.`
              : ''
          }`}
          action={
            <IconButtonMenu
              buttons={[
                {
                  label: 'Cancel',
                  onClick: () => setRetireModal(!retireModal),
                },
                {
                  label: 'Save',
                  onClick: () => {
                    CustomAsyncToast({
                      promise: () => retireStation(detailedStation.id),
                      successMessage: () => `${stationsTitle} retired successfully`,
                      errorMessage: `Error retiring ${stationsTitle}`,
                      loadingMessage: `Retiring ${stationsTitle}...`,
                    }).then((r) => {
                      if (r) {
                        history.push('/locations');
                      }
                    });
                  },
                  startIcon: <SaveIcon />,
                },
              ]}
            />
          }
          customStyles={undefined}
          useContentDividers={undefined}
        />
      </>
    );
  };

  const LocationStats = React.memo(
    ({ location_uuid, system_gid }: { location_uuid: string; system_gid: string }) => {
      const { data: stationStats } = useGetStationStats(
        { location_uuid, system_gid },
        { enabled: !!location_uuid && !!system_gid }
      );

      return (
        <div data-id="station-stats">
          <PropertyListItem divider label="Rentals started">
            {stationStats?.total_rentals_started ? (
              <>{stationStats.total_rentals_started}</>
            ) : (
              <SeverityPill color={'info'}>N/A</SeverityPill>
            )}
          </PropertyListItem>
          <PropertyListItem divider label="Rentals ended">
            {stationStats?.total_rentals_ended ? (
              <>{stationStats.total_rentals_ended}</>
            ) : (
              <SeverityPill color={'info'}>N/A</SeverityPill>
            )}
          </PropertyListItem>
          <PropertyListItem divider label="Rentals started/User (avg)">
            {stationStats?.avg_rental_started_per_user ? (
              <>{stationStats.avg_rental_started_per_user}</>
            ) : (
              <SeverityPill color={'info'}>N/A</SeverityPill>
            )}
          </PropertyListItem>
          <PropertyListItem label="Rentals ended/User (avg)">
            {stationStats?.avg_rental_ended_per_user ? (
              <>{stationStats.avg_rental_ended_per_user}</>
            ) : (
              <SeverityPill color={'info'}>N/A</SeverityPill>
            )}
          </PropertyListItem>
        </div>
      );
    }
  );

  function GeneralInfo() {
    const {
      capacity,
      banner_id,
      lat,
      lng,
      state,
      module,
      reservations,
      maxReservations,
      configuration,
      zone_type,
      parking_credit,
      slow_zone_speed,
      radius,
      prevent_ending_rentals,
      prevent_starting_rentals,
      available_hardware,
      private: isPrivate,
    } = detailedStation;
    const { currentMode } = modeInfo;

    const unitsName = currentMode === 'bike_locker' ? 'Lockers' : 'Racks';
    const { WAREHOUSE, RACK, STORE, ZONE } = stationConfigurations;
    const locationsHideBannerImg = [WAREHOUSE, STORE, ZONE];
    const hideBannerImg = locationsHideBannerImg.includes(configuration);
    const banner =
      stationImages?.filter((image: { id: string }) => image.id === banner_id) || [];
    // @ts-ignore
    const { name: statusName, color: statusColor } = stateFormatter(state);
    return (
      <PropertyList>
        {configuration && (
          <PropertyListItem
            divider
            label="Location Type"
            value={
              configuration
                ? configuration === RACK
                  ? 'Station'
                  : toTitleCase(configuration)
                : ''
            }
          />
        )}
        <PropertyListItem
          divider
          label="Access"
          value={isPrivate ? LocationAccess.Private : LocationAccess.Public}
        />
        {configuration === ZONE ? (
          <>
            <Divider />
            <PropertyListItem label="Zone Type" value={zoneTypeFormatter(zone_type)} />
          </>
        ) : null}
        {configuration === ZONE ? null : (
          <PropertyListItemLink
            label="Lat/Long"
            text={`${lat}, ${lng}`}
            onClick={() => openGoogleMapsCoordinates(lat, lng)}
          />
        )}
        {configuration === ZONE ? (
          <>
            <Divider />
            <PropertyListItem
              label="Speed Limit"
              value={
                Number.isNaN(slow_zone_speed) || !slow_zone_speed
                  ? 'None'
                  : `${slow_zone_speed} km/h`
              }
            />
          </>
        ) : null}
        {![ZONE].includes(configuration) ? (
          <>
            <Divider />
            <PropertyListItem
              label="Radius"
              value={!radius ? 'None' : `${radius} ${radius === 1 ? 'Meter' : 'Meters'}`}
            />
          </>
        ) : null}
        <Divider />
        <PropertyListItem label="Status" value={statusName}>
          <SeverityPill color={statusColor}>{statusName}</SeverityPill>
        </PropertyListItem>
        {configuration === RACK ||
        configuration === WAREHOUSE ||
        currentMode === 'bike_locker' ||
        currentMode === 'bikeshare(keybox)' ||
        currentMode === 'bikeRoom' ? (
          <>
            <Divider />
            <PropertyListItem label={`Number of ${unitsName}`}>
              <SeverityPill
                color={
                  capacity && capacity > 0 ? 'success' : !capacity ? 'success' : 'warning'
                }
              >
                {capacity || 'Unlimited'}
              </SeverityPill>
            </PropertyListItem>
          </>
        ) : null}
        <Divider />
        <PropertyListItem label="Availability" value={available_hardware || 0}>
          <SeverityPill
            color={available_hardware && available_hardware > 0 ? 'success' : 'warning'}
          >
            {available_hardware || 0}
          </SeverityPill>
        </PropertyListItem>
        {currentMode === 'bikeRoom' ? (
          <>
            <Divider />
            <PropertyListItemLink
              to={`/units/locks/${module}`}
              text={module || 'None'}
              label={'Lock'}
            />
          </>
        ) : null}
        {system.reservations && movaticAccess && !partner ? (
          <div>
            <Divider />
            <PropertyListItem
              label="Allow Reservations"
              value={reservations !== null && reservations ? 'Yes' : 'No'}
            />
            {reservations ||
            (reservations === null &&
              system.reservations &&
              movaticAccess &&
              !partner) ? (
              <>
                <Divider />
                <PropertyListItem
                  label="Max Reservations"
                  value={
                    maxReservations ||
                    (system.maxReservations ? system.maxReservations : 'None')
                  }
                />
              </>
            ) : null}
          </div>
        ) : null}
        {parking_credit ? (
          <>
            <Divider />
            <PropertyListItem
              label="Parking Credit"
              value={convertCentsToMoney(parking_credit)}
            />
          </>
        ) : null}
        {!hideBannerImg && (
          <>
            <Divider />
            <PropertyListItem label="Banner Image">
              {banner.length > 0 ? (
                <div className="imageContainer">
                  {banner.length > 0 ? (
                    !banner[0].approved_on && !banner[0].rejected ? (
                      <i className="fa fa-hourglass-half fa-fw navIconStyle navIcon pull-right" />
                    ) : banner[0].rejected ? (
                      <i className="fa fa-ban fa-fw navIconStyle navIcon pull-right" />
                    ) : banner[0].approved_on ? (
                      <i className="fa fa-check-circle fa-fw navIconStyle navIcon pull-right">
                        {' '}
                      </i>
                    ) : null
                  ) : null}
                  <img
                    alt=""
                    className="banner_image"
                    src={`data:image/png;base64,${banner[0].image}`}
                  />
                  <div className="stationOverlay">
                    <div className="stationText imageText">
                      {!banner[0].approved_on && !banner[0].rejected
                        ? 'Image is being reviewed by Movatic.'
                        : banner[0].rejected
                        ? 'Image has been rejected, due to poor quality or due to cropping issues.'
                        : banner[0].approved_on
                        ? 'Image has been approved.'
                        : null}
                    </div>
                  </div>
                </div>
              ) : (
                <NoFilePlaceHolder />
              )}
            </PropertyListItem>
          </>
        )}
        <Divider />
        {[
          {
            label: 'Prevent ending rentals',
            key: 'preventEndingRentals',
            item: prevent_ending_rentals ? 'Yes' : 'No',
          },
          {
            label: 'Prevent starting rentals',
            key: 'preventStartingRentals',
            item: prevent_starting_rentals ? 'Yes' : 'No',
          },
        ].map(({ item, key, label }) => (
          <PropertyListItem divider key={key} label={label} value={item}>
            <SeverityPill color={item === 'Yes' ? 'warning' : 'success'}>
              {item}
            </SeverityPill>
          </PropertyListItem>
        ))}
      </PropertyList>
    );
  }

  return (
    <PageContainer warningMessage={warningCheck()} isLoading={isLoadingStations}>
      <>
        <PageContent
          backLink={'/locations'}
          chipLabel={modeInfo.details.stationsTitle || 'Location'}
          contentTitle={'Locations'}
          itemName={toTitleCase(detailedStation?.name)}
          itemChipValue={detailedStation?.id}
          detailIcon={
            detailedStation?.configuration ? (
              getLocationIconByName(detailedStation?.configuration).icon
            ) : (
              <LocationOnIcon />
            )
          }
          itemActions={null}
          itemTopButtons={<IconButtonMenu buttons={button()} />}
          handleTabsChange={handleTabsChange}
          currentTabV={currentTabV}
          tabsList={[
            { label: 'Details', value: 'general' },
            { label: 'Rentals', value: 'rentals' },
          ]}
          tabsChildren={
            <>
              {currentTabV === 'general' && (
                <Grid container spacing={2}>
                  <Grid xs={12} lg={4}>
                    <Stack spacing={2}>
                      <CardView
                        isLoading={isLoadingStations}
                        content={<GeneralInfo />}
                        title={'Location Details'}
                      />
                      <CardView
                        isLoading={isLoadingStations}
                        content={
                          <LocationStats
                            location_uuid={detailedStation.id}
                            system_gid={system.gid}
                          />
                        }
                        title={'Location Stats'}
                      />
                    </Stack>
                  </Grid>
                  <Grid xs={12} lg={8}>
                    <Stack spacing={2}>{getSecondContent()}</Stack>
                  </Grid>
                </Grid>
              )}
              {currentTab === 'rentals' && (
                <>
                  <RentalsTableCard
                    noRentalsText={
                      'This user does not have any rentals matching the criteria'
                    }
                    parameters={{ station_id: stationId }}
                  />
                </>
              )}
            </>
          }
        />
        {modal()}
      </>
    </PageContainer>
  );
};

export default connect(
  (state: {
    admin: {
      systemAccess: number;
      admin: {
        admin: {
          movaticAccess: number;
          partner: number;
        };
      };
    };
    system: {
      current: ISystem;
      isLoaded: boolean;
    };
    mode: IMode;
    module: {
      modules: { mac: string; state: number }[];
    };
  }) => ({
    systemAccess: state.admin.systemAccess,
    system: state.system.current,
    modeInfo: state.mode,
    systemLoaded: state.system.isLoaded,
    partner: state.admin.admin.admin.partner,
    movaticAccess: state.admin.admin.admin.movaticAccess,
  }),
  () => ({})
)(StationDetails);
