import React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import { MyButtonCard } from './button-card';
import { CardHeader, useTheme } from '@mui/material';

const RoundedIconAvatar = ({ icon }: { icon: React.ReactNode }) => {
  const theme = useTheme();
  return (
    <>
      <MyButtonCard sx={{ backgroundColor: 'inherit', color: 'inherit' }}>
        <CardHeader
          sx={{ p: 2, pr: 3 }}
          action={
            <Box
              component={ButtonBase}
              sx={{
                alignItems: 'center',
                display: 'flex',
                borderWidth: 2,
                borderStyle: 'solid',
                borderColor: 'divider',
                height: 40,
                width: 40,
                borderRadius: '50%',
              }}
            >
              <Avatar
                sx={{
                  height: 32,
                  width: 32,
                  bgcolor: theme.palette.secondary.main,
                  color: '#fff',
                }}
              >
                {icon}
              </Avatar>
            </Box>
          }
        />
      </MyButtonCard>
    </>
  );
};

export default RoundedIconAvatar;
