import {
  baseUrl,
  getCredentials,
  fetchOptions,
  fetchOptionsV2,
  fetchPostOptions,
} from './http';
import { displayError } from '../utils';
import CustomToast from '../components/Redesing/custom-toast';

export const getUser = (phone) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);

  return fetch(`${baseUrl}/user/${phone}`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        return response.message;
      }
      throw new Error(response?.message);
    })
    .catch(() => {
      displayError(
        'Oops! Looks like there was an error while getting the user. Please try again later.'
      );
      return null;
    });
};

export const checkUser = (phone) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);

  return fetch(`${baseUrl}/user/check-existing-user/${phone}`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      displayError(`There was an error while validating the user. ${error}`, 'error');
    });
};

export const addPublicUser = (body) => {
  const fetchOps = fetchOptionsV2(null, null, 'POST', body);
  return fetch(`${baseUrl}/user/create-public-user`, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        return results?.message;
      } else {
        throw new Error(results.message);
      }
    })
    .catch((error) => {
      CustomToast({
        type: 'error',
        message: `There was an error while adding your user. ${error}`,
      });
    });
};

export const addUser = (userInfo) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, userInfo, 'POST');
  return fetch(`${baseUrl}/user`, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        return results.message;
      } else {
        displayError(results.message);
      }
    })
    .catch(() => {
      displayError(`There was an error while adding the user. Please try again!`);
    });
};

export const updateUserRfidCard = (body) => {
  const { cardId, cardPayload } = body;
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, cardPayload, 'put');
  return fetch(`${baseUrl}/user/rfid/${cardId}/edit`, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        return results;
      }
      throw new Error(results?.message);
    })
    .catch((e) => {
      return Promise.reject(`${e}`);
    });
};

export const getOneRfidCard = (cardId) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);

  return fetch(`${baseUrl}/user/rfid/${cardId}`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        return response.message;
      }
      throw new Error(response?.message);
    })
    .catch(() => {
      displayError(
        `There was an error while updating the user RFID card. Please try again!`
      );
    });
};

export const addUserRfidCard = (cardInfo) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, cardInfo, 'POST');
  return fetch(`${baseUrl}/user/${cardInfo.userId}/rfid`, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        return results.message;
      }
      throw new Error(results.message);
    })
    .catch(() => {
      displayError('There was an error while adding the RFID card. Please try again!');
    });
};

export const getUsers = () => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);
  return fetch(`${baseUrl}/user`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        return response.message;
      }
      return null;
    })
    .catch((e) => {
      displayError(`There was an issue getting all your users ${e}`);
    });
};

export const updateUser = (userInfo) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, userInfo, 'PUT');
  return fetch(`${baseUrl}/user/${userInfo.id}`, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        return results.message;
      }
      throw new Error(results?.message);
    })
    .catch(() => {
      displayError('There was an error while updating the user. Please try again!');
    });
};

export const addUsersFromCsv = (usersFromCsv) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(
    adminEmail,
    adminToken,
    systemId,
    { usersFromCsv },
    'POST'
  );

  return fetch(`${baseUrl}/user/createviacsv`, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        if (Array.isArray(results.message)) {
          const rowsWithErrors = results.message.map((message) => message.row);
          displayError(`Errors in rows: ${rowsWithErrors.toString()}`);
        }
        return results.message;
      }
      throw new Error(results?.message);
    })
    .catch(() => {
      displayError('There was an error while adding the users. Please try again!');
    });
};

export const addUserWalletCredit = (creditInfo) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, creditInfo, 'POST');
  return fetch(`${baseUrl}/wallet/credit`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results.status === 200) {
        return results.message;
      }
      throw new Error(results.message);
    })
    .catch(() => {
      displayError(
        'Oops! There was an error while adding the wallet credit. Please try again!'
      );
    });
};

export const getUserCardInformation = (userPhoneNumber) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);
  return fetch(`${baseUrl}/user/${userPhoneNumber}/card`, fetchOps)
    .then((results) => results.json())
    .then((paymentThings) => {
      if (paymentThings.status === 200) {
        return paymentThings.message;
      }
      throw new Error(paymentThings.message);
    })
    .catch((error) => {
      displayError(
        `There was an error while getting the user card information. ${error}`
      );
    });
};

export const getUserWalletInformation = (userPhoneNumber) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);
  return fetch(`${baseUrl}/wallet/foruser/${userPhoneNumber}`, fetchOps)
    .then((results) => results.json())
    .then((paymentThings) => {
      if (paymentThings.status === 200) {
        return paymentThings.message;
      }
    })
    .catch((error) => {
      displayError(
        `There was an error while getting the user wallet information. ${error}`
      );
    });
};

export const banUser = (userPhone) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, null, 'PUT');
  return fetch(`${baseUrl}/user/blacklist/${userPhone}`, fetchOps)
    .then((res) => res.json())
    .then((response) => {
      if (response.status === 200) {
        return response.message;
      } else {
        throw new Error();
      }
    })
    .catch(() => {
      displayError('There was an issue banning this user. Please try again');
    });
};

export const authorizeUser = (userPhone) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId, 'PUT');
  return fetch(`${baseUrl}/user/unblacklist/${userPhone}`, fetchOps)
    .then((response) => response.json())
    .then((data) => {
      if (data.status === 200) {
        return data.message;
      } else {
        throw new Error();
      }
    })
    .catch((e) => {
      displayError(`There was an issue authorizing this user. ${e}`);
    });
};

export const clearUserBalance = (userPhone) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId, 'PUT');
  return fetch(`${baseUrl}/user/clearbalance/${userPhone}`, fetchOps)
    .then((res) => res.json())
    .then((response) => {
      if (response.status === 200) {
        return response.message;
      } else {
        throw new Error();
      }
    })
    .catch(() => {
      return Promise.reject();
    });
};

export const updateUserDelinquency = (userPhoneNumber) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId, 'PUT');
  return fetch(`${baseUrl}/user/updatedelinquency/${userPhoneNumber}`, fetchOps)
    .then((results) => results.json())
    .then((response) => {
      if (response.status === 200) {
        return response.message;
      }
      throw new Error(response.message);
    })
    .catch((error) => {
      return Promise.reject(`${error}`);
    });
};

export const getUserStripeSetupIntent = (userPhone) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(
    adminEmail,
    adminToken,
    systemId,
    { userPhone },
    'POST'
  );
  return fetch(`${baseUrl}/billing/setupintent`, fetchOps)
    .then((results) => results.json())
    .then((response) => {
      if (response.status === 200) {
        return response.message;
      }
      throw new Error(response.message);
    })
    .catch((error) => {
      displayError(`There was an error while getting a Stripe setup intent. ${error}`);
      return null;
    });
};

export const getUserRfidCards = (userId) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);
  return fetch(`${baseUrl}/user/${userId}/rfid`, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        return results.message;
      }
      throw new Error(results.message);
    })
    .catch(() => {
      displayError(
        'There was an error while getting the user RFID cards. Please try again!'
      );
    });
};

export const archiveUserRfidCard = (cardId) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId, 'PUT');
  return fetch(`${baseUrl}/user/rfid/${cardId}/archive`, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        return results;
      }
      throw new Error(results.message);
    })
    .catch((e) => {
      return Promise.reject(`${e}`);
    });
};

export const sendTosEmail = (userId) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, { userId }, 'POST');

  return fetch(`${baseUrl}/user/send-accept-tos-email`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results.status === 200) {
        return results;
      }
      throw new Error(results.message);
    })
    .catch((e) => {
      return Promise.reject(`${e}`);
    });
};

export const acceptSystemTos = (body) => {
  const fetchOps = fetchOptionsV2(null, null, 'POST', body);

  return fetch(`${baseUrl}/user/accept-system-tos`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results.status === 200) {
        return results.message;
      }
      throw new Error(results.message);
    });
};
