import React, { Fragment } from 'react';
import { Box, Paper, Typography } from '@mui/material';

interface LegendItem {
  label: string;
  color: string;
}

interface InterfaceDashboarMapLegend {
  title: string;
  legendItems: LegendItem[];
}

const DashboardMapLegend = ({ legendItems, title }: InterfaceDashboarMapLegend) => {
  return (
    <Box display={'flex'} justifyContent={'center'}>
      <Paper variant="outlined">
        <div data-id={`routes-legend`} className={'leaflet-legend'}>
          <Typography
            fontSize={15}
            fontFamily={'"Montserrat", sans-serif'}
            variant={'h6'}
          >
            {title}
          </Typography>
          {legendItems.map((item) => {
            return (
              <Fragment key={`leyend-item-${item.label}`}>
                <div
                  style={{
                    background: `${item.color}`,
                    width: '18px',
                    height: '18px',
                    float: 'left',
                    marginRight: '8px',
                    opacity: '0.7',
                    marginBottom: '1',
                  }}
                />
                <span>{item.label}</span>
                <br />
              </Fragment>
            );
          })}
        </div>
      </Paper>
    </Box>
  );
};

export default DashboardMapLegend;
