import React from 'react';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';

interface ColumnAvatarProps {
  avatar: string | React.ReactNode;
  firstRow: string | React.ReactNode;
  secondRow?: string | React.ReactNode;
  avatarColor?: string;
  noEllipsis?: boolean;
}

const ColumnAvatar: React.FC<ColumnAvatarProps> = (props) => {
  const { avatar, firstRow, secondRow, avatarColor, noEllipsis = false } = props;
  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      <Avatar
        sx={{
          color: '#fff',
          bgcolor: avatarColor ? `${avatarColor}` : '#F58964',
          height: 42,
          width: 42,
        }}
      >
        {avatar}
      </Avatar>
      <div>
        <div
          style={
            !noEllipsis
              ? {
                  display: '-webkit-box',
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  whiteSpace: 'pre-wrap',
                  textOverflow: 'ellipsis',
                  overflowWrap: 'break-word',
                  paddingLeft: '8px',
                }
              : {}
          }
        >
          {firstRow}
        </div>
        <div>{secondRow}</div>
      </div>
    </Stack>
  );
};

export default ColumnAvatar;
