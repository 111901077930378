import React, {
  ChangeEvent,
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { CSVLink } from 'react-csv';
import SortIcon from '@mui/icons-material/Sort';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Grid, IconButton, TableContainer, Typography } from '@mui/material';
import {
  ButtonInfo,
  Filters,
  IColumn,
  ISortConfig,
} from '../../../ts/interface/components/redesing/table.interface';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import MovaticCustomModal from '../../Modal/MovaticCustomModal';
import Chip from '@mui/material/Chip';
import { SearchChip } from '../../../ts/interface/components/table.interface';
import IconButtonMenu from '../icon-button-menu';
import PageLoader from '../page-loader';
import useSmallScreen from '../../../hooks/useSmallScreen';
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants';

interface CustomerListTableProps {
  columns: IColumn[];
  dataId?: string;
  items?: any[];
  onDeselectAll?: () => void;
  onDeselectOne?: (customerId: string) => void;
  onPageChange?: (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  onRowsPerPageChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onSelectAll?: () => void;
  onSelectOne?: (customerId: string) => void;
  page?: number;
  rowsPerPage?: number;
  selected?: string[];
  sortBy?: string;
  searchText?: string;
  showSelection?: boolean;
  showActions?: boolean;
  actionButtons?: ButtonInfo[];
  format?: (value: any) => string;
  onSortChange?: (item: { key: string; direction: string }) => void;
  handleSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  buttons?: ButtonInfo[];
  showExport?: boolean;
  searchPlaceholder?: string;
  onRowCLick?: (arg0: any) => void;
  padding?: { p?: number; pt?: number; pb?: number; pl?: number; pr?: number };
  customKey?: string;
  avatar?: string;
  localSearch?: boolean;
  onApplyFilter?: () => void;
  onDateFilterChange?: (filters: Filters) => void;
  filters?: Filters;
  csvFileName?: string;
  noDataText?: string | React.ReactNode;
  onClickReturnRow?: boolean;
  sortDirection?: 'asc' | 'desc';
  noRemoteSort?: boolean;
  otherProps?: any;
  showFilter?: boolean;
  spaceFilters?: React.ReactNode;
  chips?: SearchChip[];
  quickFilters?: React.ReactNode;
  handleChipDelete?: (chip: SearchChip) => void;
  exportData?: boolean;
  onExport?: () => void;
  isLoading?: boolean;
  totalItems?: number;
  hasMore?: boolean;
  getSelectedRow?: (row: any) => boolean;
  disableHasMore?: boolean;
  noDataSlice?: boolean;
}

export const PaginationTable: FC<CustomerListTableProps> = (props) => {
  const {
    columns,
    items = [],
    onDeselectAll,
    onDeselectOne,
    onPageChange = () => {},
    onRowsPerPageChange,
    onSelectAll,
    onSelectOne,
    page: pageProp = 0,
    rowsPerPage: rowsPerPageProp = DEFAULT_ROWS_PER_PAGE,
    selected = [],
    sortBy = '',
    searchText = '',
    showSelection = false,
    showActions = false,
    actionButtons = [],
    onSortChange,
    handleSearch,
    buttons = [],
    showExport = false,
    searchPlaceholder = 'Search',
    onRowCLick,
    padding = { pl: 0, pb: 1 },
    customKey = 'id',
    localSearch = true,
    noRemoteSort = false,
    csvFileName = 'data.csv',
    noDataText = 'No data available',
    onClickReturnRow = false,
    sortDirection = 'desc',
    otherProps,
    showFilter = true,
    spaceFilters,
    chips = [],
    exportData = false,
    handleChipDelete,
    onExport,
    onApplyFilter,
    isLoading = false,
    totalItems,
    quickFilters,
    dataId,
    hasMore,
    getSelectedRow,
    disableHasMore = false,
    noDataSlice = false,
  } = props;

  const [sortConfig, setSortConfig] = useState<ISortConfig | null>({
    key: sortBy,
    direction: sortDirection,
  });

  const selectedSome = selected.length > 0 && selected.length < items.length;
  const selectedAll = items.length > 0 && selected.length === items.length;
  const enableBulkActions = selected.length > 0;
  const [sortByState, setSortByState] = useState<string>('');
  const searchTextState: string = useMemo(() => {
    return searchText;
  }, [searchText]);
  const itemsState = useMemo(() => {
    return items;
  }, [items]);
  const queryRef = useRef<HTMLInputElement | null>(null);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const isSmallScreen = useSmallScreen();

  const page = useMemo(() => {
    return pageProp;
  }, [pageProp]);

  const rowsPerPage = useMemo(() => {
    return rowsPerPageProp;
  }, [rowsPerPageProp]);

  useEffect(() => {
    setSortByState(sortBy);
  }, [sortBy]);

  const getNestedValue = useCallback((obj: any, path: string) => {
    const keys = path.split('.');
    return keys.reduce(
      (value, key) => (value && value[key] !== undefined ? value[key] : ''),
      obj
    );
  }, []);

  const compareValues = useCallback(
    (aValue: any, bValue: any, direction: 'asc' | 'desc') => {
      // Convert date strings to Date objects for proper comparison
      const dateA = aValue instanceof Date ? aValue : new Date(aValue);
      const dateB = bValue instanceof Date ? bValue : new Date(bValue);

      // Check if both values are dates, and if not, use the default comparison
      if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
        if (dateA < dateB) {
          return direction === 'asc' ? -1 : 1;
        }
        if (dateA > dateB) {
          return direction === 'asc' ? 1 : -1;
        }
        return 0;
      }

      // If not both values are dates, use default comparison
      if (aValue < bValue) {
        return direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    },
    []
  );

  const getSortedData = useCallback(
    (data: any[]) => {
      if (sortByState && compareValues && getNestedValue) {
        return [...data].sort((a, b) => {
          const aValue = getNestedValue(a, sortByState);
          const bValue = getNestedValue(b, sortByState);

          // Convert date strings to Date objects for proper comparison
          const dateA = aValue instanceof Date ? aValue : new Date(aValue);
          const dateB = bValue instanceof Date ? bValue : new Date(bValue);

          // Check if both values are dates, and if not, use the default comparison
          if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
            return compareValues(dateA, dateB, sortConfig?.direction || 'asc');
          }

          // If not both values are dates, use default comparison
          return compareValues(aValue, bValue, sortConfig?.direction || 'asc');
        });
      } else {
        return data;
      }
    },
    [sortByState, compareValues, getNestedValue, sortConfig?.direction]
  );

  const handleExportCSVV2 = useCallback(() => {
    const sortedData = getSortedData(itemsState);
    const csvData = sortedData.map((item) =>
      columns
        .filter((column) => !column.shouldNotExport)
        .map((column) => {
          if (column.exportFormat) {
            return column.exportFormat(item);
          }
          return item[column.id];
        })
    );
    const csvHeaders = columns
      .filter((column) => !column.shouldNotExport)
      .map((column) => column.label);
    const csvContent =
      csvHeaders.join(',') + '\n' + csvData.map((row) => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = csvFileName;
    link.click();
    window.URL.revokeObjectURL(url);
    onExport?.();
  }, [onExport, columns, csvFileName, getSortedData, itemsState]);

  useEffect(() => {
    if (exportData) {
      handleExportCSVV2();
    }
  }, [exportData, handleExportCSVV2]);

  const handleExportCSV = () => {
    const sortedData = getSortedData(itemsState);
    const csvData = sortedData.map((item) =>
      columns
        .filter((column) => !column.shouldNotExport)
        .map((column, index) => {
          if (column.format) {
            return column.format(item, index);
          }
          return item[column.id];
        })
    );

    const csvHeaders = columns
      .filter((column) => !column.shouldNotExport)
      .map((column) => column.label);

    const csvReport = {
      data: csvData,
      headers: csvHeaders,
      filename: csvFileName,
    };

    // Use CSVLink to trigger the download
    return (
      <div
        style={{
          paddingRight: '8px',
        }}
      >
        <CSVLink {...csvReport} target="_blank">
          Export CSV
        </CSVLink>
      </div>
    );
  };

  const countMemo = useMemo(() => {
    const count = totalItems || (itemsState ? itemsState.length : 0);
    const labelDisplayedRows = ({
      from,
      to,
      count,
    }: {
      from: number;
      to: number;
      count: number;
    }) => {
      const resultsText = count > 10000 ? 'over 10,000' : count;
      const viewingText = isSmallScreen ? '' : 'Viewing ';
      return `${viewingText}${from}-${to === -1 ? count : to} of ${resultsText} results`;
    };
    return {
      count,
      labelDisplayedRows,
    };
  }, [totalItems, itemsState, isSmallScreen]);

  const handleSort = (columnId: string) => {
    let direction: 'asc' | 'desc' = 'asc';

    if (sortConfig && sortConfig.key === columnId && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key: columnId, direction });
    onPageChange(null, 0);
    onSortChange?.({ key: columnId, direction });
  };

  const paginatedData = useMemo(() => {
    const startSlice = page * rowsPerPage;
    const endSlice = startSlice + rowsPerPage;
    let filteredItems = itemsState;
    if (searchTextState && localSearch) {
      filteredItems = itemsState.filter((item, index) =>
        columns
          .filter((column) => column.searchable)
          .some((column) => {
            const columnValue = getNestedValue(item, column.id);
            const formattedColumnValue =
              column.format && column.useFormatInSearch
                ? column.format(item, index)
                : columnValue;

            const searchValue = searchTextState.toLowerCase().trim();
            const containsSearchText = (value: string | number) =>
              value?.toString()?.toLowerCase()?.includes(searchValue);

            return (
              containsSearchText(formattedColumnValue) || containsSearchText(columnValue)
            );
          })
      );
    }
    const sortedData =
      localSearch || noRemoteSort ? getSortedData(filteredItems) : filteredItems;
    return !noDataSlice ? sortedData.slice(startSlice, endSlice) : sortedData;
  }, [
    page,
    rowsPerPage,
    searchTextState,
    localSearch,
    noRemoteSort,
    itemsState,
    columns,
    getSortedData,
    getNestedValue,
    noDataSlice,
  ]);

  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        flexWrap="wrap"
        spacing={1}
        sx={{ pl: 1 }}
      >
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent={isSmallScreen ? 'center' : 'flex-start'}
          sx={padding}
        >
          <Grid
            item
            xs={showFilter ? 12 : 10}
            sm={showFilter ? 12 : 8}
            md={showFilter ? 5 : 7}
            lg={showFilter ? 3 : 6}
          >
            <Box component="form" pr={1}>
              <OutlinedInput
                sx={{ maxHeight: '36px', fontSize: 'small' }}
                onChange={handleSearch}
                defaultValue=""
                fullWidth
                value={searchTextState}
                inputProps={{ ref: queryRef }}
                placeholder={searchPlaceholder}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </Box>
          </Grid>
          {showFilter && (
            <Grid item xs={6} sm={2} md={2} lg={4}>
              <Grid container spacing={1}>
                {showFilter && (
                  <Grid item id={`${dataId}-filters`}>
                    <IconButtonMenu
                      buttons={[
                        {
                          label: 'Filters',
                          onClick: () => setOpenFilterModal(!openFilterModal),
                          startIcon: <FilterListIcon />,
                          disabled: !spaceFilters,
                        },
                      ]}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'flex-end', // Align items to the end
            }}
            item
            xs={showFilter ? 6 : 2}
            sm={showFilter ? 10 : 4}
            md={showFilter ? 5 : 5}
            lg={showFilter ? 5 : 6}
          >
            <>
              {buttons.length > 0 && (
                <IconButtonMenu
                  buttonLimit={isSmallScreen ? 0 : 2}
                  slice={isSmallScreen ? 0 : 2}
                  showEllipsis={isSmallScreen}
                  buttons={buttons}
                  dataId="pagination-table"
                />
              )}
            </>
            {showExport && <Grid item>{handleExportCSV()}</Grid>}
          </Grid>
        </Grid>
      </Stack>
      <Grid container spacing={1} sx={{ p: 2 }} id={`${dataId}-chips`}>
        {chips.length > 0 && (
          <>
            {Object.values(
              chips.reduce((groups: any, chip) => {
                const existingGroup = groups[chip.label];
                if (existingGroup) {
                  existingGroup.values.push(chip.displayValue || chip.value);
                } else {
                  groups[chip.label] = {
                    label: chip.label,
                    values: [chip.displayValue || chip.value],
                    disabled: chip.disabled,
                  };
                }
                return groups;
              }, {})
            ).map((group: any, index) => (
              <Grid key={index} item>
                <Chip
                  disabled={group.disabled}
                  label={
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        '& span': {
                          fontWeight: 600,
                        },
                      }}
                    >
                      <>
                        <span>{group.label}</span>: {group.values.join(' | ')}
                      </>
                    </Box>
                  }
                  onDelete={(): void =>
                    handleChipDelete
                      ? handleChipDelete(group) // Delete the entire group
                      : undefined
                  }
                  variant="outlined"
                />
              </Grid>
            ))}
          </>
        )}
        {quickFilters}
      </Grid>

      {/*)}*/}
      <Box sx={{ position: 'relative' }}>
        {enableBulkActions && (
          <Stack
            direction="row"
            spacing={2}
            sx={{
              alignItems: 'center',
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? 'neutral.800' : 'neutral.50',
              display: enableBulkActions ? 'flex' : 'none',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              px: 2,
              py: 0.5,
              zIndex: 10,
            }}
          >
            <Checkbox
              checked={selectedAll}
              indeterminate={selectedSome}
              onChange={(event) => {
                if (event.target.checked) {
                  onSelectAll?.();
                } else {
                  onDeselectAll?.();
                }
              }}
            />
            <Button color="inherit" size="small">
              Delete
            </Button>
            <Button color="inherit" size="small">
              Edit
            </Button>
          </Stack>
        )}
        <TableContainer sx={{ overflowX: 'auto', overflowY: 'hidden' }} {...otherProps}>
          <Table data-id={`table-${dataId}`} size={'small'} sx={{ tableLayout: 'auto' }}>
            <TableHead>
              <TableRow>
                {showSelection && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedAll}
                      indeterminate={selectedSome}
                      onChange={(event) => {
                        if (event.target.checked) {
                          onSelectAll?.();
                        } else {
                          onDeselectAll?.();
                        }
                      }}
                    />
                  </TableCell>
                )}

                {columns
                  .filter((item) => !item.hidden)
                  .map((column) => (
                    <TableCell key={column.id} sx={{ maxWidth: '200px', py: 0.5 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {column.label}
                        {!column.notSortable && (
                          <IconButton
                            onClick={() => handleSort(column.id)}
                            size="small"
                            sx={{ marginLeft: 1 }}
                          >
                            {sortConfig?.key === column.id ? (
                              sortConfig?.direction === 'asc' ? (
                                <ArrowUpwardIcon fontSize="small" />
                              ) : (
                                <ArrowDownwardIcon fontSize="small" />
                              )
                            ) : (
                              <SortIcon fontSize="small" />
                            )}
                          </IconButton>
                        )}
                      </Box>
                    </TableCell>
                  ))}
                {showActions && <TableCell align="center">Actions</TableCell>}
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell
                    colSpan={
                      columns.filter((item) => !item.hidden).length +
                      (showSelection ? 1 : 0) +
                      (showActions ? 1 : 0)
                    }
                  >
                    <Stack
                      pt={2}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <PageLoader minHeight={'1vh'} maxSize={'10%'} />
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {paginatedData.length > 0 ? (
                  paginatedData.map((dataItem) => {
                    const isSelected = selected.includes(dataItem.id);
                    const cells = columns
                      .filter((column) => !column.hidden)
                      .map((column, index) => {
                        const {
                          id,
                          align = 'left',
                          format,
                          showToolTip = false,
                          maxWidth = '200px',
                        } = column;
                        const value = dataItem[id];
                        const formattedValue = format ? format(dataItem, index) : value;
                        return (
                          <TableCell
                            key={id}
                            align={align}
                            sx={{
                              cursor: onRowCLick ? 'pointer' : 'default',
                              maxWidth: maxWidth,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              pl: 1,
                              pr: 0,
                              py: 1,
                            }}
                            onClick={(event) => {
                              if (onRowCLick && !column.disableCLick) {
                                event.stopPropagation(); // Prevent the click event from propagating
                                onClickReturnRow
                                  ? onRowCLick(dataItem)
                                  : onRowCLick(dataItem[customKey]);
                              }
                            }}
                          >
                            {showToolTip ? (
                              <div
                                style={{
                                  display: '-webkit-box',
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: 'vertical',
                                  overflow: 'hidden',
                                  whiteSpace: 'pre-wrap',
                                  textOverflow: 'ellipsis',
                                  overflowWrap: 'break-word',
                                }}
                              >
                                <Typography variant={'body1'}>
                                  {formattedValue}
                                </Typography>
                              </div>
                            ) : (
                              <Typography variant={'body1'}>{formattedValue}</Typography>
                            )}
                          </TableCell>
                        );
                      });

                    const selectionCell = showSelection && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isSelected}
                          onChange={(event) => {
                            if (event.target.checked) {
                              onSelectOne?.(dataItem.id);
                            } else {
                              onDeselectOne?.(dataItem.id);
                            }
                          }}
                          value={isSelected}
                        />
                      </TableCell>
                    );

                    const actionButtonsCell = showActions && (
                      <TableCell
                        style={{
                          textAlign: 'center',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {actionButtons.map((button, index) => (
                          <IconButton
                            key={index}
                            //@ts-ignore
                            onClick={() => button?.onClick(dataItem[button.key])}
                          >
                            {button.startIcon}
                          </IconButton>
                        ))}
                      </TableCell>
                    );

                    return (
                      <TableRow
                        key={dataItem.id}
                        hover
                        selected={Boolean(getSelectedRow?.(dataItem))}
                      >
                        {selectionCell}
                        {cells}
                        {actionButtonsCell}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={
                        columns.filter((item) => !item.hidden).length +
                        (showSelection ? 1 : 0) +
                        (showActions ? 1 : 0)
                      }
                    >
                      <Typography variant="subtitle1" align="center">
                        {noDataText}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
          <TablePagination
            component="div"
            count={countMemo.count}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            nextIconButtonProps={
              localSearch || disableHasMore ? {} : { disabled: !hasMore }
            }
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            labelDisplayedRows={countMemo.labelDisplayedRows}
            sx={{
              overflow: 'unset',
              maxHeight: '48px',
              '& .MuiTablePagination-select': {
                marginRight: 0,
              },
              '& .MuiTablePagination-selectLabel': {
                marginTop: 1,
              },
              '& .MuiTablePagination-displayedRows': {
                marginTop: 1.3,
              },
              '& .MuiToolbar-root-MuiTablePagination-toolbar': {
                pr: 0,
              },
              '& .MuiTablePagination-actions': {
                marginLeft: isSmallScreen ? 0 : 0.5,
              },
            }}
          />
        </TableContainer>
        <MovaticCustomModal
          dataId={'filter-modal'}
          customStyles={{
            title: {
              p: 2,
            },
            action: {
              p: -2,
            },
            content: {
              p: 2,
              // minWidth: '400px',
            },
          }}
          open={openFilterModal}
          onClose={() => setOpenFilterModal(!openFilterModal)}
          content={spaceFilters}
          title={'Add Filters'}
          action={
            <IconButtonMenu
              buttons={[
                {
                  label: 'Apply',
                  startIcon: <FilterListIcon />,
                  onClick: () => {
                    onApplyFilter?.();
                    setOpenFilterModal(!openFilterModal);
                  },
                },
              ]}
            />
          }
        />
      </Box>
    </>
  );
};
