import FormItem from './form-item';
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  Link,
  Switch,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import React, { SyntheticEvent, useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import RowItem from './row-item';
import Chip from '@mui/material/Chip';
import styled from '@mui/system/styled';
import RadioButtons from '../RadioButtons/RadioButtons';
import MovaticChipInput from '../ChipInputs/MovaticChipInput';
import { MuiTelInput } from 'mui-tel-input';
import MovaticFieldMultiChipsOptions from '../ChipInputs/MovaticFieldMultipleChipsInput';

const MovaticFieldGroupSelect = ({
  label,
  name,
  value,
  onChange,
  id,
  dataId,
  optionNodes,
  hasError = false,
  placeholder = 'Select an option',
  errorMessage,
  description,
  disabled = false,
  ...props
}: {
  label?: string;
  optionNodes: React.ReactNode[];
  value: any;
  onChange: any;
  placeholder?: string;
  id?: string;
  dataId?: string;
  hasError?: boolean;
  errorMessage?: string;
  description?: string;
  name?: string;
  disabled?: boolean;
}) => {
  return (
    <FormItem
      label={label}
      description={description}
      content={
        <FormControl fullWidth error={hasError}>
          <Select
            placeholder={placeholder}
            fullWidth
            name={name}
            data-id={dataId}
            id={id}
            value={value}
            onChange={onChange}
            disabled={disabled}
            {...props}
          >
            {optionNodes}
          </Select>
          <>
            {hasError && (
              <FormHelperText data-id={`${id}-help`}>{errorMessage}</FormHelperText>
            )}
          </>
        </FormControl>
      }
    />
  );
};

const MovaticFieldGroupAsyncSelect = ({
  label,
  name,
  onChange,
  id,
  dataId = 'movatic-async-select',
  hasError = false,
  placeholder = 'Select an option',
  errorMessage,
  description,
  onClose,
  onOpen,
  open,
  isOptionEqualToValue,
  getOptionText,
  options,
  isLoading,
  handleSearch,
  showClearButton = false,
  value,
  ...props
}: {
  label?: string;
  onChange: any;
  placeholder?: string;
  id?: string;
  dataId?: string;
  hasError?: boolean;
  errorMessage?: string;
  description?: string;
  name?: string;
  showClearButton?: boolean;
  open: boolean;
  onOpen: (event: SyntheticEvent<Element, Event>) => void;
  onClose: (event: SyntheticEvent<Element, Event>) => void;
  isOptionEqualToValue: (
    option: SyntheticEvent<Element, Event>,
    value: SyntheticEvent<Element, Event>
  ) => boolean;
  getOptionText: (option: string | SyntheticEvent<Element, Event>) => string;
  options: any[];
  isLoading: boolean;
  handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: any;
}) => {
  return (
    <FormItem
      label={label}
      description={description}
      content={
        <FormControl fullWidth error={hasError}>
          <Autocomplete
            id={id}
            data-id={dataId}
            value={value}
            open={open}
            onOpen={onOpen}
            onClose={onClose}
            isOptionEqualToValue={isOptionEqualToValue}
            getOptionLabel={getOptionText}
            options={options}
            loading={isLoading}
            onChange={onChange}
            {...props}
            renderInput={(params) => (
              <TextField
                onChange={handleSearch}
                variant={'outlined'}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {!showClearButton && (
                        <style>
                          {`
                            .MuiAutocomplete-clearIndicator {
                              display: none;
                            }
                          `}
                        </style>
                      )}

                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
          <>{hasError && <FormHelperText>{errorMessage}</FormHelperText>}</>
        </FormControl>
      }
    />
  );
};
const MovaticFieldGroupTelephone = ({
  label,
  name,
  value,
  onChange,
  id,
  dataId,
  hasError = false,
  placeholder = 'Select an option',
  errorMessage,
  description,
  ...props
}: {
  label?: string;
  value: any;
  onChange: any;
  placeholder?: string;
  id?: string;
  dataId?: string;
  hasError?: boolean;
  errorMessage?: string;
  description?: string;
  name?: string;
}) => {
  return (
    <FormItem
      label={label}
      description={description}
      content={
        <MuiTelInput
          {...props}
          defaultCountry={'US'}
          error={hasError}
          helperText={errorMessage}
          name="phone"
          fullWidth
          onChange={onChange}
          value={value}
          variant={'outlined'}
          size={'medium'}
          id={id}
        />
      }
    />
  );
};

const MovaticFieldGroupText = ({
  label,
  name,
  value,
  onChange,
  id,
  dataId,
  hasError = false,
  errorMessage,
  description,
  placeholder,
  multiline = false,
  rows = undefined,
  maxRows = undefined,
  minRows = undefined,
  ...props
}: {
  label: string;
  value: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  dataId?: string;
  hasError?: boolean;
  errorMessage?: string | null;
  description?: string;
  name?: string;
  placeholder?: string;
  multiline?: boolean;
  rows?: number;
  maxRows?: number;
  minRows?: number;
}) => {
  return (
    <FormItem
      label={label}
      description={description}
      content={
        <FormControl fullWidth error={hasError}>
          <TextField
            maxRows={maxRows}
            minRows={minRows}
            multiline={multiline}
            rows={rows}
            id={id}
            name={name}
            variant="outlined"
            fullWidth
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            data-id={dataId}
            error={hasError}
            {...props}
          />
          <>
            {hasError && (
              <FormHelperText data-id={`${id}-help`}>{errorMessage}</FormHelperText>
            )}
          </>
        </FormControl>
      }
    />
  );
};

const MovaticFieldItemLink = ({
  label,
  onClick,
  content,
  dataId,
}: {
  label: string;
  onClick: () => void;
  content: React.ReactNode;
  dataId?: string;
}) => {
  return (
    <FormItem
      label={label}
      content={
        <Link data-id={dataId} onClick={onClick}>
          <Typography color="text.secondary" variant="subtitle1">
            {content}
            <i id="table_item_carot" className="fa fa-caret-right" />
          </Typography>
        </Link>
      }
    />
  );
};

const MovaticFieldGroupCheck = ({
  label,
  name,
  onChange,
  id,
  hasError = false,
  errorMessage,
  description,
  checked,
  disabled = false,
}: {
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  hasError?: boolean;
  errorMessage?: string;
  description?: string;
  name?: string;
  checked: boolean;
  disabled?: boolean;
}) => {
  const theme = useTheme();
  return (
    <FormItem
      label={label}
      description={description}
      content={
        <FormControl fullWidth error={hasError}>
          <Switch
            color={theme.palette.mode === 'light' ? 'primary' : 'secondary'}
            sx={{ marginLeft: -1.1 }}
            checked={checked}
            onChange={onChange}
            inputProps={{ 'aria-label': 'controlled' }}
            disabled={disabled}
            name={name}
            id={id}
            data-id={id}
          />
          <>{hasError && <FormHelperText>{errorMessage}</FormHelperText>}</>
        </FormControl>
      }
    />
  );
};

const MovaticFieldGroupDate = ({
  label,
  onChange,
  id,
  hasError = false,
  errorMessage,
  description,
  value,
  minDate,
}: {
  label: string;
  onChange: (event: any) => void;
  id?: string;
  hasError?: boolean;
  errorMessage?: string;
  description?: string;
  value: Date | null | undefined;
  minDate?: Date;
}) => {
  return (
    <FormItem
      label={label}
      description={description}
      content={
        <FormControl fullWidth error={hasError}>
          <DatePicker
            minDate={minDate}
            data-id={id}
            value={value}
            onChange={onChange}
            slotProps={{
              textField: {
                id: id,
                fullWidth: true,
                variant: 'outlined',
                error: hasError,
              },
            }}
          />
          <>{hasError && <FormHelperText>{errorMessage}</FormHelperText>}</>
        </FormControl>
      }
    />
  );
};

const MovaticFieldGroupOutlinedInput = ({
  label,
  name,
  value,
  onChange,
  id,
  dataId,
  hasError = false,
  errorMessage,
  description,
  placeholder,
  addOn,
  addOnPosition = 'start',
  inputProps = { min: 0, max: 99, step: 0.25 },
  type,
  allowNegativeNumbers = false,
  noInternalValue = false,
  ...props
}: {
  label: string;
  value: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  dataId?: string;
  hasError?: boolean;
  errorMessage?: string;
  description?: string;
  name?: string;
  placeholder?: string;
  type?: string;
  addOn?: string;
  addOnPosition?: 'start' | 'end';
  inputProps?: any;
  allowNegativeNumbers?: boolean;
  noInternalValue?: boolean;
}) => {
  const [internalValue, setInternalValue] = useState(value);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (/^\d*\.?(\d{0,4})?$/.test(newValue) || newValue === '') {
      setInternalValue(newValue);
      onChange(event);
    }
  };

  let inputPropAux;
  if (allowNegativeNumbers) {
    const { min, max, ...restInputProps } = inputProps;
    inputPropAux = { ...restInputProps, pattern: '-?\\d*' };
  } else {
    inputPropAux = inputProps;
  }
  return (
    <FormItem
      label={label}
      description={description}
      content={
        <FormControl fullWidth error={hasError}>
          <OutlinedInput
            {...props}
            fullWidth
            id={id}
            name={name}
            type={type}
            inputProps={inputPropAux}
            placeholder={placeholder}
            value={noInternalValue ? internalValue : value}
            onChange={noInternalValue ? handleInputChange : onChange}
            startAdornment={
              <>
                {addOn && (
                  <InputAdornment position={addOnPosition}>{addOn}</InputAdornment>
                )}
              </>
            }
          />
          <>{hasError && <FormHelperText>{errorMessage}</FormHelperText>}</>
        </FormControl>
      }
    />
  );
};

const StyledTextField = styled(TextField)(() => ({
  '& .MuiInputBase-root': {
    padding: '0.5rem',
  },
  '& .MuiInputLabel-root': {
    display: 'none',
  },
}));

const MovaticFieldGroupMultiSelect = ({
  label,
  name,
  value,
  onChange,
  id,
  dataId,
  hasError = false,
  errorMessage,
  description,
  placeholder,
  options,
  ...props
}: {
  label: string;
  value: any;
  onChange: (event: React.SyntheticEvent<Element, Event>, value: any[]) => void;
  id?: string;
  dataId?: string;
  hasError?: boolean;
  errorMessage?: string;
  description?: string;
  name?: string;
  placeholder?: string;
  options: any[];
}) => {
  return (
    <FormItem
      label={label}
      description={description}
      content={
        <FormControl fullWidth error={hasError}>
          <Autocomplete
            {...props}
            placeholder={placeholder}
            fullWidth
            data-id={dataId}
            id={id}
            multiple
            isOptionEqualToValue={(option, value) => {
              return option.value === value.value;
            }}
            value={value}
            onChange={onChange}
            options={options}
            getOptionLabel={(option) => option.label}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip label={option.label} {...getTagProps({ index })} disabled={false} />
              ))
            }
            renderInput={(params) => <StyledTextField {...params} />}
          />
          <>{hasError && <FormHelperText>{errorMessage}</FormHelperText>}</>
        </FormControl>
      }
    />
  );
};
const MovaticFieldGroupMultiChips = ({
  label,
  values,
  onChange,
  hasError = false,
  errorMessage,
  description,
}: {
  label: string;
  values: string[];
  onChange: Function;
  id?: string;
  dataId?: string;
  hasError?: boolean;
  errorMessage?: string;
  description?: string;
  name?: string;
  placeholder?: string;
}) => {
  return (
    <FormItem
      label={label}
      description={description}
      content={
        <FormControl fullWidth error={hasError}>
          <MovaticChipInput existingChipItems={values} onChipAdded={onChange} />
          <>{hasError && <FormHelperText>{errorMessage}</FormHelperText>}</>
        </FormControl>
      }
    />
  );
};

const MovaticFieldGroupSelectFilter = ({
  label,
  value,
  id,
  dataId,
  onChange,
  options,
  onClose,
  onOpen,
  isLoading = false,
}: {
  label: string;
  value: any;
  id?: string;
  dataId?: string;
  onChange: (event: React.SyntheticEvent<Element, Event>, value: any[]) => void;
  options: any[];
  isLoading?: boolean;
  onOpen?: (event: SyntheticEvent<Element, Event>) => void;
  onClose?: (event: SyntheticEvent<Element, Event>) => void;
}) => {
  return (
    <RowItem label={label}>
      <Autocomplete
        fullWidth
        loading={isLoading}
        onOpen={onOpen}
        onClose={onClose}
        data-id={dataId}
        id={id}
        multiple
        isOptionEqualToValue={(option, value) => {
          return option.value === value.value;
        }}
        value={value}
        onChange={onChange}
        options={options}
        getOptionLabel={(option) => option.label}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip label={option.label} {...getTagProps({ index })} disabled={false} />
          ))
        }
        style={{ width: 340 }}
        renderInput={(params) => <StyledTextField {...params} />}
      />
    </RowItem>
  );
};

const MovaticFieldGroupSingleSelectFilter = ({
  label,
  value,
  id,
  name,
  dataId,
  onChange,
  options,
  width = 340,
  disabled = false,
}: {
  label: string;
  value: any;
  name?: string;
  id?: string;
  dataId?: string;
  onChange: (event: SelectChangeEvent) => void;
  options: any[];
  width?: number;
  disabled?: boolean;
}) => {
  return (
    <RowItem label={label}>
      <Select
        disabled={disabled}
        sx={{
          width: width,
        }}
        data-id={dataId}
        id={id}
        name={name}
        fullWidth
        value={value}
        onChange={onChange}
      >
        {options}
      </Select>
    </RowItem>
  );
};

const MovaticFieldGroupRadio = ({
  label,
  value,
  id,
  name,
  onChange,
  options,
  hasError = false,
  errorMessage,
  description,
  row = false,
}: {
  label: string;
  row?: boolean;
  value: any;
  name?: string;
  description?: string;
  id?: string;
  dataId?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  options: { id: string; label: string; popoverContent?: string }[];
  width?: number;
  hasError?: boolean;
  errorMessage?: string;
}) => {
  return (
    <FormItem
      label={label}
      description={description}
      content={
        <FormControl fullWidth error={hasError}>
          <RadioButtons
            row={row}
            id={id}
            name={name}
            value={value}
            handleChange={onChange}
            options={options}
          />
          <>{hasError && <FormHelperText>{errorMessage}</FormHelperText>}</>
        </FormControl>
      }
    />
  );
};

const MovaticFieldMultiOptions = ({
  label,
  groupValues,
  onChangeInput,
  hasError = false,
  errorMessage,
  description,
  dataId,
}: {
  label: string;
  groupValues: string[];
  onChangeInput: (groupValues: string[]) => void;
  hasError?: boolean;
  errorMessage?: string;
  description?: string;
  dataId?: string;
}) => {
  return (
    <FormItem
      label={label}
      description={description}
      content={
        <FormControl fullWidth error={hasError}>
          <>{hasError && <FormHelperText>{errorMessage}</FormHelperText>}</>
          <MovaticFieldMultiChipsOptions
            groupValues={groupValues}
            onChangeInput={onChangeInput}
            dataId={dataId}
          />
        </FormControl>
      }
    />
  );
};

export {
  MovaticFieldGroupSelect,
  MovaticFieldGroupText,
  MovaticFieldGroupCheck,
  MovaticFieldGroupOutlinedInput,
  MovaticFieldGroupDate,
  MovaticFieldItemLink,
  MovaticFieldGroupSelectFilter,
  MovaticFieldGroupSingleSelectFilter,
  MovaticFieldGroupRadio,
  MovaticFieldGroupMultiSelect,
  MovaticFieldGroupMultiChips,
  MovaticFieldGroupTelephone,
  MovaticFieldGroupAsyncSelect,
  MovaticFieldMultiOptions,
};
