import React, { useState } from 'react';
import useAllIssuingCards from '../../../hooks/issuingCard/query/useAllIssuingCards';
import useIssuingCardBalance from '../../../hooks/issuingCard/query/useIssuingCardBalance';
import { issuingCardsColumns } from '../../../tableColumns/issuingCardsColumns';
import { connect } from 'react-redux';
import { displayError, displaySuccess, history } from '../../../utils';
import { updateIssuingCardStatus, fundIssuingBalance } from '../../../api/issuingCard';
import {
  IIssuingCardEdit,
  IIsuingBalanceFund,
} from '../../../ts/interface/issuingCard.interface';
import MovaticCustomModal from '../../../components/Modal/MovaticCustomModal';
import { PaginationTable } from '../../../components/Redesing/table/pagination-table';
import CardView from '../../../components/Redesing/card-view';
import AddIcon from '@mui/icons-material/Add';
import { Card as MuiCard } from '@mui/material';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import { MovaticFieldGroupOutlinedInput } from '../../../components/Redesing/movatic-field-group';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants';
const IssuingCardsTable = ({ systemLoaded }: { systemLoaded: boolean }) => {
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_ROWS_PER_PAGE);
  const [showAddFundsModal, setShowAddFundsModal] = useState(false);
  const [issuingBalanceAmount, setIssuingBalanceAmount] = useState('');
  const [startingAfter, setStartingAfter] = useState<string | null>(null);
  const [sortData, setSortData] = useState<string>('created');
  const [search, setSearch] = React.useState('');
  const [exportData, setExportData] = useState<boolean>(false);

  let params: { limit: number; starting_after?: string } = {
    limit: pageSize,
  };

  if (startingAfter) {
    params.starting_after = startingAfter;
  }

  const { data: issuingCardsData = [], isLoading: isLoadingIssuingCards } =
    useAllIssuingCards(params);

  const { data: issuingBalance = 0, isLoading: isLoadingIssuingCardsBalance } =
    useIssuingCardBalance({});

  const handleIssuingCardStatusChange = (id: string, value: string) => {
    updateIssuingCardStatus(
      {
        status: value,
      } as IIssuingCardEdit,
      id
    ).then((response) => {
      if (response) {
        displaySuccess('The issuing card status was successfully updated!');
        history.push(`/billing/issuing`);
      } else {
        displayError(
          'There was an error updating the issuing card status. Please try again later.'
        );
      }
    });
  };

  const handleIssuingBalanceAdd = () => {
    fundIssuingBalance({
      amount: (parseInt(issuingBalanceAmount.replace('.', ''), 10) * 100).toString(),
    } as IIsuingBalanceFund).then((response) => {
      if (response) {
        displaySuccess('The issuing balance was successfully updated!');
        history.push(`/billing/issuing`);
      } else {
        displayError(
          'There was an error updating the issuing balance. Please try again later.'
        );
      }
    });
  };

  const resetAddIssuingBalanceModalFields = () => {
    setIssuingBalanceAmount('');
    setShowAddFundsModal(false);
  };

  function issuingCardsTable() {
    return (
      <>
        <PaginationTable
          dataId={'billing-issuing-cards'}
          padding={{
            pl: -1,
            pt: 1,
            pr: 1,
          }}
          exportData={exportData}
          isLoading={isLoadingIssuingCards || !systemLoaded}
          csvFileName={'IssuingCards.csv'}
          onExport={() => setExportData(false)}
          sortBy={sortData}
          onSortChange={(sortBy) => setSortData(sortBy.key)}
          items={issuingCardsData.data}
          noDataText={`You have no issuing cards`}
          columns={issuingCardsColumns(handleIssuingCardStatusChange)}
          rowsPerPage={pageSize}
          page={pageNo}
          onPageChange={(page, pageN: number) => {
            setPageNo(pageN);
            setStartingAfter(issuingCardsData[pageN * pageSize - 1]?.id);
          }}
          onRowsPerPageChange={(rowsPerPage) =>
            setPageSize(Number(rowsPerPage.target.value))
          }
          searchText={search}
          searchPlaceholder={`Search cards`}
          handleSearch={(search) => setSearch(search.target.value)}
          buttons={[
            {
              label: 'Export',
              startIcon: <FileDownloadIcon />,
              onClick: () => setExportData(true),
            },
            {
              label: 'Add',
              startIcon: <AddIcon />,
              onClick: () => history.push('/billing/issuing/add'),
            },
          ]}
        />
      </>
    );
  }

  return (
    <Stack spacing={3}>
      <MuiCard>{issuingCardsTable()}</MuiCard>
      <CardView
        isLoading={isLoadingIssuingCardsBalance || !systemLoaded}
        dataId={'issuing-balance'}
        headerActions={
          <IconButtonMenu
            buttons={[
              {
                label: 'Add',
                startIcon: <AddIcon />,
                onClick: () => setShowAddFundsModal(true),
              },
            ]}
          />
        }
        content={
          <Stack data-id={'issuing-balance'} textAlign="center" direction={'column'} pb={2} px={2}>
            <Typography variant="subtitle1" sx={{ color: 'darkText' }}>
              Available Issuing Balance
            </Typography>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
              {`$${
                issuingBalance
                  ? (issuingBalance.issuing_balance / 100).toFixed(2)
                  : '0.00'
              }`}
            </Typography>
          </Stack>
        }
        title={'Issuing Balance'}
      />

      {showAddFundsModal && (
        <MovaticCustomModal
          open={showAddFundsModal}
          onClose={() => setShowAddFundsModal(false)}
          title={'Add Issuing Funds'}
          content={
            <>
              <MovaticFieldGroupOutlinedInput
                type="number"
                description={'Enter the amount you would like to add.'}
                name="customAmount"
                addOn={'$'}
                inputProps={{ min: 0, step: 1 }}
                label="Amount"
                id="fund_amount"
                value={issuingBalanceAmount}
                onChange={(value: React.ChangeEvent<HTMLInputElement>) =>
                  setIssuingBalanceAmount(value.target.value)
                }
              />
            </>
          }
          action={
            <IconButtonMenu
              buttons={[
                {
                  label: 'Cancel',
                  onClick: () => resetAddIssuingBalanceModalFields(),
                },
                {
                  label: 'Submit',
                  onClick: () => handleIssuingBalanceAdd(),
                  startIcon: <AddIcon />,
                  disabled: !issuingBalanceAmount,
                  dataId: 'issuing-fund-button',
                },
              ]}
            />
          }
        />
      )}
    </Stack>
  );
};

export default connect(
  (state: { system: { isLoaded: boolean } }) => ({
    systemLoaded: state.system.isLoaded,
  }),
  () => ({})
)(IssuingCardsTable);
