import React from 'react';
import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';

const RootContainer = styled('div')<{
  minHeight?: string;
  maxHeight?: string;
  paddingBottom?: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: ${({ minHeight }) =>
    minHeight || '100vh'}; // Use the provided minHeight prop or default to 100vh
  max-height: ${({ maxHeight }) =>
    maxHeight || 'none'}; // Use the provided maxHeight prop or default to none
  padding-bottom: ${({ paddingBottom }) =>
    paddingBottom || 'none'}; // Use the provided maxHeight prop or default to none
`;

// const LogoImage = styled('img')<{ maxSize?: string; applyMargin?: boolean }>`
//   max-width: ${({ maxSize }) =>
//     maxSize || '90px'}; // Use the provided maxSize prop or default to '200px'
//   width: 80%;
//   height: auto; // Maintain aspect ratio
//   margin-bottom: ${({ theme, applyMargin }) => (applyMargin ? theme.spacing(2) : 'none')};
//   animation: spin 2s linear infinite;
//
//   @keyframes spin {
//     0% {
//       transform: rotate(0deg);
//     }
//     100% {
//       transform: rotate(360deg);
//     }
//   }
// `;

interface PageLoaderProps {
  maxSize?: string;
  minHeight?: string;
  maxHeight?: string;
  applyMargin?: boolean;
  paddingBottom?: string;
  dataId?: string;
}

const PageLoader: React.FC<PageLoaderProps> = ({
  maxSize,
  minHeight,
  applyMargin = true,
  maxHeight,
  paddingBottom,
  dataId = 'page-loader',
}) => {
  return (
    <RootContainer
      data-id={dataId}
      paddingBottom={paddingBottom}
      minHeight={minHeight}
      maxHeight={maxHeight}
    >
      <CircularProgress />
    </RootContainer>
  );
};

export default PageLoader;
