import { fetchPostOptions, baseUrl, fetchOptions, getCredentials } from '../api/http.jsx';
import {
  API_SYSTEMS_SUCCESS,
  SET_BANK_INFO,
  API_STRIPE_INFO,
  SET_DETAILED_SYSTEM,
  SYSTEM_LOADING,
  IMAGE_SUCCESS,
  ALL_IMAGE_SUCCESS,
  INSERT_IMAGE,
  UPDATE_SYSTEM_LOGO_ID,
  UPDATE_SYSTEM_BANNER_ID,
  UPDATE_LOADING,
  STRIPE_INFO_LOADED,
  RESET,
} from '../constants';
import { displayError, history } from '../utils';
import { gotSuccess, gotError } from './toast';
import { getAllHardwareTypes } from './hardware';
import { setMode } from './mode';
import { logOut, setSystemAccess } from './admin';
import { getNavigationStats } from './stats';
import bugsnagClient from '../components/bugsnag';
import CustomToast from '../components/Redesing/custom-toast';
import { useSystemStore } from '../store/systemStore';

export const apiSystemsSuccess = (systems) => ({
  type: API_SYSTEMS_SUCCESS,
  systems,
});

// system images
export const imageSuccess = (images) => ({
  type: IMAGE_SUCCESS,
  images,
});

// all images
export const allImageSuccess = (images) => ({
  type: ALL_IMAGE_SUCCESS,
  images,
});

export const insertImage = (image) => ({
  type: INSERT_IMAGE,
  image,
});

export const updateSystemLogoID = (imageId) => ({
  type: UPDATE_SYSTEM_LOGO_ID,
  imageId,
});
export const updateSystemBannerID = (imageId) => ({
  type: UPDATE_SYSTEM_BANNER_ID,
  imageId,
});

export const setDetailedSystem = (detailedSystem) => ({
  type: SET_DETAILED_SYSTEM,
  detailedSystem,
});

export const systemLoaded = (loading) => ({
  type: SYSTEM_LOADING,
  loading,
});

export const update = (loading) => ({
  type: UPDATE_LOADING,
  loading,
});

export const setStripeInfo = (stripeAccountInfo) => ({
  type: API_STRIPE_INFO,
  stripeAccountInfo,
});

export const stripeInfoLoaded = (loaded) => ({
  type: STRIPE_INFO_LOADED,
  loaded,
});

export const setBankInfo = (bankInfo) => ({
  type: SET_BANK_INFO,
  bankInfo,
});

export const clearSystemData = () => ({
  type: RESET,
});

export const createSystem = (system) => (dispatch, getState) => {
  dispatch(systemLoaded(false));

  const state = getState();
  const fetchOps = fetchPostOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id,
    system,
    'POST'
  );

  fetch(`${baseUrl}/system`, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      dispatch(systemLoaded(true));

      if (results.status === 200) {
        dispatch(gotSuccess('System added!', 'success'));
        dispatch(repullSystems(state.admin.admin));
        history.push('/systems');
      } else if (results.status === 401) {
        dispatch(gotError('Your session has expired, please log back in'));
        dispatch(logOut());
      } else {
        throw new Error(results.message);
      }
    })
    .catch((error) => {
      dispatch(systemLoaded(true));
      CustomToast({
        type: 'error',
        message: `There was an error while adding your system. ${error}`,
      });
    });
};

export const setDetailedSystemAndPullData =
  (detailedSystem, auth) => (dispatch, getState) => {
    const setSystemInfo = useSystemStore.getState().setSystemInfo;
    const { admin } = auth;
    const access = admin.access
      ? admin.access.find((admin) => admin?.system === detailedSystem?.id)
      : undefined;
    let systemAccess = admin.movaticAccess ? (admin.partner ? 0 : -1) : 1;
    if (access) {
      systemAccess = access.access;
    }
    setSystemInfo({
      oldId: detailedSystem.id,
      gid: detailedSystem.gid,
    });
    localStorage.setItem('systemAux', detailedSystem.id);
    localStorage.setItem('systemGid', detailedSystem.gid);
    return Promise.all([
      dispatch(setDetailedSystem(detailedSystem)),
      // set access
      dispatch(setSystemAccess(systemAccess)),
      // get banner and logo
      dispatch(getSystemImages()),
      // set the mode
      dispatch(
        setMode({
          type: detailedSystem.hardware_type,
          type_name: detailedSystem.hardware_type_name,
          type_name_plural: detailedSystem.hardware_type_name_plural,
          hardware_type_gid: detailedSystem.hardware_type_gid,
          configuration: detailedSystem.configuration,
        })
      ),
      // load hardware types
      dispatch(getAllHardwareTypes()),
      // get side nav stats
      dispatch(getNavigationStats()),
      // turn off the loading animation
      dispatch(systemLoaded(true)),
    ]);
  };

export const FourOhOneError = (status) => (dispatch) => {
  if (status?.status === 401) {
    CustomToast({
      type: 'error',
      message: 'Your session has expired, please log back in',
    });
    dispatch(logOut());
  } else if (
    status?.status === 402 &&
    status?.message === 'System Header not present or invalid'
  ) {
    // header isn't set yet which throws an error we don't need to show
  } else if (status?.message) {
    bugsnagClient.notify(status.message.toString());
  } else if (status) {
    bugsnagClient.notify(status.toString());
  }
};

export const setNewSystem = (systemId, auth) => (dispatch) => {
  const { adminEmail, adminToken } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);
  return fetch(`${baseUrl}/system/settings/currentsystem`, fetchOps)
    .then((response) => response.json())
    .then((systemsRes) => {
      if (systemsRes.status === 200) {
        dispatch(setDetailedSystemAndPullData(systemsRes.message, auth));
        return systemsRes;
      }
      if (systemsRes.status === 401) {
        dispatch(FourOhOneError(systemsRes));
      } else {
        throw new Error(systemsRes?.message);
      }
    })
    .catch((e) => {
      CustomToast({
        type: 'error',
        message: `There was an error while loading systems. ${e}`,
      });
    });
};

export const repullSystems = (auth) => (dispatch) => {
  const fetchOps = fetchOptions(auth.admin.email, auth.token);
  return fetch(`${baseUrl}/system/settings/allforadmin`, fetchOps)
    .then((response) => response.json())
    .then((systemsRes) => {
      if (systemsRes.status === 200) {
        const systems = systemsRes.message.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });
        dispatch(apiSystemsSuccess(systems));
      } else {
        dispatch(FourOhOneError(systemsRes));
      }
    })
    .catch((e) => {
      displayError(e);
    });
};

export const setAdminError = () => (dispatch) => {
  const setSystemInfo = useSystemStore.getState().setSystemInfo;
  localStorage.removeItem('systemAux');
  localStorage.removeItem('systemGid');
  setSystemInfo({
    oldId: '',
    gid: '',
  });
  history.push('/404');
};

export const getAllSystems = (auth, urlSystem) => (dispatch) => {
  const setSystemInfo = useSystemStore.getState().setSystemInfo;
  const fetchOps = fetchOptions(
    auth.admin.email,
    auth.token,
    urlSystem || localStorage.getItem('systemGid')
  );
  return fetch(`${baseUrl}/system/settings/currentsystem`, fetchOps)
    .then((response) => response.json())
    .then((systemsRes) => {
      if (systemsRes.status === 200) {
        const selectedSystem = systemsRes.message;
        localStorage.setItem('systemAux', selectedSystem.id);
        localStorage.setItem('systemGid', selectedSystem.gid);
        setSystemInfo({
          oldId: selectedSystem.id,
          gid: selectedSystem.gid,
        });
        dispatch(setDetailedSystemAndPullData(selectedSystem, auth));
        return selectedSystem;
      }
      if (systemsRes.status === 401) {
        dispatch(FourOhOneError(systemsRes));
      } else {
        throw new Error(systemsRes?.message);
      }
    })
    .catch((e) => {
      return dispatch(setAdminError());
    });
};

export const updateSystemSettings = (info) => (dispatch, getState) => {
  dispatch(update(false));
  const state = getState();
  const fetchOps = fetchPostOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id,
    info,
    'Put'
  );

  fetch(`${baseUrl}/system/settings`, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      dispatch(update(true));
      if (results.status === 200) {
        dispatch(getAllSystems(state.admin.admin));
        dispatch(gotSuccess('Settings update success!'));
      } else {
        dispatch(FourOhOneError(results));
        dispatch(gotError(results.message));
      }
    })
    .catch((e) => {
      dispatch(update(true));
      bugsnagClient.notify(e);
      console.error(e);
    });
};

export const getStripeAccountInfo = () => (dispatch, getState) => {
  const state = getState();
  dispatch(stripeInfoLoaded(false));

  const fetchOps = fetchOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id
  );
  fetch(`${baseUrl}/system/stripeaccount`, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      dispatch(stripeInfoLoaded(true));
      if (results.status === 200) {
        const account = results.message;
        if (account.type === 'standard') {
          account.requirements = {
            currently_due: [],
          };
          account.tos_acceptance = {
            date: null,
          };
        }
        dispatch(setStripeInfo(account));
      } else {
        // if the status isn't ok then we reset the stripe account on the
        // store to empty
        if (results.message !== 'No stripe account') {
          dispatch(FourOhOneError(results));
        }

        dispatch(
          setStripeInfo({
            business_type: '',
            requirements: {
              currently_due: [],
            },
            tos_acceptance: {
              date: null,
            },
          })
        );
      }
    })
    .catch((e) => {
      dispatch(stripeInfoLoaded(true));
    });
};

export const createStripeAccount = (businessType) => (dispatch, getState) => {
  const state = getState();
  dispatch(update(false));

  const fetchOps = fetchPostOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id,
    businessType,
    'Post'
  );

  return fetch(`${baseUrl}/billing/stripeaccount/create`, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        return results.message;
      }
      dispatch(update(true));
      dispatch(
        gotError(`There was an error communicating with Stripe. ${results.message}`)
      );
      return null;
    })
    .catch((e) => {
      dispatch(update(true));
      bugsnagClient.notify(e);
      dispatch(gotError(`There was an error communicating with Stripe. ${e}`));
      console.error(e);
    });
};

export const getSetBankInfo = () => (dispatch, getState) => {
  const state = getState();
  const fetchOps = fetchOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id
  );
  fetch(`${baseUrl}/system/bank`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        response.message.isLoaded = true;
        dispatch(setBankInfo(response.message));
      } else if (response.status === 404 || response.status === 400) {
        // the API sends back a 404 if there is no bank info for this system so we want to set the bank info to empty
        dispatch(
          setBankInfo({
            bankName: '',
            accountLast4: null,
            routingNumber: null,
            isLoaded: true,
          })
        );
      } else {
        dispatch(FourOhOneError(response));
        throw new Error(response.message);
      }
    })
    .catch((error) => {
      dispatch(
        setBankInfo({
          bankName: '',
          accountLast4: null,
          routingNumber: null,
          isLoaded: true,
        })
      );
      if (error.toString() !== 'Error: System Header not present or invalid') {
        displayError(
          `There was an error while finding your bank's information: ${error}`
        );
      }
    });
};

export const clearBankInfo = () => (dispatch) => {
  const cleanBankObj = {
    bankName: '',
    accountLast4: null,
    routingNumber: null,
    isLoaded: false,
  };

  dispatch(setBankInfo(cleanBankObj));
};

export const updateSystemImage = (image) => (dispatch, getState) => {
  dispatch(update(false));
  const state = getState();
  const fetchOps = fetchPostOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id,
    image,
    'PUT'
  );
  return fetch(`${baseUrl}/approve-image/update/${image.id}`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      dispatch(update(true));
      if (results.status === 200) {
        dispatch(getSystemImages());
      } else {
        dispatch(FourOhOneError(results));
        throw new Error(results.message);
      }
    })
    .catch((error) => {
      displayError(
        `It looks like there was an error on our end while trying to add your image. Please try again! ${error}`
      );
    });
};

export const getAllImages = () => (dispatch, getState) => {
  const state = getState();
  const fetchOps = fetchOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id
  );
  return fetch(`${baseUrl}/approve-image/images`, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        if (results.message !== null) {
          const images = results.message.map((img) => {
            state.system.systems.forEach((system) => {
              if (img.id === system.banner_id || img.id === system.logo_id) {
                img.system = system.id;
              }
            });
            return img;
          });
          return dispatch(allImageSuccess(images));
        }
        return dispatch(allImageSuccess(results.message));
      }
      dispatch(FourOhOneError(results));
      throw new Error(results.message);
    })
    .catch((error) => {
      displayError(`There was an error getting images${error}`);
    });
};

export const getSystemImages = () => (dispatch, getState) => {
  const state = getState();
  const fetchOps = fetchOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id
  );
  return fetch(`${baseUrl}/approve-image/images/system`, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        if (results.message !== null) {
          const images = results.message.map((img) => {
            state.system.systems.forEach((system) => {
              if (img.id === system.banner_id || img.id === system.logo_id) {
                img.system = system.id;
              }
            });
            return img;
          });
          return dispatch(imageSuccess(images));
        }
        return dispatch(imageSuccess(results.message));
      }
      dispatch(FourOhOneError(results));
    })
    .catch((error) => {
      displayError('There was an error getting images');
    });
};

export const resetSystemAPIKey = () => (dispatch, getState) => {
  dispatch(update(false));
  const state = getState();
  const fetchOps = fetchPostOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id,
    {},
    'POST'
  );

  return fetch(`${baseUrl}/system/apikey`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      dispatch(update(true));
      if (results.status === 200) {
        dispatch(gotSuccess('API key reset!', 'success'));
        dispatch(getAllSystems(state.admin.admin));
      } else {
        dispatch(FourOhOneError(results));
        throw new Error(results.message);
      }
    })
    .catch((error) => {
      displayError(
        'It looks like there was an error on our end while trying to reset the system API key. Please try again!'
      );
    });
};

export const updateSystemProvider = (systemInfo) => (dispatch, getState) => {
  dispatch(update(false));
  const state = getState();
  const fetchOps = fetchPostOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id,
    systemInfo,
    'PUT'
  );

  return fetch(`${baseUrl}/system/update/provider`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      dispatch(update(true));
      if (results.status === 200) {
        dispatch(gotSuccess('Update successful!', 'success'));
        dispatch(getAllSystems(state.admin.admin));
      } else {
        dispatch(FourOhOneError(results));
        throw new Error(results.message);
      }
    })
    .catch((error) => {
      displayError('Error while updating, please try again.');
    });
};

export const sendUserNotifications = (title, description) => {
  const { adminEmail, adminToken, systemGid } = getCredentials();
  const fetchOps = fetchPostOptions(
    adminEmail,
    adminToken,
    null,
    {
      title,
      description,
      system_id: systemGid,
    },
    'POST'
  );

  return fetch(`${baseUrl}/system/notifications`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results.status === 200) {
        return results?.message;
      }
      FourOhOneError(results);
      throw new Error(results.message);
    })
    .catch((e) => Promise.reject(`${e}`));
};
