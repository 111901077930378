import {
  baseUrl,
  baseUrlV2,
  createQueryParameters,
  fetchOptions,
  fetchOptionsV2,
  fetchPostOptions,
  getCredentials,
} from './http';
import { convertCentsToMoney, displayError, toTitleCase } from '../utils';
import { FourOhOneError } from '../actionCreators/system';

export const getRefund = (refund_id) => {
  const { adminEmail, adminToken } = getCredentials();
  const fetchOps = fetchOptionsV2(adminEmail, adminToken);

  return fetch(`${baseUrl}/transactions/refunds/${refund_id}`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response?.status === 200) {
        return response.message;
      }

      throw new Error(response?.error_description);
    })
    .catch((error) => {
      displayError(
        'Oops! Looks like there was an error while loading the refund. Please try again later.',
        error
      );
      return [];
    });
};

export const getTransactions = (parameters) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);
  const queryParametersObject = {
    ...(parameters?.payload || {}),
  };
  const queryParameters = parameters
    ? `?${createQueryParameters(queryParametersObject)}`
    : '';

  return fetch(`${baseUrl}/transactions${queryParameters}`, fetchOps)
    .then((res) => res.json())
    .then((transactions) => {
      if (transactions.status === 200) {
        return transactions.message.map((transaction) => {
          // TODO don't map this at all
          const trans = { ...transaction };

          switch (trans.type?.toLowerCase()) {
            case 'wallet debit':
              trans.cents = trans.charge_total * -1;
              break;
            case 'transfer':
            case 'refund':
              trans.cents = trans.amount;
              break;
            default:
              trans.cents = trans.charge_total ?? trans.amount;
          }

          trans.cents = trans.cents || 0;
          trans.amount = convertCentsToMoney(trans.cents);

          if (trans.description != null) {
            trans.type = trans.description.includes('plan') ? 'Plan Charge' : trans.type;
          }
          return trans;
        });
      }
      FourOhOneError(transactions);
      throw new Error();
    })
    .catch(() => {
      displayError(
        'Oops! Looks like there was an error while loading the transactions. Please try again later.'
      );
    });
};

export const getTransactionDetails = (chargeId) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);
  return fetch(`${baseUrl}/transactions/charge/${chargeId}`, fetchOps)
    .then((res) => res.json())
    .then((chargeInfo) => {
      if (chargeInfo.status === 200) {
        return chargeInfo.message;
      }
      throw new Error(chargeInfo.error_description);
    })
    .catch((error) => {
      displayError(
        'Oops! Looks like there was an error while loading the transaction details. Please try again later.'
      );
    });
};

export const getWalletTransactionDetails = (walletTransactionId) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);
  return fetch(
    `${baseUrl}/transactions/wallettransaction/${walletTransactionId}`,
    fetchOps
  )
    .then((res) => res.json())
    .then((walletTransactionInfo) => {
      if (walletTransactionInfo.status === 200) {
        return walletTransactionInfo.message;
      } else {
        FourOhOneError(walletTransactionInfo);
        throw new Error(walletTransactionInfo.error_description);
      }
    })
    .catch(() => {
      displayError(
        'Oops! Looks like there was an error while loading the transaction details. Please try again later.'
      );
    });
};

export const getHoldTransactionDetails = (holdId) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);
  return fetch(`${baseUrl}/transactions/hold/${holdId}`, fetchOps)
    .then((res) => res.json())
    .then((holdInfo) => {
      if (holdInfo.status === 200) {
        return holdInfo.message;
      } else {
        FourOhOneError(holdInfo);
        throw new Error(holdInfo.error_description);
      }
    })
    .catch((error) => {
      return null;
    });
};

export const refundRentalCharge = (refundObj) => {
  const { adminEmail, adminToken } = getCredentials();
  const fetchOps = fetchOptionsV2(adminEmail, adminToken, 'PUT', refundObj);
  return fetch(`${baseUrlV2}/rental/${refundObj.rentalId}/refund`, fetchOps)
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.error_description);
      }
      if (res.status !== 204) {
        return res.json();
      }
      return null;
    })
    .catch((e) => {
      return Promise.reject(
        `We're sorry, there was an error while submitting your refund! Please try again. ${e}`
      );
    });
};

export const getCharges = () => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);

  return fetch(`${baseUrl}/transactions/charges`, fetchOps)
    .then((res) => res.json())
    .then((charges) => {
      if (charges.status === 200) {
        return charges.message;
      }
      FourOhOneError(charges);
      throw new Error();
    })
    .catch(() => {
      displayError(
        'Oops! Looks like there was an error while loading the charges. Please try again later.'
      );
    });
};

export const getAllDisputes = () => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);
  return fetch(`${baseUrl}/transactions/dispute`, fetchOps)
    .then((response) => response.json())
    .then((allDisputes) => {
      if (allDisputes.status === 200) {
        return allDisputes.message.map((dispute) => {
          dispute.amount = convertCentsToMoney(dispute.amount);
          dispute.fee = convertCentsToMoney(dispute.fee);
          dispute.net = convertCentsToMoney(dispute.net);
          dispute.status = toTitleCase(dispute.status.replace(/_/g, ' '));
          if (dispute.reason) {
            dispute.reason = toTitleCase(dispute.reason.replace(/_/g, ' '));
          }
          dispute.system = String(dispute.system);

          return dispute;
        });
      }
      FourOhOneError(allDisputes);
      throw new Error();
    })
    .catch((error) => {
      displayError('There was an error getting your disputes');
    });
};

export const getRefunds = () => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);
  return fetch(`${baseUrl}/transactions/refunds`, fetchOps)
    .then((res) => res.json())
    .then((refunds) => {
      if (refunds.status === 200) {
        return refunds.message;
      }
      FourOhOneError(refunds);
      throw new Error();
    })
    .catch(() => {
      displayError(
        'Oops! Looks like there was an error while loading the refunds. Please try again later.'
      );
    });
};

export const refundCharge = (refundObj) => {
  const { adminEmail, adminToken, systemId } = getCredentials();

  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, refundObj, 'PUT');

  return fetch(`${baseUrl}/transactions/refund`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results.status === 200) {
        return results;
      }
      FourOhOneError(results);
      throw new Error(results.error_description);
    })
    .catch((e) => {
      displayError(
        `We're sorry, there was an error while submitting your refund! Please try again. ${e}`
      );
    });
};
