import { Typography } from '@mui/material';
import { Logo } from '../../components/Redesing/logo';
import React from 'react';
import { Stack } from '@mui/system';
import Link from '@mui/material/Link';
import { RouterLink } from '../../components/Redesing/router-link';
import { paths } from '../../paths';

const ResetPasswordSent: React.FC<{ email: string }> = ({ email }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Adjust this value as needed
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="baseline"
        spacing={2}
        p={2}
        sx={{
          maxWidth: '28rem',
        }}
      >
        <Stack pl={3}>
          <Logo />
        </Stack>
        <Stack className="login_card" spacing={2}>
          <Typography variant="h6">Please check your email</Typography>
          <Typography variant="subtitle1">
            {`We sent an email to ${email}, which contains a link to reset your Movatic password.`}
          </Typography>
        </Stack>
        <Stack>
          <Link
            pl={3}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            component={RouterLink}
            href={paths.auth.login}
            underline="hover"
            variant="subtitle2"
          >
            Back to sign in
          </Link>
        </Stack>
      </Stack>
    </div>
  );
};

export default ResetPasswordSent;
