import React, { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { getAdminFromLocalS, history } from '../../utils';
import { logOut, resetPassword } from '../../api/admin';
import { connect } from 'react-redux';
import { adminLoaded, newLogin } from '../../actionCreators/admin';
import { getAllSystems } from '../../actionCreators/system';
import Login from './login';
import Success from './resetPasswordSent';
import CustomToast from '../../components/Redesing/custom-toast';
import ResetPassword from './resetPassword';
import SetPasswordRedux from './setPassword';
import { IAdmin } from '../../ts/interface/admins.interface';
import withTheme from './assets/withTheme';
import { ILoginPageProps } from '../../ts/interface/pages/login.interface';
import useQueryString from '../../hooks/queryParams/useQueryString';

const LoginPage: React.FC<ILoginPageProps> = ({
  getAllSystems,
  adminLoaded,
  newLogin,
  dispatch,
  admin,
  location,
}) => {
  const {
    email: emailParam,
    page,
    token,
  } = useParams<{
    email: string;
    token: string;
    page: string;
  }>();
  const systemId = useQueryString().queryParams.get('systemId');
  const [password, setPassword] = useState('');
  const [showResetSuccess, setShowResetSuccess] = useState(false);
  const [resetPasswordState, setResetPasswordState] = useState(false);
  const [adminState, setAdminState] = useState<IAdmin | null>(admin);
  const [emailSended, setEmailSended] = useState('');

  useEffect(() => {
    const loginPaths = ['setpassword', 'resetpassword'];
    if (loginPaths.includes(page)) {
      setAdminState(null);
      if (emailParam && token) {
        logOut();
      }
      if (page === 'resetpassword' && !emailParam && !token) {
        setResetPasswordState(true);
      } else if (!emailParam || !token) {
        history.replace('/login');
      }
    } else {
      const adminData = getAdminFromLocalS();
      if (adminData === null) {
        setAdminState(null);
        history.push('/login');
        return;
      }
      adminLoaded(adminData as IAdmin);
      getAllSystems(adminData as IAdmin, systemId);
      const { pathname } = location;
      if ((pathname === '/' || pathname === '/login') && !Boolean(systemId))
        history.push('/dashboard');
    }
  }, [adminLoaded, emailParam, getAllSystems, page, token, location, systemId]);

  const login = (email: string, password: string) => {
    newLogin(email, password).catch((e: { message: string }) => {
      if (e.message === 'Log in attempts exceeded') {
        dispatch('TO_MANY_LOGINS_RESET_PASSWORD', null);
        setResetPasswordState(true);
      } else {
        CustomToast({
          type: 'error',
          message: 'Bad email / password combination.',
        });
        setPassword('');
      }
    });
  };

  const handleResetPasswordCancel = () => {
    dispatch('CANCEL_RESET_PASSWORD', null);
    history.push('/login');
  };

  if (adminState === null) {
    if (resetPasswordState && !showResetSuccess) {
      const ResetPasswordWithTheme = withTheme(ResetPassword);
      return (
        <ResetPasswordWithTheme
          onSubmit={(email: string) => {
            setShowResetSuccess(true);
            setEmailSended(email);
          }}
          maxedOut={admin.maxedOut}
          cancel={handleResetPasswordCancel}
        />
      );
    }
    if ((password && !showResetSuccess) || (emailParam && token && !showResetSuccess)) {
      const SetPasswordReduxWithTheme = withTheme(SetPasswordRedux);
      return <SetPasswordReduxWithTheme />;
    }
    if (showResetSuccess) {
      const SuccessWithTheme = withTheme(Success);
      return <SuccessWithTheme email={emailSended} />;
    }
    const LoginWithTheme = withTheme(Login);
    return <LoginWithTheme login={login} />;
  }
  return null;
};

export default withRouter(
  connect(
    (state: { admin: IAdmin }) => ({
      admin: state.admin,
    }),
    (dispatch) => ({
      // @ts-ignore
      newLogin: (email: string, password: string) => dispatch(newLogin(email, password)),
      adminLoaded: (admin: IAdmin) => {
        dispatch(adminLoaded(admin));
      },
      // @ts-ignore
      resetPassword: (email: string) => dispatch(resetPassword(email)),
      dispatch: (type: string, data: any) => {
        dispatch({ type, data });
      },
      getAllSystems: (adminData: any, systemId: string | null) => {
        // @ts-ignore
        dispatch(getAllSystems(adminData, systemId));
      },
    })
  )(LoginPage)
);
