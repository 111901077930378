import React, { useState } from 'react';
import { connect } from 'react-redux';
import useRates from '../../hooks/rates/query/useRates';
import { useParams } from 'react-router-dom';
import { ISystem } from '../../ts/interface/system.interface';
import { IRate } from '../../ts/interface/rental.interface';
import { findAccess, history } from '../../utils';
import { updateRate } from '../../api/rates';
import { IEditRatesProps } from '../../ts/interface/pages/rates.interface';
import {
  MovaticFieldGroupCheck,
  MovaticFieldGroupOutlinedInput,
  MovaticFieldGroupText,
} from '../../components/Redesing/movatic-field-group';
import { Stack } from '@mui/system';
import CardView from '../../components/Redesing/card-view';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import SaveIcon from '@mui/icons-material/Save';
import PageContainer from '../../components/Redesing/page-container';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';

const EditRates = ({
  system,
  systemLoaded,
  movaticAccess,
  systemAccess,
}: IEditRatesProps) => {
  const { rateId } = useParams<{ rateId: string }>();
  const [name, setName] = useState('');
  const [nameWarning, setNameWarning] = useState<string | null>(null);
  const [creditCardHold, setCreditCardHold] = useState<number | null>(null);
  const [creditCardHoldWarning, setCreditCardHoldWarning] = useState<string | null>(null);
  const [maxRentalsWarning, setMaxRentalsWarning] = useState<string | null>(null);
  const [minimumWalletDeposit, setMinimumWalletDeposit] = useState<number | null>(null);
  const [minimumWalletDepositWarning, setMinimumWalletDepositWarning] = useState<
    string | null
  >(null);
  const [hideRate, setHideRate] = useState(false);
  const [maxRentals, setMaxRentals] = useState<number | null>(null);
  const [rateLoaded, setRateLoaded] = useState<IRate>({} as IRate);
  const { isLoading: ratesLoading } = useRates({
    enabled: systemLoaded,
    onSuccess: (data: IRate[]) => {
      const rate = data.find((r: IRate) => r.id === rateId || r.gid === rateId);
      if (rate) {
        setRateLoaded(rate);
        setName(rate.name);
        setCreditCardHold(
          rate.credit_card_hold && Number.isInteger(rate?.credit_card_hold)
            ? rate.credit_card_hold / 100
            : null
        );
        setMinimumWalletDeposit(
          rate.minimum_wallet_deposit && Number.isInteger(rate.minimum_wallet_deposit)
            ? rate.minimum_wallet_deposit / 100
            : null
        );
        setHideRate(rate.hidden);
        setMaxRentals(Number.isInteger(rate.max_rentals) ? rate.max_rentals : null);
      }
    },
  });

  const content = () => {
    const systemUsesWallets = system.billing && system.wallet_required;

    return (
      <CardView
        title={'Edit Rate'}
        content={
          <Stack px={2} pb={2}>
            <MovaticFieldGroupText
              name="name"
              label="Name"
              description='An informative description for this rate (e.g. "Hourly," "Daily," etc). Users will see this name in the app.'
              hasError={nameWarning === 'error'}
              value={name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setName(e.target.value);
                setNameWarning(null);
              }}
            />

            {systemUsesWallets && (
              <MovaticFieldGroupOutlinedInput
                addOn={'$'}
                type="number"
                name="minimum_wallet_deposit"
                id="minimum_wallet_deposit"
                label="Minimum Wallet Balance"
                description="The minimum wallet balance that users charged with this rate need to have in order to start a rental. If left blank, the value in Settings will be used. Can be overriden by Memberships."
                value={minimumWalletDeposit ?? ''}
                inputProps={{ min: 0, step: 0.25 }}
                hasError={minimumWalletDepositWarning === 'error'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setMinimumWalletDeposit(Number(e.target.value));
                  setMinimumWalletDepositWarning(null);
                }}
              />
            )}

            <MovaticFieldGroupOutlinedInput
              type="number"
              name="max_rentals"
              id="max_rentals"
              label="Max Open Rentals"
              description="The maximum number of units users are allowed to rent simultaneously. If left blank, the value will be set to the system max rental value."
              value={maxRentals || ''}
              hasError={maxRentalsWarning === 'error'}
              inputProps={{ min: 0, step: 1, max: 99 }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setMaxRentals(Number(e.target.value));
                setMaxRentalsWarning(null);
              }}
            />

            {system.billing && (
              <MovaticFieldGroupOutlinedInput
                type="number"
                name="credit_card_hold"
                id="credit_card_hold"
                label="Credit Card Hold"
                description="The credit card hold amount that users charged with this rate will be applied in order to start a rental. If left blank, the value in Settings will be used. Can be overriden by Memberships."
                value={creditCardHold ?? ''}
                hasError={creditCardHoldWarning === 'error'}
                inputProps={{ min: 0, step: 0.25 }}
                addOn={'$'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setCreditCardHold(Number(e.target.value));
                  setCreditCardHoldWarning(null);
                }}
              />
            )}

            {system.new_hardware && (
              <MovaticFieldGroupCheck
                id="hide-rate"
                label="Hide Rate"
                description="If set, the rate will only be available when assigned to a location."
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setHideRate(e.target.checked)
                }
                checked={hideRate}
              />
            )}
          </Stack>
        }
        headerActions={
          <IconButtonMenu
            buttons={[
              {
                label: 'Cancel',
                onClick: () => history.push(`/billing/rates/${rateId}`),
              },
              {
                label: 'Save',
                onClick: saveRate,
                disabled: !findAccess(systemAccess).includes('rates') && !movaticAccess,
                startIcon: <SaveIcon />,
              },
            ]}
          />
        }
      />
    );
  };

  const canSubmit = () => {
    let canSubmit = true;
    if (!name || name === '') {
      canSubmit = false;
      setNameWarning('error');
    } else {
      setNameWarning(null);
    }

    if (minimumWalletDeposit && Math.round(minimumWalletDeposit * 100) < 0) {
      canSubmit = false;
      setMinimumWalletDepositWarning('error');
    } else {
      setMinimumWalletDepositWarning(null);
    }

    if (creditCardHold && Math.round(creditCardHold * 100) < 0) {
      canSubmit = false;
      setCreditCardHoldWarning('error');
    } else {
      setCreditCardHoldWarning(null);
    }

    if (maxRentals && Number(maxRentals) < 1) {
      canSubmit = false;
      setMaxRentalsWarning('error');
    } else {
      setMaxRentalsWarning(null);
    }

    return canSubmit;
  };

  const saveRate = () => {
    if (canSubmit()) {
      const rate = { ...rateLoaded };
      rate.name = name;
      rate.minimum_wallet_deposit = minimumWalletDeposit
        ? Math.round(minimumWalletDeposit * 100)
        : null;
      rate.credit_card_hold = creditCardHold ? Math.round(creditCardHold * 100) : null;
      rate.hidden = hideRate;
      rate.max_rentals = maxRentals ? Number(maxRentals) : null;

      CustomAsyncToast({
        promise: () => updateRate(rate, null),
        successMessage: () => 'Your rate was updated successfully!',
        loadingMessage: () => 'Updating rate...',
        errorMessage: () =>
          'There was an error updating the rate. Please try again later.',
      }).then((r) => {
        if (!r) return;
        history.push(`/billing/rates/${rateId}`);
      });
    }
  };

  return (
    <>
      <PageContainer isLoading={ratesLoading || !systemLoaded}>{content()}</PageContainer>
    </>
  );
};

export default connect(
  (state: {
    admin: {
      systemAccess: number;
      admin: {
        admin: {
          movaticAccess: number;
        };
      };
    };
    system: {
      current: ISystem;
      isLoaded: boolean;
    };
  }) => ({
    movaticAccess: state.admin.admin.admin.movaticAccess,
    systemAccess: state.admin.systemAccess,
    system: state.system.current,
    systemLoaded: state.system.isLoaded,
  }),
  () => ({})
)(EditRates);
