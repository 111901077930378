import { useQuery } from '@tanstack/react-query';
import { getWalletTransactionDetails } from '../../../api/transactions';

const useWalletTransaction = (id: string, options?: {}) => {
  return useQuery(['walletTransaction', id], () => getWalletTransactionDetails(id), {
    ...options,
  });
};

export default useWalletTransaction;
