import {
  API_HARDWARE_SUCCESS,
  SET_DETAILED_HARDWARE,
  HARDWARE_LOADED,
  HARDWARE_DETAIL_LOADED,
  REMOTE_ACTION_LOADED,
  SET_REMOTE_ACTION_LOADED,
  HARDWARE_TYPE_LOADED,
  SET_HARDWARE_TYPES,
} from '../constants';

const initialState = {
  hardware: [], // TODO: remove this
  new_hardware: {
    bike: [],
    e_bike: [],
    locker: [],
    scooter: [],
    lock: [],
    base_station: [],
    battery: [],
    dock: [],
    kayak: [],
    beachchair: [],
    canoe: [],
    rack: [],
  },
  isLoaded: false,
  detailedIsLoaded: false,
  detailed: {
    // TODO: remove this
    available: null,
    current_rental: undefined,
    current_station: undefined,
    tableString: undefined,
    id: undefined,
    masterUnlockCode: undefined,
    module: undefined,
    number: 0,
    nusetSerialNumber: undefined,
    smartBoxId: undefined,
    state: undefined,
    system: 0,
    type: undefined,
    typeName: undefined,
    has_gps: false,
    last_seen: null,
    last_lat: null,
    last_lng: null,
  },
  new_hardware_detailed: {},
  baseStationUnlockId: '',
  remoteUnlockId: '',
  remoteBatteryUnlockId: '',
  remoteLockId: '',
  remoteBatteryLockId: '',
  remoteRingId: '',
  locationCheckId: '',
  remoteActionLoaded: true,
  hardwareTypes: {},
  hardwareTypesLoaded: false,
};

export default function hardwareReducer(state = initialState, action) {
  const newState = { ...state };

  switch (action.type) {
    case API_HARDWARE_SUCCESS:
      newState.new_hardware = {
        bike: [],
        e_bike: [],
        locker: [],
        scooter: [],
        lock: [],
        base_station: [],
        battery: [],
        dock: [],
        rack: [],
        kayak: [],
        beachchair: [],
        canoe: [],
        ...action.new_hardware,
      };
      newState.hardware = action.hardware;
      newState.isLoaded = true;
      break;

    case SET_DETAILED_HARDWARE:
      newState.new_hardware_detailed = action.new_hardware_detailed;
      newState.detailed = Object.assign(state.detailed, action.hardwareDetails);
      newState.detailedIsLoaded = true;
      break;

    case HARDWARE_LOADED:
      newState.isLoaded = action.loaded;
      break;

    case HARDWARE_DETAIL_LOADED:
      newState.detailedIsLoaded = action.loaded;
      break;

    case REMOTE_ACTION_LOADED:
      // specific action and hardware id
      newState[action.actionType] = action.hardwareId;
      break;

    case SET_REMOTE_ACTION_LOADED:
      // general loading state, makes the above a bit redundant
      // but is needed to prevent crashes from sending too many actions at once
      newState.remoteActionLoaded = action.loaded;
      break;

    case HARDWARE_TYPE_LOADED:
      newState.hardwareTypesLoaded = action.loaded;
      break;

    case SET_HARDWARE_TYPES:
      newState.hardwareTypesLoaded = true;
      newState.hardwareTypes = action.types;
      break;

    default:
      return state;
  }

  return newState;
}
