import { unixTimestampToDate } from '../utils';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import Select from '@mui/material/Select';

export function issuingCardsColumns(handleChangeStatus) {
  return [
    {
      id: 'status',
      label: 'Status',
      noSortAble: false,
      disableCLick: true,
      shouldNotExport: true,
      format: (row) => (
        <div>
          <Select
            fullWidth
            name={'card_type'}
            id="status"
            value={row.status}
            onChange={(e) => {
              e.stopPropagation();
              handleChangeStatus(row.id, e.target.value);
            }}
          >
            {[
              { value: 'active', name: 'Active' },
              { value: 'inactive', name: 'Inactive' },
              { value: 'canceled', name: 'Canceled' },
            ].map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      ),
    },
    {
      id: 'cardholder_name',
      label: 'Cardholder Name',
      format: (row) => {
        return row.cardholder.name;
      },
    },
    {
      id: 'created',
      label: 'Created On',
      format: (row) => {
        return unixTimestampToDate(row.created);
      },
    },
    {
      id: 'last4',
      label: 'Last Four Digits',
    },
  ];
}
