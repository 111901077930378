import { IRental } from '../ts/interface/rental.interface';
import { getHardwareIconByName } from '../util/utilsR';
import ColumnAvatar from '../components/Redesing/column-avatar';
import Typography from '@mui/material/Typography';
import { SeverityPill } from '../components/Redesing/severity-pill';
import PersonIcon from '@mui/icons-material/Person';
import {
  convertCentsToMoney,
  formatPhoneNumber,
  getLocaleDateTimeString,
} from '../utils';
import React from 'react';

export function makeColumns(unitTitle: string, stationTitle: string, userId?: string) {
  const startStation = `Start ${stationTitle}`;
  const endStation = `End ${stationTitle}`;

  if (unitTitle === 'Locker' || unitTitle === 'Rack') {
    return [
      {
        id: 'hardwareNumber',
        label: 'Hardware',
        notSortable: true,
        format: (row: IRental) => {
          const { hardware } = row;
          const identifierName = hardware.number ? 'Number' : 'Gid';
          const { Icon, bgColor } = getHardwareIconByName(hardware.type_name);
          return hardware ? (
            <ColumnAvatar
              avatar={<Icon />}
              avatarColor={bgColor}
              firstRow={
                <Typography color="text.pramary" variant="body1">
                  {hardware.number || hardware.id}
                </Typography>
              }
              secondRow={
                <Typography color="text.secondary" variant="body2">
                  <SeverityPill color={'info'}>{identifierName}</SeverityPill>
                </Typography>
              }
            />
          ) : (
            ' '
          );
        },
      },
      ...(!Boolean(userId)
        ? [
            {
              id: 'userName',
              noSort: true,
              alignment: 'left',
              notSortable: true,
              label: 'User',
              width: '15%',
              format: (row: IRental) => {
                const { user } = row;
                return row.user ? (
                  <ColumnAvatar
                    avatar={<PersonIcon />}
                    firstRow={
                      <Typography color="text.pramary" variant="body1">
                        {user.full_name}
                      </Typography>
                    }
                    secondRow={
                      <Typography color="text.secondary" variant="body2">
                        <SeverityPill color={'info'}>
                          {formatPhoneNumber(user.phone_number)}
                        </SeverityPill>
                      </Typography>
                    }
                  />
                ) : (
                  ' '
                );
              },
            },
          ]
        : []),
      {
        id: 'startStationName',
        label: stationTitle,
        notSortable: true,
        format: (row: IRental) => row.station?.name || ' ',
      },
      {
        id: 'start.time',
        label: 'Start Time',
        format: (row: IRental) =>
          row.start && row.start.time ? getLocaleDateTimeString(row.start.time) : ' ',
      },
      {
        id: 'endTime',
        notSortable: true,
        label: 'End Time',
        format: (row: IRental) =>
          row.end && row.end.time ? getLocaleDateTimeString(row.end.time) : ' ',
      },
      ...(Boolean(userId)
        ? [
            {
              id: 'billing.total',
              label: 'Total',
              notSortable: true,
              format: (row: IRental) => convertCentsToMoney(row.billing?.total || 0),
            },
          ]
        : []),
    ];
  }

  return [
    {
      id: 'hardwareNumber',
      label: 'Hardware',
      notSortable: true,
      format: (row: IRental) => {
        const { hardware } = row;
        const identifierName = hardware.number ? 'Number' : 'Gid';
        const { Icon, bgColor } = getHardwareIconByName(hardware.type_name);
        return hardware ? (
          <ColumnAvatar
            avatar={<Icon />}
            avatarColor={bgColor}
            firstRow={
              <Typography color="text.pramary" variant="body1">
                {hardware.number || hardware.id}
              </Typography>
            }
            secondRow={
              <Typography color="text.secondary" variant="body2">
                <SeverityPill color={'info'}>{identifierName}</SeverityPill>
              </Typography>
            }
          />
        ) : (
          ' '
        );
      },
    },
    ...(!Boolean(userId)
      ? [
          {
            id: 'userName',
            noSort: true,
            alignment: 'left',
            notSortable: true,
            label: 'User',
            width: '15%',
            format: (row: IRental) => {
              const { user } = row;
              return row.user ? (
                <ColumnAvatar
                  avatar={<PersonIcon />}
                  firstRow={
                    <Typography color="text.pramary" variant="body1">
                      {user.full_name}
                    </Typography>
                  }
                  secondRow={
                    <Typography color="text.secondary" variant="body2">
                      <SeverityPill color={'info'}>
                        {formatPhoneNumber(user.phone_number)}
                      </SeverityPill>
                    </Typography>
                  }
                />
              ) : (
                ' '
              );
            },
          },
        ]
      : []),

    {
      id: 'startStationName',
      noSort: true,
      alignment: 'left',
      label: startStation,
      notSortable: true,
      width: '20%',
      format: (row: IRental) => row.start?.station?.name || ' ',
    },
    {
      id: 'start.time',
      alignment: 'left',
      label: 'Start Time',
      width: '20%',
      format: (row: IRental) =>
        row.start && row.start.time ? getLocaleDateTimeString(row.start.time) : ' ',
    },
    {
      id: 'endStationName',
      notSortable: true,
      noSort: true,
      alignment: 'left',
      label: endStation,
      width: '20%',
      format: (row: IRental) => row.end?.station?.name || ' ',
    },
    {
      id: 'endTime',
      noSort: true,
      alignment: 'left',
      notSortable: true,
      label: 'End Time',
      width: '20%',
      format: (row: IRental) =>
        row.end && row.end.time ? getLocaleDateTimeString(row.end.time) : ' ',
    },
    ...(Boolean(userId)
      ? [
          {
            id: 'billing.total',
            label: 'Total',
            notSortable: true,
            format: (row: IRental) => convertCentsToMoney(row.billing?.total || 0),
          },
        ]
      : []),
  ];
}
