import { useQuery } from '@tanstack/react-query';
import {
  exportRentals,
  exportLocationRentalsRoutes,
  exportSystemRentalsRoutes,
} from '../../../api/rentals';
import { IUseRental } from '../../../ts/interface/rental.interface';

const useExportRentals = (parameters?: IUseRental, options?: {}) => {
  return useQuery(
    ['rentals', parameters?.systemId],
    () => exportRentals(parameters?.queryParameters),
    { ...options }
  );
};

interface IUseExportRentalsRoutes extends IUseRental {
  locationUUID: string;
}

export const useExportRentalsRoutes = (
  parameters?: IUseExportRentalsRoutes,
  options?: {}
) => {
  return useQuery(
    ['rentalsRoutes', parameters?.systemId],
    () =>
      exportLocationRentalsRoutes(parameters?.locationUUID, parameters?.queryParameters),
    { ...options }
  );
};

export const useExportSystemRentalsRoutes = (parameters?: IUseRental, options?: {}) => {
  return useQuery(
    ['rentals', parameters?.systemId],
    () => exportSystemRentalsRoutes(parameters?.queryParameters),
    { ...options }
  );
};

export default useExportRentals;
