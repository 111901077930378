import { useQuery } from '@tanstack/react-query';
import { getUserMemberships } from '../../../api/memberships';
import { UseMembershipFilter } from '../../../ts/interface/membership.interface';
import { useSystemStore } from '../../../store/systemStore';

const useUserMemberships = (filter?: UseMembershipFilter, options?: {}) => {
  return useQuery(
    ['userMemberships', useSystemStore.getState().systemInfo.gid, filter],
    () => getUserMemberships(filter),
    { ...options }
  );
};

export default useUserMemberships;
