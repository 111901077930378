import React, { useState } from 'react';
import CardView from '../../../components/Redesing/card-view';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { IDocument } from '../../../ts/interface/membership.interface';
import { PaginationTable } from '../../../components/Redesing/table/pagination-table';
import {
  membershipsDocuments,
  membershipsDocumentsView,
} from '../../../tableColumns/membershipPricingColumns';
import AddIcon from '@mui/icons-material/Add';

interface ICardsViewDocumentsListProps {
  docs: IDocument[];
  handleRemoveDocument?: (id: string) => void;
  handleDocumentsTableRowClick?: (id: string) => void;
  handleAddDocument?: () => void;
  showActions?: boolean;
}

const MembershipDocumentsList: React.FC<ICardsViewDocumentsListProps> = (props) => {
  const {
    docs,
    handleDocumentsTableRowClick,
    handleRemoveDocument,
    handleAddDocument,
    showActions = false,
  } = props;
  const [searchRate, setSearchRate] = React.useState('');
  const [pageRate, setPageRate] = React.useState(0);
  const [rowsPerPageRate, setRowsPerPageRate] = React.useState(5);
  const [sortData, setSortData] = useState<string>('system_name');
  const [exportData, setExportData] = useState<boolean>(false);

  return (
    <CardView
      content={
        <PaginationTable
          padding={{ pt: 1, pl: 0, pr: 1 }}
          showFilter={false}
          exportData={exportData}
          sortBy={sortData}
          onSortChange={(sortBy) => setSortData(sortBy.key)}
          items={docs || []}
          csvFileName={'Documents.csv'}
          noDataText={'This membership has no documents at this time.'}
          columns={
            showActions
              ? membershipsDocuments(handleRemoveDocument, handleDocumentsTableRowClick)
              : membershipsDocumentsView()
          }
          rowsPerPage={rowsPerPageRate}
          page={pageRate}
          onPageChange={(page, pageN: number) => setPageRate(pageN)}
          onRowsPerPageChange={(rowsPerPage) =>
            setRowsPerPageRate(Number(rowsPerPage.target.value))
          }
          searchText={searchRate}
          searchPlaceholder={'Search membership documents'}
          handleSearch={(search) => setSearchRate(search.target.value)}
        />
      }
      title={'Required Documents'}
      headerActions={
        <IconButtonMenu
          buttons={[
            ...(!showActions
              ? [
                  {
                    label: 'Export',
                    onClick: () => setExportData(true),
                    startIcon: <FileDownloadIcon />,
                  },
                ]
              : []),
            ...(showActions
              ? [
                  {
                    label: 'Add',
                    onClick: handleAddDocument,
                    startIcon: <AddIcon />,
                  },
                ]
              : []),
          ]}
        />
      }
    />
  );
};

export default MembershipDocumentsList;
