import React, { useEffect, useMemo, useState } from 'react';
import {
  MovaticFieldGroupCheck,
  MovaticFieldGroupDate,
  MovaticFieldGroupOutlinedInput,
  MovaticFieldGroupSelect,
} from '../../components/Redesing/movatic-field-group';
import MenuItem from '@mui/material/MenuItem';
import MovaticCustomModal from '../../components/Modal/MovaticCustomModal';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

const pricingTypeOptions = [
  {
    value: 'interval',
    name: 'Interval',
  },
  {
    value: 'term',
    name: 'Term',
  },
];

const intervalUnitsOptions = [
  {
    value: 'days',
    name: 'Days',
  },
  {
    value: 'weeks',
    name: 'Weeks',
  },
  {
    value: 'months',
    name: 'Months',
  },
  {
    value: 'years',
    name: 'Years',
  },
];

// Creates an array of objects with values from 1 to 10
const priorityOptions = Array.from(Array(10), (value, index) => ({
  value: index + 1,
  name: `${index + 1}`,
}));

const MembershipPricing = (props) => {
  const { pricing } = props;

  const [price, setPrice] = useState(pricing ? pricing.price : '');
  const [priority, setPriority] = useState(pricing ? pricing.priority : 5);
  const [pricingType, setPricingType] = useState(
    pricing ? pricing.pricingType : pricingTypeOptions[0].value
  );
  const [intervalUnits, setIntervalUnits] = useState(
    pricing && pricing.pricingType === 'interval'
      ? pricing.intervalUnits
      : intervalUnitsOptions[0].value
  );
  const [intervalValue, setIntervalValue] = useState(
    pricing && pricing.pricingType === 'interval' ? pricing.intervalValue : ''
  );
  const [autoRenew, setAutoRenew] = useState(
    pricing && pricing.pricingType === 'interval' ? pricing.autoRenew : false
  );
  const [starts, setStarts] = useState(
    pricing && pricing.pricingType !== 'interval'
      ? pricing.starts
      : new Date().toISOString()
  );
  const [ends, setEnds] = useState(
    pricing && pricing.pricingType !== 'interval'
      ? pricing.ends
      : new Date().toISOString()
  );
  const [priceError, setPriceError] = useState('');
  const [intervalValueError, setIntervalValueError] = useState('');

  useEffect(() => {
    setPrice(pricing && pricing.price ? (pricing.price / 100).toFixed(2) : '');
    setPriority(pricing ? pricing.priority : 5);
    setPricingType(pricing ? pricing.pricingType : pricingTypeOptions[0].value);
    setIntervalUnits(
      pricing && pricing.pricingType === 'interval'
        ? pricing.intervalUnits
        : intervalUnitsOptions[0].value
    );
    setIntervalValue(
      pricing && pricing.pricingType === 'interval' ? pricing.intervalValue : ''
    );
    setAutoRenew(
      pricing && pricing.pricingType === 'interval' ? pricing.autoRenew : false
    );
    setStarts(
      pricing && pricing.pricingType !== 'interval'
        ? pricing.starts
        : new Date().toISOString()
    );
    setEnds(
      pricing && pricing.pricingType !== 'interval'
        ? pricing.ends
        : new Date().toISOString()
    );
  }, [pricing]);

  const handlePricingTypeChange = (event) => {
    setPricingType(event.target.value);
  };
  const handleIntervalUnitsChange = (event) => {
    setIntervalUnits(event.target.value);
  };
  const handleIntervalValueChange = (event) => {
    setIntervalValue(event.target.value);
    setIntervalValueError('');
  };
  const handlePriceChange = (event) => {
    setPrice(event.target.value);
    setPriceError('');
  };
  const handlePriorityChange = (event) => {
    setPriority(event.target.value);
  };
  const dateValidation = (date) => {
    if (new Date(date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
      return 'error';
    }
    return null;
  };
  const canSubmit = () => {
    if (!price || Math.round(price * 100) < 50) {
      setPriceError('Price needs to be above 50 cents.');
      return false;
    }
    if (pricingType === 'term' && ends < starts) {
      return false;
    }

    if (
      pricingType === 'interval' &&
      (Number.isNaN(parseInt(intervalValue, 10)) || parseInt(intervalValue, 10) <= 0)
    ) {
      setIntervalValueError('Interval value cannot be empty or 0.');
      return false;
    }

    return true;
  };
  const modalSubmit = () => {
    if (!canSubmit()) return;

    const priceObject = {
      id: props.pricing ? props.pricing.id : null,
      price: Math.round(price * 100),
      priority: parseInt(priority, 10),
      pricingType,
      intervalUnits,
      intervalValue: parseInt(intervalValue, 10),
      autoRenew,
      starts,
      ends,
    };

    setPrice('');
    setPriority(5);
    setPricingType(pricingTypeOptions[0].value);
    setIntervalUnits(intervalUnitsOptions[0].value);
    setIntervalValue('');
    setAutoRenew(false);
    setStarts(new Date().toISOString());
    setEnds(new Date().toISOString());
    setPriceError('');
    setIntervalValueError('');

    props.submit(priceObject);
  };
  const modalClose = () => {
    setPrice('');
    setPriority(5);
    setPricingType(pricingTypeOptions[0].value);
    setIntervalUnits(intervalUnitsOptions[0].value);
    setIntervalValue('');
    setAutoRenew(false);
    setStarts(new Date().toISOString());
    setEnds(new Date().toISOString());
    setPriceError('');
    setIntervalValueError('');

    props.close();
  };

  const minEndDay = useMemo(() => {
    const date = new Date(starts);
    date.setDate(date.getDate() + 1);
    return date;
  }, [starts]);

  const content = () => {
    return (
      <div>
        <MovaticFieldGroupOutlinedInput
          type={'number'}
          label="Price"
          description="Must be above 50 cents"
          value={price}
          inputProps={{ min: 0.5, step: 0.25 }}
          hasError={!!priceError}
          errorMessage={priceError}
          onChange={handlePriceChange}
          id="price"
          name={'price'}
          addOn={'$'}
        />

        <MovaticFieldGroupSelect
          label="Pricing Type"
          description="Select an interval or term-based pricing configuration"
          optionNodes={pricingTypeOptions.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
          value={pricingType}
          onChange={handlePricingTypeChange}
          name="pricingType"
          id="pricingType"
        />

        {pricingType === 'interval' ? (
          <>
            <MovaticFieldGroupSelect
              label="Pricing Type"
              description="Select an interval or term-based pricing configuration"
              optionNodes={intervalUnitsOptions.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
              value={intervalUnits}
              onChange={handleIntervalUnitsChange}
              name="intervalUnits"
              id="intervalUnits"
            />

            <MovaticFieldGroupOutlinedInput
              type={'number'}
              label="Interval Value"
              description={`The amount of ${intervalUnits} the membership will last.`}
              value={intervalValue}
              inputProps={{ min: 1, step: 1 }}
              hasError={!!intervalValueError}
              errorMessage={intervalValueError}
              onChange={handleIntervalValueChange}
              id="intervalValue"
              name={'intervalValue'}
            />

            <MovaticFieldGroupCheck
              name="autoRenew"
              label="Auto Renew"
              description="Check this box if the membership should be automatically renewed at the end of each interval"
              onChange={() => setAutoRenew((checked) => !checked)}
              checked={autoRenew}
            />
          </>
        ) : (
          <>
            <MovaticFieldGroupDate
              label="Starts On"
              description="The date on which this membership will start."
              minDate={new Date()}
              value={new Date(starts) || null}
              onChange={(value) => setStarts(new Date(value))}
            />

            <MovaticFieldGroupDate
              label="Ends On"
              description="The date on which this membership will end."
              minDate={minEndDay}
              value={new Date(ends) || null}
              onChange={(value) => {
                dateValidation(value);
                setEnds(value);
              }}
              hasError={ends < starts}
            />
          </>
        )}
        <MovaticFieldGroupSelect
          label="Priority"
          description="Select a priority value between 1 and 10."
          optionNodes={priorityOptions.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
          value={priority}
          onChange={handlePriorityChange}
          name="priority"
          id="priority"
        />
      </div>
    );
  };

  return (
    <>
      <MovaticCustomModal
        open={props.showModal}
        onClose={modalClose}
        title="Membership Pricing"
        content={content()}
        action={
          <IconButtonMenu
            buttons={[
              {
                label: 'Cancel',
                onClick: modalClose,
                startIcon: <CloseIcon />,
              },
              {
                label: 'Save',
                onClick: modalSubmit,
                disabled: props.disabled,
                startIcon: <SaveIcon />,
              },
            ]}
          />
        }
      />
    </>
  );
};

export default MembershipPricing;
