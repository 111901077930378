import React, { useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

//@ts-ignore
import { Circle, Map, Marker, Polygon } from 'react-leaflet/es';
import { GenericMapTileLayer } from '../mapComponent';
import { ISystem } from '../../ts/interface/system.interface';
import { LocationStatus } from '../../ts/enums';
import useStation from '../../hooks/stations/query/useStation';
import { IStation } from '../../ts/interface/station.interface';
import CardLoader from './card-loader';

import {
  stationConfigurations,
  showRadious,
  getLocationColors,
} from '../../util/stations';
import { mapHardwareMarker, mapStationMarker } from '../mapMarker';
import { IMode } from '../../ts/interface/mode.interface';

interface ICustomMapComponent {
  system: ISystem;
  modeInfo: IMode;
  hardwareInfo?: {
    locationId: string;
    lat: number;
    lng: number;
    marker: {
      current_rental: string;
      state: number;
      battery_percent: number;
      system_access: boolean;
      markerType: string;
    };
  };
}

const defaultCurrentLocationInfo = {
  configuration: 'system',
  polygon_coordinates: [],
  hardware: [],
  gid: '',
  name: '',
  old_id: '',
  available_hardware: 1,
  capacity: 0,
  totalHardware: 0,
  hardware_type: '',
  module: '',
  zone_type: 'system',
  parking_credit: 0,
  slow_zone_speed: 0,
  radius: 0,
  prevent_ending_rentals: false,
  reservations: false,
  maxReservations: 0,
  banner_id: '',
  prevent_starting_rentals: false,
  searchHardwareValue: '',
  private: false,
};

function CustomMapComponent({ system, modeInfo, hardwareInfo }: ICustomMapComponent) {
  const mapRef = useRef();
  const stationId = useParams<{ stationId: string }>().stationId;
  const [circleBound, setCircleBound] = useState([]);

  const locationId = hardwareInfo?.locationId ?? stationId;

  const { data: locationsAround, isLoading: isLoadingAroundLocations } = useStation(
    { enabled: true },
    { system_id: system?.gid, status: LocationStatus.all }
  );

  const locationsNear = useMemo(() => {
    return (
      locationsAround?.data?.filter((station: IStation) => {
        if (locationId) {
          return station.id !== locationId;
        }

        return (
          parseFloat(station.lat || '0') !== hardwareInfo?.lat &&
          parseFloat(station.lng || '0') !== hardwareInfo?.lng
        );
      }) || []
    );
  }, [locationsAround, locationId, hardwareInfo?.lat, hardwareInfo?.lng]);

  const { data: getCurrentLocation, isLoading: isCurrentLocationLoading } = useStation(
    {
      enabled: Boolean(locationId) || Boolean(hardwareInfo),
    },
    {
      locationId: locationId,
    }
  );

  const currentLocationData = useMemo(() => {
    if (locationId) {
      return (getCurrentLocation?.data?.[0] || {}) as IStation;
    }

    if (hardwareInfo?.lat && hardwareInfo?.lng) {
      const { lat, lng, marker } = hardwareInfo;
      return {
        lat: String(lat),
        lng: String(lng),
        id: `${lat}${lng}`,
        state: marker.state || 0,
        ...defaultCurrentLocationInfo,
      } as IStation;
    }
  }, [locationId, getCurrentLocation?.data, hardwareInfo]);

  const { id, configuration, radius, lat, lng, polygon_coordinates } =
    currentLocationData || {};

  const filteredLocationList = useMemo(() => {
    const locationList = [currentLocationData, ...locationsNear];
    return locationList.filter((location) => Object.keys(location).length !== 0);
  }, [currentLocationData, locationsNear]);

  const { WAREHOUSE, RACK, ZONE } = stationConfigurations;

  const showRadius = [RACK, WAREHOUSE].includes(configuration ?? '') && radius;

  const circleBounds =
    showRadius && circleBound && circleBound.length > 0 ? circleBound : null;

  return (
    <div data-id="custom-map">
      {isLoadingAroundLocations || isCurrentLocationLoading ? (
        <CardLoader useCard dataId="map-loader" />
      ) : (
        <Map
          ref={mapRef}
          key={`${id}`}
          center={configuration === ZONE ? null : [lat, lng]}
          zoom={17}
          style={{ height: '30em' }}
          bounds={configuration === ZONE ? polygon_coordinates : circleBounds}
          boundsOptions={{ padding: [15, 15] }}
        >
          <>
            {filteredLocationList.map((location: IStation) => {
              const { strokeColor, fillColor, strokeWidth, lineDashPattern } =
                getLocationColors(location);

              return (
                <div key={location.id}>
                  {location?.configuration !== ZONE && (
                    <Marker
                      id={location?.id}
                      key={location?.id}
                      position={[location?.lat, location?.lng]}
                      icon={mapStationMarker(modeInfo.details.hardware_type, location)}
                    />
                  )}
                  {showRadious(location) && location.id === locationId ? (
                    <Circle
                      id={location?.radius}
                      center={[location?.lat, location?.lng]}
                      radius={location.radius}
                      color={strokeColor}
                      fillColor={fillColor}
                      weight={strokeWidth}
                      stroke
                      onadd={(e: {
                        target: { getBounds: () => React.SetStateAction<never[]> };
                      }) => setCircleBound(e.target.getBounds())}
                    />
                  ) : null}
                  {location?.configuration === ZONE && (
                    <Polygon
                      positions={location.polygon_coordinates}
                      color={strokeColor}
                      fillColor={fillColor}
                      weight={strokeWidth}
                      dashArray={lineDashPattern}
                      stroke
                      key={location.id}
                    />
                  )}

                  {hardwareInfo?.marker && hardwareInfo?.lat && hardwareInfo?.lng && (
                    <Marker
                      position={[hardwareInfo?.lat, hardwareInfo?.lng]}
                      icon={mapHardwareMarker(
                        hardwareInfo.marker.markerType,
                        hardwareInfo.marker
                      )}
                    />
                  )}
                </div>
              );
            })}
            <GenericMapTileLayer />
          </>
        </Map>
      )}
    </div>
  );
}
export default CustomMapComponent;
