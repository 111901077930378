import React from 'react';
import {
  MovaticFieldGroupCheck,
  MovaticFieldGroupDate,
  MovaticFieldGroupOutlinedInput,
  MovaticFieldGroupText,
} from '../../../components/Redesing/movatic-field-group';

const FeeForm = (props) => {
  const { errors, values, handleChange, setFieldValue } = props.formik;
  return (
    <>
      <MovaticFieldGroupDate
        id="active_on"
        label="Active On"
        description="The date on which this fee will start being applied."
        value={new Date(values.active_on) || null}
        hasError={errors.active_on}
        errorMessage={errors.active_on}
        onChange={(date) => setFieldValue('active_on', date && date.toISOString())}
      />

      <MovaticFieldGroupDate
        id="archived_on"
        label="Archive On"
        description="The date on which this fee will stop being applied."
        value={new Date(values.archived_on) || null}
        hasError={errors.archived_on}
        errorMessage={errors.archived_on}
        onChange={(date) => setFieldValue('archived_on', date && date.toISOString())}
      />

      <MovaticFieldGroupText
        id="name"
        name="name"
        label="Name"
        description="This name will identify the fee to users on the app and their receipts."
        value={values.name ?? ''}
        placeholder={'Name'}
        hasError={errors.name}
        errorMessage={errors.name}
        onChange={handleChange}
      />

      <MovaticFieldGroupText
        id="description"
        name="description"
        label="Description"
        description="A complementary description to help explain what the fee is about."
        value={values.description ?? ''}
        placeholder={'Description'}
        hasError={errors.description}
        errorMessage={errors.description}
        onChange={handleChange}
      />

      <MovaticFieldGroupOutlinedInput
        type={'number'}
        label="Amount"
        description="The fee amount to charge."
        value={values.amount ?? ''}
        inputProps={{ max: 999, min: 0, step: 0.25 }}
        hasError={errors.amount}
        errorMessage={errors.amount}
        onChange={handleChange}
        id="amount"
        name={'amount'}
        addOn={'$'}
      />

      <MovaticFieldGroupOutlinedInput
        type={'number'}
        label="Apply above threshold"
        description="The rental fee amount above which this fee will be applied (inclusive)."
        value={values.applied_above_sub_total_threshold ?? ''}
        inputProps={{ step: 0.25 }}
        hasError={errors.applied_above_sub_total_threshold}
        errorMessage={errors.applied_above_sub_total_threshold}
        onChange={handleChange}
        id="applied_above_sub_total_threshold"
        name={'applied_above_sub_total_threshold'}
        addOn={'$'}
      />

      <MovaticFieldGroupOutlinedInput
        type={'number'}
        label="Apply below threshold"
        description="The rental fee amount below which this fee will be applied (not inclusive)."
        value={values.applied_under_sub_total_threshold ?? ''}
        inputProps={{ step: 0.25 }}
        hasError={errors.applied_under_sub_total_threshold}
        errorMessage={errors.applied_under_sub_total_threshold}
        onChange={handleChange}
        id="applied_under_sub_total_threshold"
        name={'applied_under_sub_total_threshold'}
        addOn={'$'}
      />

      <MovaticFieldGroupCheck
        label="Apply to members"
        description="If checked, the fee will also be applied to users with memberships."
        id="applied_to_members"
        name="applied_to_members"
        onChange={handleChange}
        checked={values.applied_to_members}
      />

      <MovaticFieldGroupCheck
        label="Apply post-tax"
        description="If checked, the fee will be applied after taxes."
        id="applied_post_tax"
        name="applied_post_tax"
        onChange={handleChange}
        checked={values.applied_post_tax}
      />
    </>
  );
};

export default FeeForm;
