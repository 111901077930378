import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ICoupon, ICouponEdit } from '../../ts/interface/coupon.interface';
import useCoupons from '../../hooks/coupons/query/useCoupons';
import { IMode } from '../../ts/interface/mode.interface';
import { connect } from 'react-redux';
import { findAccess, history } from '../../utils';
import { IAuth } from '../../ts/interface/admins.interface';
import { updateCoupon } from '../../api/coupons';
import {
  MovaticFieldGroupCheck,
  MovaticFieldGroupText,
} from '../../components/Redesing/movatic-field-group';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import SaveIcon from '@mui/icons-material/Save';
import { Stack } from '@mui/system';
import PageContainer from '../../components/Redesing/page-container';
import CardView from '../../components/Redesing/card-view';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';

const CouponEdit = ({
  systemLoaded,
  systemAccess,
  movaticAccess,
}: {
  systemLoaded: boolean;
  modeInfo: IMode;
  movaticAccess: number;
  systemAccess: number;
}) => {
  const { couponId } = useParams<{ couponId: string }>();
  const [name, setName] = useState<string | undefined>('');
  const [nameValidation, setNameValidation] = useState<string | null>(null);
  const [active, setActive] = useState<boolean | undefined>(false);

  const { isLoading } = useCoupons({
    enabled: systemLoaded,
    onSuccess: (data: { coupons: ICoupon[]; retired_coupons: ICoupon[] }) => {
      let coupon = data.coupons.find((coupon: ICoupon) => coupon.id === couponId);
      if (!coupon)
        coupon = data.retired_coupons.find((coupon: ICoupon) => coupon.id === couponId);
      if (coupon) {
        setName(coupon.name);
        setActive(coupon.active);
      }
    },
  });

  const submit = () => {
    if (!name) {
      setNameValidation('error');
      return;
    }
    const coupon = {
      id: couponId,
      name: name,
      active: active,
    };
    CustomAsyncToast({
      promise: () => updateCoupon(coupon as ICouponEdit),
      successMessage: () => 'Coupon updated successfully!',
      errorMessage: 'There was an error updating the coupon.',
      loadingMessage: 'Updating coupon...',
    }).then((response) => {
      if (response) {
        history.push(`/billing/coupon/${couponId}`);
      }
    });
  };

  return (
    <PageContainer isLoading={isLoading || !systemLoaded}>
      <CardView
        content={
          <Stack px={2} pb={2}>
            <MovaticFieldGroupText
              label="Coupon Name"
              description={'The name of the coupon.'}
              value={name}
              hasError={nameValidation === 'error'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setName(e.target.value);
                setNameValidation(null);
              }}
              id="name"
              name="name"
            />

            <MovaticFieldGroupCheck
              label="Active?"
              description="Would you like this coupon to be available to users?"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setActive(e.target.checked)
              }
              checked={active || false}
              name="active"
            />
          </Stack>
        }
        title={'Edit Coupon'}
        headerActions={
          <IconButtonMenu
            buttons={[
              {
                label: 'Cancel',
                onClick: () => history.push(`/billing/coupon/${couponId}`),
              },
              {
                label: 'Save',
                onClick: submit,
                startIcon: <SaveIcon />,
                disabled: !findAccess(systemAccess).includes('rates') && !movaticAccess,
              },
            ]}
          />
        }
      />
    </PageContainer>
  );
};

export default connect(
  (state: {
    system: { isLoaded: boolean };
    mode: IMode;
    admin: {
      admin: IAuth;
      systemAccess: number;
    };
  }) => ({
    systemLoaded: state.system.isLoaded,
    modeInfo: state.mode,
    movaticAccess: state.admin.admin.admin.movaticAccess,
    systemAccess: state.admin.systemAccess,
  }),
  () => ({})
)(CouponEdit);
