import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import type { SxProps } from '@mui/system/styleFunctionSx';
import Stack from '@mui/material/Stack';
import { connect } from 'react-redux';
import { ISystem } from '../../ts/interface/system.interface';
import { IAuth } from '../../ts/interface/admins.interface';
import SystemModal from './system-selection-modal';
import { IImage } from '../../ts/interface/image.interface';
import Avatar from '@mui/material/Avatar';
import PageLoader from '../../components/Redesing/page-loader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

interface TenantSwitchProps {
  sx?: SxProps;
  system: ISystem;
  isLoaded: boolean;
  images: IImage[];
  open?: boolean;
}

const SystemPartnerSection: FC<TenantSwitchProps> = (props) => {
  const { isLoaded } = props;
  const [openModal, setOpenModal] = useState(false);
  const [logo, setLogo] = useState<IImage | undefined>(undefined);
  useEffect(() => {
    const image = props.images.find((image) => image.id === props.system.logo_id);
    setLogo(image);
  }, [props.images, props.system]);

  const imageStyle = {
    height: '45px',
    maxWidth: '45px',
  };

  if (!isLoaded)
    return (
      <Stack
        alignItems="center"
        justifyContent={'center'}
        direction="row"
        {...props}
        padding={1}
      >
        <PageLoader
          applyMargin={false}
          minHeight={!props.open ? '0.5vh' : '1vh'}
          maxSize={'50px'}
        />
      </Stack>
    );
  return (
    <>
      <ListItemButton
        onClick={() => setOpenModal(!openModal)}
        sx={{ backgroundColor: 'inherit', color: 'inherit' }}
        id={'system-partner-section'}
      >
        {props.open ? (
          <>
            <ListItemIcon>
              <Avatar sx={{ bgcolor: 'inherit' }} aria-label="recipe">
                <img
                  style={{ ...imageStyle, borderRadius: '50%', objectFit: 'cover' }}
                  alt=""
                  src={
                    logo && logo.approved_on != null
                      ? `data:image/png;base64,${logo.image}`
                      : `${process.env.PUBLIC_URL}/img/no-station.png`
                  }
                />
              </Avatar>
            </ListItemIcon>
            <ListItemText
              primary={
                <Stack direction="column" justifyContent="center" alignItems="flex-start">
                  <Typography variant="subtitle1">{props.system.partner_name}</Typography>
                  <Typography
                    color="text.secondary"
                    variant="body1"
                    sx={{
                      overflow: 'hidden',
                      wordBreak: 'break-word', // Allow long words to break and wrap
                      whiteSpace: 'normal', // Reset whiteSpace property to its default
                      maxWidth: '100%', // Adjust the width as needed
                    }}
                  >
                    {props.system.name}
                  </Typography>
                </Stack>
              }
            />
          </>
        ) : (
          <ListItemIcon>
            <Avatar sx={{ bgcolor: 'inherit' }} aria-label="recipe">
              <img
                style={{ ...imageStyle, borderRadius: '50%', objectFit: 'cover' }}
                alt=""
                src={
                  logo && logo.approved_on != null
                    ? `data:image/png;base64,${logo.image}`
                    : `${process.env.PUBLIC_URL}/img/no-station.png`
                }
              />
            </Avatar>
          </ListItemIcon>
        )}
      </ListItemButton>
      {openModal && <SystemModal open={openModal} onClose={() => setOpenModal(false)} />}
    </>
  );
};

export default connect(
  (state: {
    system: { current: ISystem; isLoaded: boolean; systems: ISystem[]; images: IImage[] };
    admin: { admin: IAuth };
  }) => ({
    system: state.system.current,
    isLoaded: state.system.isLoaded,
    images: state.system.images,
  }),
  () => ({})
)(SystemPartnerSection);
