import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  getAllProductFields,
  addHardwareProduct,
} from '../../actionCreators/hardwareproduct';
import Card from '../../components/Card.jsx';
import Button from '../../components/Button';
import { FieldGroupAdd } from '../../components/fieldGroup.jsx';
import { history } from '../../utils';

class AddHardwareProduct extends Component {
  constructor(props) {
    super(props);

    this.state = {
      productFields: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
    this.content = this.content.bind(this);
    this.button = this.button.bind(this);
  }

  componentDidMount() {
    if (this.props.movaticAccess && !this.props.partner) {
      this.props.getAllProductFields();
    } else if (this.props.systemLoaded) {
      history.push('/');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.movaticAccess !== nextProps.movaticAccess ||
      this.props.partner !== nextProps.partner
    ) {
      if (nextProps.movaticAccess && !nextProps.partner) {
        this.props.getAllProductFields();
      } else {
        history.push('/');
      }
    }

    if (this.props.productFields !== nextProps.productFields) {
      this.setState({
        productFields: nextProps.productFields,
      });

      nextProps.productFields.forEach((field) => {
        this.setState({
          [field.name]: this.getDefaultFromType(field.type),
        });
      });
    }
  }

  getDefaultFromType(type) {
    if (type === 'BOOL') {
      return false;
    }
    return '';
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    });
  }

  save() {
    const product = { ...this.state };
    delete product.productFields;

    for (const field in product) {
      if (product[field] === '') {
        product[field] = null;
      }
    }

    this.props.addHardwareProduct(product);
    this.props.getAllProductFields();
  }

  button() {
    return (
      <Button
        buttons={1}
        primaryText={
          <span>
            <i className="fas fa-plus" /> Add
          </span>
        }
        primaryName="add"
        primaryOnClick={this.save}
      />
    );
  }

  content() {
    if (this.state.productFields.length === 0) return null;

    return (
      <div>
        <Link className="page_link" to="/addversion">
          Go to add product version
          <i className="fa fa-caret-right" />
        </Link>
        {this.state.productFields.map((field) => (
          <FieldGroupAdd
            type={field.type === 'BOOL' ? 'checkbox' : 'text'}
            checked={field.type === 'BOOL' ? this.state[field.name] : null}
            name={field.name}
            label={field.name}
            description={field.type}
            value={this.state[field.name]}
            handleChange={this.handleChange}
            key={field.name}
          />
        ))}
      </div>
    );
  }

  render() {
    return (
      <Card
        header="Add Hardware Product"
        loaded={this.state.productFields.length > 0}
        detailedView
        button={this.button()}
        content={this.content()}
      />
    );
  }
}

export default connect(
  (state) => ({
    productFields: state.hardwareProduct.productFields,
    fieldsLoaded: state.hardwareProduct.fieldsLoaded,
    systemLoaded: state.system.isLoaded,
    movaticAccess: state.admin.admin.admin.movaticAccess,
    partner: state.admin.admin.admin.partner,
  }),
  (dispatch) => ({
    getAllProductFields() {
      dispatch(getAllProductFields());
    },
    addHardwareProduct(product) {
      dispatch(addHardwareProduct(product));
    },
  })
)(AddHardwareProduct);
