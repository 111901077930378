import React, { Component } from 'react';
import { connect } from 'react-redux';

// action creators
import { gotSuccess, gotError, clear } from './actionCreators/toast';

// components
import Login from './pages/login';
import AddSystemPublic from './pages/systems/addPublic';
import TOSAcceptance from './pages/systems/tosAcceptance';
import { MOVATIC_TOS } from './constants';
import AddPublicUser from './pages/userPages/addPublicUser';
import { PAGES_NAMES } from './utils';
import { createTheme } from './theme';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { withRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { SettingsConsumer, SettingsProvider } from './context/settings';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DashboardLayout from './layout/dashboard-layout';
import ApproveCardholderTos from './pages/billing/issuing/issuingApproveTos';
import SystemSetup from './components/FloatingSetup/SystemSetup';
import Error404Page from './components/Redesing/404';

class AppComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null,
      hidden: false,
    };

    this.addNotification = this.addNotification.bind(this);
  }

  componentDidMount() {
    this.setState({
      _notificationSystem: this.refs.notificationSystem,
    });
  }

  addNotification(message, type, title, dismiss = 0) {
    if (this.refs.notificationSystem) {
      this.refs.notificationSystem.addNotification({
        message,
        level: type,
        autoDismiss: dismiss,
        title,
      });
    }
  }

  render() {
    const { admin, location } = this.props;
    const pageName = PAGES_NAMES.find((item) =>
      location?.pathname?.includes(item.path)
    )?.name;
    document.title = pageName ? `${pageName} | Movatic Admin` : 'Movatic Admin';

    if (location?.pathname === '/platformtos') {
      window.location = MOVATIC_TOS;
    }
    const theme = createTheme({
      colorPreset: 'blue',
      contrast: 'normal',
      direction: 'ltr',
      paletteMode: 'light',
      responsiveFontSizes: true,
    });

    if (location?.pathname === '/login') {
      return (
        <>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Toaster />
            <Login />
          </ThemeProvider>
        </>
      );
    }

    if (location?.pathname === '/404') {
      return (
        <>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Toaster />
            <Error404Page />
          </ThemeProvider>
        </>
      );
    }

    if (location?.pathname === '/create-your-system') {
      return (
        <>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Toaster />
            <AddSystemPublic />
          </ThemeProvider>
        </>
      );
    }

    if (location?.pathname?.startsWith('/tos/') && location.search.includes('userId')) {
      const url = window.location.href;
      const urlParts = url.split('/');
      const data = urlParts[urlParts.length - 1];
      const [systemId, userId] = data.split('?userId=');

      return <TOSAcceptance systemId={systemId} userId={userId} />;
    }

    if (location?.pathname?.includes('/billing/issuing/approve')) {
      const url = window.location.href;
      const urlParts = url.split('/');
      const cardholderId = urlParts[urlParts.length - 2];
      const systemId = urlParts[urlParts.length - 1];

      return (
        <>
          <ApproveCardholderTos cardholderId={cardholderId} systemId={systemId} />
        </>
      );
    }

    if (
      location?.pathname === '/create-your-user' &&
      location.search.includes('system')
    ) {
      return (
        <>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Toaster />
            <AddPublicUser />
          </ThemeProvider>
        </>
      );
    }
    return admin ? (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SettingsProvider>
          <SettingsConsumer>
            {(settings) => {
              const theme = createTheme({
                colorPreset: settings.colorPreset,
                contrast: settings.contrast,
                direction: settings.direction,
                paletteMode: settings.paletteMode,
                responsiveFontSizes: settings.responsiveFontSizes,
              });
              return (
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <Toaster />
                  <DashboardLayout>
                    <>
                      {this.props.children}
                      {this.props.system.demoSystem && !this.state.hidden && (
                        <SystemSetup
                          onCloseSetup={() => this.setState({ hidden: true })}
                          system={this.props.system}
                        />
                      )}
                    </>
                  </DashboardLayout>
                </ThemeProvider>
              );
            }}
          </SettingsConsumer>
        </SettingsProvider>
      </LocalizationProvider>
    ) : (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Toaster />
        <Login />
      </ThemeProvider>
    );
  }
}

const ConnectedApp = connect(
  (state) => ({
    admin: state.admin.admin,
    success: state.toast.success,
    error: state.toast.error,
    isLoaded: state.admin.dbLoaded,
    systemLoaded: state.system.isLoaded,
    system: state.system.current,
  }),
  (dispatch) => ({
    clearToast: (type) => {
      dispatch(clear(type));
    },
    toast(message, type) {
      if (type === 'success') dispatch(gotSuccess(message));
      else dispatch(gotError(message));
    },
  })
)(AppComponent);

export default withRouter(ConnectedApp);
