import { useQuery } from '@tanstack/react-query';
import { getStripeAccountInfo } from '../../../api/system';
import { useSystemStore } from '../../../store/systemStore';

const useStripeAccountInfo = (systemId?: string | null, options?: {}) => {
  return useQuery(
    ['stripeAccountInfo', useSystemStore.getState().systemInfo.gid],
    () => getStripeAccountInfo(systemId),
    {
      ...options,
    }
  );
};

export default useStripeAccountInfo;
