import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { history } from '../../utils';
import { ITransaction } from '../../ts/interface/rental.interface';
import { IDispute } from '../../ts/interface/transaction.interface';
import { SearchChip } from '../../ts/interface/components/table.interface';
import { ISortConfig } from '../../ts/interface/components/redesing/table.interface';
import { useUpdateEffect } from '../../hooks/use-update-effect';
import { PaginationTable } from '../../components/Redesing/table/pagination-table';
import ShareIcon from '@mui/icons-material/Share';
import { columns } from '../../tableColumns/transactionColumns';
import Card from '@mui/material/Card';
import { getAllStripeCharges, getAllStripeTransfers } from '../../api/system';
import { MovaticFieldGroupDate } from '../../components/Redesing/movatic-field-group';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';
import CustomToast from '../../components/Redesing/custom-toast';
import useUrlState from '../../hooks/use-url-state';
import { getDateAtLastSecond } from '../../helpers/dateHelpers';
const Transactions = ({ transactions, disputes, system, isLoading }: any) => {
  const [stateTransactions, setStateTransactions] = useState<any[]>([]);
  const [chips, setChips] = useState<SearchChip[]>([]);
  const [preSelectedStartDate, setPreSelectedStartDate] = useState('');
  const [preSelectedEndDate, setPreSelectedEndDate] = useState('');

  const [pageState, setPageState, isComplete, isRestoring] = useUrlState({
    sizePerPage: DEFAULT_ROWS_PER_PAGE,
    pageNumber: 0,
    search: '',
    startDate: '',
    endDate: '',
    sortConfig: {
      key: 'created',
      direction: 'asc',
    } as ISortConfig,
  });

  useUpdateEffect(() => {
    if (isRestoring && isComplete) {
      setPreSelectedStartDate(pageState.startDate);
      setPreSelectedEndDate(pageState.endDate);
      if (pageState.startDate) {
        setChips((prevChips) => {
          const chip: SearchChip = {
            label: 'Start Date',
            field: 'startDate',
            value: new Date(pageState.startDate),
            displayValue: new Date(pageState.startDate).toLocaleDateString(),
          };
          return [...prevChips.filter((chip) => chip.field !== 'startDate'), chip];
        });
      }
      if (pageState.endDate) {
        setChips((prevChips) => {
          const chip: SearchChip = {
            label: 'End Date',
            field: 'endDate',
            value: new Date(pageState.endDate),
            displayValue: new Date(pageState.endDate).toLocaleDateString(),
          };
          return [...prevChips.filter((chip) => chip.field !== 'endDate'), chip];
        });
      }
    }
  }, [isRestoring]);

  useEffect(() => {
    const startDate = pageState.startDate;
    const endDate = pageState.endDate;

    const filteredTransactionsWithChips = transactions.filter(
      (transaction: ITransaction) => {
        if (startDate && endDate) {
          const transactionDate = new Date(transaction.created);
          const startDateDate = new Date(startDate);
          const endDateDate = getDateAtLastSecond(new Date(endDate));
          return transactionDate >= startDateDate && transactionDate <= endDateDate;
        } else if (startDate) {
          return new Date(transaction.created) >= new Date(startDate);
        } else if (endDate) {
          return new Date(transaction.created) <= getDateAtLastSecond(new Date(endDate));
        }
        return true; // If no chips are applied, don't filter based on dates
      }
    );
    setStateTransactions(filteredTransactionsWithChips);
  }, [transactions, pageState.endDate, pageState.startDate]);

  const handleChipDelete = useCallback(
    (deletedGroup: SearchChip) => {
      if (deletedGroup.label === 'Start Date') {
        setPageState((prev) => ({
          ...prev,
          startDate: '',
        }));
        setPreSelectedStartDate('');
      }
      if (deletedGroup.label === 'End Date') {
        setPageState((prev) => ({
          ...prev,
          endDate: '',
        }));
        setPreSelectedEndDate('');
      }
      setChips((prevChips) => {
        return prevChips.filter((group) => {
          return group.label !== deletedGroup.label;
        });
      });
    },
    [setPageState]
  );

  const handleDateChangeNew = useCallback(
    (field: 'startDate' | 'endDate', date: Date | null): void => {
      if (date) {
        // @ts-ignore
        setChips((prevChips) => {
          const chip: SearchChip = {
            label: field === 'startDate' ? 'Start Date' : 'End Date',
            field: field,
            value: date,
            displayValue: date.toLocaleDateString(),
          };

          return [...prevChips.filter((chip) => chip.field !== field), chip];
        });
      }
    },
    []
  );

  const rowClick = (rowId: string) => {
    if (rowId.slice(0, 2) === 'dp') {
      history.push(`/billing/disputes/${rowId}`);
    } else if (rowId.slice(0, 2) === 'ch') {
      history.push(`/billing/transactions/detail/${rowId}`);
    } else if (rowId.slice(0, 2) === 're') {
      history.push(`/billing/transactions/detail/${rowId}`);
    } else {
      history.push(`/billing/transactions/detail/${rowId}`);
    }
  };

  const generateStripeTransfersCSV = () => {
    const startDateFromPageState = pageState?.startDate;
    const endDateFromPageState = pageState?.endDate;
    const startDate = startDateFromPageState && new Date(startDateFromPageState);
    const endDate =
      endDateFromPageState && getDateAtLastSecond(new Date(endDateFromPageState));
    let formattedStartDate = '';
    let formattedEndDate = '';
    if (startDate && endDate) {
      formattedStartDate = startDate.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      });
      formatEndDate(endDate);
      formattedEndDate = endDate.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      });
    }
    CustomAsyncToast({
      promise: () => getAllStripeTransfers(formattedStartDate, formattedEndDate),
      successMessage: () =>
        'The csv file is now being compiled. It may take a few minutes to be delivered!',
      errorMessage: 'Error generating CSV',
      loadingMessage: 'Starting to Generate CSV...',
    });
  };

  const generateStripePaymentCSV = () => {
    if (
      stateTransactions.filter((transaction) => transaction.type === 'Charge').length >
      3000
    ) {
      return CustomToast({
        type: 'error',
        message:
          'Attempting to download more than 3000 records. Please filter your results further and try again.',
      });
    }
    const startDateFromPageState = pageState?.startDate;
    const endDateFromPageState = pageState?.endDate;
    const startDate = startDateFromPageState && new Date(startDateFromPageState);
    const endDate =
      endDateFromPageState && getDateAtLastSecond(new Date(endDateFromPageState));
    let formattedStartDate = '';
    let formattedEndDate = '';
    if (startDate && endDate) {
      formattedStartDate = startDate.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      });
      formatEndDate(endDate);
      formattedEndDate = endDate.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      });
    }
    CustomAsyncToast({
      promise: () => getAllStripeCharges(formattedStartDate, formattedEndDate),
      successMessage: () =>
        'The csv file is now being compiled. It may take a few minutes to be delivered!',
      errorMessage: 'Error generating CSV',
      loadingMessage: 'Starting to Generate CSV...',
    });
  };

  const formatEndDate = (eDate: Date | null) => {
    if (eDate != null) {
      eDate.setDate(eDate.getDate() + 1);
    }
  };

  const disputeLoaded = disputes.filter(
    (dispute: IDispute) => dispute.system === system.id
  );
  const tableFormatDisputes = disputeLoaded.map((dispute: IDispute) => {
    return {
      amount: dispute.net,
      charge_id: dispute.charge_id,
      charge: dispute.amount,
      created: dispute.created,
      description: dispute.reason,
      evidence_duedate: dispute.evidence_duedate,
      fee: dispute.fee,
      id: dispute.id,
      net: dispute.net,
      reason: dispute.reason,
      status: dispute.status,
      type: 'Dispute',
      userPhone: dispute.userPhone,
    };
  });

  const transactionsAndDisputes = useMemo(() => {
    return [...tableFormatDisputes, ...stateTransactions];
  }, [tableFormatDisputes, stateTransactions]);

  const { hideExportPayments, hideExportTransfers } = system; // Update with the correct properties
  return (
    <Card>
      <PaginationTable
        dataId={'transactions'}
        isLoading={isLoading}
        onRowCLick={rowClick}
        onSortChange={(sort) =>
          setPageState((prev) => ({ ...prev, sortConfig: sort as ISortConfig }))
        }
        sortBy={pageState.sortConfig.key}
        padding={{ pt: 1, pl: 0, pr: 1 }}
        chips={chips}
        showFilter
        handleChipDelete={handleChipDelete}
        onApplyFilter={() => {
          let applyedFilter = false;
          if (preSelectedStartDate) {
            handleDateChangeNew('startDate', new Date(preSelectedStartDate));
            setPageState((prev) => ({ ...prev, startDate: preSelectedStartDate }));
            applyedFilter = true;
          }
          if (preSelectedEndDate) {
            handleDateChangeNew('endDate', new Date(preSelectedEndDate));
            setPageState((prev) => ({ ...prev, endDate: preSelectedEndDate }));
            applyedFilter = true;
          }
          if (!applyedFilter) {
            setChips([]);
          }
        }}
        spaceFilters={
          <>
            <MovaticFieldGroupDate
              label="Start Date"
              value={new Date(preSelectedStartDate) || null}
              onChange={(date) => setPreSelectedStartDate(date)}
            />
            <MovaticFieldGroupDate
              label="End Date"
              value={new Date(preSelectedEndDate) || null}
              onChange={(date) => setPreSelectedEndDate(date)}
            />
          </>
        }
        columns={columns}
        buttons={[
          {
            startIcon: <ShareIcon />,
            variant: 'text',
            label: 'Export Transfers',
            onClick: generateStripeTransfersCSV,
            disabled: hideExportTransfers,
          },
          {
            startIcon: <ShareIcon />,
            variant: 'text',
            label: 'Export Payments',
            onClick: generateStripePaymentCSV,
            disabled: hideExportPayments,
          },
        ]}
        items={transactionsAndDisputes || []}
        searchText={pageState.search}
        handleSearch={(search) =>
          setPageState((prev) => ({ ...prev, search: search.target.value }))
        }
        searchPlaceholder={'Search transactions'}
        page={pageState.pageNumber}
        rowsPerPage={pageState.sizePerPage}
        onPageChange={(page, newPage) =>
          setPageState((prev) => ({ ...prev, pageNumber: newPage }))
        }
        onRowsPerPageChange={(pageSizeString) =>
          setPageState((prev) => ({
            ...prev,
            sizePerPage: Number(pageSizeString.target.value),
          }))
        }
      />
    </Card>
  );
};

export default connect(
  (state: any) => ({
    auth: state.admin.admin,
    billingTransactionPage: state.table.billingTransactionPage,
    billingTransactionSize: state.table.billingTransactionSize,
    disputes: state.disputes.disputes,
    rentals: state.rental.rentals,
    search: state.search,
    system: state.system.current,
  }),
  () => ({})
)(Transactions);
