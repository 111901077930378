import { useQuery } from '@tanstack/react-query';
import { getBankInfo } from '../../../api/system';

const useBankInfo = (options?: {}) => {
  return useQuery(['systemBankInfo'], getBankInfo, {
    ...options,
  });
};

export default useBankInfo;
