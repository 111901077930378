import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { findAccess, history } from '../../../utils.jsx';
import ArchiveIcon from '@mui/icons-material/Archive';
import { getSystemsAdmins, setSystemAccess } from '../../../actionCreators/admin';
import { update } from '../../../actionCreators/system';
import MovaticCustomModal from '../../../components/Modal/MovaticCustomModal';
import { ADMIN_PERMISSIONS } from '../../../constants.js';
import PageContainer from '../../../components/Redesing/page-container';
import CardView from '../../../components/Redesing/card-view';
import { Stack } from '@mui/system';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CustomAsyncToast from '../../../components/Redesing/custom-async-toast';
import { updateAdmin } from '../../../api/admin';
import { useParams } from 'react-router-dom';
import useSystemAdmins from '../../../hooks/system/query/useSystemAdmins';
import { IAdmin, IAuth } from '../../../ts/interface/admins.interface';
import PageContent from '../../../components/Redesing/page-content';
import { MovaticFieldGroupCheck } from '../../../components/Redesing/movatic-field-group';

interface AdminDetailProps {
  auth: IAuth;
  systemAccess: number;
  systemLoaded: boolean;
  setSystemAccess: (access: number) => void;
}

const AdminDetail: React.FC<AdminDetailProps> = ({
  auth,
  systemLoaded,
  setSystemAccess,
  systemAccess,
}) => {
  const { email } = useParams<{ email: string }>();
  const [showEdit, setShowEdit] = useState(false);
  const [adminObj, setAdminObj] = useState<any>({}); // Define the correct type for adminObj
  const [confirm, setConfirm] = useState(false);
  const adminEditPermission = findAccess(systemAccess).includes('admin');
  const [currentTabV, setCurrentTabV] = useState('general');
  const handleTabsChange = useCallback(
    (event: React.SyntheticEvent, value: React.SetStateAction<string>) => {
      setCurrentTabV(value);
    },
    []
  );

  const { data: allAdmins = [] } = useSystemAdmins({
    enabled: systemLoaded,
  });

  useEffect(() => {
    const admin = allAdmins.find((admin: IAdmin) => admin.email === email);
    const adminAccess = findAccess(admin?.access);
    adminAccess.forEach((eachAccess: string) => {
      admin[eachAccess] = true;
    });
    setAdminObj(admin);
  }, [allAdmins, email]);

  const warning = useMemo(() => {
    if (adminObj && adminObj.access === 1 && !showEdit) {
      return 'This user has no permissions';
    }
    return undefined;
  }, [adminObj, showEdit]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const adminCopy = { ...adminObj };
    const value =
      event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    switch (event.target.name) {
      case 'admin':
        if (value) {
          adminCopy.access = ADMIN_PERMISSIONS['full'];
        } else {
          adminCopy.access -= ADMIN_PERMISSIONS['admin'];
        }
        break;
      case 'rental':
        if (value) {
          adminCopy.access += ADMIN_PERMISSIONS['rental'];
        } else {
          adminCopy.access -= ADMIN_PERMISSIONS['rental'];

          if (adminCopy.access & ADMIN_PERMISSIONS['admin']) {
            adminCopy.access -= ADMIN_PERMISSIONS['admin'];
          }
        }
        break;
      case 'rates':
        if (value) {
          adminCopy.access += ADMIN_PERMISSIONS['rates'];
        } else {
          adminCopy.access -= ADMIN_PERMISSIONS['rates'];

          if (adminCopy.access & ADMIN_PERMISSIONS['admin']) {
            adminCopy.access -= ADMIN_PERMISSIONS['admin'];
          }
        }
        break;
      case 'refunds':
        if (value) {
          adminCopy.access += ADMIN_PERMISSIONS['refunds'];
        } else {
          adminCopy.access -= ADMIN_PERMISSIONS['refunds'];

          if (adminCopy.access & ADMIN_PERMISSIONS['admin']) {
            adminCopy.access -= ADMIN_PERMISSIONS['admin'];
          }
        }
        break;
      case 'bank':
        if (value) {
          adminCopy.access += ADMIN_PERMISSIONS['bank'];
        } else {
          adminCopy.access -= ADMIN_PERMISSIONS['bank'];

          if (adminCopy.access & ADMIN_PERMISSIONS['admin']) {
            adminCopy.access -= ADMIN_PERMISSIONS['admin'];
          }
        }
        break;
      case 'physical':
        if (value) {
          adminCopy.access += ADMIN_PERMISSIONS['physical'];
        } else {
          adminCopy.access -= ADMIN_PERMISSIONS['physical'];

          if (adminCopy.access & ADMIN_PERMISSIONS['admin']) {
            adminCopy.access -= ADMIN_PERMISSIONS['admin'];
          }
        }
        break;
      case 'users':
        if (value) {
          adminCopy.access += ADMIN_PERMISSIONS['users'];
        } else {
          adminCopy.access -= ADMIN_PERMISSIONS['users'];

          if (adminCopy.access & ADMIN_PERMISSIONS['admin']) {
            adminCopy.access -= ADMIN_PERMISSIONS['admin'];
          }
        }
        break;
      case 'locations':
        if (value) {
          adminCopy.access += ADMIN_PERMISSIONS['locations'];
        } else {
          adminCopy.access -= ADMIN_PERMISSIONS['locations'];

          if (adminCopy.access & ADMIN_PERMISSIONS['admin']) {
            adminCopy.access -= ADMIN_PERMISSIONS['admin'];
          }
        }
        break;
      case 'support':
        if (value) {
          adminCopy.access += ADMIN_PERMISSIONS['support'];
        } else {
          adminCopy.access -= ADMIN_PERMISSIONS['support'];

          if (adminCopy.access & ADMIN_PERMISSIONS['admin']) {
            adminCopy.access -= ADMIN_PERMISSIONS['admin'];
          }
        }
        break;
      case 'maintenance':
        if (value) {
          adminCopy.access += ADMIN_PERMISSIONS['maintenance'];
        } else {
          adminCopy.access -= ADMIN_PERMISSIONS['maintenance'];

          if (adminCopy.access & ADMIN_PERMISSIONS['admin']) {
            adminCopy.access -= ADMIN_PERMISSIONS['admin'];
          }
        }
        break;
      case 'memberships':
        if (value) {
          adminCopy.access += ADMIN_PERMISSIONS['memberships'];
        } else {
          adminCopy.access -= ADMIN_PERMISSIONS['memberships'];

          if (adminCopy.access & ADMIN_PERMISSIONS['admin']) {
            adminCopy.access -= ADMIN_PERMISSIONS['admin'];
          }
        }
        break;
      case 'report':
        adminCopy.error_report_emails = value;
        break;
      case 'pending':
        adminCopy.pending_user_emails = value;
        break;
    }

    setAdminObj(adminCopy);
  };

  const saveAccess = () => {
    const adminCopy = { ...adminObj };
    save(adminCopy, 'showEdit');
  };

  const archiveAdmin = () => {
    const adminCopy = { ...adminObj, archive: true };
    save(adminCopy, 'confirm');
  };

  const save = (adminObj: any, modal: string | null) => {
    setShowEdit(!showEdit);
    CustomAsyncToast({
      promise: () => updateAdmin(adminObj),
      successMessage: () => {
        if (auth.admin.email === adminObj.email) {
          setSystemAccess(adminObj.access);
        }
        if (modal) {
          setConfirm(modal === 'confirm');
          if (modal === 'confirm') {
            return 'Admin Archived';
          }
        }
        return 'Admin updated';
      },
      errorMessage: 'There was an error updating the admin',
      loadingMessage: 'Updating admin...',
    }).then((success) => {
      if (success && modal === 'confirm') {
        history.push('/settings/admin');
      }
    });
  };

  const button = () => {
    if (showEdit) {
      return (
        <>
          <IconButtonMenu
            buttons={[
              {
                label: 'Cancel',
                onClick: () => setShowEdit(!showEdit),
                disabled: !adminEditPermission && !auth.admin.movaticAccess,
              },
              {
                label: 'Save',
                onClick: () => saveAccess(),
                disabled: !adminEditPermission && !auth.admin.movaticAccess,
                startIcon: <SaveIcon />,
              },
            ]}
          />
        </>
      );
    }

    return (
      <>
        <IconButtonMenu
          buttons={[
            {
              label: 'Archive',
              onClick: () => setConfirm(!confirm),
              disabled: !adminEditPermission && !auth.admin.movaticAccess,
              startIcon: <ArchiveIcon />,
            },
            {
              label: 'Edit',
              onClick: () => setShowEdit(!showEdit),
              disabled: !adminEditPermission && !auth.admin.movaticAccess,
              startIcon: <EditIcon />,
            },
          ]}
        />
      </>
    );
  };

  const content = () => {
    const { access } = adminObj || {};
    const permissions = [
      {
        label: 'Admin',
        description:
          'Create other admin users (if this option is selected then admin has all permissions)',
        name: 'admin',
        checked: Boolean(access & ADMIN_PERMISSIONS['admin']),
      },
      {
        label: 'Users',
        description: 'Permission to manage users',
        name: 'users',
        checked: Boolean(access & ADMIN_PERMISSIONS['users']),
      },
      {
        label: 'Rentals',
        description: 'Permission to end rentals',
        name: 'rental',
        checked: Boolean(access & ADMIN_PERMISSIONS['rental']),
      },
      {
        label: 'Hardware',
        description: 'Permission to create or edit hardware',
        name: 'physical',
        checked: Boolean(access & ADMIN_PERMISSIONS['physical']),
      },
      {
        label: 'Locations',
        description: 'Permission to create or edit locations',
        name: 'locations',
        checked: Boolean(access & ADMIN_PERMISSIONS['locations']),
      },
      {
        label: 'Customer Support',
        description: 'Permission to manage support tickets',
        name: 'support',
        checked: Boolean(access & ADMIN_PERMISSIONS['support']),
      },
      {
        label: 'Maintenance',
        description: 'Permission to manage hardware tickets and logs',
        name: 'maintenance',
        checked: Boolean(access & ADMIN_PERMISSIONS['maintenance']),
      },
      {
        label: 'Memberships',
        description: 'Permission to manage user memberships',
        name: 'memberships',
        checked: Boolean(access & ADMIN_PERMISSIONS['memberships']),
      },
      {
        label: 'Rates',
        description: 'Permission to create, archive, or edit rates',
        name: 'rates',
        checked: Boolean(access & ADMIN_PERMISSIONS['rates']),
      },
      {
        label: 'Refunds',
        description: 'Permission to issue refunds to users',
        name: 'refunds',
        checked: Boolean(access & ADMIN_PERMISSIONS['refunds']),
      },
      {
        label: 'Settings',
        description: 'Permission to edit and view settings',
        name: 'bank',
        checked: Boolean(access & ADMIN_PERMISSIONS['bank']),
      },
    ];
    return (
      <Stack spacing={2}>
        <CardView
          content={
            <Stack px={2} pb={2}>
              {permissions.map((permission) => (
                <MovaticFieldGroupCheck
                  key={permission.name}
                  id={permission.name}
                  label={permission.label}
                  description={permission.description}
                  onChange={handleChange}
                  checked={permission.checked}
                  disabled={!showEdit}
                  name={permission.name}
                />
              ))}
            </Stack>
          }
          title={'Admin Permissions'}
        />
      </Stack>
    );
  };

  const contentNotifications = () => {
    const { pending_user_emails, error_report_emails } = adminObj || {};
    const emailNotifications = [
      {
        label: 'Pending Users',
        description: 'Receive notifications for new pending users',
        name: 'pending',
        checked: Boolean(pending_user_emails),
      },
      {
        label: 'Problem Reports',
        description: 'Receive notifications for new problem reports submitted by users',
        name: 'report',
        checked: Boolean(error_report_emails),
      },
    ];

    return (
      <Stack spacing={2}>
        <CardView
          content={
            <Stack px={2} pb={2}>
              {emailNotifications.map((permission) => (
                <MovaticFieldGroupCheck
                  key={permission.name}
                  id={permission.name}
                  label={permission.label}
                  description={permission.description}
                  onChange={handleChange}
                  checked={permission.checked}
                  disabled={!showEdit}
                  name={permission.name}
                />
              ))}
            </Stack>
          }
          title={'Email Membership Notifications'}
        />
      </Stack>
    );
  };

  const modal = () => {
    return (
      <>
        <MovaticCustomModal
          title={'Archive Admin'}
          open={confirm}
          onClose={() => setConfirm(!confirm)}
          content={<div>Are you sure you would like to archive this admin?</div>}
          action={
            <IconButtonMenu
              buttons={[
                {
                  label: 'Cancel',
                  onClick: () => setConfirm(!confirm),
                  disabled: !adminEditPermission && !auth.admin.movaticAccess,
                },
                {
                  label: 'Archive',
                  onClick: () => archiveAdmin(),
                  disabled: !adminEditPermission && !auth.admin.movaticAccess,
                  startIcon: <ArchiveIcon />,
                },
              ]}
            />
          }
        />
      </>
    );
  };

  return (
    <PageContainer warningMessage={warning} isLoading={!systemLoaded}>
      <PageContent
        backLink={'/settings/admin'}
        contentTitle={'System Admins'}
        itemName={`${adminObj?.firstName} ${adminObj?.lastName}`}
        itemChipValue={adminObj?.email}
        chipLabel={'Admin Email'}
        currentTabV={currentTabV}
        itemTopButtons={button()}
        handleTabsChange={handleTabsChange}
        tabsList={[
          { label: 'General', value: 'general' },
          { label: 'Notifications', value: 'notifications' },
        ]}
        tabsChildren={
          <>
            {currentTabV === 'general' && <>{content()}</>}
            {currentTabV === 'notifications' && <>{contentNotifications()}</>}
          </>
        }
      />
      {modal()}
    </PageContainer>
  );
};

export default connect(
  (state: any) => ({
    auth: state.admin.admin,
    systemAccess: state.admin.systemAccess,
    system: state.system.current,
    systemLoaded: state.system.isLoaded,
    admins: state.admin.systemAdmins,
  }),
  (dispatch: Function) => ({
    update(loading: boolean) {
      dispatch(update(loading));
    },
    getSystemsAdmins: () => {
      dispatch(getSystemsAdmins());
    },
    setSystemAccess: (access: number) => {
      dispatch(setSystemAccess(access));
    },
  })
)(AdminDetail);
