import React, { useState, useEffect } from 'react';
import { useFormik, yupToFormErrors } from 'formik';

import RentalForm from './rentalForm';
import { history, toTitleCase, stripEmptyFields } from '../../../utils';
import rentalSchema from './rentalSchema';
import useHardware from '../../../hooks/hardware/query/useHardware';
import useUserMembership from '../../../hooks/users/query/useUserMembership';
import useRates from '../../../hooks/rates/query/useRates';
import useCoupons from '../../../hooks/coupons/query/useCoupons';
import useUser from '../../../hooks/users/query/useUser';
import { useParams } from 'react-router-dom';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import CustomAsyncToast from '../../../components/Redesing/custom-async-toast';
import { startRental } from '../../../api/rentals';
import PageContainer from '../../../components/Redesing/page-container';
import CardView from '../../../components/Redesing/card-view';
import { Stack } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import { useSystemStore } from '../../../store/systemStore';

const AddRental = () => {
  const { userPhone } = useParams();
  const [user, setUser] = useState(null);
  const [hardware, setHardware] = useState([]);
  const [selectedHardware, setSelectedHardware] = useState([]);
  const [selectedStation, setSelectedStation] = useState(null);
  const [userMemberships, setUserMemberships] = useState([]);
  const [rates, setRates] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [hardwareParams, setHardwareParams] = useState({});

  const { refetch: refetchUser } = useUser(userPhone, { enabled: false });
  const { refetch: refetchHardware } = useHardware({ enabled: false }, hardwareParams);
  const { refetch: refetchMemberships } = useUserMembership(
    { user_id: user?.id },
    { enabled: false }
  );
  const { refetch: refetchRates } = useRates({ enabled: false });
  const { refetch: refetchCoupons } = useCoupons({ enabled: false });

  const formik = useFormik({
    initialValues: {
      ...rentalSchema.default(),
      user_id: user?.id,
    },
    validationSchema: rentalSchema,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    validate: (values) => {
      return rentalSchema
        .validate(values, { abortEarly: false, context: {} })
        .then(() => null)
        .catch((err) => {
          return yupToFormErrors(err);
        });
    },
    onSubmit: (values) => {
      CustomAsyncToast({
        promise: () =>
          startRental(
            stripEmptyFields(rentalSchema.cast(values, { stripUnknown: true }))
          ),
        successMessage: () => 'The rental has been started successfully!',
        loadingMessage: () => 'Starting rental...',
        errorMessage: () => 'Something went wrong while starting the rental.',
      }).then((r) => {
        if (r) {
          history.push(`/users/${userPhone}/rentals`);
        }
      });
    },
  });

  useEffect(() => {
    refetchUser().then((userObject) => setUser(userObject?.data?.userDetails));
  }, [userPhone, refetchUser]);

  useEffect(() => {
    if (user) {
      refetchHardware().then((hardwareObject) => setHardware(hardwareObject?.data.data));
      refetchMemberships().then((membershipsObject) =>
        setUserMemberships(membershipsObject.data)
      );
      refetchRates().then((ratesObject) => setRates(ratesObject.data));
      refetchCoupons().then((couponsObject) => setCoupons(couponsObject.data.coupons));
    }
  }, [user, refetchHardware, refetchMemberships, refetchRates, refetchCoupons]);

  useEffect(() => {
    refetchHardware().then((hardwareObject) => setHardware(hardwareObject?.data.data));
  }, [hardwareParams, refetchHardware]);

  useEffect(() => {
    const getHardwareParameters =
      selectedStation && selectedStation !== 'None'
        ? { queryParameters: { location_id: selectedStation } }
        : {};
    setHardwareParams(getHardwareParameters);
    setSelectedHardware([]);
  }, [selectedStation]);

  const handleChange = (event) => {
    if (event.target.name === 'station_id') {
      setSelectedStation(event.target.value);
    }

    formik.handleChange(event);
  };

  const handleLocationChange = (event, station) => {
    setSelectedStation(station.value);
    event.target.value = station.value;
    event.target.name = 'station_id';
    formik.handleChange(event);
  };

  const hardwareOptions = hardware
    .filter((unit) => unit.number !== null)
    .map((unit) => ({
      name: `${toTitleCase(unit.hardware_type?.name || 'Unit')} ${unit.number}`,
      value: unit.id,
    }));

  const systemGid = useSystemStore((state) => state.systemInfo.gid);

  const userMembershipOptions = userMemberships.map((userMembership) => ({
    name: userMembership.membership.name,
    value: userMembership.membership.perks.find((perk) => perk.system_uuid === systemGid)
      ?.uuid,
  }));

  const rateOptions = rates.map((rate) => ({ name: rate.name, value: rate.gid }));

  const couponOptions = coupons.map((coupon) => ({
    name: coupon.code,
    value: coupon.gid,
  }));

  const options = {
    user_id: [{ name: `${user?.fullName} - ${user?.phone}`, value: user?.id }],
    hardware_id: hardwareOptions,
    perk_id: [{ name: 'None', value: null }, ...userMembershipOptions],
    rate_id: [{ name: 'None', value: null }, ...rateOptions],
    coupon_id: [{ name: 'None', value: null }, ...couponOptions],
  };

  return (
    <PageContainer>
      <CardView
        title={`Start Rental for ${user?.fullName}`}
        content={
          <Stack px={2} pb={2}>
            <RentalForm
              formik={formik}
              options={options}
              selectedHardware={selectedHardware}
              setSelectedHardware={setSelectedHardware}
              handleChange={handleChange}
              handleLocationChange={handleLocationChange}
            />
          </Stack>
        }
        headerActions={
          <IconButtonMenu
            buttons={[
              {
                label: 'Cancel',
                onClick: () => history.push(`/users/${userPhone}/rentals`),
              },
              {
                label: 'Start',
                onClick: formik.handleSubmit,
                startIcon: <AddIcon />,
              },
            ]}
          />
        }
      />
    </PageContainer>
  );
};

export default AddRental;
