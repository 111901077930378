import { useQuery } from '@tanstack/react-query';
import { baseUrlV2, createQueryParameters } from '../../../api/http';
import { getIssuingCardResource } from '../../../api/issuingCard';
import { IUseAllIssuingCards } from '../../../ts/interface/issuingCard.interface';

const useAllIssuingCards = (parameters?: IUseAllIssuingCards, options: {} = {}) => {
  const errorMessage =
    'Oops! Looks like there was an error retrieving the issuing cards. Please try again later.';
  const endpoint = `${baseUrlV2}/billing/billing/issuingcards`;

  return useQuery(
    ['issuingcards'],
    () =>
      getIssuingCardResource(
        `${endpoint}?${createQueryParameters(parameters)}`,
        true,
        errorMessage
      ),
    { ...options }
  );
};

export default useAllIssuingCards;
