import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import useAllTask from '../../hooks/maintenance/query/useAllTask';
import useSystemAdmins from '../../hooks/system/query/useSystemAdmins';
import useAllTickets from '../../hooks/maintenance/query/useAllTickets';
import { useParams } from 'react-router-dom';
import useOneHardware from '../../hooks/hardware/query/useOneHardware';
import { connect } from 'react-redux';
import { ISystem } from '../../ts/interface/system.interface';
import { IMode } from '../../ts/interface/mode.interface';
import { IHardware } from '../../ts/interface/hardware.interface';
import { toTitleCase, history, isEmptyObject, getPriorityName } from '../../utils';
import useOneProblem from '../../hooks/maintenance/query/useOneProblem';
import { ITask, ITicket } from '../../ts/interface/problem.interface';
import { IAdmin } from '../../ts/interface/admins.interface';
import { createTicket } from '../../api/problems';
import useAllHardware from '../../hooks/hardware/query/useAllHardware';
import MovaticCustomModal from '../../components/Modal/MovaticCustomModal';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import {
  MovaticFieldGroupDate,
  MovaticFieldGroupSelect,
  MovaticFieldGroupText,
  MovaticFieldItemLink,
} from '../../components/Redesing/movatic-field-group';
import MenuItem from '@mui/material/MenuItem';
import PageContainer from '../../components/Redesing/page-container';
import CardView from '../../components/Redesing/card-view';
import { Stack } from '@mui/system';
import SaveIcon from '@mui/icons-material/Save';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';
import CustomToast from '../../components/Redesing/custom-toast';

interface IAddTicketState {
  escalated: boolean;
  priority: number;
  assignee: string;
  maintenance_task_id: string;
  communication_log: string;
  due_on: string;
  description: string;
  hardware_id: string;
  display_name: string;
  type_name: string;
  module_id: string;
  error_report_id: string | null;
  editHardware: boolean;
  errorReport: any;
  dateValidation: string | null;
  descriptionValidation: string | null;
  hardwareValidation: string | null;
  taskValidation: string | null;
  hardwareOptions: { value: string; name: string }[];
  showModal: boolean;
  hardwareFetch: null | string;
}

const Addticket = ({
  mode,
  system,
  systemLoaded,
  admin,
}: {
  mode: IMode;
  system: ISystem;
  systemLoaded: boolean;
  admin: IAdmin;
}) => {
  const { hardwareId = '', reportId = '' } = useParams<{
    hardwareId: string;
    reportId: string;
  }>();
  const initialState = {
    escalated: false,
    priority: 3,
    assignee: '',
    maintenance_task_id: '',
    communication_log: '',
    due_on: new Date().toISOString(),
    description: '',
    hardware_id: '',
    display_name: '',
    type_name: '',
    module_id: '',
    error_report_id: null,
    editHardware: true,
    errorReport: null,
    dateValidation: null,
    descriptionValidation: null,
    hardwareValidation: null,
    taskValidation: null,
    hardwareOptions: [],
    showModal: false,
    hardwareFetch: null,
  };

  const [
    {
      escalated,
      priority,
      assignee,
      maintenance_task_id,
      communication_log,
      due_on,
      description,
      hardware_id,
      type_name,
      module_id,
      error_report_id,
      editHardware,
      dateValidation,
      descriptionValidation,
      hardwareValidation,
      taskValidation,
      hardwareOptions,
      showModal,
      hardwareFetch,
      errorReport,
    },
    setState,
  ] = useState<IAddTicketState>(initialState);
  const { data: hardware } = useOneHardware(hardwareId, {
    enabled: !!hardwareId || !!hardwareFetch,
  });

  const { data: allHardware = [] } = useAllHardware({
    enabled: !hardwareId,
  });

  const { data: allTask = [] } = useAllTask({
    enabled: systemLoaded,
  });

  const { data: problem } = useOneProblem(reportId, {
    enabled: !!reportId,
  });

  const { data: allTickets = [] } = useAllTickets({
    enabled: systemLoaded,
  });

  const { data: allAdmins = [] } = useSystemAdmins({
    enabled: systemLoaded,
  });

  const getHardwareOptions = useCallback(() => {
    return allHardware
      ?.map((unit: IHardware) => ({
        value: unit.id,
        name: `${toTitleCase(unit.hardware_type_name)} ${unit.number || unit.id}`,
      }))
      .sort((a: { name: string }, b: { name: string }) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
  }, [allHardware]);

  useEffect(() => {
    if (hardwareId && hardware) {
      setState((prevState) => ({
        ...prevState,
        editHardware: false,
        hardware_id: hardware.id,
        module_id: hardware.module,
        hardwareNumber: hardware.number,
        type_name: hardware.hardware_type,
      }));
    } else if (reportId) {
      if (problem?.hardware_id) {
        setState((prevState) => ({
          ...prevState,
          error_report_id: reportId,
          escalated: true,
          errorReport: problem,
          hardware_id: problem.hardware_id,
          type_name: problem.hardware_type,
          editHardware: false,
          hardwareFetch: problem?.hardware_id || null,
        }));
      } else {
        const hardwareOptions = getHardwareOptions();
        setState((prevState) => ({
          ...prevState,
          error_report_id: reportId,
          escalated: true,
          errorReport: problem,
          hardwareOptions: hardwareOptions,
          hardware_id: hardwareOptions?.length ? hardwareOptions[0].value : '',
          editHardware: true,
          hardwareFetch: problem?.hardware_id || null,
        }));
      }
    } else {
      const hardwareOptions = getHardwareOptions();
      setState((prevState) => ({
        ...prevState,
        hardwareOptions: hardwareOptions,
        hardware_id: hardwareOptions?.length ? hardwareOptions[0].value : '',
      }));
    }
  }, [hardwareId, reportId, systemLoaded, hardware, getHardwareOptions, problem]);

  useEffect(() => {
    if (allTask.length > 0) {
      setState((prevState) => ({
        ...prevState,
        maintenance_task_id: allTask.length ? allTask[0].id : '',
      }));
    }
  }, [allTask]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'hardware_id') {
      if (!system.new_hardware) {
        const unit: IHardware =
          allHardware.find((hardware: IHardware) => hardware.id === e.target.value) ||
          ({} as IHardware);
        setState((prevState) => ({
          ...prevState,
          hardware_id: unit.id,
          hardwareValidation: null,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
          hardwareValidation: null,
        }));
      }
    } else if (e.target.name === 'description') {
      setState((prevState) => ({
        ...prevState,
        description: e.target.value,
        descriptionValidation: null,
      }));
    } else if (e.target.name === 'maintenance_task_id') {
      setState((prevState) => ({
        ...prevState,
        maintenance_task_id: e.target.value,
        taskValidation: null,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const handleDateChange = (date: string) => {
    if (!date || new Date(date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
      setState((prevState) => ({
        ...prevState,
        dateValidation: 'error',
      }));
      CustomToast({
        type: 'error',
        message: 'Due date must be a future date',
      });
    } else {
      setState((prevState) => ({
        ...prevState,
        due_on: new Date(date).toISOString(),
        dateValidation: null,
      }));
    }
  };

  const toggleModal = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: !showModal,
    }));
  };

  const isDuplicate = (hardwareId: string, taskId: string) => {
    const duplicates = allTickets.filter((ticket: ITicket) => {
      return (
        ticket.hardware_id === hardwareId &&
        ticket.maintenance_task_id === taskId &&
        !ticket.canceled_on &&
        !ticket.completed_on
      );
    });
    return duplicates.length > 0;
  };

  const canSubmit = () => {
    if (!description) {
      setState((prevState) => ({
        ...prevState,
        descriptionValidation: 'error',
      }));
      CustomToast({
        type: 'error',
        message: 'Description is required',
      });
      return false;
    }
    if (!maintenance_task_id) {
      setState((prevState) => ({
        ...prevState,
        taskValidation: 'error',
      }));
      CustomToast({
        type: 'error',
        message: 'Maintenance type is required',
      });
      return false;
    }
    if (!hardware_id) {
      setState((prevState) => ({
        ...prevState,
        hardwareValidation: 'error',
      }));
      CustomToast({
        type: 'error',
        message: `${
          system.new_hardware ? 'Hardware' : mode.details.unitsTitle
        } is required`,
      });

      return false;
    }
    if (
      !due_on ||
      new Date(due_on).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
    ) {
      setState((prevState) => ({
        ...prevState,
        dateValidation: 'error',
      }));
      CustomToast({
        type: 'error',
        message: 'Due date must be a future date',
      });
      return false;
    }
    if (isDuplicate(hardware_id, maintenance_task_id)) {
      toggleModal();
      return false;
    }
    return true;
  };

  const save = () => {
    if (canSubmit()) {
      submit();
    }
  };

  const submit = () => {
    const notes = communication_log
      ? JSON.stringify({
          admin_id: admin.email,
          created_on: new Date().toISOString(),
          message: communication_log,
        })
      : null;
    const ticket = {
      due_on: due_on,
      description: description,
      source_type: escalated ? 'user_escalated' : 'admin_created',
      priority: priority,
      communication_log: notes,
      maintenance_task_id: maintenance_task_id,
      hardware_id: hardware_id,
      module_id: module_id || null,
      assignee: assignee && assignee !== 'None' ? assignee : null,
      error_report_id: error_report_id || reportId || null,
      rental_id: !isEmptyObject(errorReport) ? errorReport.rentalId : null,
    };
    CustomAsyncToast({
      promise: () => createTicket(ticket),
      successMessage: () => 'Ticket created successfully.',
      loadingMessage: 'Creating ticket...',
      errorMessage: 'Unable to create ticket',
    }).then((r) => {
      if (r) {
        history.push(`/maintenance`);
      }
    });
  };

  const goBack = () => {
    escalated
      ? history.push(`/problems/${error_report_id || reportId}`)
      : history.push('/maintenance/tickets');
  };
  const getAdmins = () => {
    if (allAdmins.length > 0) {
      const adminOptions = allAdmins.map((admin: IAdmin) => ({
        value: admin.email,
        name: `${admin.firstName} ${admin.lastName}`,
      }));
      adminOptions.unshift({ value: '', name: 'None' });
      return adminOptions;
    }
    return [{ value: '', name: 'None' }];
  };

  return (
    <PageContainer isLoading={!systemLoaded}>
      <CardView
        content={
          <Stack px={2} pb={2}>
            {reportId && (
              <MovaticFieldItemLink
                label="Original Problem Report"
                onClick={() => history.push(`/problems/${reportId}`)}
                content={reportId}
              />
            )}
            <MovaticFieldGroupText
              label="Description"
              placeholder="Description of the problem"
              name="description"
              hasError={descriptionValidation === 'error'}
              value={description}
              onChange={handleChange}
            />

            <MovaticFieldGroupSelect
              label="Maintenance Type"
              description="Type of maintenance task."
              optionNodes={allTask
                .map((task: ITask) => ({
                  value: task.id,
                  name: task.name,
                }))
                .map((option: { value: string; name: string }, index: number) => (
                  <MenuItem key={index} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              value={maintenance_task_id || ''}
              onChange={handleChange}
              name="maintenance_task_id"
              hasError={taskValidation === 'error'}
            />

            {editHardware ? (
              <MovaticFieldGroupSelect
                label="Hardware"
                description="Hardware to be maintained."
                optionNodes={hardwareOptions.map(
                  (option: { value: string; name: string }, index: number) => (
                    <MenuItem key={index} value={option.value}>
                      {option.name}
                    </MenuItem>
                  )
                )}
                value={hardware_id || ''}
                onChange={handleChange}
                name="hardware_id"
                hasError={hardwareValidation === 'error'}
              />
            ) : system.new_hardware ? (
              <>
                <MovaticFieldItemLink
                  label={toTitleCase(type_name)}
                  onClick={() => history.push(`/hardware/${type_name}/${hardwareId}`)}
                  content={hardware_id}
                  dataId={'hardware_id'}
                />
              </>
            ) : (
              <MovaticFieldItemLink
                label={mode.details.unitsTitle}
                onClick={() => history.push(`/units/${hardwareId}`)}
                content={hardware?.number}
                dataId={'hardware_number'}
              />
            )}
            <MovaticFieldGroupDate
              label="Due"
              description="Due date for this ticket."
              value={new Date(due_on) || null}
              hasError={dateValidation === 'error'}
              onChange={handleDateChange}
            />

            <MovaticFieldGroupSelect
              id="priority"
              label="Priority"
              description="Priority of this ticket."
              optionNodes={[
                { value: 1, name: `1 - ${getPriorityName(1)}` },
                { value: 2, name: `2 - ${getPriorityName(2)}` },
                { value: 3, name: `3 - ${getPriorityName(3)}` },
                { value: 4, name: `4 - ${getPriorityName(4)}` },
                { value: 5, name: `5 - ${getPriorityName(5)}` },
              ].map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
              value={priority || 3}
              onChange={handleChange}
              name="priority"
            />

            <MovaticFieldGroupSelect
              id="assignee"
              label="Assignee"
              description="Priority of this ticket."
              optionNodes={getAdmins().map(
                (option: { name: string; value: string }, index: number) => (
                  <MenuItem key={index} value={option.value}>
                    {option.name}
                  </MenuItem>
                )
              )}
              value={assignee}
              onChange={handleChange}
              name="assignee"
            />

            <MovaticFieldGroupText
              multiline
              maxRows={10}
              minRows={1}
              label="Add Note"
              placeholder="Note"
              description="(Optional)"
              name="communication_log"
              value={communication_log}
              onChange={handleChange}
            />
          </Stack>
        }
        title={'Create Maintenance Ticket'}
        headerActions={
          <IconButtonMenu
            buttons={[
              {
                label: 'Cancel',
                onClick: goBack,
              },
              {
                label: 'Save',
                onClick: save,
                startIcon: <SaveIcon />,
              },
            ]}
          />
        }
      />
      <MovaticCustomModal
        open={showModal}
        onClose={toggleModal}
        title="Duplicate Maintenance Ticket"
        content={`There is already a similar ticket for this ${
          system.new_hardware ? type_name : mode.details.unitsTitle
        } that is still open. Do you want to create this ticket anyway?`}
        action={
          <IconButtonMenu
            buttons={[
              { label: 'Cancel', onClick: toggleModal },
              { label: 'Confirm', onClick: submit },
            ]}
          />
        }
      />
    </PageContainer>
  );
};

export default connect(
  (state: {
    system: { current: ISystem; isLoaded: boolean };
    mode: IMode;
    admin: { admin: { admin: IAdmin } };
  }) => ({
    system: state.system.current,
    systemLoaded: state.system.isLoaded,
    mode: state.mode,
    admin: state.admin.admin.admin,
  }),
  () => ({})
)(Addticket);
