import React from 'react';
import MyMap from '../../components/mapComponent';
import useStation from '../../hooks/stations/query/useStation';
import { IStation } from '../../ts/interface/station.interface';
import Stack from '@mui/material/Stack';
import { connect } from 'react-redux';
import { ISystem } from '../../ts/interface/system.interface';

interface IStationLocationsMapProps {
  systemAccess?: number;
  system?: ISystem;
  systemLoaded?: boolean;
}

const StationLocationsMap = ({
  systemAccess,
  system,
  systemLoaded,
}: IStationLocationsMapProps) => {
  const { data, isLoading } = useStation(
    { enabled: systemLoaded },
    { system_id: system?.gid }
  );
  const stations = data?.data || [];
  return (
    <Stack>
      <MyMap
        stations={stations.map((station: IStation) => ({
          ...station,
          long: station.lng,
        }))}
        loaded={!isLoading}
        systemType={system?.hardware_type}
        systemAccess={systemAccess}
      />
    </Stack>
  );
};

const StationLocationsMapR = connect(
  (state: any) => ({
    system: state.system.current,
    systemAccess: state.admin.systemAccess,
    systemLoaded: state.system.isLoaded,
  }),
  () => ({})
)(StationLocationsMap);

export default StationLocationsMapR;
