import {
  CHANGE_PAGE,
  CHANGE_SIZE,
  DEFAULT_PAGE_ACTIVE,
  DEFAULT_PAGE_COMPLETED,
} from '../constants';

const initialState = {
  userActivePage: 1,
  userActiveSize: 10,
  userInactivePage: 1,
  userInactiveSize: 10,
  userBannedPage: 1,
  userBannedSize: 10,
  userEmailAuthPage: 1,
  userEmailAuthSize: 10,
  userAdminAuthPage: 1,
  userAdminAuthSize: 10,
  userRentalPage: 1,
  userRentalSize: 10,
  userProblemPage: 1,
  userProblemSize: 10,
  rentalActivePage: 1,
  rentalActiveSize: 10,
  rentalCompletedPage: 1,
  rentalCompletedSize: 10,
  rentalProblemPage: 1,
  rentalProblemSize: 10,
  hardwarePage: 1,
  hardwareSize: 10,
  hardwareRentalPage: 1,
  hardwareRentalSize: 10,
  lockPage: 1,
  lockSize: 10,
  imagePage: 1,
  imageSize: 10,
  stationPage: 1,
  stationSize: 10,
  stationHardwarePage: 1,
  stationHardwareSize: 10,
  stationRentalsPage: 1,
  stationRentalsSize: 10,
  problemOpenPage: 1,
  problemOpenSize: 10,
  problemClosedPage: 1,
  problemClosedSize: 10,
  settingsAdminPage: 1,
  settingsAdminSize: 10,
  billingTransactionPage: 1,
  billingTransactionSize: 10,
  billingDisputePage: 1,
  billingDisputeSize: 10,
  futureMemberPage: 1,
  futureMemberSize: 10,
  activeMemberPage: 1,
  activeMemberSize: 10,
  historicMemberPage: 1,
  historicMemberSize: 10,
  completedReservationsPage: 1,
  completedReservationsSize: 10,
  pendingReservationsPage: 1,
  pendingReservationsSize: 10,
  canceledReservationsPage: 1,
  canceledreservationsSize: 10,
  assignedReservationsPage: 1,
  assignedReservationsSize: 10,
  singleRes: 10,
  couponPage: 1,
  couponSize: 10,
  retiredCouponsPage: 1,
  retiredCouponsSize: 10,
  pendingMemberPage: 1,
  pendingMemberSize: 10,
  userMembershipsPage: 1,
  userMembershipsSize: 10,
  maintenanceOpenPage: 1,
  maintenanceOpenSize: 10,
  maintenanceScheduledPage: 1,
  maintenanceScheduledSize: 10,
  maintenanceClosedPage: 1,
  maintenanceClosedSize: 10,
  maintenanceCanceledPage: 1,
  maintenanceCanceledSize: 10,
  maintenanceAllPage: 1,
  maintenanceAllSize: 10,
  maintenanceTaskPage: 1,
  maintenanceTaskSize: 10,
  hardwareTicketsPage: 1,
  hardwareTicketsSize: 10,
  hardwareLogsPage: 1,
  hardwareLogsSize: 10,
  moduleTicketsPage: 1,
  moduleTicketsSize: 10,
  moduleLogsPage: 1,
  moduleLogsSize: 10,
  problemTicketsPage: 1,
  problemTicketsSize: 10,
  pendingChargesPage: 1,
  pendingChargesSize: 10,
  userRfidCardsPage: 1,
  userRfidCardsSize: 10,
};

export default function tablePages (state = initialState, action) {
  const newState = { ...state };

  switch (action.type) {
    case CHANGE_PAGE:
      newState[`${action.value.table}Page`] = action.value.number;
      break;
    case CHANGE_SIZE:
      newState[`${action.value.table}Size`] = action.value.number;
      break;
    case DEFAULT_PAGE_ACTIVE:
      newState.rentalActivePage = action.number;
      break;

    case DEFAULT_PAGE_COMPLETED:
      newState.rentalCompletedPage = action.number;
      break;
    default:
      return state;
  }

  return newState;
}
