import {
  API_RENTALS_SUCCESS,
  SET_RENTAL_DETAILS,
  RENTAL_DETAIL_LOADED,
  RENTALS_LOADED,
  UNLOCK_LOGS,
  UNLOCK_LOGS_LOADED,
  SET_PENDING_CHARGES,
  PENDING_CHARGES_LOADED,
  PENDING_CHARGE_UPDATED,
} from '../constants.js';
// import { convertCentsToMoney, timeForHumans } from '../utils.jsx';

const initalState = {
  rentals: [],
  completedRentals: [],
  activeRentals: [],
  detailed: {
    charge_id: null,
    chargeAmount: null,
    chargePendingDecisionDate: null,
    chargePendingNote: null,
    coupon_discount_type: '',
    coupon_discount: 0,
    coupon_id: null,
    coupon_name: '',
    duration: 0,
    endLatLng: [null, null],
    endStation: null,
    endStationName: null,
    endTime: null,
    hardware: 0,
    hardwareNumber: null,
    id: null,
    isChargePending: false,
    isChargePendingApproved: null,
    module: null,
    path_coordinates: null,
    rating: 0,
    refund: null,
    refundComment: null,
    reservation: null,
    reservationCancel: null,
    reservationStart: null,
    startLatLng: [null, null],
    startStation: null,
    startStationName: null,
    startTime: null,
    type: 0,
    typeName: null,
    user: 0,
    userName: null,
  },
  unlockLogs: {},
  unlockLogsLoaded: false,
  detailRentalLoaded: false,
  isLoaded: false,
  pendingCharges: [],
  pendingChargesLoaded: false,
};

export default function rentalReducer (state = initalState, action) {
  const newState = { ...state };

  switch (action.type) {
    case API_RENTALS_SUCCESS:
      newState.rentals = action.rentals;
      newState.isLoaded = true;
      break;

    case RENTALS_LOADED:
      newState.isLoaded = action.loaded;
      break;

    case SET_RENTAL_DETAILS:
      newState.detailed = action.rentalDetails;
      newState.detailRentalLoaded = true;
      break;

    case RENTAL_DETAIL_LOADED:
      newState.detailRentalLoaded = action.loaded;
      break;

    case UNLOCK_LOGS_LOADED:
      newState.unlockLogsLoaded = action.loaded;
      break;

    case UNLOCK_LOGS:
      newState.unlockLogs = action.unlockLogs;
      newState.unlockLogsLoaded = true;
      break;

    case PENDING_CHARGES_LOADED:
      newState.pendingChargesLoaded = action.loaded;
      break;

    case SET_PENDING_CHARGES:
      newState.pendingCharges = action.pendingCharges;
      newState.pendingChargesLoaded = true;
      break;

    case PENDING_CHARGE_UPDATED:
      newState.detailed = {
        ...newState.detailed,
        isChargePending: false,
        isChargePendingApproved: action.status === 'true',
        chargePendingNote: action.note,
        chargePendingDecisionDate: action.date,
      };
      break;

    default:
      return state;
  }

  return newState;
}
