import React, { useState, useEffect, useRef } from 'react';
import { deflate, inflate } from 'pako';
import { useHistory, useLocation } from 'react-router-dom';
import bugsnag from '../components/bugsnag';

interface State {
  [key: string]: any;
}

const useUrlState = <T extends State>(
  initialState: T,
  urlSearchParams?: string | null
): [T, React.Dispatch<React.SetStateAction<T>>, boolean, boolean] => {
  const [state, setState] = useState<T>(initialState);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [restorationMade, setRestorationMade] = useState<boolean>(false);
  const history = useHistory();
  const { search, pathname } = useLocation();
  const initializedFromUrl = useRef<boolean>(false);

  useEffect(() => {
    const compressedStateFromUrl = urlSearchParams
      ? urlSearchParams
      : new URLSearchParams(search).get('state');
    if (compressedStateFromUrl && !isCompleted) {
      setRestorationMade(true);
    }
  }, [urlSearchParams, isCompleted, search, pathname]);

  useEffect(() => {
    const compressedStateFromUrl = urlSearchParams
      ? urlSearchParams
      : new URLSearchParams(search).get('state');
    if (compressedStateFromUrl && !initializedFromUrl.current) {
      try {
        const decodedState = decodeURIComponent(compressedStateFromUrl);
        const decompressedState = inflate(
          Uint8Array.from(decodedState.split(',').map(Number)),
          { to: 'string' }
        );
        const restoredState = JSON.parse(decompressedState) as T;
        setState(restoredState);
        initializedFromUrl.current = true;
        setIsCompleted(true);
      } catch (error) {
        bugsnag.notify(`Error decoding state from URL: ${error}`);
      }
    } else if (!compressedStateFromUrl) {
      setRestorationMade(false); // Reset restorationMade if not initialized from URL
      setIsCompleted(true);
    }
  }, [search, urlSearchParams, pathname]);

  useEffect(() => {
    if (isCompleted) {
      //@ts-ignore
      const compressedState = deflate(JSON.stringify(state), { to: 'string' });
      //@ts-ignore
      const urlSafeState = encodeURIComponent(compressedState);

      const existingSearchParams = new URLSearchParams(search);
      existingSearchParams.set('state', urlSafeState);

      const newSearch = existingSearchParams.toString();

      history.replace({
        search: newSearch,
      });
      setRestorationMade(false);
    }
  }, [state, history, isCompleted, search, pathname]);

  return [state, setState, isCompleted, restorationMade];
};

export default useUrlState;
