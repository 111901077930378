import {
  baseUrlV2,
  baseUrl,
  getCredentials,
  fetchOptions,
  fetchOptionsV2,
  createQueryParameters,
  fetchPostOptions,
} from './http';
import { displayError, displaySuccess, history } from '../utils';
import { API_PAGINATION_LIMIT, KEYBOX, ENGLISH_LANGUAGE } from '../constants';

import { FourOhOneError } from '../actionCreators/system';
import fileDownloader from 'js-file-download';
import CustomToast from '../components/Redesing/custom-toast';

export const getHardware = (parameters, paginationResultHandler) => {
  const { adminEmail, adminToken, systemGid } = getCredentials();
  const fetchOps = fetchOptionsV2(adminEmail, adminToken);
  const queryParametersObject = {
    limit: API_PAGINATION_LIMIT,
    system_id: systemGid,
    ...parameters,
  };
  const queryParameters = createQueryParameters(queryParametersObject);

  return fetch(`${baseUrlV2}/hardware/?${queryParameters}`, fetchOps)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText || response.status);
      }
      return response;
    })
    .then((response) => response.json())
    .then((response) => {
      if (response?.data) {
        return response;
      }

      throw Error(response?.error_description);
    })
    .catch((e) => {
      displayError(
        'Oops! Looks like there was an error while loading the hardware. Please try again later.',
        e
      );

      return {
        data: [],
      };
    })
    .then((response) => {
      if (paginationResultHandler) {
        paginationResultHandler({ data: response.data, hasMore: response.has_more });

        if (response.has_more) {
          return getHardware(
            {
              ...queryParametersObject,
              after: response.data.pop().id,
            },
            paginationResultHandler
          );
        }
      }

      return {
        data: response.data,
      };
    });
};

export const getHardwareEventLogs = (hardwareId, parameters) => {
  const { adminEmail, adminToken } = getCredentials();
  const fetchOps = fetchOptionsV2(adminEmail, adminToken);
  const queryParameters = createQueryParameters(parameters);
  return fetch(`${baseUrlV2}/hardware/${hardwareId}/events?${queryParameters}`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response?.data) {
        return response;
      }
      FourOhOneError(response);
      throw new Error(response?.message);
    })
    .catch((error) => {
      return Promise.reject(`${error}`);
    });
};

export const getOneHardware = (hardwareId) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);

  return fetch(`${baseUrl}/hardware/detail/${hardwareId}`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        return response.message;
      }
      FourOhOneError(response);
      throw new Error(response?.message);
    });
};

export const getAllHardwareTypes = () => {
  return fetch(`${baseUrl}/hardware/types`)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        return response.message;
      }
      FourOhOneError(response);
      throw new Error(response?.message);
    })
    .catch((error) => {
      return Promise.reject(`${error}`);
    });
};

export const getAllHardwareTypesBySystem = () => {
  const { adminEmail, adminToken, systemGid } = getCredentials();
  const fetchOps = fetchOptionsV2(adminEmail, adminToken);
  const queryParametersObject = {
    language: ENGLISH_LANGUAGE,
  };
  const queryParameters = createQueryParameters(queryParametersObject);
  return fetch(`${baseUrlV2}/hardware/type/${systemGid}?${queryParameters}`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response?.data) {
        return response.data;
      }
      FourOhOneError(response);
      throw new Error(response?.message);
    })
    .catch((error) => {
      return Promise.reject(`${error}`);
    });
};

export const getAllHardwareProducts = (parameters) => {
  const { adminEmail, adminToken } = getCredentials();
  const fetchOps = fetchOptionsV2(adminEmail, adminToken);
  const queryParametersObject = {
    limit: API_PAGINATION_LIMIT,
    ...parameters,
  };
  const queryParameters = createQueryParameters(queryParametersObject);
  return fetch(`${baseUrlV2}/hardware/product?${queryParameters}`, fetchOps)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText || response.status);
      }
      return response;
    })
    .then((response) => response.json())
    .then((response) => {
      if (response?.data) {
        return response;
      }
      FourOhOneError(response);
      throw Error(response?.error_description);
    })
    .catch((error) => {
      return Promise.reject(`There was an error loading the hardware products.${error}`);
    });
};

export const getAllHardwareProductVersions = (parameters) => {
  const { adminEmail, adminToken } = getCredentials();
  const fetchOps = fetchOptionsV2(adminEmail, adminToken);
  const queryParametersObject = {
    limit: API_PAGINATION_LIMIT,
    ...parameters,
  };
  const queryParameters = createQueryParameters(queryParametersObject);
  return fetch(`${baseUrlV2}/hardware/product/version?${queryParameters}`, fetchOps)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText || response.status);
      }
      return response;
    })
    .then((response) => response.json())
    .then((response) => {
      if (response?.data) {
        return response;
      }
      FourOhOneError(response);
      throw Error(response?.error_description);
    })
    .catch((error) => {
      Promise.reject(`There was an error loading the hardware product versions.${error}`);
    });
};

export const addAssigment = (body) => {
  const { adminEmail, adminToken } = getCredentials();
  const fetchOps = fetchOptionsV2(adminEmail, adminToken, 'POST', body);
  return fetch(`${baseUrlV2}/hardware/assignment`, fetchOps)
    .then((response) => {
      if (response.status === 200) {
        displaySuccess('The assignment was created successfully.');
        return response.ok;
      }
      FourOhOneError(response);
      throw new Error(response?.error_description);
    })
    .catch((error) => {
      Promise.reject(
        `Oops! Looks like there was an error while creating this assignment. Please try again later.`
      );
    });
};

export const removeAssignment = (body) => {
  const { adminEmail, adminToken } = getCredentials();
  const fetchOps = fetchOptionsV2(adminEmail, adminToken, 'PUT', body);
  return fetch(`${baseUrlV2}/hardware/assignment`, fetchOps)
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }
      FourOhOneError(response);
      throw new Error(response?.error_description);
    })
    .catch((error) => {
      Promise.reject(
        `Oops! Looks like there was an error while removing this assignment. Please try again later. ${error}`
      );
    });
};

export const updateHardware = (hardwareInfo, metadata) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const returnToAllHardwareTable = systemId !== hardwareInfo.system;

  const fetchOps = fetchPostOptions(
    adminEmail,
    adminToken,
    systemId,
    hardwareInfo,
    'PUT'
  );

  if (hardwareInfo.type === KEYBOX && hardwareInfo.masterUnlockCode !== undefined) {
    return fetch(`${baseUrl}/hardware/update`, fetchOps)
      .then((response) => response.json())
      .then((results) => {
        if (!results.status) {
          const fileName = hardwareInfo.nusetSerialNumber;
          fileDownloader(JSON.stringify(results), `${fileName}.pin`);
          displaySuccess('Update successful! Your pincodes have been downloaded!');
          history.push(`/units/${hardwareInfo.id}`);
        } else if (results.status === 200) {
          displaySuccess('Update successful!');
        } else if (results.status === 400) {
          return Promise.reject(
            `Error while updating, please try again. ${results.message}`
          );
        } else {
          FourOhOneError(results);
          throw new Error();
        }
      })
      .catch((error) => {
        return Promise.reject(`Error while updating, please try again. ${error}`);
      });
  } else {
    const newHardwareType = metadata.hardware_type;
    return fetch(`${baseUrl}/hardware/update`, fetchOps)
      .then((response) => response.json())
      .then((hardwareResponse) => {
        if (hardwareResponse.status === 200) {
          if (history.location.pathname === `/hardware/${newHardwareType}`) {
            displaySuccess('Update successful!');
          } else if (returnToAllHardwareTable) {
            // displaySuccess('Update successful!');
            // history.push(`/hardware`);
            // history.push(`/hardware/${newHardwareType}/${hardwareInfo.id}`);
          } else {
            // displaySuccess('Update successful!');
            // history.push(`/hardware/${newHardwareType}/${hardwareInfo.id}`);
          }
        } else if (hardwareResponse.status === 400) {
          displayError(
            `Error while updating, please try again. ${hardwareResponse.message}`
          );
        } else {
          FourOhOneError(hardwareResponse);
          throw new Error();
        }
      })
      .catch((error) => {
        displayError(`Error while updating, please try again. ${error}`);
      });
  }
};

export const updateHardwareV2 = (hardwareInfo) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(
    adminEmail,
    adminToken,
    systemId,
    hardwareInfo,
    'PUT'
  );
  return fetch(`${baseUrl}/hardware/update`, fetchOps)
    .then((response) => response.json())
    .then((hardwareResponse) => {
      let errorMessages = null;
      if (hardwareResponse.status === 200) {
        return hardwareResponse.message;
      } else if (hardwareResponse.status === 400) {
        errorMessages = `Error while updating, please try again. ${hardwareResponse.message}`;
      } else {
        FourOhOneError(hardwareResponse);
      }
      throw new Error(errorMessages || 'while updating, please try again.');
    })
    .catch((error) => {
      return Promise.reject(`${error}`);
    });
};

export const createHardwareFromCsv = (hardwaresFromCsv, hardwareProductInfo) => {
  const { hardwareProduct, hardwareProductVersion } = hardwareProductInfo;
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(
    adminEmail,
    adminToken,
    systemId,
    { hardwareProductVersion, hardwareProduct, hardwaresFromCsv },
    'POST'
  );

  return fetch(`${baseUrl}/hardware/add_csv/${hardwareProduct.id}`, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        if (Array.isArray(results.message)) {
          const rowsWithErrors = results.message.map((message) => message.row);
          CustomToast({
            type: 'error',
            message: `Errors in rows: ${rowsWithErrors.toString()}`,
            duration: 5000,
          });
        } else {
          return results.message;
        }
      } else if (results.status === 400) {
        CustomToast({
          type: 'error',
          message: results.message,
          duration: 5000,
        });
      }
      FourOhOneError(results);
      throw new Error(results.message);
    })
    .catch((error) => {
      return Promise.reject(`${error}`);
    });
};

export const createHardware = (hardwareInfo) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(
    adminEmail,
    adminToken,
    systemId,
    hardwareInfo,
    'POST'
  );

  const url = hardwareInfo.typeName === KEYBOX ? '/hardware/nuset' : '/hardware';
  return fetch(baseUrl + url, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        history.push(`/hardware/${hardwareInfo.typeName}`);
      } else if (results.status === 400) {
        if (results.event === 15 || results.event === 42) {
          CustomToast({
            type: 'error',
            message: results.message,
            duration: 5000,
          });
        } else if (results.message) {
          if (results.message.includes('hardware_imei_key')) {
            CustomToast({
              type: 'error',
              message: 'There is already a unit using that IMEI',
              duration: 5000,
            });
          } else if (results.message.includes('hardware_product_mac_unique')) {
            CustomToast({
              type: 'error',
              message: 'There is already a unit using that mac address',
              duration: 5000,
            });
          } else if (results.message.includes('duplicate key value (vendor_id)')) {
            CustomToast({
              type: 'error',
              message: 'There is already a piece of hardware with that Vendor ID.',
              duration: 5000,
            });
          } else {
            CustomToast({
              type: 'error',
              message: `There was an error while adding new hardware. Please try again! ${results.message}`,
              duration: 5000,
            });
          }
        } else {
          CustomToast({
            type: 'error',
            message: `There was an error while adding new hardware. Please try again! ${results.message}`,
            duration: 5000,
          });
        }
      } else {
        FourOhOneError(results);
        throw new Error();
      }
    })
    .catch((error) => {
      displayError('There was an error while adding new hardware. Please try again!');
    });
};

export const createHardwareV2 = (hardwareInfo) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(
    adminEmail,
    adminToken,
    systemId,
    hardwareInfo,
    'POST'
  );
  const url = hardwareInfo.typeName === KEYBOX ? '/hardware/nuset' : '/hardware';
  return fetch(baseUrl + url, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        return results.message;
      } else if (results.status === 400) {
        const errorMessages = [];
        if (results.event === 15 || results.event === 42) {
          errorMessages.push(results.message);
        } else if (results.message) {
          if (results.message.includes('hardware_imei_key')) {
            errorMessages.push('There is already a unit using that IMEI');
          } else if (results.message.includes('hardware_product_mac_unique')) {
            errorMessages.push('There is already a unit using that MAC address');
          } else if (results.message.includes('duplicate key value (vendor_id)')) {
            errorMessages.push(
              'There is already a piece of hardware with that Vendor ID.'
            );
          } else {
            errorMessages.push(
              `There was an error while adding new hardware. Please try again! ${results.message}`
            );
          }
        } else {
          errorMessages.push(
            'There was an error while adding new hardware. Please try again!'
          );
        }
        const errorMessage = errorMessages.join(' ');
        return Promise.reject(errorMessage);
      } else {
        FourOhOneError(results);
        throw new Error();
      }
    })
    .catch((error) => {
      return Promise.reject(`${error}`);
    });
};

export const getAllHardware = () => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);
  return fetch(`${baseUrl}/hardware/all`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        return [].concat(...Object.values(response.message));
      }
      FourOhOneError(response);
      throw new Error();
    })
    .catch((error) => {
      displayError('There was an error while fetching hardware. Please try again!');
    });
};

export const setRemoteBatteryLock = (hardwareId) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, hardwareId, 'POST');

  return fetch(`${baseUrlV2}/hardware/lockbattery`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        return Object.values(response.message).flat();
      }
      FourOhOneError(response);
      throw new Error();
    })
    .catch((error) => {
      displayError(
        'There was an error while remote locking the battery. Please try again!'
      );
    });
};

export const remoteRing = (hardware_id) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(
    adminEmail,
    adminToken,
    systemId,
    {
      hardware_id,
    },
    'Post'
  );
  return fetch(baseUrl + '/hardware/ring', fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        if (results.message.hardware_id) {
          return results.message.hardware_id;
        }
      } else {
        FourOhOneError(results);
        throw new Error();
      }
    })
    .catch((error) => {
      return Promise.reject(`${error}`);
    });
};

export const hardwareRemoteAction = (hardware_id, action) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(
    adminEmail,
    adminToken,
    systemId,
    {
      hardware_id,
    },
    'Post'
  );
  return fetch(baseUrl + `/hardware/${action}`, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        if (results.message.hardware_id) {
          return results.message.hardware_id;
        }
      } else {
        FourOhOneError(results);
        throw new Error();
      }
    })
    .catch((error) => {
      return Promise.reject(`${error}`);
    });
};

export const retireHardware = (hardwareId) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, {}, 'PUT');
  return fetch(`${baseUrl}/hardware/retire/${hardwareId}`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results.status === 200) {
        return results.message;
      }
      FourOhOneError(results);
      throw new Error();
    })
    .catch((error) => {
      return Promise.reject(`${error}`);
    });
};

export const downloadPinCode = (hardwareObject) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);
  const url = `${baseUrl}/hardware/pincode/${hardwareObject.smartBoxId}/${hardwareObject.nusetSerialNumber}`;
  return fetch(url, fetchOps)
    .then((response) => {
      return response.json();
    })
    .then((results) => {
      const fileName = hardwareObject.nusetSerialNumber;
      fileDownloader(JSON.stringify(results), `${fileName}.pin`);
      return results;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
