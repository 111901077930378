import React from 'react';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Chart } from '../../../components/Redesing/chart';
import { IChartData } from '../../../ts/interface/dashboardGraph.interface';

interface IDashboardChartProps {
  data: IChartData;
  total: number | string;
  average: string | number;
  systemTimezoneLabel: string;
  title: string;
  showTimezone?: boolean;
  id: string;
  statTittleOne: string;
  statTittleTwo: string;
}

const DashboardChart = ({
  systemTimezoneLabel,
  average,
  total,
  data,
  title,
  showTimezone,
  id,
  statTittleOne,
  statTittleTwo,
}: IDashboardChartProps) => {
  const theme = useTheme();

  const getSeries = (data: number[], name: string) => {
    return [
      {
        name: name,
        data: data || [],
      },
    ];
  };

  const useChartOptions = (data: string[], id: string) => {
    const categories = data;
    return {
      chart: {
        id: id,
        background: 'transparent',
        stacked: false,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      colors: [
        theme.palette.mode === 'dark'
          ? theme.palette.primary.dark
          : theme.palette.primary.main,
        theme.palette.warning.main,
      ],
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: 1,
        type: 'solid',
      },
      grid: {
        borderColor: theme.palette.divider,
        strokeDashArray: 2,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      legend: {
        horizontalAlign: 'right',
        labels: {
          colors: theme.palette.text.secondary,
        },
        position: 'top',
        show: true,
      },
      markers: {
        hover: {
          size: undefined,
          sizeOffset: 2,
        },
        radius: 2,
        shape: 'circle',
        size: 4,
        strokeWidth: 0,
      },
      stroke: {
        curve: 'smooth',
        dashArray: [0, 3],
        lineCap: 'butt',
        width: 3,
      },
      theme: {
        mode: theme.palette.mode,
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        categories,
        labels: {
          style: {
            colors: theme.palette.text.secondary,
          },
        },
      },
      yaxis: [
        {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
          },
        },
        {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          },
        },
      ],
    };
  };

  return (
    <Stack direction="column" id={`dashboard-chart-${id}`}>
      <Typography sx={{ p: 2 }} align="left" variant="h6">
        {title}
      </Typography>
      <Stack
        textAlign="center"
        direction={'row'}
        pb={2}
        px={2}
        spacing={2}
        justifyContent={'center'}
      >
        <Stack>
          <Typography variant="subtitle1" sx={{ color: 'darkText' }}>
            {statTittleOne}
          </Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
            {average}
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="subtitle1" sx={{ color: 'darkText' }}>
            {statTittleTwo}
          </Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
            {total}
          </Typography>
        </Stack>
      </Stack>
      <Chart
        width={'97%'}
        height={320}
        // @ts-ignore
        options={useChartOptions(data.labels, id)}
        series={getSeries(data.data, title)}
        type="line"
        labels={data.labels}
      />
      {showTimezone && (
        <Typography align="center" variant="h6" pb={2}>
          Timezone shown: {systemTimezoneLabel}
        </Typography>
      )}
      {/*</Stack>*/}
    </Stack>
    // </Stack>
  );
};

export default DashboardChart;
