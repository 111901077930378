import { useMutation } from '@tanstack/react-query';
import { updateSystem } from '../../../api/system';

const useUpdateSystem = (options?: {}) => {
  return useMutation(updateSystem, {
    ...options,
  });
};

export default useUpdateSystem;
