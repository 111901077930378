import { useLocation } from 'react-router-dom';

const useAnalyticsEventTracker = () => {
  const location = useLocation();
  return (
    eventName: string,
    options?: Gtag.CustomParams | Gtag.ControlParams | Gtag.EventParams
  ) => {
    if (process.env.NODE_ENV === 'production') {
      window.gtag('event', eventName, {
        page_title: document.title,
        page_path: location.pathname + location.search + location.hash,
        page_search: location.search,
        page_hash: location.hash,
        ...options,
      });
    } else {
      console.log('Analytics event', eventName, options);
    }
  };
};
export default useAnalyticsEventTracker;
