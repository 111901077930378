import React, { useState } from 'react';
import { getLocaleDateTimeString, history } from '../../../utils';
import { IProblem } from '../../../ts/interface/problem.interface';
import CardView from '../../../components/Redesing/card-view';
import { PaginationTable } from '../../../components/Redesing/table/pagination-table';
import { IColumn } from '../../../ts/interface/components/redesing/table.interface';

const UserProblems = ({
  problems,
  documentationLink,
}: {
  problems: IProblem[];
  documentationLink?: string;
}) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(5);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('created');
  return (
    <CardView
      documentationLink={documentationLink}
      content={
        <PaginationTable
          onRowCLick={(id: string) => history.push(`/problems/${id}`)}
          handleSearch={(search) => setSearch(search.target.value)}
          searchText={search}
          sortBy={sortBy}
          onSortChange={(sort) => setSortBy(sort.key)}
          columns={
            [
              {
                id: 'created',
                label: 'Created',
                searchable: true,
                format: (row: IProblem) => getLocaleDateTimeString(row.created),
              },
              {
                id: 'content',
                label: 'Description',
                showToolTip: true,
                searchable: true,
              },
            ] as IColumn[]
          }
          items={problems || []}
          onPageChange={(page, pageN) => {
            setPageNumber(pageN);
          }}
          onRowsPerPageChange={(row) => {
            const pageSize = Number(row.target.value);
            setSizePerPage(pageSize);
          }}
          page={pageNumber}
          rowsPerPage={sizePerPage}
          showFilter={false}
          searchPlaceholder={'Search user problems'}
        />
      }
      title={'Reported Problems'}
    />
  );
};

export default UserProblems;
