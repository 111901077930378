import React from 'react';
import CSVReader from 'react-csv-reader';
import useAddUserFromCsv from '../../hooks/users/mutation/useAddUserFromCsv';
import { displaySuccess, history } from '../../utils';
import PageContainer from '../../components/Redesing/page-container';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import '../../css/newOrganizedCss/csv-reader.scss';

const AddUsersFromCsv = () => {
  const addUserMutation = useAddUserFromCsv({
    onSuccess: () => {
      history.push('/users');
      displaySuccess('Users added successfully');
    },
  });

  return (
    <PageContainer isLoading={false} spaceName={'Add Users from CSV'}>
      <Card>
        <CardContent sx={{ p: 2 }}>
          <Stack spacing={2}>
            <Typography variant="h5">Supported Columns</Typography>
            <Typography variant="body1">
              The following columns are supported in the CSV: firstName, lastName, phone,
              email, cardId
            </Typography>
            <CSVReader
              cssClass="react-csv-input"
              label="The file must be a CSV formatted file."
              onFileLoaded={(data) => addUserMutation.mutate(data)}
              parserOptions={{
                header: true,
                dynamicTyping: true,
                skipEmptyLines: true,
              }}
              accept=".csv, text/csv"
            />
          </Stack>
        </CardContent>
      </Card>
    </PageContainer>
  );
};

export default AddUsersFromCsv;
