import React from 'react';
import { Button, ButtonGroup, OverlayTrigger, Popover } from 'react-bootstrap';

interface ButtonProps {
  primaryName?: string;
  primaryOnClick?: Function;
  primaryDisabled?: boolean;
  saveButtonLoading?: boolean;
  customPrimaryClass?: string;
  dataId1?: string;
  dataId2?: string;
  dataId3?: string;
  primaryText: string | JSX.Element;
  primaryLoaded?: boolean;
  buttons: number;
  secondaryName?: string;
  secondaryDisabled?: boolean;
  secondaryOnClick?: Function;
  customSecondaryClass?: string;
  secondaryText?: string | JSX.Element;
  secondaryLoaded?: boolean;
  ternaryName?: string;
  ternaryDisabled?: boolean;
  ternaryOnClick?: Function;
  customTernaryClass?: string;
  ternaryText?: string | JSX.Element;
  ternaryLoaded?: boolean;
  groupClass?: string;
  overlay?: boolean;
  popover?: string;
}

const Buttons = ({
  primaryName,
  primaryOnClick,
  primaryDisabled,
  saveButtonLoading,
  customPrimaryClass,
  dataId1,
  dataId2,
  dataId3,
  groupClass,
  primaryText,
  primaryLoaded,
  buttons,
  secondaryName,
  secondaryDisabled,
  secondaryOnClick,
  customSecondaryClass,
  secondaryText,
  secondaryLoaded,
  ternaryName,
  ternaryDisabled,
  ternaryOnClick,
  customTernaryClass,
  ternaryText,
  ternaryLoaded,
  popover,
  overlay,
}: ButtonProps) => {
  const buttonsRender = () => {
    return (
      <ButtonGroup className={`btn-group-sm floatRight ${groupClass || ''}`}>
        {/* first button */}
        <Button
          name={primaryName}
          onClick={() => primaryOnClick?.()}
          disabled={primaryDisabled || saveButtonLoading}
          className={`primaryButton ${customPrimaryClass || ''}`}
          data-id={dataId1}
        >
          {primaryText}
          {primaryLoaded || primaryLoaded === undefined ? (
            saveButtonLoading ? (
              <div className="lds-dual-ring" />
            ) : (
              ''
            )
          ) : (
            <div className="lds-dual-ring" />
          )}
        </Button>

        {/* second button */}
        {buttons === 2 || buttons === 3 ? (
          <Button
            name={secondaryName}
            disabled={secondaryDisabled || saveButtonLoading}
            onClick={() => secondaryOnClick?.()}
            className={`secondaryButton ${customSecondaryClass || ''}`}
            data-id={dataId2}
          >
            {secondaryText}
            {secondaryLoaded || secondaryLoaded === undefined ? (
              saveButtonLoading ? (
                <div className="lds-dual-ring" />
              ) : (
                ''
              )
            ) : (
              <div className="lds-dual-ring" />
            )}
          </Button>
        ) : null}

        {/* third button */}
        {buttons === 3 ? (
          <Button
            name={ternaryName}
            disabled={ternaryDisabled || saveButtonLoading}
            onClick={() => ternaryOnClick?.()}
            className={`secondaryButton ${customTernaryClass || ''}`}
            data-id={dataId3}
          >
            {ternaryText}
            {ternaryLoaded || ternaryLoaded === undefined ? (
              saveButtonLoading ? (
                <div className="lds-dual-ring" />
              ) : (
                ''
              )
            ) : (
              <div className="lds-dual-ring" />
            )}
          </Button>
        ) : null}
      </ButtonGroup>
    );
  };
  if (overlay && popover) {
    const popoverRender = (
      <Popover id={'buttom-popover'}>
        <Popover.Content>{popover}</Popover.Content>
      </Popover>
    );

    return (
      <OverlayTrigger placement="top" overlay={popoverRender}>
        {buttonsRender()}
      </OverlayTrigger>
    );
  }
  return buttonsRender();
};

export default Buttons;
