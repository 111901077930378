import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import useOneTicket from '../../hooks/maintenance/query/useOneTicket';
import useSystemAdmins from '../../hooks/system/query/useSystemAdmins';
import { getPriorityName, history, isEmptyObject, toTitleCase } from '../../utils';
import { editTicket } from '../../api/problems';
import { IAdmin } from '../../ts/interface/admins.interface';
import { ISystem } from '../../ts/interface/system.interface';
import { IMode } from '../../ts/interface/mode.interface';
import { ITicket } from '../../ts/interface/problem.interface';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import SaveIcon from '@mui/icons-material/Save';
import {
  MovaticFieldGroupDate,
  MovaticFieldGroupSelect,
} from '../../components/Redesing/movatic-field-group';
import CustomToast from '../../components/Redesing/custom-toast';
import MenuItem from '@mui/material/MenuItem';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';
import { Stack } from '@mui/system';
import CardView from '../../components/Redesing/card-view';
import PageContainer from '../../components/Redesing/page-container';

interface IEditTicketState {
  assignee: string;
  priority: string;
  due_on: string;
  dateValidation: string | null;
}

const EditTicket = ({
  mode,
  system,
  loaded,
}: {
  mode: IMode;
  system: ISystem;
  loaded: boolean;
}) => {
  const { ticketId = '' } = useParams<{ ticketId: string }>();
  const initialState = {
    assignee: '',
    priority: '',
    due_on: '',
    dateValidation: null,
  };
  const [{ assignee, priority, due_on, dateValidation }, setState] =
    useState<IEditTicketState>(initialState);

  const { data: ticket = {}, isLoading } = useOneTicket(ticketId, {
    enabled: loaded,
  });
  const { data: admins = [] } = useSystemAdmins({
    enabled: loaded,
  });

  useEffect(() => {
    if (!isEmptyObject(ticket)) {
      setState((prevState) => ({
        ...prevState,
        assignee: ticket.assignee || '',
        priority: ticket.priority,
        due_on: new Date(ticket.due_on).toISOString(),
      }));
    }
  }, [ticket]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateChange = (date: Date) => {
    if (date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
      setState((prevState) => ({
        ...prevState,
        dateValidation: 'error',
      }));
      CustomToast({
        type: 'error',
        message: 'Due date must be a future date',
      });
    } else {
      setState((prevState) => ({
        ...prevState,
        due_on: new Date(date).toISOString(),
        dateValidation: null,
      }));
    }
  };

  const save = () => {
    if (
      due_on &&
      new Date(due_on).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)
    ) {
      const ticket = {
        assignee: assignee && assignee !== 'None' ? assignee : null,
        priority: priority,
        due_on: due_on,
        id: ticketId,
      };
      CustomAsyncToast({
        promise: () => editTicket(ticket),
        successMessage: () => 'Ticket updated successfully',
        loadingMessage: 'Updating ticket...',
        errorMessage: 'Unable to update ticket',
      }).then((r) => {
        if (r) {
          history.push(`/maintenance/${ticketId}`);
        }
      });
    } else {
      setState((prevState) => ({
        ...prevState,
        dateValidation: 'error',
      }));
      CustomToast({
        type: 'error',
        message: 'Due date must be a future date',
      });
    }
  };
  const getAdmins = () => {
    if (admins.length > 0) {
      const adminOptions = admins.map((admin: IAdmin) => ({
        value: admin.email,
        name: `${admin.firstName} ${admin.lastName}`,
      }));
      adminOptions.unshift({ value: '', name: 'None' });
      return adminOptions;
    }
    return [{ value: '', name: 'None' }];
  };

  const getHeader = (ticket: ITicket) => {
    let header = 'Edit Ticket for ';
    // TODO: remove this
    if (system.new_hardware) {
      if (ticket?.hardware_number) {
        header += `${toTitleCase(ticket?.hardware_type_name)} ${ticket?.hardware_number}`;
      } else {
        header += ticket[ticket?.primary_identifier];
      }
    } else {
      header += `${mode.details.unitsTitle} ${ticket?.hardware_number}`;
    }
    return header;
  };

  return (
    <PageContainer isLoading={isLoading}>
      <CardView
        content={
          <Stack px={2} pb={2}>
            <MovaticFieldGroupDate
              id="archived_on"
              label="Due"
              description="Due date for this ticket."
              value={new Date(due_on) || null}
              hasError={dateValidation === 'error'}
              onChange={handleDateChange}
            />

            <MovaticFieldGroupSelect
              id="priority"
              label="Priority"
              description="Priority of this ticket."
              optionNodes={[
                { value: 1, name: `1 - ${getPriorityName(1)}` },
                { value: 2, name: `2 - ${getPriorityName(2)}` },
                { value: 3, name: `3 - ${getPriorityName(3)}` },
                { value: 4, name: `4 - ${getPriorityName(4)}` },
                { value: 5, name: `5 - ${getPriorityName(5)}` },
              ].map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
              value={priority || 3}
              onChange={handleChange}
              name="priority"
            />

            <MovaticFieldGroupSelect
              id="assignee"
              label="Assignee"
              description="Priority of this ticket."
              optionNodes={getAdmins().map(
                (option: { name: string; value: string }, index: number) => (
                  <MenuItem key={index} value={option.value}>
                    {option.name}
                  </MenuItem>
                )
              )}
              value={assignee}
              onChange={handleChange}
              name="assignee"
            />
          </Stack>
        }
        title={getHeader(ticket)}
        headerActions={
          <IconButtonMenu
            buttons={[
              {
                label: 'Cancel',
                onClick: () => history.push(`/maintenance/${ticketId}`),
              },
              {
                label: 'Save',
                onClick: save,
                startIcon: <SaveIcon />,
              },
            ]}
          />
        }
      />
    </PageContainer>
  );
};

export default connect(
  (state: { system: { current: ISystem; isLoaded: boolean }; mode: IMode }) => ({
    system: state.system.current,
    loaded: state.system.isLoaded,
    mode: state.mode,
  }),
  () => ({})
)(EditTicket);
