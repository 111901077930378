import React, { useState, useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import { postCardholderTosApproval } from '../../../api/issuingCard';
import bugsnagClient from '../../../components/bugsnag';
import useIpifyData from '../../../hooks/ipifyData/useIpifyData';
import useIssuingCardholder from '../../../hooks/issuingCard/query/useIssuingCardholder';
import Box from '@mui/material/Box';

const ApproveCardholderTos = ({
  cardholderId,
  systemId,
}: {
  cardholderId: string;
  systemId: string;
}) => {
  const [subtitle, setSubtitle] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [textColor, setTextColor] = useState<string>('');
  const [approvalSent, setApprovalSent] = useState<boolean>(false);
  const [documentationUrl, setDocumentationUrl] = useState<string>(
    'https://documentation.movatic.co/admin-site/billing/issuing-cards'
  );

  const ipAddress = useIpifyData().ipAddress;
  const cardholderResponse = useIssuingCardholder(cardholderId, systemId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (cardholderResponse.data && !approvalSent && ipAddress) {
          const tos_approval_payload = {
            cardholder_id: cardholderId,
            ip_address: ipAddress,
            system_id: systemId,
          };
          const tosAppprovalResponse = await postCardholderTosApproval(
            tos_approval_payload
          );
          setApprovalSent(true);
          if (tosAppprovalResponse) {
            setSubtitle('Your approval of the terms of service has been recorded.');
            setContent(
              'For more information about how this works, please read our documentation at the link below: '
            );
            setTextColor('#25a937');
          } else {
            setSubtitle('Terms of service approval error');
            setContent(
              'There was an error approving the terms of service. Please try again later and if the problem persists contact support.'
            );
            setTextColor('#ce2410');
            setDocumentationUrl('');
          }
        } else if (!cardholderResponse.data && approvalSent) {
          setSubtitle('Terms of service approval error');
          setContent(
            'The cardholder could not be found. Please try again later and if the problem persists contact support.'
          );
          setTextColor('#ce2410');
          setDocumentationUrl('');
        }
      } catch (error) {
        bugsnagClient.notify(`Error fetching IP address: ${error}`);
      }
    };

    fetchData();
  }, [cardholderId, systemId, ipAddress, cardholderResponse, approvalSent]);

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={4}
      sx={{ marginTop: '15vh' }}
    >
      <Box
        sx={{
          border: '1px solid #ccc',
          borderRadius: '4px',
          p: 2,
        }}
      >
        <Typography variant="h5" gutterBottom color={textColor}>
          {subtitle}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {content}
        </Typography>
        <a href={documentationUrl} target="_blank" rel="noreferrer">
          <Typography>{documentationUrl}</Typography>
        </a>
      </Box>
    </Stack>
  );
};

export default ApproveCardholderTos;
