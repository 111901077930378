import React, { useState } from 'react';
import { TextField, Chip, Box } from '@mui/material';

interface MovaticFieldMultiOptionsProps {
  groupValues: string[];
  onChangeInput: (newGroupValues: string[]) => void;
  dataId?: string;
}

const MovaticFieldMultiChipsOptions: React.FC<MovaticFieldMultiOptionsProps> = ({
  groupValues,
  onChangeInput,
  dataId = 'group-chips-input',
}) => {
  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const keys = ['Enter', ',', ' '];
    if (keys.includes(event.key)) {
      event.preventDefault();
      const newValues = inputValue
        .split(',')
        .map((v) => v.trim())
        .filter((v) => v && !groupValues.includes(v));

      if (newValues.length) {
        onChangeInput([...groupValues, ...newValues]);
        setInputValue('');
        setErrorMessage('');
      } else {
        setErrorMessage(
          'This value is already in the list. Please enter a different value.'
        );
      }
    }
  };

  const handlePaste = (event: React.ClipboardEvent) => {
    event.preventDefault(); // Prevent the default paste action
    const pastedText = event.clipboardData.getData('text'); // Get the pasted text

    // Process the pasted text similarly to how you handle key down for entering values
    const newValues = pastedText
      .split(',')
      .map((v) => v.trim())
      .filter((v) => v && !groupValues.includes(v)); // Filter out duplicates

    if (newValues.length) {
      onChangeInput([...groupValues, ...newValues]);
      setInputValue(''); // Clear the input field
      setErrorMessage('');
    } else {
      setErrorMessage(
        'This value is already in the list. Please enter a different value.'
      );
    }
  };

  const handleBlur = () => {
    const newValues = inputValue
      .split(',')
      .map((v) => v.trim())
      .filter((v) => v && !groupValues.includes(v));

    if (newValues.length) {
      onChangeInput([...groupValues, ...newValues]);
      setInputValue('');
      setErrorMessage('');
    } else {
      setErrorMessage(
        'This value is already in the list. Please enter a different value.'
      );
    }
  };

  const handleDeleteValue = (valueToDelete: string) => {
    const updatedGroupValues = groupValues.filter((value) => value !== valueToDelete);
    onChangeInput(updatedGroupValues);
  };

  return (
    <>
      <TextField
        data-id={dataId}
        variant="standard"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onPaste={handlePaste}
        error={Boolean(errorMessage)}
        helperText={errorMessage}
      />
      <Box mt={1} data-id={`group-chips-values`}>
        {groupValues.map((value, index) => (
          <Chip
            id={index.toString()}
            key={value}
            label={value}
            onDelete={() => handleDeleteValue(value)}
            color="primary"
            variant="filled"
            style={{ margin: '0.1em' }}
          />
        ))}
      </Box>
    </>
  );
};

export default MovaticFieldMultiChipsOptions;
