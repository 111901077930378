import React, { Component } from 'react';
import { connect } from 'react-redux';
import { baseUrl } from '../../api/http';
import {
  setDetailedSystem,
  updateSystemImage,
  getSystemImages,
  getAllSystems,
  insertImage,
  updateSystemLogoID,
  updateSystemBannerID,
  update,
} from '../../actionCreators/system';
import bugsnagClient from '../../components/bugsnag';
import CardView from '../../components/Redesing/card-view';
import BrandingColor from './branding-color';
import { Stack } from '@mui/system';
import FileUpload from '../../components/Redesing/file-upload';
import CustomToast from '../../components/Redesing/custom-toast';
import Typography from '@mui/material/Typography';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import SaveIcon from '@mui/icons-material/Save';
import NoFilePlaceHolder from '../../components/Redesing/no-file-place-holder';

class BrandingInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoFiles: [],
      bannerFiles: [],
      colorPrimary: this.props.currentSystem.colorPrimary,
      colorSecondary: this.props.currentSystem.colorSecondary,
      colorChanged: false,
    };
    this.handleLogoUpload = this.handleLogoUpload.bind(this);
    this.handleBannerUpload = this.handleBannerUpload.bind(this);

    this.handleColorChange = this.handleColorChange.bind(this);
    this.save = this.save.bind(this);
    this.logo = this.logo.bind(this);
    this.banner = this.banner.bind(this);
    this.button = this.button.bind(this);
  }

  save(event) {
    const { logoFiles, bannerFiles, colorChanged } = this.state;
    // check if anything has changed
    if (logoFiles.length > 0 || bannerFiles.length > 0) {
      const formData = new FormData();
      if (logoFiles.length > 0) {
        formData.append('logofile', logoFiles[0]);
      }

      if (bannerFiles.length > 0) {
        formData.append('systemBannerFile', bannerFiles[0]);
      }

      // post it to the server
      fetch(`${baseUrl}/system/settings/image`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Basic ${btoa(
            `${this.props.auth.admin.email}:${this.props.auth.token}`
          )}`,
          system: this.props.systemId,
        },
      })
        .then((res) => res.json())
        .then((results) => {
          // if it went through
          if (results.status === 200) {
            this.props.insertImage(results.message);
            if (results.message.type === 'SystemLogo') {
              this.props.updateSystemLogoID(results.message.id);
            } else {
              this.props.updateSystemBannerID(results.message.id);
            }
            this.props.updateSystemImage(results.message);
            // show success
            if (bannerFiles.length > 0 && logoFiles.length > 0) {
              CustomToast({
                type: 'success',
                message: 'Logo and Banner uploaded!',
                duration: 5000,
              });
              this.setState({
                logoFiles: [],
                bannerFiles: [],
              });
            } else if (logoFiles.length > 0) {
              CustomToast({
                type: 'success',
                message: 'Logo uploaded!',
                duration: 5000,
              });
              this.setState({
                logoFiles: [],
              });
            } else if (bannerFiles.length > 0) {
              CustomToast({
                type: 'success',
                message: 'Banner uploaded!',
                duration: 5000,
              });
              this.setState({
                bannerFiles: [],
              });
            }
            this.props.isChanged(false);
          } else {
            throw new Error(results.message);
          }
        })
        .catch((error) => {
          CustomToast({
            type: 'error',
            message: 'There was an issue while uploading your image. Please try again.',
            duration: 5000,
          });
          bugsnagClient.notify(error);
        });
    }

    if (colorChanged) {
      // the branding colors have been updated, need to make an object to send to the server
      const systemBrandingObj = {
        colorPrimary: this.state.colorPrimary,
        colorSecondary: this.state.colorSecondary,
      };

      this.props.submit(systemBrandingObj);
    }
  }

  handleLogoUpload(files, rejected) {
    if (rejected.length > 0) {
      CustomToast({
        type: 'error',
        message: 'Incorrect file type. Please try a different image.',
        duration: 5000,
      });
      return;
    }

    const images = files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    const image = new Image();
    image.src = images[0].preview;

    image.onload = () => {
      if (image.width !== 200 || image.height !== 200) {
        CustomToast({
          type: 'error',
          message: 'Your image is not 200px by 200px, please reformat and try again',
          duration: 5000,
        });
        this.setState({
          logoFiles: [],
        });
      } else {
        this.setState({ logoFiles: images }, this.props.isChanged(true));
      }
    };
  }

  handleBannerUpload(files, rejected) {
    if (rejected.length > 0) {
      CustomToast({
        type: 'error',
        message: 'Incorrect file type. Please try a different image.',
        duration: 5000,
      });

      return;
    }

    const images = files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    const image = new Image();
    image.src = images[0].preview;

    image.onload = () => {
      if (image.width !== 900 || image.height !== 300) {
        CustomToast({
          type: 'error',
          message: 'Your image is not 900px by 300px, please reformat and try again',
          duration: 5000,
        });
        this.setState({
          bannerFiles: [],
        });
      } else {
        this.setState({ bannerFiles: images }, this.props.isChanged(true));
      }
    };
  }

  handleColorChange(colorEvent, name) {
    this.setState(
      {
        [name]: colorEvent.hex,
        colorChanged: true,
      },
      this.props.isChanged(true)
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.currentSystem !== nextProps.currentSystem) {
      this.setState({
        colorPrimary: nextProps.currentSystem.colorPrimary,
        colorSecondary: nextProps.currentSystem.colorSecondary,
      });
    }
  }

  logo() {
    const logo = this.props.images.filter(
      (image) =>
        image.id === this.props.currentSystem.logo_id && image.type === 'SystemLogo'
    );
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        pb={2}
        px={2}
      >
        <Stack direction={'column'}>
          <Typography variant={'subtitle1'} pb={1}>
            Current Logo
          </Typography>
          <div id="currentLogo">
            {logo.length > 0 ? (
              <div className="logoContainer">
                {logo.length > 0 ? (
                  !logo[0].approved_on && !logo[0].rejected ? (
                    <i className="fa fa-hourglass-half fa-fw navIconStyle navIcon pull-right logoIcon " />
                  ) : logo[0].rejected ? (
                    <i className="fa fa-ban fa-fw navIconStyle navIcon pull-right imageReject logoIcon " />
                  ) : logo[0].approved_on ? (
                    <i className="fa fa-check-circle fa-fw navIconStyle navIcon pull-right imageApprove logoIcon">
                      {' '}
                    </i>
                  ) : null
                ) : null}
                <img
                  alt=""
                  className="logoRadius"
                  src={`data:image/png;base64,${logo[0].image}`}
                />
                <div className="logoOverlay">
                  <div className="imageText">
                    {!logo[0].approved_on && !logo[0].rejected
                      ? 'Image is being reviewed by Movatic.'
                      : logo[0].rejected
                      ? 'Image has been rejected, due to poor quality or due to cropping issues.'
                      : logo[0].approved_on
                      ? 'Image has been approved.'
                      : null}
                  </div>
                </div>
              </div>
            ) : (
              <NoFilePlaceHolder />
            )}
          </div>
        </Stack>
        {this.state.logoFiles.length > 0 ? (
          <Stack direction={'column'}>
            <div className="defaultLabel">
              <span>Logo Preview</span>
            </div>
            <img
              alt=""
              className="logoRadius"
              name="logofile"
              src={this.state.logoFiles[0].preview}
            />
          </Stack>
        ) : null}
        <Stack direction={'column'}>
          <Typography variant={'subtitle1'}>Upload System Logo</Typography>
          <FileUpload
            caption={'The file must be a .png, 200px wide, 200px high, and less than 1MB'}
            onDrop={this.handleLogoUpload}
          />
        </Stack>
      </Stack>
    );
  }

  banner() {
    const banner = this.props.images.filter(
      (image) =>
        image.id === this.props.currentSystem.banner_id && image.type === 'SystemBanner'
    );
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        // spacing={2}
        pb={2}
        px={2}
      >
        <Stack direction={'column'} justifyContent={'flex-start'}>
          <Typography variant={'subtitle1'}>Current Banner</Typography>
          <div id="currentBanner">
            {banner.length > 0 ? (
              <div>
                {banner.length > 0 ? (
                  !banner[0].approved_on && !banner[0].rejected ? (
                    <i className="fa fa-hourglass-half fa-fw navIconStyle navIcon pull-right settingsdetailIcon" />
                  ) : banner[0].rejected ? (
                    <i className="fa fa-ban fa-fw navIconStyle navIcon pull-right imageReject settingsdetailIcon" />
                  ) : banner[0].approved_on ? (
                    <i className="fa fa-check-circle fa-fw navIconStyle navIcon pull-right imageApprove settingsdetailIcon">
                      {' '}
                    </i>
                  ) : null
                ) : null}
                <img
                  alt="bannerImg"
                  className="bannerImg"
                  src={`data:image/png;base64,${banner[0].image}`}
                />
                <div className="imageOverlay">
                  <div className="stationText imageText">
                    {!banner[0].approved_on && !banner[0].rejected
                      ? 'Image is being reviewed by Movatic.'
                      : banner[0].rejected
                      ? 'Image has been rejected, due to poor quality or due to cropping issues.'
                      : banner[0].approved_on
                      ? 'Image has been approved.'
                      : null}
                  </div>
                </div>
              </div>
            ) : (
              <NoFilePlaceHolder />
            )}
          </div>
        </Stack>
        {this.state.bannerFiles.length > 0 ? (
          <Stack direction={'column'}>
            <div className="defaultLabel">
              <span>Banner Preview</span>
            </div>
            <img
              alt=""
              className="bannerImg placeHolder"
              id="currentBanner"
              name="bannerfile"
              src={this.state.bannerFiles[0].preview}
            />
          </Stack>
        ) : null}
        <Stack direction={'column'}>
          <Typography variant={'subtitle1'}>Upload System Banner</Typography>
          <FileUpload
            caption={'The file must be a .png, 900px wide, 300px high, and less than 1MB'}
            onDrop={this.handleBannerUpload}
          />
        </Stack>
      </Stack>
    );
  }

  button() {
    return (
      <IconButtonMenu
        buttons={[
          {
            label: 'Save',
            startIcon: <SaveIcon />,
            onClick: () => this.save(),
            disabled: this.props.buttonDisabled,
          },
        ]}
      />
    );
  }

  render() {
    return (
      <Stack spacing={2}>
        <BrandingColor
          actions={this.button()}
          colorPrimary={this.state.colorPrimary}
          colorSecondary={this.state.colorSecondary}
          handleColorChange={this.handleColorChange}
          isChanged={this.props.isChanged}
        />

        <CardView headerActions={this.button()} content={this.logo()} title={'Logo'} />

        <CardView
          headerActions={this.button()}
          content={this.banner()}
          title={'Banner'}
        />
      </Stack>
    );
  }
}

export default connect(
  (state) => ({
    images: state.system.images,
    auth: state.admin.admin,
    systemId: state.system.current.id,
    systems: state.system.systems,
    currentSystem: state.system.current,
    saveButtonLoading: state.system.loading,
  }),
  (dispatch) => ({
    setDetail(system) {
      dispatch(setDetailedSystem(system));
    },
    updateSystemImage(image) {
      dispatch(updateSystemImage(image));
    },
    getSystemImages() {
      dispatch(getSystemImages());
    },
    refreshSystems(auth) {
      return dispatch(getAllSystems(auth));
    },
    insertImage(image) {
      return dispatch(insertImage(image));
    },
    updateSystemLogoID(imageid) {
      return dispatch(updateSystemLogoID(imageid));
    },
    updateSystemBannerID(imageid) {
      return dispatch(updateSystemBannerID(imageid));
    },
    update(loading) {
      dispatch(update(loading));
    },
  })
)(BrandingInfo);
