import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ISystem, IUseStripeAccountInfo } from '../../ts/interface/system.interface';
import DashboardMap from './dashboardMap';
import DashboardResume from './dashboardResume';
import { IHardware } from '../../ts/interface/hardware.interface';
import useStation from '../../hooks/stations/query/useStation';
import {
  LOCAL_STORAGE_SYSTEM_KEY,
  LOST,
  MISSING,
  monthArray,
  RETIRED,
} from '../../constants';
import { IMode } from '../../ts/interface/mode.interface';
import useAllHardware from '../../hooks/hardware/query/useAllHardware';
import PageContainer from '../../components/Redesing/page-container';
import Stack from '@mui/material/Stack';
import {
  IChartData,
  IDashboardGraph,
  IDashboardGraphConfig,
  IDashboardGraphDataLabels,
} from '../../ts/interface/dashboardGraph.interface';
import { formatDateOffsetISO } from '../../utils';
import useGraphStats from '../../hooks/dashaboard/query/useGraphStats';
import Controls from './assets/controls';
import YearGraphs from './assets/yearGraphs';
import MonthGraphs from './assets/month-graphs';
import { Paper } from '@mui/material';
import PageLoader from '../../components/Redesing/page-loader';
import CardLoader from '../../components/Redesing/card-loader';
import StripeBanner from '../../components/Redesing/stripe-banner';
import useStripeAccountInfo from '../../hooks/system/query/useStripeAccountInfo';
import { StripeRequirementStatus, StripeRequirementType } from '../../ts/enums';
import { useCookies } from 'react-cookie';
import { useUpdateEffect } from '../../hooks/use-update-effect';
import useReadStorage from '../../hooks/useReadStorage';
import useDirectusData from '../../hooks/directus/query/useDirectusData';
import MovaticCustomModal from '../../components/Modal/MovaticCustomModal';
import Typography from '@mui/material/Typography';

import { LocationStatus } from '../../ts/enums';

const timeZones = require('../../../data/timezones.json');

const daysInMonth = (month: number, year: number, checkForCurrentMonth: boolean) => {
  const date = new Date();
  if (checkForCurrentMonth && date.getFullYear() === year && date.getMonth() === month) {
    return date.getDate();
  }
  return new Date(year, month, 0).getDate();
};

const getDurationData = (durations: { [key: string]: number }) => {
  if (!durations.total_rentals) {
    return {
      labels: [],
      data: [],
    };
  }
  const labels = [
    '<5',
    '5-10',
    '10-20',
    '20-30',
    '30-60',
    '60-90',
    '90-120',
    '120-180',
    '>180',
  ];
  const data = labels.map((label) => durations[label] || 0);
  return {
    labels,
    data,
  };
};

const getRangeArray = (start: number, end: number) => {
  return new Array(end - start + 1)
    .fill(undefined)
    .map((_, i) => i + start)
    .reverse();
};

const Welcome = ({
  system,
  systemLoaded,
  systemAccess,
  mode,
}: {
  system: ISystem;
  systemLoaded: boolean;
  systemAccess: number;
  mode: IMode;
}) => {
  const systemIdS = useReadStorage(LOCAL_STORAGE_SYSTEM_KEY);
  const [graphStats, setGraphStats] = useState<IDashboardGraph | null>(null);
  const [currentMonth, setCurrentMonth] = useState<number>(0);
  const [yearArray, setYearArray] = useState<number[]>([]);
  const [currentYear, setCurrentYear] = useState<number>(0);
  const [timePeriod, setTimePeriod] = useState<string>('year');
  const [graphStatsConfig, setGraphStatsConfig] = useState<IDashboardGraphConfig>({
    type: 'month',
  });
  const [durationAvg, setDurationAvg] = useState<string>('');
  const [rentalData, setRentalData] = useState<
    IDashboardGraphDataLabels | [] | undefined
  >([]);
  const [userData, setUserData] = useState<IDashboardGraphDataLabels | [] | undefined>(
    []
  );
  const [durationData, setDurationData] = useState<
    IDashboardGraphDataLabels | [] | undefined
  >([]);
  const [systemTimezoneLabel, setSystemTimezoneLabel] = useState<string>('');
  const [rentalAvg, setRentalAvg] = useState<string>('');
  const [userAvg, setUserAvg] = useState<string>('');
  const [userTotal, setUserTotal] = useState<number>(0);
  const [showWelcomeBanner, setShowWelcomeBanner] = useState(false);
  const [cookies, setCookie] = useCookies([]);
  const [canShowBanner, setCanShowBanner] = useState<boolean>(false);

  const { data } = useDirectusData('adminSiteWelcomeBanner');
  const directusBannerData = data?.data?.[0];

  const { data: stringAccountInfo } = useStripeAccountInfo(systemIdS, {
    enabled: !!system?.id && system?.billing && system.payment_provider === 'stripe',
  });

  useUpdateEffect(() => {
    if (
      !cookies['banner_introduction' as keyof typeof cookies] &&
      directusBannerData?.videoLink
    ) {
      setShowWelcomeBanner(true);
    }
  }, [cookies, directusBannerData?.videoLink]);

  const cookieExist = useCallback(
    (key: string) => {
      if (!stringAccountInfo) return null;
      // @ts-ignore
      const requirements = stringAccountInfo?.[key];
      if (!requirements) return null;
      const { current_deadline } = requirements;
      return cookies[
        `stripe_${key}_${current_deadline + system.id}` as keyof typeof cookies
      ];
    },
    [stringAccountInfo, cookies, system?.id]
  );

  const getOptionDateConfig = ({
    type,
    customMonth,
    customYear,
  }: {
    type: string;
    customMonth?: number;
    customYear?: number;
  }) => {
    const { timezone } = system;
    const date = new Date();
    const year = customYear ?? date.getFullYear();
    let month = customMonth ?? date.getMonth();
    let start;
    let end;
    const systemTimezone = timeZones.find((zone: { utc: string[] }) =>
      zone.utc.find((name: string) => name === timezone)
    );
    const offset = systemTimezone?.text.slice(4, 10);
    const monthSelected = month + 1;
    const monthFormat = monthSelected < 10 ? `0${monthSelected}` : monthSelected;
    if (type === 'month') {
      month += 1;
      start = formatDateOffsetISO(year, monthFormat, '01', '00:00:00', offset);
      end = formatDateOffsetISO(
        year,
        monthFormat,
        daysInMonth(month, year, false),
        '23:59:00',
        offset
      );
    } else {
      start = formatDateOffsetISO(year, '01', '01', '00:00:00', offset);
      end = formatDateOffsetISO(year, '12', '31', '23:59:00', offset);
    }
    return {
      end,
      start,
      type,
    };
  };

  const { isLoading: isLoadingGraphStats } = useGraphStats(
    {
      onSuccess: (data: IDashboardGraph) => {
        setGraphStats(data);
      },
      enabled: systemLoaded,
    },
    { parameters: getOptionDateConfig(graphStatsConfig), systemId: system.id }
  );

  const countArrayToObj = useCallback(
    (array: { [key: string]: number }[]) => {
      const period = timePeriod === 'month' ? 'day' : 'month';
      return array.reduce((obj, item) => {
        obj[item[period]] = item.count;
        return obj;
      }, {});
    },
    [timePeriod]
  );

  const getCountData = useCallback(
    (array: { count: number; day: number }[]) => {
      if (!array?.length) {
        return {
          data: [],
          labels: [],
        };
      }

      if (timePeriod === 'month') {
        const days = daysInMonth(currentMonth + 1, yearArray[currentYear], false);
        const labels = [];
        const data = [];

        for (let i = 1; i <= days; i++) {
          labels.push(i);
          data.push(countArrayToObj(array)[i] || 0);
        }

        return { labels, data };
      }
      if (timePeriod === 'year') {
        const labels = monthArray;
        const data = [];

        for (let i = 1; i <= 12; i++) {
          data.push(countArrayToObj(array)[i] || 0);
        }

        return { labels, data };
      }
    },
    [countArrayToObj, yearArray, currentMonth, currentYear, timePeriod]
  );

  const hasRequiredStripeInformation = useCallback(
    (key: string, keysToCheck: string[] = []) => {
      if (!stringAccountInfo) return false;
      const requirements = stringAccountInfo[key];
      if (!requirements) return false;

      return (
        keysToCheck.some((k) => {
          return Boolean(requirements[k]?.length);
        }) && system?.billing
      );
    },
    [stringAccountInfo, system?.billing]
  );

  useEffect(() => {
    if (systemLoaded && !isLoadingGraphStats && graphStats) {
      const { timezone } = system;

      setSystemTimezoneLabel(
        timeZones.find((zone: { utc: string[] }) =>
          zone.utc.find((name: string) => name === timezone)
        )?.text
      );

      const rentalTotal = graphStats?.durations?.total_rentals;
      setRentalAvg(
        timePeriod === 'month'
          ? (
              rentalTotal / daysInMonth(currentMonth, yearArray[currentYear], true)
            ).toFixed()
          : (rentalTotal / 12).toFixed()
      );

      const userTotalAux = graphStats?.users?.reduce(
        (acc: number, curr: { count: number }) => acc + curr.count,
        0
      );

      setUserTotal(userTotalAux);
      setUserAvg(
        timePeriod === 'month'
          ? (
              userTotalAux / daysInMonth(currentMonth, yearArray[currentYear], true)
            ).toFixed()
          : (userTotalAux / 12).toFixed()
      );

      const durationTotal = graphStats?.durations?.total_duration || 0;

      setDurationAvg(rentalTotal ? (durationTotal / rentalTotal).toFixed() : '0');
      if (graphStats.rentals) {
        setRentalData(getCountData(graphStats.rentals));
      }
      if (graphStats.users) {
        setUserData(getCountData(graphStats.users));
      }
      if (graphStats.durations) {
        setDurationData(getDurationData(graphStats.durations));
      }
    }
  }, [
    graphStatsConfig,
    graphStats,
    system,
    systemLoaded,
    currentMonth,
    currentYear,
    isLoadingGraphStats,
    timePeriod,
    yearArray,
    getCountData,
  ]);

  useEffect(() => {
    if (systemLoaded) {
      setYearArray(
        getRangeArray(new Date(system.created_on).getFullYear(), new Date().getFullYear())
      );
    }
  }, [system, systemLoaded]);

  const updatePeriod = (
    keyOld: string,
    valueOld: string,
    updateStates: boolean,
    obj: any
  ) => {
    const { key, currentYear, currentMonth, timePeriod } = obj;
    let year;
    if (key === 'currentYear') {
      setCurrentYear(currentYear);
      year = yearArray[currentYear];
    } else {
      year = yearArray[currentYear];
    }
    if (updateStates) {
      setTimePeriod(timePeriod);
      setCurrentMonth(currentMonth);
    }

    setGraphStatsConfig({
      type: timePeriod,
      customMonth: currentMonth,
      customYear: year,
    });
  };

  const { data: allHardware = [], isLoading: isLoadingAllHardware } = useAllHardware({
    enabled: systemLoaded,
  });

  const { data: stat, isLoading: isLoadingStations } = useStation(
    {
      enabled: systemLoaded && !!system?.id,
    },
    {
      status: LocationStatus.all,
      system_id: system.gid,
    }
  );

  const stations = stat?.data || [];

  const isLoaded = useCallback(() => {
    return !isLoadingStations && !isLoadingAllHardware;
  }, [isLoadingAllHardware, isLoadingStations]);

  useUpdateEffect(() => {
    if (stringAccountInfo && system && system.id && systemLoaded) {
      setCanShowBanner(true);
    }
  }, [stringAccountInfo, system, systemLoaded]);

  return (
    <PageContainer
      spaceName={'Dashboard'}
      warningMessage={
        hasRequiredStripeInformation(StripeRequirementType.now, [
          StripeRequirementStatus.currentlyDue,
          StripeRequirementStatus.pastDue,
        ])
          ? 'Stripe information is missing to enable billing.'
          : hasRequiredStripeInformation(StripeRequirementType.now, [
              StripeRequirementStatus.eventuallyDue,
            ])
          ? 'Stripe information update required soon'
          : ''
      }
    >
      <Stack direction={'column'} spacing={2}>
        <Stack>
          <DashboardResume
            systemId={system.id}
            systemAccess={systemAccess}
            systemLoaded={systemLoaded}
            mode={mode}
            new_hardware={system.new_hardware}
          />
        </Stack>

        <Stack>
          {!isLoaded() ? (
            <CardLoader useCard />
          ) : (
            <DashboardMap
              isLoading={!isLoaded()}
              stations={stations}
              units={allHardware.filter((unit: IHardware) => {
                const isRetired = unit.state === RETIRED;
                const hasValidLocation =
                  unit.last_lat && unit.last_lng && unit.state !== RETIRED;
                const isMissingOrLostWithoutLocation =
                  (unit.state === MISSING || unit.state === LOST) &&
                  unit.current_station === null;
                return (
                  system.new_hardware &&
                  hasValidLocation &&
                  !isRetired &&
                  !isMissingOrLostWithoutLocation
                );
              })}
              hardware_type={mode.details.hardware_type}
              systemAccess={systemAccess}
            />
          )}
        </Stack>
        <Stack>
          <Paper sx={{ borderRadius: '20px' }}>
            <Stack pl={2} pt={2}>
              <Controls
                timePeriod={timePeriod}
                yearArray={yearArray}
                currentMonth={currentMonth}
                //@ts-ignore
                updateTimePeriod={updatePeriod}
              />
            </Stack>
            {isLoadingGraphStats ? (
              <PageLoader />
            ) : (
              <Stack>
                <Stack>
                  {timePeriod === 'year' && graphStats && (
                    <YearGraphs
                      rentalTotal={graphStats?.durations?.total_rentals}
                      rentalAvg={rentalAvg}
                      rentalData={rentalData as IChartData}
                      userTotal={userTotal}
                      userAvg={userAvg}
                      userData={userData as IChartData}
                      durationTotal={graphStats?.durations?.total_duration || 0}
                      durationAvg={durationAvg}
                      durationData={durationData as IChartData}
                      systemTimezoneLabel={systemTimezoneLabel}
                    />
                  )}
                  {timePeriod === 'month' && graphStats && (
                    <MonthGraphs
                      rentalTotal={graphStats?.durations?.total_rentals}
                      rentalAvg={rentalAvg}
                      rentalData={rentalData as IChartData}
                      userTotal={userTotal}
                      userAvg={userAvg}
                      userData={userData as IChartData}
                      durationTotal={graphStats?.durations?.total_duration || 0}
                      durationAvg={durationAvg}
                      durationData={durationData as IChartData}
                      systemTimezoneLabel={systemTimezoneLabel}
                    />
                  )}
                </Stack>
              </Stack>
            )}
          </Paper>
        </Stack>
      </Stack>
      {directusBannerData?.videoLink && (
        <MovaticCustomModal
          sx={{
            '& .MuiDialog-paper': {
              maxWidth: '65vw',
              width: '100%',
            },
          }}
          customStyles={{
            content: {
              paddingBottom: '24px',
            },
          }}
          open={showWelcomeBanner}
          onClose={() => {
            setShowWelcomeBanner(false);
            setCookie('banner_introduction' as keyof typeof cookies, 'true', {
              path: '/',
            });
          }}
          content={
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
                height: '100%',
              }}
            >
              {directusBannerData.bodyText && (
                <Typography variant={'body1'} pb={2}>
                  {directusBannerData.bodyText}
                </Typography>
              )}
              <iframe
                style={{
                  minHeight: '65vh',
                }}
                src={directusBannerData.videoLink}
                allowFullScreen
                title="Embedded Video"
              />
            </div>
          }
          action={undefined}
          title={directusBannerData?.title}
        />
      )}

      {hasRequiredStripeInformation(StripeRequirementType.now) &&
        !cookieExist(StripeRequirementType.now) &&
        canShowBanner &&
        !showWelcomeBanner && (
          <StripeBanner
            stripeAccountInfo={stringAccountInfo as IUseStripeAccountInfo}
            keySend={StripeRequirementType.now}
            system={system}
          />
        )}
      {/*{hasRequiredStripeInformation(StripeRequirementType.future) &&*/}
      {/*!cookieExist(StripeRequirementType.future) &&*/}
      {/*canShowBanner && (*/}
      {/*  <StripeBanner*/}
      {/*    stripeAccountInfo={stringAccountInfo as IUseStripeAccountInfo}*/}
      {/*    keySend={StripeRequirementType.future}*/}
      {/*    system={system}*/}
      {/*  />*/}
      {/*)}*/}
    </PageContainer>
  );
};

export default connect(
  (state: {
    system: { current: ISystem; isLoaded: boolean };
    admin: { systemAccess: number };
    mode: IMode;
  }) => ({
    mode: state.mode,
    system: state.system.current,
    systemLoaded: state.system.isLoaded,
    systemAccess: state.admin.systemAccess,
  }),
  () => ({})
)(Welcome);
