import React, { useCallback, useState } from 'react';
import useTransactions from '../../hooks/transaction/query/useTransactions';
import { useParams } from 'react-router-dom';
import useUser from '../../hooks/users/query/useUser';
import { getLocaleDateTimeString, history, toTitleCase } from '../../utils';
import { UserInterface } from '../../ts/interface/user.interface';
import { Card } from '@mui/material';
import { ITransaction } from '../../ts/interface/rental.interface';
import { PaginationTable } from '../../components/Redesing/table/pagination-table';
import {
  Filters,
  ISortConfig,
} from '../../ts/interface/components/redesing/table.interface';
import { SeverityPill } from '../../components/Redesing/severity-pill';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants';
import useUrlState from '../../hooks/use-url-state';

const UserTransactions = () => {
  const { userPhone = '' } = useParams<{ userPhone: string }>();
  const [user, setUser] = useState<UserInterface>({} as UserInterface);
  const [transactions, setTransactions] = useState<any>([]);
  const [dateFilters, setDateFilters] = useState<Filters>({} as Filters);
  const [exportData, setExportData] = useState<boolean>(false);

  const [pageState, setPageState] = useUrlState({
    sizePerPage: DEFAULT_ROWS_PER_PAGE,
    pageNumber: 0,
    search: '',
    sortData: {
      key: 'created',
      direction: 'desc',
    } as ISortConfig,
  });

  const { isLoading: isLoadingUser, isFetching } = useUser(userPhone, {
    onSuccess: (data: any) => {
      setUser(data);
    },
  });

  const { data: baseTransaction, isLoading: isLoadingTransactions } = useTransactions(
    {
      payload: {
        user_id: user?.userDetails?.id,
      },
    },
    {
      enabled: !!user?.userDetails?.id && !isLoadingUser,
      onSuccess: (data: any) => {
        setTransactions(data);
      },
    }
  );

  const handleFiltersChange = useCallback(
    (filters: Filters): void => {
      const endDate = filters?.endDate || null;
      const startDate = filters?.startDate || null;
      const filteredTransactions = baseTransaction.filter(
        (transaction: { created: string }) => {
          const transactionDate = new Date(transaction.created).toISOString();
          const parsedTransactionDate = Date.parse(transaction.created);

          if (startDate && endDate) {
            return (
              !isNaN(parsedTransactionDate) &&
              new Date(startDate).toISOString() <= transactionDate &&
              transactionDate <= new Date(endDate).toISOString()
            );
          } else if (startDate) {
            return (
              !isNaN(parsedTransactionDate) &&
              new Date(startDate).toISOString() <= transactionDate
            );
          } else if (endDate) {
            return (
              !isNaN(parsedTransactionDate) &&
              transactionDate <= new Date(endDate).toISOString()
            );
          } else {
            return !isNaN(parsedTransactionDate);
          }
        }
      );
      setDateFilters(filters);
      setTransactions(filteredTransactions);
    },
    [baseTransaction]
  );

  const userColumns = [
    // transactionActionColumns,
    {
      id: 'created',
      label: 'Created',
      format: (row: ITransaction) => getLocaleDateTimeString(row.created),
    },
    {
      id: 'description',
      label: 'Description',
      searchable: true,
    },
    {
      id: 'type',
      label: 'Type',
      format: (cell: ITransaction) => toTitleCase(cell.type),
      searchable: true,
    },
    {
      id: 'amount',
      label: 'Amount',
      searchable: true,
    },
    {
      id: 'status',
      label: 'Status',
      format: (row: ITransaction) =>
        row.status == null || row.status === '' ? (
          <SeverityPill color={'warning'}>Transfer</SeverityPill>
        ) : (
          <SeverityPill color={row.status === 'failed' ? 'error' : 'success'}>
            {toTitleCase(row.status)}
          </SeverityPill>
        ),
      searchable: true,
    },
  ];

  return (
    <div>
      <Card>
        <PaginationTable
          dataId="transactions"
          showFilter={false}
          isLoading={isLoadingUser || isLoadingTransactions || isFetching}
          buttons={[
            {
              label: 'Export',
              onClick: () => setExportData(true),
              startIcon: <FileDownloadIcon />,
            },
          ]}
          filters={dateFilters}
          onRowCLick={(id: string) => history.push(`/billing/transactions/detail/${id}`)}
          columns={userColumns}
          items={transactions}
          onSortChange={(sort) => {
            setPageState((prev) => ({
              ...prev,
              pageNumber: 0,
              sizePerPage: DEFAULT_ROWS_PER_PAGE,
              sortData: {
                key: sort.key,
                direction: sort.direction as 'asc' | 'desc',
              },
            }));
          }}
          sortBy={pageState.sortData.key}
          onPageChange={(page, newPage) => {
            setPageState((prev) => ({
              ...prev,
              pageNumber: newPage,
            }));
          }}
          onRowsPerPageChange={(pageSizeString) =>
            setPageState((prev) => ({
              ...prev,
              sizePerPage: Number(pageSizeString.target.value),
            }))
          }
          page={pageState.pageNumber}
          rowsPerPage={pageState.sizePerPage}
          padding={{ pt: 1, pl: 0, pr: 1 }}
          searchText={pageState.search}
          handleSearch={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPageState((prev) => ({
              ...prev,
              search: e.target.value,
            }))
          }
          searchPlaceholder={'Search Transactions'}
          exportData={exportData}
          onExport={() => setExportData(false)}
          csvFileName={'transactions.csv'}
          onDateFilterChange={handleFiltersChange}
        />
      </Card>
    </div>
  );
};

export default UserTransactions;
