import React, { useState } from 'react';
import Button from '../../components/Button';
import 'react-phone-number-input/style.css';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import ReCaptchaWrapper from '../../components/recaptcha/ReCaptcha';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { VALID_EMAIL_REGEX } from '../../util/regExp';
import MovaticCustomModal from '../../components/Modal/MovaticCustomModal';
import { MOVATIC_DEFAULT_APP_LINK } from '../../constants';
import useQueryString from '../../hooks/queryParams/useQueryString';
import { User } from '../../ts/interface/user.interface';
import CardView from '../../components/Redesing/card-view';
import { Stack } from '@mui/system';
import {
  MovaticFieldGroupTelephone,
  MovaticFieldGroupText,
} from '../../components/Redesing/movatic-field-group';
import BigButton from '../../components/Redesing/big-button';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';
import { addPublicUser } from '../../api/users';

interface GlobalState {
  userPhoneNumber: string;
  userEmail: string;
  userEmailHelp: string;
  userFirstName: string;
  userLastName: string;
  userFirstNameHelp: string;
  userLastNameHelp: string;
  phoneNumberHelp: string;
  openModal: boolean;
  appName: string;
}

const AddPublicUserContent = () => {
  const query = useQueryString();
  const system = query.queryParams.get('system');
  const { executeRecaptcha } = useGoogleReCaptcha();

  const initialState = {
    userPhoneNumber: '',
    userEmail: '',
    userEmailHelp: '',
    userFirstName: '',
    userLastName: '',
    userFirstNameHelp: '',
    userLastNameHelp: '',
    phoneNumberHelp: '',
    openModal: false,
    appName: '',
  };

  const [
    {
      userPhoneNumber,
      userEmail,
      userEmailHelp,
      userFirstName,
      userLastName,
      userFirstNameHelp,
      userLastNameHelp,
      phoneNumberHelp,
      openModal,
      appName,
    },
    setState,
  ] = useState<GlobalState>(initialState as GlobalState);

  const handleChange = (event: { target: { value: string; name: string } }) => {
    const { value, name } = event.target;
    const helpState = name.concat('Help');
    setState((prevState) => ({
      ...prevState,
      [name]: value,
      [helpState]: '',
    }));
  };

  const phoneNumberChange = (userPhoneNumber: string) => {
    setState((prevState) => ({
      ...prevState,
      userPhoneNumber,
      phoneNumberHelp: '',
    }));
  };

  const handleSubmit = () => {
    let canSubmit = true;
    if (!userFirstName) {
      setState((prevState) => ({
        ...prevState,
        userFirstNameHelp: 'First name is required',
      }));
      canSubmit = false;
    }

    if (!userLastName) {
      setState((prevState) => ({
        ...prevState,
        userLastNameHelp: 'Last name is required',
      }));
      canSubmit = false;
    }

    if (!isPossiblePhoneNumber(userPhoneNumber)) {
      setState((prevState) => ({
        ...prevState,
        phoneNumberHelp: 'Please enter a valid phone number',
      }));
      canSubmit = false;
    }

    if ((userEmail && !VALID_EMAIL_REGEX.test(userEmail)) || !userEmail) {
      setState((prevState) => ({
        ...prevState,
        userEmailHelp: 'Please enter a valid email address',
      }));
      canSubmit = false;
    }

    if (canSubmit) {
      if (executeRecaptcha) {
        executeRecaptcha('createUser').then((token) => {
          let publicUserObj = {
            email: userEmail.toLowerCase().trim(),
            firstName: userFirstName,
            lastName: userLastName,
            phone: userPhoneNumber,
            recaptchaToken: token,
            systemId: system,
          };
          CustomAsyncToast({
            promise: () =>
              addPublicUser(publicUserObj).then((r: User) => {
                const appBaseName = r?.appName || 'movatic';
                setState((prevState) => ({
                  ...prevState,
                  openModal: true,
                  appName: appBaseName,
                }));
              }),
            loadingMessage: 'Adding user...',
            successMessage: () => 'User added successfully.',
            errorMessage: 'Error adding user.',
          });
        });
      }
    }
  };

  return (
    <div className="add_system_card">
      <CardView
        content={
          <Stack px={2} pb={2} sx={{ minWidth: '50vw' }}>
            <MovaticFieldGroupText
              id="userFirstName"
              name={'userFirstName'}
              label="First Name"
              description="Required"
              hasError={!!userFirstNameHelp}
              errorMessage={userFirstNameHelp}
              value={userFirstName}
              onChange={handleChange}
            />

            <MovaticFieldGroupText
              id="userLastName"
              name={'userLastName'}
              label="Last Name"
              description="Required"
              hasError={!!userLastNameHelp}
              errorMessage={userLastNameHelp}
              value={userLastName}
              onChange={handleChange}
            />

            <MovaticFieldGroupTelephone
              id="phoneNumber"
              label="Phone Number"
              errorMessage={phoneNumberHelp}
              hasError={!!phoneNumberHelp}
              description="Required."
              name="phoneNumber"
              onChange={phoneNumberChange}
              value={userPhoneNumber}
            />

            <MovaticFieldGroupText
              id="userEmail"
              name={'userEmail'}
              label="Email"
              description="Required"
              hasError={!!userEmailHelp}
              errorMessage={userEmailHelp}
              value={userEmail}
              onChange={handleChange}
            />

            <BigButton description="Create user" onClick={handleSubmit} />

            {openModal && (
              <MovaticCustomModal
                useContentDividers
                customStyles={{
                  action: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                }}
                open={true}
                onClose={() => setState(initialState)}
                title="Success!"
                content={
                  <div>
                    Your user account has been created successfully. Tap the button below
                    to launch or download the app.
                  </div>
                }
                action={
                  <a
                    rel="noreferrer"
                    href={MOVATIC_DEFAULT_APP_LINK.concat(appName)}
                    target="_blank"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Button buttons={1} primaryText="Launch" />
                  </a>
                }
              />
            )}
          </Stack>
        }
        title="Create your user"
      />
    </div>
  );
};

const AddPublicUser = () => {
  return (
    <ReCaptchaWrapper>
      <AddPublicUserContent />
    </ReCaptchaWrapper>
  );
};

export default AddPublicUser;
