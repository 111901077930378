import { useQuery } from '@tanstack/react-query';
import { getCharges } from '../../../api/transactions';

const useCharges = (options?: {}) => {
  return useQuery(['charges'], getCharges, {
    ...options,
  });
};

export default useCharges;
