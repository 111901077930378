import React from 'react';
import { Alert, Box, Typography, useTheme } from '@mui/material';

interface WarningProps {
  message: string;
}

const Warning: React.FC<WarningProps> = ({ message }) => {
  const theme = useTheme();

  return (
    <Alert
      sx={{
        backgroundColor: theme.palette.mode === 'light' ? '#ffcccc' : '#1C2331',
        color: theme.palette.text.primary,
      }}
      severity="error"
    >
      <Box display="flex" alignItems="center">
        <Typography variant="h6" sx={{ marginRight: '16px' }}>
          Warning!
        </Typography>
        <Typography variant="body1" id={`warning-message`}>
          {message}
        </Typography>
      </Box>
    </Alert>
  );
};

export default Warning;
