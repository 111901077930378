import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useRefunds from '../../hooks/transaction/query/useRefunds';
import { IRefunds, ITransaction } from '../../ts/interface/rental.interface';
import useCharges from '../../hooks/transaction/query/useCharges';
import {
  convertCentsToMoney,
  formatDateISOTime,
  formatNumberWithCommas,
  removeTimezone,
} from '../../utils';
import { ICharge } from '../../ts/interface/transaction.interface';
import Controls from '../dashboard/assets/controls';
import { monthArray } from '../../constants';
import { ISystem } from '../../ts/interface/system.interface';
import { connect } from 'react-redux';
import DashboardChart from '../dashboard/assets/dashboard-chart';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import QuickStats from '../../components/Redesing/quick-stats';
import { Paper } from '@mui/material';
import CardLoader from '../../components/Redesing/card-loader';
import BillingDashboardChart from './assets/billing-dashboard-chart';
const timeZones = require('../../../data/timezones.json');

interface IDashboardState {
  yearTickArray: number[];
  yearArray: number[];
  currentMonth: number;
  timePeriod: string;
  currentYear: number;
  monthcharges: string[];
  monthwalletdebits: string[];
  yearcharges: string[];
  yearwalletdebits: string[];
  monthrefunds: string[];
  yearrefunds: string[];
  monthchargestotal: number;
  monthwalletdebitstotal: number;
  yearchargestotal: number;
  yearwalletdebitstotal: number;
  monthrefundstotal: number;
  yearrefundstotal: number;
  monthchargesaverage: number;
  monthwalletdebitsaverage: number;
  yearchargesaverage: number;
  yearwalletdebitsaverage: number;
  monthrefundsaverage: number;
  yearrefundsaverage: number;
  chargeMonthData: { x: string; y: number; label: number }[];
  totalRentals: number;
  rentalAverage: string;
  totalYearRentals: number;
  rentalYearAverage: string;
  chargeYearData: { x: string; y: number; label: number }[];
  averageCharge: string;
  averageRefund: string;
  chargeCount: number;
  refundCount: number;
  monthTickValues: number[];
}

const Dashboard = ({
  system,
  systemLoaded,
  transactions,
  isLoading,
}: {
  system: ISystem;
  systemLoaded: boolean;
  transactions: ITransaction[];
  isLoading: boolean;
}) => {
  const initialState = {
    yearTickArray: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    yearArray: [],
    currentMonth: new Date().getUTCMonth(),
    timePeriod: 'month',
    currentYear: 0,
    monthcharges: [],
    monthwalletdebits: [],
    yearwalletdebits: [],
    yearcharges: [],
    monthrefunds: [],
    yearrefunds: [],
    monthchargestotal: 0,
    monthwalletdebitstotal: 0,
    yearwalletdebitstotal: 0,
    yearchargestotal: 0,
    monthrefundstotal: 0,
    yearrefundstotal: 0,
    monthchargesaverage: 0,
    monthwalletdebitsaverage: 0,
    yearwalletdebitsaverage: 0,
    yearchargesaverage: 0,
    monthrefundsaverage: 0,
    yearrefundsaverage: 0,
    chargeMonthData: [],
    totalRentals: 0,
    rentalAverage: '',
    totalYearRentals: 0,
    rentalYearAverage: '',
    chargeYearData: [],
    averageCharge: '',
    averageRefund: '',
    chargeCount: 0,
    refundCount: 0,
    monthTickValues: [],
  };
  const [
    {
      yearArray,
      currentMonth,
      timePeriod,
      currentYear,
      monthcharges,
      monthwalletdebits,
      yearwalletdebits,
      yearcharges,
      monthrefunds,
      yearrefunds,
      monthchargestotal,
      monthwalletdebitstotal,
      yearwalletdebitstotal,
      yearchargestotal,
      monthrefundstotal,
      yearrefundstotal,
      monthchargesaverage,
      monthwalletdebitsaverage,
      yearwalletdebitsaverage,
      yearchargesaverage,
      monthrefundsaverage,
      yearrefundsaverage,
      chargeMonthData,
      totalRentals,
      rentalAverage,
      totalYearRentals,
      rentalYearAverage,
      chargeYearData,
      averageCharge,
      averageRefund,
      chargeCount,
      refundCount,
      monthTickValues,
    },
    setState,
  ] = useState<IDashboardState>(initialState);

  const { data: refunds = [] } = useRefunds({
    enabled: systemLoaded,
  });
  const { data: chargesLoaded = [], isFetched: isChargesFetched } = useCharges({
    enabled: systemLoaded,
  });

  const [charges, walletDebits] = useMemo(() => {
    const charges = chargesLoaded.filter((item: ICharge) => item.type !== 'Wallet Debit');
    const walletDebits = chargesLoaded.filter(
      (item: ICharge) => item.type === 'Wallet Debit'
    );
    return [charges, walletDebits];
  }, [chargesLoaded]);

  const getResume = useCallback(() => {
    let charge = 0;
    let refund = 0;
    let chargeCount = 0;
    let refundCount = 0;
    transactions.forEach((trans: ITransaction) => {
      if (trans.type === 'Charge' || trans.type === 'Plan Charge') {
        charge += trans.cents / 100;
        chargeCount += 1;
      } else if (trans.type === 'Refund') {
        refund += trans.cents / 100;
        refundCount += 1;
      }
    });
    return {
      averageCharge: charge && chargeCount ? (charge / chargeCount).toFixed(2) : '0',
      averageRefund: refund && refundCount ? (refund / refundCount).toFixed(2) : '0',
      chargeCount,
      refundCount,
    };
  }, [transactions]);

  useEffect(() => {
    const { averageCharge, averageRefund, chargeCount, refundCount } = getResume();
    setState((prevState) => ({
      ...prevState,
      averageCharge,
      averageRefund,
      chargeCount,
      refundCount,
    }));
  }, [getResume, transactions]);

  const createMonthData = useCallback(
    (thisYear: number, arrayToSort: ICharge[] | IRefunds[], chargeOrRefund: string) => {
      let dataArray = [] as { x: number; y: number; label: number }[];
      let monthTotal = 0;
      let monthAverage = 0;
      const month = currentMonth;
      const isRefund = chargeOrRefund === 'refunds';
      /*
      x = the day, y and label = the total rental charges,
      dataArray = [
        {x: 1, y: 8.25, label: 8.25},
        {x: 2, y: 2.25, label: 2.25}
      ]
      */
      arrayToSort.forEach((element) => {
        const date = new Date(removeTimezone(element.created));
        const transMonth = date.getUTCMonth();
        const year = date.getUTCFullYear();

        if (transMonth === month && year === thisYear && element.status === 'succeeded') {
          const charge = isRefund
            ? element.amount
            : Number(element.charge_total ?? element.amount);

          monthTotal += charge;
          const day = date.getUTCDate();
          if (dataArray[day - 1]) {
            // the date is already in the dataArray, and so we need to increment the values
            dataArray[day - 1].y += charge;
            dataArray[day - 1].label += charge;
          } else {
            // the date is not in the dataArray, and so we need to add
            dataArray[day - 1] = {
              x: day,
              y: charge,
              label: charge,
            };
          }
        }
      });

      const daysAmount = daysInMonth(month, thisYear);
      const tickValues = [] as number[];
      for (let day = 0; day < daysAmount; day++) {
        tickValues.push(day + 1);
        if (!dataArray[day]) {
          dataArray[day] = { x: day + 1, y: 0, label: 0 };
        }
      }

      const emptyCheck = dataArray.every((el) => el.y === 0);
      if (emptyCheck) {
        dataArray = [];
      }

      monthAverage = monthTotal / daysAmount;
      if (
        currentMonth === new Date().getUTCMonth() &&
        yearArray[currentYear] === new Date().getUTCFullYear()
      ) {
        monthAverage = monthTotal / new Date().getUTCDate();
      }
      setState((prevState) => ({
        ...prevState,
        [`month${chargeOrRefund}`]: dataArray.map((item) => ({
          ...item,
          y: parseFloat((item.y / 100).toFixed(2)),
          label: parseFloat((item.label / 100).toFixed(0)),
        })),
        monthTickValues: tickValues,
        [`month${chargeOrRefund}total`]: monthTotal,
        [`month${chargeOrRefund}average`]: monthAverage,
      }));
    },
    [currentMonth, currentYear, yearArray]
  );

  const createYearData = useCallback(
    (year: number, arrayToSort: ICharge[], chargeOrRefund: string) => {
      let dataArray = [] as { x: number; y: number; label: number }[];
      let yearTotal = 0;
      let yearAverage = 0;
      const isRefund = chargeOrRefund === 'refunds';
      /*
      x = the day, y and label = the total rental charges,
      dataArray = [
        {x: 1, y: 8.25, label: 8.25}, x === the month, y === the total amount that month
        {x: 2, y: 2.25, label: 2.25}
      ]
      */
      arrayToSort.forEach((element) => {
        const date = new Date(removeTimezone(element.created));
        const transMonth = date.getUTCMonth();
        const transYear = date.getUTCFullYear();
        if (transYear === year && element.status === 'succeeded') {
          const charge = isRefund
            ? element.amount
            : Number(element.charge_total ?? element.amount);
          yearTotal += charge;
          if (dataArray[transMonth]) {
            // the date is already in the dataArray, and so we need to increment the values
            dataArray[transMonth].y += charge;
            dataArray[transMonth].label += charge;
          } else {
            // the date is not in the dataArray, and so we need to add
            dataArray[transMonth] = {
              x: transMonth,
              y: charge,
              label: charge,
            };
          }
        }
      });

      for (let month = 0; month < 12; month++) {
        if (!dataArray[month]) {
          dataArray[month] = { x: month, y: 0, label: 0 };
        }
      }
      const emptyCheck = dataArray.every((el) => el.y === 0);
      if (emptyCheck) {
        dataArray = [];
      }
      yearAverage = yearTotal / 12;
      if (yearArray[currentYear] === new Date().getUTCFullYear()) {
        yearAverage = yearTotal / (new Date().getUTCMonth() + 1);
      }
      setState((prevState) => ({
        ...prevState,
        [`year${chargeOrRefund}`]: dataArray.map((item) => ({
          ...item,
          y: parseFloat((item.y / 100).toFixed(2)),
          label: parseFloat((item.label / 100).toFixed(0)),
        })),
        [`year${chargeOrRefund}total`]: yearTotal,
        [`year${chargeOrRefund}average`]: yearAverage,
      }));
    },
    [currentYear, yearArray]
  );

  const genChargeGraphInfoMonth = useCallback(
    (charges: ICharge[]) => {
      const month = currentMonth;
      let chargeTotal = 0;
      let totalRentals = 0;
      let chargeInfo = [
        { x: 'Under 5.00', y: 0, label: 0 },
        { x: '5 to 10', y: 0, label: 0 },
        { x: '10 to 15', y: 0, label: 0 },
        { x: '15 to 20', y: 0, label: 0 },
        { x: '20 to 25', y: 0, label: 0 },
        { x: '25 to 35', y: 0, label: 0 },
        { x: '35 to 45', y: 0, label: 0 },
        { x: '45 to 60', y: 0, label: 0 },
        { x: '60 to 75', y: 0, label: 0 },
        { x: '75 to 100', y: 0, label: 0 },
        { x: '100.00 and up', y: 0, label: 0 },
      ];

      charges.forEach((charge) => {
        const date = new Date(formatDateISOTime(charge.created));
        const transMonth = date.getUTCMonth();
        const year = date.getUTCFullYear();
        if (
          transMonth === month &&
          year === yearArray[currentYear] &&
          charge.status === 'succeeded'
        ) {
          const index = findChargeIndex(charge);
          chargeTotal += Number(charge.charge_total ?? charge.amount);
          totalRentals += 1;
          if (index === null || index === undefined) {
          } else {
            chargeInfo[index].y += 1;
            chargeInfo[index].label = chargeInfo[index].y;
          }
        }
      });

      // checking if the month has no data
      const emptyCheck = chargeInfo.every((el) => el.y === 0);
      if (emptyCheck) {
        chargeInfo = [];
      }
      setState((prevState) => ({
        ...prevState,
        chargeMonthData: chargeInfo,
        totalRentals,
        rentalAverage: (chargeTotal / totalRentals).toFixed(),
      }));
    },
    [currentMonth, currentYear, yearArray]
  );

  const setupGraphs = useCallback(
    (rawCharges: ICharge[], rawRefunds: IRefunds[], rawWalletDebits: ICharge[]) => {
      createMonthData(yearArray[currentYear], rawCharges, 'charges');
      createMonthData(yearArray[currentYear], rawRefunds, 'refunds');
      createMonthData(yearArray[currentYear], walletDebits, 'walletdebits');
      genChargeGraphInfoMonth(rawCharges);
    },
    [createMonthData, genChargeGraphInfoMonth, yearArray, currentYear, walletDebits]
  );

  useEffect(() => {
    if (isChargesFetched) {
      let yearArrayX: number[] = [];
      charges.forEach((element: ICharge) => {
        const date = new Date(removeTimezone(element.created));
        const year = date.getUTCFullYear();
        if (!yearArrayX.includes(year)) {
          yearArrayX.push(year);
        }
      });
      setState((prevState) => ({
        ...prevState,
        yearArray: yearArrayX.sort((a, b) => b - a),
      }));
    }
  }, [charges, isChargesFetched]);

  useEffect(() => {
    setupGraphs(charges, refunds, walletDebits);
  }, [currentMonth, currentYear, yearArray, charges, refunds, setupGraphs, walletDebits]);

  function findChargeIndex(charge: ICharge) {
    const charge_total = charge.charge_total ?? charge.amount;
    switch (true) {
      case !charge_total:
        return null;
      case charge_total < 500: // less than $5
        return 0;
      case charge_total < 1000: // < $10
        return 1;
      case charge_total < 1500: // < $15
        return 2;
      case charge_total < 2000: // < $20
        return 3;
      case charge_total < 2500: // < $25
        return 4;
      case charge_total < 3500: // < $35
        return 5;
      case charge_total < 4500: // < $45
        return 6;
      case charge_total < 6000: // < $60
        return 7;
      case charge_total < 7500: // < $75
        return 8;
      case charge_total < 10000: // < $100
        return 9;
      case charge_total >= 10000: // >= $100
        return 10;
      default:
        return null;
    }
  }

  const daysInMonth = (month: number, year: number) => {
    return new Date(year, month + 1, 0).getUTCDate();
  };

  const genChargeGraphInfoYear = (year: number, charges: ICharge[]) => {
    let chargeTotal = 0;
    let totalRentals = 0;
    let chargeInfo = [
      { x: 'Under 5.00', y: 0, label: 0 },
      { x: '5 to 10', y: 0, label: 0 },
      { x: '10 to 15', y: 0, label: 0 },
      { x: '15 to 20', y: 0, label: 0 },
      { x: '20 to 25', y: 0, label: 0 },
      { x: '25 to 35', y: 0, label: 0 },
      { x: '35 to 45', y: 0, label: 0 },
      { x: '45 to 60', y: 0, label: 0 },
      { x: '60 to 75', y: 0, label: 0 },
      { x: '75 to 100', y: 0, label: 0 },
      { x: '100.00 and up', y: 0, label: 0 },
    ];

    charges.forEach((charge) => {
      const date = new Date(formatDateISOTime(charge.created));
      const transYear = date.getUTCFullYear();
      if (transYear === year && charge.status === 'succeeded') {
        const index = findChargeIndex(charge);
        chargeTotal += Number(charge.charge_total ?? charge.amount);
        totalRentals += 1;
        if (index === null || index === undefined) {
          console.log('nothing here');
        } else {
          chargeInfo[index].y += 1;
          chargeInfo[index].label = chargeInfo[index].y;
        }
      }
    });

    // checking if the month has no data
    const emptyCheck = chargeInfo.every((el) => el.y === 0);
    if (emptyCheck) {
      chargeInfo = [];
    }
    setState((prevState) => ({
      ...prevState,
      chargeYearData: chargeInfo,
      totalYearRentals: totalRentals,
      rentalYearAverage: (chargeTotal / totalRentals).toFixed(),
    }));
  };

  const updatePeriod = (
    key: string,
    period: string | number,
    updateSte: boolean,
    obj: any
  ) => {
    if (updateSte) {
      setState((prevState) => ({
        ...prevState,
        [key]: period,
        currentYear: obj.currentYear,
      }));
    }
    updateGraphs(obj);
  };

  const updateGraphs = (obj: any) => {
    const { key, timePeriod: period } = obj;
    if (key === 'currentMonth') {
      createMonthData(yearArray[currentYear], charges, 'charges');
      createMonthData(yearArray[currentYear], refunds, 'refunds');
      createMonthData(yearArray[currentYear], walletDebits, 'walletdebits');
      genChargeGraphInfoMonth(charges);
    } else if (key === 'currentYear' || (key === 'timePeriod' && period === 'year')) {
      createMonthData(yearArray[currentYear], charges, 'charges');
      createMonthData(yearArray[currentYear], refunds, 'refunds');
      createMonthData(yearArray[currentYear], walletDebits, 'walletdebits');
      genChargeGraphInfoMonth(charges);
      if (typeof period === 'string') {
        createYearData(
          yearArray[period === 'year' ? currentYear : parseInt(period)],
          charges,
          'charges'
        );
        createYearData(
          yearArray[period === 'year' ? currentYear : parseInt(period)],
          refunds,
          'refunds'
        );
        createYearData(
          yearArray[period === 'year' ? currentYear : parseInt(period)],
          walletDebits,
          'walletdebits'
        );
        genChargeGraphInfoYear(
          yearArray[period === 'year' ? currentYear : parseInt(period)],
          charges
        );
      }
    }
  };

  const content = () => {
    const { timezone } = system;
    const systemTimezoneLabel = timeZones.find((zone: { utc: string[] }) =>
      zone.utc.find((name: string) => name === timezone)
    )?.text;

    return (
      <Stack spacing={2}>
        <QuickStats
          sections={[
            {
              title: 'Average Charge',
              value: averageCharge,
            },
            {
              title: 'Total Charges',
              value: formatNumberWithCommas(chargeCount),
            },
            {
              title: 'Average Refund',
              value: formatNumberWithCommas(averageRefund),
            },
            {
              title: 'Total Refund',
              value: formatNumberWithCommas(refundCount),
            },
          ]}
          isLoading={isLoading}
        />
        <Paper sx={{ borderRadius: '20px' }}>
          {isLoading ? (
            <CardLoader useCard />
          ) : (
            <>
              <Stack pl={2} pt={2}>
                <Controls
                  timePeriod={timePeriod}
                  yearArray={yearArray}
                  currentMonth={currentMonth}
                  updateTimePeriod={updatePeriod}
                  fromBilling
                />
              </Stack>
              {timePeriod === 'month' ? (
                <Stack>
                  <Stack pt={2} px={2}>
                    <Card>
                      <DashboardChart
                        id={'charge'}
                        data={{
                          data: monthcharges as any[],
                          labels: monthTickValues as any[],
                        }}
                        total={convertCentsToMoney(monthchargestotal)}
                        average={convertCentsToMoney(monthchargesaverage)}
                        systemTimezoneLabel={systemTimezoneLabel}
                        title={'Card Charges by day'}
                        statTittleOne={'Average'}
                        statTittleTwo={'Total'}
                      />
                    </Card>
                  </Stack>
                  {walletDebits.length > 0 && (
                    <Stack pt={2} px={2}>
                      <Card>
                        <DashboardChart
                          id={'wallet_debits'}
                          data={{
                            data: monthwalletdebits as any[],
                            labels: monthTickValues as any[],
                          }}
                          total={convertCentsToMoney(monthwalletdebitstotal)}
                          average={convertCentsToMoney(monthwalletdebitsaverage)}
                          systemTimezoneLabel={systemTimezoneLabel}
                          title={'Wallet Charges by day'}
                          statTittleOne={'Average'}
                          statTittleTwo={'Total'}
                        />
                      </Card>
                    </Stack>
                  )}
                  <Stack pt={2} px={2} pb={2}>
                    <Card>
                      <DashboardChart
                        id={'refund_by_day'}
                        data={{
                          data: monthrefunds as any[],
                          labels: monthTickValues as any[],
                        }}
                        total={convertCentsToMoney(monthrefundstotal)}
                        average={convertCentsToMoney(monthrefundsaverage)}
                        systemTimezoneLabel={systemTimezoneLabel}
                        title={'Refunds by Day'}
                        statTittleOne={'Average'}
                        statTittleTwo={'Total'}
                      />
                    </Card>
                  </Stack>
                  <Stack pt={2} px={2}>
                    <Card>
                      <DashboardChart
                        id={'rentals_by_charge'}
                        data={{
                          data: chargeMonthData as any[],
                          labels: [
                            'Under $5.00',
                            '$5 to $10',
                            '$10 to $15',
                            '$15 to $20',
                            '$20 to $25',
                            '$25 to $35',
                            '$35 to $45',
                            '$45 to $60',
                            '$60 to $75',
                            '$75 to $100',
                            '$100 and up',
                          ] as string[],
                        }}
                        total={formatNumberWithCommas(totalRentals)}
                        average={convertCentsToMoney(rentalAverage)}
                        systemTimezoneLabel={systemTimezoneLabel}
                        title={'Rentals by Charge'}
                        statTittleOne={'Average Charge'}
                        statTittleTwo={'Total Rentals'}
                      />
                    </Card>
                  </Stack>
                </Stack>
              ) : (
                // YEARLY GRAPHS
                <Stack>
                  <Stack pt={2} px={2}>
                    <Card>
                      <BillingDashboardChart
                        id={'charges_by_month'}
                        data={{
                          //@ts-ignore
                          data: yearcharges as any[],
                          labels: monthArray as string[],
                        }}
                        total={convertCentsToMoney(yearchargestotal)}
                        average={convertCentsToMoney(yearchargesaverage)}
                        systemTimezoneLabel={systemTimezoneLabel}
                        title={'Card Charges by Month'}
                        statTittleOne={'Average'}
                        statTittleTwo={'Total'}
                      />
                    </Card>
                  </Stack>
                  {walletDebits.length > 0 && (
                    <Stack pt={2} px={2}>
                      <Card>
                        <BillingDashboardChart
                          id={'wallet_debits_by_month'}
                          data={{
                            //@ts-ignore
                            data: yearwalletdebits as any[],
                            labels: monthArray as string[],
                          }}
                          total={convertCentsToMoney(yearwalletdebitstotal)}
                          average={convertCentsToMoney(yearwalletdebitsaverage)}
                          systemTimezoneLabel={systemTimezoneLabel}
                          title={'Wallet Charges by Month'}
                          statTittleOne={'Average'}
                          statTittleTwo={'Total'}
                        />
                      </Card>
                    </Stack>
                  )}
                  <Stack pt={2} px={2} pb={2}>
                    <Card>
                      <BillingDashboardChart
                        id={'refunds_by_month'}
                        data={{
                          data: yearrefunds as any[],
                          labels: monthArray as string[],
                        }}
                        total={convertCentsToMoney(yearrefundstotal)}
                        average={convertCentsToMoney(yearrefundsaverage)}
                        systemTimezoneLabel={systemTimezoneLabel}
                        title={'Refunds by Month'}
                        statTittleOne={'Average'}
                        statTittleTwo={'Total'}
                      />
                    </Card>
                  </Stack>
                  <Stack pt={2} px={2}>
                    <Card>
                      <BillingDashboardChart
                        id={'rentals_by_charge_yearly'}
                        data={{
                          data: chargeYearData as any[],
                          labels: [
                            'Under $5.00',
                            '$5 to $10',
                            '$10 to $15',
                            '$15 to $20',
                            '$20 to $25',
                            '$25 to $35',
                            '$35 to $45',
                            '$45 to $60',
                            '$60 to $75',
                            '$75 to $100',
                            '$100 and up',
                          ] as string[],
                        }}
                        total={formatNumberWithCommas(totalYearRentals)}
                        average={convertCentsToMoney(rentalYearAverage)}
                        systemTimezoneLabel={systemTimezoneLabel}
                        title={'Rentals by Charge'}
                        statTittleOne={'Average Charge'}
                        statTittleTwo={'Total Rentals'}
                      />
                    </Card>
                  </Stack>
                </Stack>
              )}
            </>
          )}
        </Paper>
      </Stack>
    );
  };

  return <>{content()}</>;
};

export default connect(
  (state: { system: { current: ISystem; isLoaded: boolean } }) => ({
    system: state.system.current,
    systemLoaded: state.system.isLoaded,
  }),
  () => ({})
)(Dashboard);
