import React, { useEffect, useState } from 'react';
import CardView from '../../../components/Redesing/card-view';
import { getLocaleDateTimeString } from '../../../utils';
import { PaginationTable } from '../../../components/Redesing/table/pagination-table';
import { IColumn } from '../../../ts/interface/components/redesing/table.interface';
import { IRentalUnlock } from '../../../ts/interface/rental.interface';
import { PropertyList } from '../../../components/Redesing/property-list';
import { PropertyListItem } from '../../../components/Redesing/property-list-item';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Stack, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants';

interface IRentalLocksProps {
  unlocks: IRentalUnlock[];
}

const RentalLogs: React.FC<IRentalLocksProps> = ({ unlocks }) => {
  const [search, setSearch] = React.useState('');
  const [pageNumber, setPageNumber] = React.useState(0);
  const [sizePerPage, setSizePerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);
  const [sortData, setSortData] = useState<string>('created_on');
  const [currentUnlockState, setCurrentUnlockState] = useState<IRentalUnlock>(unlocks[0]);
  const [currentUnlock, setCurrentUnlock] = useState<{
    value: string;
    name: string;
  } | null>(null);
  const [inRentalUnlockOptions, setInRentalUnlockOptions] = useState<
    { value: string; name: string }[]
  >([]);

  useEffect(() => {
    if (unlocks?.length > 0) {
      const options = unlocks.map((unlock: IRentalUnlock) => {
        const successString = unlock.success ? ' SUCCESS-' : ' FAILURE-';
        const lockSeenString = unlock.lock_observed
          ? '(LOCK OBSERVED)'
          : '(LOCK NOT OBSERVED)';
        const name = unlock.id + successString + lockSeenString;
        return { value: unlock.id, name: name };
      });
      setInRentalUnlockOptions(options);
      setCurrentUnlock(options[0]);
    }
  }, [unlocks]);

  const handleUnlockLogChange = (event: any) => {
    const value = event.target.value as string;
    const currentUnlock = unlocks.find((unlock: { id: string }) => unlock.id === value);
    const successString = currentUnlock?.success ? ' SUCCESS-' : ' FAILURE-';
    const lockSeenString = currentUnlock?.lock_observed
      ? '(LOCK OBSERVED)'
      : '(LOCK NOT OBSERVED)';
    const name = currentUnlock?.id + successString + lockSeenString;
    setCurrentUnlockState(currentUnlock as IRentalUnlock);
    setCurrentUnlock({ value: currentUnlock?.id || '', name: name });
  };
  const rentalUnlockLogContent = () => {
    const unlockLog = currentUnlockState;
    if (inRentalUnlockOptions.length > 0) {
      return (
        <PropertyList>
          <PropertyListItem align={'horizontal'} label={'Unlock Log'} divider>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={currentUnlock?.value}
              label="Age"
              onChange={handleUnlockLogChange}
            >
              {inRentalUnlockOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </PropertyListItem>
          <PropertyListItem
            align={'horizontal'}
            divider
            value={getLocaleDateTimeString(unlockLog?.created_on)}
            label="Timestamp"
          />
          <PropertyListItem
            align={'horizontal'}
            divider
            value={unlockLog?.module ?? 'N/A'}
            label="Module"
          />
          <PropertyListItem
            align={'horizontal'}
            divider
            value={unlockLog?.seconds_to_scan / 1000 || 0}
            label="Seconds to Scan"
          />
          <PropertyListItem
            align={'horizontal'}
            divider
            value={unlockLog?.seconds_to_unlock / 1000 || 0}
            label="Seconds to Unlock"
          />
          <PropertyListItem
            align={'horizontal'}
            divider
            value={unlockLog?.voltage ?? 'N/A'}
            label="Battery"
          />
          <PropertyListItem
            align={'horizontal'}
            divider
            value={unlockLog?.rssi ?? 'N/A'}
            label="RSSI"
          />
          <PropertyListItem
            align={'horizontal'}
            divider
            value={unlockLog?.log_id ?? 'N/A'}
            label="Native Log Id"
          />
          <PropertyListItem align={'horizontal'} divider label="Native Log">
            {
              <TextField
                disabled
                fullWidth
                multiline
                rows={10}
                variant="outlined"
                value={unlockLog?.native_log?.join('\n')}
              />
            }
          </PropertyListItem>
        </PropertyList>
      );
    }
    return <Typography variant="body1">No unlock logs found.</Typography>;
  };

  return (
    <Stack spacing={2}>
      <CardView
        content={
          <PaginationTable
            sortBy={sortData}
            onSortChange={(sort) => setSortData(sort.key)}
            handleSearch={(search) => setSearch(search.target.value)}
            searchText={search}
            columns={
              [
                {
                  id: 'created_on',
                  label: 'Attempted On',
                  format: (lock: IRentalUnlock) =>
                    getLocaleDateTimeString(lock.created_on),
                },
                {
                  id: 'success',
                  label: 'Success',
                  format: (lock: IRentalUnlock) => {
                    return lock.success ? lock.success : ' ';
                  },
                },
              ] as IColumn[]
            }
            items={unlocks || []}
            onPageChange={(page, pageN) => {
              setPageNumber(pageN);
            }}
            onRowsPerPageChange={(row) => {
              const pageSize = Number(row.target.value);
              setSizePerPage(pageSize);
            }}
            page={pageNumber}
            rowsPerPage={sizePerPage}
            searchPlaceholder={'Search in rental locks'}
          />
        }
        title={'In Rental Unlocks'}
      />
      <CardView
        content={
          <Stack px={2} pb={2}>
            {rentalUnlockLogContent()}
          </Stack>
        }
        title={'Unlock Logs'}
      />
    </Stack>
  );
};

export default RentalLogs;
