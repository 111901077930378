import ErrorBoundary from './components/Redesing/error-boundary';
import Root from './routes';

import './css/style.scss';

export default function App() {
  return (
    <ErrorBoundary>
      <Root />
    </ErrorBoundary>
  );
}
