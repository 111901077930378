import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IAuth } from '../../ts/interface/admins.interface';
import { refundCharge, refundRentalCharge } from '../../api/transactions';
import {
  IRefundObject,
  IRental,
  ITransaction,
} from '../../ts/interface/rental.interface';
import MovaticCustomModal from '../../components/Modal/MovaticCustomModal';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import Typography from '@mui/material/Typography';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';
import {
  MovaticFieldGroupOutlinedInput,
  MovaticFieldGroupText,
} from '../../components/Redesing/movatic-field-group';

interface RefundProps {
  show: boolean;
  close: (redirect?: boolean) => void;
  refundType: string;
  transaction: ITransaction;
  rentalToRefund?: IRental;
  userName: string;
  rentalId: string;
  chargeId: string;
  walletTransactionId: string;
  buttonLoading: boolean;
  auth: IAuth;
}

const Refund: React.FC<RefundProps> = ({
  show,
  close,
  refundType,
  transaction,
  rentalToRefund,
  userName,
  rentalId,
  chargeId,
  walletTransactionId,
}) => {
  const [amount, setAmount] = useState(() => {
    let { chargeTotal: amount, cents } = transaction;

    if (refundType === 'rental') {
      amount = rentalToRefund?.billing?.total || amount;
    }
    return !isNaN(Number(Number(Math.abs(amount) / 100).toFixed(2)))
      ? Number(Math.abs(amount) / 100).toFixed(2)
      : (Math.abs(cents) / 100).toFixed(2);
  });

  const [user] = useState(userName);
  const [fullAmount] = useState(() => {
    let { chargeTotal: amount, cents } = transaction;

    if (refundType === 'rental') {
      amount = rentalToRefund?.billing?.total || amount;
    }

    return !isNaN(Number(Number(Math.abs(amount) / 100).toFixed(2)))
      ? Number(Math.abs(amount) / 100).toFixed(2)
      : (Math.abs(cents) / 100).toFixed(2);
  });

  const [comment, setComment] = useState('');
  const [commentError, setCommentError] = useState<string | null>(null);

  const handleRefund = (refundType: string, refundObj: IRefundObject) => {
    const refundFunction = refundType === 'rental' ? refundRentalCharge : refundCharge;
    CustomAsyncToast({
      promise: () => refundFunction(refundObj),
      successMessage: () => 'Your refund was issued',
      loadingMessage: 'Issuing refund...',
      errorMessage: 'There was an error issuing your refund',
    }).then((res) => {
      close(true);
    });
  };

  const submitRefund = () => {
    const amountInCents = Math.round(Number(amount) * 100);

    const refundObj: IRefundObject = {
      amount: amountInCents,
      reason: comment,
      refund: amountInCents,
      refundComment: comment,
      rentalId: rentalId,
      chargeId: chargeId,
      walletTransaction: walletTransactionId,
    };

    if (Number(amount) <= Number(fullAmount) && Number(amount) > 0 && comment) {
      if (refundType === 'rental') {
        handleRefund('rental', refundObj);
      } else {
        handleRefund('charge', refundObj);
      }
    } else {
      setCommentError(!comment ? 'error' : null);
    }
  };

  const getHelpText = (type: string) => {
    if (type === 'amount') {
      if (Number(amount) > Number(fullAmount)) {
        return `You cannot refund more than the original amount of ${fullAmount}`;
      }
      if (Number(amount) <= 0) {
        return `You can only refund a non-negative number`;
      }
    } else if (commentError) {
      if (comment.length < 1) {
        return `Refund reason is required`;
      }
      if (comment.length > 500) {
        return 'Refund reason must be 500 characters or fewer';
      }
    }
  };

  const getValidationState = (value: string, type: string) => {
    if (type === 'amount') {
      if (Number(value) <= 0 || Number(value) > Number(fullAmount)) {
        return 'error';
      }
      return null;
    }
    return undefined;
  };

  const content = () => (
    <div>
      <MovaticFieldGroupOutlinedInput
        type={'number'}
        label="Refund Amount"
        description={`The original charge was $${fullAmount}. How much would you like to refund to ${user}?`}
        value={amount || ''}
        inputProps={{ max: fullAmount, min: 0, step: 0.25 }}
        onChange={(e) => {
          setAmount(e.target.value);
          setCommentError(null);
        }}
        id="amount"
        name={'amount'}
        addOn={'$'}
        hasError={getValidationState(amount, 'amount') === 'error'}
        errorMessage={getHelpText('amount')}
      />

      <MovaticFieldGroupText
        multiline
        rows={2}
        id="comment"
        name={'comment'}
        label="Refund Reason"
        description="Please leave a comment on why a refund was issued (required)"
        value={comment || ''}
        hasError={!!commentError}
        errorMessage={getHelpText('comment')}
        onChange={(e) => {
          const { value } = e.target;
          setComment(value);
          setCommentError(null);
        }}
      />
    </div>
  );

  return (
    <>
      <MovaticCustomModal
        open={show}
        onClose={() => close()}
        content={content()}
        title={<Typography variant={'h5'}>Refund to {userName}</Typography>}
        action={
          <IconButtonMenu
            buttons={[
              {
                label: 'Cancel',
                dataId: 'cancel',
                onClick: () => close(),
              },
              {
                label: 'Refund',
                dataId: 'refund',
                onClick: submitRefund,
                startIcon: <KeyboardReturnIcon />,
              },
            ]}
          />
        }
      />
    </>
  );
};

export default connect(
  (state: {
    system: {
      loading: boolean;
    };
    admin: {
      admin: IAuth;
    };
  }) => ({
    buttonLoading: state.system.loading,
    auth: state.admin.admin,
  }),
  () => ({})
)(Refund);
