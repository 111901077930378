import React, { useState, useEffect } from 'react';
import { history, convertCentsToMoney } from '../../../utils';
import { archiveFee, getFees } from '../../../api/fees';
import PageContainer from '../../../components/Redesing/page-container';
import PageContent from '../../../components/Redesing/page-content';
import { PropertyList } from '../../../components/Redesing/property-list';
import { PropertyListItem } from '../../../components/Redesing/property-list-item';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import ArchiveIcon from '@mui/icons-material/Archive';
import EditIcon from '@mui/icons-material/Edit';
import CardView from '../../../components/Redesing/card-view';
import CustomToast from '../../../components/Redesing/custom-toast';
import CustomAsyncToast from '../../../components/Redesing/custom-async-toast';

const Buttons = (props) => {
  return (
    <IconButtonMenu
      buttons={[
        {
          label: 'Archive',
          onClick: () => {
            CustomAsyncToast({
              promise: () =>
                archiveFee(props.feeId, {
                  archive_on: new Date().toISOString(),
                }),
              successMessage: () => 'The fee has been archived successfully!',
              errorMessage: () => 'There was an error archiving the fee.',
              loadingMessage: () => 'Archiving the fee...',
            }).then((result) => {
              if (result) {
                history.push('/billing/fees');
              }
            });
          },
          disabled: props.fee?.archived_on
            ? new Date(props.fee.archived_on).getTime() > new Date().getTime() ||
              props.fee?.archived_on !== null
            : false,
          startIcon: <ArchiveIcon />,
        },
        {
          label: 'Edit',
          onClick: () => history.push(`/billing/fees/${props.feeId}/edit`),
          startIcon: <EditIcon />,
        },
      ]}
    />
  );
};

const FeeItems = (props) => {
  const activeOn = props.fee?.active_on
    ? new Date(props.fee.active_on).toLocaleDateString()
    : 'None';
  const archivedOn = props.fee?.archived_on
    ? new Date(props.fee.archived_on).toLocaleDateString()
    : 'None';

  return (
    <PropertyList>
      <PropertyListItem divider label="Active On" value={activeOn} />
      <PropertyListItem divider label="Archived On" value={archivedOn} />
      <PropertyListItem divider label="Name" value={props.fee?.name || 'None'} />
      <PropertyListItem
        divider
        label="Description"
        value={props.fee?.description || 'None'}
      />
      <PropertyListItem
        divider
        label="Amount"
        value={convertCentsToMoney(props.fee?.amount)}
      />
      <PropertyListItem
        divider
        label="Applies above threshold"
        value={
          typeof props.fee?.applied_above_sub_total_threshold === 'number'
            ? convertCentsToMoney(props.fee.applied_above_sub_total_threshold)
            : 'Not set'
        }
      />
      <PropertyListItem
        divider
        label="Applies below threshold"
        value={
          typeof props.fee?.applied_under_sub_total_threshold === 'number'
            ? convertCentsToMoney(props.fee.applied_under_sub_total_threshold)
            : 'Not set'
        }
      />
      <PropertyListItem
        divider
        label="Applied to members"
        value={props.fee?.applied_to_members ? 'Yes' : 'No'}
      />
      <PropertyListItem
        label="Applied post-tax"
        value={props.fee?.applied_post_tax ? 'Yes' : 'No'}
      />
    </PropertyList>
  );
};

const ViewFee = (props) => {
  const [fee, setFee] = useState(null);
  const [loading, setLoading] = useState(true);
  const feeId = props.match.params.feeId;
  useEffect(() => {
    getFees({ fee_id: props.match.params.feeId })
      .then((results) => {
        if (results?.length) {
          setFee(results[0]);
        } else {
          CustomToast({
            type: 'error',
            message:
              'There was an error loading the fee. It looks like the fee ID in the URL does not match an existing fee.',
          });
        }
      })
      .finally(() => setLoading(false));
  }, [props.match.params.feeId]);

  return (
    <>
      <PageContainer isLoading={loading}>
        <PageContent
          backLink={'/billing/fees'}
          contentTitle={'Fees'}
          itemName={fee?.name || 'View Fee'}
          itemChipValue={feeId}
          chipLabel={'Fee ID'}
          tabsChildren={
            <CardView title={'Fee Details'} content={<FeeItems fee={fee} />} />
          }
          itemTopButtons={<Buttons fee={fee} feeId={props.match.params.feeId} />}
        />
      </PageContainer>
    </>
  );
};

export default ViewFee;
