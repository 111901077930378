import React from 'react';
import FsLightbox from 'fslightbox-react';

interface ImageLightBoxProps {
  toggler: boolean;
  imageUrl: string;
}

const ImageLightBox = ({ toggler, imageUrl }: ImageLightBoxProps) => {
  return (
    <>
      <FsLightbox toggler={toggler} sources={[imageUrl]} type="image" />
    </>
  );
};

export default ImageLightBox;
