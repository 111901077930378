import React, { useState } from 'react';
import { taskColumnsV2 } from '../../../tableColumns/hardwareColumns';
import { history } from '../../../utils';
import { IHardware } from '../../../ts/interface/hardware.interface';
import { PaginationTable } from '../../../components/Redesing/table/pagination-table';
import { IColumn } from '../../../ts/interface/components/redesing/table.interface';
import CardView from '../../../components/Redesing/card-view';
import AddIcon from '@mui/icons-material/Add';
import { useParams } from 'react-router-dom';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants';

const MaintenanceLogs = ({
  hardware,
  isLoading,
}: {
  hardware: IHardware;
  isLoading: boolean;
}) => {
  const [search, setSearch] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [sortData, setSortData] = useState<string>('start_time');
  const { type, hardwareId } = useParams<{ type: string; hardwareId: string }>();
  const [exportData, setExportData] = useState<boolean>(false);

  return (
    <>
      <CardView
        isLoading={isLoading}
        headerActions={
          <IconButtonMenu
            buttons={[
              {
                label: 'Export',
                onClick: () => setExportData(true),
                startIcon: <FileDownloadIcon />,
              },
              {
                startIcon: <AddIcon />,
                variant: 'contained',
                label: 'Add',
                onClick: () => history.push(`/maintenance/log/add/unit/${hardwareId}`),
              },
            ]}
          />
        }
        content={
          <>
            <PaginationTable
              customKey={'id'}
              onRowCLick={(id: string) => history.push(`/maintenance/log/${id}`)}
              sortDirection={'desc'}
              exportData={exportData}
              csvFileName={'Hardware_Logs.csv'}
              onExport={() => setExportData(false)}
              sortBy={sortData}
              onSortChange={(sort) => setSortData(sort.key)}
              handleSearch={(search) => setSearch(search.target.value)}
              searchText={search}
              columns={taskColumnsV2 as IColumn[]}
              items={hardware?.logs || []}
              onPageChange={(page, pageN) => {
                setPageNumber(pageN);
              }}
              onRowsPerPageChange={(row) => {
                const pageSize = Number(row.target.value);
                setSizePerPage(pageSize);
              }}
              page={pageNumber}
              rowsPerPage={sizePerPage}
              searchPlaceholder={'Search Maintenance Logs'}
              noDataText={`No maintenance logs for this ${type}`}
            />
          </>
        }
        title={'Maintenance Logs'}
      />
    </>
  );
};

export default MaintenanceLogs;
