import { CHANGE_MODE } from '../constants';

const initalState = {
  currentMode: '',
  configuration: null,
  details: {
    unitsTitle: '',
    stationsTitle: '',
    number: 0,
  },
  isLoaded: false,
};

export default function modeReducer (state = initalState, action) {
  let newState = { ...state };
  switch (action.type) {
    case CHANGE_MODE:
      newState = { ...newState, ...action.value, isLoaded: true };
      break;
    default:
      return state;
  }

  return newState;
}
