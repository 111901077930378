import React, { useEffect, useState } from 'react';
import MovaticCustomModal from '../../../components/Modal/MovaticCustomModal';
import { IRefundModalProps } from '../../../ts/interface/pages/rentals.interface';
import {
  MovaticFieldGroupOutlinedInput,
  MovaticFieldGroupText,
} from '../../../components/Redesing/movatic-field-group';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import CloseIcon from '@mui/icons-material/Close';
import CustomAsyncToast from '../../../components/Redesing/custom-async-toast';
import { refundRentalCharge } from '../../../api/transactions';

const RefundModal = ({
  userName,
  rentalId,
  chargeId,
  walletTransactionId,
  close,
  refundType,
  amount,
  show,
  cents,
}: IRefundModalProps) => {
  const [centsAmount, setCentsAmount] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [commentError, setCommentError] = useState<string | null>('');
  const [centsFullAmount, setCentsFullAmount] = useState<string>('0');

  useEffect(() => {
    if (amount || cents) {
      const calculatedAmount = !isNaN(Math.abs(amount) / 100)
        ? (Math.abs(amount) / 100).toFixed(2)
        : (Math.abs(cents) / 100).toFixed(2);
      setCentsAmount(calculatedAmount);
      setCentsFullAmount(calculatedAmount);
    }
  }, [amount, cents]);

  const getHelpText = (type: string) => {
    if (type === 'amount') {
      if (Number(centsAmount) > Number(centsFullAmount)) {
        return `You cannot refund more than the original amount of ${centsFullAmount}`;
      }
      if (Number(centsAmount) <= 0) {
        return `You can only refund a non negative number`;
      }
    } else if (commentError) {
      if (comment.length < 1) {
        return `Refund reason is required`;
      }
      if (comment.length > 500) {
        return 'Refund reason must be 5d00 characters or fewer';
      }
    }
    return null;
  };

  const getValidationState = (value: string, type: string) => {
    if (type === 'amount') {
      if (Number(value) <= 0 || Number(value) > Number(centsFullAmount)) {
        return 'error';
      }
      return null;
    }
  };

  const submit = () => {
    const amountInCents = Math.round(Number(centsAmount) * 100);
    // `amount` and `reason` are needed for the rental API
    const refundObj = {
      amount: amountInCents,
      reason: comment,
      refund: amountInCents,
      refundComment: comment,
      rentalId: rentalId,
      chargeId: chargeId,
      walletTransaction: walletTransactionId,
    };

    if (
      Number(centsAmount) <= Number(centsFullAmount) &&
      Number(centsAmount) > 0 &&
      comment
    ) {
      CustomAsyncToast({
        promise: () => refundRentalCharge(refundObj),
        successMessage: () => 'Your refund was issued successfully!',
        errorMessage: () => 'There was an error issuing your refund.',
        loadingMessage: () => 'Issuing refund...',
      }).then((r) => {
        close(true);
      });
    } else {
      setCommentError(!comment ? 'error' : null);
    }
  };

  return (
    <MovaticCustomModal
      open={show}
      onClose={() => close()}
      content={
        <div>
          <MovaticFieldGroupOutlinedInput
            label="Refund Amount"
            description={`The original charge was $${centsFullAmount}. How much would you like to refund to ${userName}?`}
            type="number"
            name="amount"
            id="amount"
            value={centsAmount}
            hasError={getValidationState(centsAmount, 'amount') === 'error'}
            errorMessage={getHelpText('amount') || undefined}
            inputProps={{ min: 0, step: 0.25 }}
            addOn={'$'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCentsAmount(e.target.value);
            }}
          />
          <MovaticFieldGroupText
            id="refundComment"
            name={'refundComment'}
            label="Refund Reason"
            description="Please leave a comment on why a refund was issued (required)"
            hasError={commentError === 'error'}
            errorMessage={getHelpText('comment') || undefined}
            value={comment}
            multiline
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setComment(e.target.value)
            }
            rows={2}
            maxRows={4}
          />
        </div>
      }
      title={`Refund to ${userName}`}
      action={
        <IconButtonMenu
          buttons={[
            {
              label: 'Cancel',
              onClick: () => close(),
              startIcon: <CloseIcon />,
            },
            {
              label: 'Submit',
              onClick: submit,
            },
          ]}
        />
      }
    />
  );
};

export default RefundModal;
