import React from 'react';

import FormItem from '../../../components/Redesing/form-item';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import SaveIcon from '@mui/icons-material/Save';
import CardView from '../../../components/Redesing/card-view';
import { Stack } from '@mui/system';

function button(props) {
  const { saveButtonLoading, maxChargeThreshold, submit } = props;

  return (
    <div>
      <IconButtonMenu
        buttons={[
          {
            label: 'Save',
            startIcon: <SaveIcon />,
            onClick: () =>
              submit({
                maxChargeThreshold,
                card: 'maxChargeThreshold',
              }),
            disabled: !saveButtonLoading,
          },
        ]}
      />
    </div>
  );
}

function content(props) {
  const { handleChange, maxChargeThreshold } = props;

  return (
    <div>
      {/*  Charge Review Threshold */}
      <FormItem
        label="Charge Review Threshold"
        description="Charges above this amount will require admin approval before being processed."
        content={
          <OutlinedInput
            fullWidth
            id="maxChargeThreshold"
            inputProps={{ step: 0.25, min: 0 }}
            type="number"
            placeholder="0"
            value={maxChargeThreshold || ''}
            onChange={handleChange}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
        }
      />
    </div>
  );
}

export default function MaxThresholdCard(props) {
  return (
    <CardView
      title="Charge Review Threshold"
      content={
        <Stack px={2} pb={2}>
          {content(props)}
        </Stack>
      }
      headerActions={button(props)}
    />
  );
}
