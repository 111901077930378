import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import Refund from '../refund/Refund';

import {
  toTitleCase,
  convertCentsToMoney,
  getLocaleDateTimeString,
  unixTimestampToDate,
  findAccess,
  history,
} from '../../utils';

import { getRefund } from '../../api/transactions';

import {
  getTransactionDetails,
  getWalletTransactionDetails,
} from '../../actionCreators/transaction';

import { CHARGE_TRANSACTION_ID, REFUND_TRANSACTION_ID } from '../../constants';
import PageContainer from '../../components/Redesing/page-container';
import PageContent from '../../components/Redesing/page-content';
import CardView from '../../components/Redesing/card-view';
import { Stack } from '@mui/system';
import { PropertyList } from '../../components/Redesing/property-list';
import { PropertyListItem } from '../../components/Redesing/property-list-item';
import PropertyListItemLink from '../../components/Redesing/property-list-item-link';
import Divider from '@mui/material/Divider';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import { SeverityPill } from '../../components/Redesing/severity-pill';

export const TransactionDetailModal = (props) => {
  const [chargeTransaction, setChargeTransaction] = useState({});
  const [walletTransaction, setWalletTransaction] = useState({});
  const [refundTransaction, setRefundTransaction] = useState({});
  const [refundLoaded, setRefundLoading] = useState(false);
  const [showRefundModal, setRefundModal] = useState(false);

  const {
    transactionDetailedLoaded,
    walletTransactionDetailLoaded,
    systemLoaded,
    detailedWalletTransaction,
    transactionDetailed,
    getTransactionDetails,
    getWalletTransactionDetails,
    systemAccess,
  } = props;
  const loaded =
    transactionDetailedLoaded || walletTransactionDetailLoaded || refundLoaded;
  const transactionType = walletTransaction?.type || refundTransaction?.type || 'Charge';
  const refunded = chargeTransaction?.refunded || walletTransaction?.refunded;
  const transactionStatus = chargeTransaction?.status || walletTransaction?.status;
  const showRefundButton =
    !refunded &&
    (transactionType === 'Charge' || transactionType === 'Wallet Debit') &&
    transactionStatus === 'succeeded';
  const { transactionId } = props.match.params;

  const rentalId =
    chargeTransaction?.rentalData?.rental_id || walletTransaction?.rental_data?.gid;
  const userName = chargeTransaction?.user_name || walletTransaction?.user_name;
  const transactionIdPrefix = props.match.params.transactionId.slice(0, 2);

  useEffect(() => {
    if (systemLoaded) {
      if (transactionIdPrefix === CHARGE_TRANSACTION_ID) {
        getTransactionDetails(transactionId);
      } else if (transactionIdPrefix === REFUND_TRANSACTION_ID) {
        getRefund(transactionId)
          .then((data) => setRefundTransaction(data))
          .finally(() => setRefundLoading(true));
      } else {
        getWalletTransactionDetails(transactionId);
      }
    }
  }, [
    systemLoaded,
    getTransactionDetails,
    getWalletTransactionDetails,
    transactionId,
    transactionIdPrefix,
  ]);

  useEffect(() => {
    if (transactionDetailedLoaded && transactionIdPrefix === CHARGE_TRANSACTION_ID) {
      setChargeTransaction(transactionDetailed);
    }

    if (
      walletTransactionDetailLoaded &&
      transactionIdPrefix !== CHARGE_TRANSACTION_ID &&
      transactionIdPrefix !== REFUND_TRANSACTION_ID
    ) {
      setWalletTransaction(detailedWalletTransaction);
    }
  }, [
    transactionDetailed,
    detailedWalletTransaction,
    walletTransactionDetailLoaded,
    transactionDetailedLoaded,
    transactionIdPrefix,
  ]);

  const generalInfo = (info) => {
    const {
      type,
      rentalId,
      taxRate,
      taxAmount,
      chargeTotal,
      transactionId,
      created,
      description,
      status,
    } = info;

    return (
      <PropertyList dataId={'general-info-transaction'}>
        <PropertyListItem divider label={'Type'} value={type} dataId="transaction-type" />
        {rentalId && (
          <PropertyListItemLink
            divider
            label={'Rental ID'}
            value={rentalId}
            dataId="transaction-rental-id"
            to={`/rentals/${rentalId}`}
            text={rentalId}
          />
        )}
        <PropertyListItem
          divider
          label="Tax Rate"
          value={`${taxRate || 0}%`}
          dataId="transaction-tax-rate"
        />
        <PropertyListItem
          divider
          label="Tax Collected"
          value={!!taxAmount ? convertCentsToMoney(taxAmount) : '$0.00'}
          dataId="transaction-tax-collected"
        />
        <PropertyListItem
          divider
          label="Subtotal"
          value={convertCentsToMoney(chargeTotal)}
          dataId="transaction-subtotal"
        />
        <PropertyListItem
          divider
          label="Total"
          value={convertCentsToMoney(chargeTotal)}
          dataId="transaction-total"
        />
        <PropertyListItem
          divider
          label="Id"
          value={transactionId}
          dataId="transaction-id"
        />
        <PropertyListItem
          divider
          label="Date"
          value={getLocaleDateTimeString(created)}
          dataId="transaction-date"
        />
        <PropertyListItem
          divider
          label="Description"
          value={description}
          dataId="transaction-description"
        />
        <PropertyListItem label="Status" dataId="transaction-status">
          <SeverityPill color={status === 'succeeded' ? 'success' : 'error'}>
            {toTitleCase(status)}
          </SeverityPill>
        </PropertyListItem>
      </PropertyList>
    );
  };

  const generalRefundInfo = (info) => {
    const { type, chargeTotal, transactionId, created, description, status } = info;

    return (
      <PropertyList>
        <PropertyListItem divider label="Type" value={type} dataId="refund-info-type" />
        <PropertyListItem
          divider
          label="Total"
          value={convertCentsToMoney(chargeTotal)}
          dataId="refund-info-total"
        />
        <PropertyListItem
          divider
          label="Id"
          value={transactionId}
          dataId="refund-info-id"
        />
        <PropertyListItem
          divider
          label="Date"
          value={getLocaleDateTimeString(created)}
          dataId="refund-info-date"
        />
        <PropertyListItem
          divider
          label="Description"
          value={description}
          dataId="refund-info-description"
        />
        <PropertyListItem
          divider
          label="Status"
          value={toTitleCase(status)}
          dataId="refund-info-status"
        />
      </PropertyList>
    );
  };

  const button = () => {
    return (
      <>
        <IconButtonMenu
          buttons={[
            {
              label: 'Issue Refund',
              onClick: () => setRefundModal(true),
              disabled:
                !findAccess(systemAccess).includes('refunds') &&
                !props.auth.admin.movaticAccess,
              dataId: 'button-refund',
              startIcon: <KeyboardReturnIcon />,
            },
          ]}
        />
      </>
    );
  };

  const refundInfo = (info) => {
    const { refundId, refundAmmount, refundDescription, refundDate, refundStatus } = info;

    return (
      <PropertyList>
        {refundId && (
          <PropertyListItem
            divider
            label="Refund ID"
            value={refundId}
            dataId="transaction-refund-id"
          />
        )}
        <PropertyListItem
          label="Refund amount"
          value={convertCentsToMoney(refundAmmount)}
          dataId="transaction-refund-amount"
        />
        {refundDescription && (
          <>
            <Divider />
            <PropertyListItem
              label="Refund description"
              value={refundDescription}
              dataId="transaction-refund-description"
            />
          </>
        )}
        {refundDate && (
          <>
            <Divider />
            <PropertyListItem
              divider
              label="Refund Date"
              value={getLocaleDateTimeString(refundDate)}
              dataId="transaction-refund-date"
            />
          </>
        )}
        {refundStatus && (
          <>
            <Divider />
            <PropertyListItem label="Refund Status" dataId="transaction-refund-status">
              <SeverityPill color={refundStatus === 'succeeded' ? 'success' : 'error'}>
                {toTitleCase(refundStatus)}
              </SeverityPill>
            </PropertyListItem>
          </>
        )}
      </PropertyList>
    );
  };

  const content = () => {
    const {
      rentalData: chargeRentalData,
      metadata: chargeDetails,
      id: chargeId,
      created: chargeCreated,
      description: chargeDescription,
      status: chargeStatus,
      refunded: chargeRefunded,
      refunds: { data: refundCharge } = {},
    } = chargeTransaction;

    const {
      type: walletType,
      rental_data: walletRentalData,
      refunded: walletTransactionRefunded,
    } = walletTransaction;

    const transaction = {
      type: walletType || 'Charge',
      rentalId: chargeRentalData?.rental_id || walletRentalData?.gid,
      taxRate: chargeDetails?.tax_rate || walletTransaction.tax_rate || 0,
      taxAmount: Number(chargeDetails?.tax_amount || walletTransaction.tax_amount),
      chargeTotal: Number(
        chargeDetails?.charge_total ||
          chargeTransaction?.amount_captured ||
          walletTransaction.charge_total
      ),
      transactionId: chargeId || walletTransaction.id,
      created:
        (chargeCreated && unixTimestampToDate(chargeCreated)) ||
        walletTransaction.created_on,
      description: chargeDescription || walletTransaction.description,
      status: chargeStatus || walletTransaction.status,
    };

    const refundFromTransaction = {
      refundId: refundCharge?.[0]?.id,
      refundDate:
        refundCharge?.[0]?.created && unixTimestampToDate(refundCharge?.[0]?.created),
      refundAmmount: refundCharge?.[0]?.amount || walletTransaction.amount_refunded,
      refundDescription:
        chargeRentalData?.refund_description || walletTransaction.refund_description,
      refundStatus: refundCharge?.[0]?.status,
    };

    const refundGral = {
      type: refundTransaction?.type,
      chargeTotal: refundTransaction?.amount,
      transactionId: refundTransaction?.id,
      created: refundTransaction?.created,
      description: refundTransaction?.description,
      status: refundTransaction?.status,
    };

    const refunded = chargeRefunded || walletTransactionRefunded;
    const isTransactionLoaded =
      transactionDetailedLoaded || walletTransactionDetailLoaded;

    return (
      <Stack spacing={2} data-id="charge-details-content">
        {isTransactionLoaded && transactionIdPrefix !== REFUND_TRANSACTION_ID && (
          <CardView
            dataId={'charge-details'}
            content={<Stack>{generalInfo(transaction)}</Stack>}
            title={'General Information'}
          />
        )}
        {refunded && (
          <CardView
            dataId={'refund-details'}
            content={<Stack>{refundInfo(refundFromTransaction)}</Stack>}
            title={'Refund Information'}
          />
        )}
        {refundLoaded && (
          <CardView
            dataId={'refund-details'}
            content={<Stack>{generalRefundInfo(refundGral)}</Stack>}
            title={'Refund Information'}
          />
        )}
        {showRefundModal && (
          <Refund
            show={showRefundModal}
            close={(redirect) => {
              setRefundModal(false);
              if (redirect) {
                history.push('/billing/transactions');
              }
            }}
            chargeId={transactionId}
            rentalId={rentalId}
            transaction={transaction}
            refundType={rentalId ? 'rental' : 'other'}
            userName={userName || ''}
            rentalToRefund={{
              billing: { total: transaction?.cents },
            }}
          />
        )}
      </Stack>
    );
  };

  return (
    <>
      <PageContainer isLoading={!loaded}>
        <PageContent
          backLink={'/billing/transactions'}
          contentTitle={'Transactions'}
          itemName={transactionType}
          itemChipValue={transactionId}
          chipLabel={`${transactionType} ID`}
          tabsChildren={content()}
          itemTopButtons={showRefundButton ? button() : null}
        />
      </PageContainer>
    </>
  );
};

export default connect(
  (state) => ({
    auth: state.admin.admin,
    system: state.system.current,
    detailedWalletTransaction: state.transaction.detailedWalletTransaction,
    transactionDetailed: state.transaction.detailed,
    transactionDetailedLoaded: state.transaction.detailedLoaded,
    walletTransactionDetailLoaded: state.transaction.walletTransactionDetailedLoaded,
    systemLoaded: state.system.isLoaded,
    systemAccess: state.admin.systemAccess,
  }),
  (dispatch) => ({
    getTransactionDetails(chargeId) {
      dispatch(getTransactionDetails(chargeId));
    },
    getWalletTransactionDetails(chargeId) {
      dispatch(getWalletTransactionDetails(chargeId));
    },
  })
)(TransactionDetailModal);
