import React from 'react';

import { convertCentsToMoney, getIntervalInfo } from '../utils';
import { SeverityPill } from '../components/Redesing/severity-pill';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';
import IconButtonMenu from '../components/Redesing/icon-button-menu';
import EditIcon from '@mui/icons-material/Edit';

export function viewMembershipPricingColumnsV2() {
  return [
    {
      id: 'price',
      label: `Price`,
      format: (row) => convertCentsToMoney(row.price),
    },
    {
      id: 'period',
      label: 'Period',
      format: (row) => {
        if (row.interval) {
          const intervalInfo = getIntervalInfo(row.interval);
          return `${intervalInfo.value} ${intervalInfo.units}`;
        }
        return `${new Date(row.starts_on).toLocaleDateString()} - ${new Date(
          row.ends_on
        ).toLocaleDateString()}`;
      },
    },
    {
      id: 'auto_renews',
      label: `Renews`,
      format: (row) =>
        row?.auto_renews ? (
          <SeverityPill color={'success'}>Yes</SeverityPill>
        ) : (
          <SeverityPill color={'error'}>No</SeverityPill>
        ),
    },
    {
      id: 'priority',
      label: 'Priority',
    },
  ];
}

export function addMembershipPricingColumnsV2(removePrice) {
  return [
    {
      id: 'price',
      label: `Price`,
      format: (row) => convertCentsToMoney(row.price),
    },
    {
      id: 'period',
      label: 'Period',
      format: (row) => {
        return row.pricingType === 'interval'
          ? `${row.intervalValue} ${row.intervalUnits}`
          : `${new Date(row.starts).toLocaleDateString()} - ${new Date(
              row.ends
            ).toLocaleDateString()}`;
      },
    },
    {
      id: 'auto_renews',
      label: `Renews`,
      format: (row) =>
        row?.auto_renews ? (
          <SeverityPill color={'success'}>Yes</SeverityPill>
        ) : (
          <SeverityPill color={'error'}>No</SeverityPill>
        ),
    },
    {
      id: 'priority',
      label: 'Priority',
    },
    {
      id: 'actions',
      label: 'Actions',
      disableClick: true,
      disableSort: true,
      notSortable: true,
      format: (row) => {
        const arrayBtns = [
          {
            label: '',
            startIcon: <DeleteIcon />,
            onClick: (e) => {
              e.stopPropagation();
              removePrice(row.id);
            },
          },
        ];

        return (
          <Stack justifyContent={'flex-start'} alignItems={'flex-start'}>
            <IconButtonMenu buttons={arrayBtns} />
          </Stack>
        );
      },
    },
  ];
}

export function membershipsDocuments(removeDocument, editDocument) {
  return [
    {
      id: 'name',
      label: `Name`,
      searchable: true,
    },
    {
      id: 'required',
      label: `Required`,
      format: (row) => {
        return row?.required ? (
          <SeverityPill color={'error'}>Yes</SeverityPill>
        ) : (
          <SeverityPill color={'success'}>No</SeverityPill>
        );
      },
    },
    {
      id: 'actions',
      label: 'Actions',
      disableClick: true,
      disableSort: true,
      notSortable: true,
      format: (row) => {
        const arrayBtns = [
          {
            label: '',
            startIcon: <EditIcon />,
            onClick: (e) => {
              e.stopPropagation();
              editDocument(row.id);
            },
          },
          {
            label: '',
            startIcon: <DeleteIcon />,
            onClick: (e) => {
              e.stopPropagation();
              removeDocument(row.id);
            },
          },
        ];

        return (
          <Stack justifyContent={'flex-start'} alignItems={'flex-start'}>
            <IconButtonMenu buttons={arrayBtns} />
          </Stack>
        );
      },
    },
    {
      id: 'description',
      label: 'Description',
      showToolTip: true,
      searchable: true,
    },
  ];
}
export function membershipsDocumentsView() {
  return [
    {
      id: 'name',
      label: `Name`,
      searchable: true,
    },
    {
      id: 'required',
      label: `Required`,
      format: (row) => {
        return row?.required ? (
          <SeverityPill color={'error'}>Yes</SeverityPill>
        ) : (
          <SeverityPill color={'success'}>No</SeverityPill>
        );
      },
    },
    {
      id: 'description',
      label: 'Description',
      showToolTip: true,
      searchable: true,
    },
  ];
}
