import { baseUrlV2, getCredentials, fetchOptionsV2, createQueryParameters } from './http';
import { displayError } from '../utils';

export const getFees = (parameters) => {
  const { adminEmail, adminToken, systemGid } = getCredentials();
  const fetchOps = fetchOptionsV2(adminEmail, adminToken);
  const queryParametersObject = {
    limit: 1000,
    system_id: systemGid,
    ...parameters,
  };
  const queryParameters = createQueryParameters(queryParametersObject);

  return fetch(`${baseUrlV2}/fee/?${queryParameters}`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response?.data) {
        return response.data;
      }

      throw new Error(response?.error_description);
    })
    .catch((e) => {
      displayError(
        'Oops! Looks like there was an error while loading fees. Please try again later.',
        e
      );
      return [];
    });
};

export const addFee = (body) => {
  const { adminEmail, adminToken, systemGid } = getCredentials();
  const postBody = { ...body, system_id: systemGid };
  const fetchOps = fetchOptionsV2(adminEmail, adminToken, 'POST', postBody);

  return fetch(`${baseUrlV2}/fee/`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response?.data) {
        return response.data;
      }

      throw new Error(response?.error_description);
    })
    .catch((e) => {
      displayError(
        'Oops! Looks like there was an error while adding the fee. Please try again later.',
        e
      );
      return null;
    });
};

export const updateFee = (feeId, body) => {
  const { adminEmail, adminToken } = getCredentials();
  const fetchOps = fetchOptionsV2(adminEmail, adminToken, 'PUT', body);

  return fetch(`${baseUrlV2}/fee/${feeId}`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response?.data) {
        return response.data;
      }

      throw new Error(response?.error_description);
    })
    .catch((e) => {
      displayError(
        'Oops! Looks like there was an error while updating the fee. Please try again later.',
        e
      );
      return null;
    });
};

export const archiveFee = (feeId, body) => {
  const { adminEmail, adminToken } = getCredentials();
  const fetchOps = fetchOptionsV2(adminEmail, adminToken, 'PUT', body);

  return fetch(`${baseUrlV2}/fee/${feeId}/archive`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response?.data) {
        return response.data;
      }

      throw new Error(response?.error_description);
    })
    .catch((e) => {
      displayError(
        'Oops! Looks like there was an error while archiving the fee. Please try again later.',
        e
      );
      return null;
    });
};
