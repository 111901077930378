import React, { useContext, useState } from 'react';
import { Switch } from '@mui/material';
import { Brightness4Outlined, Brightness7Outlined } from '@mui/icons-material';
import { SettingsContext } from '../../context/settings';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const DarkModeToggle: React.FC = () => {
  const context = useContext(SettingsContext);

  const [isDarkMode, setIsDarkMode] = useState<boolean>(context.paletteMode === 'dark');

  const handleModeToggle = () => {
    setIsDarkMode((prevMode) => !prevMode); // Toggle the state
    context.handleUpdate({ paletteMode: !isDarkMode ? 'dark' : 'light' }); // Use the updated state value
  };

  return (
    <>
      <ListItem>
        <ListItemIcon>
          <Brightness7Outlined />
        </ListItemIcon>
        <ListItemText
          id="switch-list-label-wifi"
          primary={isDarkMode ? 'Dark' : 'Light'}
        />
        <Switch
          checked={isDarkMode}
          onChange={handleModeToggle}
          color="secondary"
          icon={<Brightness7Outlined />}
          checkedIcon={<Brightness4Outlined />}
        />
      </ListItem>
    </>
  );
};

export default DarkModeToggle;
