import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import AddIcon from '@mui/icons-material/Add';
import { connect } from 'react-redux';
import { ISystem } from '../../ts/interface/system.interface';
import { PaginationTable } from '../../components/Redesing/table/pagination-table';
import { findAccess, history } from '../../utils';
import {
  IColumn,
  ISortConfig,
} from '../../ts/interface/components/redesing/table.interface';
import { IAuth } from '../../ts/interface/admins.interface';
import { clearSystemData, setNewSystem, systemLoaded } from '../../actionCreators/system';
import { IImage } from '../../ts/interface/image.interface';
import { useEffect, useState } from 'react';
import useSystems from '../../hooks/system/query/useSystems';
import useSearchDebounce from '../../hooks/search/useSearchDebounce';
import ColumnAvatar from '../../components/Redesing/column-avatar';
import CustomToast from '../../components/Redesing/custom-toast';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import useSmallScreen from '../../hooks/useSmallScreen';

interface ISystemModalProps {
  system: ISystem;
  auth: IAuth;
  currentSystemId: string;
  isLoaded: boolean;
  loaded: Function;
  clearSystemData: Function;
  updateSystem: Function;
  open: boolean;
  onClose: () => void;
  images: IImage[];
  systemAccess: number;
}

const SystemSelectionModal = ({
  open,
  onClose,
  currentSystemId,
  loaded,
  updateSystem,
  clearSystemData,
  systemAccess,
  auth,
  images,
}: ISystemModalProps) => {
  const [search, setSearch] = React.useState('');
  const debouncedSearchTerm = useSearchDebounce(search);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [sort, setSort] = React.useState<ISortConfig>({
    key: 'name',
    direction: 'asc',
  });
  const [systems, setSystems] = React.useState<ISystem[]>([]);
  const [lastId, setLastId] = useState<string | null>(null);
  const [itemCount, setItemCount] = useState<number>(0);
  const isSmallScreen = useSmallScreen();

  const { refetch, isRefetching, isLoading, isFetching } = useSystems(
    {
      enabled: false,
    },
    {
      limit: rowsPerPage,
      search: debouncedSearchTerm,
      lastId: lastId,
    }
  );
  useEffect(() => {
    refetch().then((res) => {
      if (res.status === 'success') {
        const { data } = res;
        const systems = data.systems;
        setItemCount(data.count);
        if (lastId) {
          if (data) setSystems((stations) => [...stations, ...systems]);
        } else {
          setSystems(systems);
        }
      }
    });
  }, [lastId, rowsPerPage, debouncedSearchTerm, refetch]);

  const systemClick = (systemId: string) => {
    if (systemId !== currentSystemId) {
      loaded(false);
      clearSystemData();
      updateSystem(systemId, auth)
        .then(() => {
          loaded(true);
          history.push('/dashboard');
          onClose();
        })
        .catch((e: Error) => {
          CustomToast({
            type: 'error',
            message: `there was an error while changing systems. ${e}`,
          });
        });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: isSmallScreen ? '100vw' : '80vw',
          margin: isSmallScreen ? 1 : 'auto',
          width: '100%',
        },
      }}
    >
      <DialogContent sx={{ p: 0, minWidth: '65vw' }}>
        <PaginationTable
          padding={{
            pt: 1,
            pl: 0,
            pr: 1,
          }}
          noRemoteSort
          disableHasMore
          totalItems={itemCount}
          isLoading={isLoading || isRefetching || isFetching}
          customKey="gid"
          onRowCLick={(id: string) => systemClick(id)}
          handleSearch={(search) => {
            setSearch(search.target.value);
            setPageNumber(0);
            setLastId(null);
            setRowsPerPage(10);
          }}
          searchText={search}
          sortBy={sort.key}
          showFilter={false}
          sortDirection={sort.direction}
          onSortChange={(sort) => setSort(sort as ISortConfig)}
          getSelectedRow={(row: ISystem) => row.id === currentSystemId}
          columns={
            [
              ...(isSmallScreen
                ? [
                    {
                      id: 'name',
                      label: 'System Name',
                      searchable: true,
                      maxWidth: 'none',
                      format: (row: ISystem) => {
                        return (
                          <ColumnAvatar
                            noEllipsis
                            avatar={<AccountTreeIcon />}
                            firstRow={row.name}
                          />
                        );
                      },
                    },
                  ]
                : [
                    {
                      id: 'name',
                      label: 'System Name',
                      searchable: true,
                      showToolTip: true,
                      format: (row: ISystem) => {
                        return (
                          <ColumnAvatar
                            noEllipsis
                            avatar={<AccountTreeIcon />}
                            firstRow={row.name}
                          />
                        );
                      },
                    },
                  ]),
              {
                id: 'partner_name',
                label: 'Organization',
                searchable: true,
              },
            ] as IColumn[]
          }
          buttons={[
            {
              startIcon: <AddIcon />,
              variant: 'contained',
              label: 'Add',
              dataId: 'add-system',
              disabled: !(
                findAccess(systemAccess).includes('partner') || auth.admin.movaticAccess
              ),
              onClick: () => {
                history.push('/addsystem');
                onClose();
              },
            },
          ]}
          localSearch={false}
          items={systems || []}
          onPageChange={(page, newPage) => {
            const pageNumberXd = newPage + 1;
            if (systems?.length / rowsPerPage <= pageNumberXd) {
              setLastId(systems[systems.length - 1].gid);
            }
            setPageNumber(newPage);
          }}
          onRowsPerPageChange={(pageSizeString) => {
            const pageSize = Number(pageSizeString.target.value);
            setRowsPerPage(pageSize);
          }}
          page={pageNumber}
          rowsPerPage={rowsPerPage}
        />
      </DialogContent>
    </Dialog>
  );
};

export default connect(
  (state: {
    system: { current: ISystem; isLoaded: boolean; systems: ISystem[]; images: IImage[] };
    admin: { admin: IAuth; systemAccess: number };
  }) => ({
    system: state.system.current,
    systems: state.system.systems,
    isLoaded: state.system.isLoaded,
    currentSystemId: state.system.current.id,
    auth: state.admin.admin,
    images: state.system.images,
    systemAccess: state.admin.systemAccess,
  }),
  (dispatch: Function) => ({
    clearSystemData() {
      dispatch(clearSystemData());
    },
    updateSystem(systemId: string, auth: IAuth) {
      return dispatch(setNewSystem(systemId, auth));
    },
    loaded(bool: boolean) {
      dispatch(systemLoaded(bool));
    },
  })
)(SystemSelectionModal);
