import { MapControl, withLeaflet } from 'react-leaflet';
import L from 'leaflet';

class LeafletLegend extends MapControl {
  createLeafletElement() {
    const legend = L.control({ position: 'bottomright' });
    const legendLabels = this.props.labels.map(
      (item) => `<i style="background: ${item.color}"></i> ${item.label}`
    );

    legend.onAdd = () => {
      const div = L.DomUtil.create('div', 'leaflet-legend');
      div.style.width = this.props.width ? `${this.props.width}px` : 'auto';
      div.innerHTML = this.props.title ? `<h6>${this.props.title}</h6>` : '';
      div.innerHTML += legendLabels.join('<br>');
      return div;
    };

    return legend;
  }
}

export default withLeaflet(LeafletLegend);
