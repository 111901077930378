import { useQuery } from '@tanstack/react-query';
import { getHardware } from '../../../api/hardware';
import { IUseHardwareFilter } from '../../../ts/interface/hardware.interface';

interface Parameters {
  queryParameters?: IUseHardwareFilter;
  paginationResultHandler?: Function;
  systemId?: string;
}

//TODO: This needs to be refactored in the API, to load hardware list without filters,
// to better manage pagination and get all fields that are needed
const useHardware = (options?: {}, parameters?: Parameters) => {
  return useQuery(
    ['hardware', parameters?.queryParameters, parameters?.systemId],
    () => getHardware(parameters?.queryParameters, parameters?.paginationResultHandler),
    { ...options }
  );
};

export default useHardware;
