import React, { useEffect, useState } from 'react';
import {
  Badge,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Fab,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Modal,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import MovaticCustomModal from '../Modal/MovaticCustomModal';
import { IOnboarding, ISystem } from '../../ts/interface/system.interface';
import useSystem from '../../hooks/system/query/useSystem';
import { history } from '../../utils';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import useUpdateSystem from '../../hooks/system/mutation/useUpdateSystem';
import CheckBox from '../CheckBox/CheckBox';
import useAnalyticsEventTracker from '../../hooks/analytics/useAnalyticsEventTracker';
import IconButtonMenu from '../Redesing/icon-button-menu';

const SystemSetup = ({
  system,
  onCloseSetup,
}: {
  system: ISystem;
  onCloseSetup: Function;
}) => {
  const [open, setOpen] = useState(false);
  const [pendingItems, setPendingItems] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [onBoardingState, setOnBoardingState] = useState<IOnboarding>({} as IOnboarding);
  const [disable, setDisable] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const reportEvent = useAnalyticsEventTracker();

  const theme = useTheme();

  const updateSystemMutation = useUpdateSystem();

  const { data: systemData, isLoading } = useSystem(system.id);
  const onboarding = systemData?.metadata?.onboarding;

  useEffect(() => {
    if (onboarding) {
      const completedItems = onboarding.tips.filter(
        (item: { status: boolean }) => !item.status
      ).length;
      const itemsComplete = onboarding.tips.length - completedItems;
      const percentage = (itemsComplete / onboarding.tips.length) * 100;
      setPercentage(percentage);
      setPendingItems(completedItems);
      setOnBoardingState(onboarding);
      setHidden(onboarding.hidden);
    }
  }, [system.id, onboarding]);

  if (onboarding?.hidden || isLoading) return null;

  const onModalClose = () => {
    setOpen(!open);
    if (hidden) {
      onCloseSetup();
    }
    if (percentage === 100) {
      updateSystemMutation.mutate(
        {
          ...system,
          metadata: {
            onboarding: {
              hidden: true,
              tips: onBoardingState.tips,
            },
          },
        },
        {
          onSuccess: () => {
            reportEvent('onboarding_setup', {
              action: 'setup_completed',
              label: 'setup_completed',
              system_name: system.name,
              system_id: system.gid,
            });
            onCloseSetup();
          },
        }
      );
    }
  };

  return (
    <>
      {open ? (
        <Modal
          open={open}
          onClose={onModalClose}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '80%',
            margin: 'auto',
            overflow: 'scroll',
            maxHeight: '90%',
          }}
        >
          <>
            <Card
              sx={{
                maxHeight: '100%',
                overflow: 'scroll',
                display: 'block',
              }}
            >
              <CardHeader
                sx={{
                  paddingRight: 2,
                  paddingLeft: 2,
                  paddingTop: 2,
                  paddingBottom: 0,
                }}
                action={
                  <IconButton
                    id={'close-button'}
                    onClick={onModalClose}
                    aria-label="settings"
                  >
                    <CloseIcon
                      sx={{
                        color: theme.palette.text.primary,
                      }}
                      fontSize={'inherit'}
                    />
                  </IconButton>
                }
                title={
                  <Stack
                    alignItems="flex-start"
                    spacing={2}
                    direction={{
                      xs: 'column',
                      sm: 'row',
                    }}
                  >
                    <div>
                      <Typography variant="h6">{'Setup'}</Typography>
                      <Typography variant="body2">
                        {'Get your system running.'}
                      </Typography>
                    </div>
                  </Stack>
                }
              />
              <Box
                sx={{ width: '100%', pl: 2, pr: 2, mt: 1 }}
                id="system-setup-progress-bar"
              >
                <LinearProgress
                  title={'Progress'}
                  variant="determinate"
                  value={percentage}
                  color={theme.palette.mode === 'light' ? 'primary' : 'secondary'}
                />
                <Box>
                  <Typography
                    // sx={{ fontFamily: MOVATIC_FONTS, color: MOVATIC_FONT_COLOR }}
                    variant="caption"
                    component="div"
                    color="text.secondary"
                  >{`${Math.round(percentage)}% completed`}</Typography>
                </Box>
              </Box>
              <CardContent
                sx={{
                  p: 2,
                  '&:last-child': {
                    paddingBottom: 2,
                  },
                }}
              >
                <Grid container direction="column">
                  {onBoardingState.tips.map((item, index) => (
                    <Grid sx={{ height: 'auto' }} key={item.id} xs="auto" item>
                      <Box sx={{ mb: index === onBoardingState.tips.length - 1 ? 0 : 2 }}>
                        <Card variant="outlined">
                          <Box
                            sx={{
                              px: 2,
                              py: 1.5,
                            }}
                          >
                            <Grid
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              container
                            >
                              <Grid item xs={11}>
                                <Typography
                                  sx={{
                                    // fontFamily: MOVATIC_FONTS,
                                    // color: MOVATIC_FONT_COLOR,
                                    textDecoration: item.status ? 'line-through' : 'none',
                                  }}
                                  variant="subtitle1"
                                >
                                  {item.title}
                                </Typography>
                              </Grid>
                              <Grid
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  alignItems: 'center',
                                }}
                                item
                                xs={1}
                              >
                                <Checkbox
                                  disabled={disable}
                                  id={item.id}
                                  checked={item.status}
                                  onChange={(event: {
                                    target: { id: string; checked: boolean };
                                  }) => {
                                    setDisable(true);

                                    const newSetupItems = onBoardingState.tips.map(
                                      (item) => {
                                        if (item.id === event.target.id) {
                                          return {
                                            ...item,
                                            status: event.target.checked,
                                          };
                                        }
                                        return item;
                                      }
                                    );

                                    const payload = newSetupItems.map((item) => {
                                      return {
                                        id: item.id,
                                        status: item.status,
                                      };
                                    });

                                    const calculatedPendingItems = event.target.checked
                                      ? pendingItems - 1
                                      : pendingItems + 1;

                                    setPendingItems(calculatedPendingItems);
                                    setPercentage(
                                      100 -
                                        (calculatedPendingItems / newSetupItems.length) *
                                          100
                                    );
                                    setOnBoardingState((prevState) => ({
                                      ...prevState,
                                      tips: newSetupItems,
                                    }));

                                    updateSystemMutation.mutate(
                                      {
                                        ...system,
                                        metadata: {
                                          onboarding: {
                                            hidden: onBoardingState.hidden,
                                            tips: payload,
                                          },
                                        },
                                      },
                                      {
                                        onSettled: () => {
                                          setDisable(false);
                                        },
                                      }
                                    );
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Divider />
                            <Grid container>
                              <Grid item xs={10}>
                                <Typography
                                  sx={{
                                    // fontFamily: MOVATIC_FONTS,
                                    // color: MOVATIC_FONT_COLOR,
                                    textDecoration: item.status ? 'line-through' : 'none',
                                  }}
                                  variant="caption"
                                >
                                  {item.description}
                                </Typography>
                                <Typography
                                  // sx={{
                                  //   fontFamily: MOVATIC_FONTS,
                                  //   color: MOVATIC_FONT_COLOR,
                                  // }}
                                  variant="caption"
                                >
                                  <Link
                                    onClick={() => {
                                      reportEvent('onboarding_setup', {
                                        action: 'clicked_learn_more',
                                        label: `Learn More ${item.title}`,
                                        system_name: system.name,
                                        system_id: system.gid,
                                      });
                                    }}
                                    target={'_blank'}
                                    underline={'none'}
                                    href={item?.documentationLink || ''}
                                  >
                                    <Typography
                                      // sx={{ fontFamily: MOVATIC_FONTS }}
                                      color={'secondary'}
                                      variant="caption"
                                    >
                                      {' Learn More'}
                                    </Typography>
                                  </Link>
                                </Typography>
                              </Grid>
                              {item.pageLink && (
                                <Grid item xs={2}>
                                  <IconButton
                                    id={`page-link-${item.id}`}
                                    color={'secondary'}
                                    onClick={() => {
                                      setOpen(!open);
                                      reportEvent('onboarding_setup', {
                                        action: 'clicked_page_link',
                                        label: `Clicked ${item.pageLink}`,
                                        system_name: system.name,
                                        system_id: system.gid,
                                      });
                                      history.push(item.pageLink);
                                    }}
                                    size={'medium'}
                                    sx={{
                                      color: theme.palette.text.primary,
                                      mt: 0.5,
                                    }}
                                  >
                                    <ArrowForwardOutlinedIcon color={'inherit'} />
                                  </IconButton>
                                </Grid>
                              )}
                            </Grid>
                          </Box>
                        </Card>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
              <CardActions
                sx={{
                  p: 0,
                  float: 'right',
                }}
              >
                <CheckBox
                  checkBoxId={'hide-setup'}
                  disabled={disable}
                  checked={hidden}
                  checkBoxLabel="Don't show this again"
                  onChange={() => {
                    setOpenConfirm(true);
                  }}
                />
              </CardActions>
            </Card>
            <>
              <MovaticCustomModal
                open={openConfirm}
                onClose={() => setOpenConfirm(!openConfirm)}
                content={<div>Are you sure you want to hide the setup?</div>}
                title={'Hide Setup'}
                customStyles={{
                  title: {
                    p: 2,
                  },
                  action: {
                    p: 2,
                  },
                  content: {
                    p: 2,
                  },
                }}
                action={
                  <>
                    <IconButtonMenu
                      buttons={[
                        {
                          label: 'cancel',
                          dataId: 'cancel',
                          onClick: () => setOpenConfirm(!openConfirm),
                        },
                        {
                          label: 'Submit',
                          dataId: 'submit-hidden',
                          onClick: () => {
                            const payload = onBoardingState.tips.map((item) => {
                              return {
                                id: item.id,
                                status: item.status,
                              };
                            });
                            updateSystemMutation.mutate(
                              {
                                ...system,
                                metadata: {
                                  onboarding: {
                                    hidden: true,
                                    tips: payload,
                                  },
                                },
                              },
                              {
                                onSettled: (r) => {
                                  reportEvent('onboarding_setup', {
                                    action: 'clicked_hide_setup',
                                    label: 'hide_setup',
                                    system_name: system.name,
                                    system_id: system.gid,
                                  });
                                  setHidden(r.metadata.onboarding.hidden);
                                  setDisable(true);
                                  setOpenConfirm(!openConfirm);
                                },
                              }
                            );
                          },
                        },
                      ]}
                    />
                  </>
                }
              />
            </>
          </>
        </Modal>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column-reverse',
            marginBottom: '10px',
            position: 'fixed',
            right: 0, // Adjust the 'right' property
            bottom: '1em',
            paddingRight: '16px',
          }}
        >
          <Badge
            id={'setup-badge'}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            variant="standard"
            badgeContent={pendingItems}
            color="error"
          >
            <Fab
              onClick={() => {
                setOpen(!open);
                reportEvent('onboarding_setup', {
                  action: 'open_badge',
                  label: 'open onboarding setup',
                  system_name: system.name,
                  system_id: system.gid,
                });
              }}
              variant="extended"
              color={'primary'}
            >
              <LightbulbIcon sx={{ mr: 1 }} />
              Get Started
            </Fab>
          </Badge>
        </div>
      )}
    </>
  );
};

export default SystemSetup;
