import { alpha } from '@mui/system/colorManipulator';
import type { PaletteOptions } from '@mui/material/styles/createPalette';

import type { ColorPreset, Contrast } from '..';
import { error, info, neutral, success, warning } from '../colors';

interface Config {
  colorPreset?: ColorPreset;
  contrast?: Contrast;
}

export const createPalette = (config: Config): PaletteOptions => {
  return {
    action: {
      active: '#00B7BE',
      disabled: alpha(neutral[100], 0.38),
      disabledBackground: alpha(neutral[100], 0.12),
      focus: alpha(neutral[100], 0.16),
      hover: alpha(neutral[100], 0.04),
      selected: alpha(neutral[400], 0.12),
    },
    background: {
      default: '#0B0F19',
      paper: neutral[900],
    },
    divider: '#2D3748',
    error,
    info,
    mode: 'dark',
    neutral,
    primary: {
      main: '#182F59',
      dark: '#4586c6',
      light: '#6C737F',
    },
    secondary: {
      main: '#00B7BE',
      dark: '#11192',
    },
    success,
    text: {
      primary: '#EDF2F7',
      secondary: '#FFFFFF',
      disabled: '#A0AEC0',
    },
    warning,
  };
};
