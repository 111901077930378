import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import TimePicker from 'react-bootstrap-time-picker';
import DatePicker from './datePicker';
import Button from './Button';

const Item = (props) => {
  const whatItemStyle = !props.clickFunc ? 'itemStyle' : 'itemStyleClickable';
  const { dataId } = props;

  return (
    <div
      className={`noDescriptionLabelGroup ${props.extraClassName || ''}`}
      data-id={dataId && `${dataId}-item`}
    >
      <Col sm={5} className="leftItem">
        <div className={`defaultLabel ${props.className ? props.className : ''}`}>
          {props.label}
          {props.isBeta ? (
            <span className="super movaticColor buttonFont">BETA</span>
          ) : null}
        </div>
      </Col>
      <Col className="rightItem" sm={7}>
        <div
          style={props.style ? props.style : null}
          className={`${whatItemStyle} ${
            props.customItemClass ? props.customItemClass : ''
          }`}
          onClick={() => {
            if (props.clickFunc) {
              props.clickFunc(props);
            }
          }}
        >
          {props.item}
          {props.clickFunc ? (
            <i id="table_item_carot" className="fa fa-caret-right" />
          ) : null}
        </div>
      </Col>
    </div>
  );
};

function Group(props) {
  return (
    <Row
      className={`add-label-row marginClear ${
        props.customRowClass ? props.customRowClass : ''
      }`}
      id={props.rowId && `row-${props.rowId}`}
      data-id={props.dataId}
    >
      <Form.Group controlId={props.id || props.name}>
        <Col sm={5} className="leftItem">
          <Col sm={12} className="field_group_label">
            <div
              className={`defaultLabel labelWithDescription ${props.customClass || ''}`}
            >
              {props.label}
              {props.isBeta ? (
                <span className="super movaticColor buttonFont">BETA</span>
              ) : null}
            </div>
          </Col>
          {props.description ? (
            <Col sm={12} className="field_group_label">
              <div className="itemStyle description"> {props.description}</div>
            </Col>
          ) : null}
        </Col>
        <Col className={`rightItem ${props.customFormClass || ''}`} sm={7}>
          {props.form}
          {props.help ? (
            <Form.Text data-id={props.helpDataId} className="error-form-text">
              {props.help}
            </Form.Text>
          ) : null}
        </Col>
      </Form.Group>
    </Row>
  );
}

function FieldGroupAdd(props) {
  return (
    <Group
      {...props}
      form={
        props.addOn ? (
          <InputGroup>
            <InputGroup.Text>{props.addOn}</InputGroup.Text>
            <Form.Control
              isInvalid={props.validationResult != null}
              key={props.key}
              max={props.max}
              min={props.min}
              name={props.name}
              data-id={props.dataIdInput}
              onChange={props.handleChange}
              placeholder={props.placeholder}
              step={props.step}
              type={props.type}
              value={props.value}
            />
          </InputGroup>
        ) : props.checked != null ? (
          <Form.Check
            checked={props.checked}
            disabled={props.disabled}
            name={props.name}
            data-id={props.dataIdInput}
            onChange={props.handleChange}
          />
        ) : props.dateTime ? (
          <div className="flexDisplay">
            <DatePicker
              className="date"
              onChange={props.handleDateChange}
              showClearButton={false}
              value={props.dateValue}
              isInvalid={props.validationResult}
            />
            <TimePicker
              className="halfWidth"
              onChange={props.handleTimeChange}
              step={5}
              value={props.timeValue}
            />
          </div>
        ) : props.date ? (
          <DatePicker
            className="date"
            onChange={props.handleDateChange}
            showClearButton={false}
            value={props.dateValue}
            isInvalid={props.validationResult}
          />
        ) : props.button ? (
          <Button
            buttons={1}
            customPrimaryClass={props.customPrimaryClass}
            primaryOnClick={props.primaryOnClick}
            primaryText={props.primaryText}
          />
        ) : (
          <Form.Control
            disabled={props.disabled}
            as={props.componentClass}
            isInvalid={props.validationResult != null}
            max={props.max}
            maxLength={props.maxLength}
            min={props.min}
            name={props.name}
            data-id={props.dataIdInput}
            onChange={props.handleChange}
            placeholder={props.placeholder}
            step={props.step}
            type={props.type}
            value={props.value}
          />
        )
      }
    />
  );
}

function FieldGroupSelect(props) {
  const options = [];
  if (props.maxRes === undefined) {
    for (let i = 0; i < props.options.length; i++) {
      if (props.options[i] !== undefined) {
        options.push(
          // key={currency.code} value={currency.code}>{currency.label}</option>)
          <option
            key={props.options[i].code ? props.options[i].code : i}
            value={
              props.options[i].value && props.options[i].seconds
                ? props.options[i].seconds
                : props.options[i].value || props.options[i].code
            }
          >
            {' '}
            {props.options[i].name ||
              props.options[i].label ||
              props.options[i].value}{' '}
          </option>
        );
      }
    }
  }

  const form = (
    <Form.Control
      name={props.name}
      as="select"
      value={props.value}
      onChange={props.handleChange}
      isInvalid={props.validationResult != null}
      data-id={props.dataId && `${props.dataId}-select`}
    >
      {props.maxRes ? props.options : options}
    </Form.Control>
  );

  return <Group {...props} form={form} />;
}

export { FieldGroupAdd, FieldGroupSelect, Group, Item };
