import {
  formatPhoneNumber,
  getLocaleDateString,
  history,
  timeForHumans,
  getLocaleDateTimeString,
} from '../utils';
import EditIcon from '@mui/icons-material/Edit';
import React from 'react';
import { SeverityPill } from '../components/Redesing/severity-pill';
import { Stack } from '@mui/system';
import IconButtonMenu from '../components/Redesing/icon-button-menu';
import ColumnAvatar from '../components/Redesing/column-avatar';
import { Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import CardMembershipIcon from '@mui/icons-material/CardMembership';

export function tableColumnsV2(columnTypes, membershipImages) {
  const commonColumns = [
    {
      id: 'name',
      label: 'Membership Name',
      searchable: true,
      format: (row) => {
        const logo = membershipImages?.find(
          (image) =>
            image.id === row?.logo_image_id &&
            (image.type === 'MembershipLogo' || image.type === 'SystemLogo')
        );
        const imageStyle = {
          height: '45px',
          maxWidth: '45px',
        };
        const name =
          columnTypes === 'pendingMemberships' ? row.membership.name : row.name;
        const prices =
          columnTypes === 'pendingMemberships' ? row.membership.prices : row.prices;
        return (
          <ColumnAvatar
            avatarColor={logo?.image ? 'none' : undefined}
            avatar={
              logo?.image ? (
                <img
                  alt=""
                  src={`data:image/png;base64,${logo.image}`}
                  style={{ ...imageStyle, borderRadius: '50%', objectFit: 'cover' }}
                />
              ) : (
                <CardMembershipIcon />
              )
            }
            firstRow={
              <Typography color="text.pramary" variant="body1">
                {name}
              </Typography>
            }
            secondRow={
              <Typography color="text.secondary" variant="body2">
                <SeverityPill color={'info'}>
                  {prices.length > 0 ? 'Paid' : 'Free'}
                </SeverityPill>
              </Typography>
            }
          />
        );
      },
    },
    {
      id: 'actions',
      label: 'Actions',
      width: '25%',
      shouldExport: false,
      disableClick: true,
      notSortable: true,
      hidden: false,
      noSort: true,
      format: (membership) => {
        const editDisable = membership.archived_on
          ? new Date(membership.archived_on) <= new Date()
          : false;
        return (
          <Stack justifyContent={'flex-start'} alignItems={'flex-start'}>
            <IconButtonMenu
              buttons={[
                {
                  label: '',
                  startIcon: <EditIcon />,
                  disabled: editDisable,
                  onClick: (e) => {
                    e.stopPropagation();
                    history.push(`/memberships/edit/${membership.id}`);
                  },
                },
              ]}
            />
          </Stack>
        );
      },
    },
    {
      id: 'status',
      label: 'Status',
      notSortable: true,
      format: (membership) => {
        if (membership.archived_on && new Date(membership.archived_on) <= new Date()) {
          return <SeverityPill color={'error'}>Archived</SeverityPill>;
        } else if (
          !membership.archived_on &&
          membership.active_on &&
          new Date(membership.active_on) > new Date()
        ) {
          return <SeverityPill color={'warning'}>Pending</SeverityPill>;
        } else {
          return <SeverityPill color={'success'}>Active</SeverityPill>;
        }
      },
    },
  ];

  if (
    columnTypes.includes('activeMemberships') ||
    columnTypes.includes('futureMemberships')
  ) {
    commonColumns.push({
      id: 'active_on',
      label: 'Active On',
      align: 'left',
      format: (row) => new Date(row.active_on).toLocaleDateString(),
    });
  }

  if (columnTypes.includes('historicMemberships')) {
    commonColumns.push({
      id: 'archived_on',
      label: 'Archived On',
      align: 'left',
      format: (cell) => new Date(cell.archived_on).toLocaleDateString(),
    });
  }

  if (columnTypes.includes('pendingMemberships')) {
    commonColumns.push(
      {
        id: 'userName',
        label: 'User Name',
        align: 'left',
        format: (row) => row.user.full_name,
        width: '25%',
      },
      {
        id: 'created_on',
        label: 'Request Date',
        align: 'left',
        format: (cell) => new Date(cell.created_on).toLocaleDateString(),
        width: '20%',
      }
    );
  }

  return commonColumns;
}

export const membershipUsersColumnsV2 = (authType) => [
  {
    id: 'user.full_name',
    label: 'User',
    showToolTip: true,
    shouldNotExport: true,
    format: (row) => {
      return (
        <ColumnAvatar
          avatar={<PersonIcon />}
          firstRow={
            <Typography color="text.pramary" variant="body1">
              {row.user.full_name}
            </Typography>
          }
          secondRow={
            <Typography color="text.secondary" variant="body2">
              <SeverityPill color={'info'}>
                {formatPhoneNumber(row.user.phone)}
              </SeverityPill>
            </Typography>
          }
        />
      );
    },
  },
  {
    id: 'user.phone',
    label: 'Phone #',
    searchable: true,
    exportFormat: (row) => formatPhoneNumber(row.user.phone),
    hidden: true,
  },
  {
    id: 'user.full_name',
    label: 'Name',
    exportFormat: (row) => row.user.full_name,
    hidden: true,
    searchable: true,
  },
  ...(authType === 'email'
    ? [
        {
          id: 'email',
          label: 'Email',
          searchable: true,
          exportFormat: (row) => row.email,
          format: (row) => row.email,
        },
      ]
    : []),
  {
    id: 'created_on',
    label: 'Joined',
    searchable: true,
    exportFormat: (row) =>
      getLocaleDateString(row.membership.auth_type ? row.approved_on : row.created_on),
    format: (row) =>
      getLocaleDateString(row.membership.auth_type ? row.approved_on : row.created_on),
  },
];

export const perksColumnsV2 = [
  {
    id: 'system_name',
    label: 'System',
    searchable: true,
  },
  {
    id: 'free_time',
    label: 'Free Time',
    searchable: true,
    format: (row) =>
      row?.free_time ? (
        timeForHumans(row.free_time)
      ) : (
        <SeverityPill color="info">None</SeverityPill>
      ),
  },
  {
    id: 'discount',
    label: 'Discount',
    searchable: true,
    format: (row) =>
      row?.discount ? `${row.discount}%` : <SeverityPill color="info">None</SeverityPill>,
  },
  {
    id: 'money_off',
    label: 'Money Off',
    searchable: true,
    format: (row) =>
      row?.money_off ? (
        `$${(row.money_off / 100).toFixed(2)}`
      ) : (
        <SeverityPill color="info">None</SeverityPill>
      ),
  },
  {
    id: 'waive_base_rate',
    label: 'Waive Base Rate',
    format: (row) =>
      row?.waive_base_rate ? (
        <SeverityPill color={'error'}>Yes</SeverityPill>
      ) : (
        <SeverityPill color={'success'}>No</SeverityPill>
      ),
  },
];

export const locationAccessColumnsV2 = [
  {
    id: 'station_name',
    label: 'Location',
    searchable: true,
  },
  {
    id: 'active_on',
    label: 'Active On',
    format: (row) => getLocaleDateTimeString(row.active_on),
    searchable: true,
  },
  {
    id: 'archived_on',
    label: 'Archived On',
    format: (row) => getLocaleDateTimeString(row.archived_on),
    searchable: true,
  },
];
