import { useQuery } from '@tanstack/react-query';
import { getRefunds } from '../../../api/transactions';

const useRefunds = (options?: {}) => {
  return useQuery(['refunds'], getRefunds, {
    ...options,
  });
};

export default useRefunds;
