import { useQuery } from '@tanstack/react-query';
import { getHoldTransactionDetails } from '../../../api/transactions';

const useHoldTransaction = (id: string, options?: {}) => {
  return useQuery(['holdTransaction', id], () => getHoldTransactionDetails(id), {
    ...options,
  });
};

export default useHoldTransaction;
