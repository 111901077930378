import * as React from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Stack from '@mui/material/Stack';
import TenantSwitch from '../system-partner/system-partner-section';
import { SideNavSection } from './side-nav-section';
import Button from '@mui/material/Button';
import { usePathname } from '../../hooks/use-pathname';
import { useTheme } from '@mui/material/styles';
import { FC, useContext, useEffect, useMemo, useRef } from 'react';
import { NavColor } from '../../ts/types/settings';
import { Section } from '../config';
import HelpIcon from '@mui/icons-material/Help';
import RoundedIconAvatar from '../../components/Redesing/rounded-icon-avatar';
import AccountInfo from '../../components/Redesing/account-info';
import Link from '@mui/material/Link';
import { SettingsContext } from '../../context/settings';

const drawerWidth = 280;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9.3)} + 2px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  })
);

interface SideNavProps {
  color?: NavColor;
  sections?: Section[];
}

const useCssVars = (color: NavColor): Record<string, string> => {
  const theme = useTheme();
  return useMemo((): Record<string, string> => {
    switch (color) {
      case 'blend-in':
        if (theme.palette.mode === 'dark') {
          return {
            '--nav-bg': theme.palette.background.default,
            '--nav-color': theme.palette.neutral[100],
            '--nav-border-color': theme.palette.neutral[700],
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[200],
            '--nav-item-color': theme.palette.neutral[200],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.text.primary,
            '--nav-item-disabled-color': theme.palette.neutral[600],
            '--nav-item-icon-color': theme.palette.neutral[500],
            '--nav-item-icon-active-color': theme.palette.secondary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[700],
            '--nav-item-chevron-color': theme.palette.neutral[700],
            '--nav-scrollbar-color': theme.palette.neutral[400],
          };
        } else {
          return {
            '--nav-bg': theme.palette.background.default,
            '--nav-color': theme.palette.text.primary,
            '--nav-border-color': 'rgb(255, 255, 255)',
            '--nav-logo-border': theme.palette.neutral[100],
            '--nav-section-title-color': theme.palette.neutral[200],
            '--nav-item-color': theme.palette.text.secondary,
            '--nav-item-hover-bg': theme.palette.action.hover,
            '--nav-item-active-bg': theme.palette.action.selected,
            '--nav-item-active-color': theme.palette.text.primary,
            '--nav-item-disabled-color': theme.palette.neutral[400],
            '--nav-item-icon-color': theme.palette.primary.main,
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[400],
            '--nav-item-chevron-color': theme.palette.neutral[400],
            '--nav-scrollbar-color': theme.palette.neutral[900],
          };
        }

      case 'discrete':
        if (theme.palette.mode === 'dark') {
          return {
            '--nav-bg': theme.palette.neutral[900],
            '--nav-color': theme.palette.neutral[100],
            '--nav-border-color': theme.palette.neutral[700],
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[200],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.text.primary,
            '--nav-item-disabled-color': theme.palette.neutral[600],
            '--nav-item-icon-color': theme.palette.neutral[500],
            '--nav-item-icon-active-color': theme.palette.secondary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[700],
            '--nav-item-chevron-color': theme.palette.neutral[700],
            '--nav-scrollbar-color': theme.palette.neutral[400],
          };
        } else {
          return {
            '--nav-bg': theme.palette.neutral[50],
            '--nav-color': theme.palette.text.primary,
            '--nav-border-color': theme.palette.divider,
            '--nav-logo-border': theme.palette.neutral[200],
            '--nav-section-title-color': theme.palette.neutral[500],
            '--nav-item-color': theme.palette.neutral[500],
            '--nav-item-hover-bg': theme.palette.action.hover,
            '--nav-item-active-bg': theme.palette.action.selected,
            '--nav-item-active-color': theme.palette.text.primary,
            '--nav-item-disabled-color': theme.palette.neutral[400],
            '--nav-item-icon-color': theme.palette.neutral[400],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[400],
            '--nav-item-chevron-color': theme.palette.neutral[400],
            '--nav-scrollbar-color': theme.palette.neutral[900],
          };
        }

      case 'evident':
        if (theme.palette.mode === 'dark') {
          return {
            '--nav-bg': theme.palette.neutral[800],
            '--nav-color': theme.palette.common.white,
            '--nav-border-color': 'transparent',
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[200],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.common.white,
            '--nav-item-disabled-color': theme.palette.neutral[500],
            '--nav-item-icon-color': theme.palette.neutral[400],
            '--nav-item-icon-active-color': theme.palette.secondary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[500],
            '--nav-item-chevron-color': theme.palette.neutral[600],
            '--nav-scrollbar-color': theme.palette.neutral[400],
          };
        } else {
          return {
            '--nav-bg': theme.palette.neutral[800],
            '--nav-color': theme.palette.common.white,
            '--nav-border-color': 'transparent',
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.common.white,
            '--nav-item-disabled-color': theme.palette.neutral[500],
            '--nav-item-icon-color': theme.palette.neutral[400],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[500],
            '--nav-item-chevron-color': theme.palette.neutral[600],
            '--nav-scrollbar-color': theme.palette.neutral[400],
          };
        }

      default:
        return {};
    }
  }, [theme, color]);
};

export const MiniDrawer: FC<SideNavProps> = (props) => {
  const context = useContext(SettingsContext);
  const [open, setOpen] = React.useState<boolean>(
    context.sideBarOpen === undefined ? true : context.sideBarOpen
  );
  const { color = 'evident', sections = [] } = props;
  const pathname = usePathname();
  const cssVars = useCssVars(color);
  const styledIconButtonRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  const borderColor = theme.palette.mode === 'light' ? 'rgb(255, 255, 255)' : null;

  useEffect(() => {
    setOpen(context.sideBarOpen === undefined ? true : context.sideBarOpen);
  }, [context.sideBarOpen]);

  const handleDrawerClose = () => {
    const actualState = !open;
    setOpen(actualState);
    context.handleUpdate({ sideBarOpen: actualState });
  };

  return (
    <Drawer
      id={'side-nav'}
      PaperProps={{
        sx: {
          ...cssVars,
          backgroundColor: 'var(--nav-bg)',
          borderRightColor: 'var(--nav-border-color)',
          borderRightStyle: 'solid',
          borderRightWidth: 1,
          color: 'var(--nav-color)',
          // width: SIDE_NAV_WIDTH,
        },
      }}
      variant="permanent"
      open={open}
      ref={styledIconButtonRef}
    >
      <DrawerHeader
        sx={{
          display: 'flex',
          alignItems: 'center', // Center vertically
          justifyContent: open ? 'flex-end' : 'center',
          marginRight: open ? '8px' : '0px',
        }}
      >
        {open && (
          <div
            style={{
              minWidth: '200px',
              maxHeight: '100px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              paddingLeft: '8px',
            }}
          >
            <img
              src={
                theme.palette.mode === 'light'
                  ? '/assets/movatic_LOGO-06.png'
                  : '/assets/movatic_LOGO-05.png'
              }
              alt="movatic-logo"
              style={{
                display: 'block', // Ensures the image takes up the full width
                marginRight: '8px', // Maintain the desired margin
                maxWidth: '150px',
                maxHeight: '100%', // Make the image responsive in height
              }}
            />
          </div>
        )}
        <IconButton onClick={handleDrawerClose}>
          {!open ? (
            <ChevronRightIcon
              sx={{
                color:
                  theme.palette.mode === 'light'
                    ? theme.palette.primary.main
                    : theme.palette.primary.contrastText,
                '&:focus': {
                  outline: 'none',
                },
                '&:focus-visible': {
                  outline: 'none',
                },
              }}
            />
          ) : (
            <ChevronLeftIcon
              sx={{
                color:
                  theme.palette.mode === 'light'
                    ? theme.palette.primary.main
                    : theme.palette.primary.contrastText,
                '&:focus': {
                  outline: 'none',
                },
                '&:focus-visible': {
                  outline: 'none',
                },
              }}
            />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider sx={{ borderColor }} />
      <Stack sx={{ height: '100%' }}>
        <Stack>
          <TenantSwitch open={open} sx={{ flexGrow: 1 }} />
        </Stack>
        <Divider sx={{ borderColor }} />
        <Stack
          component="nav"
          spacing={2}
          sx={{
            flexGrow: 1,
            pt: 2,
            px: 2,
          }}
        >
          {sections.map((section, index) => (
            <SideNavSection
              open={open}
              items={section.items}
              key={index}
              pathname={pathname}
              subheader={section.subheader}
            />
          ))}
        </Stack>
        <Divider sx={{ borderColor }} />
        <AccountInfo open={open} />
        <Divider sx={{ borderColor }} />
        {open ? (
          <Box sx={{ px: 3, py: 2 }}>
            <Typography variant="subtitle1">Need help?</Typography>
            <Typography color="text.secondary" sx={{ mb: 1 }} variant="body2">
              Please check our docs.
            </Typography>
            <Button
              sx={{
                bgcolor:
                  theme.palette.mode === 'light'
                    ? '#101C3D'
                    : theme.palette.secondary.main,
                '&:hover': {
                  textDecoration: 'none',
                  color: '#FFFFFF',
                },
              }}
              disableRipple
              fullWidth
              href="https://documentation.movatic.co/"
              target="_blank"
              startIcon={<i className="fa fa-book" aria-hidden="true" />}
              variant="contained"
            >
              <Typography variant={'subtitle2'}>Documentation</Typography>
            </Button>
          </Box>
        ) : (
          <Link href="https://documentation.movatic.co/" target="_blank">
            <RoundedIconAvatar icon={<HelpIcon />} />
          </Link>
        )}
      </Stack>
    </Drawer>
  );
};
