import React, { useCallback, useMemo, useState } from 'react';
import useUsers from '../../hooks/users/query/useUsers';
import { connect } from 'react-redux';
import { ISystem } from '../../ts/interface/system.interface';
import { IUserDetail } from '../../ts/interface/userDetail.interface';
import { formatPhoneNumber, getLocaleDateString, history } from '../../utils';
import {
  IColumn,
  ISortConfig,
} from '../../ts/interface/components/redesing/table.interface';
import PageContainer from '../../components/Redesing/page-container';
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import { PaginationTable } from '../../components/Redesing/table/pagination-table';
import { SeverityPill } from '../../components/Redesing/severity-pill';
import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SendIcon from '@mui/icons-material/Send';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ColumnAvatar from '../../components/Redesing/column-avatar';
import useDirectusData from '../../hooks/directus/query/useDirectusData';
import { DirectusData } from '../../ts/interface/directus';
import PersonIcon from '@mui/icons-material/Person';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants';
import useUrlState from '../../hooks/use-url-state';
import {
  MovaticFieldGroupSelectFilter,
  MovaticFieldGroupText,
} from '../../components/Redesing/movatic-field-group';
import { SearchChip, Option } from '../../ts/interface/components/table.interface';
import MovaticCustomModal from '../../components/Modal/MovaticCustomModal';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import { sendUserNotifications } from '../../actionCreators/system';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';

const filterOptions = [{ value: 'non-hidden', label: 'Non-Hidden' }];

const Index = ({ system, systemLoaded }: { system: ISystem; systemLoaded: boolean }) => {
  const [sortData, setSortData] = useState<ISortConfig>({
    key: 'fullName',
    direction: 'asc',
  });
  const [exportData, setExportData] = useState<boolean>(false);
  const [directusData, setDirectusData] = useState<DirectusData>({ space_name: '' });
  const [value, setValue] = useState<Option[]>([
    { label: 'Non-Hidden', value: 'non-hidden' },
  ]);
  const [chips, setChips] = useState<SearchChip[]>([
    {
      label: 'Configuration',
      field: 'configuration',
      value: 'non-hidden',
      displayValue: 'Non-Hidden',
      disabled: false,
    },
  ]);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationDescription, setNotificationDescription] = useState('');

  const [filters, setFilters] = useUrlState({
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    status: 'active',
    configuration: 'hidden',
    page: 0,
    search: '',
  });

  const { isLoading: loadingDirectus } = useDirectusData(
    'adminSiteSpace',
    { code: 'users' },
    true,
    {
      onSuccess: (dataXd: { value: DirectusData }) => {
        setDirectusData(dataXd.value);
      },
    }
  );

  const { data, isLoading: isLoadingUsers } = useUsers({ enabled: systemLoaded });
  const usersData = useMemo(() => {
    if (!data) {
      return [];
    }
    if (chips.some((item) => item.value === 'non-hidden')) {
      return data.filter((user: IUserDetail) => !user.hidden);
    }
    return data;
  }, [data, chips]);

  const columns: IColumn[] = [
    {
      id: 'fullName',
      label: 'User',
      searchable: true,
      showToolTip: true,
      maxWidth: '350px',
      exportFormat: (cell: IUserDetail) => cell.fullName,
      format: (cell: IUserDetail) => {
        return (
          <ColumnAvatar
            avatar={<PersonIcon />}
            firstRow={
              <Typography color="text.pramary" variant="body1">
                {cell.fullName}
              </Typography>
            }
            secondRow={
              <Typography color="text.secondary" variant="body2">
                <SeverityPill color={'info'}>{cell.emailAddress}</SeverityPill>
              </Typography>
            }
          />
        );
      },
    },
    {
      id: 'phone',
      label: 'Phone #',
      searchable: true,
      useFormatInSearch: true,
      exportFormat: (cell: IUserDetail) => formatPhoneNumber(cell.phone),
      format: (cell: IUserDetail) => formatPhoneNumber(cell.phone),
    },
    {
      id: 'joined',
      label: 'Joined',
      exportFormat: (cell: IUserDetail) => getLocaleDateString(cell.joined),
      format: (cell: IUserDetail) => getLocaleDateString(cell.joined),
    },
    {
      id: 'emailAddress',
      label: 'Email',
      hidden: true,
      searchable: true,
    },
  ];

  if (system.privateSystem) {
    columns.push(
      {
        id: 'has_membership',
        label: 'Membership',
        exportFormat: (cell: IUserDetail) => (cell.has_membership ? 'Yes' : 'No'),
        format: (cell: IUserDetail) => {
          const hasMembership = cell?.has_membership;
          return (
            <SeverityPill color={hasMembership ? 'success' : 'error'}>
              {hasMembership ? 'Active' : 'None'}
            </SeverityPill>
          );
        },
      },
      {
        id: 'banned',
        label: 'Status',
        exportFormat: (cell: IUserDetail) => (cell.banned ? 'Banned' : 'Active'),
        format: (cell: IUserDetail) => {
          const banned = cell?.banned;
          return (
            <SeverityPill color={banned ? 'error' : 'success'}>
              {banned ? 'Banned' : 'Active'}
            </SeverityPill>
          );
        },
      }
    );
  }

  const handleChipDelete = useCallback(
    (deletedGroup: SearchChip) => {
      setChips((prevChips) => {
        return prevChips.filter((group) => {
          return group.label !== deletedGroup.label;
        });
      });

      const chipToDelete = deletedGroup.label.toLowerCase() as 'configuration';

      setFilters((prevState) => ({
        ...prevState,
        page: 0,
        sizePerPage: DEFAULT_ROWS_PER_PAGE,
        [chipToDelete]: '',
      }));

      const deleteChip = {
        configuration: () => setValue([]),
      };

      if (deleteChip[chipToDelete]) {
        deleteChip[chipToDelete]();
      }
    },
    [setFilters]
  );

  const handleChipsChange = useCallback(
    (
      values: string[],
      list: { label: string; value: string }[],
      metadata: { label: string; value: string }
    ): void => {
      setFilters((prevState) => ({
        ...prevState,
        page: 0,
        sizePerPage: DEFAULT_ROWS_PER_PAGE,
        [metadata.value]: values.join(','),
      }));

      setChips((prevChips) => {
        const valuesFound: string[] = [];

        const newChips = prevChips.filter((chip) => {
          if (chip.field !== metadata.value) {
            return true;
          }

          const found = values.includes(chip.value as string);

          if (found) {
            valuesFound.push(chip.value as string);
          }

          return found;
        });

        if (values.length === valuesFound.length) {
          return newChips;
        }

        values.forEach((value) => {
          if (!valuesFound.includes(value)) {
            const option = list.find((option) => option.value.toString() === value);
            newChips.push({
              label: metadata.label,
              field: metadata.value,
              value,
              displayValue: option!.label,
            });
          }
        });
        return newChips;
      });
    },
    [setFilters]
  );

  const pageButtons = [
    {
      label: 'Export',
      onClick: () => setExportData(true),
      startIcon: <FileDownloadIcon />,
    },
    {
      startIcon: <AddIcon />,
      label: 'Add',
      onClick: () => history.push('/users/add'),
    },
    {
      label: 'Add CSV',
      onClick: () => history.push('/users/add_csv'),
      startIcon: <FileUploadIcon />,
    },
    ...(system.partner !== 0
      ? [
          {
            label: 'Send notifications',
            onClick: () => setOpenFilterModal(true),
            startIcon: <SendIcon />,
          },
        ]
      : []),
  ];

  return (
    <PageContainer
      csvReport={{
        filename: 'Users.csv',
        data: usersData || [],
        headers: [
          'fullName',
          'emailAddress',
          'phone',
          'joined',
          'has_membership',
          'banned',
        ],
      }}
      onImportClick={() => history.push('/users/add_csv')}
      importLabel={'Add CSV'}
      spaceName={'Users'}
      documentationLink={directusData?.documentation_link}
    >
      <Card>
        <PaginationTable
          chips={chips}
          handleChipDelete={handleChipDelete}
          isLoading={loadingDirectus || isLoadingUsers || !systemLoaded}
          onApplyFilter={() => {
            if (JSON.stringify(value) !== JSON.stringify(filters.configuration)) {
              handleChipsChange(
                [
                  ...value
                    .filter((option) =>
                      filterOptions.some((item) => item.label === option.label)
                    )
                    .map((item) => item.value.toString()),
                ],
                filterOptions,
                { label: 'Configuration', value: 'configuration' }
              );
            }
          }}
          spaceFilters={
            <>
              <MovaticFieldGroupSelectFilter
                label={'Status'}
                value={value}
                onChange={(event: React.SyntheticEvent, newValue: any[]) => {
                  setValue([
                    ...newValue.filter((option) =>
                      filterOptions.some((item) => item.label === option.label)
                    ),
                  ]);
                }}
                options={filterOptions}
              />
            </>
          }
          exportData={exportData}
          csvFileName={'Users.csv'}
          onExport={() => setExportData(false)}
          buttons={pageButtons}
          padding={{
            pl: -1,
            pt: 1,
            pr: 1,
          }}
          onRowCLick={(id: string) => history.push(`/users/${id}/general`)}
          customKey={'phone'}
          handleSearch={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFilters((prevState) => ({ ...prevState, search: e.target.value }))
          }
          onSortChange={(sort) => setSortData(sort as ISortConfig)}
          sortBy={sortData.key}
          sortDirection={sortData.direction}
          searchText={filters.search}
          columns={columns as IColumn[]}
          items={usersData || []}
          onPageChange={(page: any, pageN: number) =>
            setFilters((prevState) => ({ ...prevState, page: pageN }))
          }
          onRowsPerPageChange={(row: any) =>
            setFilters((prevState) => ({
              ...prevState,
              rowsPerPage: Number(row.target.value),
            }))
          }
          page={filters.page}
          rowsPerPage={filters.rowsPerPage}
          searchPlaceholder={'Search users'}
        />
      </Card>
      <MovaticCustomModal
        customStyles={{
          title: {
            p: 2,
          },
          action: {
            p: -2,
          },
          content: {
            p: 2,
          },
        }}
        open={openFilterModal}
        onClose={() => setOpenFilterModal(false)}
        content={
          <div>
            <Typography variant="body1">
              This action will send notifications to all users
            </Typography>
            <MovaticFieldGroupText
              id="chargePendingDescriptionGroup"
              label="Title (Required)"
              value={notificationTitle || ''}
              onChange={(e) => setNotificationTitle(e.target.value)}
              description="The name of the system will be prepended to this title."
            />
            <MovaticFieldGroupText
              multiline
              rows={2}
              id="chargePendingDescriptionGroup"
              label="Description (Required)"
              value={notificationDescription || ''}
              onChange={(e) => setNotificationDescription(e.target.value)}
            />
          </div>
        }
        title={'Send notifications'}
        action={
          <IconButtonMenu
            buttons={[
              {
                label: 'Cancel',
                onClick: () => setOpenFilterModal(false),
              },
              {
                label: 'Send',
                onClick: () => {
                  CustomAsyncToast({
                    promise: () =>
                      sendUserNotifications(notificationTitle, notificationDescription),
                    successMessage: () => 'The notifications were sent successfully',
                    loadingMessage: 'Sending notifications...',
                    errorMessage: 'There was an error sending the notifications',
                  }).then(() => {
                    setOpenFilterModal(false);
                    setNotificationTitle('');
                    setNotificationDescription('');
                  });
                },
                disabled: !notificationTitle || !notificationDescription,
              },
            ]}
          />
        }
      />
    </PageContainer>
  );
};

const UsersR = connect(
  (state: { system: { current: ISystem; isLoaded: boolean } }) => ({
    system: state.system.current,
    systemLoaded: state.system.isLoaded,
  }),
  () => ({})
)(Index);

export default UsersR;
