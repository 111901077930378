import type { FC, ReactNode } from 'react';

import { useSections } from './config';
import { VerticalLayout } from './default-layout';
import { useNprogress } from '../hooks/use-nprogress';
import { useSettings } from '../hooks/use-settings';
import { connect } from 'react-redux';
import { ISystem } from '../ts/interface/system.interface';
import { NavigationContextProvider } from '../context/navigation-context';

interface LayoutProps {
  children?: ReactNode;
  system: ISystem;
  systemAccess: number;
}

const LayoutComp: FC<LayoutProps> = (props) => {
  const sections = useSections({
    system: props.system,
    systemAccess: props.systemAccess,
  });
  const settings = useSettings();
  useNprogress();
  return (
    <VerticalLayout
      // @ts-ignore
      sections={sections}
      navColor={settings.navColor}
      {...props}
    />
  );
};

const WrappedLayout: FC<LayoutProps> = (props) => {
  return (
    <NavigationContextProvider initialSystemId={props.system.id}>
      <LayoutComp {...props}>{props.children}</LayoutComp>
    </NavigationContextProvider>
  );
};

const DashboardLayout = connect(
  (state: {
    system: { current: ISystem; isLoaded: boolean };
    admin: { systemAccess: number };
  }) => ({
    system: state.system.current,
    systemLoaded: state.system.isLoaded,
    systemAccess: state.admin.systemAccess,
  }),
  () => ({})
)(WrappedLayout);

export default DashboardLayout;
