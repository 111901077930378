import React, { useCallback, useMemo, useState } from 'react';
import { tableColumnsV2 } from '../../../tableColumns/membershipColumns';
import { history, toTitleCase } from '../../../utils';
import { PaginationTable } from '../../../components/Redesing/table/pagination-table';
import Card from '@mui/material/Card';
import { MovaticFieldGroupSingleSelectFilter } from '../../../components/Redesing/movatic-field-group';
import { ISortConfig } from '../../../ts/interface/components/redesing/table.interface';
import { SearchChip } from '../../../ts/interface/components/table.interface';
import AddIcon from '@mui/icons-material/Add';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import useMembershipImages from '../../../hooks/membership/query/useMembershipImages';
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants';
import { MenuItem } from '@mui/material';
import { IMembership, IFilters } from '../../../ts/interface/membership.interface';
import { useUpdateEffect } from '../../../hooks/use-update-effect';

interface IMembershipListProps {
  memberships: IMembership[];
  isLoading: boolean;
  hasMore: boolean;
  filters: IFilters;
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>;
  isFilterComplete: boolean;
  isRestoring: boolean;
  onPageChange: (page: number) => void;
  onSizePerPageChange: (sizePerPage: number) => void;
}

const filterOptions = [
  { value: 'active', label: 'Active' },
  { value: 'archived', label: 'Archived' },
  { value: 'future', label: 'Future' },
];

const MembershipList: React.FC<IMembershipListProps> = (props) => {
  const {
    memberships,
    isLoading,
    hasMore,
    filters,
    setFilters,
    isFilterComplete,
    onSizePerPageChange,
    onPageChange,
    isRestoring,
  } = props;

  const [chips, setChips] = useState<SearchChip[]>(
    filters.status
      ? [
          {
            label: 'Status',
            field: 'status',
            value: filters.status,
            displayValue: toTitleCase(filters.status),
          },
        ]
      : []
  );

  const [value, setValue] = React.useState<any>({
    value: filters.status,
    label: toTitleCase(filters.status),
  });
  const [exportData, setExportData] = useState<boolean>(false);

  const hasMoreMemo = useMemo(() => {
    const { sizePerPage, pageNumber } = filters;
    return !Boolean(hasMore) && memberships?.length >= sizePerPage * (pageNumber + 1)
      ? true
      : hasMore;
  }, [hasMore, memberships, filters]);

  const { data: membershipImages } = useMembershipImages();

  const sorting: ISortConfig = useMemo(() => {
    return {
      key: filters.sortConfig.key || 'name',
      direction: filters.sortConfig.direction || 'asc',
    };
  }, [filters.sortConfig.key, filters.sortConfig.direction]);

  useUpdateEffect(() => {
    if (isRestoring) {
      setFilters((prev) => ({
        ...prev,
        pageNumber: 0,
        sizePerPage: DEFAULT_ROWS_PER_PAGE,
      }));

      if (Boolean(filters.status)) {
        setChips([
          {
            label: 'Status',
            field: 'status',
            value: filters.status,
            displayValue: toTitleCase(filters.status),
            disabled: false,
          },
        ]);
        setValue({
          value: filters.status,
          label: toTitleCase(filters.status),
        });
      } else {
        setChips([]);
        setValue({});
      }
    }
  }, [isRestoring]);

  const columns = useCallback(() => {
    return tableColumnsV2(filters.status, membershipImages);
  }, [filters, membershipImages]);

  const handleStatusChange = useCallback(
    (value: string): void => {
      setFilters((prevState) => ({
        ...prevState,
        pageNumber: 0,
        sizePerPage: DEFAULT_ROWS_PER_PAGE,
        status: value,
        lastId: null,
      }));

      setChips([
        {
          label: 'Status',
          field: 'status',
          value,
          displayValue: toTitleCase(value),
        },
      ]);
    },
    [setFilters]
  );

  const handleChipDelete = useCallback(
    (deletedGroup: SearchChip) => {
      setChips((prevState) => {
        return prevState.filter((group) => group.label !== deletedGroup.label);
      });
      setValue({});
      setFilters((prevState) => ({
        ...prevState,
        status: '',
        pageNumber: 0,
        sizePerPage: DEFAULT_ROWS_PER_PAGE,
        lastId: null,
      }));
    },
    [setChips, setValue, setFilters]
  );

  return (
    <Card>
      <PaginationTable
        showFilter
        isLoading={isLoading || !isFilterComplete}
        padding={{
          pr: 1,
          pt: 1,
        }}
        sortBy={sorting.key}
        onSortChange={(sort) =>
          setFilters((prevState) => ({
            ...prevState,
            sortConfig: sort as ISortConfig,
            lastId: null,
          }))
        }
        items={memberships}
        noDataText={`There are no memberships at this time`}
        onRowCLick={(id: string) => history.push(`/memberships/${id}/general`)}
        chips={chips}
        handleChipDelete={handleChipDelete}
        onApplyFilter={() => {
          handleStatusChange(value.label);
        }}
        spaceFilters={
          <MovaticFieldGroupSingleSelectFilter
            label={'Status'}
            value={value.value}
            onChange={(newValue) => {
              const selectedOption = filterOptions.find(
                (option) => option.value === newValue.target.value
              );
              setValue({
                value: selectedOption?.value,
                label: selectedOption?.label,
              });
            }}
            options={filterOptions.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          />
        }
        buttons={[
          {
            label: 'Export',
            onClick: () => setExportData(true),
            startIcon: <FileDownloadIcon />,
          },
          {
            label: 'Add',
            onClick: () => history.push('/memberships/add'),
            startIcon: <AddIcon />,
            variant: 'contained',
          },
        ]}
        localSearch={false}
        columns={columns()}
        exportData={exportData}
        csvFileName={'Memberships.csv'}
        onExport={() => setExportData(false)}
        rowsPerPage={filters.sizePerPage}
        hasMore={hasMoreMemo}
        totalItems={memberships?.length}
        page={filters.pageNumber}
        onPageChange={(page, pageN: number) => onPageChange(pageN)}
        onRowsPerPageChange={(rowsPerPage) =>
          onSizePerPageChange(Number(rowsPerPage.target.value))
        }
        searchText={filters.search}
        searchPlaceholder={`Search memberships`}
        handleSearch={(search: React.ChangeEvent<HTMLInputElement>) =>
          setFilters((prevState) => ({
            ...prevState,
            search: search.target.value,
            lastId: null,
          }))
        }
      />
    </Card>
  );
};

export default MembershipList;
