import FormItem from '../../../components/Redesing/form-item';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import CardView from '../../../components/Redesing/card-view';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import SaveIcon from '@mui/icons-material/Save';
import { Stack } from '@mui/system';
import { MovaticFieldGroupSelect } from '../../../components/Redesing/movatic-field-group';

const currencies = require('../../../../data/currencies.json');

function content(props) {
  return (
    <div>
      <MovaticFieldGroupSelect
        label={'Currency'}
        name={'currencyCode'}
        optionNodes={currencies
          .map((item) => ({
            value: item.code,
            label: item.label,
          }))
          .map((option) => (
            <MenuItem id={'currencyCode'} key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        value={props.info.currencyCode || ''}
        onChange={props.handleCurrencyChange}
        id={'currencyCode'}
      />

      <FormItem
        label="Tax Rate"
        content={
          <OutlinedInput
            fullWidth
            id="tax_rate"
            inputProps={{ step: 0.1, max: 100, min: 0 }}
            type="number"
            placeholder="0"
            value={props.info.tax_rate || ''}
            onChange={props.handleChange}
            startAdornment={<InputAdornment position="start">%</InputAdornment>}
          />
        }
      />
    </div>
  );
}

export default function BillingCard(props) {
  return (
    <CardView
      headerActions={
        <IconButtonMenu
          buttons={[
            {
              label: 'Save',
              startIcon: <SaveIcon />,
              onClick: () => {
                props.submit({
                  currencyCode: props.info.currencyCode,
                  tax_rate: props.info.tax_rate,
                  card: 'billingSettings',
                });
              },
              disabled:
                !props.saveButtonLoading || (!props.bankAccess && !props.movaticAccess),
            },
          ]}
        />
      }
      content={
        <Stack px={2} pb={2}>
          {content(props)}
        </Stack>
      }
      title={'Details'}
    />
  );
}
