import { connect } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { getAllProblems } from '../../api/problems';
import { getNavigationStats } from '../../actionCreators/stats';
import { problemsColumns } from '../../tableColumns/problemColumns.jsx';
import { changeTab, displayError, history } from '../../utils';
import { IProblem, ITag } from '../../ts/interface/problem.interface';
import PageContainer from '../../components/Redesing/page-container';
import { PaginationTable } from '../../components/Redesing/table/pagination-table';
import {
  FilterChips,
  IColumn,
} from '../../ts/interface/components/redesing/table.interface';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card as CardMui } from '@mui/material';
import { MovaticFieldGroupSelectFilter } from '../../components/Redesing/movatic-field-group';
import { SearchChip } from '../../ts/interface/components/table.interface';
import { useUpdateEffect } from '../../hooks/use-update-effect';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { getCredentials } from '../../api/http';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants';
import { useParams } from 'react-router-dom';
import PageContent from '../../components/Redesing/page-content';
import ProblemsTags from './problems-tags';
import useTags from '../../hooks/supportTickets/query/useTags';

const filterOptions = [
  { value: 0, label: 'Open', disabled: true },
  { value: 1, label: 'Closed' },
];

interface IFilters {
  label: string;
  value: string;
}

function Problems(props: { systemLoaded: boolean }) {
  const { systemId } = getCredentials();
  const [search, setSearch] = React.useState('');
  const [pageNumber, setPageNumber] = React.useState(0);
  const [sizePerPage, setSizePerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);
  const [sortData, setSortData] = useState<string>('created');
  const { currentTab = 'tickets' } = useParams<{ currentTab: string }>();
  const [currentTabState, setCurrentTabState] = useState(currentTab);
  const [tagValues, setTagValues] = useState<IFilters[]>([]);

  const { systemGid } = getCredentials();
  const { data = [], isLoading } = useTags(systemGid, { enabled: props.systemLoaded });
  const loadedTags = useMemo(
    () =>
      data.map((item: ITag) => {
        return {
          label: item.name,
          value: item.name,
        };
      }),
    [data]
  );

  const [chips, setChips] = useState<SearchChip[]>([
    {
      label: 'Status',
      field: 'status',
      value: '0',
      displayValue: 'Open',
      disabled: false,
    },
  ]);
  const [exportData, setExportData] = useState<boolean>(false);
  const [filterChips, setFilterChips] = useState<FilterChips>({
    status: ['0'],
    tags: [],
  } as FilterChips);

  const [value, setValue] = React.useState<IFilters[]>([{ label: 'Open', value: '0' }]);

  useEffect(() => {
    if (currentTab !== currentTabState) {
      setCurrentTabState(currentTab);
    }
  }, [currentTab, currentTabState]);
  const handleTabsChange = useCallback(
    (event: React.SyntheticEvent, value: React.SetStateAction<string>) => {
      changeTab(value, currentTab, 'problems');
    },
    [currentTab]
  );

  const query = useQuery(['problems', systemId], getAllProblems, {
    enabled: props.systemLoaded,
    onError: (error) => {
      displayError(
        'Oops! Looks like there was an error while getting support tickets requests. Please try again later.',
        error
      );
    },
  });

  const filterProblems = useMemo(() => {
    return (
      query.data
        ?.filter((problem: IProblem) =>
          filterChips && filterChips.status && filterChips?.status?.length > 0
            ? //@ts-ignore
              filterChips.status?.includes(problem.state.toString())
            : true
        )
        .filter((problem: IProblem) => {
          return filterChips.tags && filterChips.tags.length > 0
            ? //@ts-ignore
              problem.tags.some((tag) => filterChips.tags.includes(tag))
            : true;
        }) || []
    );
  }, [filterChips, query.data]);

  const handleChipsUpdate = useCallback(() => {
    const filtersAux: FilterChips = {
      status: [],
      tags: [],
    };
    chips.forEach((chip) => {
      switch (chip.field) {
        case 'status':
          // @ts-ignore
          filtersAux.status.push(chip.value);
          break;
        case 'tags':
          // @ts-ignore
          filtersAux.tags.push(chip.value);
          break;
        default:
          break;
      }
    });
    setFilterChips(filtersAux);
  }, [chips]);

  const handleStatusChange = useCallback(
    (values: string[], list: any[], metadata: { label: string; value: string }): void => {
      setPageNumber(0);
      setSizePerPage(10);
      setChips((prevChips) => {
        const valuesFound: string[] = [];

        const newChips = prevChips.filter((chip) => {
          if (chip.field !== metadata.value) {
            return true;
          }

          const found = values.includes(chip.value as string);

          if (found) {
            valuesFound.push(chip.value as string);
          }

          return found;
        });

        if (values.length === valuesFound.length) {
          return newChips;
        }

        values.forEach((value) => {
          if (!valuesFound.includes(value)) {
            const option = list.find((option) => option.value.toString() === value);
            newChips.push({
              label: metadata.label,
              field: metadata.value,
              value,
              displayValue: option!.label,
            });
          }
        });
        return newChips;
      });
    },
    []
  );

  useUpdateEffect(() => {
    handleChipsUpdate();
  }, [chips, handleChipsUpdate]);

  const handleChipDelete = useCallback(
    (deletedGroup: SearchChip) => {
      setChips((prevChips) => {
        return prevChips.filter((group) => {
          return group.label !== deletedGroup.label;
        });
      });
      setValue([]);
      if (deletedGroup.label === 'Status') {
        setValue([]);
      }
      if (deletedGroup.label === 'Tags') {
        setTagValues([]);
      }
      handleChipsUpdate();
    },
    [handleChipsUpdate]
  );

  const contentNew = () => {
    return (
      <CardMui>
        <PaginationTable
          isLoading={query.isLoading || !props.systemLoaded}
          padding={{
            pr: 1,
            pt: 1,
          }}
          exportData={exportData}
          csvFileName={'Support_tickets.csv'}
          onExport={() => setExportData(false)}
          buttons={[
            {
              label: 'Export',
              onClick: () => setExportData(true),
              startIcon: <FileDownloadIcon />,
            },
          ]}
          customKey={'id'}
          onRowCLick={(id: string) => history.push(`/problems/${id}`)}
          sortDirection={'desc'}
          showFilter
          chips={chips}
          handleChipDelete={handleChipDelete}
          onApplyFilter={() => {
            if (JSON.stringify(value) !== JSON.stringify(filterChips.status)) {
              handleStatusChange(
                [
                  ...value
                    .filter((option) =>
                      filterOptions.some((item) => item.label === option.label)
                    )
                    .map((item) => item.value.toString()),
                ],
                filterOptions,
                { label: 'Status', value: 'status' }
              );
            }

            if (JSON.stringify(tagValues) !== JSON.stringify(filterChips.tags)) {
              handleStatusChange(
                tagValues.map((item) => item.value.toString()),
                loadedTags,
                { label: 'Tags', value: 'tags' }
              );
            }
          }}
          spaceFilters={
            <>
              <MovaticFieldGroupSelectFilter
                label={'Status'}
                value={value}
                onChange={(event: React.SyntheticEvent, newValue: any[]) => {
                  setValue([
                    ...newValue.filter((option) =>
                      filterOptions.some((item) => item.label === option.label)
                    ),
                  ]);
                }}
                options={filterOptions}
              />
              <MovaticFieldGroupSelectFilter
                label={'Tags'}
                isLoading={isLoading}
                value={tagValues}
                onChange={(event, newValue) => {
                  setTagValues([
                    ...newValue.filter((option) =>
                      loadedTags.some(
                        (item: { label: string }) => item.label === option.label
                      )
                    ),
                  ]);
                }}
                options={loadedTags}
              />
            </>
          }
          sortBy={sortData}
          onSortChange={(sort) => setSortData(sort.key)}
          handleSearch={(search) => setSearch(search.target.value)}
          searchText={search}
          columns={problemsColumns() as IColumn[]}
          items={filterProblems || []}
          onPageChange={(page, pageN) => {
            setPageNumber(pageN);
          }}
          onRowsPerPageChange={(row) => {
            const pageSize = Number(row.target.value);
            setSizePerPage(pageSize);
          }}
          page={pageNumber}
          rowsPerPage={sizePerPage}
          searchPlaceholder={'Search'}
          noDataText={`No support tickets`}
        />
      </CardMui>
    );
  };

  return (
    <PageContainer spaceName={'Support Tickets'}>
      <PageContent
        detailedView={false}
        currentTabV={currentTabState}
        handleTabsChange={handleTabsChange}
        tabsList={[
          {
            label: 'Tickets',
            value: 'tickets',
          },
          {
            label: 'Tags',
            value: 'tags',
          },
        ]}
        tabsChildren={
          <>
            {currentTabState === 'tickets' && contentNew()}
            {currentTabState === 'tags' && <ProblemsTags />}
          </>
        }
      />
    </PageContainer>
  );
}

export default connect(
  (state: any) => ({
    systemLoaded: state.system.isLoaded,
  }),
  (dispatch: Function) => ({
    getNavigationStats() {
      dispatch(getNavigationStats());
    },
  })
)(Problems);
