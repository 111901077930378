import { useQuery } from '@tanstack/react-query';
import { baseUrlV2 } from '../../../api/http';
import { getIssuingCardResource } from '../../../api/issuingCard';

const useIssuingCardBalance = (options?: {}) => {
  return useQuery(
    ['issuingbalance'],
    () =>
      getIssuingCardResource(
        `${baseUrlV2}/billing/billing/issuingcard/balance`,
        true,
        'Oops! Looks like there was an error while getting the issuing card balance. Please try again later.'
      ),
    { ...options }
  );
};

export default useIssuingCardBalance;
