import React from 'react';
import CardView from '../../../components/Redesing/card-view';
import FormItem from '../../../components/Redesing/form-item';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import SaveIcon from '@mui/icons-material/Save';
import { Stack } from '@mui/system';

function button(props) {
  const { saveButtonLoading, creditCardHold, submit } = props;

  return (
    <IconButtonMenu
      buttons={[
        {
          label: 'Save',
          startIcon: <SaveIcon />,
          onClick: () =>
            submit({
              creditCardHold,
              card: 'creditCardHold',
            }),
          disabled: !saveButtonLoading,
        },
      ]}
    />
  );
}

function content(props) {
  const { handleChange, creditCardHold } = props;

  return (
    <div>
      <FormItem
        label="Credit Card Hold"
        description="The credit card hold amount that users on this system will be applied in order to start a rental. Can be overriden by Membership and Rates."
        content={
          <OutlinedInput
            fullWidth
            id="creditCardHold"
            name="creditCardHold"
            inputProps={{ step: 0.25, min: 0 }}
            type="number"
            placeholder="0"
            value={creditCardHold || ''}
            onChange={handleChange}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
        }
      />
    </div>
  );
}

export default function CreditCardHoldCard(props) {
  return (
    <CardView
      title="Credit Card Hold"
      content={
        <Stack px={2} pb={2}>
          {content(props)}
        </Stack>
      }
      headerActions={button(props)}
    />
  );
}
