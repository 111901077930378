import { getLocaleDateTimeString } from '../utils';
import { SeverityPill } from '../components/Redesing/severity-pill';

export const disputeColumns = [
  {
    id: 'created',
    label: 'Created',
    format: (row) =>
      row.created ? (
        getLocaleDateTimeString(row.created)
      ) : (
        <SeverityPill color={'info'}>N/A</SeverityPill>
      ),
  },
  {
    id: 'amount',
    label: 'Amount',
    format: (row) => {
      return row.amount ? row.amount : <SeverityPill color={'info'}>N/A</SeverityPill>;
    },
  },
  {
    id: 'status',
    label: 'Status',
    format: (row) => {
      const color =
        row.status === 'Won' ? 'success' : row.status === 'Lost' ? 'error' : 'warning';
      return row.status ? (
        <SeverityPill color={color}>{row.status}</SeverityPill>
      ) : (
        <SeverityPill color={'info'}>N/A</SeverityPill>
      );
    },
  },
  {
    id: 'charge_id',
    label: 'Charge',
    width: '20%',
    format: (row) => {
      return row ? row.charge_id : <SeverityPill color={'info'}>N/A</SeverityPill>;
    },
  },
  {
    id: 'reason',
    label: 'Reason',
    format: (row) => {
      return row.reason ? row.reason : <SeverityPill color={'info'}>N/A</SeverityPill>;
    },
  },
];
