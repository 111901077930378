import React, { ReactNode } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

interface InfoDatePickerProps {
  label: string | ReactNode;
  description?: string;
  content?: ReactNode;
}

const FormItem: React.FC<InfoDatePickerProps> = ({ label, description, content }) => {
  return (
    <Grid container spacing={2} paddingY={1}>
      <Grid item xs={5}>
        <Stack>
          <Typography gutterBottom variant="subtitle1">
            {label}
          </Typography>
          {description && (
            <Typography color="text.secondary" variant="body2">
              {description}
            </Typography>
          )}
        </Stack>
      </Grid>
      <Grid item xs={7}>
        {content}
      </Grid>
    </Grid>
  );
};

export default FormItem;
