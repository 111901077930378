import React, { useMemo, useState } from 'react';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import useWalletTransaction from '../../../hooks/transaction/query/useWalletTransaction';
import useHoldTransaction from '../../../hooks/transaction/query/useHoldTransaction';
import useTransaction from '../../../hooks/transaction/query/useTransaction';
import RefundModal from './refundModal';
import {
  convertCentsToMoney,
  getLocaleDateTimeString,
  getPricingString,
  history,
  timeForHumans,
  toTitleCase,
} from '../../../utils';

import {
  IDetailedCardTransaction,
  IDetailedHoldTransaction,
  IDetailedWalletTransaction,
  IRefunds,
} from '../../../ts/interface/rental.interface';
import { ITransactionInformationProps } from '../../../ts/interface/pages/rentals.interface';
import PendingChargesModal from '../../billing/pendingChargesModal';
import { CardHeader } from '@mui/material';
import { PropertyList } from '../../../components/Redesing/property-list';
import { PropertyListItem } from '../../../components/Redesing/property-list-item';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import CardView from '../../../components/Redesing/card-view';
import { SeverityPill } from '../../../components/Redesing/severity-pill';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';

const TransactionInformation = ({
  walletTransactionId,
  holdTransactionId,
  transactionId,
  rental,
  admins,
  movaticAccess,
  refundAdminPermission,
}: ITransactionInformationProps) => {
  const [showRefund, setShowRefund] = useState(false);
  const [showChargePendingModal, setShowChargePendingModal] = useState(false);
  const { data: detailedWalletTransaction, isLoading: isLoadingWallet } =
    useWalletTransaction(walletTransactionId, {
      enabled: Boolean(walletTransactionId),
    });
  const { data: detailedHoldTransaction, isLoading: isLoadingHold } = useHoldTransaction(
    holdTransactionId,
    {
      enabled: Boolean(holdTransactionId),
    }
  );
  const { data: transactionData, isLoading: isLoadingTransaction } = useTransaction(
    transactionId,
    {
      enabled: Boolean(transactionId),
    }
  );
  const detailedTransaction = useMemo<IDetailedCardTransaction>(
    () => transactionData || ({} as IDetailedCardTransaction),
    [transactionData]
  );

  const isLoading =
    (isLoadingWallet && Boolean(walletTransactionId)) ||
    (isLoadingHold && Boolean(holdTransactionId)) ||
    (isLoadingTransaction && Boolean(transactionId));

  const issueRefund = () => {
    let refunds = {} as IRefunds;
    let amount = 0;
    let refunded = false;
    if (detailedTransaction) {
      refunded = refunded || detailedTransaction.refunded;
      amount += detailedTransaction.amount ?? 0;
      refunds = detailedTransaction.refunds || [];
    }
    let walletTransactionId = null;
    let holdChargeId = null;
    if (detailedWalletTransaction) {
      refunded = refunded || detailedWalletTransaction.refunded;
      amount += detailedWalletTransaction.amount ?? 0;
      walletTransactionId = detailedWalletTransaction.id;
    }
    if (detailedHoldTransaction) {
      const holdTransaction = detailedHoldTransaction;
      const holdCharge =
        (holdTransaction.charges?.data?.length && holdTransaction.charges.data[0]) || {};

      if (holdTransaction.status !== 'requires_capture') {
        refunded = refunded || (!holdTransaction.canceled_at && holdCharge.refunded);
        amount += holdTransaction.amount_received ?? 0;
        holdChargeId = holdCharge.id;
      }
    }

    const dispute =
      rental.billing?.transactions?.find(
        ({ type, source }) => type === 'charge' && source === 'stripe'
      )?.dispute_id || null;

    if (!refunded && amount > 0 && (!dispute || !(refunds?.data?.length > 0))) {
      if (detailedTransaction?.status !== 'failed') {
        if (detailedTransaction?.id || walletTransactionId || holdChargeId) {
          return {
            label: 'Issue Refund',
            onClick: () => setShowRefund(!showRefund),
            disabled: !!dispute || (!refundAdminPermission && !movaticAccess),
            startIcon: <KeyboardReturnIcon />,
          };
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const TransactionInformationButtonGroup = () => {
    const buttons = [];
    const isChargePending = rental.billing?.pending_charge?.pending;
    if (isChargePending) {
      buttons.push({
        label: 'Pending Charge',
        onClick: () => setShowChargePendingModal(!showChargePendingModal),
        startIcon: <PendingActionsIcon />,
      });
    }
    const refundButton = issueRefund();
    if (refundButton) {
      buttons.push(refundButton);
    }
    return <IconButtonMenu buttons={buttons} />;
  };

  const transactionClick = (event: { item: string }) => {
    history.push(`/billing/transactions/${event.item}`);
  };

  const transactionContent = () => {
    // have both wallet transaction and charge
    return renderWalletAndCCTransaction();
  };

  const renderTransactionsSummary = (
    walletTransaction: IDetailedWalletTransaction,
    cardTransaction: IDetailedCardTransaction,
    holdTransaction: IDetailedHoldTransaction
  ) => {
    const holdCharge = holdTransaction && holdTransaction.charges.data[0];
    const holdStatus =
      holdTransaction &&
      (holdTransaction.status === 'requires_capture'
        ? 'Ready for capture'
        : toTitleCase(holdTransaction.status));

    return (
      <div>
        {/* wallet transaction inf */}
        {!!walletTransaction?.id && (
          <div>
            <CardHeader
              sx={{ pl: 2, pt: 3 }}
              title={
                <div style={{ minHeight: '36px', display: 'flex', alignItems: 'center' }}>
                  Wallet
                </div>
              }
            />
            <PropertyList>
              <PropertyListItem
                align="horizontal"
                divider
                label={'Amount'}
                value={convertCentsToMoney(getWalletTotal(walletTransaction))}
              />
              <PropertyListItem align="horizontal" divider label={'Charge Id'}>
                <Link onClick={() => transactionClick({ item: walletTransaction.id })}>
                  <Typography color="text.secondary" variant="subtitle1">
                    {walletTransaction.id}
                    <i id="table_item_carot" className="fa fa-caret-right" />
                  </Typography>
                </Link>
              </PropertyListItem>
              <PropertyListItem
                divider
                align="horizontal"
                label={'Description'}
                value={walletTransaction.description}
              />
              <PropertyListItem
                divider
                align="horizontal"
                label={'Status'}
                value={toTitleCase(walletTransaction.status)}
              />
            </PropertyList>
            {walletTransaction.refunded ? (
              <>
                <CardHeader
                  sx={{ pl: 2, pt: 3 }}
                  title={
                    <div
                      style={{ minHeight: '36px', display: 'flex', alignItems: 'center' }}
                    >
                      Wallet Refund
                    </div>
                  }
                />
                <PropertyList>
                  <PropertyListItem
                    divider
                    align="horizontal"
                    label={'Amount'}
                    value={convertCentsToMoney(walletTransaction.amount_refunded)}
                  />
                  <PropertyListItem
                    divider
                    align="horizontal"
                    label={'Description'}
                    value={walletTransaction.refund_description}
                  />
                </PropertyList>
              </>
            ) : (
              !walletTransaction.refunded &&
              !!walletTransaction.amount_refunded && (
                <>
                  <CardHeader
                    sx={{ pl: 2, pt: 3 }}
                    title={
                      <div
                        style={{
                          minHeight: '36px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        Wallet Refund
                      </div>
                    }
                  />
                  <PropertyList>
                    <PropertyListItem
                      align="horizontal"
                      divider
                      label="Refund Status"
                      value="Pending"
                    />
                    <PropertyListItem
                      align="horizontal"
                      divider
                      label={'Amount'}
                      value={convertCentsToMoney(walletTransaction.amount_refunded)}
                    />
                    <PropertyListItem
                      align="horizontal"
                      divider
                      label={'Description'}
                      value={walletTransaction.refund_description}
                    />
                  </PropertyList>
                </>
              )
            )}
          </div>
        )}
        {/* stripe transaction info */}
        {cardTransaction?.id && (
          <div>
            <Divider />
            <CardHeader
              sx={{ pl: 2, pt: 3 }}
              title={
                <div style={{ minHeight: '36px', display: 'flex', alignItems: 'center' }}>
                  Credit Card - {cardTransaction.payment_method_details.card.last4}
                </div>
              }
            />
            <PropertyList>
              <PropertyListItem align="horizontal" divider label={'Charge Id'}>
                <Link onClick={() => transactionClick({ item: cardTransaction?.id })}>
                  <Typography color="text.secondary" variant="subtitle1">
                    {cardTransaction?.id}
                    <i id="table_item_carot" className="fa fa-caret-right" />
                  </Typography>
                </Link>
              </PropertyListItem>

              <PropertyListItem
                align="horizontal"
                divider
                label={'Amount'}
                value={convertCentsToMoney(cardTransaction.amount)}
              />
              <PropertyListItem
                align="horizontal"
                divider
                label={'Description'}
                value={cardTransaction.description}
              />
              <PropertyListItem
                align="horizontal"
                divider
                label={'Status'}
                value={toTitleCase(cardTransaction.status)}
              />
            </PropertyList>
            {cardTransaction?.refunds?.data?.length > 0 && (
              <>
                {cardTransaction.refunds.data.map((refund, index) => (
                  <div key={index}>
                    <CardHeader
                      sx={{ pl: 2, pt: 3 }}
                      title={
                        <div
                          style={{
                            minHeight: '36px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {`Credit Card - ${cardTransaction.payment_method_details.card.last4} Refund`}
                        </div>
                      }
                    />
                    <PropertyList key={index}>
                      <PropertyListItem
                        align="horizontal"
                        divider
                        label={'Refund ID'}
                        value={refund.id}
                      />
                      <PropertyListItem
                        align="horizontal"
                        divider
                        label={'Refunded On'}
                        value={new Date(refund.created * 1000).toLocaleString()}
                      />
                      <PropertyListItem
                        align="horizontal"
                        divider
                        label={'Refund Status'}
                        value={toTitleCase(refund.status)}
                      />
                      <PropertyListItem
                        align="horizontal"
                        divider
                        label={'Amount'}
                        value={convertCentsToMoney(refund.amount)}
                      />
                      <PropertyListItem
                        align="horizontal"
                        divider
                        label={'Description'}
                        value={refund.metadata.description}
                      />
                    </PropertyList>
                  </div>
                ))}
              </>
            )}
          </div>
        )}
        {holdTransaction?.id && (
          <div>
            <CardHeader
              sx={{ pl: 2, pt: 3 }}
              title={
                <div style={{ minHeight: '36px', display: 'flex', alignItems: 'center' }}>
                  Credit Card Hold - {holdTransaction.payment_method.card.last4}
                </div>
              }
            />
            <PropertyList>
              <PropertyListItem align="horizontal" divider label={'Charge Id'}>
                <Link
                  onClick={() =>
                    holdCharge ? transactionClick({ item: holdCharge.id }) : null
                  }
                >
                  <Typography color="text.secondary" variant="subtitle1">
                    {holdCharge ? (
                      <>
                        {holdCharge.id}
                        <i id="table_item_carot" className="fa fa-caret-right" />
                      </>
                    ) : (
                      holdTransaction.id
                    )}
                  </Typography>
                </Link>
              </PropertyListItem>
              <PropertyListItem
                align="horizontal"
                divider
                label={'Hold Amount'}
                value={convertCentsToMoney(holdTransaction.amount)}
              />
              <PropertyListItem
                align="horizontal"
                divider
                label={'Captured Amount'}
                value={convertCentsToMoney(holdTransaction.amount_received)}
              />

              <PropertyListItem
                align="horizontal"
                divider
                label={'Status'}
                value={holdStatus}
              />

              <PropertyListItem
                align="horizontal"
                label={'Description'}
                value={holdTransaction.description}
              />
            </PropertyList>
            {holdCharge &&
              holdCharge.refunds.data.length > 0 &&
              holdTransaction.status !== 'canceled' && (
                <>
                  {holdCharge.refunds.data.map((refund, index) => (
                    <>
                      <Divider />
                      <CardHeader
                        sx={{ pl: 2, pt: 3 }}
                        title={
                          <div
                            style={{
                              minHeight: '36px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {`Credit Card Hold - ${holdCharge.payment_method_details.card.last4} Refund`}
                          </div>
                        }
                      />
                      <PropertyList key={index}>
                        <PropertyListItem
                          align="horizontal"
                          divider
                          label={'Refund ID'}
                          value={refund.id}
                        />
                        <PropertyListItem
                          align="horizontal"
                          divider
                          label={'Refunded On'}
                          value={new Date(refund.created * 1000).toLocaleString()}
                        />
                        <PropertyListItem
                          align="horizontal"
                          divider
                          label={'Refund Status'}
                          value={toTitleCase(refund.status)}
                        />
                        <PropertyListItem
                          align="horizontal"
                          divider
                          label={'Amount'}
                          value={convertCentsToMoney(refund.amount)}
                        />
                        <PropertyListItem
                          align="horizontal"
                          divider
                          label={'Description'}
                          value={refund.metadata.description}
                        />
                      </PropertyList>
                    </>
                  ))}
                </>
              )}
          </div>
        )}
      </div>
    );
  };

  const getWalletTotal = (walletTransaction: IDetailedWalletTransaction) => {
    let total = walletTransaction?.amount || 0;
    if (walletTransaction?.charge_total && walletTransaction.charge_total !== '') {
      total = parseInt(walletTransaction.charge_total, 10);
    }

    return total;
  };

  const renderWalletAndCCTransaction = () => {
    const walletTransaction = detailedWalletTransaction;
    const transaction = detailedTransaction;
    const holdTransaction = detailedHoldTransaction;
    const pendingChargeDeclined = rental.billing?.pending_charge?.status === false;
    const totalAmount = pendingChargeDeclined ? 0 : rental.billing?.total;
    const rate = rental.billing?.rate;
    const perk = rental.billing?.perk;
    const fees = rental.billing?.fees || [];

    const [preTaxFeeItems, postTaxFeeItems] = fees.reduce(
      (feesArray, customFee) => {
        feesArray[customFee.applied_post_tax ? 1 : 0].push(
          //@ts-ignore
          <PropertyListItem
            align="horizontal"
            key={customFee.id}
            value={convertCentsToMoney(customFee.amount)}
            label={customFee.name}
          />
        );
        return feesArray;
      },
      [[], []]
    );

    const formatCoupon = (value: number, type: string) => {
      switch (type?.toLowerCase()) {
        case 'money':
          return `${convertCentsToMoney(value)} off`;
        case 'percent':
          return `${value}% off`;
        case 'time':
          return `${timeForHumans(value)} free`;
        default:
          return 'N/A';
      }
    };
    const formattedCoupon = formatCoupon(
      rental?.billing?.coupon?.discount as number,
      rental?.billing?.coupon?.type as string
    );

    const getDuration = () => {
      if (rental?.duration === null) {
        return 'Rental is ongoing';
      }
      const duration = timeForHumans(rental.duration);
      return duration === 0 ? '0 Seconds' : duration;
    };

    const UsageDetails = () => {
      return (
        <>
          <PropertyListItem
            align="horizontal"
            data-id={'rental-duration'}
            value={getDuration()}
            label="Rental Duration"
          />
          {/* Rate info */}
          {rate?.id ? (
            <div>
              <Divider />
              <PropertyListItem align="horizontal" label={'Rate'}>
                <Link onClick={() => history.push(`/billing/rates/${rate.old_id}`)}>
                  <Typography color="text.secondary" variant="subtitle1">
                    {rate.name}
                    <i id="table_item_carot" className="fa fa-caret-right" />
                  </Typography>
                </Link>
                <br />
                <Typography color="text.secondary" variant="subtitle1">
                  {getPricingString(rate)}
                </Typography>
              </PropertyListItem>
            </div>
          ) : null}
          {/* perk info */}
          {perk?.id ? (
            <div>
              <Divider />
              <PropertyListItem align="horizontal" label={'Membership'}>
                <Link
                  onClick={() =>
                    history.push(`/memberships/${perk.membership_id}/general`)
                  }
                >
                  <Typography color="text.secondary" variant="subtitle1">
                    {perk.membership_name}
                    <i id="table_item_carot" className="fa fa-caret-right" />
                  </Typography>
                </Link>
                {perk.free_time ? (
                  <>
                    <br />

                    <Typography color="text.secondary" variant="subtitle1">
                      {`${timeForHumans(perk.free_time)} free`}
                    </Typography>
                  </>
                ) : null}
                {perk.money_off ? (
                  <>
                    <br />

                    <Typography color="text.secondary" variant="subtitle1">
                      {`$${(perk.money_off / 100).toFixed(2)} Off`}
                    </Typography>
                  </>
                ) : null}
                {perk.discount ? (
                  <>
                    <br />

                    <Typography color="text.secondary" variant="subtitle1">
                      {`${perk.discount}% Off`}
                    </Typography>
                  </>
                ) : null}
              </PropertyListItem>
            </div>
          ) : null}
          {/* coupon info */}
          {rental.billing?.coupon?.id ? (
            <div>
              <Divider />
              <PropertyListItem align="horizontal" label={'Coupon'}>
                <Link
                  onClick={() =>
                    history.push(`/billing/coupon/${rental.billing.coupon.old_id}`)
                  }
                >
                  <Typography color="text.secondary" variant="subtitle1">
                    {rental.billing.coupon.name}
                    <i id="table_item_carot" className="fa fa-caret-right" />
                  </Typography>
                </Link>
                <br />

                <Typography color="text.secondary" variant="subtitle1">
                  {formattedCoupon}
                </Typography>
              </PropertyListItem>
            </div>
          ) : null}
        </>
      );
    };

    if (rental.ended_within_grace_period) {
      return (
        <>
          <UsageDetails />
          <Divider />
          <PropertyListItem align="horizontal" data-id={'rental-duration'} label="">
            <SeverityPill color={'info'}>Ended within the grace period.</SeverityPill>
          </PropertyListItem>
        </>
      );
    }

    if (rental.billing?.void_charge) {
      const voidedByAdminName = rental?.billing?.void_by?.full_name || 'Admin';
      const voidedByAdminEmail = rental?.billing?.void_by?.email;
      const voidedOn = rental?.billing?.voided_on;
      const voidedReason = rental?.billing?.void_reason;
      const voidByAdminIsOnSystem = !!admins.find(
        (admin: { email: string }) => admin.email === voidedByAdminEmail
      );

      return (
        <>
          <UsageDetails />
          <Divider />
          {voidedOn ? (
            <PropertyListItem
              align="horizontal"
              label="Voided on"
              value={getLocaleDateTimeString(voidedOn)}
            />
          ) : null}
          <PropertyListItem align="horizontal" divider label={'Voided by'}>
            <Link
              onClick={() =>
                voidByAdminIsOnSystem
                  ? () => history.push(`/settings/admin/${voidedByAdminEmail}`)
                  : null
              }
            >
              <Typography color="text.secondary" variant="subtitle1">
                {voidedByAdminName +
                  (voidByAdminIsOnSystem ? '' : ` (${voidedByAdminEmail})`)}
                <i id="table_item_carot" className="fa fa-caret-right" />
              </Typography>
            </Link>
            <br />

            <Typography color="text.secondary" variant="subtitle1">
              {formattedCoupon}
            </Typography>
          </PropertyListItem>
          {voidedReason && (
            <PropertyListItem
              align="horizontal"
              label="Voided reason"
              value={voidedReason}
            />
          )}
        </>
      );
    }

    return (
      <div>
        <UsageDetails />
        {/* General info */}
        {!!rental.end?.time && (
          <>
            <Divider />
            <PropertyListItem
              align="horizontal"
              label="Usage Subtotal"
              value={convertCentsToMoney(rental?.billing?.usage_sub_total)}
            />
            {!!rental.billing?.parking_credit && (
              <PropertyListItem
                align="horizontal"
                label={
                  rental.billing.parking_credit < 0 ? 'Parking Credit' : 'Parking Fee'
                }
                value={convertCentsToMoney(rental.billing.parking_credit)}
              />
            )}
            <>{preTaxFeeItems}</>
            {!!rental.billing?.admin_modification_reason && (
              <PropertyListItem align="horizontal" label="Admin Modification">
                <Typography color="text.secondary" variant="subtitle1">
                  {convertCentsToMoney(rental.billing.admin_modification)}
                </Typography>
                {!!rental.billing?.admin_modification_reason && (
                  <>
                    <br />
                    <Typography color="text.secondary" variant="subtitle1">
                      {rental.billing.admin_modification_reason}
                    </Typography>
                  </>
                )}
              </PropertyListItem>
            )}
            <Divider />
            <PropertyListItem align="horizontal" label="Subtotal">
              <Typography color="text.secondary" variant="subtitle1">
                {convertCentsToMoney(rental.billing.sub_total)}
              </Typography>
              {rental.billing.pending_charge?.pending ? (
                <>
                  <br />

                  <Typography color="text.secondary" variant="subtitle1">
                    Pending admin approval
                  </Typography>
                </>
              ) : null}
              {rental.billing.pending_charge?.decision_date ? (
                <>
                  <br />

                  <Typography color="text.secondary" variant="subtitle1">
                    {`${
                      rental.billing.pending_charge.status ? 'Accepted' : 'Declined'
                    } by admin on ${getLocaleDateTimeString(
                      rental.billing.pending_charge.decision_date
                    )}`}
                  </Typography>
                  <br />

                  <Typography color="text.secondary" variant="subtitle1">
                    {rental.billing.pending_charge.note}
                  </Typography>
                </>
              ) : null}
            </PropertyListItem>
            {typeof rental.billing.tax_rate === 'number' && (
              <>
                <PropertyListItem
                  align="horizontal"
                  value={`${convertCentsToMoney(rental.billing.tax_amount)} (${
                    rental.billing.tax_rate.toFixed(2) // TODO: Remove this rounding once this ticket gets resolved: https://movatic.atlassian.net/browse/BUGS-150
                  }%)`}
                  label="Tax"
                />
              </>
            )}
            <>{postTaxFeeItems}</>
            <Divider />
            <PropertyListItem
              align="horizontal"
              value={convertCentsToMoney(totalAmount)}
              label={'Total'}
            />
          </>
        )}
        {(walletTransaction?.id || transaction?.id || holdTransaction?.id) && (
          <div>
            {renderTransactionsSummary(walletTransaction, transaction, holdTransaction)}
          </div>
        )}
        <PendingChargesModal
          handleModal={(value: boolean) => setShowChargePendingModal(value)}
          rentalId={rental.old_id}
          rentalAmount={rental.billing?.sub_total}
          show={showChargePendingModal}
        />
      </div>
    );
  };

  return (
    <div>
      <CardView
        headerActions={TransactionInformationButtonGroup()}
        isLoading={isLoading}
        content={
          <>
            {transactionContent()}
            {showRefund && (
              <RefundModal
                show={showRefund}
                userName={rental.user?.full_name}
                close={(redirect?: boolean) => {
                  setShowRefund(false);
                  if (redirect) {
                    history.push(`/rentals`);
                  }
                }}
                rentalId={rental.id}
                chargeId={detailedTransaction?.id}
                walletTransactionId={detailedWalletTransaction?.id}
                refundType={'rental'}
                amount={rental.billing?.total}
                cents={detailedTransaction?.cents || 0}
              />
            )}
          </>
        }
        title={'Transaction Details'}
      />
    </div>
  );
};

export default TransactionInformation;
