import React, { useState, useEffect } from 'react';
import { useFormik, yupToFormErrors } from 'formik';
import FeeForm from './feeForm';
import { history, convertCentFieldsToDollars } from '../../../utils';
import { getFees, updateFee } from '../../../api/fees';
import feeSchema from './feeSchema';
import CustomToast from '../../../components/Redesing/custom-toast';
import PageContainer from '../../../components/Redesing/page-container';
import CardView from '../../../components/Redesing/card-view';
import { Stack } from '@mui/system';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import SaveIcon from '@mui/icons-material/Save';
import CustomAsyncToast from '../../../components/Redesing/custom-async-toast';

const Buttons = (props) => {
  return (
    <IconButtonMenu
      buttons={[
        {
          label: 'Cancel',
          onClick: () => history.push('/billing/fees'),
        },
        {
          label: 'Save',
          onClick: props.submitFee,
          startIcon: <SaveIcon />,
        },
      ]}
    />
  );
};

const EditFee = (props) => {
  const [fee, setFee] = useState(null);
  const formik = useFormik({
    initialValues: fee ?? feeSchema.default(),
    validationSchema: feeSchema,
    validate: (values) => {
      return feeSchema
        .validate(values, { abortEarly: false, context: fee })
        .then(() => null)
        .catch((err) => {
          return yupToFormErrors(err);
        });
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      CustomAsyncToast({
        promise: () =>
          updateFee(
            props.match.params.feeId,
            feeSchema.cast(values, { stripUnknown: true })
          ),
        successMessage: () => 'The fee has been edited successfully!',
        loadingMessage: () => 'Editing fee...',
        errorMessage: () => 'There was an error editing the fee.',
      }).then((result) => {
        if (result) {
          history.push(`/billing/fees/${props.match.params.feeId}`);
        }
      });
    },
  });

  useEffect(() => {
    getFees({ fee_id: props.match.params.feeId }).then((response) => {
      const feeObject = response?.length && response[0];
      if (feeObject) {
        const parsedFeeObject = convertCentFieldsToDollars(feeObject, [
          'amount',
          'applied_above_sub_total_threshold',
          'applied_under_sub_total_threshold',
        ]);

        setFee(parsedFeeObject);
      } else {
        CustomToast({
          type: 'error',
          message:
            'There was an error loading the fee to edit. It looks like the fee ID in the URL does not match an existing fee.',
        });
      }
    });
  }, [props.match.params.feeId]);

  return (
    <>
      <PageContainer>
        <CardView
          content={
            <Stack px={2} pb={2}>
              <FeeForm formik={formik} />
            </Stack>
          }
          title={'Edit Fee'}
          headerActions={<Buttons submitFee={formik.handleSubmit} />}
        />
      </PageContainer>
    </>
  );
};

export default EditFee;
