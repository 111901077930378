import React from 'react';
import DialogActions from "@mui/material/DialogActions";

const ModalAction = ({children, customStyle}) => {
    return (
        <DialogActions sx={customStyle}>
            {children}
        </DialogActions>
    );
};

export default ModalAction;
