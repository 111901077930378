import { history } from '../utils';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import React from 'react';
import { SeverityPill } from '../components/Redesing/severity-pill';

export const couponColumns = [
  {
    id: 'name',
    label: 'Name',
    format: (row) => {
      return row.name ? row.name : <SeverityPill color={'info'}>N/A</SeverityPill>;
    },
  },
  {
    id: 'active',
    label: 'Status',
    format: (row) => {
      return row.active ? (
        <SeverityPill color={'success'}>Active</SeverityPill>
      ) : (
        <SeverityPill color={'error'}>Inactive</SeverityPill>
      );
    },
  },
  {
    id: 'actions',
    label: 'Actions',
    shouldNotExport: true,
    format: (row) => {
      return (
        <div style={{ display: 'flex', justifyContent: 'flex-start', maxWidth: '10px' }}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              history.push(`/billing/coupon/edit/${row.id}`);
            }}
          >
            <EditIcon />
          </IconButton>
        </div>
      );
    },
    notSortable: true,
  },
  {
    id: 'code',
    label: 'Code',
    format: (row) => {
      return row.code ? row.code : <SeverityPill color={'info'}>N/A</SeverityPill>;
    },
  },
  {
    id: 'discount',
    label: 'Discount',
    format: (row) => {
      return row.discount ? (
        row.discount
      ) : (
        <SeverityPill color={'info'}>N/A</SeverityPill>
      );
    },
  },

  {
    id: 'duration',
    label: 'Valid',
    format: (row) => {
      if (row.duration == null) {
        return row.uses;
      }
      return row.duration;
    },
  },
];
