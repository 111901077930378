import React, { useMemo, useState } from 'react';
import {
  convertCentsToMoney,
  displaySuccess,
  formatPhoneNumber,
  getLocaleDateTimeString,
  toTitleCase,
} from '../../../../utils';
import {
  IUserDetail,
  UserDetailStatus,
} from '../../../../ts/interface/userDetail.interface';
import { PropertyList } from '../../../../components/Redesing/property-list';
import { PropertyListItem } from '../../../../components/Redesing/property-list-item';
import { ISystem } from '../../../../ts/interface/system.interface';
import isEmpty from 'lodash/isEmpty';
import MovaticCustomModal from '../../../../components/Modal/MovaticCustomModal';
import UserCreditWaller from '../../userCreditWaller';
import AddStripeCard from '../../../../components/AddStripeCard';
import { IUserCard } from '../../../../ts/interface/userCard.interface';
import { IUserWallet } from '../../../../ts/interface/userWallet.interface';
import useUserCard from '../../../../hooks/users/query/useUserCard';
import useUserWallet from '../../../../hooks/users/query/useUserWallet';
import {
  clearUserBalance,
  getUserStripeSetupIntent,
  sendTosEmail,
  updateUserDelinquency,
} from '../../../../api/users';
import { useParams } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import PaymentIcon from '@mui/icons-material/Payment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CardView from '../../../../components/Redesing/card-view';
import IconButtonMenu from '../../../../components/Redesing/icon-button-menu';
import {
  MovaticFieldGroupOutlinedInput,
  MovaticFieldGroupRadio,
  MovaticFieldGroupText,
} from '../../../../components/Redesing/movatic-field-group';
import CustomAsyncToast from '../../../../components/Redesing/custom-async-toast';
import { createCharge } from '../../../../api/manualCharge';
import SaveIcon from '@mui/icons-material/Save';
import useIsMobileDevice from '../../../../hooks/mobileDevices/useIsMobileDevice';
import { useMutation } from '@tanstack/react-query';

import { SeverityPill } from '../../../../components/Redesing/severity-pill';

interface GeneralUserInfoBannerProps {
  userDetails: IUserDetail;
  privateSystem: boolean;
  system: ISystem;
  onSuccess: Function;
  paymentInfoDocumentationLink?: string;
}

export enum IChargeRecipient {
  SYSTEM = 'system',
  PARTNER = 'partner',
}

const GeneralUserInfoBanner = ({
  userDetails,
  privateSystem,
  system,
  onSuccess,
  paymentInfoDocumentationLink,
}: GeneralUserInfoBannerProps) => {
  const {
    appVersion,
    autoGenNumber,
    deviceModel,
    deviceOS,
    deviceOSVersion,
    phone,
    amount_due,
    joined,
    emailAddress,
    acceptedTOSOn,
  } = userDetails;

  const deviceOSInfo = deviceOS ? `- ${deviceOS} (${deviceOSVersion})` : '';
  const deviceInfoString = deviceModel ? `${deviceModel} ${deviceOSInfo}` : '';
  const usesWallet = system.wallet_required;
  const { userPhone = '' } = useParams<{ userPhone: string }>();
  const [showChargeModal, setShowChargeModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showCreditUserModal, setShowCreditUserModal] = useState(false);
  const [showAddCreditCardModal, setShowAddCreditCardModal] = useState(false);
  const [showClearBalanceModal, setShowClearBalanceModal] = useState(false);
  const [chargeAmount, setChargeAmount] = useState('');
  const [chargeAmountDescription, setChargeAmountDescription] = useState('');
  const isMobile = useIsMobileDevice();
  const isMedium = useIsMobileDevice(1300);
  const [userCard, setUserCard] = useState<IUserCard>({});
  const [userWallet, setUserWallet] = useState<IUserWallet>({} as IUserWallet);
  const [chargeRecipient, setChargeRecipient] = useState<IChargeRecipient>(
    IChargeRecipient.SYSTEM
  );

  const calculateArrayBtns = useMemo(() => {
    return [
      {
        label: 'Charge Card',
        dataId: 'charge-card-button',
        onClick: () => setShowChargeModal(true),
        startIcon: <PaymentIcon />,
        shouldRender: Boolean(userCard && !userCard.delinquent),
      },
      {
        label: 'Credit',
        onClick: () => setShowCreditUserModal(true),
        dataId: 'credit-user-button',
        startIcon: <PaymentIcon />,
        shouldRender: Boolean(usesWallet) && !isEmpty(userWallet),
      },
      {
        label: 'Set Non-Delinquent',
        dataId: 'set-non-delinquent-button',
        onClick: () => setShowConfirmationModal(true),
        startIcon: <CheckCircleIcon />,
        shouldRender: Boolean(userCard && userCard.delinquent),
      },
      {
        label: 'Add Card',
        dataId: 'add-card-button',
        onClick: () => setShowAddCreditCardModal(true),
        startIcon: <AddIcon />,
        shouldRender: Boolean(isEmpty(userCard)),
      },
      {
        label: 'Clear Balance',
        dataId: 'clear-balance-button',
        onClick: () => setShowClearBalanceModal(true),
        startIcon: <CloseIcon />,
        shouldRender: Boolean(
          userDetails.amount_due != null && userDetails.amount_due > 0
        ),
      },
    ];
  }, [usesWallet, userWallet, userCard, userDetails.amount_due]);

  const { isLoading: isLoadingCard, refetch: refetchUserCard } = useUserCard(userPhone, {
    onSuccess: (r: IUserCard) => setUserCard(r),
  });
  const { refetch: refetchWallet } = useUserWallet(userPhone, {
    onSuccess: (r: IUserWallet) => setUserWallet(r),
  });
  const sendTosEmailMutation = useMutation({
    mutationFn: sendTosEmail,
  });

  const resetChargeModalFields = () => {
    setChargeAmount('');
    setChargeAmountDescription('');
    setChargeRecipient(IChargeRecipient.SYSTEM);
    setShowChargeModal(false);
  };

  const handleRadioConfigurationChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setChargeRecipient(value as IChargeRecipient);
  };

  const getStripeClientSecret = async () => {
    const response = await getUserStripeSetupIntent(userPhone);
    return response?.client_secret;
  };

  return (
    <>
      <CardView
        headerActions={
          !acceptedTOSOn && (
            <IconButtonMenu
              buttonLimit={isMobile ? 0 : 1}
              slice={isMobile ? 0 : 1}
              buttons={[
                {
                  label: 'Send ToS Email',
                  onClick: () => sendTosEmailMutation.mutate(userDetails.id),
                  startIcon: <i className={'fa fa-envelope'} />,
                  disabled: sendTosEmailMutation.isLoading,
                },
              ]}
            />
          )
        }
        content={
          <PropertyList>
            <PropertyListItem divider label={'Status'}>
              {userDetails.banned ||
              userDetails?.status === UserDetailStatus.BLACKLISTED ? (
                <SeverityPill color={'error'}>Banned</SeverityPill>
              ) : (
                <SeverityPill color={'success'}>Authorized</SeverityPill>
              )}
            </PropertyListItem>
            <PropertyListItem divider label={'Email'} value={emailAddress} />
            <PropertyListItem
              divider
              label={autoGenNumber ? 'Temporary ID' : 'Phone'}
              value={autoGenNumber ? phone : formatPhoneNumber(phone)}
            />
            <PropertyListItem divider label="App Version" value={appVersion} />
            <PropertyListItem divider label="Device" value={deviceInfoString} />
            {/*<PropertyListItem label="Accepted ToS">*/}
            {/*  {acceptedTOSOn ? (*/}
            {/*    getLocaleDateTimeString(acceptedTOSOn)*/}
            {/*  ) : (*/}
            {/*    <SeverityPill color={'error'}>N/A</SeverityPill>*/}
            {/*  )}*/}
            {/*</PropertyListItem>*/}

            {amount_due > 0 && (
              <>
                <Divider />
                <PropertyListItem
                  divider
                  label="Account Balance"
                  value={convertCentsToMoney(amount_due)}
                />
              </>
            )}
            {privateSystem && joined && (
              <>
                <Divider />
                <PropertyListItem
                  label="Joined"
                  value={getLocaleDateTimeString(joined)}
                />
              </>
            )}
          </PropertyList>
        }
        title={'Basic Details'}
      />

      <CardView
        documentationLink={paymentInfoDocumentationLink}
        content={
          <PropertyList>
            {system.billingLive && (
              <>
                {!isEmpty(userCard) && !isLoadingCard ? (
                  <PropertyList>
                    <PropertyListItem
                      divider
                      label={'Type'}
                      value={toTitleCase(userCard.brand)}
                    />
                    <PropertyListItem
                      divider
                      label="Expiration Date"
                      value={`${userCard.exp_month}/${userCard.exp_year}`}
                    />
                    <PropertyListItem divider label="Last 4" value={userCard.last4} />
                    <PropertyListItem divider label="Origin" value={userCard.country} />
                    <PropertyListItem label="Delinquent">
                      {userCard.delinquent ? (
                        <SeverityPill color={'error'}>Yes</SeverityPill>
                      ) : (
                        <SeverityPill color={'success'}>No</SeverityPill>
                      )}
                    </PropertyListItem>
                  </PropertyList>
                ) : isEmpty(userCard) ? (
                  <PropertyList>
                    <Divider />
                    <PropertyListItem label={'No credit card on file'} value={''} />
                  </PropertyList>
                ) : null}

                {usesWallet && !isEmpty(userWallet) ? (
                  <PropertyList>
                    <Divider />
                    <PropertyListItem
                      label={'Wallet Balance'}
                      value={convertCentsToMoney(userWallet.balance)}
                    />
                  </PropertyList>
                ) : null}
                {showConfirmationModal && (
                  <MovaticCustomModal
                    useContentDividers
                    open={showConfirmationModal}
                    onClose={() => setShowConfirmationModal(false)}
                    title="Set Non-Delinquent"
                    content={
                      <div>
                        {`Are you sure that would like to Set Non-Delinquent ${userDetails.fullName}?`}
                      </div>
                    }
                    action={
                      <>
                        <IconButtonMenu
                          buttons={[
                            {
                              label: 'Cancel',
                              onClick: () => setShowConfirmationModal(false),
                            },
                            {
                              label: 'Submit',
                              onClick: () => {
                                CustomAsyncToast({
                                  promise: () => updateUserDelinquency(userPhone),
                                  successMessage: () => 'User Delinquency Status Updated',
                                  errorMessage: 'User Delinquency Status Update Failed',
                                  loadingMessage: 'Updating User Delinquency Status...',
                                }).then((r) => {
                                  if (r) {
                                    onSuccess();
                                    setShowConfirmationModal(false);
                                  }
                                });
                              },
                              startIcon: <SaveIcon />,
                            },
                          ]}
                        />
                      </>
                    }
                  />
                )}
                {showCreditUserModal && (
                  <UserCreditWaller
                    onSuccess={() => refetchWallet()}
                    showModal={showCreditUserModal}
                    close={() => setShowCreditUserModal(false)}
                  />
                )}
                {showAddCreditCardModal && (
                  <AddStripeCard
                    showModal={showAddCreditCardModal}
                    close={() => setShowAddCreditCardModal(false)}
                    success={displaySuccess}
                    getClientSecret={getStripeClientSecret}
                    getUserCard={() => refetchUserCard()}
                  />
                )}
                {showClearBalanceModal && (
                  <MovaticCustomModal
                    useContentDividers
                    open={showClearBalanceModal}
                    onClose={() => setShowClearBalanceModal(false)}
                    title="Clear Balance"
                    content={
                      <div>
                        {`Are you sure that would like to Clear Balance for ${userDetails.fullName}?`}
                      </div>
                    }
                    action={
                      <>
                        <IconButtonMenu
                          buttons={[
                            {
                              label: 'Cancel',
                              onClick: () => setShowClearBalanceModal(false),
                            },
                            {
                              label: 'Submit',
                              onClick: () => {
                                CustomAsyncToast({
                                  promise: () => clearUserBalance(userPhone),
                                  successMessage: () => 'User Balance Cleared',
                                  errorMessage: 'User Balance Clear Failed',
                                  loadingMessage: 'Clearing User Balance...',
                                }).then((r) => {
                                  if (r) {
                                    setShowClearBalanceModal(false);
                                    onSuccess();
                                  }
                                });
                              },
                              startIcon: <SaveIcon />,
                              disabled: !chargeAmount || !chargeAmountDescription,
                            },
                          ]}
                        />
                      </>
                    }
                  />
                )}
              </>
            )}
          </PropertyList>
        }
        title="Payment Information"
        headerActions={
          <IconButtonMenu
            buttonLimit={
              isMobile
                ? 0
                : isMedium
                ? 1
                : calculateArrayBtns.filter((item) => item.shouldRender).length
            }
            slice={
              isMobile
                ? 0
                : isMedium
                ? 1
                : calculateArrayBtns.filter((item) => item.shouldRender).length
            }
            buttons={calculateArrayBtns
              .filter((item) => item.shouldRender)
              .map((item) => ({
                label: item.label,
                onClick: item.onClick,
                startIcon: item.startIcon,
                dataId: item.dataId,
              }))}
          />
        }
      />
      {showChargeModal && (
        <>
          <MovaticCustomModal
            open={showChargeModal}
            onClose={() => resetChargeModalFields()}
            title={"Charge User's Card"}
            content={
              <>
                <MovaticFieldGroupOutlinedInput
                  type={'number'}
                  label="Amount"
                  description="How much would you like to charge the user's card?"
                  value={chargeAmount}
                  inputProps={{ max: 9999, min: 0, step: 0.25 }}
                  onChange={(e) => setChargeAmount(e.target.value)}
                  id="amount"
                  name={'amount'}
                  addOn={'$'}
                />
                <MovaticFieldGroupText
                  name="description"
                  label="Charge Description"
                  description="Please insert a charge description"
                  value={chargeAmountDescription}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setChargeAmountDescription(e.target.value);
                  }}
                />
                {system.partner_name !== 'Movatic' && (
                  <MovaticFieldGroupRadio
                    label="Recipient"
                    description={`Choose if the collected funds will be added to the system account ("${system.name}") or the partner account ("${system.partner_name}").`}
                    value={chargeRecipient}
                    onChange={handleRadioConfigurationChange}
                    options={[
                      {
                        id: IChargeRecipient.SYSTEM,
                        label: `System ("${system.name}")`,
                      },
                      {
                        id: IChargeRecipient.PARTNER,
                        label: `Partner ("${system.partner_name}")`,
                      },
                    ]}
                  />
                )}
              </>
            }
            action={
              <IconButtonMenu
                buttons={[
                  {
                    label: 'Cancel',
                    onClick: () => resetChargeModalFields(),
                  },
                  {
                    label: 'Charge',
                    dataId: 'charge-button',
                    onClick: () => {
                      CustomAsyncToast({
                        promise: () =>
                          createCharge({
                            userID: userDetails.id,
                            description: chargeAmountDescription,
                            amount: Math.round(parseFloat(chargeAmount) * 100).toString(),
                            recipient: chargeRecipient,
                          }),
                        successMessage: (r: any) => {
                          if (r.message) {
                            return r.message;
                          }
                          return 'Successfully sent charge request.';
                        },
                        errorMessage: 'Charge Failed',
                        loadingMessage: 'Charging User...',
                      }).then((r) => {
                        if (r) {
                          resetChargeModalFields();
                        }
                      });
                    },
                    startIcon: <PaymentIcon />,
                    disabled: !chargeAmount || !chargeAmountDescription,
                  },
                ]}
              />
            }
          />
        </>
      )}
    </>
  );
};

export default GeneralUserInfoBanner;
