import React, { useMemo, useState } from 'react';
import { IUserMembership } from '../../ts/interface/membership.interface';
import { tableColumnsV2 } from '../../tableColumns/membershipColumns';
import Card from '@mui/material/Card';
import { PaginationTable } from '../../components/Redesing/table/pagination-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { history } from '../../utils';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants';
import useUrlState from '../../hooks/use-url-state';
import { ISortConfig } from '../../ts/interface/components/redesing/table.interface';

interface IPendingMembershipsProps {
  isLoading: boolean;
  pendingUserMemberships: IUserMembership[];
}

const PendingMemberships: React.FC<IPendingMembershipsProps> = (props) => {
  const { isLoading, pendingUserMemberships } = props;

  const [filters, setFilters] = useUrlState({
    sizePerPage: DEFAULT_ROWS_PER_PAGE,
    pageNumber: 0,
    search: '',
    sortConfig: {} as ISortConfig,
  });

  const sorting: ISortConfig = useMemo(() => {
    return {
      key: filters.sortConfig.key || 'name',
      direction: filters.sortConfig.direction || 'asc',
    };
  }, [filters.sortConfig.key, filters.sortConfig.direction]);

  const [exportData, setExportData] = useState<boolean>(false);
  const columns = useMemo(() => {
    return tableColumnsV2('pendingMemberships');
  }, []);

  return (
    <Card>
      <PaginationTable
        isLoading={isLoading}
        padding={{
          pr: 1,
          pt: 1,
        }}
        showFilter={false}
        exportData={exportData}
        csvFileName={'Pending_memberships.csv'}
        onExport={() => setExportData(false)}
        buttons={[
          {
            label: 'Export',
            onClick: () => setExportData(true),
            startIcon: <FileDownloadIcon />,
          },
        ]}
        sortBy={sorting.key}
        onSortChange={(sort) =>
          setFilters((prevState) => ({
            ...prevState,
            sortConfig: sort as ISortConfig,
          }))
        }
        items={pendingUserMemberships || []}
        noDataText={'There are no pending user memberships at this time'}
        onRowCLick={(id: string) => history.push(`/memberships/user/${id}`)}
        columns={columns}
        rowsPerPage={filters.sizePerPage}
        page={filters.pageNumber}
        onPageChange={(page, pageN: number) =>
          setFilters((prevState) => ({ ...prevState, pageNumber: pageN }))
        }
        onRowsPerPageChange={(rowsPerPage) =>
          setFilters((prevState) => ({
            ...prevState,
            sizePerPage: Number(rowsPerPage.target.value),
          }))
        }
        searchText={filters.search}
        searchPlaceholder={'Search pending memberships'}
        handleSearch={(search) =>
          setFilters((prevState) => ({ ...prevState, search: search.target.value }))
        }
      />
    </Card>
  );
};

export default PendingMemberships;
