import React, { useCallback, useMemo, useState } from 'react';
import useCoupons from '../../hooks/coupons/query/useCoupons';
import { ICoupon } from '../../ts/interface/coupon.interface';
import { connect } from 'react-redux';
import { couponColumns } from '../../tableColumns/couponColumns';
import { findAccess, history } from '../../utils';
import { IAuth } from '../../ts/interface/admins.interface';
import Card from '@mui/material/Card';
import { PaginationTable } from '../../components/Redesing/table/pagination-table';
import AddIcon from '@mui/icons-material/Add';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { MovaticFieldGroupSelectFilter } from '../../components/Redesing/movatic-field-group';
import { SearchChip } from '../../ts/interface/components/table.interface';
import { FilterChips } from '../../ts/interface/components/redesing/table.interface';
import { useUpdateEffect } from '../../hooks/use-update-effect';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants';

const filterOptions = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
];

const CouponIndex = ({
  systemLoaded,
  systemAccess,
  movaticAccess,
}: {
  systemLoaded: boolean;
  movaticAccess: number;
  systemAccess: number;
}) => {
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_ROWS_PER_PAGE);
  const [coupons, setCoupons] = useState<ICoupon[]>([]);
  const [sortData, setSortData] = useState<string>('created');
  const [search, setSearch] = React.useState('');
  const [exportData, setExportData] = useState<boolean>(false);
  const [value, setValue] = React.useState<any[]>([{ label: 'Active', value: 'active' }]);
  const [chips, setChips] = useState<SearchChip[]>([
    {
      label: 'Status',
      field: 'status',
      value: 'active',
      displayValue: 'Active',
      disabled: false,
    },
  ]);
  const [filterChips, setFilterChips] = useState<FilterChips>({
    status: ['active'],
    locations: [],
  } as FilterChips);

  useCoupons({
    enabled: systemLoaded,
    onSuccess: (data: { coupons: ICoupon[]; retired_coupons: ICoupon[] }) => {
      setCoupons([...data.coupons, ...data.retired_coupons]);
    },
  });

  const filterCoupons = useMemo(() => {
    return coupons.filter((coupon) => {
      const couponStatus = coupon.active ? 'active' : 'inactive';
      // @ts-ignore
      if (filterChips.status.length > 0) {
        // @ts-ignore
        if (!filterChips.status.includes(couponStatus)) {
          return false;
        }
      }
      return true;
    });
  }, [filterChips.status, coupons]);

  const handleChipsUpdate = useCallback(() => {
    const filtersAux: FilterChips = {
      status: [],
    };
    chips.forEach((chip) => {
      switch (chip.field) {
        case 'status':
          // @ts-ignore
          filtersAux.status.push(chip.value);
          break;
        default:
          break;
      }
    });
    setFilterChips(filtersAux);
  }, [chips]);

  const handleStatusChange = useCallback((values: string[]): void => {
    setPageNo(0);
    setPageSize(DEFAULT_ROWS_PER_PAGE);
    setChips((prevChips) => {
      const valuesFound: string[] = [];

      const newChips = prevChips.filter((chip) => {
        if (chip.field !== 'status') {
          return true;
        }

        const found = values.includes(chip.value as string);

        if (found) {
          valuesFound.push(chip.value as string);
        }

        return found;
      });

      if (values.length === valuesFound.length) {
        return newChips;
      }

      values.forEach((value) => {
        if (!valuesFound.includes(value)) {
          const option = filterOptions.find(
            (option) => option.value.toString() === value
          );
          newChips.push({
            label: 'Status',
            field: 'status',
            value,
            displayValue: option!.label,
          });
        }
      });
      return newChips;
    });
  }, []);

  useUpdateEffect(() => {
    handleChipsUpdate();
  }, [chips, handleChipsUpdate]);

  const handleChipDelete = useCallback(
    (deletedGroup: SearchChip) => {
      setChips((prevChips) => {
        return prevChips.filter((group) => {
          return group.label !== deletedGroup.label;
        });
      });
      setValue([]);
      handleChipsUpdate();
    },
    [handleChipsUpdate]
  );

  return (
    <>
      <Card>
        <PaginationTable
          dataId={'billing-coupons'}
          padding={{ pt: 1, pl: 0, pr: 1 }}
          sortBy={sortData}
          onSortChange={(sortBy) => setSortData(sortBy.key)}
          items={filterCoupons as any[]}
          noDataText="No Coupons Created Yet!"
          onRowCLick={(id: string) => history.push(`/billing/coupon/${id}`)}
          columns={couponColumns}
          rowsPerPage={pageSize}
          page={pageNo}
          onPageChange={(page, pageN: number) => {
            setPageNo(pageN);
          }}
          onRowsPerPageChange={(rowsPerPage) =>
            setPageSize(Number(rowsPerPage.target.value))
          }
          searchText={search}
          searchPlaceholder={`Search coupons`}
          handleSearch={(search) => setSearch(search.target.value)}
          buttons={[
            {
              label: 'Export',
              startIcon: <FileDownloadIcon />,
              onClick: () => setExportData(true),
            },
            {
              label: 'Add',
              startIcon: <AddIcon />,
              onClick: () => history.push('/billing/coupons/add'),
              disabled: !findAccess(systemAccess).includes('rates') && !movaticAccess,
            },
          ]}
          exportData={exportData}
          csvFileName={'Coupons.csv'}
          chips={chips}
          handleChipDelete={handleChipDelete}
          onApplyFilter={() => {
            handleStatusChange([
              ...value
                .filter((option) =>
                  filterOptions.some((item) => item.label === option.label)
                )
                .map((item) => item.value.toString()),
            ]);
          }}
          spaceFilters={
            <MovaticFieldGroupSelectFilter
              label={'Status'}
              value={value}
              onChange={(event: React.SyntheticEvent, newValue: any[]) => {
                setValue([
                  ...newValue.filter((option) =>
                    filterOptions.some((item) => item.label === option.label)
                  ),
                ]);
              }}
              options={filterOptions}
            />
          }
          onExport={() => setExportData(false)}
        />
      </Card>
    </>
  );
};

export default connect(
  (state: {
    system: { isLoaded: boolean };
    admin: {
      admin: IAuth;
      systemAccess: number;
    };
  }) => ({
    systemLoaded: state.system.isLoaded,
    movaticAccess: state.admin.admin.admin.movaticAccess,
    systemAccess: state.admin.systemAccess,
  }),
  () => ({})
)(CouponIndex);
