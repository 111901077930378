import { createHashHistory } from 'history';
import {
  UNASSIGNED,
  ACTIVE,
  RATE_DURATIONS,
  MAINTENANCE,
  RETIRED,
  REBALANCE,
  MISSING,
  LOST,
  STORED,
  INACTIVE,
  RESERVED,
} from './constants';
import { setError, setSuccess } from './actionCreators/toast';
import reduxStore from './store';
import bugsnagClient from './components/bugsnag';

// use a single instance of hashhistory
export const history = createHashHistory();

// determines if a unit or type of hardware is rentable within a system
export const rentable = (product, system) => product === system;

export const handleChangeByName = function (e) {
  const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
  if (e.target.name === undefined) return;

  this.setState({
    [e.target.name]: value,
  });
};

export const handleChangeById = function (e) {
  const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
  if (e.target.id === undefined) return;

  this.setState({
    [e.target.id]: value,
  });
};

// gets permissions and updates state
export const setAccess = function (access) {
  const permissions = [];
  if (access & 2) permissions.push('admin');
  if (access & 4) permissions.push('rental');
  if (access & 8) permissions.push('rates');
  if (access & 16) permissions.push('refunds');
  if (access & 32) permissions.push('bank');
  if (access & 64) permissions.push('physical');
  if (access & 128) permissions.push('users');
  if (access & 256) permissions.push('locations');
  if (access & 512) permissions.push('support');
  if (access & 1024) permissions.push('maintenance');
  if (access & 2048) permissions.push('memberships');
  permissions.forEach((eachAccess) => {
    this.setState({
      [eachAccess]: true,
    });
  });
};

// gets permissions but just returns the array
export const findAccess = (access) => {
  // access 0 means partner account
  // access -1 means Movatic account
  if (access === 0 || access === -1) {
    const partnerPermissions = [
      'partner',
      'admin',
      'rental',
      'rates',
      'refunds',
      'bank',
      'physical',
      'users',
      'locations',
      'support',
      'maintenance',
      'memberships',
      'documentation',
    ];

    // partner
    if (access === 0) {
      return partnerPermissions;
    }

    // movatic
    return [...partnerPermissions, 'movaticAccess'];
  }

  const permissions = [];

  if (access > 1) permissions.push('documentation');
  if (access & 2) permissions.push('admin');
  if (access & 4) permissions.push('rental');
  if (access & 8) permissions.push('rates');
  if (access & 16) permissions.push('refunds');
  if (access & 32) permissions.push('bank');
  if (access & 64) permissions.push('physical');
  if (access & 128) permissions.push('users');
  if (access & 256) permissions.push('locations');
  if (access & 512) permissions.push('support');
  if (access & 1024) permissions.push('maintenance');
  if (access & 2048) permissions.push('memberships');

  return permissions;
};

export const changeTab = (tabToChangeTo, currentTab, page) => {
  if (tabToChangeTo !== currentTab) history.push(`/${page}/${tabToChangeTo}`);
};

export const validateMacAddress = (macAddress) => {
  const letterNumber = /^[0-9a-fA-F]+$/;
  const colonCheck =
    macAddress[2] === ':' &&
    macAddress[5] === ':' &&
    macAddress[8] === ':' &&
    macAddress[11] === ':' &&
    macAddress[14] === ':';
  const lengthCheck = macAddress.length === 17;
  const alphaNum = letterNumber.test(macAddress.split(':').join(''));

  return colonCheck && lengthCheck && alphaNum && macAddress.split(':').length === 6;
};

export const validateImei = (imei) => {
  if (!imei) {
    return false;
  }

  if (isNaN(Number(imei))) {
    return false;
  }

  if (imei.length !== 15 && imei.length !== 17) {
    return false;
  }

  return true;
};

export const validateNokeSerial = (serial) => {
  const re = /^[a-z]+$/;
  const segements = serial.split('-');

  const hyphenated = serial[3] === '-' && serial[7] === '-';
  const lowercase = re.test(serial.split('-').join(''));
  const correctSegmentLength =
    segements[0].length === 3 && segements[1].length === 3 && segements[2].length === 4;

  return (
    serial.length === 12 &&
    segements.length === 3 &&
    hyphenated &&
    lowercase &&
    correctSegmentLength
  );
};

export const validateAxaSerial = (serial) => {
  const split = serial.split('-');

  return serial.length === 57 && split.length === 3;
};

export const validateVendorSerial = (serial, re) => new RegExp(re).test(serial);

export const toTitleCase = (str) => {
  if (!str) {
    return;
  }
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
};

export const isEmptyObject = (objectToCheck) => {
  if (!objectToCheck) {
    return true;
  }

  return Object.keys(objectToCheck).every((key) => {
    if (objectToCheck[key]) {
      if (
        objectToCheck[key] instanceof Object &&
        objectToCheck[key].constructor === Object
      ) {
        return isEmptyObject(objectToCheck[key]);
      }
      if (Array.isArray(objectToCheck[key])) {
        return objectToCheck[key].length === 0;
      }
    }
    return (
      objectToCheck[key] === false ||
      objectToCheck[key] === 0 ||
      objectToCheck[key] === null ||
      objectToCheck[key] === undefined ||
      objectToCheck[key] === ''
    ); // or just "return o[x];" for falsy values
  });
};

export const convertToCents = (number) => {
  if (isNaN(number)) return 0;
  number /= 100;
  number = number.toFixed(2);
  return number;
};

export const convertCentsToMoney = (cents) => {
  let number = convertToCents(cents);
  number = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `$${number}`;
};

export const formatNumberWithCommas = (num) => {
  if (!isNaN(num) && num !== null) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return '';
};

export const secondsToMinFormat = function (seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${String(remainingSeconds).padStart(2, '0')}`;
};

export const timeForHumans = (seconds) => {
  // there is a case where NaN is passed in as a string from the dashboard
  if (!seconds || seconds === 'NaN') return 0;

  const levels = [
    [Math.floor(seconds / 31536000), 'years'],
    [Math.floor((seconds % 31536000) / 86400), 'days'],
    [Math.floor(((seconds % 31536000) % 86400) / 3600), 'hours'],
    [Math.floor((((seconds % 31536000) % 86400) % 3600) / 60), 'minutes'],
    [(((seconds % 31536000) % 86400) % 3600) % 60, 'seconds'],
  ];
  let returntext = '';

  for (let i = 0, max = levels.length; i < max; i++) {
    if (levels[i][0] === 0) continue;
    returntext += ` ${levels[i][0]} ${
      levels[i][0] === 1 ? levels[i][1].substr(0, levels[i][1].length - 1) : levels[i][1]
    }`;
  }
  return returntext.trim();
};

export const formatPhoneNumber = (phone) => {
  if (phone) {
    phone = phone.toString();
    return `${phone.slice(0, -10)} (${phone.slice(-10, -7)}) ${phone.slice(
      -7,
      -4
    )}-${phone.slice(-4)}`;
  }
  return 'No phone number';
};

export const makeLockOptionsArray = (locks) => {
  const locksArray = locks.reduce(
    (resArray, lock) => {
      if (lock.state === UNASSIGNED) {
        return resArray.concat([{ value: lock.mac, name: lock.mac }]);
      }
      return resArray;
    },
    [{ value: null, name: 'No Lock' }]
  );
  return locksArray;
};

export const makeStationOptionsArray = (
  stations,
  stationTitle = 'Station',
  useGid = false
) => {
  const stationArray = stations.reduce(
    (resArray, station) => {
      if (station.state === ACTIVE && station.configuration !== 'zone') {
        return resArray.concat([
          { value: useGid ? station.id : station.old_id, name: station.name },
        ]);
      }
      return resArray;
    },
    [] // Provide an empty array as the initial value
  );
  return stationArray;
};

export const isTimeEarlier = (startDateTime, inputedDate, inputedTime) => {
  const t = new Date(inputedDate);
  const startTime = new Date(startDateTime).getTime();
  const hourSeconds = 3600 * t.getHours();
  const minSeconds = 60 * t.getMinutes();
  const seconds = t.getSeconds();
  const millSec = t.getMilliseconds();
  const totalInputedValue =
    t.getTime() - (hourSeconds + minSeconds + seconds + millSec) + inputedTime;
  return startTime / 1000 / 60 < totalInputedValue / 1000 / 60;
};

// toLocaleString isn't reliable on Safari--use this to get formatted datetimes
export function getLocaleDateTimeString(date) {
  if (date) {
    let formattedDate = `${new Date(
      date.replace(/ /g, 'T')
    ).toLocaleDateString()}, ${new Date(date.replace(/ /g, 'T')).toLocaleTimeString()}`;

    if (formattedDate.includes('Invalid Date')) {
      formattedDate = `${new Date(date).toLocaleDateString()}, ${new Date(
        date
      ).toLocaleTimeString()}`;
    }
    return formattedDate;
  }
  return '';
}

export const getInitials = (name = '') =>
  name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join('');

// date string only, again because Safari/IE/Edge don't work
export function getLocaleDateString(date) {
  if (date) {
    let formattedDate = `${new Date(date.replace(/ /g, 'T')).toLocaleDateString()}`;

    if (formattedDate.includes('Invalid Date')) {
      formattedDate = `${new Date(date).toLocaleDateString()}`;
    }
    return formattedDate;
  }
  return '';
}

// time string only, again because Safari/IE/Edge don't work
export function getLocaleTimeString(date) {
  if (date) {
    let formattedDate = `${new Date(date.replace(/ /g, 'T')).toLocaleTimeString()}`;

    if (formattedDate.includes('Invalid Date')) {
      formattedDate = `${new Date(date).toLocaleTimeString()}`;
    }
    return formattedDate;
  }
  return '';
}

export function getUtcSeconds(seconds, offset) {
  let convertedSeconds = seconds + offset * 60;
  if (convertedSeconds > 86400) {
    convertedSeconds -= 86400;
  } else if (convertedSeconds < 0) {
    convertedSeconds += 86400;
  }
  return convertedSeconds;
}

export function getLocalSeconds(seconds, offset) {
  let convertedSeconds = seconds - offset * 60;
  if (convertedSeconds > 86400) {
    convertedSeconds -= 86400;
  } else if (convertedSeconds < 0) {
    convertedSeconds += 86400;
  }
  return convertedSeconds;
}

export const issueStateFormatter = (value) => {
  if (value === 0) {
    return 'Open';
  }
  if (value === 1) {
    return 'Closed';
  }
};

export const STATE_OPTIONS = [
  {
    name: 'Unassigned',
    value: UNASSIGNED,
    color: 'warning',
    hardware: false,
    stringValue: 'unassigned',
  },
  {
    name: 'Active',
    value: ACTIVE,
    color: 'success',
    hardware: true,
    stringValue: 'active',
  },
  {
    name: 'Inactive',
    value: INACTIVE,
    color: 'error',
    hardware: false,
    stringValue: 'inactive',
  },
  {
    name: 'Maintenance',
    value: MAINTENANCE,
    color: 'warning',
    hardware: true,
    stringValue: 'maintenance',
  },
  {
    name: 'Retired',
    value: RETIRED,
    color: 'error',
    hardware: true,
    stringValue: 'retired',
  },
  {
    name: 'Reserved',
    value: RESERVED,
    color: 'info',
    hardware: false,
    stringValue: 'reserved',
  },
  {
    name: 'Rebalancing',
    value: REBALANCE,
    color: 'info',
    hardware: true,
    stringValue: 'rebalancing',
  },
  {
    name: 'Missing',
    value: MISSING,
    color: 'error',
    hardware: true,
    stringValue: 'missing',
  },
  { name: 'Lost', value: LOST, color: 'error', hardware: true, stringValue: 'lost' },
  { name: 'Stored', value: STORED, color: 'info', hardware: true, stringValue: 'stored' },
];

export const PAGES_NAMES = [
  { name: 'Dashboard', path: '/dashboard' },
  { name: 'Users', path: '/users' },
  { name: 'Rentals', path: '/rentals' },
  { name: 'Hardware', path: '/hardware' },
  { name: 'Location', path: '/stations' },
  { name: 'Customer Support', path: '/problems' },
  { name: 'Maintenance', path: '/maintenance' },
  { name: 'Memberships', path: '/memberships' },
  { name: 'Billing', path: '/billing' },
  { name: 'Settings', path: '/settings' },
  { name: 'New System', path: '/create-your-system' },
  { name: 'New User', path: '/create-your-user' },
  { name: 'Hardware', path: '/units' },
];

export const stateFormatter = (state) => {
  const stateObj = STATE_OPTIONS.find(({ name, value }) => {
    return (
      (typeof state === 'string' || !Number.isNaN(parseInt(state, 10))) &&
      (name.toLowerCase() === state.toString().toLowerCase() || value === state)
    );
  });

  return stateObj || {};
};

export const authoirzeFormater = (cell, row) => stateFormatter(cell).name;

export function getPricingString(rate) {
  if (!rate) {
    return '';
  }

  let rateString = '';

  if (rate.price_per_period && !rate.base) {
    RATE_DURATIONS.forEach((time) => {
      if (time.seconds === rate.period_duration) {
        rate.stringDuration = time.value;
      }
    });
    rate.free_time && rate.free_time !== 0 && rate.free_time !== null
      ? (rateString = `${timeForHumans(rate.free_time)} free, then $${(
          rate.price_per_period / 100
        ).toFixed(2)} for ${rate.stringDuration}`)
      : (rateString = `$${(rate.price_per_period / 100).toFixed(2)} for ${
          rate.stringDuration
        }`);
  } else if (rate.price_per_period && rate.base) {
    RATE_DURATIONS.forEach((time) => {
      if (time.seconds === rate.period_duration) {
        rate.stringDuration = time.value;
      }
    });
    rate.free_time && rate.free_time !== 0 && rate.free_time !== null
      ? (rateString = `${timeForHumans(rate.free_time)} free, then $${(
          rate.base / 100
        ).toFixed(2)} + $${(rate.price_per_period / 100).toFixed(2)} / ${timeForHumans(
          rate.period_duration
        )}`)
      : (rateString = `$${(rate.base / 100).toFixed(2)} + $${(
          rate.price_per_period / 100
        ).toFixed(2)} / ${timeForHumans(rate.period_duration)}`);
  }

  return rateString;
}

export function getPriorityName(priority) {
  // eslint-disable-next-line default-case
  switch (priority) {
    case 1:
      return 'Lowest';
    case 2:
      return 'Low';
    case 3:
      return 'Normal';
    case 4:
      return 'High';
    case 5:
      return 'Highest';
  }
}

export function unavailableReason(hardware) {
  const reasons = [];

  if (!hardware.available) {
    if (
      hardware.station_config !== 'store' &&
      !hardware?.children?.some(
        (child) => child.child_type === 'lock' && !child.removed_on
      )
    ) {
      reasons.push('lock');
    }

    if (
      hardware.hardware_type_name === 'kayak' &&
      !hardware?.childre?.some(
        (child) => child.child_type === 'locker' && !child.removed_on
      )
    ) {
      reasons.push('locker');
    }
  }

  return reasons;
}

// LOCAL STORAGE

/**
 * validate and returns the admin data stored locally
 * @returns {null|Object}
 */
export function getAdminFromLocalS() {
  let { admin } = localStorage;
  const adminDate = new Date(localStorage.adminDate);
  const dat = new Date();

  if (adminDate) {
    if (adminDate.setDate(adminDate.getDate() + 1) < dat) {
      admin = null;
      localStorage.removeItem('admin');
      localStorage.removeItem('adminDate');
    } else {
      try {
        admin = JSON.parse(admin);
      } catch (e) {
        admin = null;
      }
    }
  }

  return admin;
}

export function zoneTypeFormatter(zoneType) {
  switch (zoneType) {
    case 'parking':
      return 'Parking zone';
    case 'preferred':
      return 'Preferred parking zone';
    case 'system':
      return 'Service zone';
    case 'restricted':
      return 'Restricted zone';
    case 'slowzone':
      return 'Slow zone';
    case 'nogo':
      return 'No Go zone';
    case 'deployment':
      return 'Deployment zone';
    default:
      return zoneType;
  }
}

export const HOUR_IN_SECONDS = 3600;
export const DAY_IN_SECONDS = HOUR_IN_SECONDS * 24;
export const WEEK_IN_SECONDS = DAY_IN_SECONDS * 7;
export const MONTH_IN_SECONDS = DAY_IN_SECONDS * 30;
export const YEAR_IN_SECONDS = DAY_IN_SECONDS * 365;

export function getIntervalInfo(intervalInSeconds) {
  if (intervalInSeconds % YEAR_IN_SECONDS === 0) {
    const value = intervalInSeconds / YEAR_IN_SECONDS;
    return {
      units: value > 1 ? 'Years' : 'Year',
      value,
    };
  }

  if (intervalInSeconds % MONTH_IN_SECONDS === 0) {
    const value = intervalInSeconds / MONTH_IN_SECONDS;
    return {
      units: value > 1 ? 'Months' : 'Month',
      value,
    };
  }

  if (intervalInSeconds % WEEK_IN_SECONDS === 0) {
    const value = intervalInSeconds / WEEK_IN_SECONDS;
    return {
      units: value > 1 ? 'Weeks' : 'Week',
      value,
    };
  }

  if (intervalInSeconds % DAY_IN_SECONDS === 0) {
    const value = intervalInSeconds / DAY_IN_SECONDS;
    return {
      units: value > 1 ? 'Days' : 'Day',
      value,
    };
  }

  const value = intervalInSeconds / HOUR_IN_SECONDS;
  return {
    units: value > 1 ? 'Hours' : 'Hour',
    value,
  };
}

export function getFormattedMembershipPrice(membershipPrice) {
  const price = `$${(membershipPrice.price / 100).toFixed(2)}`;
  const intervalInfo = membershipPrice.interval
    ? getIntervalInfo(membershipPrice.interval)
    : null;
  const period = intervalInfo
    ? `${intervalInfo.value} ${intervalInfo.units}`
    : `${new Date(membershipPrice.starts_on).toLocaleDateString()} - ${new Date(
        membershipPrice.ends_on
      ).toLocaleDateString()}`;
  const formattedMembershipPrice = `${price} / ${period}`;

  return formattedMembershipPrice;
}

export function downloadBlob(blob, filename) {
  const newBlob = new Blob([blob]);

  // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator?.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
  } else {
    // For other browsers: create a link pointing to the ObjectURL containing the blob.
    const objUrl = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = objUrl;
    link.download = filename;
    link.click();

    // For Firefox it is necessary to delay revoking the ObjectURL.
    setTimeout(() => {
      window.URL.revokeObjectURL(objUrl);
    }, 250);
  }
}

export function displayError(userFriendlyErrorMessage, error) {
  reduxStore.dispatch(setError(userFriendlyErrorMessage));
  if (error) {
    bugsnagClient.notify(error);
  }
}

export function displaySuccess(success) {
  reduxStore.dispatch(setSuccess(success));
}

/**
 * Loops through the object's properties and if a property's key matches an item in the array
 * and is a number, changes the value of that property to be the number divided by 100.
 *
 * @param {object} object The object with the fields to convert
 * @param {array} fieldNames An array with the object's field keys to convert
 * @returns {object} The object with the converted fields
 */
export function convertCentFieldsToDollars(object, fieldNames) {
  if (object && fieldNames) {
    const newObject = { ...object };

    Object.entries(newObject).forEach(([key, value]) => {
      if (fieldNames.includes(key) && typeof value === 'number')
        newObject[key] = value / 100;
    });

    return newObject;
  }

  return object;
}

export const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }

  return true;
}

export function isObject(object) {
  return object != null && typeof object === 'object';
}

export const openGoogleMapsCoordinates = (latitude, longitude) => {
  window.open(
    `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`,
    '_blank'
  );
};

export const stripEmptyFields = (obj) => {
  const newObject = {};

  Object.keys(obj).forEach((key) => {
    if (obj[key] !== null && obj[key] !== undefined) newObject[key] = obj[key];
  });

  return newObject;
};

/**
 * Format timezone according to ISO 8601 spec.
 * Example: YYYY-MM-DDThh:mm:ss[.mmm]TZD (eg 2012-03-29T10:05:45-06:00)
 */
export const formatDateOffsetISO = (year, month, day, time, offset) => {
  const date = `${year}-${month}-${day}T${time}${offset}`;
  return date;
};

/**
 * Removing the timezone from the current date-time. And adding the T character
 * to indicate the start of the representation of the time of day.
 * Input format example: 2020-08-21 16:20:16-12:00
 * Output example: 2020-08-21T16:20:16+00:00
 */
export const removeTimezone = (datetime) => {
  if (isIsoDate(datetime)) {
    return formatDateISOTime(`${datetime.substring(0, datetime.length - 6)}+00:00`);
  }
  return datetime;
};

/**
 * Format timezone according to ISO 8601 spec.
 * Example: YYYY-MM-DDThh:mm:ss[.mmm]TZD (eg 2012-03-29T10:05:45-06:00).
 * This adds the T character as a designator to indicate the start of the representation of the time of day.
 * This is because Safari does not support the date without this character.
 */
export const formatDateISOTime = (datetime) => {
  if (isIsoDate(datetime)) {
    return datetime.replace(/ /g, 'T');
  }
  return datetime;
};

/**
 * This verifies the datetime has the ISO format according to the specification
 * Example: 2020-08-21 16:20:16-12:00, 2020-08-21 16:20:16+12:00 or 2020-08-21 16:20:16.
 * These format would be considered as ISO dates.
 */
export const isIsoDate = (str) => {
  if (/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}([-|+]\d{2}:\d{2}|$)/.test(str)) return true;
  return false;
};

export const EN_US_DATE_FORMAT = 'MM/DD/YYYY';

/**
 * This converts a unix timestamp date to a readable date.
 */
export const unixTimestampToDate = (timestamp) => {
  const date = new Date(timestamp * 1000).toUTCString();
  return date;
};

// RegEx source: https://www.w3resource.com/javascript/form/email-validation.php
export const isValidEmail = (emailToTest) =>
  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailToTest);

export const truncateName = (text, limit = 20) =>
  text.length > limit ? `${text.substring(0, limit)}...` : text;
