import React, { FC } from 'react';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import { RouterLink } from './router-link';
import SvgIcon from '@mui/material/SvgIcon';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { getInitials } from '../../utils';
import Chip from '@mui/material/Chip';
import { Badge, useTheme } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CardLoader from './card-loader';

interface ITab {
  label: string;
  value: string;
  icon?: any;
  warnings?: number;
}

interface IPageContent {
  backLink?: string;
  contentTitle?: string;
  itemName?: string;
  itemChipValue?: string;
  chipLabel?: string;
  currentTabV?: string;
  itemActions?: React.ReactNode;
  itemTopButtons?: React.ReactNode;
  handleTabsChange?: (event: React.SyntheticEvent, newValue: string) => void;
  tabsList?: ITab[];
  tabsChildren: React.ReactNode;
  detailedView?: boolean;
  isLoading?: boolean;
  detailIcon?: React.ReactNode;
  avatarColor?: string;
  dataId?: string;
}

const PageContent: FC<IPageContent> = ({
  backLink,
  chipLabel,
  contentTitle,
  itemName,
  itemChipValue,
  itemActions,
  itemTopButtons,
  handleTabsChange,
  currentTabV,
  tabsList,
  tabsChildren,
  detailedView = true,
  isLoading = false,
  detailIcon,
  avatarColor,
  dataId = 'page-content',
}) => {
  const theme = useTheme();

  return (
    <Stack
      data-id={'text'}
      spacing={2}
      sx={{
        marginTop: '0 !important',
      }}
    >
      {detailedView ? (
        <div>
          <Link
            color="text.primary"
            component={RouterLink}
            href={backLink || '/'}
            sx={{
              alignItems: 'center',
              display: 'inline-flex',
            }}
            underline="hover"
          >
            <SvgIcon sx={{ mr: 1 }}>
              <ArrowBackIcon />
            </SvgIcon>
            <Typography variant="subtitle2">{contentTitle}</Typography>
          </Link>
        </div>
      ) : null}
      <Stack sx={{ pl: 2 }} spacing={0.5}>
        {detailedView && (
          <>
            {isLoading ? (
              <CardLoader />
            ) : (
              <Stack
                alignItems="flex-start"
                direction={{
                  xs: 'column',
                  md: 'row',
                }}
                justifyContent="space-between"
                spacing={2}
              >
                <Stack alignItems="center" direction="row" spacing={2}>
                  <Avatar
                    src={''}
                    sx={{
                      bgcolor: avatarColor ? `${avatarColor}` : '#F58964',
                      color: 'white',
                      height: 64,
                      width: 64,
                    }}
                  >
                    {detailIcon ? <>{detailIcon}</> : <>{getInitials(itemName)}</>}
                  </Avatar>
                  <Stack spacing={1}>
                    <Typography variant="h4">{itemName}</Typography>
                    <Stack
                      alignItems="center"
                      direction={{
                        xs: 'column',
                        md: 'row',
                      }}
                      spacing={1}
                    >
                      <Typography variant="subtitle2">{`${chipLabel}:`}</Typography>
                      <Chip
                        data-id={`${dataId}-identifier`}
                        label={itemChipValue}
                        size="small"
                      />
                    </Stack>
                    {itemActions}
                  </Stack>
                </Stack>
                {itemTopButtons}
              </Stack>
            )}
          </>
        )}
        {tabsList && tabsList.length > 0 && (
          <div>
            <Tabs
              id={`${dataId}-tabs`}
              indicatorColor="secondary"
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor={theme.palette.text.primary as any}
              value={currentTabV}
              variant="scrollable"
            >
              {tabsList.map((tab: ITab) => {
                return (
                  <Tab
                    sx={{
                      color: theme.palette.text.primary,
                      '&:focus': {
                        outline: 'none',
                        color: 'text.primary',
                      },
                      '&:focus-visible': {
                        outline: 'none',
                        color: 'text.primary',
                      },
                      '& .MuiTabs-indicatorSpan': {
                        maxWidth: 900,
                        width: '100%',
                        backgroundColor: '#635ee7',
                      },
                    }}
                    key={tab.value}
                    label={
                      tab?.warnings && tab.warnings > 0 ? (
                        <Badge
                          badgeContent={5}
                          color={'error'}
                          variant={'dot'}
                          sx={{ mr: 0.5 }}
                        >
                          {tab.label}
                        </Badge>
                      ) : (
                        <>{tab.label}</>
                      )
                    }
                    value={tab.value}
                  />
                );
              })}
            </Tabs>
          </div>
        )}
      </Stack>
      <Stack>{tabsChildren}</Stack>
    </Stack>
  );
};

export default PageContent;
