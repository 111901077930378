import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import CardView from '../../components/Redesing/card-view';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import SaveIcon from '@mui/icons-material/Save';
import { Stack } from '@mui/system';
import '../../css/TOS.scss';
import { useTheme } from '@mui/material';

interface TermsProps {
  isLoaded: boolean;
  getSystemTOS: () => Promise<string>;
  submit: (tos: { text: string; original: string }) => void;
  isChanged: (flag: boolean) => void;
  buttonDisabled: boolean;
}

const Terms: React.FC<TermsProps> = ({
  isLoaded,
  getSystemTOS,
  submit,
  buttonDisabled,
  isChanged,
}) => {
  const [original, setOriginal] = useState<string>('');
  const [text, setText] = useState<string>('');
  const [loaded, setLoaded] = useState<boolean>(false);
  const editorRef = useRef<any>(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      if (isLoaded) {
        const response = await getSystemTOS();
        setText(response || '');
        setOriginal(response || '');
        setLoaded(true);
      }
    };

    fetchData();
  }, [isLoaded, getSystemTOS]);

  const handleEditorInit = (evt: any, editor: any) => {
    editorRef.current = editor;
  };

  const handleEditorChange = () => {
    const newTos = {
      text: editorRef.current.getContent(),
      original: original,
    };
    submit(newTos);
  };

  const renderEditor = () => {
    return (
      <Editor
        tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`}
        onInit={handleEditorInit}
        initialValue={text}
        init={{
          skin: theme.palette.mode === 'dark' ? 'oxide-dark' : undefined,
          content_css: theme.palette.mode === 'dark' ? 'dark' : undefined,
          height: 1000,
          menubar: false,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'preview',
            'help',
            'wordcount',
          ],
          toolbar:
            'undo redo | blocks | ' +
            'bold italic forecolor | link | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
        onChange={() => isChanged(true)}
      />
    );
  };

  return (
    <CardView
      headerActions={
        <IconButtonMenu
          buttons={[
            {
              label: 'Save',
              startIcon: <SaveIcon />,
              onClick: handleEditorChange,
              disabled: buttonDisabled,
            },
          ]}
        />
      }
      content={
        <Stack px={1.5} pb={2}>
          {loaded && renderEditor()}
        </Stack>
      }
      title={'Terms of Service'}
    />
  );
};

export default Terms;
