import {
  baseUrl,
  getCredentials,
  fetchOptions,
  fetchPostOptions,
  createQueryParameters,
} from './http';
import { FourOhOneError } from '../actionCreators/system';
import CustomToast from '../components/Redesing/custom-toast';

export const getAllProblems = () => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);

  return fetch(`${baseUrl}/maintenance`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        return response.message;
      }
      throw new Error(response?.message);
    });
};

export const getOneProblem = (problemId) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);

  return fetch(`${baseUrl}/maintenance/${problemId}`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        return response.message;
      }
      throw new Error(response?.message);
    });
};

export const getOneTicket = (ticketId) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);
  return fetch(`${baseUrl}/maintenance/tickets/${ticketId}`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        return response.message;
      }
      FourOhOneError(response);
      throw new Error(response?.message);
    })
    .catch((e) => {
      CustomToast({
        type: 'error',
        message: `Oops! An error has occurred while loading ticket details. Please try again later. ${e}`,
      });
    });
};

export const getAllTickets = () => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);

  return fetch(`${baseUrl}/maintenance/tickets`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        return response.message;
      }
      throw new Error(response?.message);
    });
};

export const getAllCompletedTasks = () => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);
  return fetch(`${baseUrl}/maintenance/tasks/completed`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        return response.message;
      }
      FourOhOneError(response);
      throw new Error(response?.message);
    })
    .catch((e) => {
      CustomToast({
        type: 'error',
        message: `Oops! An error has occurred while loading completed tasks. Please try again later. ${e}`,
      });
    });
};

export const getOneCompletedTask = (taskId) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);
  return fetch(`${baseUrl}/maintenance/tasks/completed/${taskId}`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        return response.message;
      }
      FourOhOneError(response);
      throw new Error(response?.message);
    })
    .catch((e) => {
      CustomToast({
        type: 'error',
        message: `Oops! An error has occurred while loading completed task details. Please try again later.${e}`,
      });
    });
};

export const cancelTicket = (ticket) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, ticket, 'PUT');
  return fetch(`${baseUrl}/maintenance/tickets/cancel`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results.status === 200) {
        return results;
      } else {
        FourOhOneError(results);
        throw new Error(results?.message);
      }
    })
    .catch((error) => Promise.reject(`${error}`));
};

export const addNoteToTicket = (note) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, note, 'PUT');
  return fetch(`${baseUrl}/maintenance/tickets/note`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results.status === 200) {
        return results;
      }
      FourOhOneError(results);
      throw new Error(results?.message);
    })
    .catch((error) => Promise.reject(`${error}`));
};

export const editTicket = (ticket) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, ticket, 'PUT');
  return fetch(`${baseUrl}/maintenance/tickets/update`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results.status === 200) {
        return results;
      }
      FourOhOneError(results);
      throw new Error(results?.message);
    })
    .catch((error) => Promise.reject(`${error}`));
};

export const getAllTasks = () => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);
  return fetch(`${baseUrl}/maintenance/tasks`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        return response.message;
      }
      FourOhOneError(response);
      throw new Error(response?.message);
    })
    .catch((error) => Promise.reject(`${error}`));
};

export const createTicket = (ticket) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, ticket, 'POST');
  return fetch(`${baseUrl}/maintenance/tickets/add`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results.status === 200) {
        return results;
      }
      FourOhOneError(results);
      throw new Error(results?.message);
    })
    .catch((error) => Promise.reject(`${error}`));
};

export const closeTicket = (task) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, task, 'PUT');
  return fetch(`${baseUrl}/maintenance/tickets/close`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results.status === 200) {
        return results;
      }
      FourOhOneError(results);
      throw new Error(results?.message);
    })
    .catch((error) => Promise.reject(`${error}`));
};

export const completeTask = (task) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, task, 'POST');
  return fetch(`${baseUrl}/maintenance/tasks/close`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results.status === 200) {
        return results;
      }
      FourOhOneError(results);
      throw new Error(results?.message);
    })
    .catch((error) => Promise.reject(`${error}`));
};

export const updateIssue = (issue) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, issue, 'PUT');
  return fetch(`${baseUrl}/maintenance/update/${issue.id}`, fetchOps)
    .then((response) => {
      if (response.status === 200) {
        return response;
      }
      FourOhOneError(response);
      throw new Error(response?.message);
    })
    .catch((error) => Promise.reject(`${error}`));
};

export const sendReplyEmail = (emailContent, issueId) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(
    adminEmail,
    adminToken,
    systemId,
    emailContent,
    'PUT'
  );
  return fetch(`${baseUrl}/maintenance/reply/${issueId}`, fetchOps)
    .then((response) => {
      if (response.status === 200) {
        return response;
      }

      FourOhOneError(response);
      throw new Error(response?.message);
    })
    .catch((error) => Promise.reject(`${error}`));
};

export const addNoteToReport = (note) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, note, 'PUT');
  return fetch(`${baseUrl}/maintenance/report/note`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results.status === 200) {
        return results;
      }
      FourOhOneError(results);
      throw new Error(results?.message);
    })
    .catch((error) => Promise.reject(`${error}`));
};

export const getTags = () => {
  const { adminEmail, adminToken, systemId, systemGid } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);
  const queryParametersObject = {
    system_id: systemGid,
  };
  const queryParameters = createQueryParameters(queryParametersObject);
  return fetch(`${baseUrl}/maintenance/support/tags?${queryParameters}`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        return response.message;
      }
      throw new Error(response?.message);
    })
    .catch((e) =>
      CustomToast({
        type: 'error',
        message: `Oops! An error has occurred while loading tags. Please try again later. ${e}`,
      })
    );
};
export const addTag = (tag) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, tag, 'POST');
  return fetch(`${baseUrl}/maintenance/support/tags`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results.status === 200) {
        return results;
      }
      FourOhOneError(results);
      throw new Error(results?.message);
    })
    .catch((error) => Promise.reject(`${error}`));
};

export const archiveTag = (tagId, payload) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, payload, 'PUT');
  return fetch(`${baseUrl}/maintenance/support/tags/${tagId}`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results.status === 200) {
        return results;
      }
      FourOhOneError(results);
      throw new Error(results?.message);
    })
    .catch((error) => Promise.reject(`${error}`));
};

export const addTagsToErrorReport = (reportGid, payload) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, payload, 'PUT');
  return fetch(`${baseUrl}/maintenance/support/update/tags/${reportGid}`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results.status === 200) {
        return results;
      }
      FourOhOneError(results);
      throw new Error(results?.message);
    })
    .catch((error) => Promise.reject(`${error}`));
};
