import React from 'react';
import { useFormik, yupToFormErrors } from 'formik';

import FeeForm from './feeForm';
import { history } from '../../../utils';
import { addFee } from '../../../api/fees';
import feeSchema from './feeSchema';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import SaveIcon from '@mui/icons-material/Save';
import PageContainer from '../../../components/Redesing/page-container';
import CardView from '../../../components/Redesing/card-view';
import { Stack } from '@mui/system';
import CustomAsyncToast from '../../../components/Redesing/custom-async-toast';

const Buttons = (props) => {
  return (
    <IconButtonMenu
      buttons={[
        {
          label: 'Cancel',
          onClick: () => history.push('/billing/fees'),
        },
        {
          label: 'Save',
          onClick: props.submitFee,
          startIcon: <SaveIcon />,
        },
      ]}
    />
  );
};

const AddFee = () => {
  const formik = useFormik({
    initialValues: feeSchema.default(),
    validationSchema: feeSchema,
    validate: (values) => {
      return feeSchema
        .validate(values, { abortEarly: false, context: {} })
        .then(() => null)
        .catch((err) => {
          return yupToFormErrors(err);
        });
    },
    onSubmit: (values) => {
      CustomAsyncToast({
        promise: () => addFee(feeSchema.cast(values, { stripUnknown: true })),
        successMessage: () => 'The fee has been added successfully!',
        loadingMessage: () => 'Adding the fee...',
        errorMessage: () => 'Something went wrong when adding the fee.',
      }).then((result) => {
        if (result) {
          history.push('/billing/fees');
        }
      });
    },
  });

  return (
    <PageContainer>
      <CardView
        content={
          <Stack px={2} pb={2}>
            <FeeForm formik={formik} />
          </Stack>
        }
        title={'Add'}
        headerActions={<Buttons submitFee={formik.handleSubmit} />}
      />
    </PageContainer>
  );
};

export default AddFee;
