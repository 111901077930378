import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { ISystem } from '../../ts/interface/system.interface';
import { useParams } from 'react-router-dom';
import useMemberships from '../../hooks/membership/query/useMemberships';
import { convertToCents, history } from '../../utils';
import { updateMembership, uploadImage } from '../../api/memberships';
import useMembershipImages from '../../hooks/membership/query/useMembershipImages';
import { IImage } from '../../ts/interface/image.interface';
import { ChromePicker } from 'react-color';

import {
  MovaticFieldGroupCheck,
  MovaticFieldGroupOutlinedInput,
  MovaticFieldGroupText,
  MovaticFieldMultiOptions,
} from '../../components/Redesing/movatic-field-group';
import FormItem from '../../components/Redesing/form-item';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import FileUpload from '../../components/Redesing/file-upload';
import PageContainer from '../../components/Redesing/page-container';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import CardView from '../../components/Redesing/card-view';
import { Stack } from '@mui/system';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';
import CustomToast from '../../components/Redesing/custom-toast';
import { type IDocument, IMembershipData } from '../../ts/interface/membership.interface';
import MembershipDocumentsList from './assets/membership-documents-list';
import EditDocumentModal from './assets/edit-document-modal';
import AddDocumentModal from './assets/add-document-modal';
import { v4 as uuidv4 } from 'uuid';
import NoFilePlaceHolder from '../../components/Redesing/no-file-place-holder';
import { EMAIL_REGEX } from '../../constants';
interface IEditMembershipProps {
  system: ISystem;
  systemLoaded: boolean;
}

interface EditMembershipState {
  logo: null | Blob;
  branding_color: string;
  password: string | number | string[] | undefined;
  valid_emails: string[];
  hide_valid_emails: boolean;
  logo_image_id: null | number;
  logo_image_uuid: null | string;
  minimum_wallet_deposit: null | number;
  credit_card_hold: null | number;
  priority: string;
  max_rentals: null | string;
  hidden: boolean;
  chipError: null | string;
  data: IMembershipData;
}

const EditMembership = ({ system, systemLoaded }: IEditMembershipProps) => {
  const initialState = {
    logo: null,
    branding_color: '',
    password: undefined,
    valid_emails: [],
    hide_valid_emails: false,
    logo_image_id: null,
    logo_image_uuid: null,
    minimum_wallet_deposit: null,
    credit_card_hold: null,
    priority: null as unknown as string,
    max_rentals: null as unknown as string,
    hidden: false,
    chipError: null,
    data: {} as IMembershipData,
  };
  const [
    {
      logo,
      branding_color,
      password,
      valid_emails,
      hide_valid_emails,
      logo_image_id,
      logo_image_uuid,
      minimum_wallet_deposit,
      credit_card_hold,
      priority,
      max_rentals,
      hidden,
      chipError,
      data,
    },
    setState,
  ] = useState<EditMembershipState>(initialState);
  const { membershipId } = useParams<{ membershipId: string }>();
  const theme = useTheme();
  const isDarkMode = useMemo(() => theme.palette.mode === 'dark', [theme.palette.mode]);
  const [selectedDocument, setSelectedDocument] = useState<IDocument | null>(null);
  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [showRequiredDocumentsModal, setShowRequiredDocumentsModal] = useState(false);

  const { data: membershipData, isLoading: isLoadingMembership } = useMemberships(
    {
      membership_id: membershipId,
    },
    {
      enabled: systemLoaded,
    }
  );

  const { data: membershipImages, isLoading: isLoadingMembershipImages } =
    useMembershipImages({ enabled: systemLoaded });

  const membership = membershipData?.data?.[0];

  useEffect(() => {
    if (membership) {
      const data = membership?.data || {};
      if (data.docs) {
        data.docs.forEach((doc: IDocument) => {
          if (!doc.id) {
            doc.id = uuidv4();
          }
        });
        setDocuments(data.docs);
      }
      setState((prevState) => ({
        ...prevState,
        ...membership,
        valid_emails: membership?.valid_emails || [],
        hide_valid_emails: membership?.hide_valid_emails || false,
        logo_image_id: membership?.logo_image_id,
        logo_image_uuid: membership?.logo_image_uuid,
        minimum_wallet_deposit: convertToCents(membership?.minimum_wallet_deposit),
        credit_card_hold: convertToCents(membership?.credit_card_hold),
        priority: membership?.priority,
        max_rentals: membership?.max_rentals,
        hidden: membership?.hidden,
        data,
      }));
    }
  }, [membership]);

  const handleFileUpload = (files: Blob[], rejected: string) => {
    if (rejected.length > 0) {
      CustomToast({
        message: 'That photo is too large',
        type: 'error',
      });
      return;
    }

    const images = files.map((file: Blob | MediaSource) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    const image = new Image();
    image.src = images[0].preview;

    image.onload = () => {
      if (image.width !== 200 || image.height !== 200) {
        CustomToast({
          message: 'Your image is not 200px by 200px, please reformat and try again',
          type: 'error',
        });
        setState((prevState) => ({
          ...prevState,
          logo: null,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          logo: files[0],
        }));
      }
    };
    setState((prevState) => ({
      ...prevState,
      logo: files[0],
    }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'hidden') {
      setState((prevState) => ({
        ...prevState,
        hidden: event.target.checked,
      }));
    } else if (event.target.name === 'hide_valid_emails') {
      setState((prevState) => ({
        ...prevState,
        hide_valid_emails: event.target.checked,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
    }
  };

  const handleChangeValidEmails = (newEmails: string[]) => {
    setState((prevState) => ({
      ...prevState,
      valid_emails: [...newEmails],
    }));
  };

  const handleColorChange = (colorEvent: { hex: string }) => {
    setState((prevState) => ({
      ...prevState,
      branding_color: colorEvent.hex,
    }));
  };
  const canSubmit = () => {
    let canSubmit = true;
    const { auth_type } = membership;

    if (auth_type === 'email' && !valid_emails) {
      canSubmit = false;
      CustomToast({
        message:
          'At least one valid email address must be entered to save this membership',
        type: 'error',
      });
    }

    // Check that the email domains are valid
    if (auth_type === 'email' && valid_emails) {
      const isValid = valid_emails.every((email: string) => EMAIL_REGEX.test(email));

      if (!isValid) {
        canSubmit = false;
        CustomToast({
          message: 'Please enter a valid email domain',
          type: 'error',
        });
      }
    }

    if (auth_type === 'password' && (password === null || password === '')) {
      canSubmit = false;
      CustomToast({
        message: 'Please enter a password to save this membership',
        type: 'error',
      });
    }

    if (minimum_wallet_deposit && Math.round(minimum_wallet_deposit * 100) < 0) {
      canSubmit = false;
      CustomToast({
        message:
          'Please use a positive number (or leave empty) for the minimum wallet balance value.',
        type: 'error',
      });
    }

    if (credit_card_hold && Math.round(credit_card_hold * 100) < 0) {
      canSubmit = false;
      CustomToast({
        message:
          'Please use a positive number (or leave empty) for the credit card hold value.',
        type: 'error',
      });
    }

    if (
      !Number.isInteger(parseInt(priority, 10)) ||
      parseInt(priority, 10) < 1 ||
      parseInt(priority, 10) > 10
    ) {
      canSubmit = false;
      CustomToast({
        message: 'Priority must be an integer value from 1 to 10.',
        type: 'error',
      });
    }

    if (
      max_rentals &&
      (!Number.isInteger(parseInt(max_rentals, 10)) || parseInt(max_rentals, 10)) < 1
    ) {
      canSubmit = false;
      CustomToast({
        message: 'Max rentals must be empty or a positive integer.',
        type: 'error',
      });
    }

    return canSubmit;
  };
  const saveMembership = async () => {
    if (canSubmit()) {
      const membershipFields = {
        branding_color: branding_color,
        password: membership.auth_type === 'password' ? password : null,
        valid_emails: membership.auth_type === 'email' ? valid_emails : null,
        hide_valid_emails: membership.auth_type === 'email' ? hide_valid_emails : false,
        minimum_wallet_deposit: minimum_wallet_deposit
          ? Math.round(minimum_wallet_deposit * 100)
          : null,
        credit_card_hold: credit_card_hold ? Math.round(credit_card_hold * 100) : null,
        priority: parseInt(priority, 10),
        max_rentals: max_rentals ? parseInt(max_rentals, 10) : null,
        hidden: hidden,
        data: {
          ...data,
          docs: documents.map((item) => {
            delete item.id;
            return item;
          }),
        } as IMembershipData,
      };

      let logoImageId = logo_image_uuid;

      if (logo) {
        const formData = new FormData();
        formData.append('membershipLogoFile', logo);
        logoImageId = await uploadImage(formData).then((response) => response.gid);
        // If logoImageId is falsy then the upload failed
        if (!logoImageId) return;
      }

      CustomAsyncToast({
        promise: () =>
          updateMembership(membership.id, {
            ...membershipFields,
            ...(logoImageId && { logo_image_id: logoImageId }),
          }),
        successMessage: () => 'Membership updated successfully',
        errorMessage: 'Error updating membership',
        loadingMessage: 'Updating membership...',
      }).then((r) => {
        if (r) {
          history.push(`/memberships/${membership.id}/general`);
        }
      });
    }
  };
  const content = () => {
    const systemUsesWallets = system.billing && system.wallet_required;
    const currentLogo = membershipImages?.find(
      (image: IImage) =>
        image.id === logo_image_id &&
        (image.type === 'MembershipLogo' || image.type === 'SystemLogo')
    );

    return (
      <Stack px={2} pb={2}>
        {/* conditionally show password/emails */}
        {membership?.auth_type === 'password' ? (
          <MovaticFieldGroupText
            id="password_container"
            name={'password_container'}
            label="Password"
            description="Users will enter the following password to gain access to this membership."
            value={password}
            onChange={(event) => {
              setState((prevState) => ({
                ...prevState,
                password: event.target.value,
              }));
            }}
          />
        ) : null}
        {membership?.auth_type === 'email' ? (
          <>
            <MovaticFieldMultiOptions
              data-id="valid_emails"
              dataId={`accepted-email-domains`}
              label="Accepted Email Domain(s)"
              description={`Enter the domains you wish to allow, starting each with the "@" symbol (e.g., "@gmail.com", "@movatic.co"). You can save each domain by pressing the "Enter" key or typing a comma (",").`}
              groupValues={valid_emails}
              onChangeInput={handleChangeValidEmails}
              errorMessage={chipError || ''}
            />

            <MovaticFieldGroupCheck
              label="Hide Accepted Email Domain(s)"
              description="Check this box if the accepted email domain(s) should not be visible to users in the app"
              id="hide_valid_emails"
              name="hide_valid_emails"
              onChange={handleChange}
              checked={hide_valid_emails}
            />
          </>
        ) : null}

        {systemUsesWallets ? (
          <MovaticFieldGroupOutlinedInput
            type={'number'}
            name="minimum_wallet_deposit"
            id="minimum_wallet_deposit"
            label="Minimum Wallet Balance"
            description="The minimum wallet balance that users charged with this rate need to have in order to start a rental. If left blank, the value in Settings will be used. Can be overriden by Memberships."
            value={minimum_wallet_deposit ?? ''}
            inputProps={{ step: 0.25, min: 0 }}
            onChange={(event) =>
              setState((prevState) => ({
                ...prevState,
                minimum_wallet_deposit: Number(event.target.value),
              }))
            }
            addOn={'$'}
          />
        ) : null}

        {system.billing ? (
          <MovaticFieldGroupOutlinedInput
            type={'number'}
            name="credit_card_hold"
            id="credit_card_hold"
            label="Credit Card Hold"
            description="The credit card hold amount that users charged with this rate will be applied in order to start a rental. If left blank, the value in Settings will be used. Can be overriden by Memberships."
            value={credit_card_hold ?? ''}
            inputProps={{ step: 0.25, min: 0 }}
            onChange={(event) =>
              setState((prevState) => ({
                ...prevState,
                credit_card_hold: Number(event.target.value),
              }))
            }
            addOn={'$'}
          />
        ) : null}

        <MovaticFieldGroupOutlinedInput
          type={'number'}
          name="priority"
          id="priority"
          label="Priority"
          description="Determines display order in the app (highest goes first). Must be a value between 1 and 10."
          value={priority ?? ''}
          inputProps={{ step: 1, min: 1, max: 10 }}
          onChange={(event) =>
            setState((prevState) => ({
              ...prevState,
              priority: event.target.value,
            }))
          }
        />

        <MovaticFieldGroupOutlinedInput
          type={'number'}
          name="max_rentals"
          id="max_rentals"
          label="Max Open Rentals"
          description="Maximum number of simultaneous rentals for a user when using this subscription."
          value={max_rentals ?? ''}
          inputProps={{ step: 1, min: 1, max: 10 }}
          onChange={(event) =>
            setState((prevState) => ({
              ...prevState,
              max_rentals: event.target.value,
            }))
          }
        />

        <MovaticFieldGroupCheck
          label="Hidden"
          description="Check this box if the membership should be hidden in the app."
          id="hidden"
          name="hidden"
          onChange={handleChange}
          checked={hidden}
        />

        <FormItem
          label="Branding Color"
          description="Branding color for the membership"
          content={
            <div className={isDarkMode ? 'dark-mode-chrome-picker' : 'none'}>
              <ChromePicker
                color={branding_color}
                onChangeComplete={(colorEvent: { hex: string }) =>
                  handleColorChange(colorEvent)
                }
              />
            </div>
          }
        />

        <FormItem
          label="Logo"
          description="The logo image must be 200px by 200px and a .png"
          content={
            <div>
              <div>
                Current Logo
                <div id="currentLogo">
                  {Boolean(currentLogo) ? (
                    <img
                      alt=""
                      src={`data:image/png;base64,${currentLogo.image}`}
                      className="logoImg"
                    />
                  ) : (
                    <NoFilePlaceHolder />
                  )}
                </div>
              </div>
              {Boolean(logo) && (
                <div>
                  <div>New Logo</div>
                  <img
                    alt=""
                    className="logoImg"
                    //@ts-ignore
                    name="bannerfile"
                    //@ts-ignore
                    src={logo.preview}
                  />
                </div>
              )}
              <div>
                <Typography>
                  <i
                    className="far fa-file-image   fieldHorizontalPadding paddingLeftClear"
                    aria-hidden="true"
                  />
                  Upload A New Logo
                </Typography>
                <FileUpload
                  caption="The file must be a .png, 200px wide, 200px high, and less than
                              1MB"
                  onDrop={handleFileUpload}
                />
              </div>
            </div>
          }
        />

        {membership?.auth_type === 'admin' && (
          <Stack pt={1}>
            <MembershipDocumentsList
              showActions
              handleAddDocument={() => {
                setShowRequiredDocumentsModal(!showRequiredDocumentsModal);
              }}
              handleRemoveDocument={(id) => {
                setDocuments(documents.filter((doc) => doc.id !== id));
              }}
              handleDocumentsTableRowClick={(id) => {
                setSelectedDocument(documents.find((doc) => doc.id === id) || null);
              }}
              docs={documents}
            />
          </Stack>
        )}

        <EditDocumentModal
          document={selectedDocument as IDocument}
          open={!!selectedDocument}
          onClose={() => setSelectedDocument(null)}
          addDocumentModalSubmit={(document) => {
            setSelectedDocument(null);
            setDocuments(
              documents.map((doc) => (doc.id === document.id ? document : doc))
            );
          }}
        />

        <AddDocumentModal
          open={showRequiredDocumentsModal}
          onClose={() => setShowRequiredDocumentsModal(!showRequiredDocumentsModal)}
          addDocumentModalSubmit={(document) => {
            setShowRequiredDocumentsModal(!showRequiredDocumentsModal);
            setDocuments([...documents, { ...document, id: uuidv4() }]);
          }}
        />
      </Stack>
    );
  };

  return (
    <PageContainer
      isLoading={isLoadingMembership || isLoadingMembershipImages || !systemLoaded}
    >
      <CardView
        title={'Edit Membership'}
        headerActions={
          <IconButtonMenu
            buttons={[
              {
                label: 'Cancel',
                onClick: () => history.push(`/memberships/${membershipId}/general`),
                startIcon: <CloseIcon />,
              },
              {
                label: 'Save',
                onClick: saveMembership,
                startIcon: <SaveIcon />,
              },
            ]}
          />
        }
        content={content()}
      />
    </PageContainer>
  );
};

export default connect(
  (state: { system: { current: ISystem; isLoaded: boolean } }) => ({
    system: state.system.current,
    systemLoaded: state.system.isLoaded,
  }),
  () => ({})
)(EditMembership);
