import React from 'react';
import Dialog from '@mui/material/Dialog';
import ModalTittle from './assets/ModalTittle';
import ModalAction from './assets/ModalAction';
import ModalContent from './assets/ModalContent';

const MovaticCustomModal = ({
  dataId = 'movatic-custom-modal',
  open,
  onClose,
  content,
  title,
  action,
  customStyles = {},
  sx = {},
  useContentDividers = false,
}) => {
  const defaultStyle = {
    title: {
      // color: `${MOVATIC_PRIMARY_COLOR(1)}`,
      fontWeight: '600',
      ...customStyles?.title,
    },
    action: {
      //padding: '1.6rem',
    },
  };
  return (
    <Dialog sx={sx} open={open} onClose={onClose} id={`${dataId}`}>
      <ModalTittle customStyle={defaultStyle.title} onClose={onClose}>
        {title}
      </ModalTittle>
      <ModalContent
        useDividers={useContentDividers}
        customStyle={customStyles?.content || { minWidth: '30rem' }}
        dividers
      >
        {content}
      </ModalContent>
      {action && (
        <ModalAction customStyle={customStyles?.action || defaultStyle.action}>
          {action}
        </ModalAction>
      )}
    </Dialog>
  );
};

export default MovaticCustomModal;
