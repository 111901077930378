import {
  baseUrlV2,
  getCredentials,
  fetchOptions,
  fetchOptionsV2,
  createQueryParameters,
  fetchPostOptions,
  baseUrl,
} from './http';
import { displayError, displaySuccess, downloadBlob } from '../utils';
import { FourOhOneError } from '../actionCreators/system';
import CustomToast from '../components/Redesing/custom-toast';

export const getRentals = (parameters) => {
  const { adminEmail, adminToken, systemGid } = getCredentials();
  const fetchOps = fetchOptionsV2(adminEmail, adminToken);
  const queryParametersObject = {
    system_id: systemGid,
    ...parameters,
  };
  const queryParameters = createQueryParameters(queryParametersObject);

  return fetch(`${baseUrlV2}/rental/?${queryParameters}`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response?.data) {
        return response;
      }

      throw new Error(response?.error_description);
    })
    .catch((e) => {
      displayError(
        'Oops! Looks like there was an error while loading rentals. Please try again later.'
      );
      return [];
    });
};

export const getRentalRoute = (rentalId, parameters, paginationResultHandler) => {
  const { adminEmail, adminToken } = getCredentials();
  const fetchOps = fetchOptionsV2(adminEmail, adminToken);
  const queryParametersObject = {
    limit: 500,
    ...parameters,
  };
  const queryParameters = createQueryParameters(queryParametersObject);

  return fetch(`${baseUrlV2}/rental/${rentalId}/route?${queryParameters}`, fetchOps)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText || response.status);
      }
      return response;
    })
    .then((response) => response.json())
    .then((response) => {
      if (response?.data) {
        return response;
      }

      throw Error(response?.error_description);
    })
    .catch((e) => {
      displayError(
        'Oops! Looks like there was an error while loading the rental route information. Please try again later.'
      );

      return {
        data: [],
      };
    })
    .then((response) => {
      if (paginationResultHandler) {
        paginationResultHandler({ data: response.data, hasMore: response.has_more });

        if (response.has_more) {
          let lastRecordedOn;

          response.data.forEach((source) => {
            const sourceLastRecordedOn =
              source.route[source.route.length - 1].recorded_on;

            if (
              !lastRecordedOn ||
              new Date(sourceLastRecordedOn) > new Date(lastRecordedOn)
            ) {
              lastRecordedOn = sourceLastRecordedOn;
            }
          });

          return getRentalRoute(
            rentalId,
            {
              ...queryParametersObject,
              end: lastRecordedOn,
            },
            paginationResultHandler
          );
        }
      }

      return response.data;
    });
};

export const startRental = (body) => {
  const { adminEmail, adminToken } = getCredentials();
  const fetchOps = fetchOptionsV2(adminEmail, adminToken, 'POST', body);

  return fetch(`${baseUrlV2}/rental/`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response?.data?.id) {
        return response.data;
      }
      throw new Error(response?.error_description);
    })
    .catch((error) => {
      return Promise.reject(`${error}`);
    });
};

export const endRental = ({ rentalId, body }) => {
  const { adminEmail, adminToken } = getCredentials();
  const fetchOps = fetchOptionsV2(adminEmail, adminToken, 'POST', body);

  return fetch(`${baseUrlV2}/rental/${rentalId}/end`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response?.data?.id) {
        return response.data;
      }
      throw new Error(`${response?.error_description}`);
    })
    .catch((e) => {
      return Promise.reject(`${e}`);
    });
};

export const exportRentals = (parameters) => {
  const { adminEmail, adminToken, systemGid } = getCredentials();
  const fetchOps = fetchOptionsV2(adminEmail, adminToken);
  const queryParametersObject = {
    system_id: systemGid,
    ...parameters,
  };
  const queryParameters = createQueryParameters(queryParametersObject);

  return fetch(`${baseUrlV2}/rental/export?${queryParameters}`, fetchOps)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText || response.status);
      }
      return response;
    })
    .then((response) => response.blob())
    .then((blob) => downloadBlob(blob, 'rentals.csv'))
    .then(() => displaySuccess('The rentals have been exported successfully!'))
    .catch((error) => {
      displayError(
        'Oops! An error has occurred while exporting the rentals. Please try again later.',
        error
      );
    });
};

export const exportLocationRentalsRoutes = (locationUUID, parameters) => {
  const { adminEmail, adminToken, systemGid } = getCredentials();

  const fetchOps = fetchOptionsV2(adminEmail, adminToken);
  const queryParametersObject = {
    system_id: systemGid,
    ...parameters,
  };
  const queryParameters = createQueryParameters(queryParametersObject);

  return fetch(
    `${baseUrl}/rental/${locationUUID}/export_routes/${adminEmail}?${queryParameters}`,
    fetchOps
  )
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText || response.status);
      }
      displaySuccess('An email with the location rentals routes has been sent!');
      return response.json();
    })
    .catch((error) => {
      displayError(
        'Oops! An error has occurred while exporting the rentals. Please try again later.',
        error
      );
    });
};

export const exportSystemRentalsRoutes = (parameters) => {
  const { adminEmail, adminToken, systemGid, systemId } = getCredentials();

  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);
  const queryParametersObject = {
    system_id: systemGid,
    ...parameters,
  };
  const queryParameters = createQueryParameters(queryParametersObject);

  return fetch(
    `${baseUrl}/rental/export_routes/${adminEmail}?${queryParameters}`,
    fetchOps
  )
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText || response.status);
      }
      displaySuccess('An email with the system rentals routes has been sent!');
      return response.json;
    })
    .catch((error) => {
      displayError(
        'Oops! An error has occurred while exporting the rentals. Please try again later.',
        error
      );
    });
};

export const updatePendingCharge = (rentalData) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, rentalData, 'PUT');

  return fetch(`${baseUrl}/billing/rentals/updatependingstatus`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results.status === 200) {
        return results.message;
      } else {
        FourOhOneError(results);
        throw new Error(results.message);
      }
    })
    .catch((error) => {
      displayError(
        'It looks like there was an error on our end while trying to update that pending rental. Please try again!'
      );
    });
};

export const chargeRental = ({ rentalId, rentalData }) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, rentalData, 'PUT');
  return fetch(`${baseUrl}/rental/charge/${rentalId}`, fetchOps)
    .then((results) => {
      if (results.status === 200) {
        return results.json();
      } else {
        throw new Error();
      }
    })
    .catch(() => {
      CustomToast({
        type: 'error',
        message: 'Oops! Looks like there was an error while trying to charge the rental.',
      });
      return Promise.reject();
    });
};

export const getPendingCharges = () => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);
  return fetch(`${baseUrl}/billing/rentals/pending`, fetchOps)
    .then((response) => response.json())
    .then((pendingCharges) => {
      if (pendingCharges.status === 200) {
        return pendingCharges.message;
      }
      FourOhOneError(pendingCharges);
      throw new Error(pendingCharges.message);
    })
    .catch((e) => {
      displayError(
        `Oops! Looks like there was an error while trying to get the pending charges. ${e}`
      );
    });
};
