import React from 'react';
import DashboardChart from './dashboard-chart';
import { IChartData } from '../../../ts/interface/dashboardGraph.interface';
import Card from '@mui/material/Card';
import { Stack } from '@mui/system';
import { formatNumberWithCommas } from '../../../utils';

interface YearGraphsProps {
  rentalAvg: string;
  rentalTotal: number;
  rentalData: IChartData;
  userAvg: string;
  userTotal: number;
  userData: IChartData;
  durationAvg: string;
  durationTotal: number;
  durationData: IChartData;
  systemTimezoneLabel: string;
}

const YearGraphs: React.FC<YearGraphsProps> = ({
  rentalAvg,
  rentalTotal,
  rentalData,
  userAvg,
  userTotal,
  userData,
  durationAvg,
  durationTotal,
  durationData,
  systemTimezoneLabel,
}) => {
  return (
    <Stack>
      <Stack pt={2} px={2}>
        <Card>
          <DashboardChart
            id={'rental'}
            data={rentalData}
            total={formatNumberWithCommas(rentalTotal)}
            average={formatNumberWithCommas(rentalAvg)}
            systemTimezoneLabel={systemTimezoneLabel}
            title={'Rentals Per Month'}
            showTimezone
            statTittleOne={'Average'}
            statTittleTwo={'Total'}
          />
        </Card>
      </Stack>
      <Stack pt={2} px={2}>
        <Card>
          <DashboardChart
            id={'user'}
            data={userData}
            total={formatNumberWithCommas(userTotal)}
            average={formatNumberWithCommas(userAvg)}
            systemTimezoneLabel={systemTimezoneLabel}
            showTimezone
            title={'New Users Per Month'}
            statTittleOne={'Average'}
            statTittleTwo={'Total'}
          />
        </Card>
      </Stack>
      <Stack pt={2} px={2} pb={2}>
        <Card>
          <DashboardChart
            id={'duration'}
            data={durationData}
            total={formatNumberWithCommas(durationTotal)}
            average={formatNumberWithCommas(durationAvg)}
            showTimezone
            systemTimezoneLabel={systemTimezoneLabel}
            title={'Duration of Rentals'}
            statTittleOne={'Average'}
            statTittleTwo={'Total'}
          />
        </Card>
      </Stack>
    </Stack>
  );
};

export default YearGraphs;
