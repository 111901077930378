import React from 'react';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import ScrollToTop from './util/components/ScrollToTop';
// ON LOAD FUNCTIONS
import { checkPermissions } from './onLoadFunctions';

// components
import Welcome from './pages/dashboard/welcome';
import App from './app';
import Billing from './pages/billing/index';
import Refund from './pages/refund/Refund';
import SettingsDetailR from './pages/settings';

// Memberships
import Memberships from './pages/memberships/index';
import AddMembershipR from './pages/memberships/addMembership';
import MembershipDetail from './pages/memberships/membershipDetail';
import EditMembership from './pages/memberships/editMembership';
import UserMembershipDetails from './pages/memberships/userMembershipDetails';

// RATE V2
import RatesV2Add from './pages/ratesv2/AddRates';
import ViewRate from './pages/ratesv2/ViewRates';
import EditRate from './pages/ratesv2/EditRates';

// Fees
import AddFee from './pages/billing/fees/addFee';
import EditFee from './pages/billing/fees/editFee';
import ViewFee from './pages/billing/fees/viewFee';

// new hardware components
import HardwareR from './pages/hardware/index';
import NewHardwareAdd from './pages/hardware/hardwareAddNew';
import NewHardwareDetail from './pages/hardware/newHardwareDetail';
import NewHardwareEdit from './pages/hardware/newHardwareEdit';
import NewHardwareAddFromCsv from './pages/hardware/newHardwareAddFromCsv';
import AddHardwareAssignment from './pages/hardware/addHardwareAssignment';

// user components
import UsersR from './pages/userPages/index';
import UserDetailR from './pages/userPages/userDetails';
import UserAdd from './pages/userPages/addUser';
import UserEdit from './pages/userPages/editUser';
import UserAddFromCsv from './pages/userPages/addUsersFromCsv';
import UserAddRfidCard from './pages/userPages/addUserRfidCard';
import UserRfidCardDetails from './pages/userPages/userDetailsAssets/userRfidCardDetail';
import EditUserRfidCard from './pages/userPages/editUserRfidCard';
import UserAddRental from './pages/userPages/rentals/addRental';

// rental components
import RentalR from './pages/rentals/rentals';
import RentalDetailsR from './pages/rentals/rentalDetail';

// station components
import StationR from './pages/stations/station';
import StaionDetailsR from './pages/stations/stationDetails';
import StationAddR from './pages/stations/stationAdd';
import StationEditR from './pages/stations/stationEdit';

// Reported Problems component
import ProblemDetail from './pages/problems/problemDetails';

// Dispute Component
import DisputeDetails from './pages/billing/disputeDetails';

// Transactions
import TransactionDetailModal from './pages/transactions/transactionDetailModal';

// System components
// import System from './pages/systems/systems';
import AddSystem from './pages/systems/add';
import adminDetails from './pages/settings/admin/admin-details';
import AddAdmin from './pages/settings/admin/admin-add';

// Coupons
import AddCoupon from './pages/coupons/couponAdd';
import ViewCoupon from './pages/coupons/couponDetail';
import EditCoupon from './pages/coupons/couponEdit';

// Maintenance
import Maintenance from './pages/maintenance/index';
import TicketDetail from './pages/maintenance/ticketdetail';
import TaskDetail from './pages/maintenance/taskdetail';
import EditTicket from './pages/maintenance/editticket';
import AddCompletedTask from './pages/maintenance/addCompletedTask';
import AddTicket from './pages/maintenance/addticket';

// MOVATIC ONLY
// Image Approval
import Images from './pages/imageApproval';
// add hardware product/version
import AddHardwareProduct from './pages/hardwareproduct/addproduct';
import AddProductVersion from './pages/hardwareproduct/addversion';

// components
import LoginPage from './pages/login';

import store from './store';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from './theme';

// issuing
import AddIssuingCard from './pages/billing/issuing/issuingCardAdd';
import ProblemsOpen from './pages/problems/problems';
import membershipDetail from './pages/memberships/membershipDetail';
import withSystemId from './hoc/withSystemId';

const queryClient = new QueryClient();

export default class Root extends React.Component {
  constructor(props) {
    super(props);

    this.addNotification = this.addNotification.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.error !== nextProps.error ||
      this.props.success !== nextProps.success
    ) {
      if (nextProps.error) {
        this.addNotification(nextProps.error, 'error', 'Error!');
        this.props.clearToast('CLEAR_ERROR');
      }
      if (nextProps.success) {
        this.addNotification(nextProps.success, 'success', 'Congratulations!', 8);
        this.props.clearToast('CLEAR_SUCCESS');
      }
    }
  }

  addNotification(message, type, title, dismiss = 0) {
    if (this.refs.notificationSystem) {
      this.refs.notificationSystem.addNotification({
        message,
        level: type,
        autoDismiss: dismiss,
        title,
      });
    }
  }

  render() {
    const AppRoutes = () => (
      <Switch>
        <Route exact path="/" component={LoginPage} />

        <Route path="/(resetpassword|setpassword)">
          <Switch>
            <Route exact path="/login" component={LoginPage} />
            <Route
              exact
              path="/:page(resetpassword|setpassword)/:email?/:token?"
              component={LoginPage}
            />
          </Switch>
        </Route>

        <Route>
          <App>
            <Switch>
              <Route exact path="/dashboard" component={withSystemId(Welcome)} />

              {/* USERS  */}
              <Route path="/users">
                <Route
                  render={() => {
                    checkPermissions(['users']);
                  }}
                />
                <Switch>
                  <Route exact path="/users/" component={withSystemId(UsersR)} />
                  <Route path="/users/">
                    <Switch>
                      <Route exact path="/users/add" component={withSystemId(UserAdd)} />
                      <Route
                        exact
                        path="/users/add_csv"
                        component={withSystemId(UserAddFromCsv)}
                      />
                      <Route
                        exact
                        path="/users/:userPhone/edit"
                        component={withSystemId(UserEdit)}
                      />
                      <Route
                        exact
                        path="/users/:userPhone/add-rfid"
                        component={withSystemId(UserAddRfidCard)}
                      />
                      <Route
                        exact
                        path="/users/:userPhone/rfid/:cardId"
                        component={withSystemId(UserRfidCardDetails)}
                      />
                      <Route
                        exact
                        path="/users/:userPhone/rfid/:cardId/edit"
                        component={withSystemId(EditUserRfidCard)}
                      />
                      <Route
                        exact
                        path="/users/:userPhone/:currentTab?"
                        component={withSystemId(UserDetailR)}
                      />
                      <Route
                        exact
                        path="/users/:userPhone/rentals/add"
                        onEnter={checkPermissions(['rental'])}
                        component={withSystemId(UserAddRental)}
                      />
                      <Route render={() => <Redirect to="/users/" />} />
                    </Switch>
                  </Route>
                </Switch>
              </Route>
              {/* RENTALS */}
              <Route path="/rentals">
                <Route
                  render={() => {
                    checkPermissions(['rental']);
                  }}
                />
                <Switch>
                  <Route exact path="/rentals" component={withSystemId(RentalR)} />
                  <Route
                    exact
                    path="/rentals/:rentalId"
                    component={withSystemId(RentalDetailsR)}
                  />
                  {/*<Route render={() => <Redirect to="/rentals" />} />*/}
                  <Route
                    exact
                    path="/rentals/:rentalId/:currentTab(details|support|logs|hardware-logs)?"
                    component={withSystemId(RentalDetailsR)}
                  />
                </Switch>
              </Route>
            </Switch>

            {/* new */}
            <Route path="/hardware">
              <Route
                render={() => {
                  checkPermissions(['physical']);
                }}
              />
              <Switch>
                <Route
                  exact
                  path="/hardware/add"
                  component={withSystemId(NewHardwareAdd)}
                />
                <Route
                  exact
                  path="/hardware/add_csv"
                  component={withSystemId(NewHardwareAddFromCsv)}
                />
                <Route
                  exact
                  path="/hardware/:type?"
                  component={withSystemId(HardwareR)}
                />
                <Route
                  exact
                  path="/hardware/:type/:hardwareId/edit"
                  component={withSystemId(NewHardwareEdit)}
                />
                <Route
                  exact
                  path="/hardware/:type/:hardwareId/:currentTab(general|rentals|maintenance-tickets|maintenance-logs|event-logs)?"
                  component={withSystemId(NewHardwareDetail)}
                />
                <Route
                  exact
                  path="/hardware/:type/:hardwareId/add-assignment"
                  component={withSystemId(AddHardwareAssignment)}
                />
              </Switch>
            </Route>

            <Route path="/locations">
              <Route
                render={() => {
                  checkPermissions(['locations']);
                }}
              />
              <Switch>
                <Route exact path="/locations/" component={withSystemId(StationR)} />
                <Route
                  exact
                  path="/locations/add"
                  component={withSystemId(StationAddR)}
                />
                <Route
                  exact
                  path="/locations/:stationId/edit"
                  component={withSystemId(StationEditR)}
                />
                <Route
                  exact
                  path="/locations/:currentTab(locations|map)?"
                  component={withSystemId(StationR)}
                />
                <Route
                  exact
                  path="/locations/:stationId/:currentTab(general|rentals)?"
                  component={withSystemId(StaionDetailsR)}
                />
              </Switch>
            </Route>

            <Route path="/settings">
              <Route
                render={() => {
                  checkPermissions(['bank']);
                }}
              />
              <Switch>
                <Route
                  exact
                  path="/settings/admin/add"
                  component={withSystemId(AddAdmin)}
                />
                <Route
                  exact
                  path="/settings/admin/:email"
                  component={withSystemId(adminDetails)}
                />
                <Route
                  exact
                  path="/settings/:currentTab(general|rental|opendata|billing|billingWarning|branding|brandingWarning|terms|termsWarning|admin)?"
                  component={withSystemId(SettingsDetailR)}
                />
              </Switch>
            </Route>

            <Route path="/problems">
              <Route
                render={() => {
                  checkPermissions(['support']);
                }}
              />
              <Switch>
                <Route exact path="/problems" component={withSystemId(ProblemsOpen)} />
                <Route
                  exact
                  path="/problems/:currentTab(tickets|tags)?"
                  component={withSystemId(ProblemsOpen)}
                />
                <Route
                  exact
                  path="/problems/:problemId"
                  component={withSystemId(ProblemDetail)}
                />
              </Switch>
            </Route>

            <Route path="/maintenance">
              <Route
                render={() => {
                  checkPermissions(['maintenance']);
                }}
              />
              <Switch>
                <Route
                  exact
                  path="/maintenance/add"
                  component={withSystemId(AddTicket)}
                />
                <Route
                  exact
                  path="/maintenance/add/report/:reportId"
                  component={withSystemId(AddTicket)}
                />
                <Route
                  exact
                  path="/maintenance/add/unit/:hardwareId"
                  component={withSystemId(AddTicket)}
                />
                <Route
                  exact
                  path="/maintenance/:currentTab(tickets|logs|tags)?"
                  component={withSystemId(Maintenance)}
                />
                <Route
                  exact
                  path="/maintenance/log/add/unit/:hardwareId"
                  component={withSystemId(AddCompletedTask)}
                />
                <Route
                  exact
                  path="/maintenance/log/add"
                  component={withSystemId(AddCompletedTask)}
                />
                <Route
                  exact
                  path="/maintenance/log/:taskId"
                  component={withSystemId(TaskDetail)}
                />
                <Route
                  exact
                  path="/maintenance/edit/:ticketId"
                  component={withSystemId(EditTicket)}
                />
                <Route
                  exact
                  path="/maintenance/:ticketId"
                  component={withSystemId(TicketDetail)}
                />
                <Route
                  exact
                  path="/maintenance/close/:ticketId"
                  component={withSystemId(AddCompletedTask)}
                />
              </Switch>
            </Route>

            <Route path="/memberships">
              <Route
                render={() => {
                  checkPermissions(['memberships']);
                }}
              />
              <Switch>
                <Route
                  exact
                  path="/memberships/add"
                  component={withSystemId(AddMembershipR)}
                />
                <Route
                  exact
                  path="/memberships/user/:membershipId"
                  component={withSystemId(UserMembershipDetails)}
                />
                <Route
                  exact
                  path="/memberships/edit/:membershipId"
                  component={withSystemId(EditMembership)}
                />
                <Route
                  exact
                  path="/memberships/:currentTab(memberships|pending)?"
                  component={withSystemId(Memberships)}
                />
                <Route
                  exact
                  path="/memberships/:membershipId"
                  component={withSystemId(MembershipDetail)}
                />
                <Route
                  exact
                  path="/memberships/:membershipId/:currentTab(general|members)?"
                  component={withSystemId(membershipDetail)}
                />
              </Switch>
            </Route>

            <Route path="/billing" onEnter={checkPermissions(['rates', 'refunds'])}>
              <Route
                render={() => {
                  checkPermissions(['rates', 'refunds']);
                }}
              />
              <Switch>
                <Route
                  exact
                  path="/billing/:currentTab(overview|rates|fees|coupons|transactions|disputes|disputesWarning|pending|issuing|issuingbalance)?"
                  component={withSystemId(Billing)}
                />
                <Route
                  exact
                  path="/billing/coupons/add"
                  component={withSystemId(AddCoupon)}
                />
                <Route
                  exact
                  path="/billing/rates/add"
                  component={withSystemId(RatesV2Add)}
                />
                <Route
                  exact
                  path="/billing/rates/:rateId"
                  component={withSystemId(ViewRate)}
                />
                <Route
                  exact
                  path="/billing/rates/:rateId/edit"
                  component={withSystemId(EditRate)}
                />
                <Route exact path="/billing/fees/add" component={withSystemId(AddFee)} />
                <Route
                  exact
                  path="/billing/fees/:feeId"
                  component={withSystemId(ViewFee)}
                />
                <Route
                  exact
                  path="/billing/fees/:feeId/edit"
                  component={withSystemId(EditFee)}
                />
                <Route
                  exact
                  path="/billing/coupon/edit/:couponId"
                  component={withSystemId(EditCoupon)}
                />
                <Route
                  exact
                  path="/billing/coupon/:couponId"
                  component={withSystemId(ViewCoupon)}
                />
                <Route
                  exact
                  path="/billing/transactions/:transactionId"
                  component={withSystemId(TransactionDetailModal)}
                />
                <Route
                  exact
                  path="/billing/transactions/detail/:transactionId"
                  component={withSystemId(TransactionDetailModal)}
                />
                <Route
                  exact
                  path="/billing/disputes/:disputeId"
                  component={withSystemId(DisputeDetails)}
                />
                <Route
                  exact
                  path="/billing/issuing/add"
                  component={withSystemId(AddIssuingCard)}
                />
              </Switch>
            </Route>
            <Route path="/refund">
              <Route
                render={() => {
                  checkPermissions(['refunds']);
                }}
              />
              <Route exact path="/refund/:rentalId" component={withSystemId(Refund)} />
            </Route>

            {/*<Route exact path="/systems" component={System} />*/}

            <Route path="/(approve-image|addproduct|addversion)">
              <Route
                render={() => {
                  checkPermissions(['movaticAccess']);
                }}
              />
              <Switch>
                <Route exact path="/approve-image" component={withSystemId(Images)} />
                <Route
                  exact
                  path="/addproduct"
                  component={withSystemId(AddHardwareProduct)}
                />
                <Route
                  exact
                  path="/addversion"
                  component={withSystemId(AddProductVersion)}
                />
              </Switch>
            </Route>

            <Route path="/addSystem">
              <Route
                render={() => {
                  checkPermissions(['partner']);
                }}
              />
              <Route exact path="/addSystem" component={withSystemId(AddSystem)} />
            </Route>
          </App>
        </Route>
      </Switch>
    );
    const theme = createTheme({
      colorPreset: 'blue',
      contrast: 'normal',
      direction: 'ltr',
      paletteMode: 'dark',
      responsiveFontSizes: true,
    });
    return (
      <Router>
        <React.StrictMode>
          <ScrollToTop />
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <ThemeProvider theme={theme}>
                <AppRoutes />
              </ThemeProvider>
            </QueryClientProvider>
          </Provider>
        </React.StrictMode>
      </Router>
    );
  }
}
