import { useQuery } from '@tanstack/react-query';
import { IUseHardwareFilter } from '../../../../ts/interface/hardware.interface';
import { getHardwareV2 } from '../../../../api/hardwareV2';

const useHardwareV2 = (parameters?: IUseHardwareFilter, options?: {}) => {
  return useQuery(['hardwareV2', parameters], () => getHardwareV2(parameters), {
    ...options,
  });
};

export default useHardwareV2;
