import React, { useState, useEffect, useCallback } from 'react';

import { history } from '../../../utils';
import feeTableColumns from './feeTableColumns';
import { getFees } from '../../../api/fees';
import { PaginationTable } from '../../../components/Redesing/table/pagination-table';
import RowItem from '../../../components/Redesing/row-item';
import { Autocomplete, TextField } from '@mui/material';
import Chip from '@mui/material/Chip';
import {
  FilterChips,
  IColumn,
} from '../../../ts/interface/components/redesing/table.interface';
import { SearchChip } from '../../../ts/interface/components/table.interface';
import { useUpdateEffect } from '../../../hooks/use-update-effect';
import Card from '@mui/material/Card';
import AddIcon from '@mui/icons-material/Add';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants';

const filterOptions = [
  { value: 'active', label: 'Active' },
  { value: 'archived', label: 'Archived' },
];

const FeesTable = () => {
  const [pageNumberV2, setPageNumberV2] = useState<number>(0);
  const [sizePerPageV2, setSizePerPageV2] = useState<number>(DEFAULT_ROWS_PER_PAGE);
  const [sortData, setSortData] = useState<string>('created_on');
  const [search, setSearch] = useState<string>('');
  const [chips, setChips] = useState<SearchChip[]>([]);
  const [fees, setFees] = useState<any[]>([]);
  const [filterChips, setFilterChips] = useState<FilterChips>({} as FilterChips);
  const [value, setValue] = useState<any[]>([]);
  const [exportData, setExportData] = useState<boolean>(false);

  const handleChipsUpdate = useCallback(() => {
    const filtersAux: FilterChips = {
      status: [],
    };
    chips.forEach((chip) => {
      switch (chip.field) {
        case 'status':
          // @ts-ignore
          filtersAux.status.push(chip.value);
          break;
        default:
          break;
      }
    });
    setFilterChips(filtersAux);
  }, [chips]);

  const handleStatusChange = useCallback((values: string[]): void => {
    setPageNumberV2(0);
    setSizePerPageV2(10);
    setChips((prevChips) => {
      const valuesFound: string[] = [];

      const newChips = prevChips.filter((chip) => {
        if (chip.field !== 'status') {
          return true;
        }

        const found = values.includes(chip.value as string);

        if (found) {
          valuesFound.push(chip.value as string);
        }

        return found;
      });

      if (values.length === valuesFound.length) {
        return newChips;
      }

      values.forEach((value) => {
        if (!valuesFound.includes(value)) {
          const option = filterOptions.find(
            (option) => option.value.toString() === value
          );
          newChips.push({
            label: 'Status',
            field: 'status',
            value,
            displayValue: option!.label,
          });
        }
      });
      return newChips;
    });
  }, []);

  useUpdateEffect(() => {
    handleChipsUpdate();
  }, [chips, handleChipsUpdate]);

  const handleChipDelete = useCallback(
    (deletedGroup: SearchChip) => {
      setChips((prevChips) => {
        return prevChips.filter((group) => {
          return group.label !== deletedGroup.label;
        });
      });
      setValue([]);
      handleChipsUpdate();
    },
    [handleChipsUpdate]
  );

  useEffect(() => {
    getFees({
      //@ts-ignore
      status: filterChips.status?.length > 0 ? filterChips.status?.join(',') : 'all',
    }).then(setFees);
  }, [filterChips]);

  return (
    <Card>
      <PaginationTable
        showFilter
        padding={{ pt: 1, pl: 0, pr: 1 }}
        chips={chips}
        handleChipDelete={handleChipDelete}
        buttons={[
          {
            label: 'Export',
            startIcon: <FileDownloadIcon />,
            onClick: () => setExportData(true),
          },
          {
            label: 'Add',
            startIcon: <AddIcon />,
            onClick: () => history.push('/billing/fees/add'),
          },
        ]}
        exportData={exportData}
        csvFileName={'Disputes.csv'}
        onExport={() => setExportData(false)}
        spaceFilters={
          <>
            <RowItem label={'Status'}>
              <Autocomplete
                multiple
                isOptionEqualToValue={(option, value) => {
                  return option.value === value.value;
                }}
                value={value}
                onChange={(event, newValue) => {
                  setValue([
                    ...newValue.filter((option) =>
                      filterOptions.some((item) => item.label === option.label)
                    ),
                  ]);
                  handleStatusChange([
                    ...newValue
                      .filter((option) =>
                        filterOptions.some((item) => item.label === option.label)
                      )
                      .map((item) => item.value.toString()),
                  ]);
                }}
                options={filterOptions}
                getOptionLabel={(option) => option.label}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      label={option.label}
                      {...getTagProps({ index })}
                      disabled={false}
                    />
                  ))
                }
                style={{ width: 340 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      '& .MuiInputBase-root': {
                        padding: 0,
                        paddingLeft: '1.5rem',
                        paddingRight: '1.5rem',
                      },
                      '& .MuiInputLabel-root': {
                        display: 'none',
                      },
                    }}
                  />
                )}
              />
            </RowItem>
          </>
        }
        onRowCLick={(id: string) => history.push(`/billing/fees/${id}`)}
        sortBy={sortData}
        onSortChange={(sort) => setSortData(sort.key)}
        columns={feeTableColumns as IColumn[]}
        items={fees || []}
        searchPlaceholder={`Search fees`}
        page={pageNumberV2}
        rowsPerPage={sizePerPageV2}
        onPageChange={(page, newPage) => {
          setPageNumberV2(newPage);
        }}
        onRowsPerPageChange={(pageSizeString) => {
          const pageSize = Number(pageSizeString.target.value);
          setSizePerPageV2(pageSize);
        }}
        handleSearch={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearch(e.target.value)
        }
        searchText={search}
      />
      {/*<TableFilter*/}
      {/*  label="Fee Status"*/}
      {/*  options={[*/}
      {/*    { value: '', name: 'All Fees', code: '' },*/}
      {/*    { value: 'active', name: 'Active' },*/}
      {/*    { value: 'archived', name: 'Archived' },*/}
      {/*  ]}*/}
      {/*  name="hardwareStateFilter"*/}
      {/*  value={props.status}*/}
      {/*  handleChange={(event) => props.setStatus(event.target.value)}*/}
      {/*/>*/}
      {/*<TableComponent*/}
      {/*  tableInfo={{*/}
      {/*    data: props.fees,*/}
      {/*    columns: feeTableColumns,*/}
      {/*    csv: 'feeList.csv',*/}
      {/*    defaultSort: 'created_on',*/}
      {/*    defaultSortOrder: 'desc',*/}
      {/*    noDataText: 'There are currently no fees available that meet this criteria',*/}
      {/*    whichComponent: 'activeFees',*/}
      {/*    pageSize: 25,*/}
      {/*  }}*/}
      {/*  add={() => history.push('/billing/fees/add')}*/}
      {/*  rowClick={(fee) => history.push(`/billing/fees/${fee.id}`)}*/}
      {/*  addButtonState*/}
      {/*/>*/}
    </Card>
  );
};

export default FeesTable;
