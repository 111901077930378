import { useQuery } from '@tanstack/react-query';
import { getOneProblem } from '../../../api/problems';

const useOneProblem = (reportId: String, options?: {}) => {
  return useQuery(['oneProblem', reportId], () => getOneProblem(reportId), {
    ...options,
  });
};

export default useOneProblem;
