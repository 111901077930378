export const userPermissions = {
  PARTNER: 'partner',
  ADMIN: 'admin',
  RENTAL: 'rental',
  RATES: 'rates',
  REFUNDS: 'refunds',
  BANK: 'bank',
  PHYSICAL: 'physical',
  USERS: 'users',
  LOCATIONS: 'locations',
  SUPPORT: 'support',
  MAINTENANCE: 'maintenance',
  MEMBERSHIPS: 'memberships',
  DOCUMENTATION: 'documentation',
};
