import React, { useEffect, useState } from 'react';
import { getLocaleDateTimeString, history, toTitleCase } from '../../../utils';
import useUserMembership from '../../../hooks/users/query/useUserMembership';
import { IMembership, IUserMembership } from '../../../ts/interface/membership.interface';
import AddUserMembership from './addUserMembership';
import { UserInterface } from '../../../ts/interface/user.interface';
import CardView from '../../../components/Redesing/card-view';
import { PaginationTable } from '../../../components/Redesing/table/pagination-table';
import { IColumn } from '../../../ts/interface/components/redesing/table.interface';
import AddIcon from '@mui/icons-material/Add';
import { SeverityPill } from '../../../components/Redesing/severity-pill';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import CustomAsyncToast from '../../../components/Redesing/custom-async-toast';
import { membershipApproval } from '../../../api/memberships';
import { Stack } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants';

const UserMemberships = ({
  user,
  documentationLink,
}: {
  user: UserInterface;
  documentationLink?: string;
}) => {
  const [membershipsLoaded, setMembershipsLoaded] = useState<IMembership[]>([]);
  const [queryLimit, setQueryLimit] = useState(DEFAULT_ROWS_PER_PAGE);
  const [queryAfter, setQueryAfter] = useState<string | null>();
  const [showAddUserMembershipModal, setShowAddUserMembershipModal] = useState(false);
  const [search, setSearch] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [sortBy, setSortBy] = useState('created_on');

  const { refetch: refetchMemberships } = useUserMembership(
    {
      user_id: user?.userDetails?.id,
      limit: queryAfter ? queryLimit : queryLimit + 1,
      after: queryAfter,
    },
    { enabled: false }
  );

  useEffect(() => {
    refetchMemberships().then((res) => {
      const { data } = res;
      if (queryAfter) {
        if (data) setMembershipsLoaded((memberships) => [...memberships, ...data]);
      } else {
        setMembershipsLoaded(data);
      }
    });
  }, [queryAfter, queryLimit, refetchMemberships, user]);

  return (
    <>
      <CardView
        documentationLink={documentationLink}
        headerActions={
          <IconButtonMenu
            buttons={[
              {
                label: 'Add Membership',
                onClick: () => setShowAddUserMembershipModal(true),
                startIcon: <AddIcon />,
              },
            ]}
          />
        }
        content={
          <>
            <PaginationTable
              onRowCLick={(id: string) => history.push(`/memberships/user/${id}`)}
              handleSearch={(search) => setSearch(search.target.value)}
              searchText={search}
              sortBy={sortBy}
              onSortChange={(sort) => setSortBy(sort.key)}
              columns={
                [
                  {
                    id: 'membership.name',
                    label: 'Name',
                    searchable: true,
                    format: (row: IUserMembership) => {
                      return row?.membership?.name;
                    },
                  },
                  {
                    id: 'created_on',
                    label: 'Created On',
                    searchable: true,
                    format: (row: IUserMembership) => {
                      const date = row?.created_on;
                      if (date) {
                        return getLocaleDateTimeString(date);
                      }
                      return ' ';
                    },
                  },
                  {
                    id: 'canceled_on',
                    label: 'Canceled',
                    format: (row) => {
                      const date = row?.canceled_on;
                      if (date) {
                        return getLocaleDateTimeString(date);
                      }
                      return ' ';
                    },
                  },
                  {
                    id: 'auth_type',
                    label: 'Approval',
                    format: (row: IUserMembership) => {
                      if (row.canceled_on) {
                        if (
                          row.membership.archived_on &&
                          new Date(row.canceled_on) <=
                            new Date(row.membership.archived_on)
                        ) {
                          return <SeverityPill color={'warning'}>Archived</SeverityPill>;
                        }
                        return <SeverityPill color={'error'}>Canceled</SeverityPill>;
                      }
                      if (
                        row.rejected_on &&
                        row.membership.archived_on &&
                        new Date(row.membership.archived_on) < new Date()
                      ) {
                        return <SeverityPill color={'warning'}>Archived</SeverityPill>;
                      }
                      if (row.expires_on && new Date(row.expires_on) < new Date()) {
                        return <SeverityPill color={'warning'}>Expired</SeverityPill>;
                      }
                      if (row.membership.auth_type === 'admin') {
                        if (row.approved_on) {
                          return <SeverityPill color={'success'}>Approved</SeverityPill>;
                        }
                        if (row.rejected_on) {
                          return <SeverityPill color={'error'}>Rejected</SeverityPill>;
                        }
                        return (
                          <Stack justifyContent={'flex-start'} alignItems={'flex-start'}>
                            <IconButtonMenu
                              slice={1}
                              buttons={[
                                {
                                  label: 'Approve',
                                  startIcon: <CheckCircleIcon />,
                                  onClick: (e: React.ChangeEvent<HTMLInputElement>) => {
                                    e.stopPropagation();
                                    CustomAsyncToast({
                                      promise: () =>
                                        membershipApproval({
                                          body: { approval: 'accept' },
                                          userId: row.id,
                                        }),
                                      successMessage: () =>
                                        'Membership approved successfully',
                                      errorMessage: 'Error approving membership',
                                      loadingMessage: 'Approving membership...',
                                    }).then((r) => {
                                      if (r) {
                                        refetchMemberships().then((res) => {
                                          const { data } = res;
                                          if (queryAfter) {
                                            if (data)
                                              setMembershipsLoaded((memberships) => [
                                                ...memberships,
                                                ...data,
                                              ]);
                                          } else {
                                            setMembershipsLoaded(data);
                                          }
                                        });
                                      }
                                    });
                                  },
                                },
                              ]}
                            />
                          </Stack>
                        );
                      }
                      if (row.membership.auth_type && !row.approved_on) {
                        return (
                          <SeverityPill color={'warning'}>
                            {toTitleCase(`${row.membership.auth_type} pending`)}
                          </SeverityPill>
                        );
                      }
                      if (row.membership.auth_type && row.approved_on) {
                        return (
                          <SeverityPill color={'success'}>
                            {toTitleCase(`${row.membership.auth_type} approved`)}
                          </SeverityPill>
                        );
                      }
                      return <SeverityPill color={'error'}>No approval</SeverityPill>;
                    },
                  },
                  {
                    id: 'active',
                    label: 'Status',
                    align: 'left',
                    format: (row: IUserMembership) => {
                      return (
                        <SeverityPill color={row.active ? 'success' : 'error'}>
                          {row.active ? 'Active' : 'Inactive'}
                        </SeverityPill>
                      );
                    },
                  },
                ] as IColumn[]
              }
              showFilter={false}
              items={membershipsLoaded || []}
              onPageChange={(page, pageN) => {
                const pageL = pageN + 1;
                if (membershipsLoaded.length / queryLimit < pageL) {
                  setQueryAfter(membershipsLoaded[membershipsLoaded.length - 1].id);
                }
                setPage(pageN);
              }}
              onRowsPerPageChange={(row) => {
                const pageSize = Number(row.target.value);
                setQueryLimit(pageSize);
                setQueryAfter(null);
                setRowsPerPage(pageSize);
                setPage(0);
              }}
              page={page}
              rowsPerPage={rowsPerPage}
              searchPlaceholder={'Search user memberships'}
            />
            {showAddUserMembershipModal && (
              <AddUserMembership
                showModal={showAddUserMembershipModal}
                close={() => {
                  refetchMemberships().then((res) => {
                    const { data } = res;
                    if (queryAfter) {
                      if (data)
                        setMembershipsLoaded((memberships) => [...memberships, ...data]);
                    } else {
                      setMembershipsLoaded(data);
                    }
                    setShowAddUserMembershipModal(false);
                  });
                }}
                user={user}
              />
            )}
          </>
        }
        title={'User Memberships'}
      />
    </>
  );
};

export default UserMemberships;
