import React, { useState } from 'react';
import { hardwareRentalColumnsV2 } from '../../../tableColumns/hardwareColumns';
import { history } from '../../../utils';
import { IHardware } from '../../../ts/interface/hardware.interface';
import { PaginationTable } from '../../../components/Redesing/table/pagination-table';
import { IColumn } from '../../../ts/interface/components/redesing/table.interface';
import CardView from '../../../components/Redesing/card-view';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants';

const HardwareAssignment = ({
  hardware,
  isLoading,
}: {
  hardware: IHardware;
  isLoading: boolean;
}) => {
  const [search, setSearch] = React.useState('');
  const [pageNumber, setPageNumber] = React.useState(0);
  const [sizePerPage, setSizePerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);
  const [sortData, setSortData] = useState<string>('start_time');
  const [exportData, setExportData] = useState<boolean>(false);

  return (
    <>
      <CardView
        isLoading={isLoading}
        headerActions={
          <IconButtonMenu
            buttons={[
              {
                label: 'Export',
                onClick: () => setExportData(true),
                startIcon: <FileDownloadIcon />,
              },
            ]}
          />
        }
        content={
          <>
            <PaginationTable
              customKey={'gid'}
              onRowCLick={(gid: string) => history.push(`/rentals/${gid}`)}
              exportData={exportData}
              csvFileName={'Hardware_Rentals.csv'}
              onExport={() => setExportData(false)}
              sortDirection={'desc'}
              sortBy={sortData}
              onSortChange={(sort) => setSortData(sort.key)}
              handleSearch={(search) => setSearch(search.target.value)}
              searchText={search}
              columns={hardwareRentalColumnsV2 as IColumn[]}
              items={hardware?.rentals || []}
              onPageChange={(page, pageN) => {
                setPageNumber(pageN);
              }}
              onRowsPerPageChange={(row) => {
                const pageSize = Number(row.target.value);
                setSizePerPage(pageSize);
              }}
              page={pageNumber}
              rowsPerPage={sizePerPage}
              searchPlaceholder={'Rentals'}
              noDataText={`No rentals for this ${hardware?.hardware_type_name}`}
            />
          </>
        }
        title={'Rentals'}
      />
    </>
  );
};

export default HardwareAssignment;
