import React, { useState } from 'react';
import { displaySuccess, history } from '../../utils';
import { useParams } from 'react-router-dom';
import useUser from '../../hooks/users/query/useUser';
import useAddUserRfidCard from '../../hooks/users/mutation/useAddUserRfidCard';
import { UserInterface } from '../../ts/interface/user.interface';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { MovaticFieldGroupText } from '../../components/Redesing/movatic-field-group';
import PageContainer from '../../components/Redesing/page-container';
import CardView from '../../components/Redesing/card-view';
import { Stack } from '@mui/system';

const AddUserRfidCard = () => {
  const [cardId, setCardId] = useState<string>('');
  const [userId, setUserId] = useState<string | undefined>('');
  const [cardLabel, setCardLabel] = useState<string>('');
  const [cardIdValidation, setCardIdValidation] = useState<string | null>(null);
  const [cardLabelValidation, setCardLabelValidation] = useState<string | null>(null);
  const [cardIdHelp, setCardIdHelp] = useState<string>('');
  const [cardLabelHelp, setCardLabelHelp] = useState<string>('');
  const addUserMutation = useAddUserRfidCard({
    onSuccess: () => {
      displaySuccess('RFID card added successfully!');
      history.push(`/users/${userPhone}/general`);
    },
  });
  const { userPhone = '' } = useParams<{ userPhone: string }>();
  const { isLoading } = useUser(userPhone, {
    onSuccess: (r: UserInterface) => setUserId(r?.userDetails?.id),
  });

  const canSubmit = () => {
    if (!cardId) {
      setCardIdValidation('error');
      setCardIdHelp('Card ID is required');
      return false;
    }
    return true;
  };

  const submit = () => {
    if (canSubmit()) {
      const cardInfo = {
        userId,
        userPhone,
        card_id: cardId,
        card_label: cardLabel,
      };
      addUserMutation.mutate(cardInfo);
    }
  };

  return (
    <PageContainer isLoading={isLoading}>
      <CardView
        content={
          <Stack px={2} pb={2}>
            <MovaticFieldGroupText
              id="cardId"
              name={'cardId'}
              label="Card ID"
              description="The ID of the RFID card"
              hasError={cardIdValidation === 'error'}
              errorMessage={cardIdHelp}
              value={cardId}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setCardId(e.target.value);
                setCardIdHelp('');
                setCardIdValidation(null);
              }}
            />

            <MovaticFieldGroupText
              id="cardLabel"
              name={'cardLabel'}
              label="Card label (optional)"
              description="The label of the RFID card"
              hasError={cardLabelValidation === 'error'}
              errorMessage={cardLabelHelp}
              value={cardLabel}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setCardLabel(e.target.value);
                setCardLabelHelp('');
                setCardLabelValidation(null);
              }}
            />
          </Stack>
        }
        title="Add RFID Card"
        headerActions={
          <>
            <IconButtonMenu
              buttons={[
                {
                  label: 'Cancel',
                  onClick: () => history.push(`/users/${userPhone}/general`),
                  startIcon: <CloseIcon />,
                },
                {
                  label: 'Submit',
                  onClick: submit,
                  startIcon: <SaveIcon />,
                },
              ]}
            />
          </>
        }
      />
    </PageContainer>
  );
};

export default AddUserRfidCard;
