import {
  API_SYSTEMS_SUCCESS,
  LOADING_TRANSFER_CSV,
  LOADING_PAYMENTS_CSV,
  SET_BANK_INFO,
  SET_DETAILED_SYSTEM,
  API_STRIPE_INFO,
  SYSTEM_LOADING,
  SET_BILLING_PAGE_STATS,
  IMAGE_SUCCESS,
  ALL_IMAGE_SUCCESS,
  UPDATE_ACCESS,
  INSERT_IMAGE,
  UPDATE_SYSTEM_LOGO_ID,
  UPDATE_SYSTEM_BANNER_ID,
  UPDATE_LOADING,
  STRIPE_INFO_LOADED,
  RESET,
} from '../constants';
import { useSystemStore } from '../store/systemStore';

const initialState = {
  systems: [],
  images: [],
  allImages: [],
  loading: true,
  isLoaded: false,
  imagesLoaded: false,
  allImagesLoaded: false,
  stripeInfoLoaded: false,
  current: {
    access: 0,
    billing: null,
    billingLive: null,
    colorPrimary: '',
    colorSecondary: '',
    country: null,
    currencyCode: null,
    id: null,
    maxOpenRentals: null,
    name: '',
    partner: null,
    privateSystem: null,
    rentalLengthWarning: null,
    requireCreditCard: null,
    timezone: null,
    demoSystem: null,
    visibility: null,
    reservations: false,
    maxReservations: null,
    round_trip: false,
    payment_provider: null,
    operatingHours: false,
    open_time: null,
    close_time: null,
    partner_maintenance_program: null,
    new_hardware: false,
    max_membership_renewal_attempts: null,
    custom_statement_billing_descriptor: '',
  },
  stripeAccountInfo: {
    business_type: '',
    requirements: {
      currently_due: [],
    },
    tos_acceptance: {
      date: null,
    },
  },
  bankInfo: {
    bankName: '',
    accountLast4: null,
    routingNumber: null,
    isLoaded: false,
  },
  balance: {
    available: [
      {
        amount: 0,
        currency: 'usd',
        source_types: {
          card: 0,
        },
      },
    ],
    pending: [
      {
        amount: 0,
        currency: 'usd',
        source_types: {
          card: 0,
        },
      },
    ],
  },
  billingPageStats: {
    averageCharge: 0,
    chargeCount: 0,
    averageRefund: 0,
    refundCount: 0,
    charges: [],
  },
  loadingTransferCSV: false,
  loadingPaymentsCSV: false,
};

export default function systemReducer(state = initialState, action) {
  let newState = { ...state };
  switch (action.type) {
    case RESET:
      newState = Object.assign(initialState, { systems: state.systems });
      break;

    case UPDATE_LOADING:
      newState.loading = action.loading;
      newState.isLoaded = action.loading;
      break;

    case UPDATE_SYSTEM_BANNER_ID:
      newState.current.banner_id = action.imageId;
      break;

    case UPDATE_SYSTEM_LOGO_ID:
      newState.current.logo_id = action.imageId;
      break;

    case INSERT_IMAGE:
      newState.images.push(action.image);
      break;

    case API_SYSTEMS_SUCCESS:
      newState.systems = action.systems;
      break;

    case IMAGE_SUCCESS:
      newState.images = action.images;
      newState.imagesLoaded = true;
      break;

    case ALL_IMAGE_SUCCESS:
      newState.allImages = action.images;
      newState.allImagesLoaded = true;
      break;

    case STRIPE_INFO_LOADED:
      newState.stripeInfoLoaded = action.loaded;
      break;

    case API_STRIPE_INFO:
      newState.stripeAccountInfo = action.stripeAccountInfo;
      newState.stripeInfoLoaded = true;
      break;

    case SET_DETAILED_SYSTEM:
      // set the detailed system to the cache
      localStorage.setItem('systemId', action.detailedSystem.id);
      localStorage.setItem('systemGid', action.detailedSystem.gid);
      const setSystemInfo = useSystemStore.getState().setSystemInfo;
      setSystemInfo({
        oldId: action.detailedSystem.id,
        gid: action.detailedSystem.gid,
      });
      newState.current = action.detailedSystem;
      break;

    case SET_BANK_INFO:
      newState.bankInfo = action.bankInfo;
      break;

    case UPDATE_ACCESS:
      newState.current.access = action.access;
      break;

    case SYSTEM_LOADING:
      newState.isLoaded = action.loading;
      break;

    case LOADING_TRANSFER_CSV:
      newState.loadingTransferCSV = action.bool;
      break;

    case LOADING_PAYMENTS_CSV:
      newState.loadingPaymentsCSV = action.bool;
      break;

    case SET_BILLING_PAGE_STATS:
      newState.billingPageStats = action.stats;
      break;

    default:
      return state;
  }

  return newState;
}
