import React, { useState, useEffect } from 'react';
import { convertCentsToMoney } from '../../utils';
import FormItem from '../../components/Redesing/form-item';
import {
  MovaticFieldGroupOutlinedInput,
  MovaticFieldGroupSelect,
  MovaticFieldGroupText,
} from '../../components/Redesing/movatic-field-group';
import MenuItem from '@mui/material/MenuItem';
import MovaticCustomModal from '../../components/Modal/MovaticCustomModal';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import CustomToast from '../../components/Redesing/custom-toast';
import { updatePendingCharge } from '../../api/rentals';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';

const OPTIONS = [
  {
    value: 'full',
    name: 'Approve full amount',
  },
  {
    value: 'custom',
    name: 'Approve custom amount',
  },
  {
    value: 'decline',
    name: 'Decline',
  },
];

/**
 * pendingChargesModal component.
 *
 * @param {object} props - The props for the component.
 * @param {Number} props.id - current rental ID
 * @param {Boolean} props.show - show the modal?
 * @param {Function} props.handleModal - show modal handler
 * @param {Function} props.updatePendingCharge - Redux function to update the charge pending
 * @returns {undefined} *
 */

interface IPendingChargeModalProps {
  rentalId: string;
  show: boolean;
  handleModal: Function;
  rentalAmount: number;
}

function PendingChargesModal(props: IPendingChargeModalProps) {
  const { rentalId, show, handleModal, rentalAmount } = props;

  const rentalAmountInDollars = rentalAmount / 100;
  const [description, setDescription] = useState('');
  const [action, setAction] = useState('full');
  const [amount, setAmount] = useState<number>(rentalAmountInDollars);

  useEffect(() => {
    setAmount(rentalAmountInDollars);
  }, [rentalAmount, rentalAmountInDollars]);

  function resetData() {
    setDescription('');
    setAction('full');
    setAmount(rentalAmountInDollars);
    handleModal(false); // close the modal
  }

  function validateDescription(desc: string) {
    if (!desc && action === 'decline') return 'error';
  }

  return (
    <MovaticCustomModal
      open={show}
      onClose={() => resetData()}
      content={
        <div>
          <FormItem
            label="Pending charge amount"
            content={convertCentsToMoney(rentalAmount)}
          />

          <MovaticFieldGroupSelect
            label={'Select an action'}
            description="Please select an action to take on the pending charge."
            name={'action'}
            optionNodes={OPTIONS.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
            value={action}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setAction(e.target.value);
            }}
            id={'card_type'}
          />

          {action === 'custom' && (
            <MovaticFieldGroupOutlinedInput
              label="Custom charge amount"
              description="The custom amount to charge the user."
              type="number"
              name="credit_card_hold"
              id="credit_card_hold"
              value={amount}
              inputProps={{ min: 0, step: 0.25 }}
              addOn={'$'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setAmount(Number(e.target.value));
              }}
            />
          )}

          <MovaticFieldGroupText
            multiline
            rows={2}
            id="chargePendingDescriptionGroup"
            label="Note"
            description="Please add additional notes for the decision"
            value={description || ''}
            hasError={validateDescription(description) === 'error'}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      }
      title="Update charge status"
      action={
        <IconButtonMenu
          buttons={[
            {
              label: 'Cancel',
              onClick: () => resetData(),
            },
            {
              label: 'Accept',
              onClick: () => {
                if (!description && action === 'decline') {
                  CustomToast({
                    type: 'error',
                    message: 'Please provide a note for the decision.',
                    duration: 5000,
                  });
                  return;
                }

                const customAmountInCents = Math.round(amount * 100);
                if (action === 'custom' && customAmountInCents < 50) {
                  CustomToast({
                    type: 'error',
                    message:
                      'Unable to approve a custom charge amount of less than 50 cents. Please decline the charge instead.',
                    duration: 5000,
                  });
                  return;
                }
                CustomAsyncToast({
                  promise: () =>
                    updatePendingCharge({
                      id: rentalId,
                      charge_pending_status: action === 'full' || action === 'custom',
                      charge_pending_note: description,
                      charge_custom_amount:
                        action === 'custom' ? customAmountInCents : null,
                    }),
                  successMessage: () => 'Your Pending Charge was updated successfully!',
                  loadingMessage: () => 'Updating Pending Charge...',
                  errorMessage: () =>
                    'There was an error updating the Pending Charge. Please try again.',
                }).then((r) => {
                  if (r) {
                    resetData();
                  }
                });
              },
            },
          ]}
        />
      }
    />
  );
}

export default PendingChargesModal;
