import { formatPhoneNumber, getLocaleDateTimeString } from '../utils';
import { SeverityPill } from '../components/Redesing/severity-pill';
import ColumnAvatar from '../components/Redesing/column-avatar';
import Typography from '@mui/material/Typography';
import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import TagsCell from '../pages/problems/assets/tags-cell';
export function problemsColumns() {
  return [
    {
      id: 'userName',
      label: 'User',
      searchable: true,
      format: (row) => {
        return row?.userName ? (
          <ColumnAvatar
            avatar={<PersonIcon />}
            firstRow={
              <Typography color="text.pramary" variant="body1">
                {row.userName}
              </Typography>
            }
            secondRow={
              <Typography color="text.secondary" variant="body2">
                <SeverityPill color={'info'}>
                  {formatPhoneNumber(row.userId)}
                </SeverityPill>
              </Typography>
            }
          />
        ) : (
          ' '
        );
      },
    },
    {
      id: 'created',
      searchable: true,
      label: 'Created',
      exportFormat: (row) =>
        row?.created ? `"${getLocaleDateTimeString(row.created)}"` : ' ',
      format: (row) => (row?.created ? getLocaleDateTimeString(row.created) : ' '),
    },
    {
      id: 'state',
      searchable: true,
      label: 'Status',
      exportFormat: (row) => (row?.state === 0 ? 'Open' : 'Close'),
      format: (row) => {
        return (
          <SeverityPill color={row?.state === 1 ? 'success' : 'warning'}>
            {row.state === 0 ? 'Open' : 'Close'}
          </SeverityPill>
        );
      },
    },
    {
      id: 'content',
      searchable: true,
      showToolTip: true,
      label: 'Description',
      format: (row) => {
        return row?.content ? row?.content : ' ';
      },
    },
    {
      id: 'tags',
      label: `Tags`,
      exportFormat: (row) => `"${row?.tags?.join(', ')}"`,
      disableCLick: true,
      format: (row) => <TagsCell tags={row?.tags} />,
      searchable: true,
    },
  ];
}
