import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getStationStats } from '../../../api/stations';
import { UseGetStationStats, LocationStats } from '../../../ts/interface/station.interface';

const useGetStationStats = (parameters: UseGetStationStats, options?: {}): UseQueryResult<LocationStats, null> =>
  useQuery(['stationStats', parameters], () => getStationStats(parameters), {
    ...options,
  });

export default useGetStationStats;
