import { useQuery } from '@tanstack/react-query';
import { getTransactions } from '../../../api/transactions';
import { ITransactionHook } from '../../../ts/interface/transaction.interface';

const useTransactions = (payload?: ITransactionHook, options?: {}) => {
  return useQuery(['transactions', payload?.trigger], () => getTransactions(payload), {
    ...options,
  });
};

export default useTransactions;
