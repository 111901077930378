import {
  ADMIN_LOADED,
  DB_LOADED,
  LOGGED_IN_SUCCESS,
  LOG_OUT,
  API_SYSTEMS_ADMIN_SUCCESS,
  SET_ACCESS,
  SET_ADMIN_NAMES,
} from '../constants';
import { baseUrl, fetchOptions, fetchPostOptions, fetchOptionsV2 } from '../api/http.jsx';
import { getAllSystems, systemLoaded, FourOhOneError, update } from './system';

import bugsnagClient from '../components/bugsnag';
import { displayError, getAdminFromLocalS, history } from '../utils';

export const adminLoaded = (data) => ({
  type: ADMIN_LOADED,
  data,
});

export const loggedInSuccess = (data) => ({
  type: LOGGED_IN_SUCCESS,
  data,
});

export const logOutUser = () => ({
  type: LOG_OUT,
});

export const logOut = () => (dispatch) => {
  const fetchOps = fetchOptionsV2(null, null, 'delete');

  fetch(`${baseUrl}/adminuser/logout`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results.status === 200) {
        dispatch(logOutUser());
        localStorage.removeItem('adminDate');
        localStorage.removeItem('admin');
      } else {
        dispatch(FourOhOneError(results));
        displayError(
          'It looks like there was an error while logging you out. Please try again later.'
        );
      }
    });
};

export const loaded = (isLoaded) => ({
  type: DB_LOADED,
  isLoaded,
});

export const setSystemAccess = (access) => ({
  type: SET_ACCESS,
  access,
});

export const dbLoaded = (isLoaded) => (dispatch) => {
  dispatch(loaded(isLoaded));
  dispatch(systemLoaded(isLoaded));
};

export const apiSystemsAdminsSuccess = (admins) => ({
  type: API_SYSTEMS_ADMIN_SUCCESS,
  admins,
});

export const setAdminNames = (admin) => ({
  type: SET_ADMIN_NAMES,
  admin,
});

export const setAdmin = () => (dispatch) => {
  const admin = getAdminFromLocalS();
  const systemId = localStorage.getItem('systemGid');
  if (admin && systemId) {
    history.push('/dashboard');
  }
};

export const setAdminError = () => (dispatch) => {
  history.push('/404');
};

export const newLogin = (user, password, urlSystem) => (dispatch) => {
  const fetchOps = fetchOptionsV2(null, null, 'POST', { user, password });
  return fetch(`${baseUrl}/adminuser/login`, fetchOps)
    .then((response) => response.json())
    .then((data) => {
      if (data.status !== 200) {
        throw new Error(data.message);
      } else {
        dispatch(getAllSystems(data.message, urlSystem))
          .then(() => {
            dispatch(dbLoaded(true));
            dispatch(setAdmin());
          })
          .catch((e) => {
            return dispatch(setAdminError());
          });
        return dispatch(loggedInSuccess(data.message));
      }
    })
    .then(() => dispatch(setAdmin()));
};

export const getSystemsAdmins = () => (dispatch, getState) => {
  const state = getState();
  const fetchOps = fetchOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id
  );
  return fetch(`${baseUrl}/adminuser`, fetchOps)
    .then((res) => res.json())
    .then((admins) => {
      if (admins.status === 200) {
        return dispatch(apiSystemsAdminsSuccess(admins.message));
      }
      dispatch(FourOhOneError(admins));
    })
    .catch((error) => {
      bugsnagClient.notify(error);
      console.log('error in get system admins', error);
      return error;
    });
};

export const getCurrentAdmin = (email, token, system) => (dispatch, getState) => {
  const fetchOps = fetchOptions(email, token, system);
  return fetch(`${baseUrl}/adminuser/current`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        return response.message;
      }
      console.log('not signed in');
      return null;
    });
};

export const updateAdmin = (adminObject) => (dispatch, getState) => {
  dispatch(update(false));
  const state = getState();
  const fetchOps = fetchPostOptions(
    state.admin.admin.admin.email,
    state.admin.admin.token,
    state.system.current.id,
    adminObject,
    'post'
  );
  return fetch(`${baseUrl}/adminuser/adminName`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      dispatch(update(true));
      if (results.status === 200) {
        dispatch(setAdminNames(results.message));
        dispatch(update(true));
      } else {
        dispatch(FourOhOneError(results));
        displayError('There was an error updating admin name.');
      }
    });
};
