import { useQuery } from '@tanstack/react-query';
import { getUserMemberships } from '../../../api/memberships';
import { UseUserMembership } from '../../../ts/interface/membership.interface';

const useUserMembership = (body?: UseUserMembership, options?: {}) => {
  return useQuery(['userMembership', body], () => getUserMemberships(body), {
    ...options,
  });
};

export default useUserMembership;
