import React from 'react';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {GOOGLE_RECAPTCHA_SITE_KEY} from "../../constants";

const ReCaptchaWrapper = ({children}) => {
    return (
        <GoogleReCaptchaProvider useEnterprise reCaptchaKey={GOOGLE_RECAPTCHA_SITE_KEY}>
            {children}
        </GoogleReCaptchaProvider>
    );
};

export default ReCaptchaWrapper;
