import { toTitleCase, getLocaleDateTimeString, history } from '../utils.jsx';
import MovaticBtnsGroup from '../components/ButtonGroup';
import { SeverityPill } from '../components/Redesing/severity-pill';

const transactionActionColumns = {
  id: 'actions',
  alignment: 'left',
  title: 'Actions',
  width: '18%',
  shouldExport: false,
  noSort: true,
  activeFormatter: (cell, row) => {
    const arrayBtns = [
      {
        text: (
          <>
            <div>
              <i className="fa fa-eye" style={{ color: 'white' }} />
            </div>
          </>
        ),
        onClick: (e) => {
          e.stopPropagation();
          history.push(`/billing/transactions/${row.id}`);
        },
        shouldRender: true,
        shouldDisable: false,
      },
    ];

    return (
      <MovaticBtnsGroup arrayBtns={arrayBtns} btnGroupClass="btn-group-sm floatLeft" />
    );
  },
};

export const columns = [
  {
    id: 'created',
    label: 'Created',
    searchable: true,
    format: (row) => getLocaleDateTimeString(row.created),
  },
  {
    id: 'userName',
    label: 'User',
    searchable: true,
  },

  {
    id: 'type',
    label: 'Type',
    width: '10%',
    searchable: true,
    format: (row) => toTitleCase(row.type),
  },
  {
    id: 'amount',
    label: 'Amount',
    searchable: true,
  },
  {
    id: 'status',
    label: 'Status',
    searchable: true,
    format: (row) =>
      row.status == null || row.status === '' ? (
        <SeverityPill color={'warning'}>Transfer</SeverityPill>
      ) : (
        <SeverityPill color={row.status === 'succeeded' ? 'success' : 'error'}>
          {toTitleCase(row.status)}
        </SeverityPill>
      ),
  },
  {
    id: 'description',
    searchable: true,
    label: 'Description',
    showToolTip: true,
  },
];

export const userColumns = [
  transactionActionColumns,
  {
    id: 'created',
    title: 'Created',
    width: '20%',
    activeFormatter: (date) => getLocaleDateTimeString(date),
  },
  {
    id: 'description',
    title: 'Description',
  },
  {
    id: 'type',
    title: 'Type',
    width: '12%',
    activeFormatter: (cell) => toTitleCase(cell),
  },
  {
    id: 'amount',
    title: 'Amount',
    width: '12%',
  },
  {
    id: 'status',
    title: 'Status',
    activeFormatter: (cell, row, enumObject, index) =>
      row.status == null || row.status === '' ? 'Transfer' : toTitleCase(row.status),
    width: '12%',
  },
];
