import L, { divIcon } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import ElectricScooter from '@mui/icons-material/ElectricScooter';
import Kayaking from '@mui/icons-material/Kayaking';
import EventSeat from '@mui/icons-material/EventSeat';
import BatteryFull from '@mui/icons-material/BatteryFull';
import Battery6Bar from '@mui/icons-material/Battery6Bar';
import Battery5Bar from '@mui/icons-material/Battery5Bar';
import Battery4Bar from '@mui/icons-material/Battery4Bar';
import Battery3Bar from '@mui/icons-material/Battery3Bar';
import Battery2Bar from '@mui/icons-material/Battery2Bar';
import Battery1Bar from '@mui/icons-material/Battery1Bar';
import Battery0Bar from '@mui/icons-material/Battery0Bar';
import Build from '@mui/icons-material/Build';
import Person from '@mui/icons-material/Person';

import {
  BEACH_CHAIR,
  BIKE,
  KAYAK,
  LOCKER,
  RACK,
  SCOOTER,
  BIKE_ROOM,
  ACTIVE,
} from '../constants';
import { getHardwareIconByName } from '../util/utilsR';

type Badge = {
  count?: number;
  inMaintenance?: boolean;
  inRental?: boolean;
  batteryPercent?: number;
};

type UnitIconProps = {
  url: string;
  Icon?: React.ComponentType;
  badge?: Badge;
};

type StationIconProps = {
  url: string;
  Icon?: React.ComponentType;
  badge?: Badge;
};

function getHardwareIconProps(type: string | number, hardware: any): UnitIconProps {
  const url = (iconName: string) => `${process.env.PUBLIC_URL}/img/${iconName}.png`;
  const badge = {
    inRental: !!hardware?.current_rental,
    inMaintenance:
      (hardware?.state || hardware?.state === 0) && hardware.state !== ACTIVE,
    batteryPercent: hardware?.battery_percent,
  };

  switch (type) {
    case 22:
    case 'bike':
      return {
        url: url('unit-blue-template'),
        Icon: PedalBikeIcon,
        badge,
      };
    case 'start':
      return {
        url: url('start'),
      };
    case 'end':
      return {
        url: url('end'),
      };
    case 19:
    case 'scooter':
    default:
      return {
        url: url('unit-green-template'),
        Icon: getHardwareIconByName(type?.toString()).Icon,
        badge,
      };
  }
}

function getStationIconProps(type: string, station?: any): StationIconProps {
  const url = (iconName: string) => `${process.env.PUBLIC_URL}/img/${iconName}.png`;
  const badge = {
    count: station?.available_hardware,
  };

  switch (type) {
    case LOCKER:
    case RACK:
    case BIKE_ROOM:
      return {
        url: url('parking-blue-template'),
        Icon: PedalBikeIcon,
        badge,
      };
    case SCOOTER:
      return {
        url: url('parking-green-template'),
        Icon: ElectricScooter,
        badge,
      };
    case KAYAK:
      return {
        url: url('parking-orange-template'),
        Icon: Kayaking,
        badge,
      };
    case BEACH_CHAIR:
      return {
        url: url('parking-pink-template'),
        Icon: EventSeat,
        badge,
      };
    case BIKE:
      return {
        url: url('parking-blue-template'),
        Icon: PedalBikeIcon,
        badge,
      };
    default:
      return {
        url: url('parking-blue-template'),
        Icon: PedalBikeIcon,
        badge,
      };
  }
}

function IconBadge({ badge, type }: { badge: Badge; type: 'unit' | 'station' }) {
  const { count, inMaintenance, inRental, batteryPercent } = badge;

  let MaterialIcon = null;

  if (inRental) {
    MaterialIcon = Person;
  } else if (inMaintenance) {
    MaterialIcon = Build;
  } else if (batteryPercent && batteryPercent >= 90) {
    MaterialIcon = BatteryFull;
  } else if (batteryPercent && batteryPercent >= 80) {
    MaterialIcon = Battery6Bar;
  } else if (batteryPercent && batteryPercent >= 70) {
    MaterialIcon = Battery5Bar;
  } else if (batteryPercent && batteryPercent >= 60) {
    MaterialIcon = Battery4Bar;
  } else if (batteryPercent && batteryPercent >= 50) {
    MaterialIcon = Battery3Bar;
  } else if (batteryPercent && batteryPercent >= 40) {
    MaterialIcon = Battery2Bar;
  } else if (batteryPercent && batteryPercent >= 30) {
    MaterialIcon = Battery1Bar;
  } else if (batteryPercent && batteryPercent >= 20) {
    MaterialIcon = Battery1Bar;
  } else if (batteryPercent || batteryPercent === 0) {
    MaterialIcon = Battery0Bar;
  }

  if (!count && count !== 0 && !MaterialIcon) return null;

  return (
    <span
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#d51d59',
        borderRadius: '12px',
        color: '#fff',
        fontSize: '10px',
        fontWeight: 700,
        height: '16px',
        left: type === 'unit' ? '24px' : '32px',
        lineHeight: '16px',
        position: 'absolute',
        textAlign: 'center',
        top: '-4px',
        verticalAlign: 'middle',
        width: '16px',
        zIndex: 1,
      }}
    >
      {MaterialIcon ? (
        <MaterialIcon style={{ position: 'relative', height: '12px' }} />
      ) : (
        count
      )}
    </span>
  );
}

export function mapHardwareMarker(markerType: string | number, hardware?: any) {
  const { url, Icon, badge } = getHardwareIconProps(markerType, hardware);

  if (Icon) {
    const iconMarkup = renderToStaticMarkup(
      <div>
        <div>
          <img src={url} height={40} alt="" />
          <Icon
            // @ts-ignore
            style={{ color: 'white', position: 'absolute', top: '8px', left: '8px' }}
          />
        </div>
        {badge && <IconBadge badge={badge} type="unit" />}
      </div>
    );

    return divIcon({
      html: iconMarkup,
      iconAnchor: [20, 40],
      iconSize: [40, 40],
    });
  }

  return new L.Icon({
    iconUrl: url,
    iconAnchor: [20, 40],
    iconSize: [40, 40],
  });
}

export function mapStationMarker(markerType: string, station?: any) {
  const { url, Icon, badge } = getStationIconProps(markerType, station);

  if (Icon) {
    const iconMarkup = renderToStaticMarkup(
      <div>
        <div>
          <img src={url} height={40} alt="" />
          <Icon
            // @ts-ignore
            style={{ color: 'white', position: 'absolute', top: '8px', left: '17px' }}
          />
        </div>
        {badge && <IconBadge badge={badge} type="station" />}
      </div>
    );

    return divIcon({
      html: iconMarkup,
      iconAnchor: [23, 40],
      iconSize: [46, 40],
    });
  }

  return new L.Icon({
    iconUrl: url,
    iconAnchor: [23, 40],
    iconSize: [46, 40],
  });
}
