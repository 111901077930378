import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useQueryString from '../hooks/queryParams/useQueryString';

interface WithSystemIdProps {
  systemId: string;
}

const withSystemId =
  <P extends WithSystemIdProps>(WrappedComponent: React.ComponentType<P>) =>
  (props: Omit<P, 'systemId'>) => {
    const history = useHistory();
    const systemId = localStorage.getItem('systemGid');
    const { queryParams: getQueryParam } = useQueryString();

    const appendQueryParam = useCallback(
      (paramName: string, paramValue: string) => {
        if (Boolean(getQueryParam.get('systemId'))) return;
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set(paramName, paramValue);
        history.replace({ search: queryParams.toString() });
      },
      [history, getQueryParam]
    );

    useEffect(() => {
      appendQueryParam('systemId', systemId || '');
    }, [systemId, appendQueryParam]);

    return <WrappedComponent {...(props as P)} systemId={systemId} />;
  };

export default withSystemId;
