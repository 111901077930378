import { useQuery } from '@tanstack/react-query';
import { getAllSystemsV2 } from '../../../api/system';

const useSystems = (options?: {}, parameters?: any) => {
  return useQuery(
    ['useSystems', parameters?.system_id, parameters?.locationId],
    () => getAllSystemsV2(parameters),
    {
      ...options,
    }
  );
};

export default useSystems;
