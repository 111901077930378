import React from 'react';
import FormItem from '../../../components/Redesing/form-item';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import SaveIcon from '@mui/icons-material/Save';
import CardView from '../../../components/Redesing/card-view';
import { Stack } from '@mui/system';

function button(props) {
  const { saveButtonLoading, minimumWalletDeposit, submit } = props;

  return (
    <IconButtonMenu
      buttons={[
        {
          label: 'Save',
          startIcon: <SaveIcon />,
          onClick: () =>
            submit({
              minimumWalletDeposit,
              card: 'minimumWalletDeposit',
            }),
          disabled: !saveButtonLoading,
        },
      ]}
    />
  );
}

function content(props) {
  const { handleChange, minimumWalletDeposit } = props;

  return (
    <div>
      <FormItem
        label="Minimum Wallet Balance"
        description="The minimum wallet balance that users on this system need to have in order to start a rental. Can be overriden by Membership and Rates."
        content={
          <OutlinedInput
            fullWidth
            id="minimumWalletDeposit"
            name="minimumWalletDeposit"
            inputProps={{ step: 0.25, min: 0 }}
            type="number"
            placeholder="0"
            value={minimumWalletDeposit || ''}
            onChange={handleChange}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
        }
      />
    </div>
  );
}

export default function MinimumWalletBalanceCard(props) {
  return (
    <CardView
      title="Minimum Wallet Balance"
      content={
        <Stack px={2} pb={2}>
          {content(props)}
        </Stack>
      }
      headerActions={button(props)}
    />
  );
}
