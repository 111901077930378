import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { getNavigationStats } from '../../actionCreators/stats';
import {
  INavigationStats,
  IStripeRequirements,
  ISystem,
} from '../../ts/interface/system.interface';
import { useParams } from 'react-router-dom';
import { changeTab } from '../../utils';
import useStripeAccountInfo from '../../hooks/system/query/useStripeAccountInfo';
import useTransactions from '../../hooks/transaction/query/useTransactions';
import useRates from '../../hooks/rates/query/useRates';
import RatesV2 from '../ratesv2';
import Transactions from '../transactions';
import Coupons from '../coupons/couponIndex';
import Disputes from './disputesTable';
import PendingCharges from './pendingCharges';
import Fees from './fees';
import BillingDash from './dashboard';
import IssuingCardsTable from './issuing/issuingCardIndex';
import { IAdmin } from '../../ts/interface/admins.interface';
import PageContainer from '../../components/Redesing/page-container';
import PageContent from '../../components/Redesing/page-content';
import usePendingCharges from '../../hooks/rentals/query/usePendingCharges';
import { IDispute } from '../../ts/interface/transaction.interface';
import useDisputes from '../../hooks/transaction/query/useDisputes';

interface IBillingProps {
  system: ISystem;
  systemLoaded: boolean;
  navigationStats: INavigationStats;
}

const Billing = ({ system, systemLoaded, navigationStats }: IBillingProps) => {
  let { currentTab = 'overview' } = useParams<{ currentTab: string }>();
  const [stripeRequirements, setStripeRequirements] =
    useState<IStripeRequirements | null>(null);
  const [newRates, setNewRates] = useState<[]>([]);
  const [currentTabV, setCurrentTabV] = useState(currentTab);
  const handleTabsChange = useCallback(
    (event: React.SyntheticEvent, value: React.SetStateAction<string>) => {
      changeTab(value, currentTab, 'billing');
    },
    [currentTab]
  );
  useRates({
    enabled: systemLoaded,
    onSuccess: (data: []) => {
      setNewRates(data);
    },
  });

  useEffect(() => {
    if (currentTab !== currentTabV) {
      setCurrentTabV(currentTab);
    }
  }, [currentTab, currentTabV]);

  const { isLoading: isStripeAccountInfoLoading } = useStripeAccountInfo(null, {
    enabled: systemLoaded,
    onSuccess: (data: IStripeRequirements) => {
      setStripeRequirements(data);
    },
  });

  const { data: disputes = [] } = useDisputes({
    enabled: systemLoaded,
  });
  const { data: pendingCharges = [] } = usePendingCharges({
    enabled: systemLoaded,
  });

  const { data: transactions = [], isLoading } = useTransactions(
    {
      payload: {},
      trigger: {
        systemId: system.id,
      },
    },
    {
      enabled: systemLoaded,
    }
  );

  const currentOpenDisputes = useMemo(() => {
    return disputes.filter(
      (dispute: IDispute) =>
        dispute.system === system.id &&
        (dispute.status === 'Needs Response' ||
          dispute.status === 'Under Review' ||
          dispute.status === 'Warning Under Review' ||
          dispute.status === 'Warning Needs Response')
    );
  }, [disputes, system.id]);

  const warning = () => {
    if (
      system &&
      system.billing &&
      system.payment_provider === 'stripe' &&
      !isStripeAccountInfoLoading &&
      stripeRequirements &&
      !stripeRequirements?.id &&
      systemLoaded
    ) {
      // stripe not verified
      return 'Please verify your settings account in /settings/billing';
    }

    if (Boolean(navigationStats.billing_warning)) {
      // no rates
      return navigationStats.billing_warning;
    }

    return !system.billing &&
      (transactions.length !== 0 || newRates.length !== 0) &&
      system.payment_provider !== 'qvo'
      ? 'Your billing is inactive, this data is historic. You will not be able to create new rate until billing is enabled.'
      : undefined;
  };

  return (
    <PageContainer warningMessage={warning()} spaceName={'Billing'}>
      <PageContent
        detailedView={false}
        itemName={'Billing'}
        currentTabV={currentTabV}
        handleTabsChange={handleTabsChange}
        tabsList={[
          { label: 'Overview', value: 'overview' },
          {
            label: 'Pending Charges',
            value: 'pending',
            warnings: pendingCharges?.length,
          },
          { label: 'Rates', value: 'rates' },
          { label: 'Fees', value: 'fees' },
          { label: 'Coupons', value: 'coupons' },
          { label: 'Transactions', value: 'transactions' },
          {
            label: 'Disputes',
            value: 'disputes',
            warnings: currentOpenDisputes.length,
          },
          { label: 'Issuing Cards', value: 'issuing' },
        ]}
        tabsChildren={
          <>
            {currentTab === 'overview' && (
              <BillingDash isLoading={isLoading} transactions={transactions} />
            )}
            {currentTab === 'pending' && <PendingCharges />}
            {currentTab === 'rates' && <RatesV2 />}
            {currentTab === 'fees' && <Fees />}
            {currentTab === 'coupons' && <Coupons />}
            {currentTab === 'transactions' && (
              <Transactions isLoading={isLoading} transactions={transactions} />
            )}
            {currentTab === 'disputes' && <Disputes />}
            {currentTab === 'issuing' && <IssuingCardsTable />}
          </>
        }
      />
    </PageContainer>
  );
};

export default connect(
  (state: {
    admin: {
      admin: IAdmin;
    };
    system: {
      current: ISystem;
      isLoaded: boolean;
    };
    stats: {
      navigation: INavigationStats;
    };
  }) => ({
    system: state.system.current,
    systemLoaded: state.system.isLoaded,
    navigationStats: state.stats.navigation,
  }),
  (dispatch: Function) => ({
    getNavigationStats() {
      dispatch(getNavigationStats());
    },
  })
)(Billing);
