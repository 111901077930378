import React, { useEffect } from 'react';
import MovaticCustomModal from '../../../components/Modal/MovaticCustomModal';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';
import CloseIcon from '@mui/icons-material/Close';
import { IDocument } from '../../../ts/interface/membership.interface';
import SaveIcon from '@mui/icons-material/Save';
import {
  MovaticFieldGroupCheck,
  MovaticFieldGroupText,
} from '../../../components/Redesing/movatic-field-group';

interface AddDocumentModalProps {
  open: boolean;
  onClose: () => void;
  addDocumentModalSubmit: (documents: IDocument) => void;
  document: IDocument;
}

const EditDocumentModal: React.FC<AddDocumentModalProps> = (props) => {
  const { open, onClose, addDocumentModalSubmit, document } = props;
  const [name, setName] = React.useState<string>(document?.name || '');
  const [description, setDescription] = React.useState<string>(
    document?.description || ''
  );
  const [required, setRequired] = React.useState<boolean>(document?.required || false);

  useEffect(() => {
    setName(document?.name || '');
    setDescription(document?.description || '');
    setRequired(document?.required || false);
  }, [document]);

  const cleanState = () => {
    setName('');
    setDescription('');
    setRequired(false);
  };

  return (
    <div>
      <MovaticCustomModal
        open={open}
        onClose={onClose}
        title="Edit Required Documents"
        content={
          <>
            <MovaticFieldGroupText
              label={'Name'}
              value={name}
              description={'Enter a name for this document'}
              onChange={(value) => setName(value.target.value)}
              placeholder={'Enter document name'}
            />
            <MovaticFieldGroupCheck
              id="requiredDocuments"
              label="Required Document"
              description="Check this box if you would like to require users to upload documents to use this membership"
              name="requiredDocuments"
              onChange={(event) => {
                setRequired(event.target.checked);
              }}
              checked={required}
            />
            <MovaticFieldGroupText
              multiline
              rows={4}
              label={'Description'}
              description={'Enter a description for this document'}
              value={description}
              onChange={(value) => setDescription(value.target.value)}
              placeholder={'Enter document description'}
            />
          </>
        }
        action={
          <IconButtonMenu
            buttons={[
              {
                label: 'Cancel',
                onClick: onClose,
                startIcon: <CloseIcon />,
              },
              {
                label: 'Save',
                onClick: () => {
                  cleanState();
                  addDocumentModalSubmit({
                    name,
                    description,
                    required,
                    id: document.id,
                  });
                },
                startIcon: <SaveIcon />,
              },
            ]}
          />
        }
      />
    </div>
  );
};

export default EditDocumentModal;
