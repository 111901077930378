import React, { useCallback, useEffect, useState } from 'react';
import { history } from '../../utils';
import {
  addAssigment,
  getAllHardwareProducts,
  getAllHardwareTypes,
  getHardware,
} from '../../api/hardware';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';
import PageContainer from '../../components/Redesing/page-container';
import CardView from '../../components/Redesing/card-view';
import { Stack } from '@mui/system';
import {
  MovaticFieldGroupAsyncSelect,
  MovaticFieldGroupSelect,
  MovaticFieldItemLink,
} from '../../components/Redesing/movatic-field-group';
import MenuItem from '@mui/material/MenuItem';

function AddHardwareAssignment({ match }) {
  const parentHardware = match.params.hardwareId;
  const parentHardwareType = match.params.type;
  const valuesMinSearch = {
    mac: 17,
    label: 1,
    number: 1,
    vendor_id: 15,
    hardware_id: 36,
  };
  const [hardwareChild, setHardwareChild] = useState('');
  const [hardwareParent, setHardwareParent] = useState({});
  const [searchFilter, setSearchFilter] = useState('');
  const [searchFilterOptions, setSearchFilterOptions] = useState([]);
  const [isLoadingHardware, setIsLoadingHardware] = useState(false);
  const [hardwareTyId, setHardwareTyId] = useState('');
  const [hardwareProductId, setHardwareProductId] = useState('');
  const [hardwareTypesLoaded, setHardwareTypesLoaded] = useState([]);
  const [hardwareTypeSelected, setHardwareTypeSelected] = useState([]);
  const [hardwareProductsLoaded, setHardwareProductsLoaded] = useState([]);
  const [hardwareProductSelected, setHardwareProductSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [open, setOpen] = React.useState(false);

  const [hardwareTypeValidation, setHardwareTypeValidation] = useState({
    result: null,
    help: '',
  });
  const [searchFilterValidation, setSearchFilterValidation] = useState({
    result: null,
    help: '',
  });
  const [hardwareChildValidation, setHardwareChildValidation] = useState({
    result: null,
    help: '',
  });

  const loadHardware = useCallback(
    (filter) => {
      setIsLoadingHardware(true);
      getHardware({ ...filter }).then((resp) => {
        const filteredArray = resp.data.filter(
          (hardware) => hardware.id !== parentHardware
        );
        setOptions(filteredArray);
        setIsLoadingHardware(false);
      });
    },
    [parentHardware]
  );

  useEffect(() => {
    setIsLoading(true);
    getHardware({ hardware_id: parentHardware }).then((response) => {
      setHardwareParent(response.data[0]);
    });
    getAllHardwareTypes().then((response) => {
      setIsLoading(false);
      const typesOptions = Object.keys(response).map((key) => ({
        name: response[key].hardware_type_name,
        value: response[key].gid,
      }));
      setHardwareTypesLoaded(typesOptions);
      setSearchFilterOptions(hardwareSearchFilters());
    });
  }, [parentHardware]);

  useEffect(() => {
    if (hardwareTypeSelected?.length > 0) {
      const typeId = hardwareTypeSelected[0].value;
      getAllHardwareProducts({ hardware_type_id: typeId }).then((res) => {
        const data = res.data
          .filter((item) => item.data.name !== null)
          .map((item) => ({
            name: item.data.name,
            value: item.data.id,
          }));
        setHardwareProductsLoaded(data);
      });
    }
  }, [hardwareTypeSelected]);

  useEffect(() => {
    if (hardwareTypeSelected?.length > 0) {
      let filter = {
        hardware_type_id: hardwareTypeSelected[0].value,
      };
      if (hardwareProductSelected?.length > 0) {
        filter.hardware_product_id = hardwareProductSelected[0].value;
      }
      loadHardware(filter);
    }
  }, [hardwareProductSelected, hardwareTypeSelected, loadHardware]);

  const getFilterMin = (filter) => {
    return valuesMinSearch[filter];
  };

  const hardwareSearchFilters = () => {
    return [
      { value: ' ', name: 'Select' },
      { value: 'hardware_id', name: 'ID - (e.g., g1h2i3j4-k1l2-m1n2-o1p2-a1b2c3d4e4f4)' },
      { value: 'label', name: 'Label - (e.g., Hardware Label)' },
      { value: 'mac', name: 'Mac Address - (e.g., 01:23:45:67:89:AB)' },
      { value: 'number', name: 'Number - (e.g., 805)' },
      { value: 'vendor_id', name: 'Vendor ID - (e.g., 123456789123456)' },
      { value: 'vendor_serial', name: 'Vendor Serial Number - (e.g., V2020201138230)' },
    ];
  };

  const createAssigment = () => {
    if (!requiredFieldsValidation()) return;
    CustomAsyncToast({
      promise: () =>
        addAssigment({
          hardware_parent_id: parentHardware,
          hardware_child_id: hardwareChild.id,
        }),
      successMessage: () => `Hardware ${hardwareChild.id} was assigned successfully`,
      loadingMessage: () => `Assigning hardware ${hardwareChild.id}...`,
      errorMessage: () => `Error assigning hardware ${hardwareChild.id}`,
    }).then((response) => {
      if (response) goBack();
    });
  };

  const goBack = () => {
    history.push(`/hardware/${parentHardwareType}/${hardwareParent.old_id}`);
  };

  const getValidationState = (value) => {
    if (value.length <= 0) return 'error';
    return null;
  };

  const requiredFieldsValidation = () => {
    const hardwareTypeResult = {
      result: null,
      help: '',
    };
    const searchFilterResult = {
      result: null,
      help: '',
    };
    const hardwareChildResult = {
      result: null,
      help: '',
    };
    let validationResult = true;
    if (getValidationState(hardwareTypeSelected) === 'error') {
      validationResult = false;
      hardwareTypeResult.result = 'error';
      hardwareTypeResult.help = 'Please select a hardware child type.';
    }
    if (getValidationState(searchFilter) === 'error') {
      validationResult = false;
      searchFilterResult.result = 'error';
      searchFilterResult.help = 'Please select a search by.';
    }
    if (getValidationState(hardwareChild) === 'error' && hardwareTypeSelected) {
      validationResult = false;
      hardwareChildResult.result = 'error';
      hardwareChildResult.help = 'Please select a hardware child.';
    }
    setHardwareTypeValidation(hardwareTypeResult);
    setSearchFilterValidation(searchFilterResult);
    setHardwareChildValidation(hardwareChildResult);
    return validationResult;
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    const minFilter = getFilterMin(searchFilter);
    if (query?.length >= minFilter) {
      const filter = {};
      if (searchFilter === 'label') filter[searchFilter] = `%${query}%`;
      else filter[searchFilter] = query;
      if (hardwareProductSelected.length > 0)
        filter.hardware_product_id = hardwareProductSelected[0].value;
      filter.hardware_type_id = hardwareTypeSelected[0].value;
      loadHardware(filter);
    }
  };

  const handleChildSelected = (child) => {
    setHardwareChild(child);
    setHardwareChildValidation({ result: null, help: '' });
  };

  const getOptionText = (option) => {
    if (searchFilter === 'hardware_id') return option.id;
    return `${option[searchFilter] || ''} - ${option.id}`;
  };

  const onHardwareTypeChange = (e) => {
    const selected = e.target.value;
    setSearchFilter('');
    setHardwareProductSelected([]);
    setHardwareProductsLoaded([]);
    setHardwareTypeSelected([
      {
        value: selected,
        name: hardwareTypesLoaded.find((item) => item.value === selected).name,
      },
    ]);
    setHardwareTyId(selected);
    if (selected) {
      loadHardware({ hardware_type_id: selected });
    }
    setHardwareTypeValidation({ result: null, help: '' });
  };

  const onHardwareProductChange = (e) => {
    const selected = e.target.value;
    setHardwareProductSelected([
      {
        value: selected,
        name: hardwareProductsLoaded.find((item) => item.value === selected).name,
      },
    ]);
    setHardwareProductId(selected);
    setSearchFilter('');
    setSearchFilterOptions(hardwareSearchFilters());
  };

  const handleSearchChange = (event) => {
    const searchType = event.target.value;
    setSearchFilter(searchType);
    setSearchFilterValidation({ result: null, help: '' });
  };

  return (
    <PageContainer isLoading={isLoading}>
      <CardView
        content={
          <Stack px={2} pb={2}>
            <MovaticFieldItemLink
              label="Hardware Parent"
              onClick={goBack}
              content={
                hardwareParent.label ||
                hardwareParent.mac ||
                hardwareParent.vendor_id ||
                hardwareParent.number ||
                hardwareParent.id
              }
            />
            <MovaticFieldGroupSelect
              label="Hardware Child Type"
              description="Select the hardware type"
              name={'hardware_type_id'}
              optionNodes={hardwareTypesLoaded.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
              value={hardwareTyId}
              onChange={onHardwareTypeChange}
              hasError={hardwareTypeValidation.result === 'error'}
              errorMessage={hardwareTypeValidation.help}
              id="discount_type"
            />

            <>
              {hardwareTyId && (
                <>
                  <MovaticFieldGroupSelect
                    label="Hardware Child Product"
                    description="Select the hardware product"
                    name={'hardware-product-id'}
                    optionNodes={hardwareProductsLoaded.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                    value={hardwareProductId}
                    onChange={onHardwareProductChange}
                    id="hardware-product-id"
                  />

                  <MovaticFieldGroupSelect
                    label="Search Child By"
                    description="Select the search option"
                    name={'search-id'}
                    optionNodes={searchFilterOptions.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                    value={searchFilter}
                    onChange={handleSearchChange}
                    hasError={searchFilterValidation.result === 'error'}
                    errorMessage={searchFilterValidation.help}
                    id="search-id"
                  />
                </>
              )}
            </>
            <>
              {hardwareTypeSelected && searchFilter && searchFilter.length > 1 && (
                <MovaticFieldGroupAsyncSelect
                  isLoading={isLoadingHardware}
                  label="Hardware Child"
                  description="Select the hardware child"
                  name={'hardware-child-id'}
                  open={open}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionText={getOptionText}
                  options={options}
                  onChange={(event, newValue) => {
                    handleChildSelected(newValue);
                  }}
                  handleSearch={handleSearch}
                  hasError={hardwareChildValidation.result === 'error'}
                  errorMessage={hardwareChildValidation.help}
                />
              )}
            </>
          </Stack>
        }
        title="Add Hardware Assignment"
        headerActions={
          <>
            <IconButtonMenu
              buttons={[
                {
                  label: 'Cancel',
                  onClick: goBack,
                  startIcon: <CloseIcon />,
                },
                {
                  label: 'Submit',
                  onClick: createAssigment,
                  startIcon: <SaveIcon />,
                },
              ]}
            />
          </>
        }
      />
    </PageContainer>
  );
}

export default AddHardwareAssignment;
