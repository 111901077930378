import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';

interface PropertyListProps {
  children: ReactNode;
  dataId?: string;
}

export const PropertyList: FC<PropertyListProps> = (props) => {
  const { children, dataId } = props;

  return (
    <List data-id={dataId} disablePadding>
      {children}
    </List>
  );
};

PropertyList.propTypes = {
  children: PropTypes.node,
};
