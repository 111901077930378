import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IAuth } from '../../ts/interface/admins.interface';
import { ISystem } from '../../ts/interface/system.interface';
import { findAccess, history } from '../../utils';
import { COUPON_DURATION, COUPON_TIME_OPTION } from '../../constants';
import { addCoupon } from '../../api/coupons';
import { ICouponAdd } from '../../ts/interface/coupon.interface';
import {
  MovaticFieldGroupCheck,
  MovaticFieldGroupOutlinedInput,
  MovaticFieldGroupSelect,
  MovaticFieldGroupText,
} from '../../components/Redesing/movatic-field-group';
import MenuItem from '@mui/material/MenuItem';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import SaveIcon from '@mui/icons-material/Save';
import PageContainer from '../../components/Redesing/page-container';
import CardView from '../../components/Redesing/card-view';
import { Stack } from '@mui/system';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';
interface ICouponAddProps {
  systemLoaded: boolean;
  systemAccess: number;
  auth: IAuth;
}

interface ICouponTimeDuration {
  name: string;
  value: number;
}
const CouponAdd = ({ systemLoaded, systemAccess, auth }: ICouponAddProps) => {
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [discountType, setDiscountType] = useState('');
  const [discount, setDiscount] = useState<number | null>(0);
  const [nameValidation, setNameValidation] = useState<string | null>(null);
  const [codeValidation, setCodeValidation] = useState<string | null>(null);
  const [validValidation, setValidValidation] = useState<string | null>(null);
  const [discountValidation, setDiscountValidation] = useState<string | null>(null);
  const [durationValidation, setDurationValidation] = useState<string | null>(null);
  const [active, setActive] = useState(false);
  const [valid, setValid] = useState('');
  const [duration, setDuration] = useState<string | null>('');
  const [uses, setUses] = useState<number | null>(0);

  let options: ICouponTimeDuration[] = [];
  COUPON_DURATION.map((duration) =>
    options.push({ value: duration.seconds, name: duration.value })
  );
  let timeOptions: ICouponTimeDuration[] = [];
  COUPON_TIME_OPTION.map((time) =>
    timeOptions.push({ value: time.seconds, name: time.value })
  );

  const resetValid = () => {
    setDuration(null);
    setUses(null);
  };

  const resetDiscount = () => {
    setDiscount(null);
  };

  const canSubmit = () => {
    let canSubmit = true;
    if (code.length > 10 || code.length === 0) {
      canSubmit = false;
      setCodeValidation('error');
    } else {
      setCodeValidation(null);
    }
    if (!name) {
      canSubmit = false;
      setNameValidation('error');
    } else {
      setNameValidation(null);
    }
    if (discountType == null) {
      canSubmit = false;
      setDiscountValidation('error');
    } else {
      setDiscountValidation(null);
    }
    if (!valid) {
      canSubmit = false;
      setValidValidation('error');
    } else {
      setValidValidation(null);
    }
    if (!uses && !duration) {
      canSubmit = false;
      setDurationValidation('error');
    } else {
      setDurationValidation(null);
    }
    return canSubmit;
  };

  const submit = () => {
    const coupon = {
      name: name,
      code: code,
      active: active,
      discount_type: discountType,
      discount: discount,
      duration: duration,
      uses: uses,
    };
    if (coupon.discount_type === 'money') {
      coupon.discount = Number(coupon.discount) * 100;
    }
    if (canSubmit()) {
      CustomAsyncToast({
        promise: () => addCoupon(coupon as ICouponAdd),
        successMessage: () => 'Coupon added successfully!',
        errorMessage: 'There was an error adding the coupon.',
        loadingMessage: 'Adding coupon...',
      }).then((response) => {
        if (response) {
          history.push('/billing/coupons');
        }
      });
    }
  };

  return (
    <>
      <PageContainer isLoading={!systemLoaded}>
        <CardView
          headerActions={
            <IconButtonMenu
              buttons={[
                {
                  label: 'Cancel',
                  onClick: () => history.push('/billing/coupons'),
                },
                {
                  label: 'Save',
                  onClick: submit,
                  startIcon: <SaveIcon />,
                  disabled:
                    !findAccess(systemAccess).includes('rates') &&
                    !auth.admin.movaticAccess,
                },
              ]}
            />
          }
          content={
            <Stack px={2} pb={2}>
              <MovaticFieldGroupText
                label="Coupon Name"
                description={'The name of the coupon.'}
                value={name}
                hasError={nameValidation === 'error'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setName(e.target.value);
                  setNameValidation(null);
                }}
                id="name"
                name={'name'}
              />

              <MovaticFieldGroupOutlinedInput
                label="Code"
                description="Please enter up to 10 characters for the user to input to apply this coupon."
                value={code}
                inputProps={{ max: 10 }}
                hasError={codeValidation === 'error'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setCode(e.target.value);
                  setCodeValidation(null);
                }}
                id="code"
                name={'code'}
              />

              <MovaticFieldGroupSelect
                label="Discount Type"
                description="Please choose the discount type you would like this coupon to apply."
                name={'card_type'}
                optionNodes={[
                  { value: 'percent', name: 'Percentage Discount' },
                  { value: 'time', name: 'Free Time' },
                  { value: 'money', name: 'Money Discount' },
                ].map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
                value={discountType}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setDiscountType(e.target.value);
                  resetDiscount();
                }}
                hasError={discountValidation === 'error'}
                id="discount_type"
              />

              {discountType === 'percent' && (
                <MovaticFieldGroupOutlinedInput
                  label="Percent Off"
                  type="number"
                  addOn={'%'}
                  description="Please enter the percent off you would like to apply to the coupon."
                  value={discount ? discount : ''}
                  inputProps={{ max: 100, min: 1 }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setDiscount(parseInt(e.target.value));
                  }}
                  id="code"
                  name={'code'}
                />
              )}
              {discountType === 'money' && (
                <MovaticFieldGroupOutlinedInput
                  label="Money Off"
                  type="number"
                  addOn={'$'}
                  description="Please enter the discount you would like to apply to the coupon."
                  value={discount ? discount : ''}
                  inputProps={{ max: 100, min: 1, step: 0.25 }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setDiscount(parseInt(e.target.value));
                  }}
                  id="discount"
                  name="discount"
                />
              )}

              {discountType === 'time' && (
                <MovaticFieldGroupSelect
                  label="Free Time"
                  description="Please choose an amount of of free time before a user's rental will be charged."
                  optionNodes={options.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                  value={discount ? discount : ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setDiscount(parseInt(e.target.value));
                  }}
                  id="discount"
                />
              )}

              <MovaticFieldGroupSelect
                label="Valid For"
                description="Please choose the type of duration the coupon will be valid for."
                optionNodes={[
                  { value: 'time', name: 'Time Based' },
                  { value: 'uses', name: '# of Uses' },
                ].map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
                value={valid}
                hasError={validValidation === 'error'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setValid(e.target.value);
                  resetValid();
                }}
                id="discount"
              />

              {valid === 'time' && (
                <MovaticFieldGroupSelect
                  label="Durations"
                  description="Please choose how long the user has to use the coupon after applying it."
                  optionNodes={timeOptions.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                  value={valid}
                  hasError={durationValidation === 'error'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setDuration(e.target.value);
                    setValidValidation(null);
                  }}
                  id="discount"
                />
              )}

              {valid === 'uses' && (
                <MovaticFieldGroupOutlinedInput
                  label="Uses"
                  type="number"
                  addOn={'#'}
                  description="Please choose how many times the user can use this coupon."
                  value={uses ? uses : ''}
                  inputProps={{ max: 100, min: 1 }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setUses(parseInt(e.target.value));
                  }}
                  id="discount"
                  name="discount"
                />
              )}

              <MovaticFieldGroupCheck
                label="Active?"
                description="Make this coupon active immediately"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setActive(e.target.checked)
                }
                checked={active}
              />
            </Stack>
          }
          title={'Add'}
        />
      </PageContainer>
    </>
  );
};

export default connect(
  (state: {
    admin: {
      admin: IAuth;
      systemAccess: number;
    };
    system: {
      isLoaded: boolean;
      current: ISystem;
    };
  }) => ({
    auth: state.admin.admin,
    system: state.system.current,
    systemLoaded: state.system.isLoaded,
    movaticAccess: state.admin.admin.admin.movaticAccess,
    systemAccess: state.admin.systemAccess,
  }),
  () => ({})
)(CouponAdd);
