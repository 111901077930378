import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useUser from '../../hooks/users/query/useUser';
import { connect } from 'react-redux';
import { changeTab, history } from '../../utils';
import GeneralUserInfo from './userDetailsAssets/generalUserInfo';
import TabComponent from '../../components/tabComponent';
import { getTransactions } from '../../actionCreators/transaction';
import { ISystem } from '../../ts/interface/system.interface';
import { IUserDetail } from '../../ts/interface/userDetail.interface';
import { IProblem } from '../../ts/interface/problem.interface';
import PageContainer from '../../components/Redesing/page-container';

const UserDetails = ({
  system,
  unitsTitle,
  systemLoaded,
}: {
  system: ISystem;
  unitsTitle: string;
  systemLoaded: boolean;
}) => {
  const { userPhone = '', currentTab } = useParams<{
    userPhone: string;
    currentTab: string;
  }>();

  const [userDetails, setUserDetails] = useState<IUserDetail>({} as IUserDetail);
  const [userProblems, setUserProblems] = useState<IProblem[]>([]);

  const {
    isLoading,
    refetch: userRefetch,
    data: user,
  } = useUser(userPhone, {
    enabled: systemLoaded,
    onError: () => history.push('/users'),
  });

  //effect to set user details data and problems
  useEffect(() => {
    if (user) {
      const { userDetails: userDetailLoaded, problems } = user;
      setUserDetails(userDetailLoaded);
      setUserProblems(problems);
    }
  }, [user]);

  useEffect(() => {
    getTransactions({ user_id: userDetails?.id });
  }, [userDetails]);

  const tabs = () => {
    return (
      <TabComponent
        loaded={!isLoading}
        activeKey={currentTab}
        id="sub-page-tabs"
        onSelect={(key: { key: string }) => {
          changeTab(
            `${userDetails.phone}/${key}`,
            `${userDetails.phone}/${currentTab}`,
            'users'
          );
        }}
        tabs={[
          {
            eventKey: 'general',
            title: `${userDetails?.fullName || 'User Loading...'}`,
            shouldRender: true,
          },
          { eventKey: 'rentals', title: 'Rentals', shouldRender: true },
          { eventKey: 'transactions', title: 'Transactions', shouldRender: true },
        ]}
      />
    );
  };

  return (
    <PageContainer isLoading={isLoading || !userDetails.id}>
      <GeneralUserInfo
        unitsTitle={unitsTitle}
        tabs={tabs()}
        user={user}
        isLoading={isLoading}
        userDetails={userDetails}
        privateSystem={system.privateSystem}
        onSuccess={() => {
          userRefetch().then((res) => {
            setUserDetails(res.data.userDetails);
            setUserProblems(res.data.problems);
          });
        }}
        usesWallet={system.wallet_required}
        system={system}
        problems={userProblems}
      />
    </PageContainer>
  );
};

const UserDetailR = connect(
  (state: {
    system: { current: ISystem; isLoaded: boolean };
    mode: { details: { unitsTitle: string } };
  }) => ({
    system: state.system.current,
    systemLoaded: state.system.isLoaded,
    unitsTitle: state.mode.details.unitsTitle,
  }),
  () => ({})
)(UserDetails);

export default UserDetailR;
