import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { updateSystemSettings } from '../../actionCreators/system';
import FormItem from '../../components/Redesing/form-item';
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CardView from '../../components/Redesing/card-view';
import { Stack } from '@mui/system';
import SaveIcon from '@mui/icons-material/Save';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import {
  MovaticFieldGroupCheck,
  MovaticFieldGroupSelect,
  MovaticFieldGroupTelephone,
} from '../../components/Redesing/movatic-field-group';
import CustomToast from '../../components/Redesing/custom-toast';

const timeZones = require('../../../data/timezones.json');
class GeneralSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      privateSystem: this.props.detailedSystem.privateSystem || false,
      name: this.props.detailedSystem.name || '',
      systemDescription: this.props.detailedSystem.description,
      supportPhone: this.props.detailedSystem.supportPhoneNumber,
      billing: this.props.detailedSystem.billing || false,
      visibility: this.props.detailedSystem.visibility || false,
      demoSystem: this.props.detailedSystem.demoSystem || false,
      countryCode: this.props.detailedSystem.country,
      maintenancePlan: this.props.detailedSystem.partner_maintenance_program || 'no_plan',
      new_hardware: this.props.detailedSystem.new_hardware || false,
      status: this.props.detailedSystem.status || 'open',
      closedReason: this.props.detailedSystem.closed_reason || '',
      maxMembershipRenewalAttempts:
        this.props.detailedSystem.max_membership_renewal_attempts || 'default',
    };

    this.changeFunction = this.changeFunction.bind(this);
    this.phoneNumberChange = this.phoneNumberChange.bind(this);
    this.getValidationStateStringLength = this.getValidationStateStringLength.bind(this);
    this.content = this.content.bind(this);
    this.submit = this.submit.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.detailedSystem !== nextProps.detailedSystem) {
      this.setState({
        privateSystem: nextProps.detailedSystem.privateSystem || false,
        name: nextProps.detailedSystem.name || '',
        systemDescription: nextProps.detailedSystem.description,
        supportPhone: nextProps.detailedSystem.supportPhoneNumber,
        billing: nextProps.detailedSystem.billing || false,
        visibility: nextProps.detailedSystem.visibility || false,
        demoSystem: nextProps.detailedSystem.demoSystem || false,
        countryCode: nextProps.detailedSystem.country,
        maintenancePlan:
          nextProps.detailedSystem.partner_maintenance_program || 'no_plan',
        new_hardware: nextProps.detailedSystem.new_hardware || false,
        status: nextProps.detailedSystem.status || 'open',
        closedReason: nextProps.detailedSystem.closed_reason || '',
        maxMembershipRenewalAttempts:
          nextProps.detailedSystem.max_membership_renewal_attempts || 'default',
      });
    }
  }

  changeFunction(event) {
    const value =
      event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    if (event.target.name === undefined) return;

    if (event.target.name === 'demoSystem' && value) {
      // if system is a demo system, it must be private
      // and visibility must be false
      this.setState(
        {
          [event.target.name]: value,
          privateSystem: true,
          visibility: false,
        },
        () => {
          this.props.isChanged(true);
        }
      );
    } else if (event.target.name === 'privateSystem' && !value) {
      // if a system is public, it cannot be a demo system
      // and visibility must be true
      this.setState(
        {
          [event.target.name]: value,
          demoSystem: false,
          visibility: true,
        },
        () => {
          this.props.isChanged(true);
        }
      );
    } else {
      this.setState(
        {
          [event.target.name]: value,
        },
        () => {
          this.props.isChanged(true);
        }
      );
    }
  }

  getValidationStateStringLength(type = '') {
    if (type != null) {
      if (type.length > 200) return 'error';
    }
  }

  phoneNumberChange(phone) {
    this.setState(
      {
        supportPhone: phone ? phone : null,
      },
      () => {
        this.props.isChanged(true);
      }
    );
  }

  makeSystemType() {
    const { currentMode } = this.props;
    let message;
    if (!currentMode) {
      message = '';
    } else {
      let mode = currentMode;
      if (mode.includes('_')) {
        mode = mode.split('_').join(' ');
      }
      message = `${mode[0].toUpperCase()}${mode.slice(1)}`;
    }
    return message;
  }

  onTimeZoneChange(event) {
    this.setState(
      {
        timezone_offset: event.target.value,
        timezone: event.target.value,
      },
      this.props.isChanged(true)
    );
  }

  renderTimeZoneSelections() {
    const selections = [
      // <option key="none" value="" label="">
      //   Select Timezone
      // </option>,
    ];
    for (const i in timeZones) {
      selections.push(
        <MenuItem key={i} value={timeZones[i].utc[0]} label={timeZones[i].text}>
          {timeZones[i].text}
        </MenuItem>
      );
    }

    return selections;
  }

  submit() {
    const systemName = this.state.name.trim();
    this.setState({ name: systemName });

    const updatedSettings = {
      ...this.state,
      name: systemName,
      closed_reason: this.state.closedReason,
    };

    const { timezone } = this.state;

    if (timezone === '') {
      CustomToast({
        type: 'error',
        message: 'Select a timezone',
      });
      return;
    }

    const rentals = this.props.rentals.filter(
      (rental) =>
        (rental.startTime !== null && rental.endTime === null) ||
        (rental.reservation &&
          rental.reservationCancel === null &&
          rental.endTime === null)
    );

    if (rentals.length > 0 && this.state.billing !== this.props.detailedSystem.billing) {
      this.setState({ billing: false });
      CustomToast({
        type: 'error',
        message: 'Cannot change to billing with active rentals',
      });
    } else if (
      this.state.supportPhone &&
      !isPossiblePhoneNumber(this.state.supportPhone)
    ) {
      CustomToast({
        type: 'error',
        message: 'Please enter a valid support phone number',
      });
    } else if (systemName.length < 3 || systemName.length > 50) {
      CustomToast({
        type: 'error',
        message:
          'System name must be equal or fewer than 50 characters, and more than 3 characters.',
      });
    } else if (
      this.state.systemDescription &&
      this.state.systemDescription.length > 200
    ) {
      CustomToast({
        type: 'error',
        message: 'System description must be fewer than 200 characters',
      });
    } else {
      if (updatedSettings.maintenancePlan === 'no_plan') {
        updatedSettings.maintenancePlan = null;
      }
      this.props.submit(updatedSettings);
    }
  }

  content() {
    const isMovatic = this.props.admin && !this.props.adminPartner;
    const isOBS = this.props.detailedSystem.partner === 2; // on bikeshare

    return (
      <div>
        <div>
          <FormItem
            label="System Name"
            description="The name of the system."
            content={
              <TextField
                id="name"
                name="name"
                variant="outlined"
                fullWidth
                value={this.state.name}
                onChange={this.changeFunction}
                placeholder="You can type up to 50 characters."
              />
            }
          />

          <MovaticFieldGroupSelect
            label="System Status"
            description="Select the current system status."
            name={'system_status'}
            optionNodes={[
              { name: 'Open', value: 'open' },
              { name: 'Temporarily Closed', value: 'closed' },
            ].map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
            value={this.state.status}
            onChange={(e) => {
              this.setState({ status: e.target.value });
              this.props.isChanged(true);
            }}
            id={'system_status'}
          />

          {this.state.status === 'closed' ? (
            <>
              <FormItem
                label="Temporary closure reason"
                description="The reason why the system is temporarily closed. Will be displayed to users."
                content={
                  <TextField
                    id="closedReason"
                    variant="outlined"
                    multiline
                    rows={4}
                    fullWidth
                    error={this.getValidationStateStringLength(this.state.closedReason)}
                    value={this.state.closedReason || ''}
                    placeholder="You can type up to 200 characters."
                    name="closedReason"
                    onChange={this.changeFunction}
                  />
                }
              />
              {/*<Group*/}
              {/*  id="closedReason"*/}
              {/*  label="Temporary closure reason"*/}
              {/*  description="The reason why the system is temporarily closed. Will be displayed to users."*/}
              {/*  form={*/}
              {/*    <Form.Group>*/}
              {/*      <Form.Control*/}
              {/*        isInvalid={this.getValidationStateStringLength(*/}
              {/*          this.state.closedReason*/}
              {/*        )}*/}
              {/*        as="textarea"*/}
              {/*        value={this.state.closedReason || ''}*/}
              {/*        placeholder="You can type up to 200 characters."*/}
              {/*        name="closedReason"*/}
              {/*        onChange={this.changeFunction}*/}
              {/*      />*/}
              {/*    </Form.Group>*/}
              {/*  }*/}
              {/*/>*/}
            </>
          ) : null}
          {/* SYSTEM TYPE */}
          <FormItem label="System Type" content={this.makeSystemType()} />
          {/* Edit Description */}
          <FormItem
            label="Edit Description"
            description="The system description."
            content={
              <TextField
                id="systemDescription"
                variant="outlined"
                multiline
                rows={2}
                fullWidth
                error={this.getValidationStateStringLength(this.state.systemDescription)}
                value={
                  this.state.systemDescription === null
                    ? ''
                    : this.state.systemDescription
                }
                placeholder="You can type up to 200 characters."
                name="systemDescription"
                onChange={this.changeFunction}
              />
            }
          />

          {/* Support Phone Number */}
          <MovaticFieldGroupTelephone
            id="phoneNumber"
            label="Edit Support Number"
            description="Phone number for user support. Confirm the correct country is selected before saving."
            name="phone"
            onChange={this.phoneNumberChange}
            value={this.state.supportPhone}
          />

          {/* SYSTEM COUNTRY */}
          <FormItem
            id="systemCountry"
            label="System Country"
            content={this.props.detailedSystem.country}
          />

          {/* TIME ZONE OFFSET */}
          <FormItem
            label="Time Zone"
            description="Time zone where the system is located."
            content={
              <Select
                fullWidth
                id="timezone_offset"
                value={this.state.timezone || this.props.detailedSystem.timezone}
                onChange={(timezone) => this.onTimeZoneChange(timezone)}
              >
                {this.renderTimeZoneSelections()}
              </Select>
            }
          />

          {/* PRIVATE SYSTEM CHECKBOX */}
          <MovaticFieldGroupCheck
            label="Require Membership"
            description="Require the user to have a membership before they can access the system."
            id="privateSystem"
            name="privateSystem"
            onChange={(e) => {
              this.setState({ privateSystem: e.target.checked });
              this.props.isChanged(true);
            }}
            checked={this.state.privateSystem}
          />

          {/* Show Visibility Field */}
          {this.state.privateSystem && (isMovatic || isOBS) ? (
            <MovaticFieldGroupCheck
              label="Visible"
              description="If this box is not checked, stations will only be visible to approved members on the Movatic app."
              id="visibility"
              name="visibility"
              onChange={(e) => {
                this.setState({ visibility: e.target.checked });
                this.props.isChanged(true);
              }}
              checked={this.state.visibility}
            />
          ) : null}

          {/* Demo Field */}
          {this.props.admin ? (
            <MovaticFieldGroupCheck
              label="Demo"
              description={`Set this system for demonstration purposes only. Demo systems must require memberships${
                isMovatic || isOBS ? ' and cannot be visible' : ''
              }.`}
              id="demoSystem"
              name="demoSystem"
              onChange={(e) => {
                this.setState({ demoSystem: e.target.checked });
                this.props.isChanged(true);
              }}
              checked={this.state.demoSystem}
            />
          ) : null}

          {/* Enable Billing */}
          {this.props.admin ||
          (this.props.currentMode !== 'bikeshare(keybox)' && !isOBS) ? (
            <MovaticFieldGroupCheck
              label="Enable Billing"
              description="Will this system be charging money for rentals?"
              id="billing"
              name="billing"
              onChange={(e) => {
                this.setState({ billing: e.target.checked });
                this.props.isChanged(true);
              }}
              checked={this.state.billing}
            />
          ) : null}

          <FormItem
            label="Maximum Attempts To Renew Membership"
            description="The amount of attempts before canceling the membership"
            content={
              <Select
                fullWidth
                id="maxMembershipRenewalAttempts"
                name="maxMembershipRenewalAttempts"
                value={this.state.maxMembershipRenewalAttempts}
                onChange={({ target }) => {
                  this.setState({ maxMembershipRenewalAttempts: target.value });
                  this.props.isChanged(true);
                }}
              >
                {[
                  { label: 'Use partner default', value: 'default' },
                  { label: 1, value: 1 },
                  { label: 2, value: 2 },
                  { label: 3, value: 3 },
                  { label: 4, value: 4 },
                  { label: 5, value: 5 },
                ].map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            }
          />

          {/* maintenance plan */}
          {isOBS ? (
            <>
              <FormItem
                label="Maintenance Plan"
                description="Select the appropriate maintenance plan for the system."
                content={
                  <Select
                    fullWidth
                    name="maintenancePlan"
                    value={this.state.maintenancePlan}
                    onChange={({ target }) => {
                      this.setState({ maintenancePlan: target.value });
                      this.props.isChanged(true);
                    }}
                  >
                    {[
                      { name: 'No Plan', value: 'no_plan' },
                      { name: 'Safety Checks', value: 'safety_checks' },
                      { name: 'Average Use System', value: 'average_use_system' },
                      { name: 'High Use System', value: 'high_use_system' },
                    ].map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                }
              />
            </>
          ) : null}

          {/* new hardware (beta) */}
          {isMovatic && (
            <MovaticFieldGroupCheck
              label="New Hardware"
              description="System uses the new hardware model."
              id="new_hardware"
              name="new_hardware"
              onChange={(e) => {
                this.setState({ new_hardware: e.target.checked });
                this.props.isChanged(true);
              }}
              checked={this.state.new_hardware}
            />
          )}
        </div>
      </div>
    );
  }

  render() {
    return (
      <CardView
        headerActions={
          <IconButtonMenu
            buttons={[
              {
                label: 'Save',
                startIcon: <SaveIcon />,
                onClick: this.submit,
                disabled: !this.props.saveButtonLoading || this.props.buttonDisabled,
              },
            ]}
          />
        }
        content={
          <Stack px={2} pb={2}>
            {this.content()}
          </Stack>
        }
        title={'General Settings'}
      />
    );
  }
}

export default connect(
  (state) => ({
    updateError: state.system.error,
    detailedSystem: state.system.current,
    currentMode: state.mode.currentMode,
    admin: state.admin.admin.admin.movaticAccess,
    adminPartner: state.admin.admin.admin.partner,
    rentals: state.rental.rentals,
    saveButtonLoading: state.system.loading,
  }),
  (dispatch) => ({
    updateGeneralSettings(info) {
      dispatch(updateSystemSettings(info));
    },
  })
)(GeneralSettings);
