import React, { useState } from 'react';
import MovaticCustomModal from '../../components/Modal/MovaticCustomModal';
import useAddUserWalletCredit from '../../hooks/users/mutation/useAddUserWalletCredit';
import { useParams } from 'react-router-dom';
import { displaySuccess } from '../../utils';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { MovaticFieldGroupOutlinedInput } from '../../components/Redesing/movatic-field-group';

const UserCreditWaller = ({
  showModal,
  close,
  onSuccess,
}: {
  showModal: boolean;
  close: Function;
  onSuccess: Function;
}) => {
  const [creditAmount, setCreditAmount] = useState<string>('');
  const { userPhone } = useParams<{ userPhone: string }>();

  const addUserWalletCreditMutation = useAddUserWalletCredit();

  const submit = () => {
    const creditInfo = {
      amount: Math.round(parseFloat(creditAmount) * 100).toString(),
      user: userPhone,
    };
    addUserWalletCreditMutation.mutate(creditInfo, {
      onSuccess: (r) => {
        if (r) {
          displaySuccess('Credit added');
          close();
          onSuccess();
        }
      },
    });
  };

  return (
    <>
      <MovaticCustomModal
        open={showModal}
        onClose={close}
        title="Add Credit to Wallet"
        content={
          <div>
            <MovaticFieldGroupOutlinedInput
              type={'number'}
              label="Amount"
              description="How much would you like to credit the user?"
              value={creditAmount}
              inputProps={{ max: 99, min: 0, step: 0.25 }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = event.target;
                const cents =
                  value.split('.')[1] !== undefined ? value.split('.')[1] : '';
                if (value === '') return;
                if (cents.length > 2) return;
                if (
                  value.charAt(value.length - 1) === '.' &&
                  value.split('.').length >= 2
                )
                  return;
                setCreditAmount(value);
              }}
              id="customAmount"
              name={'customAmount'}
              addOn={'$'}
            />
          </div>
        }
        action={
          <>
            <IconButtonMenu
              buttons={[
                {
                  label: 'Cancel',
                  startIcon: <CloseIcon />,
                  onClick: close,
                },
                {
                  label: 'Submit',
                  startIcon: <SaveIcon />,
                  onClick: submit,
                },
              ]}
            />
          </>
        }
      />
    </>
  );
};

export default UserCreditWaller;
