import {
  baseUrlV2,
  getCredentials,
  fetchOptionsV2,
  createQueryParameters,
  fetchPostOptions,
  fetchOptions,
  baseUrl,
} from './http';
import { downloadBlob, displayError, displaySuccess } from '../utils';
import { FourOhOneError } from '../actionCreators/system';
import CustomToast from '../components/Redesing/custom-toast';

export const getMemberships = (parameters) => {
  const { adminEmail, adminToken, systemGid } = getCredentials();
  const fetchOps = fetchOptionsV2(adminEmail, adminToken);
  const queryParametersObject = {
    limit: parameters.limit ?? 100,
    system_id: systemGid,
    ...(parameters.membership_id && { membership_id: parameters.membership_id }),
    ...(parameters.lastId && { after: parameters.lastId }),
    ...(parameters.status && { status: parameters.status }),
    ...(parameters.order_by && { order_by: parameters.order_by }),
    ...(parameters.order && { order: parameters.order }),
    ...(parameters.pattern && { pattern: `%${parameters.pattern}%` }),
  };

  const queryParameters = createQueryParameters(queryParametersObject);
  return fetch(`${baseUrlV2}/membership/?${queryParameters}`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response?.data) {
        return response;
      }
      throw new Error(response?.error_description);
    })
    .catch((e) => {
      displayError(
        'Oops! Looks like there was an error while loading the memberships. Please try again later.'
      );
      return [];
    });
};

export const getUserMemberships = (parameters) => {
  const { adminEmail, adminToken, systemGid } = getCredentials();
  const fetchOps = fetchOptionsV2(adminEmail, adminToken);
  const queryParametersObject = {
    limit: 1000,
    order: 'desc',
    system_id: systemGid,
    ...parameters,
  };
  const queryParameters = createQueryParameters(queryParametersObject);

  return fetch(`${baseUrlV2}/membership/user?${queryParameters}`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response?.data) {
        return response.data;
      }
      throw new Error(response?.error_description);
    })
    .catch((e) => {
      displayError(
        'Oops! Looks like there was an error while loading users memberships. Please try again later.',
        e
      );
      return [];
    });
};

export const exportUserMemberships = (parameters) => {
  const { adminEmail, adminToken } = getCredentials();
  const fetchOps = fetchOptionsV2(adminEmail, adminToken);
  const queryParametersObject = {
    ...parameters,
  };
  const queryParameters = createQueryParameters(queryParametersObject);

  return fetch(`${baseUrlV2}/membership/user/export?${queryParameters}`, fetchOps)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText || response.status);
      }
      return response;
    })
    .then((response) => response.blob())
    .then((blob) => downloadBlob(blob, 'membership_users.csv'))
    .then(() => displaySuccess('The membership users have been exported successfully!'))
    .catch((error) => {
      displayError(
        'Oops! An error has occurred while exporting the membership users. Please try again later.',
        error
      );
    });
};

export const membershipApproval = (payload) => {
  const { body, userId } = payload;
  const { adminEmail, adminToken, systemGid } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemGid, body, 'PUT');
  return fetch(`${baseUrlV2}/membership/user/${userId}/approve`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results.data) {
        return results.data;
      }
      FourOhOneError(results);
      if (results?.error_type === 'payment_error') {
        CustomToast({
          type: 'error',
          message: `There was an error approving the membership: ${results?.error_description}`,
        });
        return null;
      }
      throw new Error(results?.error_description);
    })
    .catch((error) => {
      return Promise.reject(`${error}`);
    });
};

export const addUserMembership = (payload) => {
  const { adminEmail, adminToken, systemGid } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemGid, payload, 'POST');
  return fetch(`${baseUrlV2}/membership/user`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results?.data) {
        return results.data;
      } else if (results?.error_type === 'duplicate') {
        throw new Error('This user already has this membership active.');
      } else {
        throw new Error(results?.error_description);
      }
    })
    .catch((e) => {
      return Promise.reject(`${e}`);
    });
};

export const getMembershipImages = () => {
  const { adminEmail, adminToken, systemId } = getCredentials();

  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);
  return fetch(`${baseUrl}/approve-image/images/membership`, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        return results.message;
      }
      FourOhOneError(results);
      throw new Error(results?.error_description);
    })
    .catch((error) => {
      displayError(`There was an error getting images${error}`);
    });
};

export const archiveMembership = (membershipId) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(
    adminEmail,
    adminToken,
    systemId,
    {
      archive: true,
    },
    'PUT'
  );
  return fetch(`${baseUrl.replace('v1', 'v2')}/membership/${membershipId}`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results?.data) {
        displaySuccess('Your membership was archived successfully!');
      } else {
        throw new Error(results?.error_description);
      }
    })
    .catch(() =>
      displayError(
        'Oops! It looks like there was an error on our end while trying to archive your membership. Please try again later!'
      )
    );
};

export const cancelMembership = (userMembershipId, reason) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, { reason }, 'PUT');

  return fetch(`${baseUrlV2}/membership/user/${userMembershipId}/cancel`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results?.data) {
        displaySuccess('The user membership was canceled successfully!');
        return results?.data;
      }
      throw new Error(results?.error_description);
    })
    .catch(() =>
      displayError(
        'Oops! It looks like there was an error on our end while trying to cancel the user membership. Please try again later!'
      )
    );
};

export const expireMembership = (userMembershipId) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, {}, 'PUT');

  return fetch(`${baseUrlV2}/membership/user/${userMembershipId}/expire`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results?.data) {
        displaySuccess('The user membership was expired successfully!');
        return results?.data;
      }
      throw new Error(
        results?.error_description ||
          results?.message ||
          'Oops! It looks like there was an error on our end while trying to expire the user membership. Please try again later!'
      );
    })
    .catch((error) => {
      return Promise.reject(`${error}`);
    });
};

export const uploadImage = (formData) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  return fetch(`${baseUrl}/system/settings/image`, {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: `Basic ${btoa(`${adminEmail}:${adminToken}`)}`,
      system: systemId,
    },
  })
    .then((res) => res.json())
    .then((results) => {
      if (results.status === 200) {
        return results.message;
      }
      FourOhOneError(results);
      throw new Error(results.message);
    })
    .catch((error) => {
      displayError(`There was an error uploading the image: ${error}`);
      return null;
    });
};

export const updateMembership = (membershipId, fields) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, fields, 'PUT');
  return fetch(`${baseUrlV2}/membership/${membershipId}`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results.data) {
        return results.data;
      }
      FourOhOneError(results);
      throw new Error(results.message);
    })
    .catch((error) => {
      return Promise.reject(`${error}`);
    });
};

export const addMembership = (body) => {
  const { adminEmail, adminToken, systemId } = getCredentials();

  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, body, 'POST');
  return fetch(`${baseUrlV2}/membership/`, fetchOps)
    .then((res) => res.json())
    .then((response) => {
      if (response?.data) {
        return response.data;
      } else {
        FourOhOneError(response);
        throw new Error(response?.error_description);
      }
    })
    .catch((e) => Promise.reject(`${e}`));
};

export const updateMembershipPerks = (membershipId, fields) => {
  const { adminEmail, adminToken, systemId, systemGid } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, fields, 'PUT');

  const queryParametersObject = {
    system_id: systemGid,
  };

  const queryParameters = createQueryParameters(queryParametersObject);

  return fetch(
    `${baseUrlV2}/membership/${membershipId}/perks?${queryParameters}`,
    fetchOps
  )
    .then((res) => res.json())
    .then((results) => {
      if (results.data) {
        displaySuccess('Your membership was updated successfully');
        return true;
      }
      FourOhOneError(results);
      throw new Error(results.message);
    })
    .catch(() =>
      displayError(
        'Oops! It looks like there was an error on our end while trying to update your membership. Please try again later!'
      )
    );
};

export const addMinutesToMembership = (membershipId, minutes) => {
  const { adminEmail, adminToken, systemId } = getCredentials();

  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, { minutes }, 'PUT');
  return fetch(`${baseUrlV2}/membership/user/${membershipId}/admin/add-minutes`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results.data) {
        return true;
      }
      throw new Error(results.error_description);
    })
    .catch((e) => Promise.reject(`${e}`));
};

export const addUnlocksToMembership = (membershipId, unlocks) => {
  const { adminEmail, adminToken, systemId } = getCredentials();

  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, { unlocks }, 'PUT');
  return fetch(`${baseUrlV2}/membership/user/${membershipId}/admin/add-unlocks`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results.data) {
        return true;
      }
      throw new Error(results.error_description);
    })
    .catch((e) => Promise.reject(`${e}`));
};

export const discountMinutesToMembership = (membershipId, minutes) => {
  const { adminEmail, adminToken, systemId } = getCredentials();

  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, { minutes }, 'PUT');
  return fetch(
    `${baseUrlV2}/membership/user/${membershipId}/admin/discount-minutes`,
    fetchOps
  )
    .then((res) => res.json())
    .then((results) => {
      if (results.data) {
        return true;
      }
      FourOhOneError(results);
      throw new Error(results.error_description);
    })
    .catch((e) => Promise.reject(`${e}`));
};

export const discountUnlocksToMembership = (membershipId, unlocks) => {
  const { adminEmail, adminToken, systemId } = getCredentials();

  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, { unlocks }, 'PUT');
  return fetch(
    `${baseUrlV2}/membership/user/${membershipId}/admin/discount-unlocks`,
    fetchOps
  )
    .then((res) => res.json())
    .then((results) => {
      if (results.data) {
        return true;
      }
      FourOhOneError(results);
      throw new Error(results.error_description);
    })
    .catch((e) => Promise.reject(`${e}`));
};
