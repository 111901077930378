import { fetchDirectusData } from '../../../api/directus';
import { useQuery } from '@tanstack/react-query';

const useDirectusData = (
  collection: string,
  filters?: Record<string, string>,
  includeDetailedViews: boolean = false,
  options?: {}
) => {
  return useQuery(
    ['directusData', collection, filters],
    () => fetchDirectusData(collection, filters, includeDetailedViews),
    { ...options }
  );
};

export default useDirectusData;
