import { useSystemStore } from '../store/systemStore';

function getURL() {
  switch (window.location.hostname) {
    case 'web':
      return 'http://admindev.movatic.co/management';
    case 'localhost':
      return process.env.REACT_APP_ADMIN_API_ENDPOINT;
    case 'adminbeta.movatic.co':
      return 'https://adminbeta.movatic.co/management';
    case 'admin.movatic.co':
      return 'https://admin.movatic.co/management';
    case 'newadmin.movatic.co':
      return 'https://newapi.movatic.co/management';
    default:
      return 'https://admindev.movatic.co/management';
  }
}

const baseUrl = `${getURL()}/v1`;
const baseUrlV2 = `${getURL()}/v2`;
const baseUrlV6 = `${getURL()}/v6`;

function fetchOptions(admin, token, system = '', method = 'GET') {
  const options = {
    method,
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // eslint-disable-next-line no-undef
      Authorization: `Basic ${btoa(`${admin}:${token}`)}`,
      ...(system && { system: system }),
    },
  };

  return options;
}

function fetchPostOptions(admin, token, system, data, type) {
  const options = {
    method: type,
    mode: 'cors',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // eslint-disable-next-line no-undef
      Authorization: `Basic ${btoa(`${admin}:${token}`)}`,
      system,
    },
  };
  return options;
}

function fetchOptionsV2(admin, token, method = 'GET', body = null) {
  const options = {
    method,
    mode: 'cors',
    ...(body && { body: JSON.stringify(body) }),
    headers: {
      Accept: 'application/json',
      ...(method !== 'GET' && { 'Content-Type': 'application/json' }),
      Authorization: `Basic ${window.btoa(`${admin}:${token}`)}`,
    },
  };

  return options;
}

function getCredentials() {
  const { admin: adminString } = localStorage;
  const systemInfo = useSystemStore.getState().systemInfo;
  const systemId = systemInfo.oldId || '';
  const systemGid = systemInfo.gid || '';
  let admin = null;

  try {
    admin = JSON.parse(adminString);
  } catch (e) {
    console.error('Error parsing local storage: ', e);
  }

  const adminEmail = admin?.admin?.email;
  const adminToken = admin?.token;

  return {
    adminEmail,
    adminToken,
    systemGid,
    systemId,
  };
}

function createQueryParameters(parametersObject) {
  return Object.entries(parametersObject)
    .reduce(
      (parameters, [key, value]) =>
        value != null
          ? parameters.concat([`${encodeURIComponent(key)}=${encodeURIComponent(value)}`])
          : parameters,
      []
    )
    .join('&');
}

export {
  fetchOptions,
  baseUrl,
  baseUrlV2,
  baseUrlV6,
  fetchPostOptions,
  fetchOptionsV2,
  getCredentials,
  createQueryParameters,
};
