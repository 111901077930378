import React, { useState } from 'react';
import CardView from '../../../components/Redesing/card-view';
import { getLocaleDateTimeString, history } from '../../../utils';
import { PaginationTable } from '../../../components/Redesing/table/pagination-table';
import { IColumn } from '../../../ts/interface/components/redesing/table.interface';
import { IRentalSupport } from '../../../ts/interface/rental.interface';
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants';

interface IRentalRentalSupportProps {
  supportMessage: IRentalSupport[];
}

const RentalSupport: React.FC<IRentalRentalSupportProps> = ({ supportMessage }) => {
  const [search, setSearch] = React.useState('');
  const [pageNumber, setPageNumber] = React.useState(0);
  const [sizePerPage, setSizePerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);
  const [sortData, setSortData] = useState<string>('created_on');

  return (
    <CardView
      content={
        <PaginationTable
          customKey={'old_id'}
          onRowCLick={(id: string) => history.push(`/problems/${id}`)}
          // sortOptions={[
          //   {
          //     label: 'Created',
          //     value: 'created_on',
          //   },
          //   {
          //     label: 'Customer',
          //     value: 'full_name',
          //   },
          //   {
          //     label: 'Description',
          //     value: 'content',
          //   },
          // ]}
          sortBy={sortData}
          onSortChange={(sort) => setSortData(sort.key)}
          handleSearch={(search) => setSearch(search.target.value)}
          searchText={search}
          columns={
            [
              {
                id: 'full_name',
                label: 'Customer',
                format: (row: IRentalSupport) => {
                  return row?.full_name ? row.full_name : ' ';
                },
              },
              {
                id: 'created_on',
                label: 'Created',
                width: '25%',
                format: (row: IRentalSupport) => getLocaleDateTimeString(row?.created_on),
              },
              {
                id: 'content',
                label: 'Description',
                width: '50%',
                format: (row: IRentalSupport) => {
                  return row?.content ? row.content : ' ';
                },
              },
            ] as IColumn[]
          }
          items={supportMessage || []}
          onPageChange={(page, pageN) => {
            setPageNumber(pageN);
          }}
          onRowsPerPageChange={(row) => {
            const pageSize = Number(row.target.value);
            setSizePerPage(pageSize);
          }}
          page={pageNumber}
          rowsPerPage={sizePerPage}
          searchPlaceholder={'Search in rental support'}
        />
      }
      title={'Customer Support'}
    />
  );
};

export default RentalSupport;
