import React, { useMemo, useState } from 'react';
import { perksColumnsV2 } from '../../../tableColumns/membershipColumns';
import { PaginationTable } from '../../../components/Redesing/table/pagination-table';
import { IMembershipPerk } from '../../../ts/interface/membership.interface';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CardView from '../../../components/Redesing/card-view';
import IconButtonMenu from '../../../components/Redesing/icon-button-menu';

interface IMembershipPerksProps {
  perks: IMembershipPerk[];
}

const MembershipPerks: React.FC<IMembershipPerksProps> = (props) => {
  const [searchRate, setSearchRate] = React.useState('');
  const [pageRate, setPageRate] = React.useState(0);
  const [rowsPerPageRate, setRowsPerPageRate] = React.useState(5);
  const [sortData, setSortData] = useState<string>('system_name');
  const [exportData, setExportData] = useState<boolean>(false);
  const columns = useMemo(() => {
    return perksColumnsV2;
  }, []);
  return (
    <CardView
      content={
        <PaginationTable
          showFilter={false}
          sortBy={sortData}
          onSortChange={(sortBy) => setSortData(sortBy.key)}
          exportData={exportData}
          csvFileName={'Perks.csv'}
          onExport={() => setExportData(false)}
          items={props.perks || []}
          noDataText={'This membership has no perks at this time'}
          columns={columns}
          rowsPerPage={rowsPerPageRate}
          page={pageRate}
          onPageChange={(page, pageN: number) => setPageRate(pageN)}
          onRowsPerPageChange={(rowsPerPage) =>
            setRowsPerPageRate(Number(rowsPerPage.target.value))
          }
          searchText={searchRate}
          searchPlaceholder={'Search membership perks'}
          handleSearch={(search) => setSearchRate(search.target.value)}
        />
      }
      title={'Perks'}
      headerActions={
        <IconButtonMenu
          buttons={[
            {
              label: 'Export',
              onClick: () => setExportData(true),
              startIcon: <FileDownloadIcon />,
            },
          ]}
        />
      }
    />
  );
};

export default MembershipPerks;
