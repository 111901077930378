import React, { Component } from 'react';
import Spinner from './spinner';

// This card component is used to hold all of our general page views

class Card extends Component {
  render() {
    if (this.props.hideCard) {
      return null;
    }

    return (
      <div
        className={`cardWrapper ${this.props.customWrapperClass ?? ''}`}
        data-id={this.props.dataId}
      >
        {this.props.modal ? this.props.modal : null}
        {this.props.loaded ? null : (
          <Spinner
            className={this.props.spinnerClass}
            customSpinners={this.props.customSpinners}
          />
        )}
        {this.props.warning
          ? this.props.warning
          : // Add this prop if you want the loading spinner to say "Loading {pageName}" else it will just say "Loading"
            null}
        {
          // Add this prop if you want tabs to be above the card
          this.props.tabs ? this.props.tabs : null
        }
        <div
          className={`cardComponent  ${
            this.props.customClass ? this.props.customClass : ''
          } ${this.props.loaded ? '' : 'loadingBlur'}`}
        >
          {
            // header and button (optional)
            this.props.header ? (
              <div
                className={`headerStyle ${
                  this.props.customHeaderClass ? this.props.customHeaderClass : ''
                }`}
                data-id={this.props.dataId && `${this.props.dataId}-card-header`}
              >
                {this.props.header}
                {this.props.button && this.props.button}
              </div>
            ) : null
          }
          {
            // When there is a button but no header
            this.props.header == null && this.props.button != null ? (
              <div className="headerStyle no-header-card">{this.props.button}</div>
            ) : null
          }
          {
            // Add this prop if you need a datefilter for a table on the card
            this.props.dateFilter ? this.props.dateFilter : null
          }
          <div
            className={`${this.props.detailedView ? 'cardBody' : ''} ${
              this.props.customBodyClass ? this.props.customBodyClass : ''
            }`}
          >
            {this.props.loaded ? null : this.props.loadingDiv ? (
              <div className="loadingDiv" />
            ) : null}
            {
              // This prop is the content that you want in the body of the card
              this.props.content
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Card;
