import type { FC } from 'react';
import Box from '@mui/material/Box';
import type { ListItemProps } from '@mui/material/ListItem';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

type Direction = 'horizontal' | 'vertical';

interface PropertyListItemProps extends ListItemProps {
  align?: Direction;
  label: string | React.ReactNode | number;
  value?: string | number;
  dataId?: string | number;
  isBeta?: boolean; // Add isBeta prop
}

export const PropertyListItem: FC<PropertyListItemProps> = (props) => {
  const {
    align = 'horizontal',
    children,
    disableGutters,
    value,
    label,
    dataId,
    isBeta, // Use the isBeta prop
    ...other
  } = props;

  return (
    <ListItem
      sx={{
        px: disableGutters ? 0 : 2,
        py: 1.5,
      }}
      {...other}
    >
      <ListItemText
        data-id={`${dataId}-item`}
        disableTypography
        primary={
          <Typography
            sx={{ minWidth: align === 'vertical' ? 'inherit' : 180 }}
            variant="body2"
          >
            {label}
          </Typography>
        }
        secondary={
          <Box
            sx={{
              position: 'relative',
              flex: 1,
              mt: align === 'vertical' ? 0.5 : 0,
              width: align === 'vertical' ? '100%' : 'auto',
            }}
          >
            {children || (
              <>
                <Typography
                  color="text.secondary"
                  variant="subtitle1"
                  sx={{
                    overflow: 'hidden',
                    whiteSpace: 'normal', // Allow text wrapping
                    wordWrap: 'break-word', // Wrap long words
                    wordBreak: 'break-all', // Break long words if necessary
                  }}
                >
                  {value}
                  {isBeta && <span className="super movaticColor buttonFont">BETA</span>}
                </Typography>
              </>
            )}
          </Box>
        }
        sx={{
          display: 'flex',
          flexDirection: align === 'vertical' ? 'column' : 'row',
          my: 0,
        }}
      />
    </ListItem>
  );
};
