import React from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import {
  MovaticFieldGroupAsyncSelect,
  MovaticFieldGroupSelect,
} from '../../../components/Redesing/movatic-field-group';
import MenuItem from '@mui/material/MenuItem';
import useStation from '../../../hooks/stations/query/useStation';
import { LocationStatus } from '../../../ts/enums';

const RentalForm = (props) => {
  const {
    formik,
    options,
    handleChange,
    selectedHardware,
    setSelectedHardware,
    handleLocationChange,
  } = props;
  const { errors, values, setFieldValue } = formik;
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const { data, isLoading: isLoadingStations } = useStation(
    {
      enabled: true,
    },
    { status: LocationStatus.active, pattern: search }
  );
  const stations = data?.data
    ? [{ value: 'null', name: 'Location' }, ...data?.data]
    : [{ value: '', name: 'No Location' }];

  return (
    <>
      <MovaticFieldGroupSelect
        label="User"
        description="Select the user that the rental is for"
        optionNodes={options.user_id.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
        value={values.user_id ?? ''}
        onChange={handleChange}
        hasError={!!errors.user_id}
        errorMessage={errors.user_id}
        id="user_id"
        name="user_id"
      />

      <MovaticFieldGroupAsyncSelect
        isLoading={isLoadingStations}
        label={'Location'}
        description="Select the location from which the hardware will be selected"
        id="station_id"
        name="station_id"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        options={stations
          .filter((station) => station.configuration !== 'zone')
          .map((station) => {
            return { value: station.id, name: station.name };
          })}
        onChange={handleLocationChange}
        hasError={!!errors.station_id}
        errorMessage={errors.station_id}
        getOptionText={(option) => `${option.name}`}
        handleSearch={(search) => setSearch(search.target.value)}
      />

      <MovaticFieldGroupSelect
        label="Hardware"
        description="Select the hardware that the user will rent"
        optionNodes={options.hardware_id.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
        value={selectedHardware}
        onChange={(e) => {
          const selected = e.target.value;
          setSelectedHardware(selected);
          setFieldValue('hardware_id', selected);
        }}
        hasError={!!errors.hardware_id}
        errorMessage={errors.hardware_id}
        id="hardware_id"
        name="hardware_id"
      />

      <MovaticFieldGroupSelect
        label="User Membership"
        description="Select an active user membership to use for this rental"
        optionNodes={options.perk_id.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
        value={values.perk_id ?? ''}
        onChange={handleChange}
        hasError={!!errors.perk_id}
        errorMessage={errors.perk_id}
        id="perk_id"
        name="perk_id"
      />

      <MovaticFieldGroupSelect
        label="Rate"
        description="Select a rate to use for this rental"
        optionNodes={options.rate_id.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
        value={values.rate_id ?? ''}
        onChange={handleChange}
        hasError={!!errors.rate_id}
        errorMessage={errors.rate_id}
        id="rate_id"
        name="rate_id"
      />

      <MovaticFieldGroupSelect
        label="Coupon"
        description="Select a coupon to use for this rental (optional)"
        optionNodes={options.coupon_id.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
        value={values.coupon_id ?? ''}
        onChange={handleChange}
        hasError={!!errors.coupon_id}
        errorMessage={errors.coupon_id}
        id="coupon_id"
        name="coupon_id"
      />
    </>
  );
};

export default RentalForm;
