import React, { useMemo, useState } from 'react';
import useHardwareTypes from '../../hooks/hardware/query/useHardwareTypes';
import { connect } from 'react-redux';
import { ISystem } from '../../ts/interface/system.interface';
import usePartner from '../../hooks/partner/query/usePartner';
import { IAuth } from '../../ts/interface/admins.interface';
import {
  IAddSystemDispatchProps,
  IAddSystemProps,
} from '../../ts/interface/pages/system.interface';
import { BEACH_CHAIR, BIKE, KAYAK, LOCKER, RACK, SCOOTER } from '../../constants';
import {
  MovaticFieldGroupAsyncSelect,
  MovaticFieldGroupCheck,
  MovaticFieldGroupSelect,
  MovaticFieldGroupText,
} from '../../components/Redesing/movatic-field-group';
import MenuItem from '@mui/material/MenuItem';
import PageContainer from '../../components/Redesing/page-container';
import CardView from '../../components/Redesing/card-view';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import { Stack } from '@mui/system';
import { createSystem } from '../../api/system';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';
import { history } from '../../utils';
import countries from '../../../data/countries.json';
import CloseIcon from '@mui/icons-material/Close';
import BigButton from '../../components/Redesing/big-button';
import useSearchDebounce from '../../hooks/search/useSearchDebounce';
import { useUpdateEffect } from '../../hooks/use-update-effect';
import useSystems from '../../hooks/system/query/useSystems';

interface IAddSystemState {
  name: string;
  country: string;
  partner: string;
  selectedMobilityType: string;
  demoSystem: boolean;
  adminUserEmail: string;
  adminUserFirstName: string;
  adminUserLastName: string;
  mobilityTypes: { name: string; id: string }[];
  nameValidationResult: null | string;
  nameValidationHelp: string;
  countryValidationResult: null | string;
  countryValidationHelp: string;
  partnerValidationResult: null | string;
  partnerValidationHelp: string;
  selectedMobilityTypeValidationResult: null | string;
  selectedMobilityTypeValidationHelp: string;
  adminUserEmailValidationResult: null | string;
  adminUserEmailValidationHelp: string;
  adminUserFirstNameValidationResult: null | string;
  adminUserFirstNameValidationHelp: string;
  adminUserLastNameValidationResult: null | string;
  adminUserLastNameValidationHelp: string;
}

interface ISystemItem {
  name: string;
  id: string;
  gid: string;
  country: string;
  partner: string;
  hardware_type_gid: string;
}

const Add = ({ systemLoaded, admin, auth, system }: IAddSystemProps) => {
  const initialState = {
    name: '',
    country: '',
    partner: '',
    selectedMobilityType: '',
    demoSystem: true,
    adminUserEmail: '',
    adminUserFirstName: '',
    adminUserLastName: '',
    mobilityTypes: [],
    nameValidationResult: null,
    nameValidationHelp: '',
    countryValidationResult: null,
    countryValidationHelp: '',
    partnerValidationResult: null,
    partnerValidationHelp: '',
    selectedMobilityTypeValidationResult: null,
    selectedMobilityTypeValidationHelp: '',
    adminUserEmailValidationResult: null,
    adminUserEmailValidationHelp: '',
    adminUserFirstNameValidationResult: null,
    adminUserFirstNameValidationHelp: '',
    adminUserLastNameValidationResult: null,
    adminUserLastNameValidationHelp: '',
  };
  const [
    {
      name,
      country,
      partner,
      selectedMobilityType,
      demoSystem,
      adminUserEmail,
      adminUserFirstName,
      adminUserLastName,
      nameValidationResult,
      nameValidationHelp,
      countryValidationResult,
      countryValidationHelp,
      partnerValidationResult,
      partnerValidationHelp,
      selectedMobilityTypeValidationResult,
      selectedMobilityTypeValidationHelp,
      adminUserEmailValidationResult,
      adminUserEmailValidationHelp,
      adminUserFirstNameValidationResult,
      adminUserFirstNameValidationHelp,
      adminUserLastNameValidationResult,
      adminUserLastNameValidationHelp,
    },
    setState,
  ] = useState<IAddSystemState>(initialState);
  const { data: dataHardwareTypes, isLoading: isLoadingTypes } = useHardwareTypes();
  const mobilityTypeOptions = useMemo(() => {
    if (!dataHardwareTypes) return [];

    return [
      {
        value: dataHardwareTypes[BIKE].gid,
        name: 'Bike Share',
        old_id: dataHardwareTypes[BIKE].old_id,
      },
      {
        value: dataHardwareTypes[RACK].gid,
        name: 'Bike Room',
        old_id: dataHardwareTypes[RACK].old_id,
      },
      {
        value: dataHardwareTypes[LOCKER].gid,
        name: 'Bike Locker',
        old_id: dataHardwareTypes[LOCKER].old_id,
      },
      {
        value: dataHardwareTypes[SCOOTER].gid,
        name: 'Scooter',
        old_id: dataHardwareTypes[SCOOTER].old_id,
      },
      {
        value: dataHardwareTypes[KAYAK].gid,
        name: 'Kayak',
        old_id: dataHardwareTypes[KAYAK].old_id,
      },
      {
        value: dataHardwareTypes[BEACH_CHAIR].gid,
        name: 'Beach Chairs',
        old_id: dataHardwareTypes[BEACH_CHAIR].old_id,
      },
    ];
  }, [dataHardwareTypes]);

  const { data } = usePartner({
    enabled: mobilityTypeOptions?.length > 0,
  });
  const partners = data || [];

  const [systemSearch, setSystemSearch] = useState('');
  const systemDebouncedSearchTerm = useSearchDebounce(systemSearch);
  const [systemItems, setSystemItems] = useState<ISystemItem[]>([]);
  const [openSystemSelect, setOpenSystemSelect] = useState(false);
  const [isSystemLoading, setIsSystemLoading] = useState(false);
  const [selectedSystem, setSelectedSystemId] = useState<ISystemItem | null>(null);

  const [useCopySystemSetting, setUseCopySystemSetting] = useState(false);

  const { refetch } = useSystems(
    {
      enabled: false,
    },
    {
      search: systemDebouncedSearchTerm,
    }
  );

  useUpdateEffect(() => {
    const fetchSystems = async () => {
      setIsSystemLoading(true);
      const res = await refetch();
      if (res.status === 'success') {
        const { id, name, gid } = system;
        const currentSystem = {
          id,
          name,
          gid,
          country: system.country,
          partner: system.partner,
          hardware_type_gid: system.hardware_type_gid,
        };
        const { data } = res;
        const systems = data.systems;

        const otherAccessibleSystems = systems
          .filter((system: ISystem) => currentSystem.id !== system.id)
          .map((system: ISystem) => ({
            name: system.name,
            id: system.id,
            gid: system.gid,
            country: system.country,
            partner: system.partner,
            hardware_type_gid: system.hardware_type_gid,
          }));
        setSystemItems([currentSystem, ...otherAccessibleSystems]);
      }
      setIsSystemLoading(false);
    };

    if (openSystemSelect) {
      fetchSystems();
    }
  }, [systemDebouncedSearchTerm, openSystemSelect, system]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventType = event.target.type;
    const eventName = event.target.name;
    let value = eventType === 'checkbox' ? event.target.checked : event.target.value;
    if (eventType === 'text' || eventType === 'select-one') {
      setState((prevState) => ({
        ...prevState,
        [eventName.concat('ValidationResult')]: null,
        [eventName.concat('ValidationHelp')]: '',
      }));
    }
    setState((prevState) => ({
      ...prevState,
      [eventName]: value,
    }));
  };
  const checkValidation = () => {
    let valid = true;
    if (!name) {
      setState((prevState) => ({
        ...prevState,
        nameValidationResult: 'error',
        nameValidationHelp: 'Please add a system name.',
      }));
      valid = false;
    }
    if (!country) {
      setState((prevState) => ({
        ...prevState,
        countryValidationResult: 'error',
        countryValidationHelp: 'Please select a country.',
      }));
      valid = false;
    }
    if (admin && !auth.admin.partner && partner === '') {
      setState((prevState) => ({
        ...prevState,
        partnerValidationResult: 'error',
        partnerValidationHelp: 'Please select a partner.',
      }));
      valid = false;
    }
    if (selectedMobilityType === '') {
      setState((prevState) => ({
        ...prevState,
        selectedMobilityTypeValidationResult: 'error',
        selectedMobilityTypeValidationHelp: 'Please select a mobility type.',
      }));
      valid = false;
    }
    if (!adminUserEmail) {
      setState((prevState) => ({
        ...prevState,
        adminUserEmailValidationResult: 'error',
        adminUserEmailValidationHelp: 'Please add admin user email.',
      }));
      valid = false;
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(adminUserEmail)) {
      setState((prevState) => ({
        ...prevState,
        adminUserEmailValidationResult: 'error',
        adminUserEmailValidationHelp: 'Please enter a valid email address.',
      }));
      return;
    }
    if (!adminUserFirstName) {
      setState((prevState) => ({
        ...prevState,
        adminUserFirstNameValidationResult: 'error',
        adminUserFirstNameValidationHelp: 'Please add admin user first name.',
      }));
      valid = false;
    }
    if (!adminUserLastName) {
      setState((prevState) => ({
        ...prevState,
        adminUserLastNameValidationResult: 'error',
        adminUserLastNameValidationHelp: 'Please add admin user last name.',
      }));
      valid = false;
    }
    return valid;
  };
  const submit = () => {
    if (checkValidation()) {
      const system = {
        name: name.trim(),
        country,
        partner: auth.admin.partner ? auth.admin.partner : partner,
        rentable_hardware_type_id: selectedMobilityType,
        demoSystem,
        adminUser: {
          email: adminUserEmail,
          firstName: adminUserFirstName,
          lastName: adminUserLastName,
        },
        copy_system_id: selectedSystem?.gid || null,
      };
      CustomAsyncToast({
        promise: () => createSystem(system),
        loadingMessage: 'Creating System...',
        successMessage: () => 'System Created Successfully',
        errorMessage: 'Error Creating System.',
      }).then((r) => {
        if (r) {
          history.push(`/dashboard`);
        }
      });
    }
  };

  return (
    <PageContainer isLoading={!systemLoaded || isLoadingTypes}>
      <CardView
        content={
          <Stack px={2} pb={2}>
            {/* SYSTEM NAME */}
            <MovaticFieldGroupText
              id="name"
              name="name"
              label="System Name"
              description="Required"
              hasError={nameValidationResult === 'error'}
              errorMessage={nameValidationHelp}
              value={name}
              onChange={handleChange}
            />

            <MovaticFieldGroupCheck
              id="check-copy-system"
              label={'Enable copy configuration'}
              description={`Enable this option to copy the settings from an existing system configuration.`}
              name="check-copy-system"
              onChange={() => {
                setUseCopySystemSetting(!useCopySystemSetting);
                setSelectedSystemId(null);
                setState((prevState) => ({
                  ...prevState,
                  selectedMobilityType: '',
                  partner: '',
                  country: '',
                }));
              }}
              checked={useCopySystemSetting}
            />

            {useCopySystemSetting && (
              <MovaticFieldGroupAsyncSelect
                id="copy-system-name"
                isLoading={isSystemLoading}
                label="Copy configuration from"
                description={`Choose a system to copy settings from. If the system you need isn't listed, try searching by name.`}
                name={'copy-system-name'}
                value={selectedSystem}
                showClearButton
                open={openSystemSelect}
                isOptionEqualToValue={(option: any, value: any) =>
                  value !== null && option.id === value.id
                }
                getOptionText={(option: any) => option.name}
                options={systemItems}
                handleSearch={(search) => setSystemSearch(search.target.value)}
                onOpen={() => setOpenSystemSelect(true)}
                onClose={() => setOpenSystemSelect(false)}
                onChange={(event: any, item: ISystemItem) => {
                  setSelectedSystemId(item);
                  if (item) {
                    setState((prevState) => ({
                      ...prevState,
                      selectedMobilityType: item.hardware_type_gid,
                      partner: String(item.partner),
                      country: item.country,
                    }));
                  } else {
                    setState((prevState) => ({
                      ...prevState,
                      selectedMobilityType: '',
                      partner: '',
                      country: '',
                    }));
                  }
                }}
              />
            )}

            {/* COUNTRY */}
            <MovaticFieldGroupSelect
              label="Country"
              description={"Required. Don't see your country? Contact support@movatic.co"}
              name={'country'}
              optionNodes={countries.map((country) => (
                <MenuItem key={country['alpha-2']} value={country['alpha-2']}>
                  {country.label}
                </MenuItem>
              ))}
              value={country || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setState((prevState) => ({
                  ...prevState,
                  countryValidationResult: null,
                  countryValidationHelp: '',
                  country: e.target.value,
                }));
              }}
              hasError={countryValidationResult === 'error'}
              errorMessage={countryValidationHelp}
              id={'country'}
              disabled={
                useCopySystemSetting && Boolean(selectedSystem) && Boolean(country)
              }
            />

            {/* PARTNERS (MOVATIC ONLY) */}
            {admin && !auth.admin.partner ? (
              <MovaticFieldGroupSelect
                label="Partner"
                description="Required"
                name={'partner'}
                optionNodes={[
                  { name: 'Select', value: 'none' },
                  ...partners.map((partner: { name: string; id: string }) => ({
                    name: partner.name,
                    value: partner.id,
                  })),
                ].map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
                value={partner || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setState((prevState) => ({
                    ...prevState,
                    partnerValidationResult: null,
                    partnerValidationHelp: '',
                    partner: e.target.value,
                  }));
                }}
                hasError={partnerValidationResult === 'error'}
                errorMessage={partnerValidationHelp}
                id={'partner'}
                disabled={
                  useCopySystemSetting && Boolean(selectedSystem) && Boolean(partner)
                }
              />
            ) : null}

            {/* MOBILITY TYPE */}
            <MovaticFieldGroupSelect
              label="Mobility Type"
              description="Required"
              name={'selectedMobilityType'}
              optionNodes={mobilityTypeOptions.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
              value={selectedMobilityType || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setState((prevState) => ({
                  ...prevState,
                  selectedMobilityTypeValidationResult: null,
                  selectedMobilityTypeValidationHelp: '',
                  selectedMobilityType: e.target.value,
                }));
              }}
              hasError={selectedMobilityTypeValidationResult === 'error'}
              errorMessage={selectedMobilityTypeValidationHelp}
              id={'selectedMobilityType'}
              disabled={
                useCopySystemSetting &&
                Boolean(selectedSystem) &&
                Boolean(selectedMobilityType)
              }
            />

            {/* DEMO CHECKBOX */}
            <MovaticFieldGroupCheck
              id="demoSystem"
              label={'Demo System'}
              description="Is this system for demonstration purposes only?"
              name="demoSystem"
              onChange={handleChange}
              checked={demoSystem}
            />

            {/* ADMIN FIRST NAME */}
            <MovaticFieldGroupText
              id="adminFirstName"
              name="adminFirstName"
              label="Admin User First Name"
              description="First Name of the first user of the system."
              hasError={adminUserFirstNameValidationResult === 'error'}
              errorMessage={adminUserFirstNameValidationHelp}
              value={adminUserFirstName}
              onChange={(event) => {
                setState((prevState) => ({
                  ...prevState,
                  adminUserFirstNameValidationResult: null,
                  adminUserFirstNameValidationHelp: '',
                  adminUserFirstName: event.target.value,
                }));
              }}
            />

            {/* ADMIN LAST NAME */}
            <MovaticFieldGroupText
              id="adminLastName"
              name="adminLastName"
              label="Admin User Last Name"
              description="Last Name of the first user of the system."
              hasError={adminUserLastNameValidationResult === 'error'}
              errorMessage={adminUserLastNameValidationHelp}
              value={adminUserLastName}
              onChange={(event) => {
                setState((prevState) => ({
                  ...prevState,
                  adminUserLastNameValidationResult: null,
                  adminUserLastNameValidationHelp: '',
                  adminUserLastName: event.target.value,
                }));
              }}
            />

            {/* ADMIN EMAIL */}
            <MovaticFieldGroupText
              id="adminUserEmail"
              name="adminUserEmail"
              label="Admin User Email"
              description="Email of the first user of the system."
              hasError={adminUserEmailValidationResult === 'error'}
              errorMessage={adminUserEmailValidationHelp}
              value={adminUserEmail}
              onChange={(event) => {
                setState((prevState) => ({
                  ...prevState,
                  adminUserEmailValidationResult: null,
                  adminUserEmailValidationHelp: '',
                  adminUserEmail: event.target.value,
                }));
              }}
            />
            <BigButton description="Create Your System" onClick={submit} />
          </Stack>
        }
        title={'Add'}
        headerActions={
          <IconButtonMenu
            buttons={[
              {
                label: 'Cancel',
                startIcon: <CloseIcon />,
                onClick: () => history.push(`/dashboard`),
              },
            ]}
          />
        }
      />
    </PageContainer>
  );
};

export default connect(
  (state: {
    system: { current: ISystem; isLoaded: boolean };
    admin: { admin: IAuth };
  }) => ({
    system: state.system.current,
    systemLoaded: state.system.isLoaded,
    systemId: state.system.current.id,
    auth: state.admin.admin,
    admin: state.admin.admin.admin.movaticAccess,
  }),
  (dispatch: Function) => ({
    createSystem(system: IAddSystemDispatchProps) {
      dispatch(createSystem(system));
    },
  })
)(Add);
