import React from 'react';

export default function Spinner (props) {
  return (
    <div className={`spinnerContainer ${props.className ? props.className : ''}`}>
      <div className="spinnerBackground" />
      <div className="rectContainer">
        <div className="rect1 rect" />
        <div className="rect2 rect" />
        <div className="rect3 rect" />
        <div className="rect4 rect" />
        <div className="rect5 rect" />
        <div className="rect6 rect" />
        <div className="rect7 rect" />
        <div className="rect8 rect" />
        <div className="rect9 rect" />
        <div className="rect10 rect" />
        <div className="rect11 rect" />
        <div className="rect12 rect" />
        <div className="rect13 rect" />
      </div>
    </div>
  );
}
