import { useQuery } from '@tanstack/react-query';
import { getUserWalletInformation } from '../../../api/users';

const useUserWallet = (userPhone: string, options?: {}) => {
  return useQuery(
    ['userWalletInformation', userPhone],
    () => getUserWalletInformation(userPhone),
    { ...options }
  );
};

export default useUserWallet;
