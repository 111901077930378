import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { changeTab, findAccess, history, toTitleCase } from '../../utils';
import { connect } from 'react-redux';
import StoreIcon from '@mui/icons-material/Store';
import {
  ACTIVE,
  ALL,
  DEFAULT_ROWS_PER_PAGE,
  LOST,
  MAINTENANCE,
  MISSING,
  MOVATIC_SHOP,
  REBALANCE,
  RETIRED,
  STORED,
} from '../../constants';
import { hardwareColumnsV2 } from '../../tableColumns/hardwareColumns';
import { ISystem } from '../../ts/interface/system.interface';
import { IMode } from '../../ts/interface/mode.interface';
import { IAuth } from '../../ts/interface/admins.interface';
import { IHardware, IHardwareType } from '../../ts/interface/hardware.interface';
import { INewHardwareIndex } from '../../ts/interface/pages/hardware.interface';
import useAllHardware from '../../hooks/hardware/query/useAllHardware';
import PageContainer from '../../components/Redesing/page-container';
import { PaginationTable } from '../../components/Redesing/table/pagination-table';
import {
  FilterChips,
  IColumn,
  ISortConfig,
} from '../../ts/interface/components/redesing/table.interface';
import { updateHardware } from '../../api/hardware';
import AddIcon from '@mui/icons-material/Add';
import { SearchChip } from '../../ts/interface/components/table.interface';
import { useUpdateEffect } from '../../hooks/use-update-effect';
import customAsyncToast from '../../components/Redesing/custom-async-toast';
import useHardwareTypeSystem from '../../hooks/hardware/query/useHardwareTypeSystem';
import Card from '@mui/material/Card';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { MovaticFieldGroupSelectFilter } from '../../components/Redesing/movatic-field-group';
import useQueryString from '../../hooks/queryParams/useQueryString';
import Button from '@mui/material/Button';
import { Stack } from '@mui/system';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import MultiActionChip from '../../components/Redesing/multi-action-chip';
import { getHardwareIconByName } from '../../util/utilsR';
import useMediaQuery from '@mui/material/useMediaQuery';
import { IStatePillsOption } from '../../ts/interface/components/redesing/state-pills.interface';
import useUrlState from '../../hooks/use-url-state';

const filterOptions = [
  { value: ACTIVE, label: 'Active' },
  { value: MAINTENANCE, label: 'Maintenance' },
  { value: RETIRED, label: 'Retired' },
  { value: REBALANCE, label: 'Rebalancing' },
  { value: MISSING, label: 'Missing' },
  { value: LOST, label: 'Lost' },
  { value: STORED, label: 'Stored' },
];

const NewHardware = ({
  system,
  remoteUnlockId,
  remoteLockId,
  remoteRingId,
  remoteActionLoaded,
  auth,
  systemLoaded,
  systemAccess,
  movaticAccess,
}: INewHardwareIndex) => {
  let { type = system.hardware_type } = useParams<{ type: string }>();
  const { queryParams, deleteQueryParam } = useQueryString();
  const [filters, setFilters, isCompleted] = useUrlState({
    sizePerPage: DEFAULT_ROWS_PER_PAGE,
    status: [] as number[],
    types: [] as string[],
    pageNumber: 0,
    search: '',
    sortConfig: {} as ISortConfig,
  });

  const [exportData, setExportData] = useState<boolean>(false);
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumDevice = useMediaQuery(theme.breakpoints.down('md'));
  const shortDevice = useMediaQuery(theme.breakpoints.down('xs'));
  const isMediumScreen = isMediumDevice || shortDevice;
  const [quickFilterStatus, setQuickFilterStatus] = useState<string[]>([]);
  const [resetChips, setResetChips] = useState<boolean>(false);
  const [clicked, setClicked] = useState<boolean>(false);
  const hardwareAccess = findAccess(systemAccess).includes('physical') || movaticAccess;
  const [chips, setChips] = useState<SearchChip[]>([
    {
      label: 'Hardware Type',
      field: 'hardwareTypes',
      value: queryParams.get('type') ? queryParams.get('type') : 'all',
      displayValue: queryParams.get('type')
        ? toTitleCase(queryParams.get('type'))
        : 'All',
      disabled: true,
    },
    ...(queryParams.get('status')
      ? [
          {
            label: 'Status',
            field: 'status',
            value: ACTIVE,
            displayValue: 'Active',
            disabled: false,
          },
        ]
      : []),
  ]);
  const [filterChips, setFilterChips] = useState<FilterChips>({
    status: queryParams.get('status') ? [ACTIVE] : [],
    hardwareTypes: queryParams.get('type')
      ? [queryParams.get('type')?.toLowerCase()]
      : ['all'],
  } as FilterChips);
  const [selectFilter, setSelectFilter] = useState<string | null>(
    queryParams.get('type') ? queryParams.get('type') : 'all'
  );
  const [value, setValue] = React.useState<any[]>([
    ...(queryParams.get('status') ? [{ label: 'Active', value: ACTIVE }] : ([] as any[])),
  ]);

  const sorting: ISortConfig = useMemo(() => {
    return {
      key: filters.sortConfig.key,
      direction: filters.sortConfig.direction,
    };
  }, [filters.sortConfig.key, filters.sortConfig.direction]);

  useUpdateEffect(() => {
    if (isCompleted) {
      const typeFilters = filters.types;
      const statusFilters = filters.status;
      setQuickFilterStatus(typeFilters);

      const chipsAux: SearchChip[] = [];
      statusFilters.forEach((item) => {
        const option = filterOptions.find((option) => option.value === Number(item));
        chipsAux.push({
          label: 'Status',
          field: 'status',
          value: item,
          displayValue: option!?.label,
        });
      });
      setChips(chipsAux);
      setValue(
        statusFilters.map((item) => {
          const option = filterOptions.find((option) => option.value === Number(item));
          return {
            label: option!?.label,
            value: option?.value,
          };
        })
      );
      setFilterChips((prevState) => ({
        ...prevState,
        status: filters.status,
      }));
    }
  }, [isCompleted]);

  useEffect(() => {
    changeTab(selectFilter || selectFilter ? type : '', type, 'hardware');
  }, [selectFilter, type]);

  const { data: hardwareTypeData = [], isLoading: isLoadingHardwareTypes } =
    useHardwareTypeSystem(system.id, {
      enabled: true,
    });

  const hardwareTypeFilters = useMemo(() => {
    const returnData = [{ value: 'all', label: 'All', labelPlural: 'All' }];
    returnData.push(
      ...hardwareTypeData.map((item: IHardwareType) => {
        return {
          value: item.name,
          label: toTitleCase(item.name),
          labelPlural: toTitleCase(item.name_plural),
        };
      })
    );
    return returnData;
  }, [hardwareTypeData]);

  const { data, isLoading, refetch, isFetched } = useAllHardware({
    enabled: true,
  });

  const hardwareColumns = useMemo(() => {
    return hardwareColumnsV2(
      type,
      system.hardware_type,
      remoteUnlockId,
      remoteLockId,
      remoteRingId,
      remoteActionLoaded,
      (row: IHardware, item: IStatePillsOption) => {
        // @ts-ignore
        customAsyncToast({
          promise: () =>
            updateHardware(
              {
                id: row.id,
                state: item.value,
                station:
                  row.state !== LOST &&
                  row.state !== MISSING &&
                  (item.value === LOST || item.value === MISSING)
                    ? null
                    : row.current_station || null,
              },
              {
                hardware_type: type,
              }
            ),
          loadingMessage: 'Updating...',
          successMessage: () => {
            return 'Hardware updated successfully!';
          },
          errorMessage: 'Failed to update hardware',
        }).then(() => refetch());
      },
      isMobileDevice,
      isMediumScreen,
      hardwareAccess
    ) as IColumn[];
  }, [
    type,
    system.hardware_type,
    remoteUnlockId,
    remoteLockId,
    remoteRingId,
    remoteActionLoaded,
    refetch,
    isMobileDevice,
    isMediumScreen,
    hardwareAccess,
  ]);

  const handleChipsUpdate = useCallback(() => {
    const filtersAux: FilterChips = {
      status: [],
      hardwareTypes: [],
    };
    chips.forEach((chip) => {
      switch (chip.field) {
        case 'status':
          // @ts-ignore
          filtersAux.status.push(chip.value);
          break;
        case 'hardwareTypes':
          // @ts-ignore
          filtersAux.hardwareTypes.push(chip.value);
          break;
        default:
          break;
      }
    });
    setFilterChips(filtersAux);
  }, [chips]);

  useUpdateEffect(() => {
    handleChipsUpdate();
  }, [chips, handleChipsUpdate]);

  const handleChipDelete = useCallback(
    (deletedGroup: SearchChip) => {
      if (deletedGroup.field === 'status') {
        if (queryParams.get('status')) {
          deleteQueryParam('status');
        }
        setValue([]);
      }
      setChips((prevChips) => {
        return prevChips.filter((group) => {
          return group.label !== deletedGroup.label;
        });
      });
      handleChipsUpdate();
    },
    [handleChipsUpdate, deleteQueryParam, queryParams]
  );

  const handleStatusChange = useCallback(
    (values: number[]): void => {
      setFilters((prevState) => ({
        ...prevState,
        pageNumber: 0,
        sizePerPage: DEFAULT_ROWS_PER_PAGE,
      }));
      setChips((prevChips) => {
        const valuesFound: number[] = [];

        const newChips = prevChips.filter((chip) => {
          if (
            chip.field !== 'status' &&
            quickFilterStatus.length === 0 &&
            selectFilter === 'all'
          ) {
            return true;
          }

          const found = values.includes(chip.value as number);

          if (found) {
            valuesFound.push(chip.value as number);
          }

          return found;
        });

        if (values.length === valuesFound.length) {
          return newChips;
        }

        values.forEach((value) => {
          if (!valuesFound.includes(value)) {
            const option = filterOptions.find(
              //@ts-ignore
              (option) => option.value === value
            );
            newChips.push({
              label: 'Status',
              field: 'status',
              value,
              displayValue: option!.label,
            });
          }
        });
        return newChips;
      });
    },
    [quickFilterStatus.length, selectFilter, setFilters]
  );

  const handleTypeChange = useCallback(
    (values: string[]): void => {
      setFilters((prevState) => ({
        ...prevState,
        pageNumber: 0,
        sizePerPage: DEFAULT_ROWS_PER_PAGE,
      }));
      setChips((prevChips) => {
        const valuesFound: string[] = [];
        const newChips = prevChips.filter((chip) => {
          if (
            chip.field !== 'hardwareTypes' &&
            quickFilterStatus.length === 0 &&
            selectFilter === 'all'
          ) {
            return true;
          }

          const found = values.includes(chip.value as string);

          if (found) {
            valuesFound.push(chip.value as string);
          }

          return found;
        });

        if (values.length === valuesFound.length) {
          return newChips;
        }

        values.forEach((value) => {
          if (!valuesFound.includes(value)) {
            const option = hardwareTypeFilters.find(
              (option: { value: { toString: () => string } }) =>
                option.value.toString() === value
            );
            newChips.push({
              label: 'Hardware Type',
              field: 'hardwareTypes',
              value,
              displayValue: option!.label,
              disabled: true,
            });
          }
        });
        return newChips;
      });
    },
    [hardwareTypeFilters, quickFilterStatus.length, selectFilter, setFilters]
  );

  const totalHardware = useMemo(() => {
    return data?.length || 0;
  }, [data]);

  const getHardware = useMemo(() => {
    if (!Boolean(data)) return [];
    if (quickFilterStatus.length > 0) {
      //@ts-ignore
      if (isFetched && filterChips.status?.length > 0) {
        const filteredUniqueDataStatus = data
          ?.filter(
            (item: IHardware) =>
              //@ts-ignore
              filterChips.status?.includes(item.state) &&
              quickFilterStatus?.includes(item.hardware_type_name)
          )
          .reduce((uniqueMap: { [key: string]: IHardware }, item: IHardware) => {
            if (!uniqueMap[item.id]) {
              uniqueMap[item.id] = item;
            }
            return uniqueMap;
          }, {});
        // @ts-ignore
        return Object.values(filteredUniqueDataStatus);
      }

      const filteredUniqueData = data
        ?.filter((item: IHardware) =>
          quickFilterStatus?.includes(item.hardware_type_name)
        )
        .reduce((uniqueMap: { [key: string]: IHardware }, item: IHardware) => {
          if (!uniqueMap[item.id]) {
            uniqueMap[item.id] = item;
          }
          return uniqueMap;
        }, {});
      // @ts-ignore
      return Object.values(filteredUniqueData);
    }
    if (
      (isFetched && filterChips?.hardwareTypes?.includes('all')) ||
      filterChips?.hardwareTypes?.length === 0
    ) {
      const filteredUniqueData = data
        ?.filter((item: IHardware) =>
          //@ts-ignore
          filterChips.status?.length > 0 ? filterChips.status?.includes(item.state) : true
        )
        ?.reduce((uniqueMap: { [key: string]: IHardware }, item: IHardware) => {
          if (!uniqueMap[item.id]) {
            uniqueMap[item.id] = item;
          }
          return uniqueMap;
        }, {});
      // @ts-ignore
      return Object.values(filteredUniqueData);
    } else if (isFetched && filterChips.status?.length === 0) {
      const filteredUniqueData = data
        ?.filter(
          (item: IHardware) =>
            filterChips &&
            filterChips.hardwareTypes &&
            filterChips.hardwareTypes.includes(item.hardware_type_name)
        )
        .reduce((uniqueMap: { [key: string]: IHardware }, item: IHardware) => {
          if (!uniqueMap[item.id]) {
            uniqueMap[item.id] = item;
          }
          return uniqueMap;
        }, {});
      // @ts-ignore
      return Object.values(filteredUniqueData);
    }
    //@ts-ignore
    else if (isFetched && filterChips?.status?.length > 0) {
      const filteredUniqueData = data
        ?.filter(
          (item: IHardware) =>
            filterChips &&
            filterChips.hardwareTypes &&
            filterChips.hardwareTypes.includes(item.hardware_type_name) &&
            //@ts-ignore
            filterChips.status?.includes(item.state)
        )
        .reduce((uniqueMap: { [key: string]: IHardware }, item: IHardware) => {
          if (!uniqueMap[item.id]) {
            uniqueMap[item.id] = item;
          }
          return uniqueMap;
        }, {});
      // @ts-ignore
      return Object.values(filteredUniqueData);
    } else {
      const filteredUniqueData = data?.reduce(
        (uniqueMap: { [key: string]: IHardware }, item: IHardware) => {
          if (!uniqueMap[item.id]) {
            uniqueMap[item.id] = item;
          }
          return uniqueMap;
        },
        {}
      );
      // @ts-ignore
      return Object.values(filteredUniqueData || []);
    }
  }, [data, isFetched, filterChips, quickFilterStatus]);

  const getQuickFilterStatus = useCallback(() => {
    return hardwareTypeFilters
      .filter((item) => item.value !== 'all')
      .map((item) => {
        const { Icon } = getHardwareIconByName(item.value);
        return {
          label: item.labelPlural,
          id: item.value,
          icon: <Icon />,
          defaultActive: !quickFilterStatus.includes(item.value),
          onActive: (id: string) => {
            const status = [...quickFilterStatus];
            status.push(id);
            setQuickFilterStatus(status);
            setFilters((prevState) => ({
              ...prevState,
              types: status,
            }));
            setSelectFilter('all');
          },
          onInactive: (id: string) => {
            const status = [...quickFilterStatus];
            const index = status.indexOf(id);
            if (index > -1) {
              status.splice(index, 1);
            }
            if (status.length === 0) {
              setSelectFilter('all');
              setFilterChips((prevState) => {
                return {
                  ...prevState,
                  hardwareTypes: ['all'],
                };
              });
              setChips((prevChips) => {
                return [
                  ...prevChips.filter((chip) => chip.field !== 'hardwareTypes'),
                  {
                    label: 'Hardware Type',
                    field: 'hardwareTypes',
                    value: queryParams.get('type') ? queryParams.get('type') : 'all',
                    displayValue: queryParams.get('type')
                      ? toTitleCase(queryParams.get('type'))
                      : 'All',
                    disabled: true,
                  },
                ];
              });
            }
            setQuickFilterStatus(status);
            setFilters((prevState) => ({
              ...prevState,
              types: status,
            }));
          },
        };
      });
  }, [quickFilterStatus, hardwareTypeFilters, queryParams, setFilters]);

  return (
    <PageContainer
      documentationLink={'https://documentation.movatic.co/admin-site/modes'}
      spaceName={'Hardware'}
    >
      <Card>
        <PaginationTable
          showFilter
          chips={chips.filter((chip) => chip.value !== 'all')}
          isLoading={isLoading || !systemLoaded || isLoadingHardwareTypes || !isCompleted}
          handleChipDelete={handleChipDelete}
          exportData={exportData}
          csvFileName={'Hardware.csv'}
          buttons={[
            {
              startIcon: <StoreIcon />,
              label: 'Buy',
              onClick: () => window.open(MOVATIC_SHOP, '_blank'),
            },
            {
              startIcon: <AddIcon />,
              label: 'Add',
              onClick: () => history.push('/hardware/add'),
              disabled:
                !systemLoaded &&
                !(
                  findAccess(systemAccess).includes('physical') ||
                  auth.admin.movaticAccess
                ),
            },
            {
              label: 'Export',
              startIcon: <FileDownloadIcon />,
              onClick: () => setExportData(true),
            },
            {
              label: 'Import',
              startIcon: <FileUploadIcon />,
              onClick: () => history.push('/hardware/add_csv'),
            },
          ]}
          onExport={() => setExportData(false)}
          onApplyFilter={() => {
            if (selectFilter && clicked) {
              setQuickFilterStatus([]);
              setFilters((prevState) => ({
                ...prevState,
                status: [],
              }));
              setResetChips(true);
              setClicked(false);
              const array: string[] = [];
              const obj = hardwareTypeFilters.find(
                (item: { value: string }) => item.value === selectFilter
              );
              array.push(obj?.value || ALL.toString());
              handleTypeChange(array);
              if (queryParams.get('type') && queryParams.get('type') !== selectFilter) {
                deleteQueryParam('type');
              }
            }
            if (value.length > 0) {
              handleStatusChange(value.map((item: { value: number }) => item.value));
              setFilters((prevState) => ({
                ...prevState,
                status: value.map((item: { value: number }) => item.value),
              }));
              if (queryParams.get('status')) {
                deleteQueryParam('status');
              }
            } else if (queryParams.get('status')) {
              deleteQueryParam('status');
              handleStatusChange([]);
            }
          }}
          spaceFilters={
            <>
              <MovaticFieldGroupSelectFilter
                label={'Status'}
                value={value}
                onChange={(event: React.SyntheticEvent, newValue: any[]) => {
                  setValue([
                    ...newValue.filter((option) =>
                      filterOptions.some((item) => item.label === option.label)
                    ),
                  ]);
                }}
                options={filterOptions}
              />
            </>
          }
          onRowCLick={(id: string) => history.push(`/hardware/${type}/${id}`)}
          sortBy={sorting.key}
          sortDirection={sorting.direction}
          onSortChange={(sort) =>
            setFilters((prevState) => ({
              ...prevState,
              sortConfig: sort as ISortConfig,
            }))
          }
          padding={{ pt: 1, pl: 0, pr: 1 }}
          columns={hardwareColumns as IColumn[]}
          items={getHardware || []}
          searchPlaceholder={`Search`}
          page={filters.pageNumber}
          rowsPerPage={filters.sizePerPage}
          quickFilters={
            <>
              <MultiActionChip
                resetChips={resetChips}
                onReset={() => setResetChips(false)}
                items={[...getQuickFilterStatus()]}
              />
            </>
          }
          noDataText={
            <>
              {totalHardware === 0 ? (
                <Stack alignItems={'center'}>
                  <div style={{ maxWidth: '100%', overflow: 'hidden' }}>
                    <img
                      src={'/assets/hardware_banner.png'}
                      alt={''}
                      style={{ width: '100%', height: 'auto', display: 'block' }}
                    />
                  </div>
                  <Button
                    sx={{
                      bgcolor:
                        theme.palette.mode === 'light'
                          ? '#101C3D'
                          : theme.palette.secondary.main,
                      '&:hover': {
                        textDecoration: 'none',
                        color: '#FFFFFF',
                      },
                    }}
                    disableRipple
                    href={MOVATIC_SHOP}
                    target="_blank"
                    startIcon={<StoreMallDirectoryIcon />}
                    variant="contained"
                  >
                    <Typography variant={'subtitle2'}>Build your fleet</Typography>
                  </Button>
                </Stack>
              ) : (
                <Typography variant={'subtitle1'}>No hardware found</Typography>
              )}
            </>
          }
          onPageChange={(page, newPage) =>
            setFilters((prevState) => ({ ...prevState, pageNumber: newPage }))
          }
          onRowsPerPageChange={(pageSizeString) =>
            setFilters((prevState) => ({
              ...prevState,
              sizePerPage: Number(pageSizeString.target.value),
            }))
          }
          handleSearch={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFilters((prevState) => ({ ...prevState, search: e.target.value }))
          }
          searchText={filters.search}
        />
      </Card>
    </PageContainer>
  );
};

const ConnectedNewHardware = connect(
  (state: {
    hardware: {
      hardwareTypes: string[];
      remoteUnlockId: string;
      remoteLockId: string;
      remoteRingId: string;
      remoteActionLoaded: boolean;
    };
    mode: IMode;
    system: { current: ISystem; isLoaded: boolean };
    admin: { admin: IAuth; systemAccess: number };
  }) => ({
    systemAccess: state.admin.systemAccess,
    modeInfo: state.mode,
    system: state.system.current,
    remoteUnlockId: state.hardware.remoteUnlockId,
    remoteLockId: state.hardware.remoteLockId,
    remoteRingId: state.hardware.remoteRingId,
    remoteActionLoaded: state.hardware.remoteActionLoaded,
    auth: state.admin.admin,
    systemLoaded: state.system.isLoaded,
    movaticAccess: state.admin.admin.admin.movaticAccess,
  }),
  () => ({})
)(NewHardware);

export default withRouter(ConnectedNewHardware);
