import React, { ReactNode } from 'react';
import Stack from '@mui/material/Stack';
import { RouterLink } from '../../components/Redesing/router-link';
import { useMounted } from '../../hooks/use-mounted';
import { paths } from '../../paths';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import PageLoader from '../../components/Redesing/page-loader';

interface PageContainerProps {
  children: ReactNode;
}

const TOP_NAV_HEIGHT = 64;

const LayoutRoot = styled('div')(({ theme }) => ({
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top center',
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  height: '100%',
}));

const Page = ({ children }: PageContainerProps) => {
  const isMounted = useMounted();
  if (!isMounted) return <PageLoader />;
  return (
    <>
      <LayoutRoot>
        <Box
          component="header"
          sx={{
            left: 0,
            position: 'fixed',
            right: 0,
            top: 0,
            zIndex: (theme) => theme.zIndex.appBar,
          }}
        >
          <Container
            sx={{
              maxWidth: '100%',
            }}
          >
            <Stack direction="row" spacing={2} sx={{ height: TOP_NAV_HEIGHT }}>
              <Stack
                alignItems="center"
                component={RouterLink}
                direction="row"
                display="inline-flex"
                href={paths.index}
                spacing={1}
                sx={{ textDecoration: 'none' }}
              ></Stack>
            </Stack>
          </Container>
        </Box>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            flex: '1 1 auto',
          }}
        >
          <Container
            maxWidth="sm"
            sx={{
              py: {
                xs: '60px',
                md: '120px',
              },
            }}
          >
            {children}
          </Container>
        </Box>
      </LayoutRoot>
    </>
  );
};

export default Page;
