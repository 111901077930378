import { useQuery } from '@tanstack/react-query';
import { getOneSystem } from '../../../api/system';

const useSystem = (systemId: string, options?: {}) => {
  return useQuery(['oneSystem', systemId], () => getOneSystem(systemId), {
    ...options,
  });
};

export default useSystem;
