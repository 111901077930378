import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { mapStationMarker } from '../../components/mapMarker';
import { createLocation } from '../../api/stations';
import { ACTIVE, MOVATIC_PRIMARY_COLOR, RADIUS_UPPER_LIMIT } from '../../constants';
import {
  displayError,
  findAccess,
  getPricingString,
  history,
  truncateName,
} from '../../utils';
import { uploadImage } from '../../api/memberships';
//@ts-ignore
import { FeatureGroup, Map } from 'react-leaflet/es';
//@ts-ignore
import { EditControl } from 'react-leaflet-draw/dist/esm';
import { GenericMapTileLayer } from '../../components/mapComponent';
import {
  ICoordinates,
  INewStation,
  IStation,
  StationConfiguration,
} from '../../ts/interface/station.interface';
import useRates from '../../hooks/rates/query/useRates';
import { IRate } from '../../ts/interface/rental.interface';
import { ISystem } from '../../ts/interface/system.interface';
import { IMode } from '../../ts/interface/mode.interface';
import { IStationAddProps } from '../../ts/interface/pages/stations.interfaces';
import FileUpload from '../../components/Redesing/file-upload';
import { Stack } from '@mui/system';
import Typography from '@mui/material/Typography';
import {
  MovaticFieldGroupCheck,
  MovaticFieldGroupMultiSelect,
  MovaticFieldGroupOutlinedInput,
  MovaticFieldGroupRadio,
  MovaticFieldGroupSelect,
  MovaticFieldGroupText,
} from '../../components/Redesing/movatic-field-group';
import FormItem from '../../components/Redesing/form-item';
import MenuItem from '@mui/material/MenuItem';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import SaveIcon from '@mui/icons-material/Save';
import PageContainer from '../../components/Redesing/page-container';
import CardView from '../../components/Redesing/card-view';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';
import NoFilePlaceHolder from '../../components/Redesing/no-file-place-holder';

const SLOW_ZONE_ERROR = 'Please enter a valid speed limit';
const RADIUS_ERROR = `Please enter a valid radius value between 1 and ${RADIUS_UPPER_LIMIT}`;
const { ZONE, RACK, DOCK, STORE, WAREHOUSE } = StationConfiguration;

interface StationState {
  stationName: string;
  bannerFiles: [] | { preview: string }[];
  nameValidationResult: string | null;
  nameValidationHelp: string;
  lat: number | null;
  latValidationResult: string | null;
  latValidationHelp: string;
  lng: number | null;
  longValidationResult: string | null;
  longValidationHelp: string;
  state: number | null;
  validationResult: string | null;
  help: string;
  capacity: number | null;
  isLoading: boolean;
  module: string | null;
  moduleMac?: string | null;
  moduleResult: string | null;
  moduleValidationHelp: string;
  reservations: boolean;
  maxReservations: number;
  zoneCoordinates: number[][];
  configuration: StationConfiguration;
  zoneType: string;
  slowZoneSpeed: string | null;
  slowZoneSpeedHelp: string;
  radius: number;
  radiusValidationResult: string | null;
  radiusValidationHelp: string;
  preventEndingRentals: boolean;
  preventStartingRentals: boolean;
  stations: IStation[];
  stationsLoaded: boolean;
  systemRates: { label: string; value: string }[] | string[];
  ratesLoaded: boolean;
  selectedRates: string[];
  componentRates: string[];
}

const initialState: StationState = {
  stationName: '',
  bannerFiles: [],
  nameValidationResult: null,
  nameValidationHelp: '',
  lat: null,
  latValidationResult: null,
  latValidationHelp: '',
  lng: null,
  longValidationResult: null,
  longValidationHelp: '',
  state: null,
  validationResult: null,
  help: '',
  capacity: null,
  isLoading: false,
  module: null,
  moduleMac: undefined,
  moduleResult: null,
  moduleValidationHelp: '',
  reservations: false,
  maxReservations: 0,
  zoneCoordinates: [],
  configuration: RACK,
  zoneType: 'parking',
  slowZoneSpeed: null,
  slowZoneSpeedHelp: '',
  radius: 30,
  radiusValidationResult: null,
  radiusValidationHelp: '',
  preventEndingRentals: false,
  preventStartingRentals: false,
  stations: [],
  stationsLoaded: false,
  systemRates: [],
  ratesLoaded: false,
  selectedRates: [],
  componentRates: [],
};

const StationAdd = ({
  systemLoaded,
  systemAccess,
  movaticAccess,
  system,
  modeInfo,
  modules,
}: IStationAddProps) => {
  const [state, setState] = useState<StationState>(initialState);
  const mapRef = useRef();
  const [marker, setMarker] = useState(null);
  const { isLoading: ratesLoading } = useRates({
    enabled: systemLoaded,
    onSuccess: (data: IRate[]) => {
      setState((prevState) => ({
        ...prevState,
        systemRates: data
          .filter((rate) => !rate.archived)
          .map((rate) => {
            const priceString = getPricingString({ ...rate, base: rate.base_rate });
            return {
              label: `${truncateName(rate.name)}` + (priceString && ` (${priceString})`),
              value: rate.gid,
            };
          }),
        ratesLoaded: true,
      }));
    },
  });

  const getMapRef = () => {
    if (mapRef.current) {
      //@ts-ignore
      return mapRef.current.leafletElement;
    }
    return null;
  };

  const handleMapClick = (e: { latlng: { lat: number; lng: number } }) => {
    let map = getMapRef();
    const lat = parseFloat(e.latlng.lat.toFixed(10));
    const lng = parseFloat(e.latlng.lng.toFixed(10));
    let latValidationResult: string | null = null;
    let latValidationHelp = '';
    let longValidationResult: string | null = null;
    let longValidationHelp = '';

    if (lat > 90) {
      latValidationResult = 'error';
      latValidationHelp = 'Latitude cannot be greater than 90';
    } else if (lat < -90) {
      latValidationResult = 'error';
      latValidationHelp = 'Latitude cannot be less than -90';
    }

    if (lng > 180) {
      longValidationResult = 'error';
      longValidationHelp = 'Longitude cannot be greater than 180';
    } else if (lng < -180) {
      longValidationResult = 'error';
      longValidationHelp = 'Longitude cannot be less than -180';
    }

    removeMarkers();

    setMarker(
      // @ts-ignore
      window.L.marker([e.latlng.lat, e.latlng.lng], {
        icon: mapStationMarker(modeInfo.details.hardware_type),
      }).addTo(map)
    );

    setState((prevState) => ({
      ...prevState,
      lat,
      lng,
      latValidationResult,
      latValidationHelp,
      longValidationResult,
      longValidationHelp,
      zoneCoordinates: [],
    }));
  };

  const onCreatePolygon = (e: {
    layer: { editing: { latlngs: [ICoordinates][][] } };
  }) => {
    // save points to state always, but show error if coords are out of bounds
    const mapCoords = e.layer.editing.latlngs[0][0];
    const coords = mapCoords.map((latlng: ICoordinates) => [
      Number(latlng.lat.toFixed(5)),
      Number(latlng.lng.toFixed(5)),
    ]);
    setState((prevState) => ({
      ...prevState,
      zoneCoordinates: coords,
    }));

    if (outOfBounds(coords)) {
      displayError('One or more of the points in your zone are invalid');
    }
  };

  const onEditPolygon = (e: { layers: { toGeoJSON: Function } }) => {
    if (e.layers.toGeoJSON().features.length === 0) {
      // user clicked edit but made no changes
      return;
    }

    // save points to state always, but show error if coords are out of bounds
    const mapCoords = e.layers.toGeoJSON().features[0].geometry.coordinates[0];
    // geoJSON is backwards (lnglat instead of latlng)
    const coords = mapCoords.map((latlng: number[]) => [
      Number(latlng[1].toFixed(5)),
      Number(latlng[0].toFixed(5)),
    ]);

    // the last point tends to be a duplicate of the first one for some reason
    if (
      coords[0][0] === coords[coords.length - 1][0] &&
      coords[0][1] === coords[coords.length - 1][1]
    ) {
      coords.pop();
    }
    setState((prevState) => ({
      ...prevState,
      zoneCoordinates: coords,
    }));

    if (outOfBounds(coords)) {
      displayError('One or more of the points in your zone are invalid');
    }
  };

  const onDeletePolygon = () => {
    setState((prevState) => ({
      ...prevState,
      zoneCoordinates: [],
    }));
  };

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: value,
    }));
  };

  const handleSlowZoneOptionsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      slowZoneSpeed: e.target.value,
      slowZoneSpeedHelp: '',
    }));
  };

  const handleModuleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newModuleId: null | string = null;
    modules.forEach((module) => {
      if (module.mac === e.target.value) {
        newModuleId = module.mac;
      }
    });
    setState((prevState) => ({
      ...prevState,
      module: newModuleId,
    }));
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      stationName: e.target.value,
      nameValidationResult: null,
      nameValidationHelp: '',
    }));
  };

  const handleRadiusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      radius: parseInt(e.target.value),
      radiusValidationResult: null,
      radiusValidationHelp: '',
    }));
  };

  const handleLatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const lat = parseFloat(e.target.value);
    if (lat > 90) {
      setState((prevState) => ({
        ...prevState,
        latValidationResult: 'error',
        latValidationHelp: 'Latitude cannot be greater than 90',
      }));
      removeMarkers();
    } else if (lat < -90) {
      setState((prevState) => ({
        ...prevState,
        latValidationResult: 'error',
        latValidationHelp: 'Latitude cannot be less than -90',
      }));
      removeMarkers();
    } else {
      setState((prevState) => ({
        ...prevState,
        latValidationResult: null,
        latValidationHelp: '',
      }));
      if (state.lng) {
        const map = getMapRef();
        removeMarkers();
        // @ts-ignore
        setMarker(window.L.marker([lat, state.lng]).addTo(map));
        map.setView([lat, state.lng], 12);
      }
    }
    setState((prevState) => ({
      ...prevState,
      lat,
    }));
  };

  const removeMarkers = () => {
    if (marker !== null) {
      const map = getMapRef();
      map.removeLayer(marker);
      setMarker(null);
    }
  };

  const handleFileUpload = (files: Blob[] | MediaSource[], rejected: string) => {
    if (rejected.length > 0) {
      displayError('That photo is too large');
      return;
    }

    const images = files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    const image = new Image();
    image.src = images[0].preview;

    image.onload = () => {
      if (image.width !== 900 || image.height !== 300) {
        displayError('Your image is not 900px by 300px, please reformat and try again');
        setState((prevState) => ({
          ...prevState,
          bannerFiles: [],
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          bannerFiles: images,
        }));
      }
    };
  };

  const handleLongChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const long = parseFloat(e.target.value);
    if (long > 180) {
      setState((prevState) => ({
        ...prevState,
        longValidationResult: 'error',
        longValidationHelp: 'Longitude cannot be greater than 180',
      }));
      removeMarkers();
    } else if (long < -180) {
      setState((prevState) => ({
        ...prevState,
        longValidationResult: 'error',
        longValidationHelp: 'Longitude cannot be less than -180',
      }));
      removeMarkers();
    } else {
      if (state.lat) {
        const map = getMapRef();
        removeMarkers();
        // @ts-ignore
        setMarker(window.L.marker([state.lat, long]).addTo(map));
        map.setView([state.lat, long], 8);
      }
      setState((prevState) => ({
        ...prevState,
        longValidationResult: null,
        longValidationHelp: '',
      }));
    }
    setState((prevState) => ({
      ...prevState,
      lng: long,
    }));
  };

  const handleStateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      state: parseInt(e.target.value),
    }));
  };

  const handleCapacityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (state.capacity?.toString().length === 1 && e.target.value === '') {
      setState((prevState) => ({
        ...prevState,
        capacity: null,
      }));
      return;
    }
    setState((prevState) => ({
      ...prevState,
      capacity: Math.floor(parseInt(e.target.value)),
    }));
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const name = e.target.name;
    if (value !== RACK && value !== WAREHOUSE && value !== DOCK && value !== STORE) {
      removeMarkers();
      setState((prevState) => ({
        ...prevState,
        [name]: value,
        zoneType: value,
        lng: null,
        lat: null,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
        zoneCoordinates: [],
      }));
    }
  };

  const handleRadioConfigurationChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    const name = e.target.name;
    if (value !== RACK && value !== WAREHOUSE && value !== DOCK && value !== STORE) {
      removeMarkers();
      setState((prevState) => ({
        ...prevState,
        [name]: value,
        configuration: value as StationConfiguration,
        lng: null,
        lat: null,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
        configuration: value as StationConfiguration,
        zoneCoordinates: [],
      }));
    }
  };

  const click = () => {
    const {
      configuration,
      slowZoneSpeed,
      radius,
      preventEndingRentals,
      preventStartingRentals,
      selectedRates,
    } = state;
    const isZone = configuration === ZONE;
    const validSlowZoneSpeedValue = Number.isNaN(parseInt(slowZoneSpeed || '', 10))
      ? null
      : parseInt(slowZoneSpeed || '', 10);
    const canAddRadius = [RACK, WAREHOUSE].includes(configuration);
    const validRadiusValue = Number.isNaN(parseInt(String(radius), 10))
      ? null
      : parseInt(String(radius), 10);

    if (canBeSubmitted()) {
      // info that all system types need
      const newStation: INewStation = {
        name: state.stationName.trim(),
        lat: isZone ? null : Number(state.lat),
        lng: isZone ? null : Number(state.lng),
        state: 1, // this is NOT the React state. it's the state of the station e.g. 'active' or 'unassigned'
        type: modeInfo.details.number,
        reservations: state.reservations,
        maxReservations: state.maxReservations,
        configuration:
          modeInfo.currentMode === 'bikeshare' || modeInfo.currentMode === 'scooter'
            ? state.configuration
            : null,
        polygon_coordinates: isZone ? state.zoneCoordinates : null,
        zone_type: isZone ? state.zoneType : null,
        slow_zone_speed: isZone ? validSlowZoneSpeedValue : null,
        radius: canAddRadius ? validRadiusValue : null,
        prevent_ending_rentals: preventEndingRentals,
        prevent_starting_rentals: preventStartingRentals,
        rates_id: selectedRates.length ? selectedRates : null,
      };
      if (state.capacity) newStation.capacity = state.capacity;
      if (modeInfo.currentMode === 'bikeRoom' && !system.new_hardware) {
        newStation.module = state.module;
        if (newStation.module == null) newStation.state = 0;
      }
      if (state.bannerFiles.length > 0) {
        const formData = new FormData();
        // @ts-ignore
        formData.append('stationBannerFile', state.bannerFiles[0]);
        CustomAsyncToast({
          promise: () => uploadImage(formData),
          successMessage: () => 'Image Uploaded Successfully',
          errorMessage: 'Image Upload Failed',
          loadingMessage: 'Uploading Image...',
        }).then((response) => {
          if (response) {
            newStation.banner_id = response.id;
            CustomAsyncToast({
              promise: () => createLocation(newStation),
              successMessage: () => 'Station Created Successfully',
              errorMessage: 'Station Creation Failed',
              loadingMessage: 'Creating Station...',
            }).then(() => history.push('/locations'));
          }
        });
      } else {
        CustomAsyncToast({
          promise: () => createLocation(newStation),
          successMessage: () => 'Station Created Successfully',
          errorMessage: 'Station Creation Failed',
          loadingMessage: 'Creating Station...',
        }).then(() => history.push('/locations'));
      }
    }
  };

  const canBeSubmitted = () => {
    let canBeSubmitted = true;

    if (state.stationName === '') {
      setState((prevState) => ({
        ...prevState,
        nameValidationResult: 'error',
        nameValidationHelp: 'A Name is Required to Create',
      }));
      canBeSubmitted = false;
    }

    if (state.stationName) {
      state.stations.forEach((station) => {
        if (
          station.name.toLowerCase().replace(/\s/g, '') ===
            state.stationName.toLowerCase().replace(/\s/g, '') &&
          station.state === ACTIVE
        ) {
          canBeSubmitted = false;
          setState((prevState) => ({
            ...prevState,
            nameValidationResult: 'error',
            nameValidationHelp: 'The name you entered already exists',
          }));
        }
      });
    }

    if (state.stationName.length > 30) {
      setState((prevState) => ({
        ...prevState,
        nameValidationResult: 'error',
        nameValidationHelp: 'Station Name Must be Less then 30 Characters',
      }));
      canBeSubmitted = false;
    }

    if (state.configuration === ZONE) {
      // TODO: more specific
      if (outOfBounds(state.zoneCoordinates)) {
        displayError('The coordinates for your zone are invalid');
        canBeSubmitted = false;
      }

      if (state.zoneCoordinates.length <= 2) {
        displayError('A zone must be made of at least three points');
        canBeSubmitted = false;
      }

      const speedLimit = parseInt(state.slowZoneSpeed ?? '0', 10);

      if (Number.isNaN(speedLimit) || speedLimit < 0 || speedLimit > 999) {
        displayError(SLOW_ZONE_ERROR);
        setState((prevState) => ({
          ...prevState,
          slowZoneSpeedHelp: SLOW_ZONE_ERROR,
        }));
        canBeSubmitted = false;
      }
    } else {
      if (!state.lat) {
        setState((prevState) => ({
          ...prevState,
          latValidationResult: 'error',
          latValidationHelp: 'Latitude is required',
        }));
        canBeSubmitted = false;
      } else if (state.lat > 90) {
        setState((prevState) => ({
          ...prevState,
          latValidationResult: 'error',
          latValidationHelp: 'Latitude cannot be greater than 90',
        }));
        canBeSubmitted = false;
      } else if (state.lat < -90) {
        setState((prevState) => ({
          ...prevState,
          latValidationResult: 'error',
          latValidationHelp: 'Latitude cannot be less than -90',
        }));
        canBeSubmitted = false;
      }
      if (!state.lng) {
        setState((prevState) => ({
          ...prevState,
          longValidationResult: 'error',
          longValidationHelp: 'Longitude is required',
        }));
        canBeSubmitted = false;
      } else if (state.lng > 180) {
        setState((prevState) => ({
          ...prevState,
          longValidationResult: 'error',
          longValidationHelp: 'Longitude cannot be greater than 180',
        }));
        canBeSubmitted = false;
      } else if (state.lng < -180) {
        setState((prevState) => ({
          ...prevState,
          longValidationResult: 'error',
          longValidationHelp: 'Longitude cannot be less than -180',
        }));
        canBeSubmitted = false;
      }

      const radius = state.radius;

      if (
        [RACK, WAREHOUSE].includes(state.configuration) &&
        (Number.isNaN(radius) || radius < 1 || radius > RADIUS_UPPER_LIMIT)
      ) {
        setState((prevState) => ({
          ...prevState,
          radiusValidationResult: 'error',
          radiusValidationHelp: RADIUS_ERROR,
        }));
        canBeSubmitted = false;
      }
    }

    return canBeSubmitted;
  };

  const outOfBounds = (coords: number[][]): boolean => {
    // !! converts to boolean
    // find returns undefined if no match and casts to false (i.e., no out of bounds points)
    // or the pair of coordinates that match, which will be cast to true
    return !!coords.find(
      (latLng) => latLng[0] > 90 || latLng[0] < -90 || latLng[1] > 180 || latLng[1] < -180
    );
  };

  const content = () => {
    const { stationsTitle } = modeInfo.details;
    const { preventEndingRentals, preventStartingRentals, systemRates } = state;
    const moduleOptions = [
      ...modules.map((module) => {
        if (module.state === 0) {
          return {
            value: module.mac,
            name: module.mac,
            id: module.mac,
          };
        }
        return undefined;
      }),
      {
        value: 'null',
        name: 'None',
        id: 0,
      },
    ];

    return (
      <div>
        {
          // name
        }
        <MovaticFieldGroupText
          id="name"
          name="name"
          label={`${stationsTitle} Name`}
          description="Visible to users to identify where they are renting from."
          value={state.stationName}
          hasError={state.nameValidationResult === 'error'}
          errorMessage={state.nameValidationHelp}
          onChange={handleNameChange}
        />

        {(modeInfo.currentMode === 'bikeshare' || modeInfo.currentMode === 'scooter') && (
          <MovaticFieldGroupRadio
            label="Location Type"
            value={state?.configuration || 'rack'}
            onChange={handleRadioConfigurationChange}
            options={[
              {
                id: RACK,
                label: 'Rack-based',
                popoverContent: `Locations that have racks where ${modeInfo.details.pluralUnits.toLowerCase()} with smart locks can park`,
              },
              {
                id: ZONE,
                label: 'Zone-based',
                popoverContent: `Large areas where ${modeInfo.details.pluralUnits.toLowerCase()} can be parked`,
              },
              {
                id: DOCK,
                label: 'Dock-based',
                popoverContent: `Locations that have racks with smart locks for ${modeInfo.details.unitsTitle.toLowerCase()} parking`,
              },
              {
                id: STORE,
                label: 'Store',
                popoverContent: `Staffed location where users can rent or park ${modeInfo.details.pluralUnits.toLowerCase()}`,
              },
              {
                id: WAREHOUSE,
                label: 'Warehouse',
                popoverContent: `Locations where ${modeInfo.details.pluralUnits.toLowerCase()} are stored. They are not visible to users.`,
              },
            ]}
          />
        )}
        {(modeInfo.currentMode === 'bikeshare' || modeInfo.currentMode === 'scooter') &&
          state.configuration === ZONE && (
            <MovaticFieldGroupRadio
              label="Zone Type"
              value={state.zoneType || 'parking'}
              onChange={handleRadioChange}
              options={[
                {
                  id: 'parking',
                  label: 'Parking',
                  popoverContent: `Area for users to park their ${modeInfo.details.pluralUnits.toLowerCase()} at the end of rentals`,
                },
                {
                  id: 'preferred',
                  label: 'Preferred',
                  popoverContent: `Preferred area for users to park their ${modeInfo.details.pluralUnits.toLowerCase()} at the end of rentals`,
                },
                {
                  id: 'system',
                  label: 'System',
                  popoverContent: `Large area to mark zone where users can leave ${modeInfo.details.pluralUnits.toLowerCase()} in free roam systems`,
                },
                {
                  id: 'restricted',
                  label: 'Restricted',
                  popoverContent: `Area where users are not allowed to park ${modeInfo.details.pluralUnits.toLowerCase()} at the end of rentals`,
                },
                {
                  id: 'slowzone',
                  label: 'Slow Zone',
                  popoverContent: `Area where users are not allowed to ride ${modeInfo.details.pluralUnits.toLowerCase()} above a speed limit`,
                },
                {
                  id: 'nogo',
                  label: 'No Go',
                  popoverContent: `Area where users are prohibited from riding`,
                },
                {
                  id: 'deployment',
                  label: 'Deployment',
                  popoverContent: `A zone used to balance ${modeInfo.details.unitsTitle.toLowerCase()} deployments. This zone is not visible to users.`,
                },
              ]}
            />
          )}

        {(modeInfo.currentMode === 'bikeshare' || modeInfo.currentMode === 'scooter') &&
          state.configuration === ZONE && (
            <MovaticFieldGroupOutlinedInput
              label="Speed Limit"
              description="Speed limit for this zone."
              type="number"
              name="slowZoneOptions"
              id="slowZoneOptions"
              value={state.slowZoneSpeed ?? null}
              inputProps={{ min: 0, step: 1 }}
              onChange={handleSlowZoneOptionsChange}
              hasError={state.slowZoneSpeedHelp === 'error'}
              errorMessage={state.slowZoneSpeedHelp}
              addOn={'km/h'}
            />
          )}

        {![ZONE].includes(state.configuration) && (
          <MovaticFieldGroupOutlinedInput
            label="Radius"
            description="Radius of the area for this location in meters."
            type="number"
            name="radius"
            id="radius"
            value={state.radius || ''}
            inputProps={{ min: 0 }}
            onChange={handleRadiusChange}
            addOn={'meters'}
          />
        )}
        {
          // capacity for rack and dock based
          (modeInfo.currentMode === 'bikeshare' || modeInfo.currentMode === 'scooter') &&
          [RACK, DOCK, WAREHOUSE].includes(state.configuration) ? (
            <MovaticFieldGroupOutlinedInput
              label="Station Capacity"
              description="The number of spots available for return. Leave blank for unlimited capacity."
              type="number"
              name="capacity"
              id="capacity"
              value={state.capacity}
              inputProps={{ min: 0, step: 1 }}
              onChange={handleCapacityChange}
            />
          ) : null
        }
        {
          // user does not choose capacity for bike room
          modeInfo.currentMode === 'bikeRoom' && !system.new_hardware ? (
            <div>
              <MovaticFieldGroupSelect
                label="Module"
                description="What module is assigned to the hardware"
                name={'module'}
                optionNodes={moduleOptions.map((option, index) => (
                  <MenuItem key={index} value={option?.value}>
                    {option?.name}
                  </MenuItem>
                ))}
                value={state.module ? state.module : 'None'}
                onChange={handleModuleChange}
                id="module"
              />
            </div>
          ) : null
        }

        {state.module == null || state.module === '' ? null : (
          <MovaticFieldGroupSelect
            label={`${stationsTitle} Status`}
            description={`A ${stationsTitle.toLowerCase()} must be active for rentals to occur at it.`}
            name={'module'}
            optionNodes={[
              { value: 1, name: 'Active' },
              { value: 3, name: 'Maintenance' },
            ].map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
            value={state.state === 2 ? '3' : state.state}
            onChange={handleStateChange}
            id="state"
          />
        )}
        {system.reservations ? (
          <div>
            <MovaticFieldGroupCheck
              label="Reservations"
              description="Allow users to make reservations ahead of time"
              id="reservations"
              name="reservations"
              onChange={onChangeValue}
              checked={state.reservations}
            />
            {state.reservations ? (
              <MovaticFieldGroupSelect
                label="Max Reservations"
                description="Limit the amount of reservations"
                name={'maxReservations'}
                optionNodes={[
                  { value: '0', label: 'none' },
                  { value: '1', label: '1' },
                  { value: '2', label: '2' },
                  { value: '3', label: '3' },
                  { value: '4', label: '4' },
                  { value: '5', label: '5' },
                  { value: '10', label: '10' },
                  { value: '20', label: '20' },
                ].map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
                value={state.maxReservations}
                onChange={onChangeValue}
                id="maxReservations"
              />
            ) : null}
          </div>
        ) : null}

        <FormItem
          label={'Banner Image'}
          description="Add a banner so users can identify this station more easily. The banner image must be 900px X 300px and a .png"
          content={
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Stack>
                <span>Banner Preview</span>
                <div id="currentLogo">
                  {state.bannerFiles.length > 0 ? (
                    <img
                      alt=""
                      className="station_bannerImage"
                      //@ts-ignore
                      name="bannerfile"
                      src={state.bannerFiles[0].preview}
                    />
                  ) : (
                    <NoFilePlaceHolder />
                  )}
                </div>
              </Stack>
              <Stack mt={'6em'}>
                <Typography>
                  <i
                    className="far fa-file-image fieldHorizontalPadding paddingLeftClear"
                    aria-hidden="true"
                  />
                  Upload A Station Banner
                </Typography>
                <FileUpload
                  caption={
                    'The file must be a .png, 900px wide, 300px high, and less than 1MB'
                  }
                  onDrop={handleFileUpload}
                />
              </Stack>
            </Stack>
          }
        />
        {[
          {
            id: 'preventEndingRentals',
            label: 'Prevent ending rentals',
            description:
              'Check this option if you do not want a user to be able to end a rental in this location.',
            checked: preventEndingRentals,
          },
          {
            id: 'preventStartingRentals',
            label: 'Prevent starting rentals',
            description:
              'Check this option if you do not want a user to be able to start a rental in this location.',
            checked: preventStartingRentals,
          },
        ].map(({ id, label, description, checked }) => (
          <MovaticFieldGroupCheck
            key={id}
            label={label}
            description={description}
            id={id}
            name={id}
            onChange={onChangeValue}
            checked={checked}
          />
        ))}
        {systemRates.length > 0 && system.new_hardware && (
          <MovaticFieldGroupMultiSelect
            label="Assigned Rates"
            description="Select optional rates for this location."
            value={state.componentRates}
            onChange={(event: React.SyntheticEvent, newValue: any[]) => {
              setState((state) => ({
                ...state,
                selectedRates: newValue.map((rate) => rate.value),
                componentRates: newValue,
              }));
            }}
            options={systemRates}
          />
        )}

        <div className="fieldTopPadding">
          {state.configuration !== ZONE ? (
            <div>
              <MovaticFieldGroupOutlinedInput
                type={'number'}
                description={`The latitude of the ${stationsTitle.toLowerCase()}. (Enter the value or click the location on the map)`}
                label={`${stationsTitle} Latitude`}
                value={`${state.lat}`}
                allowNegativeNumbers
                hasError={state.latValidationResult === 'error'}
                errorMessage={state.latValidationHelp}
                onChange={handleLatChange}
                id="lat"
              />

              <MovaticFieldGroupOutlinedInput
                type={'number'}
                description={`The longitude of the ${stationsTitle.toLowerCase()}. (Enter the value or click the location on the map)`}
                label={`${stationsTitle} Longitude`}
                value={`${state.lng}`}
                allowNegativeNumbers
                hasError={state.longValidationResult === 'error'}
                errorMessage={state.longValidationHelp}
                onChange={handleLongChange}
                id="lng"
              />
            </div>
          ) : (
            <FormItem
              label="Edit Zone"
              content="Click the pentagon to begin drawing the zone. Click the edit icon to edit the shape afterward, or the delete icon to remove it and start over."
            />
          )}
        </div>
        <Stack pt={2}>
          <Map
            center={[0, 0]}
            zoom={2}
            ref={mapRef}
            maxBounds={[
              [90, 180],
              [-90, -180],
            ]}
            onClick={state.configuration !== ZONE ? handleMapClick : null}
          >
            {state.configuration === ZONE && (
              <FeatureGroup>
                <EditControl
                  onCreated={onCreatePolygon}
                  onDeleted={onDeletePolygon}
                  onEdited={onEditPolygon}
                  draw={{
                    polygon:
                      state.zoneCoordinates.length === 0
                        ? {
                            allowIntersection: false,
                            shapeOptions: {
                              color: MOVATIC_PRIMARY_COLOR(1),
                            },
                          }
                        : false,
                    rectangle: false,
                    circle: false,
                    polyline: false,
                    marker: false,
                    circlemarker: false,
                  }}
                />
              </FeatureGroup>
            )}
            <GenericMapTileLayer />
          </Map>
        </Stack>
      </div>
    );
  };

  return (
    <PageContainer isLoading={!systemLoaded || ratesLoading}>
      <CardView
        content={
          <Stack px={2} pb={2}>
            {content()}
          </Stack>
        }
        title={`Add a ${modeInfo.details.stationsTitle}`}
        headerActions={
          <IconButtonMenu
            buttons={[
              {
                label: 'Cancel',
                onClick: () => history.push('/locations'),
              },
              {
                label: 'Save',
                onClick: click,
                disabled:
                  (!findAccess(systemAccess).includes('physical') && !movaticAccess) ||
                  state.isLoading,
                startIcon: <SaveIcon />,
              },
            ]}
          />
        }
      />
    </PageContainer>
  );
};

export default connect(
  (state: {
    admin: {
      systemAccess: number;
      admin: {
        admin: {
          movaticAccess: boolean;
          partner: number;
        };
      };
    };
    system: {
      current: ISystem;
      isLoaded: boolean;
    };
    mode: IMode;
    module: {
      modules: { mac: string; state: number }[];
    };
  }) => ({
    systemAccess: state.admin.systemAccess,
    system: state.system.current,
    modeInfo: state.mode,
    modules: state.module.modules,
    systemLoaded: state.system.isLoaded,
    movaticAccess:
      state.admin.admin.admin.movaticAccess && !state.admin.admin.admin.partner,
  }),
  () => ({})
)(StationAdd);
