import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useOneHardware from '../../hooks/hardware/query/useOneHardware';
import {
  findAccess,
  history,
  rentable,
  toTitleCase,
  validateImei,
  validateMacAddress,
  validateVendorSerial,
} from '../../utils';
import { IAuth } from '../../ts/interface/admins.interface';
import { connect } from 'react-redux';
import {
  ACTIVE,
  HARDWARE_NUMBER_LIMIT,
  LOST,
  MAINTENANCE,
  MISSING,
  REBALANCE,
  RETIRED,
  STORED,
} from '../../constants';
import CheckBox from '../../components/CheckBox/CheckBox';
import useHardwareProduct from '../../hooks/hardwareProduct/query/useHardwareProduct';
import { IMode } from '../../ts/interface/mode.interface';
import { ISystem } from '../../ts/interface/system.interface';
import { IStation } from '../../ts/interface/station.interface';
import useStation from '../../hooks/stations/query/useStation';
import { INewHardwareEditProps } from '../../ts/interface/pages/hardware.interface';
import PageContainer from '../../components/Redesing/page-container';
import CardView from '../../components/Redesing/card-view';
import { Autocomplete, TextField } from '@mui/material';
import { Stack } from '@mui/system';
import FormItem from '../../components/Redesing/form-item';
import { updateHardwareV2 } from '../../api/hardware';
import {
  MovaticFieldGroupAsyncSelect,
  MovaticFieldGroupOutlinedInput,
  MovaticFieldGroupSelect,
  MovaticFieldGroupText,
} from '../../components/Redesing/movatic-field-group';
import MenuItem from '@mui/material/MenuItem';
import IconButtonMenu from '../../components/Redesing/icon-button-menu';
import SaveIcon from '@mui/icons-material/Save';
import useSearchDebounce from '../../hooks/search/useSearchDebounce';
import { getAllSystemsV2 } from '../../api/system';
import CustomAsyncToast from '../../components/Redesing/custom-async-toast';
import { mapHardwareMarker } from '../../components/mapMarker';
// @ts-ignore
import { Map, Marker } from 'react-leaflet/es';
import { GenericMapTileLayer } from '../../components/mapComponent';
import { LocationStatus } from '../../ts/enums';
interface INewHardwareEditState {
  openSystemSelect: boolean;
  systemSelected: ISystem | null;
  physical: boolean;
  unretire: boolean;
  state: Number | null;
  system: string;
  station: { value: number; name: string };
  number: string;
  numberValidation: string | null;
  numberHelp: string;
  mac: string;
  macHelp: string;
  macValidation: string | null;
  latitude: number;
  longitude: number;
  latitudeHelp: string;
  latitudeValidation: string | null;
  longitudeHelp: string;
  longitudeValidation: string | null;
  imei: string;
  imeiHelp: string;
  imeiValidation: string | null;
  label: string;
  labelHelp: string;
  labelValidation: string | null;
  movaticQrCode: string;
  movaticQrCodeHelp: string;
  movaticQrCodeValidation: string | null;
  vendorQrCode: string;
  vendorQrCodeHelp: string;
  vendorQrCodeValidation: string | null;
  vendorId: string;
  vendorIdHelp: string;
  vendorIdValidation: string | null;
  validationResultSystem: boolean;
  systemHelp: string;
  has_vendor_serial: boolean;
  vendorSerial: string;
  vendorSerialValidation: string | null;
  vendorSerialHelp: string;
}

const reducer = (state: INewHardwareEditState, action: { type: string; value: any }) => {
  switch (action.type) {
    case 'SET_VALUE':
      return { ...state, ...action.value };
    default:
      return state;
  }
};

const initialState = {
  openSystemSelect: false,
  systemSelected: null,
  physical: false,
  unretire: false,
  state: null,
  station: null,
  number: '',
  numberValidation: null,
  numberHelp: '',
  mac: '',
  macHelp: '',
  macValidation: null,
  imei: '',
  imeiHelp: '',
  imeiValidation: null,
  label: '',
  labelHelp: '',
  labelValidation: null,
  // qr codes from our qr_code table
  movaticQrCode: '',
  movaticQrCodeHelp: '',
  movaticQrCodeValidation: null,
  // qr codes from the vendor for temporary use only
  vendorQrCode: '',
  vendorQrCodeHelp: '',
  vendorQrCodeValidation: null,
  vendorId: '',
  vendorIdHelp: '',
  vendorIdValidation: null,
  validationResultSystem: false,
  systemHelp: '',
  has_vendor_serial: false,
  vendorSerial: '',
  vendorSerialValidation: null,
  vendorSerialHelp: '',
  latitude: '',
  longitude: '',
  latitudeHelp: '',
  longitudeHelp: '',
  circleBounds: null,
};

const NewHardwareEdit = ({
  auth,
  mode,
  system,
  systemLoaded,
  systemAccess,
}: INewHardwareEditProps) => {
  const { hardwareId, type } = useParams<{ hardwareId: string; type: string }>();
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
    system: system?.id,
  });
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = React.useState('');
  const debouncedSearchTerm = useSearchDebounce(search, 500);
  const [refetched, setRefetch] = useState(false);
  const mapRef = useRef();
  const [systemChanged, setSystemChanged] = useState(false);

  const [availableSystems, setAvailableSystems] = useState<
    { name: string; id: string }[]
  >([]);
  const [hardware, setHardware] = useState<any>({});

  const { isLoading, refetch } = useOneHardware(hardwareId);

  useEffect(() => {
    refetch().then((r) => {
      setHardware(r.data);
      setRefetch(true);
    });
  }, [refetch]);

  const { data: hardwareProduct, isLoading: isLoadingProduct } = useHardwareProduct(
    { hardware_product_id: hardware?.hardware_product_gid },
    {
      enabled: !!hardware?.hardware_product_gid,
    }
  );
  const { data: stationsResult } = useStation(
    {
      enabled: !!system?.gid,
    },
    { status: LocationStatus.active, system_id: system.gid }
  );

  const stationsList = useMemo(() => {
    return stationsResult?.data || [];
  }, [stationsResult]);

  const hardwareProductData = hardwareProduct?.data[0]?.data;
  const isRentable = rentable(
    hardwareProductData?.hardware_type.id,
    mode.details.hardware_type_gid
  );
  const productName = hardwareProductData?.hardware_type?.name;

  useEffect(() => {
    if (state.openSystemSelect) {
      setLoading(true);
      getAllSystemsV2({
        search: debouncedSearchTerm,
      }).then((response) => {
        const { id, name } = system;
        const currentSystem = { id, name };
        const otherAccessibleSystems = response.systems
          .filter((system: ISystem) => currentSystem.id !== system.id)
          .map(({ name, id }: { name: string; id: string }) => ({ name, id }));

        setAvailableSystems([currentSystem, ...otherAccessibleSystems]);
        setLoading(false);
      });
    }
  }, [state.openSystemSelect, system, debouncedSearchTerm]);

  useEffect(() => {
    if (!!hardware?.id && systemLoaded && state.state === null && refetched) {
      const station = stationsList.find(
        (station: IStation) => station.old_id === hardware.current_station
      );
      dispatch({
        type: 'SET_VALUE',
        value: {
          state: hardware?.state ? hardware.state : null,
          station: station ? { value: station.old_id, name: station.name || '' } : null,
          number: hardware.number,
          latitude: hardware?.last_lat || '',
          longitude: hardware?.last_lng || '',
          mac: hardware.mac,
          imei: hardware.imei,
          label: hardware.label,
          movaticQrCode: hardware.qr_id,
          vendorQrCode: hardware.vendor_qr_code,
          vendorId: hardware.vendor_id,
          vendorSerial: hardware.vendor_serial,
          system: system.id,
        },
      });
    }
  }, [hardware, systemLoaded, system, stationsList, refetched, state.state]);

  const getStations = () => {
    return [
      { name: `No Location`, value: null },
      ...stationsList
        .filter((station: IStation) => station.configuration !== 'zone')
        .map((station: IStation) => ({
          name: `${station.name}${
            hardware?.current_station === station.old_id ? ' (current location)' : ''
          }`,
          value: station.old_id,
        })),
    ];
  };

  const handleLatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (value > 90) {
      dispatch({
        type: 'SET_VALUE',
        value: {
          latitudeHelp: 'The number you entered is invalid',
        },
      });
    } else if (value < -90) {
      dispatch({
        type: 'SET_VALUE',
        value: {
          latitudeHelp: 'Latitude cannot be less than -90',
        },
      });
    } else {
      dispatch({
        type: 'SET_VALUE',
        value: {
          latitudeHelp: '',
          latitude: parseFloat(value.toString(10)),
        },
      });
    }
  };

  const handleLongChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (value > 180) {
      dispatch({
        type: 'SET_VALUE',
        value: {
          longitudeHelp: 'Longitude cannot be greater than 180',
        },
      });
    } else if (value < -180) {
      dispatch({
        type: 'SET_VALUE',
        value: {
          longitudeHelp: 'Longitude cannot be less than -180',
        },
      });
    } else {
      dispatch({
        type: 'SET_VALUE',
        value: {
          longitudeHelp: '',
          longitude: parseFloat(value.toString(10)),
        },
      });
    }
  };

  const handleMapClick = (e: { latlng: { lat: number; lng: number } }) => {
    const lat = parseFloat(e.latlng.lat.toString(10));
    const lng = parseFloat(e.latlng.lng.toString(10));
    let latValidationHelp = '';
    let longValidationHelp = '';

    if (lat > 90) {
      latValidationHelp = 'Latitude cannot be greater than 90';
    } else if (lat < -90) {
      latValidationHelp = 'Latitude cannot be less than -90';
    }

    if (lng > 180) {
      longValidationHelp = 'Longitude cannot be greater than 180';
    } else if (lng < -180) {
      longValidationHelp = 'Longitude cannot be less than -180';
    }

    dispatch({
      type: 'SET_VALUE',
      value: {
        longitudeHelp: longValidationHelp,
        latitudeHelp: latValidationHelp,
        latitude: lat,
        longitude: lng,
      },
    });
  };

  const canSubmit = () => {
    let canSubmit = true;
    if (isRentable) {
      if (!state.number) {
        canSubmit = false;
        dispatch({
          type: 'SET_VALUE',
          value: { numberValidation: 'error', numberHelp: 'A number is required.' },
        });
      }

      if (state.number < 1 || state.number > HARDWARE_NUMBER_LIMIT) {
        canSubmit = false;
        dispatch({
          type: 'SET_VALUE',
          value: {
            numberValidation: 'error',
            numberHelp: 'The number you entered is invalid',
          },
        });
      }
    }
    if (!state.system) {
      canSubmit = false;
      dispatch({
        type: 'SET_VALUE',
        value: {
          systemHelp: 'The system cannot be empty.',
          validationResultSystem: 'error',
        },
      });
    }

    if (hardwareProductData.has_mac) {
      if (!validateMacAddress(state.mac)) {
        canSubmit = false;
        dispatch({
          type: 'SET_VALUE',
          value: { macValidation: 'error', macHelp: 'A valid MAC address is required.' },
        });
      }
    }

    if (hardwareProductData.has_imei) {
      if (!validateImei(state.imei)) {
        canSubmit = false;
        dispatch({
          type: 'SET_VALUE',
          value: { imeiValidation: 'error', imeiHelp: 'A valid IMEI is required.' },
        });
      }
    }

    if (hardwareProductData.require_label) {
      if (!state.label) {
        canSubmit = false;
        dispatch({
          type: 'SET_VALUE',
          value: { labelValidation: 'error', labelHelp: 'A label is required.' },
        });
      }
    }

    if (hardwareProductData.has_vendor_id) {
      if (!state.vendorId) {
        canSubmit = false;
        dispatch({
          type: 'SET_VALUE',
          value: {
            vendorSerialValidation: 'error',
            vendorSerialHelp: 'Vendor ID is required.',
          },
        });
      }
    }

    if (hardwareProductData.has_vendor_serial) {
      if (!validateVendorSerial(state.vendorSerial)) {
        canSubmit = false;
        dispatch({
          type: 'SET_VALUE',
          value: {
            vendorSerialValidation: 'error',
            vendorSerialHelp: 'Please format your vendor serial properly.',
          },
        });
      }
    }
    return canSubmit;
  };

  const selectedValue = useMemo(() => {
    if (state.systemSelected !== system.id) {
      setSystemChanged(true);
    } else {
      setSystemChanged(false);
    }
    return state.systemSelected ? state.systemSelected : system;
  }, [state.systemSelected, system]);

  const loadingSystems = useMemo(() => {
    return loading;
  }, [loading]);

  const submit = () => {
    const updateData = {
      id: hardware.id,
      imei: state.imei || null,
      label: state.label || null,
      lat: state.latitude || null,
      lng: state.longitude || null,
      mac: state.mac || null,
      movaticQrCode: state.movaticQrCode || null,
      number: state.number || null,
      state: state.state,
      station:
        state.system && system.id !== state.system
          ? null
          : hardware.state !== LOST &&
            hardware.state !== MISSING &&
            (state.state === LOST || state.state === MISSING)
          ? null
          : state?.station?.value?.toString() || null,
      system: state.system,
      vendorQrCode: state.vendorQrCode || null,
      ...(hardwareProductData?.has_vendor_id
        ? { vendor_id: state.vendorId || null }
        : {}),
      vendor_serial: state.vendorSerial || null,
    };

    if (hardware.state === RETIRED) {
      CustomAsyncToast({
        promise: () =>
          updateHardwareV2({
            id: hardware.id,
            number: hardware.number,
            unretire: state.unretire,
          }),
        successMessage: () => 'Hardware retired successfully!',
        errorMessage: 'There was an error retiring the hardware.',
        loadingMessage: 'Retiring hardware...',
      }).then((response) => {
        if (response) {
          history.push(`/hardware/${type}/${hardware.id}`);
        }
      });
    } else if (canSubmit()) {
      CustomAsyncToast({
        promise: () =>
          updateHardwareV2(updateData).then(async () => {
            const r_1 = await refetch();
            return r_1.data;
          }),
        successMessage: () => 'Hardware updated successfully!',
        errorMessage: 'There was an error updating the hardware.',
        loadingMessage: 'Updating hardware...',
      }).then((response) => {
        if (response) {
          if (!systemChanged) {
            history.push(`/hardware/${type}/${hardware.id}`);
          } else {
            history.push('/hardware');
          }
        }
      });
    }
  };
  const content = () => {
    const { name, id } = system;
    if (hardware?.state === RETIRED) {
      return (
        <FormItem
          label="Unretire?"
          description={`This ${hardware.hardware_type_name} is currently in retirement. To edit it you need to first bring it out of retirement.`}
          content={
            <CheckBox
              checkBoxId={'state'}
              checked={state.unretire}
              onChange={() =>
                dispatch({ type: 'SET_VALUE', value: { unretire: !state.unretire } })
              }
            />
          }
        />
      );
    }
    return (
      <div>
        <MovaticFieldGroupSelect
          label="Hardware State"
          description="Please choose the state you would like to apply to this hardware."
          name={'state'}
          optionNodes={[
            // TODO: extract this and make it a utility constant
            { value: ACTIVE, name: 'Active' },
            { value: MAINTENANCE, name: 'Maintenance' },
            { value: REBALANCE, name: 'Rebalance' },
            { value: MISSING, name: 'Missing' },
            { value: LOST, name: 'Lost' },
            { value: STORED, name: 'Stored' },
          ].map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
          value={`${state.state}`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            dispatch({
              type: 'SET_VALUE',
              value: { state: Number(e.target.value) || '' },
            });
          }}
          id={'state'}
        />

        {isRentable && (
          <MovaticFieldGroupOutlinedInput
            type={'number'}
            label="Number"
            description={`This must be a number from 1 to ${HARDWARE_NUMBER_LIMIT}`}
            value={state.number}
            inputProps={{ max: HARDWARE_NUMBER_LIMIT, min: 1 }}
            hasError={state.numberValidation === 'error'}
            errorMessage={state.numberHelp}
            onChange={(e) => {
              const value = Number(e.target.value);
              dispatch({
                type: 'SET_VALUE',
                value: {
                  number: value,
                  numberValidation: null,
                  numberHelp: '',
                },
              });
            }}
            id="number"
            name={'number'}
          />
        )}

        {hardwareProductData?.has_vendor_id && (
          <MovaticFieldGroupText
            id="vendorId"
            name={'vendorId'}
            label="Vendor ID"
            description="Unique vendor identifier for this hardware"
            hasError={state.vendorIdValidation === 'error'}
            errorMessage={state.vendorIdHelp}
            value={state.vendorId}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch({
                type: 'SET_VALUE',
                value: {
                  vendorId: e.target.value,
                  vendorIdValidation: null,
                  vendorIdHelp: '',
                },
              });
            }}
          />
        )}

        <MovaticFieldGroupText
          id="label"
          name={'label'}
          label="Label"
          description={`Descriptive label to help admins identify the ${productName} ${
            hardwareProductData?.require_label ? '(required)' : '(optional)'
          }`}
          hasError={state.labelValidation === 'error'}
          errorMessage={state.labelHelp}
          value={state.label}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            dispatch({
              type: 'SET_VALUE',
              value: {
                label: e.target.value,
                labelValidation: null,
                labelHelp: '',
              },
            });
          }}
        />

        <div>
          {isRentable && (
            <FormItem
              label="Movatic QR Code"
              description={`ID of the QR code you would like to assign to this ${productName} (optional)`}
              content={
                <TextField
                  id="movaticQrCode"
                  variant="outlined"
                  fullWidth
                  type={'number'}
                  value={state.movaticQrCode}
                  helperText={state.movaticQrCodeHelp}
                  error={!!state.movaticQrCodeValidation}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    if (value && value < 1) {
                      dispatch({
                        type: 'SET_VALUE',
                        value: {
                          movaticQrCode: e.target.value,
                          movaticQrCodeValidation: 'error',
                          movaticQrCodeHelp: 'The number you entered is invalid',
                        },
                      });
                      return;
                    }
                    dispatch({
                      type: 'SET_VALUE',
                      value: {
                        movaticQrCode: e.target.value,
                        movaticQrCodeValidation: null,
                        movaticQrCodeHelp: '',
                      },
                    });
                  }}
                />
              }
            />
          )}
          {isRentable && (
            <FormItem
              label="Vendor QR Code"
              description={`ID of the QR code for this ${productName} provided by the vendor. For use in demo systems only (optional)`}
              content={
                <TextField
                  id="vendorQrCode"
                  variant="outlined"
                  fullWidth
                  value={state.vendorQrCode}
                  error={!!state.vendorQrCodeValidation}
                  helperText={state.vendorQrCodeHelp}
                  onChange={(e) =>
                    dispatch({
                      type: 'SET_VALUE',
                      value: {
                        vendorQrCode: e.target.value,
                        vendorQrCodeValidation: null,
                        vendorQrCodeHelp: '',
                      },
                    })
                  }
                />
              }
            />
          )}
          {hardwareProductData?.has_vendor_serial && (
            <FormItem
              label="Serial Number"
              description={hardwareProductData?.vendor_serial_description || ''}
              content={
                <TextField
                  id="vendorSerial"
                  error={!!state.vendorSerialValidation}
                  variant="outlined"
                  fullWidth
                  value={state.vendorSerial}
                  helperText={state.vendorSerialHelp}
                  onChange={(e) =>
                    dispatch({
                      type: 'SET_VALUE',
                      value: {
                        vendorSerial: e.target.value,
                        vendorSerialValidation: null,
                        vendorSerialHelp: '',
                      },
                    })
                  }
                />
              }
            />
          )}
        </div>

        <MovaticFieldGroupAsyncSelect
          id="system-name"
          isLoading={loadingSystems}
          label="System"
          description={`Move ${hardware?.hardware_type_name} to a different system`}
          name={'system-name'}
          value={selectedValue}
          showClearButton
          open={state.openSystemSelect}
          isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
          getOptionText={(option: any) => option.name}
          options={availableSystems}
          hasError={state.validationResultSystem}
          errorMessage={state.systemHelp}
          handleSearch={(search) => {
            setSearch(search.target.value);
          }}
          onOpen={() => {
            dispatch({ type: 'SET_VALUE', value: { openSystemSelect: true } });
          }}
          onClose={() => {
            dispatch({ type: 'SET_VALUE', value: { openSystemSelect: false } });
          }}
          onChange={(event: any, item: { id: string }) => {
            dispatch({
              type: 'SET_VALUE',
              value: {
                system: item?.id || id,
                systemSelected: item || { name: name, id: id },
              },
            });
          }}
        />

        {/* station */}
        {hardwareProductData?.has_station && (
          <FormItem
            label="Assigned Location"
            description={`The current location of the ${productName}`}
            content={
              <Autocomplete
                id="station"
                options={getStations()}
                getOptionLabel={(option) => toTitleCase(option.name)}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                value={state.station}
                onChange={(_, selected) =>
                  dispatch({ type: 'SET_VALUE', value: { station: selected } })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="stationSelected"
                    variant="outlined"
                    placeholder={'Select Station'}
                    fullWidth
                  />
                )}
              />
            }
          />
        )}

        {/* mac */}
        {hardwareProductData?.has_mac && (
          <FormItem
            label="Mac Address"
            description={`Please enter the mac address for this ${hardware.hardware_type_name}. This must be six groups of two hexadecimal digits (0-9, a-f) separated by colons (e.g. 01:23:45:67:89:ab)`}
            content={
              <TextField
                id="mac"
                variant="outlined"
                fullWidth
                value={state.mac}
                error={!!state.macValidation}
                helperText={state.macHelp}
                onChange={(e) =>
                  dispatch({
                    type: 'SET_VALUE',
                    value: {
                      mac: e.target.value,
                      macValidation: null,
                      macHelp: '',
                    },
                  })
                }
              />
            }
          />
        )}

        {/* imei */}
        {hardwareProductData?.has_imei && (
          <FormItem
            label="IMEI"
            description="Must be 15 or 17 digits"
            content={
              <TextField
                id="imei"
                variant="outlined"
                fullWidth
                value={state.imei}
                helperText={state.imeiHelp}
                error={!!state.imeiValidation}
                onChange={(e) =>
                  dispatch({
                    type: 'SET_VALUE',
                    value: {
                      imei: e.target.value,
                      imeiValidation: null,
                      imeiHelp: '',
                    },
                  })
                }
              />
            }
          />
        )}
        {(hardware?.has_gps || hardware?.is_free_roam) && (
          <>
            <MovaticFieldGroupOutlinedInput
              type={'number'}
              description={`The latitude of the ${productName}`}
              label={'Latitude'}
              allowNegativeNumbers
              value={state.latitude}
              hasError={state.latitudeHelp.length > 0}
              errorMessage={state.latitudeHelp}
              onChange={handleLatChange}
              id="lat"
            />
            <MovaticFieldGroupOutlinedInput
              type={'number'}
              allowNegativeNumbers
              description={`The longitude of the ${productName}`}
              label={'Longitude'}
              value={state.longitude}
              onChange={handleLongChange}
              hasError={state.longitudeHelp.length > 0}
              errorMessage={state.longitudeHelp}
              id="lng"
            />

            <Map
              center={[state.latitude, state.longitude]}
              zoom={17}
              bounds={null}
              boundsOptions={{ padding: [15, 15] }}
              ref={mapRef}
              onClick={handleMapClick}
            >
              {state.latitude && state.longitude && (
                <Marker
                  position={[state.latitude, state.longitude]}
                  icon={mapHardwareMarker(hardware.hardware_type_name)}
                />
              )}
              <GenericMapTileLayer />
            </Map>
          </>
        )}
      </div>
    );
  };

  return (
    <PageContainer>
      <CardView
        headerActions={
          <>
            <IconButtonMenu
              buttons={[
                {
                  label: 'Cancel',
                  onClick: () => history.push(`/hardware/${type}/${hardware.id}`),
                },
                {
                  label: 'Save',
                  onClick: submit,
                  disabled:
                    !!hardware?.current_rental ||
                    (!findAccess(systemAccess).includes('physical') &&
                      !auth.admin.movaticAccess),
                  startIcon: <SaveIcon />,
                },
              ]}
            />
          </>
        }
        title={`Edit ${toTitleCase(type)}`}
        content={
          <Stack paddingX={2} paddingBottom={2}>
            {content()}
          </Stack>
        }
        isLoading={isLoading || !systemLoaded || isLoadingProduct}
      />
    </PageContainer>
  );
};

export default connect(
  (state: {
    admin: { admin: IAuth; systemAccess: number };
    system: { current: ISystem; systems: ISystem[]; isLoaded: boolean };
    mode: IMode;
  }) => ({
    auth: state.admin.admin,
    system: state.system.current,
    mode: state.mode,
    systemLoaded: state.system.isLoaded,
    systemAccess: state.admin.systemAccess,
  }),
  () => ({})
)(NewHardwareEdit);
