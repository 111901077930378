import { useQuery } from '@tanstack/react-query';
import { getAllHardwareProducts } from '../../../api/hardware';

const useHardwareProduct = (parameters?: {}, options?: {}) => {
  return useQuery(
    ['hardwareProduct', parameters],
    () => getAllHardwareProducts(parameters),
    { ...options }
  );
};

export default useHardwareProduct;
