import {
  baseUrl,
  createQueryParameters,
  fetchOptions,
  fetchOptionsV2,
  fetchPostOptions,
  getCredentials,
} from './http';
import { logOut } from '../actionCreators/admin';
import bugsnagClient from '../components/bugsnag';
import { FourOhOneError } from '../actionCreators/system';
import customToast from '../components/Redesing/custom-toast';
import CustomToast from '../components/Redesing/custom-toast';

export const createSystemPublic = (system) => {
  const fetchOps = fetchOptionsV2(null, null, 'POST', system);

  return fetch(`${baseUrl}/system/public`, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        return results.message;
      } else {
        throw new Error(results.message);
      }
    })
    .catch((error) => {
      CustomToast({
        type: 'error',
        message: `There was an error while creating your system. ${error}`,
      });
      return null;
    });
};

export const getAllSystemsV2 = (parameters) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const queryParametersObject = {
    limit: parameters?.limit || 100,
    search: parameters?.search,
    last_id: parameters?.lastId,
    current_system: systemId,
  };
  const queryParameters = createQueryParameters(queryParametersObject);
  const fetchOps = {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // eslint-disable-next-line no-undef
      Authorization: `Basic ${btoa(`${adminEmail}:${adminToken}`)}`,
    },
  };
  return fetch(`${baseUrl}/system/settings/adminsystems?${queryParameters}`, fetchOps)
    .then((response) => response.json())
    .then((systemsRes) => {
      if (systemsRes.status === 200) {
        return systemsRes.message;
      }
      if (systemsRes.status === 401) {
        CustomToast({
          type: 'error',
          message: 'Your session has expired, please log back in',
        });
        logOut();
      } else {
        throw Error(systemsRes?.message);
      }
    })
    .catch((e) => {
      bugsnagClient.notify(e);
      console.error(e);
    });
};

export const getStripeAccountInfo = (systemId) => {
  const { adminEmail, adminToken, systemId: actualSystemId } = getCredentials();

  const fetchOps = fetchOptions(adminEmail, adminToken, systemId || actualSystemId);
  return fetch(`${baseUrl}/system/stripeaccount`, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        return results.message;
      } else if (results.status === 400 && results.message === 'No stripe account') {
        return null;
      }
      FourOhOneError(results);
      throw Error(results.message);
    })
    .catch((e) => {
      bugsnagClient.notify(e);
      console.error(e);
    });
};

export const updateSystem = (payload) => {
  const { adminEmail, adminToken, systemId } = getCredentials();

  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, payload, 'Put');
  return fetch(`${baseUrl}/system/settings`, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        return results.message;
      } else {
        throw Error(results.message);
      }
    })
    .catch((e) => {
      return Promise.reject(`${e}`);
    });
};

export const getOneSystem = (systemId) => {
  const { adminEmail, adminToken } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);
  return fetch(`${baseUrl}/system/settings`, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        return results.message;
      } else {
        throw Error(results.message);
      }
    })
    .catch((e) => {
      CustomToast({
        type: 'error',
        message: `There was an error while loading this system. ${e}`,
      });
    });
};

export const getPartners = () => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);

  return fetch(`${baseUrl}/system/partners`, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        return results.message;
      }
      throw new Error('Error loading partners.');
    })
    .catch((e) => {
      CustomToast({
        type: 'error',
        message: `There was an error while loading partners. ${e}`,
      });
      return [];
    });
};

export const getSystemsAdmins = () => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);

  return fetch(`${baseUrl}/adminuser`, fetchOps)
    .then((res) => res.json())
    .then((admins) => {
      if (admins.status === 200) {
        return admins.message;
      }
      FourOhOneError(admins);
      throw new Error(admins.message);
    })
    .catch((error) => {
      CustomToast({
        type: 'error',
        message: `There was an error while loading admins. ${error}`,
      });
      return [];
    });
};

export const getBankInfo = () => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);
  return fetch(`${baseUrl}/system/bank`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        return response.message;
      } else if (response.status === 404 || response.status === 400) {
        return null;
      }
      FourOhOneError(response);
      throw new Error(response.message);
    })
    .catch((error) => {
      CustomToast({
        type: 'error',
        message: `There was an error while loading your bank information. ${error}`,
      });
    });
};

export const updateStripeBankInfo = (stripeToken) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, stripeToken, 'Put');
  return fetch(`${baseUrl}/system/bank`, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        return results.message;
      }
      FourOhOneError(results);
      throw new Error(results.message);
    })
    .catch((error) => {
      CustomToast({
        type: 'error',
        message: `There was an error while updating your bank information. ${error}`,
      });
    });
};

export const getAllStripeTransfers = (sDate, eDate) => {
  let startDate = sDate;
  let endDate = eDate;
  if (sDate === undefined || sDate === '' || eDate === undefined || eDate === '') {
    startDate = null;
    endDate = null;
  }
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(
    adminEmail,
    adminToken,
    systemId,
    { startDate, endDate },
    'POST'
  );
  return fetch(`${baseUrl}/system/stripepayouts/${adminEmail}`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results.status === 200) {
        return results.message;
      }
      FourOhOneError(results);
      throw new Error('There was an issue downloading your stripe payouts.');
    })
    .catch((error) => {
      return Promise.reject(`${error}`);
    });
};

export const getAllStripeCharges = (sDate, eDate) => {
  let startDate = sDate;
  let endDate = eDate;
  if (sDate === undefined || sDate === '' || eDate === undefined || eDate === '') {
    startDate = null;
    endDate = null;
  }
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(
    adminEmail,
    adminToken,
    systemId,
    { startDate, endDate },
    'POST'
  );

  return fetch(`${baseUrl}/system/stripecharges/${adminEmail}`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results.status === 200) {
        return results.message;
      }
      FourOhOneError(results);
      throw new Error(`There was an error downloading all payments ${results.message}`);
    })
    .catch((error) => {
      return Promise.reject(`${error}`);
    });
};

export const updateTOS = (tosText, sendEmail) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(
    adminEmail,
    adminToken,
    systemId,
    { tos: tosText, sendEmail },
    'PUT'
  );
  return fetch(`${baseUrl}/system/tos`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results.status === 200) {
        return 'success';
      }
      FourOhOneError(results);
      throw new Error(results.message);
    })
    .catch((error) => {
      return Promise.reject(`${error}`);
    });
};

export const updateSystemSettings = (info) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, info, 'PUT');
  return fetch(`${baseUrl}/system/settings`, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        return results.message;
      }
      FourOhOneError(results);
      CustomToast({
        type: 'error',
        message: results.message,
      });
      throw new Error(results.message);
    })
    .catch((e) => {
      return Promise.reject(`${e}`);
    });
};

export const getSystemTOS = () => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);
  return fetch(`${baseUrl}/system/tos`, fetchOps)
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 200) {
        return response.message;
      }
      FourOhOneError(response);
      throw new Error(response.message);
    })
    .catch((e) => {
      CustomToast({
        type: 'error',
        message: `There was an error while loading your terms of service. ${e}`,
      });
    });
};

export const updateSystemProvider = (systemInfo) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, systemInfo, 'PUT');
  return fetch(`${baseUrl}/system/update/provider`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results.status === 200) {
        return results.message;
      }
      FourOhOneError(results);
      throw new Error(results.message);
    })
    .catch((error) => {
      return Promise.reject(`${error}`);
    });
};

export const resetSystemAPIKey = () => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, {}, 'POST');
  return fetch(`${baseUrl}/system/apikey`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results.status === 200) {
        return results.message;
      }
      FourOhOneError(results);
      throw new Error(results.message);
    })
    .catch((error) => {
      return Promise.reject(`${error}`);
    });
};

export const getStripeOnboardingLink = (type) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemId);
  return fetch(`${baseUrl}/billing/onboard/${type}`, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        return results.message;
      }
      customToast({
        message: `There was an error communicating with Stripe. ${results.message}`,
        type: 'error',
      });
      throw new Error(results.message);
    })
    .catch((e) => {
      bugsnagClient.notify(e);
    });
};

export const createSystem = (system) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(adminEmail, adminToken, systemId, system, 'POST');
  return fetch(`${baseUrl}/system`, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        return results.message;
      }
      FourOhOneError(results);
      throw new Error(results.message);
    })
    .catch((error) => {
      return Promise.reject(`${error}`);
    });
};

export const getOneSystemNew = () => {
  const { adminEmail, adminToken, systemGid } = getCredentials();
  const fetchOps = fetchOptions(adminEmail, adminToken, systemGid);
  return fetch(`${baseUrl}/system/settings/currentsystem`, fetchOps)
    .then((response) => response.json())
    .then((systemsRes) => {
      if (systemsRes.status === 200) {
        return systemsRes.message;
      }
      if (systemsRes.status === 401) {
        FourOhOneError(systemsRes);
      } else {
        throw new Error(systemsRes?.message);
      }
    })
    .catch((e) => {
      CustomToast({
        type: 'error',
        message: `There was an error while loading this system. ${e}`,
      });
    });
};

export const imageApproval = (approval, id) => {
  const { adminEmail, adminToken, systemId } = getCredentials();
  const fetchOps = fetchPostOptions(
    adminEmail,
    adminToken,
    systemId,
    { approval: approval },
    'PUT'
  );
  return fetch(`${baseUrl}/approve-image/${id}`, fetchOps)
    .then((res) => res.json())
    .then((results) => {
      if (results.status === 200) {
        return results;
      }
      FourOhOneError(results);
      throw new Error(results.message);
    })
    .catch((error) => {
      return Promise.reject(`${error}`);
    });
};

export const getSystemTosPublic = (systemId) => {
  const fetchOps = fetchOptionsV2(null, null, 'GET', null);
  return fetch(`${baseUrl}/system/tos?systemId=${systemId}`, fetchOps)
    .then((response) => response.json())
    .then((results) => {
      if (results.status === 200) {
        return results.message;
      }

      FourOhOneError(results);
      CustomToast({
        type: 'error',
        message: results.message,
      });
      throw new Error(results.message);
    })
    .catch((e) => {
      CustomToast({
        type: 'error',
        message: `There was an error while loading your terms of service. ${e}`,
      });
    });
};
