import React from 'react';
import MovaticBtnsGroup from '../components/ButtonGroup';
import { getLocaleDateString, history } from '../utils';
import { Button } from '@mui/material';
import { Stack } from '@mui/system';

export const makePendingChargesColumns = (openModalFunction) => {
  const pendingChargesColumns = [
    {
      id: 'actions',
      alignment: 'left',
      title: 'Actions',
      width: '18%',
      shouldExport: false,
      noSort: true,
      activeFormatter: (cell, row) => {
        const arrayBtns = [
          {
            text: (
              <>
                <div>
                  <i className="fa fa-eye" style={{ color: 'white' }} />
                </div>
              </>
            ),
            onClick: (e) => {
              e.stopPropagation();
              history.push(`/rentals/${row.gid}`);
            },
            shouldRender: true,
            shouldDisable: false,
          },
        ];

        return (
          <MovaticBtnsGroup
            arrayBtns={arrayBtns}
            btnGroupClass="btn-group-sm floatLeft"
          />
        );
      },
    },
    {
      id: 'userName',
      title: 'User Name',
      width: '20%',
    },
    {
      id: 'userEmail',
      title: 'User Email',
      width: '20%',
    },
    {
      id: 'startTime',
      title: 'Rental Date',
      width: '10%',
      activeFormatter: (date) => getLocaleDateString(date),
    },
    {
      id: 'chargeAmount',
      title: 'Amount',
      width: '10%',
      alignment: 'left',
      activeFormatter: (amount) => `$ ${(amount / 100).toFixed(2)}`,
    },
    {
      id: 'updateStatus',
      title: 'Update Status',
      width: '20%',
      alignment: 'center',
      shouldExport: false,
      activeFormatter: (cell, row) => (
        <div className="flexDisplay alignCenter justifyCenter">
          <Button
            buttons={1}
            primaryText="Update"
            groupClass="unsetFloat"
            primaryOnClick={(e) => {
              e.stopPropagation();
              openModalFunction(row.id, row.chargeAmount);
            }}
          />
        </div>
      ),
    },
  ];

  return pendingChargesColumns;
};
export const makePendingChargesColumnsV2 = (openModalFunction) => {
  return [
    {
      id: 'userName',
      label: 'User Name',
      format: (row) => {
        return row.userName;
      },
    },
    {
      id: 'userEmail',
      label: 'User Email',
    },
    {
      id: 'startTime',
      label: 'Rental Date',
      format: (row) => getLocaleDateString(row.startTime),
    },
    {
      id: 'chargeAmount',
      label: 'Amount',
      format: (row) => `$ ${(row.chargeAmount / 100).toFixed(2)}`,
    },
    {
      id: 'updateStatus',
      label: 'Status',
      notSortable: true,
      format: (row) => (
        <Stack direction="row" alignContent={'flex-start'} spacing={2}>
          <Button
            variant="outlined"
            onClick={(e) => {
              e.stopPropagation();
              openModalFunction(row.id, row.chargeAmount);
            }}
          >
            Update
          </Button>
        </Stack>
      ),
    },
  ];
};
