import { useRef } from 'react';
import ReactQRCode from 'react-qr-code';
import { Stack, Button, Paper } from '@mui/material';
import FileDownload from '@mui/icons-material/FileDownload';

const downloadQRCode = (qrCodeRef, fileName) => {
  const svg = qrCodeRef.current;

  if (!svg) {
    return;
  }

  const svgData = new XMLSerializer().serializeToString(svg);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const img = new Image();

  img.onload = () => {
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);
    const pngFile = canvas.toDataURL('image/png');
    const downloadLink = document.createElement('a');
    downloadLink.download = fileName || 'qr-code.png';
    downloadLink.href = `${pngFile}`;
    downloadLink.click();
  };
  img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
};

export default function QRCode({
  value,
  size,
  level,
  bgColor,
  fgColor,
  title,
  fileName,
}) {
  const qrCodeRef = useRef(null);

  return (
    <Stack justifyContent={'center'} alignItems={'flex-start'} spacing={1}>
      <Paper
        sx={{
          border: '2px solid #FFFFFF', // Add a white border (adjust the color as needed)
        }}
      >
        <ReactQRCode
          ref={qrCodeRef}
          value={value}
          size={size}
          level={level}
          bgColor={bgColor}
          fgColor={fgColor}
          title={title}
        />
      </Paper>
      <Button
        variant={'contained'}
        startIcon={<FileDownload />}
        onClick={() => downloadQRCode(qrCodeRef, fileName)}
      >
        Download
      </Button>
    </Stack>
  );
}
