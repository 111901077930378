import { useLocation, useHistory } from 'react-router-dom';
import { useMemo } from 'react';

const useQueryString = () => {
  const { search } = useLocation();
  const history = useHistory();

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const deleteQueryParam = (paramName: string) => {
    const updatedParams = new URLSearchParams(queryParams);
    updatedParams.delete(paramName);

    // Replace the current URL with the updated query parameters
    history.push({
      search: updatedParams.toString(),
    });
  };

  return { queryParams, deleteQueryParam };
};

export default useQueryString;
