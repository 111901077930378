import React from 'react';
import { Badge, CardActions, CardHeader, Divider, useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import PageLoader from './page-loader';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Tooltip from '@mui/material/Tooltip';

const CardView = ({
  title,
  content,
  actions,
  headerActions,
  headerStyle,
  isLoading,
  documentationLink = '',
  dataId = '',
  warningMessage = '',
}: {
  content: React.ReactNode;
  headerActions?: React.ReactNode;
  actions?: React.ReactNode;
  headerStyle?: {};
  title: string | React.ReactNode;
  isLoading?: boolean;
  documentationLink?: string;
  dataId?: string;
  warningMessage?: string;
}) => {
  const theme = useTheme();
  return (
    <Card>
      <CardHeader
        data-id={`${dataId}-card-header`}
        sx={headerStyle ? headerStyle : { pl: 2, pt: 3, pr: 2, m: 0 }}
        title={
          <Stack direction="row" spacing={0.5}>
            <Badge
              badgeContent={
                documentationLink && (
                  <a
                    style={{ marginLeft: '0.5rem', marginTop: '0.1rem' }}
                    rel={'noreferrer'}
                    href={documentationLink}
                    target={'_blank'}
                  >
                    <i
                      className="fa fa-book"
                      aria-hidden="true"
                      style={{ color: theme.palette.text.primary }}
                    />
                  </a>
                )
              }
            >
              <Typography variant="h6">
                {warningMessage ? (
                  <Stack
                    direction="row"
                    spacing={1}
                    data-id={`${dataId}-warning-message`}
                  >
                    <Tooltip title={warningMessage}>
                      <ErrorOutlineIcon color="warning" />
                    </Tooltip>
                    <span style={{ paddingLeft: '0.1rem' }}>{title}</span>
                  </Stack>
                ) : (
                  title
                )}
              </Typography>
            </Badge>
          </Stack>
        }
        action={headerActions}
      />
      <>
        {isLoading ? (
          <PageLoader paddingBottom={'24px'} minHeight={'1vh'} maxSize={'50px'} />
        ) : (
          <>{content}</>
        )}
      </>
      {actions && (
        <>
          <Divider /> <CardActions sx={{ flexWrap: 'wrap' }}>{actions}</CardActions>
        </>
      )}
    </Card>
  );
};

export default CardView;
